
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiPengajuanKTANPC {
    static async approveSKP (
        id,
		skp_approve_pc

    ){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"approval": "approve",
			"skp_approve_pc": skp_approve_pc,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_SKP(id),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }
    static async reviseSKP ({
        idSKP,
		skpApprovePC,
		catatan

	}){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var raw = JSON.stringify({
			"approval": "revise",
			"skp_approve_pc": skpApprovePC,
			"catatan": catatan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_SKP(idSKP),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async approveAll (
        skpValue,
    ){
        let myHeaders =  new Headers();
		
		var payloadData = skpValue

		var skpValues = payloadData.skpValue.map(item => ({ skp_id: parseInt(item.skp_id), value: parseInt(item.value) }));

		

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
			"approval": "approve",
			"payload": skpValues
		});


		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_SKP_BULK,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }
}


export default ApiPengajuanKTANPC;
