import WilayahTimePresenter from "../../presenter/wilayah_time-presenter.js";

WilayahTimePresenter
const WilayahTime = {
    async render() {
        return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-12 col-lg-9 col-md-9">
      
                    <div class="card shadow-lg my-2">
                        <div class="card-header text-primary font-weight-bold">
                           Jadwal Operational
                        </div>
                        <div class="card-body" style="z-index:10000;">
                            <!-- Nested Row within Card Body -->
                            <div class="alert alert-info">
                                <strong>Informasi:</strong>
                                <p>  Daftar Jadwal Operasional Website Untuk Sementara waktu di setiap daerah</p>
                            </div>
                            <a href="#/login" class="btn btn-sm text-center">&larr; Kembali ke Login</a>
                            <div class="row">
                                <div class="col-lg-12">
                                   <img loading="lazy" src=\'./src/public/img/jadwal_time_terbaru.jpeg\';"  width="100%"/>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await WilayahTimePresenter.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default WilayahTime;
  

