import AnggotaPDPresenter from "../../../presenter/menu_pengurus_pd/anggota-pd-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const AnggotaPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiKTAN" class="nav-link hapus-border active" id="tab1" data-bs-toggle="tab" data-bs-target="#tabVerifikasiKTAN" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Pengajuan KTAN</button>
					</li>
                    <li class="nav-item" role="presentation">
                        <button href="#tabDataAnggota" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabDataAnggota" data-toggle="tab" type="button" role="tab" aria-controls="tabDataAnggota" aria-selected="true">Data Anggota</button>
                    </li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiKTAN" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
						<div class="row mt-2">
                            <div class="col-sm-12">
                                
                                <div class="card mb-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                            <div class="row">
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Status Approvement</label>
                                                    <select id="selectStatusApprove" class="form-control">
                                                        <option value="pending">Pending</option>
                                                        <option value="approve">Approve</option>
                                                        <option value="revise">Revise</option>
                                                        <option value="all">Semua</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Keanggotaan Cabang</label>
                                                    <select id="selectPengajuanPC" class="form-control">
                                                        <option>Pilih Cabang</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Pengajuan KTAN
                                    </div>
                                    <div class="card-body">
                                        <div class="row d-none">
                                            <div class="col-sm-4 col-xl-4 col-lg-4 text-center">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanKTAN" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAll"></th>
                                                                <th data-column="created_at" >Tanggal Pengajuan</th>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th>Cabang</th>
                                                                <th>NIK</th>
                                                                <th>KTP</th>
                                                                <th>PAS Foto</th>
                                                                <th>Bukti Bayar</th>
                                                                <th>Ijazah</th>
                                                                <th data-column="status" class="text-center">Tracking</th>
                                                                <th class="text-center">Status PD</th>
                                                                <th>Admin PC</th>
                                                                <th data-column="approval_cabang_at">Tanggal Approval PC</th>
                                                                <th>Admin PD</th>
                                                                <th data-column="approval_daerah_at" >Tanggal Approval PD</th>
                                                                <th>Admin PP</th>
                                                                <th data-column="approval_pusat_at" >Tanggal Approval PP</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
                    <div class="tab-pane fade show" id="tabDataAnggota" role="tabpanel" aria-labelledby="tabDataAnggota">
                        <div class="row mt-2">
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                            <div class="row">
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Jenis Keanggotaan</label>
                                                    <select id="selectJenisKeanggotaan" class="form-control">
                                                        <option value="all">Semua</option>
                                                        <option value="1">Sudah Memiliki KTAN</option>
                                                        <option value="0">Belum Memiliki KTAN</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Keanggotaan Cabang</label>
                                                    <select id="selectAnggotaPC" class="form-control">
                                                        <option>Pilih Cabang</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-4 mb-2">
                                                    <label class="control-label font-weight-bold">Jenjang Pendidikan</label>
                                                    <select id="selectJenjang" class="form-control">
                                                        <option value="">Semua</option>
                                                        <option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
                                                        <option value="D3 FARMASI">D3 FARMASI</option>
                                                        <option value="D3 ANAFARMA">D3 ANAFARMA</option>
                                                        <option value="D3 NON-FARMASI">D3 NON-FARMASI</option>
                                                        <option value="S1 FARMASI">S1 FARMASI</option>
                                                        <option value="S1 NON-FARMASI">S1 NON-FARMASI</option>
                                                        <option value="S2 FARMASI">S2 FARMASI</option>
                                                        <option value="S2 NON-FARMASI">S2 NON-FARMASI</option>
                                                        <option value="S3 FARMASI">S3 FARMASI</option>
                                                        <option value="S3 NON-FARMASI">S3 NON-FARMASI</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Kelola Data Anggota
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tableDataAnggota" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="name"  rowspan="2">Nama Anggota</th>
                                                                <th data-column="email" rowspan="2">Email</th>
                                                                <th rowspan="2">PC</th>
                                                                <th data-column="ktan" rowspan="2">No KTAN</th>
                                                                <th rowspan="2">No STRTTK</th>
                                                                <th colspan="4" class="text-center">Pendidikan</th>
                                                                <th data-column="jenis_kelamin" rowspan="2">Jenis Kelamin</th>
                                                                <th data-column="tempat_lahir"  rowspan="2">Tempat Lahir</th>
                                                                <th data-column="tanggal_lahir" rowspan="2">Tanggal Lahir</th>
                                                                <th rowspan="2">No Hp</th>
                                                                <th data-column="ktan_expires_at" rowspan="2">Masa Berlaku KTAN</th>
                                                                <th rowspan="2">Foto</th>
                                                                <th rowspan="2">KTAN</th>
                                                                <th rowspan="2">KTP</th>
                                                                <th rowspan="2">Ijazah</th>
                                                                <th rowspan="2">Aksi</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Jenjang Pendidikan</th>
                                                                <th>Nama Institusi</th>
                                                                <th>Tahun Lulus</th>
                                                                <th>Tanggal Ijazah</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>
		</div>
        <div class="modal fade" id="commentModalUserKTAN" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary form-weight-bold">Komentar Permohonan KTAN</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                                <form id="form-comment-user-ktan">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuanKTANUser" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanKTAN"><i class='fas fa-comment'></i> Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Revisi & Berikan Komentar</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Revisi & Berikan Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="detailAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header font-weight-bold text-primary">
                        Detail Anggota
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 pr-4">
                                <div class="row mt-3 ">
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3 mb-3 text-center">
                                        <img loading="lazy" id="detailAvatar" src="images/logo.png" width="30%">
                                    </div>
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3">
                                        <h3 class="font-weight-bold" id="detailNamaAnggota">Nama Anggota</h3>
                                        <div class="row text-primary">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-id-card"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailKTAN">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-phone-alt"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailNoHP">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-at"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailEmailHead">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 mx-auto pt-3">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-user"></i> Data Umum</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                NIK
                                            </div>
                                            <div class="col-sm-8" id="detailNIK">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Email
                                            </div>
                                            <div class="col-sm-8" id="detailEmail">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tempat Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTempatLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tanggal Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTanggalLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Jenis Kelamin
                                            </div>
                                            <div class="col-sm-8" id="detailJenisKelamin">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-map-marker-alt"></i> Alamat</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Alamat
                                            </div>
                                            <div class="col-sm-7" id="detailAlamat">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Provinsi
                                            </div>
                                            <div class="col-sm-7" id="detailProvinsi">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kota / Kabupaten
                                            </div>
                                            <div class="col-sm-7" id="detailKota">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kecamatan
                                            </div>
                                            <div class="col-sm-7" id="detailKecamatan">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kelurahan / Desa
                                            </div>
                                            <div class="col-sm-7" id="detailKelurahan">
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-money-check"></i> Bukti Bayar</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table width="100%">
                                                    <tr>
                                                        <td id="detailBuktiBayar">Tidak ada bukti bayar !</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-graduation-cap"></i> Pendidikan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPendidikan">

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-briefcase"></i> Pekerjaan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPekerjaan">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-certificate"></i> Serkom</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailSerkom">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-file"></i> STRTTK</h5>
                                        <div class="col-sm-12 text-center overflow-auto">
                                            <table class="table table-bordered" width="100%" id="detailSTR">
                                            
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-id-badge"></i> SIPTTK</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center overflow-auto">
                                                <table class="table table-bordered" width="100%" id="detailSIP">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        
        `;
		return view;
	  },

	async afterRender() {
		await AnggotaPDPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default AnggotaPD;