/* eslint-disable new-cap */
import API_ENDPOINT from '../config/globals/endpoint.js';
import NOTIFICATION_ENDPOINT from '../config/globals/notification_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';

class ApiNotication {
	
	static async getUnreadNotification() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_UNREAD_NOTIFICATION, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getCountUnreadNotification() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_UNREAD_COUNT_NOTIFICATION, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async getNotificationPC() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_NOTIFICATION_PC, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getNotificationPD() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_NOTIFICATION_PD, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async getNotificationPP() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_NOTIFICATION_PP, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async readNotification(id) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetch(NOTIFICATION_ENDPOINT.READ_NOTIFICATION_ID(id),{
				method : "POST",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			Autentication.autoLogout();
			return {};
		}
	}

	static async readAllNotification() {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetch(NOTIFICATION_ENDPOINT.READ_ALL_NOTIFICATION,{
				method : "POST",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}


	static async getAllNotification() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(NOTIFICATION_ENDPOINT.GET_All_NOTIFICATION, {
				method: 'GET',
				headers: myHeaders
		})
		const responseJson = await response.json();
		const statusCode = response.status;
		const responseWithStatusCode = {
			status_code: statusCode,
			data: responseJson
		};
			
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseWithStatusCode;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

}

export default ApiNotication;
