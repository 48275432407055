
import API_ENDPOINT_PENGURUS_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanKTANPP from '../../api/api_pengurus_pp/pengajuan-ktan.js';
import ApiRegister from "../../api/data-register.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";

const RegistrasiAnggotaBaruPresenter = {

	async init() {
		await StorageAnggotaPP.makeStore()
		await StorageAnggotaPPRegis.makeStore()
		await this._setView();
		await this._getDataPrivate();
		await this._getPengajuanRegistrasiFiltered();
		await this._checkAllEventRegis();
		await this._approvePengajuanRegistrasi();
		await this._initAllApproveRegis();
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},



	async _getDataPrivate() {

		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectPengajuanRegisPD").add(new Option(data.province_name, data.province_code))
			});
		}

		const eventChangeRegis = async (e) => {
			document.getElementById("selectPengajuanRegisPC").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanRegisPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanRegisPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}
			document.getElementById("selectPengajuanRegisPC").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanRegisPC").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}


		document.getElementById("selectPengajuanRegisPD").addEventListener("change", eventChangeRegis);


	},



	async _checkAllEventRegis() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanRegistrasi')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAllRegis').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanRegistrasi').DataTable();
				let data = table.row(index).data()

				if (checked) {
					StorageAnggotaPPRegis.pushData(data.uuid)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPPRegis.deleteData(data.uuid)
				}
			}

		}

		document.getElementById('checkAllRegis').addEventListener('change', eventSelectAll)

	},


	async _approvePengajuanRegistrasi() {


		let idPengajuan = 0;
		let approval = '';
		$('#tablePengajuanRegistrasi tbody').on('click', '#approve', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = "approve"
			const approveAnggota = await ApiPengajuanKTANPP.aprroveRegistrasi({
				id: idPengajuan,
				approval: approval,
			});


			if (approveAnggota.status_code != 200) {
				NotificationModal.show(`${approveAnggota.message}`, 'error');
				$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${approveAnggota.message}`, 'success');
				$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
			}

			await StorageAnggotaPPRegis.makeStore();


		});


		$('#tablePengajuanRegistrasi tbody').on('click', '#rejectedRegist', async function () {
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.uuid;
			approval = 'reject';

		});

		$("#btnSendKomentarRegistrasi").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarRegistrasi").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Kirim");
			} else {
				const approveAnggota = await ApiPengajuanKTANPP.aprroveRegistrasi({
					id: idPengajuan,
					approval: approval,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Tolak & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Tolak & Berikan Komentar");
					$("#komentarRegistrasi").val("")
					$('#commentModalRegist').modal('hide');
					$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
				}
			}

		});


	},

	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPP.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPP.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanKTANPP.approveAllKTAN(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$('#tablePengajuanKTAN').DataTable().ajax.reload();
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
						}
						await StorageAnggotaPP.makeStore();
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},

	async _initAllApproveRegis() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPPRegis.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPPRegis.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanKTANPP.approveAllRegistrasi(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
							$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$('#tablePengajuanRegistrasi').DataTable().ajax.reload();
							$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
						}
						await StorageAnggotaPPRegis.makeStore();
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiRegisAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAllRegis').addEventListener('click', eventAllApprove)
	},

	async _getPengajuanRegistrasiFiltered(){
		let status = document.getElementById('selectStatusApprove').value;
		let id_cabang = document.getElementById('selectPengajuanRegisPC').value;
		let id_daerah = document.getElementById('selectPengajuanRegisPD').value;
		let status_pembayaran = document.getElementById('selectStatusPembayaran').value;
		await this._getPengajuanRegistrasi(status,id_cabang,id_daerah,status_pembayaran)

		const eventFiltered = async (e) => {
			let status = document.getElementById('selectStatusApprove').value;
			let id_cabang = document.getElementById('selectPengajuanRegisPC').value;
			let id_daerah = document.getElementById('selectPengajuanRegisPD').value;
			let status_pembayaran = document.getElementById('selectStatusPembayaran').value;
			await this._getPengajuanRegistrasi(status,id_cabang,id_daerah,status_pembayaran)
		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered)
	},

	async _getPengajuanRegistrasi(status,id_cabang, id_daerah,status_pembayaran) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let hide = ''
		if (status == 1) {
			hide = 'd-none'
		}
		let tablePengajuan = await $('#tablePengajuanRegistrasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiRegisAllDt"
					},
					action: function () {
						$("#btnVerifikasiRegisAllDt").html("Loading...");
						$("#btnVerifikasiAllRegis").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PP.REGISTRASI_AKUN(status, id_cabang, id_daerah, status_pembayaran)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'name' },
				{ data: 'user_pd_aktif_id' },
				{ data: 'nik' },
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'email' },
				{ data: 'tanggal_lahir' },
				{ data: 'phone_number' },
				{
					data: 'nominal_invoice', render: function (data) {
						data = parseFloat(data).toFixed(2);

						// Menambahkan titik sebagai pemisah ribuan
						data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

						return "Rp. " + data;
					}
				},

				{ data: 'tanggal_upload_bukti' },
				{
					data: 'bukti_bayar_registrasi',
					render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'status_bayar', render: function (data) {
						if (data === 'Lunas') {
							var status_bayar = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						} else if(data === 'Belum Bayar'){
							var status_bayar = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}else {
							var status_bayar = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}

						return status_bayar;
					}
				},
				{
					data: 'registration_status', render: function (data) {
						if (data === 'Approve') {
							var status_bayar = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						} else {
							var status_bayar = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`
						}

						return status_bayar;
					}
				},
			],
			
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAllRegis').checked;
				if (checked) {
					document.getElementById('checkAllRegis').checked = false;
				}

				$('#tablePengajuanRegistrasi tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.uuid;
						let isChecked = StorageAnggotaPPRegis.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			"initComplete": function () {
				$('#tablePengajuanRegistrasi_filter input').unbind();
					$('#tablePengajuanRegistrasi_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tablePengajuanRegistrasi_filter input').val()).draw();
						}
					}); 
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [{
				"targets": 0,
				"orderable": false,
				className: 'select-checkbox',
				"data": null,
				"defaultContent": ``
			},
			{
				"targets": [0,3,4,5,6,9,10,12,13,14,15],
				"orderable": false
			},
			{
				"targets": [0],
				"visible": true,
				"searchable": false
			},
			{
				"targets": [1],
				"visible": true,
				"type": "datetime"
			},
			{
				"targets": 3,
				className: 'd-none',
				"visible": false
			},
			{
				"targets": 15,
				"data": null,
				render: function (data) {
					let content  = ``;
					if (data.registration_status == 'Approve') {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}else {
						content = `
						<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalRegist" id='rejectedRegist' title='Tolak Regitrasi'><i class='fas fa-times'></i></button>
						</div>`
					}

					return content;
				}
			}
			]
		});


		$('#tablePengajuanRegistrasi thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanRegistrasi thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order = tablePengajuan.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}

			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

		$('#tablePengajuanRegistrasi tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanRegistrasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.uuid

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPPRegis.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPPRegis.pushData(idPengajuan);
			}
		});

	},



}


const StorageAnggotaPP = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

const StorageAnggotaPPRegis = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

export default RegistrasiAnggotaBaruPresenter;