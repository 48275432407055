
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSTRTTKPD from '../../api/api_pengurus_pd/pengajuan-strttk.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiKepengurusanPD from "../../api/api_pengurus_pd/kepengurusan-pd.js"


const STRTTKPD = {

	async init() {
		await this._setView();
		await this._getPersyaratan();
		await this.createSyaratSTRTTK();
		await this._getPengajuanSTRTTK();
		await this._approveSTRTTK();
		await this._downloadSTRTTK();
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSyaratSTRTTK").hide();

		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},

	async _getPersyaratan() {
		$("#tab2").one("click", async function () {
		 mRefresh.refresh()
		let table = await $('#tablePersyaratanSTRTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.PERSYARATAN_STRTTK}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'description' },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 2,
					"data": null,
					"defaultContent": `
					<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-sm'  id='edit' title='Edit data '><i class='far fa-edit'></i></button>
                     <button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
			]
		});
		mRefresh.resolve()
	});

	},

	async _getPengajuanSTRTTK() {
		var status = 'pending_pd';
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tablePengajuanSTRTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.STRTTK(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'user.name' },
				{
					data: 'created_at'
				},
				{ data: 'jenis_permohonan' },
				{
					data: 'berkas_data_umum.ktan_lama', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.ijazah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.serkom', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_keterangan_praktik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_keabsahan_dokumen', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_patuh_kode_etik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_sumpah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				}
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [3, 4, 5, 6, 7, 8, 9, 11],
					"visible": true,
					"searchable": true,
					className: "text-center"
				},
				{
					"targets": [3, 4, 5, 6, 7, 8, 9,10, 11],
					"orderable": false
				},
				{
					"targets": 14,
					"data": null,
					"defaultContent": `
					<div style="display:block">
						<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSTRTTK' title='Download Rekomendasi STRTTK'><i class='fas fa-download'></i> Rekomendasi STRTTK</button>
						<button style="width:100%;" data-toggle="modal" data-target="#detailSTRTTKModal" class='mb-1 btn btn-success btn-sm' id='approve' title='Approve'><i class='fas fa-check-circle'></i> Proses</button>
					</div>`
				},
			]
		});





		$(".btn-jenis-permohonan").on("click", async function () {
			var status = $(this).val();
			var startDate = '';
			var endDate = '';
			let buttonAllElement = document.querySelectorAll('.btn-jenis-permohonan')

			buttonAllElement.forEach((element) => {
				element.classList.remove('btn-primary');
				element.classList.add('btn-outline-primary');
			});

			this.classList.remove('btn-outline-primary')
			this.classList.add('btn-primary')


			if (status == 'approve_pd') {
				var bg = 'bg-success'
				$('#btnVerifikasiAllDt').hide()
				var disabled = 'd-none';
			} else if (status == 'revise_pd') {
				var bg = 'bg-warning'
				$('#btnVerifikasiAllDt').hide()
			} else if (status == 'pending_pd') {
				var bg = 'bg-primary'
				$('#btnVerifikasiAllDt').show()
			}

			let table = await $('#tablePengajuanSTRTTK').DataTable({
				"pageLength": 10,
				"lengthMenu": [10, 25, 50, 100],
				"processing": true,
				"destroy": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_PENGURUS_PD.STRTTK(status)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					},
					"data" : function (d) {
						d.orderColumn = savedOrderColumn;
						d.orderDirection = savedOrderDirection;
					}
				},
				"columns": [
					{ data: 'user.name' },
					{
						data: 'created_at'
					},
					{ data: 'jenis_permohonan' },
					{
						data: 'berkas_data_umum.ktan_lama', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_data_umum.ktp_photo', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.ijazah', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.serkom', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.strttk', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.surat_keterangan_praktik', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.surat_pernyataan_keabsahan_dokumen', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.surat_pernyataan_patuh_kode_etik', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'berkas_wajib.surat_pernyataan_sumpah', render: function (data) {
							var url = data;
							var content = '';
							if (url == '' || url == null) {
								content = '-';
							} else {
								content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}

							return content;
						}
					},
					{
						data: 'status_pc', render: function (data) {
							if (data == 'pending') {
								var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							} else if (data == 'revise') {
								var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							} else {
								var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							}
							return status
						}
					},
					{
						data: 'status_pd', render: function (data) {
							if (data == 'pending') {
								var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							} else if (data == 'revise') {
								var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							} else {
								var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
							}

							return status
						}
					}
				],
				order: [
					[1, 'desc']
				],
				"columnDefs": [
					{
						"targets": [0],
						"visible": true,
						"searchable": false
					},
					{
						"targets": [3, 4, 5, 6, 7, 8, 9,10, 11],
						"orderable": false
					},
					{
						"targets": [3, 4, 5, 6, 7, 8, 9, 10, 11],
						"visible": true,
						"searchable": true,
						className: "text-center"
					},
					{
						"targets": 14,
						"data": null,
						"defaultContent": `
						<div style="display:block">
							<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSTRTTK' title='Download Rekomendasi STRTTK'><i class='fas fa-download'></i> Rekomendasi STRTTK</button>
							<button style="width:100%;" data-toggle="modal" data-target="#detailSTRTTKModal" class='mb-1 btn btn-success btn-sm' id='approve' title='Approve'><i class='fas fa-check-circle'></i> Proses</button>
						</div>`
					},
				]
			});

			$('#tablePengajuanSTRTTK thead').off('click', 'th').on('click', 'th', function () {
				let chachedCoulumnName = savedOrderColumn;
				const columnIndex = $(this).index();
				const columnHeaders = $('#tablePengajuanSTRTTK thead th');
				const columnName = $(columnHeaders[columnIndex]).data('column');
				var order =  table.order()[0][1];
				
				if (order == "asc") {
					order = "desc"
				}else{
					order = "asc"
				}
				if(chachedCoulumnName != columnName){
					order = "asc"
				}
				savedOrderColumn = columnName;
				chachedCoulumnName = savedOrderColumn;
				savedOrderDirection = order;
			});

		});


		$('#tablePengajuanSTRTTK tbody').on('click', '#approve', async function () {
			$("#persyaratanSTRTTK").html('');
			$("#dataPendidikanSTRTTK").html('');
			$("#dataPekerjaan").html('');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			$("#textHidden").val(id);
			const getDetailPenagjuanSTRTTK = await ApiAnggota.getPengajuanSTRTTKByID({ id });


			let dataDetail = getDetailPenagjuanSTRTTK.data;
			let UserID = '';
			if (getDetailPenagjuanSTRTTK.status_code == 200) {
				UserID = dataDetail.user_id;
				let timePengajuan = dataDetail.created_at;
				let timeSurat = new Date().toLocaleDateString('en-US');
				let jenisPermohonan = dataDetail.jenis_permohonan;
				$("#textNomorSuratRekomendasi").val(dataDetail.nomor_sr_pd);
				if (jenisPermohonan == 'new') {
					jenisPermohonan = 'Baru';
				} else {
					jenisPermohonan = 'Perpanjang'
				}

				let perpanjangDengan = dataDetail.perpanjang_dengan;

				if (perpanjangDengan == null) {
					perpanjangDengan = '-';
				} else {
					perpanjangDengan = perpanjangDengan;
				}
				$("#tanggalSuratRekomendasi").val(timeSurat);
				$("#tanggalPengajuan").val(timePengajuan);
				$("#textKTAN").val(dataDetail.user.ktan);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textEmail").val(dataDetail.user.email);
				$("#textJenisPermohonan").val(jenisPermohonan);
				$("#textPerpanjangDengan").val(perpanjangDengan);
				$("#textStatus").val(dataDetail.status);


				var dokumen = dataDetail.dokumen
				var persyaratanSTRTTK = ``
				dokumen.forEach(dataBerkas => {
					persyaratanSTRTTK += `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`
				});
				$("#persyaratanSTRTTK").append(persyaratanSTRTTK);
				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="5" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPendidikan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPendidikanSTRTTK").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td colspan="10" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<td>${dokumenPekerjaan.nama_tempat_kerja}</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
							<td>${dokumenPekerjaan.phone_number}</td>
							<td>${dokumenPekerjaan.email}</td>
							<td>${dokumenPekerjaan.jabatan}</td>
							<td>${dokumenPekerjaan.jenis_tempat_kerja}</td>
							<td>${dokumenPekerjaan.dari_tahun}</td>
							<td>${dokumenPekerjaan.sampai_tahun}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPekerjaan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPekerjaan").append(pekerjaan);


			}
			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanSTRTTKPD.getCommentar(UserID, "rekom_strttk", id)
			let dataComment = response.data;
			dataComment.reverse();

			let getAllContent = async () => {
				let elmHtml = ``
				await dataComment.forEach(async element => {
					elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
				});
				return elmHtml
			}


			if (response.data == undefined || response.data.length <= 0) {
				document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
			} else {
				document.getElementById("content-comment").innerHTML = await getAllContent();

				setTimeout(() => {
					var elem = document.getElementById('content-comment');
					elem.scrollTop = elem.scrollHeight;
				}, 400);
			}

			$("#btnSendComment").on('click', async function () {



				if (UserID != '' && id != '') {
					document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					let content = document.getElementById("textKomentar").value
					const response = await ApiPengajuanSTRTTKPD.sendCommentar(UserID, id, "rekom_strttk", content);
					if (response.status_code == 200 || 201) {

						let getContentAdmin = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-left text-dark text-left" style="width:88%;">
											<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}

						let getContentUser = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-right text-dark text-left" style="width:88%;">
											<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}


						let response = await ApiPengajuanSTRTTKPD.getCommentar(UserID, "rekom_strttk", id)
						let dataComment = response.data;
						dataComment.reverse();

						let getAllContent = async () => {
							let elmHtml = ``
							await dataComment.forEach(async element => {
								elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
							});
							return elmHtml
						}

						if (response.data == undefined || response.data.length <= 0) {
							document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
						} else {
							document.getElementById("content-comment").innerHTML = await getAllContent();

							setTimeout(() => {
								var elem = document.getElementById('content-comment');
								elem.scrollTop = elem.scrollHeight;
							}, 400);
						}
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					} else {
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					}


				}


				// $(this).off('click');


			});

			$('#detailSTRTTKModal').on('hidden.bs.modal', async function (e) {
				$('#btnSendComment').off('click');
				$('#textNomorSuratRekomendasi').val('');
				$('#selectStatusApprove').val('');
				$('#textCatatan').val('');
				$('#textKomentar').val('');

			});
		});


		$('#tablePengajuanSTRTTK thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSTRTTK thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});
	},


	async _downloadSTRTTK() {
		$('#tablePengajuanSTRTTK tbody').on('click', '#rekomendasiSTRTTK', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			if (data.status_pd != "approve") {
				swal.fire({
					title: 'Warning !',
					html: "Status Permohonan : <strong><u>" + data.status_pd + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PD ",
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
			} else {
				const downloadRekomSTRTTKPD = await ApiAnggota.downloadSTRTTKPD({ id });
			}
			$(this).html('<i class="fas fa-download"></i> Rekomendasi STRTTK');

		});
	},

	async createSyaratSTRTTK() {
		const eventCreateSyaratSTRTTK = async (e) => {
			e.preventDefault();
			if ($("#btnAddSyaratSTRTTK").is(':visible')) {
				document.getElementById("btnAddSyaratSTRTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSyaratSTRTTK = await ApiPengajuanSTRTTKPD.createSyaratSTRTTK({
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,
				});
				if (createSyaratSTRTTK.status_code != 201) {
					NotificationModal.show(`${createSyaratSTRTTK.message}`, 'error');
					document.getElementById("btnAddSyaratSTRTTK").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSTRTTK").hide();
				} else {
					NotificationModal.show(`${createSyaratSTRTTK.message}`, 'success');
					document.getElementById("btnAddSyaratSTRTTK").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSTRTTK").hide();
					$("#btnClearSyaratSTRTTK").trigger("click");
					$('#tablePersyaratanSTRTTK').DataTable().ajax.reload();
				}
			} else {
				document.getElementById("btnUpdateSyaratSTRTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSyaratSTRTTK = await ApiPengajuanSTRTTKPD.updateSyaratSTRTTK({
					id: document.getElementById('textHIddenPersyaratan').value,
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,

				});

				if (updateSyaratSTRTTK.status_code != 200) {
					NotificationModal.show(`${updateSyaratSTRTTK.message}`, 'error');
					$("#btnAddSyaratSTRTTK").hide();
					document.getElementById("btnUpdateSyaratSTRTTK").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
				} else {
					NotificationModal.show(`${updateSyaratSTRTTK.message}`, 'success');
					document.getElementById("btnUpdateSyaratSTRTTK").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
					$("#btnAddSyaratSTRTTK").hide();
					$("#btnClearSyaratSTRTTK").trigger("click");
					$('#tablePersyaratanSTRTTK').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-persyaratan-STRTTK').addEventListener('submit', eventCreateSyaratSTRTTK);

		$('#tablePersyaratanSTRTTK tbody').on('click', '#edit', function () {
			const table = $('#tablePersyaratanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();

			$('#textHIddenPersyaratan').val(data.id);
			$('#textNamaBerkas').val(data.name);
			$('#textDeskripsi').val(data.description);
			$("#btnAddSyaratSTRTTK").hide();
			$("#btnUpdateSyaratSTRTTK").show();

		});


		const eventClear = async (e) => {
			document.getElementById("form-persyaratan-STRTTK").reset();
			$("#btnAddSyaratSTRTTK").show();
			$("#btnUpdateSyaratSTRTTK").hide();
		}

		document.getElementById("btnClearSyaratSTRTTK").addEventListener("click", eventClear)

		$('#tablePersyaratanSTRTTK tbody').on('click', '#delete', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			const table = $('#tablePersyaratanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Persyaratan </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deleteSyaratSTRTTK = await ApiPengajuanSTRTTKPD.deleteSyaratSTRTTK({
						id: id
					});

					if (deleteSyaratSTRTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSyaratSTRTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePersyaratanSTRTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSyaratSTRTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				} else if (result.dismiss == 'cancel') {
					$(this).html('<i class="fas fa-trash"></i></button>');
				}
			}

			);
		});
	},


	async _approveSTRTTK() {

		const approveSTRTTK = async (e) => {
			$("#btnSave").html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');

			let id = $("#textHidden").val();
			let approval = $("#selectStatusApprove").val();
			let tanggalSuratRekomendasi = $("#tanggalSuratRekomendasi").val();
			let nomorSuratKeputusan = $("#textNomorSuratKeputusan").val();
			let nomorSuratRekomendasi = $("#textNomorSuratRekomendasi").val();
			let nomorSuratKeterangan = $("#textNomorSuratKeterangan").val();
			let namaPenandatangan1 = $("#textNamaPenandatangan1").val();
			let jabatanPenandatangan1 = $("#textJabatanPenandatangan1").val();
			let nianPenandatangan1 = $("#textNIANPenandatangan1").val();
			let periodePenandatangan1 = $("#textPeriodePenandatangan1").val();
			let namaPenandatangan2 = $("#textNamaPenandatangan2").val();
			let jabatanPenandatangan2 = $("#textJabatanPenandatangan2").val();
			let nianPenandatangan2 = $("#textNIANPenandatangan2").val();
			let periodePenandatangan2 = $("#textPeriodePenandatangan2").val();
			let masaBerlaku = $("#textMasaBerlaku").val();
			let kepada = $("#textKepada").val();
			let di = $("#textDi").val();
			let sifat = $("#textSifat").val();
			let lampiran = $("#textLampiran").val();
			let perihal = $("#textPerihal").val();
			let jumlahPembayaran = $("#textJumlahPembayaran").val();
			let statusPembayaran = $("#selectStatusPembayaran").val();
			let penandatanganKwitansi = $("#textPenandaKwitansi").val();
			let tandaTanganDigital = $("#selectTandaTanganDigital").val();
			let catatan = $("#textCatatan").val();

			const ApproveSTRTTK = await ApiPengajuanSTRTTKPD.approvePengajuanSTRTTK({
				id: id,
				approval: approval,
				tanggal_sr_pd: tanggalSuratRekomendasi,
				nomor_surat_keputusan_pd: nomorSuratKeputusan,
				nomor_sr_pd: nomorSuratRekomendasi,
				nomor_keterangan_pd: nomorSuratKeterangan,
				nama_penanda1_pd: namaPenandatangan1,
				jabatan_penanda1_pd: jabatanPenandatangan1,
				nian_penanda1_pd: nianPenandatangan1,
				periode_penanda1_pd: periodePenandatangan1,
				nama_penanda2_pd: namaPenandatangan2,
				jabatan_penanda2_pd: jabatanPenandatangan2,
				nian_penanda2_pd: nianPenandatangan2,
				masa_berlaku_sr_pd: masaBerlaku,
				kepada_pd: kepada,
				di_pd: di,
				sifat_pd: sifat,
				lampiran_pd: lampiran,
				perihal_pd: perihal,
				jumlah_pembayaran_pd: jumlahPembayaran,
				status_pembayaran_pd: statusPembayaran,
				penanda_kwitansi_pd: penandatanganKwitansi,
				ttd_digital_active: tandaTanganDigital,
				catatan: catatan
			});

			if (ApproveSTRTTK.status_code == 200) {
				NotificationModal.show(`${ApproveSTRTTK.message}`, 'success');
				$("#btnSave").html('<i class="fas fa-save"></i> Simpan');
				$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${ApproveSTRTTK.message}`, 'error');
				$("#btnSave").html('<i class="fas fa-save"></i> Simpan');
				$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
			}
		}

		document.getElementById('btnSave').addEventListener('click', approveSTRTTK);
	}




}

export default STRTTKPD;
