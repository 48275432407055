
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPD from "../../api/api_pengurus_pd/kepengurusan-pd.js"
import ApiAnggota from "../../api/data-anggota.js";


const GeneralSettingPDPresenter = {

	async init() {
		await this._setView();
		await this._getSetting();
		await this.updateSetting();

	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},

	async _showLoading(){
		
	},

	async _hideLoading(){
	
	},

	async _getSetting(){
		this._showLoading();
		const getSetting = await ApiKepengurusanPD.getSetting();

		let dataSetting = getSetting.data;
		if (getSetting.status_code == 200) {
			$("#textNamaKota").val(dataSetting.nama_kota);
			$("#textAlamatDaerah").val(dataSetting.address);
			$("#textInformasiUmum").val(dataSetting.information);
			$("#textTelepon").val(dataSetting.telp);
			$("#textEmailCabang").val(dataSetting.email);
			$("#textFaks").val(dataSetting.fak);
			$("#textKodePos").val(dataSetting.kode_pos);
			$("#textUrlMaps").val(dataSetting.maps_url);
			$("#textInformasiRekening").val(dataSetting.informasi_rekening);
			$("#strttkTTDPD").val(dataSetting.ttd_digital_active);
		}
		this._hideLoading();

	},

	async updateSetting(){
		const evenUpdateSetting = async (e) => {
				
			document.getElementById("btnUpdate").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const updateSetting = await ApiKepengurusanPD.updateSetting({
				nama_kota : $("#textNamaKota").val(),
				alamat_cabang: $("#textAlamatDaerah").val(),
				informasi_umum: $("#textInformasiUmum").val(),
				telpon: $("#textTelepon").val(),
				email: $("#textEmailCabang").val(),
				faksimil: $("#textFaks").val(),
				kode_pos: $("#textKodePos").val(),
				url_maps: $("#textUrlMaps").val(),
				informasi_rekening: $("#textInformasiRekening").val(),
				ttd_digital: $("#strttkTTDPD").val()

			});

			if (updateSetting.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'error');
				document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Update Setting`;
			} else {
				NotificationModal.show('Setting data umum berhasil di update !', 'success');
				document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Update Setting`;
			}
			e.preventDefault();
			

            
		}
		document.getElementById('btnUpdate').addEventListener('click', evenUpdateSetting);
	},

}



export default GeneralSettingPDPresenter;