import ApiPengajuanSIPTTKPC from "../../api/api_pengurus_pc/pengajuan-sipttk.js";
import ApiMutasiPC from "../../api/api_pengurus_pd/data-mutasi.js";
import ApiPengajuanSTRTTKPD from "../../api/api_pengurus_pd/pengajuan-strttk.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PC_ENDPOINT from "../../config/globals/menu_pengurus_pc_endpoint.js";
import API_PENGURUS_PD_ENDPOINT from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from "../../utils/autentication.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiAnggota from "../../api/data-anggota.js";


const RekomMutasiPdPresenter = {

	async init() {
		await this._setView();
		await this.getPengurusPd();
		await this.accPDAsalInitiator();
		await this.getDatain()
		await this.initFiltered();
		await this.initSendCommentar();
		await this.initSendCommentarTujuan();

		const theEvent = async (e) => {
			mRefresh.refresh();
			await this.getData(null);
			mRefresh.resolve();
		}
		$("#tab2").one("click", async function () {
			await theEvent();
		});
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		await ApiAnggota.getPrivate();
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},

	async initSendCommentar() {
		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = document.getElementById("textUserIdKomentar").value;
			let PengajuanID = $("#textIdPengajuan").val();
			if (UserID != '' && PengajuanID != '') {
				document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let content = document.getElementById("textKomentar").value;
				const response = await ApiMutasiPC.sendCommentar(UserID, PengajuanID, content, "rekom_mutasi");
				if (response.status_code == 200 || 201) {

					let getContentAdmin = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						return `
                            <div style="width:100%">
                                <div style="width:10%;" class="float-left">
                                    <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                                </div>
                                <div class="float-left text-dark text-left" style="width:88%;">
                                    <div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
                                        <small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
                                        <small>${user.body}</small><br>
                                        <div class="text-right">
                                            <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `
					}

					let getContentUser = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
						return `
                            <div style="width:100%">
                                <div style="width:10%;" class="float-right">
                                    <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                                </div>
                                <div class="float-right text-dark text-left" style="width:88%;">
                                    <div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
                                        <small><b>${user.actor.name} [${user.actor.role}  ${ketLocation}]</b></small><hr>
                                        <small>${user.body}</small><br>
                                        <div class="text-right">
                                            <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `
					}

					let response_comment = await ApiPengajuanSTRTTKPD.getCommentar(UserID, "rekom_mutasi", PengajuanID)

					let getAllContent = async () => {
						let elmHtml = ``
						await response_comment.data.reverse().forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}

					if (response_comment.data == undefined || response_comment.data.length <= 0) {

					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
					document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentar").val('');
				} else {
					document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentar").val('');
				}

			}
			e.preventDefault();
		}
		document.getElementById("form-comment").addEventListener("submit", eventSendCommentar, false)

	},

	async initSendCommentarTujuan() {
		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let UserID = document.getElementById("textUserIdKomentar").value;
			let PengajuanID = $("#textIdPengajuanTujuan").val();
			if (UserID != '' && PengajuanID != '') {
				document.getElementById("btnSendCommentTujuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let fromPC = await Autentication.getPDMember()
				let content = `${document.getElementById("textKomentarTujuan").value} <br><br><b>PD [${fromPC}]</b>`
				const response = await ApiMutasiPC.sendCommentar(UserID, PengajuanID, content, "rekom_mutasi");
				if (response.status_code == 200 || 201) {

					let getContentAdmin = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						return `
                            <div style="width:100%">
                                <div style="width:10%;" class="float-left">
                                    <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                                </div>
                                <div class="float-left text-dark text-left" style="width:88%;">
                                    <div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
                                        <small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
                                        <small>${user.body}</small><br>
                                        <div class="text-right">
                                            <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `
					}

					let getContentUser = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
						return `
                            <div style="width:100%">
                                <div style="width:10%;" class="float-right">
                                    <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                                </div>
                                <div class="float-right text-dark text-left" style="width:88%;">
                                    <div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
                                        <small><b>${user.actor.name} [${user.actor.role}  ${ketLocation}]</b></small><hr>
                                        <small>${user.body}</small><br>
                                        <div class="text-right">
                                            <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `
					}

					let response_comment = await ApiPengajuanSIPTTKPC.getCommentar(UserID, "rekom_mutasi", PengajuanID)

					let getAllContent = async () => {
						let elmHtml = ``
						await response_comment.data.reverse().forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}

					if (response_comment.data == undefined || response_comment.data.length <= 0) {

					} else {
						document.getElementById("content-comment-tujuan").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment-tujuan');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
					document.getElementById("btnSendCommentTujuan").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentarTujuan").val('');
				} else {
					document.getElementById("btnSendCommentTujuan").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentar").val('');
				}

			}
			e.preventDefault();
		}

		document.getElementById("form-comment-tujuan").addEventListener("submit", eventSendCommentar, false)
	},

	async getDataLogMutasi() {

		let table = await $('#tableRekapMutasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PD_ENDPOINT.GET_ALL_LOG_MUTASI_DATA(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'name' },
				{ data: 'ktan' },
				{ data: 'cabang_asal' },
				{ data: 'cabang_tujuan' },
				{
					data: 'created_at'
				},
				{ data: 'alasan' },
				{
					data: 'lampiran', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
			},
			"columnDefs": [
				{
					"targets": [0],
					"data": null,
					"orderable": false,
					"searchable": false
				},
				{
					"targets": [5, 6],
					"visible": true,
					"searchable": true,
					"className": "text-center font-weight-bold",
				},
			],
			order: [[1, 'asc']]
		});
	},

	async initFiltered() {

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("selectFilterData").value;
			await this.getData(valueSelected)

			e.preventDefault();
		}


		const eventChange2 = async (e) => {
			await this.getDatain()
			e.preventDefault();
		}

		document.getElementById("selectFilterData2").addEventListener("change", eventChange2)

		document.getElementById("selectFilterData").addEventListener("change", eventChange)
	},



	async getData(filtered) {
		let status = ``
		let valueSelected = document.getElementById("selectFilterData").value;
		status = valueSelected
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'desc';
		let table = await $('#tableDataMutasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PD_ENDPOINT.GET_ALL_MUTASI_DATA_OUT(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'name' },
				{ data: 'tanggal_pengajuan' },
				{ data: 'cabang_asal' },
				{ data: 'daerah_asal' },
				{ data: 'daerah_tujuan' },
				{ data: 'cabang_tujuan' },
				{
					data: 'status_pc_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return `<div style="display:block">
							<div style="display:flex"><button class=' mb-1  btn btn-info btn-sm'  id='edit' title='Pilih Pengajuan' data-toggle="modal" data-target="#detailMutasiModal"><i class='far fa-edit'></i> Pilih</button>
							</div>`

					}
				}
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
			},
			"columnDefs": [
				{
					"targets": [0],
					"data": null,
					"orderable": false,
					"searchable": false
				},
				{
					"targets": [3,4,5, 6, 7, 8,9,10],
					"visible": true,
					"searchable": true,
					"className": "text-center font-weight-bold",
				},
				{
					"targets": [3,4,5,6,11,12],
					"orderable": false,
				},
				{
					"targets": 12,
					"data": null,
					"defaultContent": `
					<div style="display:block">
                    <div style="display:flex">
						<button style="margin-left:10px;" class='mb-1 btn btn-light border btn-sm' id='cetakLolosButuh' title='Cetak Lolos Butuh'><i class='fas fa-download'></i> Cetak Lolos Butuh</button>
					</div>`
				},
			],
			order: [[2, 'desc']]
		});

		$('#tableDataMutasi tbody').off('click', '#cetakLolosButuh').on('click', '#cetakLolosButuh', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tableDataMutasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const downloadRekomSTRTTKPD = await ApiMutasiPC.downloadCetakLolosButuhPD({ id });
			$(this).html('<i class="fas fa-download"></i> Cetak Mutasi)');

		});

		const callComentar = async (e) => {
			await this.initKomentar()
		}

		const callTracking = async (id, user_id) => {
			await this.initTracking(id, user_id)
		}
		
		$('#tableDataMutasi tbody').off('click', '#edit').on('click', '#edit', async function () {
			mRefresh.refresh();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			if (data.status_pd_asal == "approve") {
				document.getElementById("btnSimpanMutasi").classList.add("d-none")
			}else{
				document.getElementById("btnSimpanMutasi").classList.remove("d-none")
			}
			// here@Todo
			document.getElementById("textIdPengajuan").value = id;
			const responseDetail = await ApiMutasiPC.getDetailMutasiPD({ id });
			let dataDetail = responseDetail.data;
			
			if (responseDetail.status_code == 200) {
				document.getElementById("textNameAsal").value = dataDetail.name
				document.getElementById("textDariPD").value = dataDetail.daerah_asal
				document.getElementById("textDariPC").value = dataDetail.cabang_asal
				document.getElementById("textKTAN").value = dataDetail.ktan
				document.getElementById("textLulusan").value = dataDetail.lulusan
				document.getElementById("textTahunLulus").value = dataDetail.tahun_lulus
				document.getElementById("textNOSTRTTK").value = dataDetail.nomor_strttk
				document.getElementById("textTempatKerjaPC").value = dataDetail.tempat_kerja_asal
				document.getElementById("textSaranaTujuanKefarmasian").value = dataDetail.sarana_kefarmasian_tujuan

				var selectPD = document.getElementById("selectProvinsiTujuan");
				var lengthOptionPd = selectPD.options.length;
				for (let i = lengthOptionPd - 1; i >= 0; i--) {
					selectPD.options[i] = null;
				}


				var selectPC = document.getElementById("selectKotaTujuan");
				var lengthOptionPc = selectPC.options.length;
				for (let i = lengthOptionPc - 1; i >= 0; i--) {
					selectPC.options[i] = null;
				}

				document.getElementById("selectProvinsiTujuan").add(new Option(dataDetail.daerah_tujuan, dataDetail.daerah_tujuan))

				document.getElementById("selectKotaTujuan").add(new Option(dataDetail.cabang_tujuan, dataDetail.cabang_tujuan))

				$("#data-umum-tab").trigger("click");

				document.getElementById("textUserIdKomentar").value = dataDetail.user_id;
				await callComentar();
				await callTracking(id, dataDetail.user_id)

				$("#persyaratanSerkom").html('');
				var dokumen = dataDetail.dokumen;
				dokumen.forEach(dataBerkas => {
					var persyaratanSerkom = `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`
					$("#persyaratanSerkom").append(persyaratanSerkom);
				});

				$("#persyaratanTujuanOut").html('');
				var dokumenOut = dataDetail.dokumen_tujuan;
				dokumenOut.forEach(dataMy => {
					var content = `
						<tr>
							<td>${dataMy.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataMy.dokumen_url}' width="40px"></td>
						</tr>
					`
					$("#persyaratanTujuanOut").append(content);
				});
			}



			mRefresh.resolve();
		});

		$('#tableDataMutasi thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataMutasi thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async getDatain() {
		const refreshData = async () => {
			await this.getDatain()
		}
		const callComentar = async (e) => {
			await this.initKomentarTujuan()
		}

		var savedOrderColumn = 'name';
		var savedOrderDirection = 'desc';

		let statusMasuk = document.getElementById("selectFilterData2").value;
		let tableMutasiMasuk = await $('#tableDataMutasiMasuk').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PD_ENDPOINT.GET_ALL_MUTASI_DATA_IN(statusMasuk)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'name' },
				{ data: 'tanggal_pengajuan' },
				{ data: 'cabang_asal' },
				{ data: 'daerah_asal' },
				{ data: 'daerah_tujuan' },
				{ data: 'cabang_tujuan' },
				{
					data: 'status_pc_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return `<div style="display:block">
							
							<button style="margin-left:10px;" class='mb-1 btn btn-info btn-sm text-center' id='edit' title='Pilih Pengajuan' data-toggle="modal" data-target="#detailMutasiModalPdTujuan"><i class="fas fa-edit"></i> Pilih</button>
							<button style="margin-left:10px;" class='mb-1 btn btn-success btn-sm text-center' id='approve' title='Detail'><i class="fas fa-check"></i> Approve</button>
							<button style="margin-left:10px;" class='mb-1 btn btn-danger btn-sm text-center'  id='revise' title='Reject'><i class="fas fa fa-exclamation-triangle"></i> Reject</button>
						</div>`
					}
				}
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
			},
			"columnDefs": [
				{
					"targets": [0],
					"data": null,
					"orderable": false,
					"searchable": false
				},
				{
					"targets": [3,4,5,6,11,12],
					"orderable": false,
				},
				{
					"targets": [3,4,5, 6, 7, 8,9,10],
					"visible": true,
					"searchable": true,
					"className": "text-center font-weight-bold",
				},
				{
					"targets": 12,
					"data": null,
					"defaultContent": `
					<div style="display:block">
                    <div style="display:flex">
						<button style="margin-left:10px;" class='mb-1 btn btn-light border btn-sm' id='cetakLolosButuh' title='Cetak Mutasi'><i class='fas fa-download'></i> Cetak Mutasi</button>
					
					</div>`
				},

			],
			order: [[2, 'desc']]
		});

		$('#tableDataMutasiMasuk tbody').off('click', '#approve').on('click', '#approve', async function (e) {
			e.stopImmediatePropagation()
			const table = $('#tableDataMutasiMasuk').DataTable();
			let data = table.row($(this).parents('tr')).data();

			swal.fire({
				title: 'Approve Mutasi',
				html: "Anda ingin menyetujui (Approve) REKOM mutasi ini?\ " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Approve!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const approvalPcTujuan = await ApiMutasiPC.approvalMutasiPDTujuan({
						idPengajuan: id,
						approval: true
					});

					if (approvalPcTujuan.status_code == '200') {
						swal.fire({
							title: 'Approve',
							text: "Data berhasil disetujui",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						await refreshData();

					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcTujuan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		$('#tableDataMutasiMasuk tbody').off('click', '#revise').on('click', '#revise', async function (e) {
			e.stopImmediatePropagation()
			const table = $('#tableDataMutasiMasuk').DataTable();
			let data = table.row($(this).parents('tr')).data();

			swal.fire({
				title: 'Reject Mutasi',
				html: "Anda ingin menolak (Reject) REKOM mutasi ini?\ " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const approvalPcTujuan = await ApiMutasiPC.approvalMutasiPDTujuan({
						idPengajuan: id,
						approval: false
					});

					if (approvalPcTujuan.status_code == '200') {
						swal.fire({
							title: 'Reject Mutasi',
							text: "Data berhasil ditolak",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						await refreshData();

					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcTujuan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});
		
		
		$('#tableDataMutasiMasuk tbody').off('click', '#edit').on('click', '#edit', async function (e) {
			mRefresh.refresh();
			let data = tableMutasiMasuk.row($(this).parents('tr')).data();
			let id = data.id;
			if (data.status_pd_tujuan == "approve") {
				document.getElementById("btnApproveMutasiTujuan").classList.add("d-none")
			}else{
				document.getElementById("btnApproveMutasiTujuan").classList.remove("d-none")
			}
			document.getElementById("textIdPengajuanTujuan").value = id;
			const responseDetail = await ApiMutasiPC.getDetailMutasiPD({ id });
			let dataDetail = responseDetail.data;
			if (responseDetail.status_code == 200) {
				document.getElementById("textNameTujuan").value = dataDetail.name;
				document.getElementById("textDariPD_Tujuan").value = dataDetail.daerah_asal;
				document.getElementById("textTujuanDariPD_Tujuan").value = dataDetail.daerah_tujuan;

				document.getElementById("textDariPC_Tujuan").value = dataDetail.cabang_asal;
				document.getElementById("textTujuanDariPC_Tujuan").value = dataDetail.cabang_tujuan;

				document.getElementById("textKTAN_Tujuan").value = dataDetail.ktan;
				document.getElementById("textLulusan_Tujuan").value = dataDetail.lulusan;
				document.getElementById("textTahunLulus_Tujuan").value = dataDetail.tahun_lulus;

				document.getElementById("textNOSTRTTK_Tujuan").value = dataDetail.nomor_strttk;
				document.getElementById("textTempatKerjaPC_Tujuan").value = dataDetail.tempat_kerja_asal;
				document.getElementById("textSaranaTujuanKefarmasian_Tujuan").value = dataDetail.sarana_kefarmasian_tujuan;
				document.getElementById("textDate_Tujuan").value = dataDetail.tanggal_pengajuan;

				$("#persyaratanMutasiTujuan").html('');
				var dokumen = dataDetail.dokumen;
				dokumen.forEach(dataBerkas => {
					var persyaratanSerkom = `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`
					$("#persyaratanMutasiTujuan").append(persyaratanSerkom);
				});
				
				//here
				$("#persyaratanTujuanIn").html('');
				var dokumenOut = dataDetail.dokumen_tujuan;
				dokumenOut.forEach(dataMy => {
					var content = `
						<tr>
							<td>${dataMy.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataMy.dokumen_url}' width="40px"></td>
						</tr>
					`
					$("#persyaratanTujuanIn").append(content);
				});
				document.getElementById("textUserIdKomentar").value = dataDetail.user_id;
				await callComentar();
		}});

		$('#tableDataMutasiMasuk tbody').off('click', '#cetakLolosButuh').on('click', '#cetakLolosButuh', async function (e) {
			e.stopImmediatePropagation()
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tableDataMutasiMasuk').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const downloadRekomSTRTTKPD = await ApiMutasiPC.downloadCetakLolosButuhPD({ id });
			$(this).html('<i class="fas fa-download"></i> Cetak Mutasi)');

		});

		$('#tableDataMutasiMasuk thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataMutasiMasuk thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  tableMutasiMasuk.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});


		const eventReject = async (e) => {
			e.stopImmediatePropagation()
			swal.fire({
				title: 'Reject Mutasi',
				html: "Anda ingin menolak (Reject) REKOM mutasi ini?\ " + document.getElementById("textNameTujuan").value,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya!'
			}).then(async (result) => {
				if (result.value) {
					let id = document.getElementById("textIdPengajuanTujuan").value

					const approvalPcTujuan = await ApiMutasiPC.approvalMutasiPDTujuan({
						idPengajuan: id,
						approval: false
					});

					if (approvalPcTujuan.status_code == '200') {
						swal.fire({
							title: 'Reject Mutasi',
							text: "Data berhasil ditolak",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						await refreshData()
						$('#detailMutasiModalPcTujuan').modal('hide');
					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcTujuan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
						await refreshData();
					}
				};
			});
			e.preventDefault();
		}

		const eventAppove = async (e) => {
			e.stopImmediatePropagation()
			swal.fire({
				title: 'Approve Mutasi',
				html: "Anda ingin menyetujui (Approve) REKOM mutasi ini?\ " + document.getElementById("textNameTujuan").value,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Approve!'
			}).then(async (result) => {
				if (result.value) {
					let id = document.getElementById("textIdPengajuanTujuan").value;
					const approvalPcTujuan = await ApiMutasiPC.approvalMutasiPDTujuan({
						idPengajuan: id,
						approval: true
					});

					if (approvalPcTujuan.status_code == '200') {
						swal.fire({
							title: 'Approve',
							text: "Data berhasil disetujui",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						$('#detailMutasiModalPcTujuan').modal('hide');
						await refreshData();

					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcTujuan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
						await refreshData();
					}
				};
			});

			e.preventDefault();
		}

		document.getElementById("btnApproveMutasiTujuan").addEventListener("click", eventAppove, false)
		document.getElementById("btnRejectMutasiTujuan").addEventListener("click", eventReject, false)


	},

	async getPengurusPd() {
		const statusData = await ApiMutasiPC.getPenandatanganMutasiPD()

		statusData.data.forEach(data => {
			document.getElementById("selectPenandatanganan").add(new Option(`${data.user_name} ${data.jabatan} `, data.id))
		});

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("selectPenandatanganan").value;
			const dataPengurus = await ApiMutasiPC.getPenandatanganMutasiPDById(valueSelected)
			document.getElementById("textNamePenandatangan").value = dataPengurus.data.user_name
			document.getElementById("textKtanPenandatangan").value = dataPengurus.data.ktan
			e.preventDefault();
		}

		document.getElementById("selectPenandatanganan").addEventListener("change", eventChange)
	},

	async accPDAsalInitiator() {
		const eventChange = async (e) => {
			if (document.getElementById("textNomorSurat").value == "" ||
				document.getElementById("textNamePenandatangan").value == "" ||
				document.getElementById("textKtanPenandatangan").value == ""
			) {
				NotificationModal.show(`Mohon Lengkapi data terlebih dahulu`, 'warning');
				return;
			} else {
				//  document.getElementById("btnSimpanMutasi").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			}

			swal.fire({
				title: 'Approve Mutasi',
				html: "Anda ingin Setujui (Approve) REKOM mutasi ini?\ " + document.getElementById("textNameAsal").value,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya!'
			}).then(async (result) => {
				if (result.value) {
					const response = await ApiMutasiPC.accMutasiPDAsal(
						{
							nomor_pd: document.getElementById("textNomorSurat").value,
							nama_penanda_pd: document.getElementById("textNamePenandatangan").value,
							nian_penanda_pd: document.getElementById("textKtanPenandatangan").value,
							id: document.getElementById("textIdPengajuan").value,
						}
					)
					if (response.status_code != 200) {
						NotificationModal.show(`${response.message}`, 'error');
						document.getElementById("btnSimpanMutasi").innerHTML = `<i class='fas fa-edit'></i> Simpan`;
					} else {
						NotificationModal.show(`${response.message}`, 'success');
						await this.getData(null)
						document.getElementById("btnSimpanMutasi").innerHTML = `<i class='fas fa-edit'></i> Simpan`;
						$('#tableDataMutasi').DataTable().ajax.reload();
						$('#detailMutasiModal').modal('hide');
					}
				};
			});
			e.preventDefault();
		}

		const eventReject = async (e) => {
			swal.fire({
				title: 'Reject Mutasi',
				html: "Anda ingin menolak (Reject) REKOM mutasi ini?\ " + document.getElementById("textNameAsal").value,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya!'
			}).then(async (result) => {
				if (result.value) {
					const approvalPcAsal = await ApiMutasiPC.rejectMutasiPDAsal(
						{
							id: document.getElementById("textIdPengajuan").value,
						}
					)
					if (approvalPcAsal.status_code == '200') {
						swal.fire({
							title: 'Reject Mutasi',
							text: "Data berhasil ditolak",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						await this.getData(null)
						await this.getDatain()
						$('#detailMutasiModal').modal('hide');

					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcAsal.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});
			e.preventDefault();
		}

		document.getElementById("btnSimpanMutasi").addEventListener("click", eventChange)
		document.getElementById("btnRejectMutasiAsal").addEventListener("click", eventReject)
	},

	async initKomentar() {
		document.getElementById("content-comment").innerHTML = ''
		let fromPC = await Autentication.getPDMember();

		let getContentAdmin = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			return `
                    <div style="width:100%">
                        <div style="width:10%;" class="float-left">
                            <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                        </div>
                        <div class="float-left text-dark text-left" style="width:88%;">
                            <div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
                                <small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
                                <small>${user.body}</small><br>
                                <div class="text-right">
                                    <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    `
		}
		
		let getContentUser = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
			return `
                    <div style="width:100%">
                        <div style="width:10%;" class="float-right">
                            <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                        </div>
                        <div class="float-right text-dark text-left" style="width:88%;">
                            <div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
                                <small><b>${user.actor.name} [${user.actor.role}  ${ketLocation}]</b></small><hr>
                                <small>${user.body}</small><br>
                                <div class="text-right">
                                    <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    `
		}

		let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
		let dataLocal = JSON.parse(local);
		let UserID = document.getElementById("textUserIdKomentar").value;
		let PengajuanID = $("#textIdPengajuan").val();
		let response = await ApiPengajuanSTRTTKPD.getCommentar(UserID, "rekom_mutasi", PengajuanID)


		let getAllContent = async () => {
			let elmHtml = ``
			await response.data.reverse().forEach(async element => {
				elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
			});
			return elmHtml
		}


		if (response.data == undefined || response.data.length <= 0) {
			document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
		} else {
			document.getElementById("content-comment").innerHTML = await getAllContent();

			setTimeout(() => {
				var elem = document.getElementById('content-comment');
				elem.scrollTop = elem.scrollHeight;
			}, 400);
		}
	},

	async initKomentarTujuan() {
		document.getElementById("content-comment-tujuan").innerHTML = ''
		
		let getContentAdmin = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			return `
                    <div style="width:100%">
                        <div style="width:10%;" class="float-left">
                            <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                        </div>
                        <div class="float-left text-dark text-left" style="width:88%;">
                            <div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
                                <small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
                                <small>${user.body}</small><br>
                                <div class="text-right">
                                    <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    `
		}

		let getContentUser = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
			return `
                    <div style="width:100%">
                        <div style="width:10%;" class="float-right">
                            <img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
                        </div>
                        <div class="float-right text-dark text-left" style="width:88%;">
                            <div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
                                <small><b>${user.actor.name} [${user.actor.role}  ${ketLocation}]</b></small><hr>
                                <small>${user.body}</small><br>
                                <div class="text-right">
                                    <small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    `
		}

		let UserID = document.getElementById("textUserIdKomentar").value;
		let PengajuanID = $("#textIdPengajuanTujuan").val();
		let response = await ApiPengajuanSTRTTKPD.getCommentar(UserID, "rekom_mutasi", PengajuanID)
		let getAllContent = async () => {
			let elmHtml = ``
			await response.data.reverse().forEach(async element => {
				elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
			});
			return elmHtml
		}


		if (response.data == undefined || response.data.length <= 0) {
			document.getElementById("content-comment-tujuan").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
		} else {
			document.getElementById("content-comment-tujuan").innerHTML = await getAllContent();

			setTimeout(() => {
				var elem = document.getElementById('content-comment-tujuan');
				elem.scrollTop = elem.scrollHeight;
			}, 400);
		}
	},

	async initTracking(id, user_id) {
		$("#tableHistoryRekomSIPTTK").dataTable().fnDestroy();
		let table_tracking = await $("#tableHistoryRekomSIPTTK").dataTable({
			"processing": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PC_ENDPOINT.GET_TRACKING_MUTASI_PC(id, user_id)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [

				{ data: 'created_at' },
				{ data: 'actor.name' },
				{ data: 'actor_type' },
				{ data: 'description' },
			],

			"order": [[0, 'desc']],
		});
	}

}



export default RekomMutasiPdPresenter;