import ApiLogin from "../api/data-login.js";
import ApiNotication from "../api/data-notification.js";
import UrlParser from "../routes/url-parser.js";
import Autentication from "../utils/autentication.js";
import NotificationModal from "../utils/initial_notification.js";
import SideBar from "../views/component/side-bar.js";
import NotificationRoutes from '../routes/notification-routes.js';

const LoginInitiator = {

	async init() {
		await this._setView();
		await this._giveEventLogin();
		if (UrlParser._getParameterByName("action") == "login") {
			let alertShow = await Autentication.getAlertLogout()
			if (alertShow == "true") {
				await Autentication.saveAlert(false)
				NotificationModal.show(`Akses login kadaluarsa silahkan login kembali`, 'warning');
			}
		}
	},

	async _setView() {
		document.getElementById('username').focus();
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		document.getElementById('main-content').style.removeProperty("height");
	},

	async _navigateToDashboard() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		window.location.hash = '#/dashboard';
		await this._setNotification();
	},

	async _giveEventLogin() {
		let role = "anggota"

		const eventLogin = async (e) => {
			e.preventDefault();
				document.getElementById("btnLogin").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataLogin = await ApiLogin.Login({
					username: document.getElementById('username').value,
					password: document.getElementById('password').value,
					role:role
				});
				if (dataLogin.status_code != 200) {
					NotificationModal.show(`${dataLogin.message}`, 'error');
					document.getElementById("btnLogin").innerHTML = `Login`;
				} else {
					this._saveData(dataLogin.user) 
					await this._saveToken(dataLogin.data.access_token,dataLogin.data.refresh_token,dataLogin.data.expires_in )
					this._saveRole(dataLogin)
					document.querySelector('app-bar').hideAll = true;
					await SideBar.init()
					 this._navigateToDashboard()
				}
			e.preventDefault();
		}
		document.getElementById('login-user').addEventListener('submit', eventLogin);
		document.getElementById('btnRegister').addEventListener('click',()=>{
			this._navigateToRegister();
		});
		document.getElementById('btnUploadBuktiBayar').addEventListener('click',()=>{
			this._navigateToUploadBuktiBayar();
		});
		document.getElementById('btnForgotPassword').addEventListener('click',()=>{
			this._navigateToForgotPassword();
		})
		document.getElementById('btnResendVerificationEmail').addEventListener('click',()=>{
			this._navigateToResendEmailVerification();
		})
		document.getElementById('btnResendPaymentRegist').addEventListener('click',()=>{
			this._navigateToResendPayment();
		})
	},


	_navigateToRegister(){
		window.location.hash = '#/register';
	},
	_navigateToUploadBuktiBayar(){
		window.location.hash = '#/upload-bukti-bayar-registrasi';
	},
	
	_navigateToForgotPassword(){
		window.location.hash = '#/forgot-password';
	},
	_navigateToResendEmailVerification(){
		window.location.hash = '#/resend-verification';
	},
	_navigateToResendPayment(){
		window.location.hash = '#/resend-payment';
	},

	_removeActiveRole() {
		document.getElementById('RoleAnggota').classList.remove('active');
		document.getElementById('RolePp').classList.remove('active');
		document.getElementById('RolePd').classList.remove('active');
		document.getElementById('RolePc').classList.remove('active');

	},

	async _setNameAppBar(){
		const datas = await Autentication.getData();

		datas.forEach(data => {
			document.querySelector('app-bar').setName = data;
		});
		document.getElementById("containerNotificationPC").style.display = 'none';
        document.getElementById("containerNotificationPP").style.display = 'none';
        document.getElementById("containerNotificationPD").style.display = 'none';
	},

	_saveData(dataUser){
		Autentication.saveData(dataUser)
		this._setNameAppBar()
	},

	async _saveToken(token , refreshToken , expireIn){
		await Autentication.saveToken(token , expireIn)
		await Autentication.saveRefreshToken(refreshToken)
	},

	_saveRole(data){
		const roleData = [];
		data.user.role.forEach(role => {
			roleData.push(role.level_name);
		});
		Autentication.saveRole(roleData)
	},

	async _setNotification(){
		const isLogin = await Autentication.isLogin(); 
		if (isLogin) {
		  let role = await Autentication.getRole() 
		  if (role != null) {
			for (let index = 0; index < role.length; index++) {
			  const value = role[index];
			  if (value == "pp") {
				this._setUpNotificationPP()
			  }else if (value == "anggota") {
				this._setUpNotificationAnggota()
			  }
			  else if (value == "pd") {
				this._setUpNotificationPD()
			  }
			  else if (value == "pc") {
				this._setUpNotificationPC()
			  }
			}
		  }
		}
	  },

	  async _setUpNotificationAnggota(){   
		try {
		  document.getElementById("drop-notification").innerHTML = "";
		  const dataUnreadNotification = await ApiNotication.getUnreadNotification();
		  let item = ``
		  const dataNotification = dataUnreadNotification.data;
		  let idElmNotification = 0
		  dataNotification.forEach(data => {
			idElmNotification++
			let timeCreated =  new Date(data.created_at).toLocaleDateString('en-US') + " " + new Date(data.created_at).toLocaleTimeString('en-US') ;
			if (this.isValidDate(timeCreated) == false) {
			  timeCreated = data.created_at
			}
			let title =data.data.title;
			let content = data.data.body;
			let action = NotificationRoutes.getRouting(data.notifiable_level, data.data.type);
			item +=  ` <a class="dropdown-item d-flex align-items-center" id="read_action${idElmNotification}"  href="${action}">
						  <div class="mr-3">
							  <div class="icon-circle bg-primary">
								  <i class="fas fa-file-alt text-white"></i>
							  </div>
						  </div>
						  <div>
							  <div class="small text-gray-500">${timeCreated}</div>
							  <div class="font-weight-bold">${title}</div>
							  <span class="font-weight-light">${content}</span>
						  </div>
					  </a>`
		  }); 
	
		  document.getElementById("drop-notification").innerHTML = item;
		  let idElmNotificationAction = 0
		  dataNotification.forEach(data =>{
			idElmNotificationAction++
			document.getElementById('read_action'+idElmNotificationAction).addEventListener('click', async ()=>{
			 await this._readNotification(data.id)
			}); 
		   })  
		} catch (error) {
		  
		}
	  },
  
	  async _readNotification(notificationID){	
		  let request = await ApiNotication.readNotification(notificationID);
		 
		  if (request.status_code == 200) {
			  await this._setUpNotificationAnggota()
		  }else{
			  console.log(`"error status notification ${request.status_code}  ${request.message}"`)
		  }
		},
  
	  async _setUpNotificationPC(){
		document.getElementById("containerNotificationPC").style.display = '';
	   
		const dataUnreadNotification = await ApiNotication.getNotificationPC();
		if (dataUnreadNotification.status_code == 200) {
		  const dataNotification = dataUnreadNotification.data;
		  document.getElementById("counter_notif_pc_permohonan_serkom_skp").innerHTML = dataNotification.permohonan_serkom_skp
		  document.getElementById("counter_notif_pc_permohonan_rekom_strttk").innerHTML = dataNotification.permohonan_rekom_strttk
		  document.getElementById("counter_notif_pc_permohonan_rekom_sipttkk").innerHTML = dataNotification.permohonan_rekom_sipttk
		  document.getElementById("counter_notif_pc_permohonan_skp").innerHTML = dataNotification.permohonan_skp
		  document.getElementById("counter_notif_pc_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
		  document.getElementById("counter_notif_pc_permohonan_mutasi_in").innerHTML = dataNotification.permohonan_mutasi_in
		  document.getElementById("counter_notif_pc_permohonan_mutasi_out").innerHTML = dataNotification.permohonan_mutasi_out
		  document.getElementById("counter_notif_pc_user_membership_unpaid").innerHTML = dataNotification.user_membership_unpaid
		  document.getElementById("counter_notif_pc_kecukupan_skp").innerHTML = dataNotification.permohonan_kecukupan_skp
		  document.getElementById("counter_notif_pc").innerHTML = dataNotification.permohonan_skp + dataNotification.permohonan_rekom_sipttk + dataNotification.permohonan_rekom_strttk + dataNotification.permohonan_serkom_skp + dataNotification.permohonan_ktan
	  }},
  
	  async _setUpNotificationPD(){
		document.getElementById("containerNotificationPD").style.display = '';
		const dataUnreadNotification = await ApiNotication.getNotificationPD();
		if (dataUnreadNotification.status_code == 200) {
		  const dataNotification = dataUnreadNotification.data;
		  document.getElementById("counter_notif_pd_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
		  document.getElementById("counter_notif_pd_permohonan_serkom_skp").innerHTML = dataNotification.permohonan_serkom_skp
		  document.getElementById("counter_notif_pd_permohonan_rekom_strttk").innerHTML = dataNotification.permohonan_rekom_strttk
		  document.getElementById("counter_notif_pd_permohonan_skp").innerHTML = dataNotification.permohonan_skp
		  document.getElementById("counter_notif_pd_permohonan_mutasi_in").innerHTML = dataNotification.permohonan_mutasi_in
		  document.getElementById("counter_notif_pd_permohonan_mutasi_out").innerHTML = dataNotification.permohonan_mutasi_out
		  document.getElementById("counter_notif_pd_seminar_in").innerHTML = dataNotification.permohonan_seminar_in
		  document.getElementById("counter_notif_pd_seminar_payment").innerHTML = dataNotification.pembayaran_seminar_need_confirmed
		  document.getElementById("counter_notif_pd_kecukupan_skp").innerHTML = dataNotification.permohonan_kecukupan_skp
		  document.getElementById("counter_notif_pd").innerHTML = dataNotification.permohonan_ktan + dataNotification.permohonan_serkom_skp + dataNotification.permohonan_rekom_strttk + dataNotification.permohonan_skp + dataNotification.permohonan_seminar_in + dataNotification.pembayaran_seminar_need_confirmed
  
	  }},
  
	  async _setUpNotificationPP(){
		document.getElementById("containerNotificationPP").style.display = '';
		const dataUnreadNotification = await ApiNotication.getNotificationPP();
		if (dataUnreadNotification.status_code == 200) {
		  const dataNotification = dataUnreadNotification.data;
		  document.getElementById("counter_notif_pp_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
		  document.getElementById("counter_notif_pp_register_need_confirmed").innerHTML = dataNotification.register_need_confirmed
		  document.getElementById("counter_notif_pp_seminar_in").innerHTML = dataNotification.permohonan_seminar_in
		  document.getElementById("counter_notif_pp_seminar_payment").innerHTML = dataNotification.pembayaran_seminar_need_confirmed
		  document.getElementById("counter_notif_pp_seminar_agreement").innerHTML = dataNotification.seminar_unsigned
		  document.getElementById("counter_notif_pp").innerHTML = dataNotification.register_need_confirmed + dataNotification.permohonan_ktan + dataNotification.permohonan_seminar_in + dataNotification.pembayaran_seminar_need_confirmed + dataNotification.seminar_unsigned
  
	  }}

}

export default LoginInitiator;
