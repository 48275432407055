/* eslint-disable new-cap */
import API_ENDPOINT from '../config/globals/endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class DataFile {

	static async uploadImageProfile({
		imageKtp,
		imagePhotoAvatar,
		imagePembayaran,
		imageKtan,
		imageIjazah,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token}`);
        
		var formdata = new FormData();
        formdata.append("_method", "PUT");
		if (imagePhotoAvatar != null) {
			formdata.append("avatar", imagePhotoAvatar);
		}
		if (imageKtp != null) {
			formdata.append("ktp_photo", imageKtp);
		}
		if (imagePembayaran != null) {
			formdata.append("bukti_bayar_ktan", imagePembayaran);
		}
		if (imageKtan != null) {
			formdata.append("old_ktan", imageKtan);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPLOAD_MEDIA_PROFILE, {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
}

export default DataFile;
