import UrlParser from '../routes/url-parser.js';

const NotYourTimePresenter = {

	async init() {
        await this._setUpViewNotYourTime();
		await this._setView();
	},

	async _setView() {
        document.getElementById("main-content").style.height = "100vh";
        document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},

	async _setUpViewNotYourTime(){
        let key = UrlParser._getParameterByName("note")
        console.log(key)
        if (key != null) {
            document.getElementById('noteMessage').textContent = key
        }
       
	},

}

export default NotYourTimePresenter;
