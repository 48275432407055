import ForgotPasswordInitiatior from "../../presenter/forgot-password-presenter.js";

const ForgotPassword = {
    async render() {
            return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-10 col-lg-12 col-md-12">
      
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-5 p-3 d-lg-block ">
                                    <div class="text-center">
                                        <img loading="lazy" src="./src/public/img/logo.png" width="30%">
                                    </div>
                                    <div class="text-left">
                                        <h1 class="h4 text-center text-gray-900 mb-4">Petunjuk Reset Password</h1>
                                        <ol>
                                            <li>Isi Alamat email anda yang anda gunakan untuk registrasi sebelumnya.</li>
                                        </ol>
                                        <hr>
                                        <h1 class="h4 text-center text-gray-900 mb-4">Catatan Penting !</h1>
                                        <ol>
                                            <li>Link reset password akan kami kirim ke alamat email anda,anda bisa buka inbox email anda dan jika tidak ada email di inbox, silahkan periksa di folder spam email anda.</li>
                                            <li>Setelah masuk email, ikuti langkah panduan yang kami kirim via email tersebut</li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">Lupa Password !</h1>
                                            <p>Jika ada kesulitan bisa kontak kami di<br>
                                            Kontak Telp/WA: +62 822-2046-7605<br>
                                            Email: helpdesk.pafi@gmail.com</p>
                                        </div>
                                       
                                        <form class="user" class="form-horizontal" id='reset-password'>
                                            <div class="form-group">
                                                <input type="email" name="email" class="form-control form-control-user" required id="resetPasswordEmail" aria-describedby="email" placeholder="Masukkan Email...">
                                            </div>
                                            <hr>
      
                                            <button id="btnResetPassword" type="submit" class="btn btn-primary btn-user btn-block">Kirim Link Reset Password</button>
                                            <a id="btnLogin" class="btn btn-google btn-user btn-block">Kembali Ke Login</a>
                                            <hr>
                                            <a id="btnHome" class="btn btn-dark btn-user btn-block">Kembali Ke Home</a>
                                        </form>
                                            
                                        
                                        <hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await ForgotPasswordInitiatior.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default ForgotPassword;
  

