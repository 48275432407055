import ApiResetPassword from "../api/reset-password.js";
import Autentication from "../utils/autentication.js";
import NotificationModal from "../utils/initial_notification.js";

const ResetPasswordInitiator = {

	async init() {
		await this._setView();
		await this._giveEventResetPassword();

	},

	async _setView() {
		document.getElementById('email').focus();
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		document.getElementById('main-content').style.removeProperty("height");
	},

	async _giveEventResetPassword() {
		
		

		const eventResetPassword = async (e) => {
			e.preventDefault();
				
				document.getElementById("btnResetPassword").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const url = window.location.hash;

				const urlsSplits = url.split('=');

				const getToken = urlsSplits[1];
				
				const dataResetPassword = await ApiResetPassword.ResetPassword({
					email: document.getElementById('email').value,
					password: document.getElementById('resetPasswordNew').value,
					password_confirmation: document.getElementById('resetPasswordConfirmation').value,
					token:getToken
				});

				if (dataResetPassword.status_code != 200) {
					NotificationModal.show(`${dataResetPassword.message}`, 'error');
					document.getElementById("btnResetPassword").innerHTML = `Ubah Password`;
				} else {
					NotificationModal.show(`${dataResetPassword.message}`, 'success');
					this._navigateToLogin()
				}
			e.preventDefault();
		}
			document.getElementById('reset-password').addEventListener('submit', eventResetPassword);
			document.getElementById('btnLogin').addEventListener('click',()=>{
				this._navigateToLogin();
			});
			document.getElementById('btnShowPasswordNew').addEventListener('click',()=>{
				this._showPassword();
			});
			document.getElementById('btnShowPasswordConfirmationNew').addEventListener('click',()=>{
				this._showPasswordConfirm();
			});
	},


	_navigateToLogin(){
		window.location.hash = '#/login';
	},

	async _setNameAppBar(){
		const datas = await Autentication.getData();

		datas.forEach(data => {
			document.querySelector('app-bar').setName = data;
		});
	},

	_showPassword(){
		var x = document.getElementById("resetPasswordNew");
        var show_eye = document.getElementById("show_eye");
        var hide_eye = document.getElementById("hide_eye");
        hide_eye.classList.remove("d-none");
        if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
        } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
        }
	},
	_showPasswordConfirm(){
		var x = document.getElementById("resetPasswordConfirmation");
        var show_eye = document.getElementById("show_eye_confirm");
        var hide_eye = document.getElementById("hide_eye_confirm");
        hide_eye.classList.remove("d-none");
        if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
        } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
        }
	}
}

export default ResetPasswordInitiator;
