import CONFIG from './config.js';

const BERITA_END_POINT = {
	SEND_BERITA: `${CONFIG.BASE_URL}beritas/pp`,
	EDIT_BERITA: (id) => `${CONFIG.BASE_URL}beritas/pp/${id}`,
	GET_BERITA: (status, start_date, end_date) => {
		let url = `${CONFIG.BASE_URL}beritas/pp/list?&status_berita=${status}&`;
	
		if (start_date !== null) {
			url += `start_date=${start_date}&`;
		}
	
		if (end_date !== null) {
			url += `end_date=${end_date}&`;
		}
	
		return url;
	},
	BERITA_WITH_ID: (id) => `${CONFIG.BASE_URL}beritas/${id}`,
	CHANGE_STATUS_BERITA: (id) => `${CONFIG.BASE_URL}beritas/status/${id}`,

	// edaran
	SEND_EDARAN: `${CONFIG.BASE_URL}edarans/pp`,
	EDIT_EDARAN: (id) => `${CONFIG.BASE_URL}edarans/pp/${id}`,
	GET_EDARAN: (status, start_date, end_date) => {
		let url = `${CONFIG.BASE_URL}edarans/pp/list?&status_edaran=${status}&`;
	
		if (start_date !== null) {
			url += `start_date=${start_date}&`;
		}
	
		if (end_date !== null) {
			url += `end_date=${end_date}&`;
		}
	
		return url;
	},
	EDARAN_WITH_ID: (id) => `${CONFIG.BASE_URL}edarans/${id}`,
	CHANGE_STATUS_EDARAN: (id) => `${CONFIG.BASE_URL}edarans/status/${id}`,

};

export default BERITA_END_POINT;
