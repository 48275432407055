
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiKepengurusanPD {

	static async getAnggota({
		status_regis,
		status_ktan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.ANGGOTA(status_regis,status_ktan),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

    static async getPeriode (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PERIODE_KEPENGURUSAN,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getPeriodeByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PERIODE_KEPENGURUSAN_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async createPeriode ({
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PERIODE_KEPENGURUSAN,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    
    static async updatePeriode ({
        id,
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERIODE_KEPENGURUSAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async deletePeriode ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.DELETE_PERIODE_KEPENGURUSAN(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
    static async getPengurus (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PENGURUS,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async getPengurusByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PENGURUS_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async createPengurus ({
		periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PENGURUS,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async updatePengurus ({
		id,
        periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PENGURUS(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async deletePengurus ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.DELETE_PENGURUS(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    static async updateTTD ({
        id,
		dokumen
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", "ttd");
		formdata.append("document", dokumen);

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_TTD(id),{
				method : "POST",
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
    static async getSetting (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.GENERAL_SETTING,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateSetting ({
		nama_kota,
        alamat_cabang,
		informasi_umum,
		telpon,
		email,
		faksimil,
		kode_pos,
		url_maps,
		informasi_rekening,
		ttd_digital
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "nama_kota": nama_kota,
			"address": alamat_cabang,
			"information": informasi_umum,
			"telp": telpon,
			"email": email,
			"fak": faksimil,
			"kode_pos": kode_pos,
			"maps_url": url_maps,
			"informasi_rekening": informasi_rekening,
			"ttd_digital_active": ttd_digital,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.GENERAL_SETTING,{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	// static async getInformationSerkom (){
	// 	// console.log(data);
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SERKOM_INFORMATION,{
	// 			method : "GET",
	// 			headers: myHeaders,
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	// static async updateSyaratSerkom ({
	// 	id,
	// 	name,
	// 	description
	// }){
	// 	// console.log(data);
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	var raw = JSON.stringify({
	// 		"name": name,
	// 		"description": description,
	// 	  });
	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
	// 			method : "PUT",
	// 			headers: myHeaders,
    //             body : raw
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	// static async deleteSyaratSerkom ({
	// 	id
	// }){
	// 	// console.log(data);
	// 	console.log(id)
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
	// 			method : "DELETE",
	// 			headers: myHeaders,
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	
}


export default ApiKepengurusanPD;