import RekomMutasiPdPresenter from "../../../presenter/menu_pengurus_pd/rekom-mutasi-pd-presenter.js";
import ImagePopUPInitiator from "../../../presenter/pop-up-presenter.js";


const RekomMutasiPd = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<button href="#tabProfilPendidikan2" class="nav-link hapus-border active" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan2" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan2" aria-selected="false">Data [Pindah Ke PD Anda]</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Data [Keluar ke PD Lain]</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
				

					<div class="tab-pane fade show " id="tabProfilPendidikan"  role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
							Data Pindah Ke PD Lain
							</div>
							<div class="card-body ">
								<div class="table-responsive">
								<div class="form-group row col-sm-6">
										<label class="control-label col-sm-6 font-weight-bold">Filter</label>
										<select class="form-control" style="margin-left:10px;" id="selectFilterData" name="pd_tujuan" required>
											<option value="1">Belum Acc PD Asal</option>
											<option value="2">Sudah Acc PD Asal</option>
											<option value="3">Belum Acc PD Tujuan</option>
											<option value="4">Sudah Acc PD Tujuan</option>
											<option value="all">Semua</option>
										</select>
									</div>
									<table class="table table-bordered table-hover" id="tableDataMutasi" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th rowspan="2">No</th>
												<th data-column="name" rowspan="2">Nama</th>
												<th data-column="created_at" rowspan="2">Tanggal Pengajuan</th>
												<th colspan="2">Asal</th>
												<th colspan="2">Tujuan</th>
												<th data-column="status_pc_asal" rowspan="2">STATUS PC ASAL</th>
												<th data-column="status_pd_asal"rowspan="2">STATUS PD ASAL</th>
												<th data-column="status_pd_tujuan" rowspan="2">STATUS PD Tujuan</th>
												<th data-column="status_pc_tujuan"rowspan="2">STATUS PC Tujuan</th>
												<th rowspan="2">Aksi</th>
												<th rowspan="2">Data</th>
											</tr>
											<tr>
												<th>PC</th>
												<th>PD</th>
												<th>PD</th>
												<th>PC</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
                                    	</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>


					<div class="tab-pane fade show active" id="tabProfilPendidikan2"  role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pindah Ke PD Anda
							</div>
							<div class="card-body ">
								<div class="table-responsive">
								<div class="form-group row col-sm-6">
										<label class="control-label col-sm-6 font-weight-bold">Filter</label>
										<select class="form-control" style="margin-left:10px;" id="selectFilterData2" name="pd_tujuan" required>
											<option value="3">Belum Acc PD Tujuan</option>
											<option value="4">Sudah Acc PD Tujuan</option>
											<option value="1">Belum Acc PD Asal</option>
											<option value="2">Sudah Acc PD Asal</option>
											<option value="all">Semua</option>
										</select>
									</div>
									<table class="table table-bordered table-hover" id="tableDataMutasiMasuk" width="100%" cellspacing="0">
										<thead>
											<tr>
											    <th rowspan="2">No</th>
												<th data-column="name" rowspan="2">Nama</th>
												<th data-column="created_at" rowspan="2">Tanggal Pengajuan</th>
												<th colspan="2">Asal</th>
												<th colspan="2">Tujuan</th>
												<th data-column="status_pc_asal" rowspan="2">STATUS PC ASAL</th>
												<th data-column="status_pd_asal"rowspan="2">STATUS PD ASAL</th>
												<th data-column="status_pd_tujuan" rowspan="2">STATUS PD Tujuan</th>
												<th data-column="status_pc_tujuan"rowspan="2">STATUS PC Tujuan</th>
												<th rowspan="2">Aksi</th>
												<th rowspan="2">Data</th>
											</tr>
											<tr>
												<th>PC</th>
												<th>PD</th>
												<th>PD</th>
												<th>PC</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
                                    	</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

				
		
		<div class="modal fade" id="detailMutasiModal" tabindex="-1" role="dialog" aria-labelledby="detailSerkomModal"
		aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="">Detail Pengajuan Mutasi</h5>
					<button class="close" type="button" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body" id="bodyModalSerkom">
				<div class="row mt-3">
						<div class="col-lg-12 col-xl-6">

						<div class="card">
								<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
									<h6 class="m-0 font-weight-bold text-primary">Formulir ACC PD</h6>
								</div>

								<div class="card-body ">
								<form id="form-mutasi">
								<input type="hidden" placeholder="" readonly required class="form-control" id="textUserIdKomentar">
								<input type="hidden" placeholder="" readonly required class="form-control" id="textIdPengajuan">
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Nomor</label>
											<div class="col-sm-12">
												<input type="text" name="no_strttk" placeholder="Nomor" class="form-control" id="textNomorSurat">
											</div>
										</div>
									
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Pilih Penandatangan</label>
											<div class="col-sm-12">
												<select class="form-control" id="selectPenandatanganan" name="pd_tujuan" required>
													<option value="">Pilih Salah Satu</option>
												</select>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Nama Penandatangan</label>
												<div class="col-sm-12">
													<input type="text" name="no_strttk" placeholder="" readonly required class="form-control" id="textNamePenandatangan">
												</div>
										</div>

										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">KTAN Penandatangan</label>
											<div class="col-sm-12">
											<input type="text" name="no_strttk" placeholder="" readonly required class="form-control" id="textKtanPenandatangan">
											</div>
										</div>

										
										</form>
								</div>

						</div>		
							<div class="card mt-3">
								<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
									<h6 class="m-0 font-weight-bold text-primary">Formulir Mutasi/Lolos Butuh</h6>
								</div>
								<div class="card-body ">
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Nama</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pd" placeholder="..." class="form-control" required id="textNameAsal" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Daerah</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pd" placeholder="Dari Pengurus Daerah..." class="form-control" required id="textDariPD" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Cabang</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pc" placeholder="Dari Pengurus Cabang..." class="form-control" required id="textDariPC" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
											<div class="col-sm-12">
												<input type="text" name="ktan" placeholder="KTAN..." class="form-control" required readonly id="textKTAN">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Lulusan</label>
											<div class="col-sm-12">
												<input type="text" name="lulusan" placeholder="Lulusan..." class="form-control" required id="textLulusan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tahun Lulus</label>
											<div class="col-sm-12">
												<input type="number" name="tahun_lulus" placeholder="Tahun Lulus..." class="form-control" required id="textTahunLulus">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">No STRTTK</label>
											<div class="col-sm-12">
												<input type="text" name="no_strttk" placeholder="No STRTTK..." class="form-control" readonly required id="textNOSTRTTK">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tempat Kerja Saat di [PC]</label>
											<div class="col-sm-12">
												<input type="text" name="pd" placeholder="Tempa Kerja Saat di [PC]..." class="form-control" readonly required id="textTempatKerjaPC">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Sarana Tujuan Kefarmasian</label>
											<div class="col-sm-12">
												<input type="text" name="pd" placeholder="Sarana Tujuan Kefarmasian..." class="form-control" readonly required id="textSaranaTujuanKefarmasian">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Pindah ke Pengurus Daerah Provinsi</label>
											<div class="col-sm-12">
												<select class="form-control" id="selectProvinsiTujuan" name="pd_tujuan"  readonly required>
													<option value="">Pilih Salah Satu</option>
												</select>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Pindah ke Pengurus Cabang Kabupaten / Kota</label>
											<div class="col-sm-12">
												<select class="form-control" readonly id="selectKotaTujuan" name="pc_tujuan" required>
													<option value="">Pilih Salah Satu</option>
												</select>
											</div>
										</div>
									
										<div class="form-group row">
											<div class="col-sm-12 mb-1">
												<button type="button" class="form-control btn btn-primary btn-sm" id="btnSimpanMutasi"><i class="fas fa-save"></i> Simpan [APPROVE]</button>
											</div>
										</div>

										<div class="form-group row">
											<div class="col-sm-12 mb-1">
												<button type="button" class="form-control btn btn-danger btn-sm" id="btnRejectMutasiAsal"><i class="fas fa-save"></i> Reject Mutasi</button>
											</div>
										</div>
								</div>
							</div>
						</div>

						

						<div class="col-lg-12 col-xl-6">
							<div class="card">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
									<h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
								</div>
								<div class="card-body chat-body p-1" id="lamanChat">
									<div class="row p-3 chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<div class="shimmer circle"></div>
											</div>
											<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line end"></div>
											</div>
										</div>
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<div class="shimmer circle"></div>
											</div>
											<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line end"></div>
											</div>
										</div>
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<div class="shimmer circle"></div>
											</div>
											<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line end"></div>
											</div>
										</div>
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<div class="shimmer circle"></div>
											</div>
											<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line"></div>
												<div class="shimmer content-line end"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="card-footer mb-3">
									<form id="form-comment">
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Komentar</label>
											<div class="col-sm-12">
												<textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
											</div>
										</div>
										<div class="form-group row">
											<div class="col-sm-12">
												<button type="submit" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
											</div>
										</div>
									</form>
								</div>

							
							</div>

							<div class="card mt-3">
								<div class="card-header text-primary font-weight-bold">
									Persyaratan Berkas
								</div>
								<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
								<div class="col-sm-12">
									<label class="label control-label"><strong>Data Persyaratan Asal</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanSerkom">

												</tbody>
											</table>
										</div>
									</div>

									<label class="label control-label"><strong>Data Persyaratan Tujuan</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanTujuanOut">

												</tbody>
											</table>
										</div>
									</div>
								</div>
								</div>
							</div>
							<div class="card mt-3">
								<div class="card-header text-primary font-weight-bold">
									Riwayat Permohonan Mutasi
								</div>
								<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
									<div class="table-responsive">
										<table class="table table-bordered table-hover" id="tableHistoryRekomSIPTTK" width="100%" cellspacing="0">
											<thead>
												<tr>
													<th>Tanggal</th>
													<th>Nama</th>
													<th>Hak</th>
													<th>Keterangan</th>
												</tr>
											</thead>
											<tfoot>
												
											</tfoot>
											<tbody>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>		
	
		<div class="modal fade" id="detailMutasiModalPdTujuan" tabindex="-1" role="dialog" aria-labelledby="detailSerkomModal"
		aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="">Detail Pengajuan Mutasi</h5>
					<button class="close" type="button" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body" id="bodyModalSerkom">
				<div class="row mt-3">
						<div class="col-lg-12 col-xl-6">
							<div class="card mt-3">
								<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
									<h6 class="m-0 font-weight-bold text-primary">Formulir Mutasi/Lolos Butuh</h6>
								</div>
								<input type="hidden" placeholder="" readonly required class="form-control" id="textIdPengajuanTujuan">
								<div class="card-body ">
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Nama</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pd" placeholder="Nama" class="form-control" required id="textNameTujuan" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Daerah</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pd" placeholder="Dari Pengurus Daerah..." class="form-control" required id="textDariPD_Tujuan" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tujuan Pengurus Daerah</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pd" placeholder="Tujuan Pengurus Daerah..." class="form-control" required id="textTujuanDariPD_Tujuan" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Cabang</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pc" placeholder="Dari Pengurus Cabang..." class="form-control" required id="textDariPC_Tujuan" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tujuan Ke Pengurus Cabang</label>
											<div class="col-sm-12">
												<input type="text" name="dari_pc" placeholder=".." class="form-control" required id="textTujuanDariPC_Tujuan" readonly>
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
											<div class="col-sm-12">
												<input type="text" name="ktan" placeholder="KTAN..." class="form-control" required readonly id="textKTAN_Tujuan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Lulusan</label>
											<div class="col-sm-12">
												<input type="text" name="lulusan" placeholder="Lulusan..." class="form-control" readonly required id="textLulusan_Tujuan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tahun Lulus</label>
											<div class="col-sm-12">
												<input type="number" name="tahun_lulus" placeholder="Tahun Lulus..." class="form-control" readonly required id="textTahunLulus_Tujuan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">No STRTTK</label>
											<div class="col-sm-12">
												<input type="text" name="no_strttk" placeholder="No STRTTK..." class="form-control" readonly required id="textNOSTRTTK_Tujuan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tempat Kerja Saat di [PC]</label>
											<div class="col-sm-12">
												<input type="text" name="pd" placeholder="Tempa Kerja Saat di [PC]..." class="form-control" readonly required id="textTempatKerjaPC_Tujuan">
											</div>
										</div>
										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Sarana Tujuan Kefarmasian</label>
											<div class="col-sm-12">
												<input type="text" name="pd" placeholder="Sarana Tujuan Kefarmasian..." class="form-control" readonly required id="textSaranaTujuanKefarmasian_Tujuan">
											</div>
										</div>

										<div class="form-group row">
											<label class="control-label col-sm-12 font-weight-bold">Tanggal Pengajuan</label>
											<div class="col-sm-12">
												<input type="text" name="pd" placeholder="Sarana Tujuan Kefarmasian..." class="form-control" readonly required id="textDate_Tujuan">
											</div>
										</div>

								</div>
							</div>
						</div>

						

						<div class="col-lg-12 col-xl-6">
						<div class="card">
						<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
								<h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
						</div>
						<div class="card-body chat-body p-1" id="lamanChatTujuan">
							<div class="row p-3 chat-body" id="content-comment-tujuan"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<div class="shimmer circle"></div>
										</div>
										<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line end"></div>
										</div>
									</div>
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<div class="shimmer circle"></div>
										</div>
										<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line end"></div>
										</div>
									</div>
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<div class="shimmer circle"></div>
										</div>
										<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line end"></div>
										</div>
									</div>
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<div class="shimmer circle"></div>
										</div>
										<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line"></div>
											<div class="shimmer content-line end"></div>
										</div>
									</div>
								</div>
							</div>

							<div class="card-footer mb-3">
								<form id="form-comment-tujuan">
									<div class="form-group row">
										<label class="control-label col-sm-12 font-weight-bold">Komentar</label>
										<div class="col-sm-12">
											<textarea class="form-control" id="textKomentarTujuan" name="komentar" placeholder="Komentar..." required></textarea>
										</div>
									</div>
									<div class="form-group row">
										<div class="col-sm-12">
											<button type="submit" class="form-control btn btn-sm btn-primary" id="btnSendCommentTujuan"><i class='fas fa-comment'></i> Kirim</button>
										</div>
									</div>
								</form>
							</div>
						</div>
							<div class="card mt-3">
								<div class="card-header text-primary font-weight-bold">
									Persyaratan Berkas
								</div>
								<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
								<div class="col-sm-12">
									<label class="label control-label"><strong>Data Persyaratan Asal</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanMutasiTujuan">

												</tbody>
											</table>
										</div>
									</div>
									
									<label class="label control-label"><strong>Data Persyaratan Tujuan</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanTujuanIn">

												</tbody>
											</table>
										</div>
									</div>

								</div>
								</div>
							</div>

							<div class="card mt-3">
							<div class="card-header text-primary font-weight-bold">
								Aksi
							</div>
							<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
							<div class="col-sm-12">
								<label class="label control-label"><strong></strong></label>
								
								<div class="col-sm-12">
									<div class="form-group row">
										<div class="col-sm-12 mb-1">
											<button type="button" class="form-control btn btn-primary btn-sm" id="btnApproveMutasiTujuan"><i class="fas fa-save"></i> Simpan [SETUJUI APPROVE]</button>
										</div>
									</div>
			
									<div class="form-group row">
										<div class="col-sm-12 mb-1">
											<button type="button" class="form-control btn btn-danger btn-sm" id="btnRejectMutasiTujuan"><i class="fas fa-save"></i> Reject Mutasi</button>
										</div>
									</div>
								</div>
							</div>
							</div>
						</div>


							
							
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>

	</div>
		
		
		
		
		`;
		return view;
	  },

	async afterRender() {
		await RekomMutasiPdPresenter.init()
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default RekomMutasiPd;