import ApiMutasiPC from "../../api/api_pengurus_pc/data-mutasi.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiNotication from "../../api/data-notification.js";
import ApiRegister from "../../api/data-register.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PC_ENDPOINT from "../../config/globals/menu_pengurus_pc_endpoint.js";
import UrlParser from "../../routes/url-parser.js";
import NotificationModal from "../../utils/initial_notification.js";
import MembershipHelper from "../../utils/membership-helper.js";


const PengajuanMutasiPresenter = {

	async init() {
	//	await MembershipHelper.scyncMembershipHelper();
		await this._setView();
		await this._getInformationMutasi();
		await this.initProvinceValue();
		await this._getDataUser()
		await this._setUpInsertMutasi()
		await this._initDataMutasi()
		await this._uploadBerkas()
		await this._readNotification()
		await this.initSendCommentar()
		await this._getFormulir();
	},

	async initSendCommentar() {
		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			let PengajuanIDValue = document.getElementById("hiddenIdPengajuan").value;
			let commentFor = 'rekom_mutasi';
			if (UserID != '' && PengajuanIDValue != '') {
				document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let content = document.getElementById("textKomentar").value
				const response = await ApiAnggota.sendCommentar({
					pengajuanID : PengajuanIDValue,
					contentComment : content,
					commentFor : commentFor
				});
				if (response.status_code == 200 || 201) {

					let getContentAdmin = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
						return `
								<div style="width:100%">
									<div style="width:10%;" class="float-left">
										<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
									</div>
									<div class="float-left text-dark text-left" style="width:88%;">
										<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
											<small><b>${user.actor.name} [${user.actor.role} ${ketLocation}]</b></small><hr>
											<small>${user.body}</small><br>
											<div class="text-right">
												<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
											</div>
										</div>
									</div>
								</div>
								`
					}

					let getContentUser = (user) => {
						let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
						
						return `
								<div style="width:100%">
									<div style="width:10%;" class="float-right">
										<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
									</div>
									<div class="float-right text-dark text-left" style="width:88%;">
										<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
											<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
											<small>${user.body}</small><br>
											<div class="text-right">
												<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
											</div>
										</div>
									</div>
								</div>
								`
					}


					let response = await ApiAnggota.getCommentar(PengajuanIDValue, "rekom_mutasi", 1)

					let getAllContent = async () => {
						let elmHtml = ``
						await response.data.reverse().forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}

					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
					document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentar").val('');
				} else {
					document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
					$("#textKomentar").val('');
				}
				e.preventDefault();

			}
		}
		document.getElementById("form-comment").addEventListener("submit", eventSendCommentar, false)
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},

	async _getFormulir(){
		var response =await ApiAnggota.getFormulirMutasi()
		
		if (response.status_code == 200) {
			var berkas = response.data.berkas;

			berkas.forEach(dataBerkas => {
				let option= new Option(dataBerkas.name, dataBerkas.document_name)
				option.setAttribute("description-lng",dataBerkas.description)
				document.getElementById("selectBerkas").add(option)
			});
		}
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
			const eventChangeBerkas= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas").classList.remove("d-none")
					document.getElementById("valueAlertBerkas").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas").addEventListener("change", eventChangeBerkas);

			const eventChangeBerkas2= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas2")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas2").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas2").classList.remove("d-none")
					document.getElementById("valueAlertBerkas2").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas2").addEventListener("change", eventChangeBerkas2);
		} else {
			window.location.hash = '#/not-access-fitur';
		}

	},

	async _getInformationMutasi() {
		let data = await ApiMutasiPC.getInformationMutasi();
		if (data.status_code == 200) {
			$("#information_mutasi").html(data.data.description);
		} else {
			$("#information_mutasi").html('Informasi belum di isi !');
		}
	},

	async _giveEventResetPassword() {

	},

	async initProvinceValue() {
		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectProvinsiTujuan").add(new Option(data.province_name, data.province_code))
			});
		}

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("selectProvinsiTujuan").value;
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectKotaTujuan");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectKotaTujuan").add((new Option("Pilih Keanggotaan Wilayah PC", "")))
			if (dataRegistry.status_code == 200) {
				dataRegistry.data.forEach(data => {
					document.getElementById("selectKotaTujuan").add(new Option(data.regency_name, data.id))
				});
			}

			e.preventDefault();
		}

		document.getElementById("selectProvinsiTujuan").addEventListener("change", eventChange)

	},

	async _getDataUser() {
		const dataUser = await ApiMutasiPC.getInformationAnggota();
		if (dataUser.status_code != 200) { return }
		try {
			document.getElementById("textDariPC").value = dataUser.data.cabang_asal;
			document.getElementById("textDariPD").value = dataUser.data.daerah_asal;
			document.getElementById("textKTAN").value = dataUser.data.ktan;
			document.getElementById("textLulusan").value = dataUser.data.pendidikan.jenjang;
			document.getElementById("textTahunLulus").value = dataUser.data.pendidikan.tahun_lulus;
			document.getElementById("textNOSTRTTK").value = dataUser.data.strttk.nomor_strttk;
			$("#textTempatKerjaPC").attr("placeholder", "Tempat Kerja Saat Di " + dataUser.data.cabang_asal)
		} catch (error) {

		}

	},

	async _setUpInsertMutasi() {
		const eventSubmit = async (e) => {
			e.preventDefault();
			let id = document.getElementById('idPengajuan').value;
			if (id == '') {
				const response = await ApiMutasiPC.applyMutasi({
				lulusan: document.getElementById("textLulusan").value,
				tahun_lulus: document.getElementById("textTahunLulus").value,
				nomor_strttk: document.getElementById("textNOSTRTTK").value,
				tempat_kerja_asal: document.getElementById("textTempatKerjaPC").value,
				sarana_kefarmasian_tujuan: document.getElementById("textSaranaTujuanKefarmasian").value,
				pc_id_tujuan: document.getElementById("selectKotaTujuan").value,
				pd_id_tujuan: document.getElementById("selectProvinsiTujuan").value

				})
				
				
				if (response.status_code == 200 || response.status_code == 201) {
					NotificationModal.show(`${response.message}`, 'success');
					await this._initDataMutasi();
					document.getElementById("form-mutasi").reset();
					await this._getDataUser();
					document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-save"></i> Simpan`;
				} else {
					NotificationModal.show(`${response.message}`, 'error');
					document.getElementById("form-mutasi").reset();
					await this._getDataUser();
					document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-save"></i>  Simpan`;
				}
			}else {
				const response = await ApiMutasiPC.updateMutasi({
				id: id,
				lulusan: document.getElementById("textLulusan").value,
				tahun_lulus: document.getElementById("textTahunLulus").value,
				nomor_strttk: document.getElementById("textNOSTRTTK").value,
				tempat_kerja_asal: document.getElementById("textTempatKerjaPC").value,
				sarana_kefarmasian_tujuan: document.getElementById("textSaranaTujuanKefarmasian").value,
				pc_id_tujuan: document.getElementById("selectKotaTujuan").value,
				pd_id_tujuan: document.getElementById("selectProvinsiTujuan").value
				})

				
			if (response.status_code == 200 || response.status_code == 201) {
				NotificationModal.show(`${response.message}`, 'success');
				await this._initDataMutasi();
				document.getElementById("form-mutasi").reset();
				await this._getDataUser();
				document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-save"></i> Simpan`;
			} else {
				NotificationModal.show(`${response.message}`, 'error');
				document.getElementById("form-mutasi").reset();
				await this._getDataUser();
				document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-save"></i>  Simpan`;
			}
			}
			

		}

		const eventResetForm = async () => {
			document.getElementById("form-mutasi").reset();
			await this._getDataUser();
			document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-save"></i> Simpan`;
			$('#btnEdit').addClass('d-none')
		}

		document.getElementById("form-mutasi").addEventListener("submit", eventSubmit)
		document.getElementById("btnEdit").addEventListener("click", eventResetForm)
	},

	async _initDataMutasi() {
		var savedOrderColumn = 'tanggal_pengajuan';
		var savedOrderDirection = 'desc';
		let table = await $('#tableDataMutasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"autoWidth": false,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PC_ENDPOINT.GET_MUTASI_ANGGOTA}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{
					"data": null,
					"width": "5%",
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'name' },
				{ data: 'tanggal_pengajuan' },
				{ data: 'cabang_asal' },
				{ data: 'daerah_asal' },
				{ data: 'daerah_tujuan' },
				{ data: 'cabang_tujuan' },
				{
					data: 'status_pc_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_asal', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_tujuan', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_tujuan', render: function (data) {
						var content = `<div style="display:flex">
						<button class=' btn btn-warning btn-sm m-1 text-center' data-toggle="modal" data-target="#detailMutasiModal" id='detail' title='Detail'><i class="fas fa-list"></i></button>
						<button class=' btn btn-info btn-sm m-1 text-center' id='edit' title='Edit'><i class="fas fa-edit"></i></button>
						<button class=' btn btn-danger btn-sm m-1 text-center' id='delete' title='Hapus'><i class="fas fa-trash"></i></button>
						</div>`


						return content
					}
				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
			},
			"columnDefs": [
				{
					"targets": [0],
					"data": null,
					"orderable": false,
					"searchable": false
				},
				{
					"targets": [3,4,5,6,11],
					"orderable": false,
				},
				{
					"targets": [1, 2, 3],
					"visible": true,
					"searchable": true
				},
				{
					"targets": [7, 8, 9, 10],
					"visible": true,
					"searchable": true,
					"className": "text-center"
				}
			],
			order: [[2, 'desc']]
		});



		const callComentar = async (e) => {
			await this.initKomentar()
		}

		const callTracking = async (id) => {
			await this.initTracking(id)
		}


		$('#tableDataMutasi tbody').on('click', '#detail', async function () {
			mRefresh.refresh();
			$("#persyaratanSerkom").html('');
			$("#dataPekerjaan").html('');
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let PengajuanID = data.id;
			$("#hiddenIdPengajuan").val(PengajuanID);


			const responseDetail = await ApiMutasiPC.getDetailApplyMutasi({ id });
			let dataDetail = responseDetail.data;
			if (responseDetail.status_code == 200) {

				let tanggalPengajuan = dataDetail.tanggal_pengajuan;

				$("#tanggalPengajuan").val(tanggalPengajuan);
				$("#namaAnggota").val(dataDetail.name);
				$("#cabangAsal").val(dataDetail.cabang_asal);
				$("#cabangTujuan").val(dataDetail.cabang_tujuan);



				var dokumen = dataDetail.dokumen;
				dokumen.forEach(dataBerkas => {
					var persyaratanSIPTTK = `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`

					$("#persyaratanSerkom").append(persyaratanSIPTTK);
				});

				var dokumen2 = dataDetail.dokumen_tujuan
				dokumen2.forEach(dataBerkas => {
					var persyaratanSerkom = `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					$("#persyaratanSerkom2").append(persyaratanSerkom);
				});

				mRefresh.refresh()
				const responseD = await ApiMutasiPC.getPersyaratanMutasiPcTujuan({
					id : dataDetail.pc_id_tujuan
				})

				var selectPC = document.getElementById("selectBerkas2");
				var lengthOptionPc = selectPC.options.length;
				for (let i = lengthOptionPc - 1; i >= 0; i--) {
					selectPC.options[i] = null;
				}

				document.getElementById("selectBerkas2").add((new Option("Pilih salah satu", "")))
				if (responseD.status_code == 200) {
					var berkas = responseD.data;
		
					berkas.forEach(dataBerkas => {
						let option= new Option(dataBerkas.name, dataBerkas.document_name)
						option.setAttribute("description-lng",dataBerkas.description)
						document.getElementById("selectBerkas2").add(option)
					});
				}
				mRefresh.resolve()
				await callComentar()
				await callTracking(PengajuanID)
			}

			mRefresh.resolve();


		});

		$('#tableDataMutasi tbody').on('click', '#edit', async function () {
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let PengajuanID = data.id;
			$("#data-umum-tab").trigger('click')
			$("#idPengajuan").val(PengajuanID);
			PengajuanMutasiPresenter._getDataUser();

			$("#btnSimpanRekomSIPTTK").html("<i class='fas fa-edit'></i> Edit Pengajuan")
			$("#btnEdit").removeClass("d-none")
		});


		$('#tableDataMutasi tbody').on('click', '#delete', async function () {

			const table = $('#tableDataMutasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Batalkan [Hapus]',
				html: "Anda ingin Batalkan [Hapus] Permohonan Mutasi??\ ",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya!'
			}).then(async (result) => {
				if (result.value) {
					let idData = data.id;
					mRefresh.refresh();
					const approvalPcTujuan = await ApiMutasiPC.deleteMutasiAnggota({
						id: idData,
					});

					mRefresh.resolve();

					if (approvalPcTujuan.status_code == '200') {

						swal.fire({
							title: 'Mutasi dibatalkan',
							text: "Data berhasil dihapus",
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});

						$('#tableDataMutasi').DataTable().ajax.reload();

					} else {
						swal.fire({
							title: 'Error',
							text: approvalPcTujuan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		$('#tableDataMutasi thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataMutasi thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});


	},

	async initKomentar() {
		document.getElementById("content-comment").innerHTML = ''
		let PengajuanID = document.getElementById("hiddenIdPengajuan").value;
		let getContentAdmin = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			let ketLocation = user.actor.role == "Admin PD" ? user.actor.province_name :  user.actor.cabang_name 
			return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role} ${ketLocation}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
		}

		let getContentUser = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			return `
					<div style="width:100%">
						<div style="width:10%;" class="float-right">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-right text-dark text-left" style="width:88%;">
							<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
		}

		let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
		let dataLocal = JSON.parse(local);
		let UserID = dataLocal[0].id;
		let response = await ApiAnggota.getCommentar(PengajuanID, "rekom_mutasi", 1)


		let getAllContent = async () => {
			let elmHtml = ``
			await response.data.reverse().forEach(async element => {
				elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
			});
			return elmHtml
		}


		if (response.data == undefined || response.data.length <= 0) {
			document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
		} else {
			document.getElementById("content-comment").innerHTML = await getAllContent();

			setTimeout(() => {
				var elem = document.getElementById('content-comment');
				elem.scrollTop = elem.scrollHeight;
			}, 400);
		}
	},


	async _uploadBerkas() {
		let DzoneBerkasSerkom = new Dropzone("#form-upload-serkom", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-serkom .dz-preview:hidden').remove()
					$('#form-upload-serkom .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-serkom").classList.remove('dz-started');
				});
			}
		});

		let DzoneBerkasMutasiTujuan = new Dropzone("#form-upload-serkom2", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-serkom2 .dz-preview:hidden').remove()
					$('#form-upload-serkom2 .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-serkom2").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			$("#persyaratanSerkom").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSerkom").html('');
			let id = $("#hiddenIdPengajuan").val();
			document.getElementById("btnUpdateBerkas").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSerkom = await ApiMutasiPC.uploadBerkasMutasi({
				id: id,
				document_name: document.getElementById('selectBerkas').value,
				dokumen: (DzoneBerkasSerkom.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasSerkom.files[0].dataURL, DzoneBerkasSerkom.files[0].name)
			});
			if (uploadBerkasSerkom.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'error');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'success');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const responseDetail = await ApiMutasiPC.getDetailApplyMutasi({ id });
			let dataDetail = responseDetail.data;
			if (responseDetail.status_code == 200) {

				var dokumen = dataDetail.dokumen
				dokumen.forEach(dataBerkas => {
					var persyaratanSerkom = `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					$("#persyaratanSerkom").append(persyaratanSerkom);
				});

			}

			DzoneBerkasSerkom.emit("removeThumbnailCustom");
			e.preventDefault();
		}

		const eventUploadBerkas2 = async (e) => {
			e.preventDefault();
			$("#persyaratanSerkom2").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSerkom2").html('');
			let id = $("#hiddenIdPengajuan").val();
			document.getElementById("btnUpdateBerkas2").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSerkom = await ApiMutasiPC.uploadBerkasMutasi({
				id: id,
				document_name: document.getElementById('selectBerkas2').value,
				dokumen: (DzoneBerkasMutasiTujuan.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasMutasiTujuan.files[0].dataURL, DzoneBerkasMutasiTujuan.files[0].name)
			});
			if (uploadBerkasSerkom.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'error');
				document.getElementById("btnUpdateBerkas2").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'success');
				document.getElementById("btnUpdateBerkas2").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const responseDetail = await ApiMutasiPC.getDetailApplyMutasi({ id });
			let dataDetail = responseDetail.data;
			if (responseDetail.status_code == 200) {

				var dokumen = dataDetail.dokumen_tujuan
				dokumen.forEach(dataBerkas => {
					var persyaratanSerkom = `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					$("#persyaratanSerkom2").append(persyaratanSerkom);
				});

			}

			DzoneBerkasMutasiTujuan.emit("removeThumbnailCustom");
			e.preventDefault();
		}

		document.getElementById('btnUpdateBerkas').addEventListener('click', eventUploadBerkas);
		document.getElementById('btnUpdateBerkas2').addEventListener('click', eventUploadBerkas2);

	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	async initTracking(id) {
		$("#tableHistorytableHistoryVerifikasiMutasiRekomSIPTTK").dataTable().fnDestroy();
		let table_tracking = await $("#tableHistoryVerifikasiMutasi").dataTable({
			"processing": true,
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PC_ENDPOINT.GET_TRACKING_MUTASI_ANGGOTA(id)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'created_at' },
				{ data: 'actor.name' },
				{ data: 'actor_type' },
				{ data: 'description' },
			],

			"order": [[0, 'desc']],
		});
	}




}

export default PengajuanMutasiPresenter;