import ApiResetPassword from "../api/reset-password.js";
import Autentication from "../utils/autentication.js";
import NotificationModal from "../utils/initial_notification.js";

const ForgotPasswordInitiatior = {

	async init() {
		await this._setView();
		await this._giveEventResetPassword();
	},

	async _setView() {
		document.getElementById('resetPasswordEmail').focus();
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		document.getElementById('main-content').style.removeProperty("height");
	},

	async _giveEventResetPassword() {

		const eventResetPassword = async (e) => {
			e.preventDefault();
				document.getElementById("btnResetPassword").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataResetPassword = await ApiResetPassword.Reset({
					email: document.getElementById('resetPasswordEmail').value
				});

				if (dataResetPassword.status_code != 201) {
					NotificationModal.show(`${dataResetPassword.message}`, 'error');
					document.getElementById("btnResetPassword").innerHTML = ` Kirim Link Reset Password`;
				} else {
					NotificationModal.show(`${dataResetPassword.message}`, 'success');
					this._navigateToLogin()
				}
			e.preventDefault();
		}
		document.getElementById('reset-password').addEventListener('submit', eventResetPassword);
		document.getElementById('btnLogin').addEventListener('click',()=>{
			this._navigateToLogin();
		});
	},


	_navigateToLogin(){
		window.location.hash = '#/login';
	},
	

	_removeActiveRole() {
		document.getElementById('RoleAnggota').classList.remove('active');
		document.getElementById('RolePp').classList.remove('active');
		document.getElementById('RolePd').classList.remove('active');
		document.getElementById('RolePc').classList.remove('active');

	},

	async _setNameAppBar(){
		const datas = await Autentication.getData();

		datas.forEach(data => {
			document.querySelector('app-bar').setName = data;
		});
	},

	_saveData(dataUser){
		Autentication.saveData(dataUser)
		this._setNameAppBar()
	},

}

export default ForgotPasswordInitiatior;
