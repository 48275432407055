
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSTRTTKPC from '../../api/api_pengurus_pc/pengajuan-strttk.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"


const STRTTKPC = {

	async init() {
		await this._setView();
		await this._getInformationSTRTTK();
		await this.createInformationSTRTTK();
		await this._getPengajuanSTRTTKFiltered();
		await this._approveSTRTTK();
		await this._downloadSTRTTK();
		await this._getPengajuanDetail();
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async _downloadSTRTTK() {
		$('#tablePengajuanSTRTTK tbody').on('click', '#rekomendasiSTRTTK', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const downloadRekomSTRTTKPD = await ApiAnggota.downloadSTRTTKPC({ id });
			$(this).html('<i class="fas fa-download"></i> Rekomendasi STRTTK');

		});
	},

	async _getPengajuanSTRTTKFiltered(){
		let status = document.getElementById('selectStatusList').value;
		await this._getPengajuanSTRTTK(status)

		const eventFilteredData = async () => {
			let status = document.getElementById('selectStatusList').value;
			await this._getPengajuanSTRTTK(status)
		}

		document.getElementById('selectStatusList').addEventListener('change',eventFilteredData)
	},

	async _getPengajuanSTRTTK(status) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tablePengajuanSTRTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.STRTTK(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'user.name' },
				{
					data: 'created_at'
				},
				{ data: 'jenis_permohonan' },
				{
					data: 'berkas_data_umum.ktan_lama', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.ijazah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.serkom', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_permohonan_pc', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_keterangan_praktik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_keabsahan_dokumen', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_patuh_kode_etik', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_wajib.surat_pernyataan_sumpah', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == "approve") {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == "approve") {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [3, 4, 5, 6, 7, 8, 9,10, 11,12],
					"orderable": false
				},
				{
					"targets": [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
					"visible": true,
					"searchable": true,
					className: "text-center"
				},
				{
					"targets":[15],
					"data":null,
					render : function (data) {
						let content = ``
						if (data.status_pd == 'approve') {
							content = `
							<div style="display:flex">
							<button class='btn btn-warning btn-sm' id='detail' data-toggle="modal" data-target="#detailSTRTTKModal"  title='Detail'><i class='fas fa-list'></i></button>`
						}else {
							if (data.status_pc == 'approve') {
								content = `
								<div style="display:flex">
								<button class='btn btn-warning btn-sm' id='detail' data-toggle="modal" data-target="#detailSTRTTKModal"  title='Detail'><i class='fas fa-list'></i></button>&nbsp;
								<button class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>`
							}else {
								content = `
								<div style="display:flex">
								<button class='btn btn-warning btn-sm' id='detail' data-toggle="modal" data-target="#detailSTRTTKModal"  title='Detail'><i class='fas fa-list'></i></button>&nbsp;
								<button class='btn btn-success btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>&nbsp;
								<button class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>`
							}
						}

						return content
					}
				}
			]
		});

		$('#tablePengajuanSTRTTK thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSTRTTK thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async _getPengajuanDetail() {
		let idPengajuan = '';
		$('#tablePengajuanSTRTTK tbody').on('click', '#detail', async function () {
			$("#persyaratanSTRTTK").html('');
			$("#dataPendidikanSTRTTK").html('');
			$("#dataPekerjaan").html('');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			$("#textHidden").val(id);
			const getDetailPenagjuanSTRTTK = await ApiAnggota.getPengajuanSTRTTKByID({ id });


			let dataDetail = getDetailPenagjuanSTRTTK.data;
			let UserID = '';
			if (getDetailPenagjuanSTRTTK.status_code == 200) {
				UserID = dataDetail.user_id;
				let timePengajuan = dataDetail.created_at;
				let timeSurat = new Date().toLocaleDateString('en-US');
				let jenisPermohonan = dataDetail.jenis_permohonan;
				$("#textNomorSuratRekomendasi").val(dataDetail.nomor_sr_pd);
				if (jenisPermohonan == 'new') {
					jenisPermohonan = 'Baru';
				} else {
					jenisPermohonan = 'Perpanjang'
				}

				let perpanjangDengan = dataDetail.perpanjang_dengan;

				if (perpanjangDengan == null) {
					perpanjangDengan = '-';
				} else {
					perpanjangDengan = perpanjangDengan;
				}
				$("#tanggalSuratRekomendasi").val(timeSurat);
				$("#tanggalPengajuan").val(timePengajuan);
				$("#textKTAN").val(dataDetail.user.ktan);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textEmail").val(dataDetail.user.email);
				$("#textJenisPermohonan").val(jenisPermohonan);
				$("#textPerpanjangDengan").val(perpanjangDengan);
				$("#textStatus").val(dataDetail.status);


				var dokumen = dataDetail.dokumen
				var persyaratanSTRTTK = ``
				dokumen.forEach(dataBerkas => {
					persyaratanSTRTTK += `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`
				});
				$("#persyaratanSTRTTK").append(persyaratanSTRTTK);
				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="5" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPendidikan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPendidikanSTRTTK").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td colspan="10" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<td>${dokumenPekerjaan.nama_tempat_kerja}</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
							<td>${dokumenPekerjaan.phone_number}</td>
							<td>${dokumenPekerjaan.email}</td>
							<td>${dokumenPekerjaan.jabatan}</td>
							<td>${dokumenPekerjaan.jenis_tempat_kerja}</td>
							<td>${dokumenPekerjaan.dari_tahun}</td>
							<td>${dokumenPekerjaan.sampai_tahun}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dokumenPekerjaan.dokumen}' width="40px"></td>
						</tr>
					`
				}

				$("#dataPekerjaan").append(pekerjaan);


			}
			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanSTRTTKPC.getCommentar(UserID, "rekom_strttk", id)
			let dataComment = response.data;
			dataComment.reverse();

			let getAllContent = async () => {
				let elmHtml = ``
				await dataComment.forEach(async element => {
					elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
				});
				return elmHtml
			}


			if (response.data == undefined || response.data.length <= 0) {
				document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
			} else {
				document.getElementById("content-comment").innerHTML = await getAllContent();

				setTimeout(() => {
					var elem = document.getElementById('content-comment');
					elem.scrollTop = elem.scrollHeight;
				}, 400);
			}

			$("#btnSendComment").on('click', async function () {



				if (UserID != '' && id != '') {
					document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					let content = document.getElementById("textKomentar").value
					const response = await ApiPengajuanSTRTTKPC.sendCommentar(UserID, id, "rekom_strttk", content);
					if (response.status_code == 200 || 201) {

						let getContentAdmin = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-left text-dark text-left" style="width:88%;">
											<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}

						let getContentUser = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-right text-dark text-left" style="width:88%;">
											<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}


						let response = await ApiPengajuanSTRTTKPC.getCommentar(UserID, "rekom_strttk", id)
						let dataComment = response.data;
						dataComment.reverse();

						let getAllContent = async () => {
							let elmHtml = ``
							await dataComment.forEach(async element => {
								elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
							});
							return elmHtml
						}

						if (response.data == undefined || response.data.length <= 0) {
							document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
						} else {
							document.getElementById("content-comment").innerHTML = await getAllContent();

							setTimeout(() => {
								var elem = document.getElementById('content-comment');
								elem.scrollTop = elem.scrollHeight;
							}, 400);
						}
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					} else {
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					}


				}


				// $(this).off('click');


			});

			$('#detailSTRTTKModal').on('hidden.bs.modal', async function (e) {
				$('#btnSendComment').off('click');
				$('#textNomorSuratRekomendasi').val('');
				$('#selectStatusApprove').val('');
				$('#textCatatan').val('');
				$('#textKomentar').val('');

			});
		});
			
	},


	async _getInformationSTRTTK() {

		tinymce.remove('#textInformasiSTRTTK')
		tinymce.init({
			selector: 'textarea#textInformasiSTRTTK',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});

		const getInformation = await ApiPengajuanSTRTTKPC.getInformationSTRTTK();

		var textInformationSTRTTK = tinymce.get('textInformasiSTRTTK').setContent(getInformation.data.description);

	},

	async createInformationSTRTTK() {

		$("#btnUpdateInformasiSTRTTK").click(async function () {
			$(this).html("Loading...")
			var textInformationSTRTTK = tinymce.get('textInformasiSTRTTK').getContent();
			const createInformationSTRTTK = await ApiPengajuanSTRTTKPC.createInfromationSTRTTK({
				description: textInformationSTRTTK,
			});

			if (createInformationSTRTTK.status_code == 200) {
				NotificationModal.show(`${createInformationSTRTTK.message}`, 'success');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			} else {
				NotificationModal.show(`${createInformationSTRTTK.message}`, 'error');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			}


		});
	},

	async _approveSTRTTK() {
		let idPengajuan = '';
		$('#tablePengajuanSTRTTK tbody').on('click', '#approve', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let approval = "approve";
			const ApproveSTRTTK = await ApiPengajuanSTRTTKPC.approvePengajuanSTRTTK({
				id: id,
				approval: approval
			});

			if (ApproveSTRTTK.status_code == 200) {
				NotificationModal.show(`${ApproveSTRTTK.message}`, 'success');
				$(this).html('<i class="fas fa-check"></i>');
				$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${ApproveSTRTTK.message}`, 'error');
				$(this).html('<i class="fas fa-check"></i>');
			}
		});

		$('#tablePengajuanSTRTTK tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let UserID = data.user_id
			idPengajuan = data.id;

			// let getContentAdmin = (user) => {
			// let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// return `
			// 		<div style="width:100%">
			// 			<div style="width:10%;" class="float-left">
			// 				<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 			</div>
			// 			<div class="float-left text-dark text-left" style="width:88%;">
			// 				<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
			// 					<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 					<small>${user.body}</small><br>
			// 					<div class="text-right">
			// 						<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 					</div>
			// 				</div>
			// 			</div>
			// 		</div>
			// 		`
			// }

			// let getContentUser = (user) => {
			// 	let time =  new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US') ;
			// 	return 	`
			// 			<div style="width:100%">
			// 				<div style="width:10%;" class="float-right">
			// 					<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
			// 				</div>
			// 				<div class="float-right text-dark text-left" style="width:88%;">
			// 					<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
			// 						<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
			// 						<small>${user.body}</small><br>
			// 						<div class="text-right">
			// 							<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			</div>
			// 			`
			// }


			// let response = await ApiPengajuanKTANPC.getCommentar(UserID,"membership",idPengajuan)
			// let dataComment = response.data;
			// dataComment.reverse();

			// if (dataComment.length <= 0) {
			// 	$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			// }else {
			// 	let getAllContent = async () => {
			// 	let elmHtml = ``
			// 		await dataComment.forEach(async element => {
			// 			elmHtml = elmHtml + (element.actor_type == "user" ?  getContentAdmin(element) :  getContentUser(element))
			// 		});
			// 		return elmHtml
			// 	}


			// 	if (response.data == undefined || response.data.length <= 0) {
			// document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`

			// 	}else{
			// 		document.getElementById("content-comment").innerHTML = await getAllContent();

			// 		setTimeout(() => {
			// 			var elem = document.getElementById('content-comment');
			// 			elem.scrollTop = elem.scrollHeight;
			// 			}, 400);
			// 	}
			// }

		});

		$("#btnSendKomentarPengajuan").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#komentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const revisePengajuanKTAN = await ApiPengajuanSTRTTKPC.approvePengajuanSTRTTK({
					id: idPengajuan,
					approval: "revise",
					catatan: comment
				})
				if (revisePengajuanKTAN.status_code != 200) {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
				} else {
					NotificationModal.show(`${revisePengajuanKTAN.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanSTRTTK').DataTable().ajax.reload();
				}
			}

		});

		$('#commentModal').on('hidden.bs.modal', async function (e) {
			$("#komentarPengajuan").val("")
		});

	}


}

export default STRTTKPC;
