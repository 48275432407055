import UrlParser from "../routes/url-parser.js";


const ErrorPresenter = {

	async init() {
        await this.isMembershipUnpaid();
        await this.isPcPdLocked()
    },

    async isMembershipUnpaid() {
      if (UrlParser._getParameterByName("reason") == "unpaid") {
        document.getElementById("reason").innerHTML ="Maaf Anda tidak memiliki akses kesini karena belum membayar iuran."
      }
    },

    async isPcPdLocked() {
      if (UrlParser._getParameterByName("reason") == "iuranpc") {
        document.getElementById("reason").innerHTML ="Maaf Anda tidak memiliki akses kesini karena belum membayar iuran PC."
      }
      if (UrlParser._getParameterByName("reason") == "iuranpd") {
        document.getElementById("reason").innerHTML ="Maaf Anda tidak memiliki akses kesini karena belum membayar iuran PD."
      }
    },
    

}

export default  ErrorPresenter;