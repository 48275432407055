/* eslint-disable new-cap */
import API_ENDPOINT from '../config/globals/endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';
import KECUKUPAN_SKP_ENDPOINT from '../config/globals/kecukupan_skp_endpoint.js';
import API_PENGURUS_PC_ENDPOINT from '../config/globals/menu_pengurus_pc_endpoint.js';

class ApiKecukupanSKP {

    static async createKecukupanSKP() {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token}`);
        
		var formdata = new FormData();
     
		try {
			const fetchApi = await fetch(KECUKUPAN_SKP_ENDPOINT.CREATE_KECUKUPAN_SKP, {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		} 
	}

	static async getPengajuanAdditional(status, startDate, endDate , idCabang){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.GET_PENGAJUAN_LIST_PD(status, startDate, endDate , idCabang), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	};

    static async getPengajuanByID({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DETAIL_PENGAJUAN(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

    static async deletePengajuan({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DETAIL_PENGAJUAN(id), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createSyaratKecukupanSKP ({
		name,
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.PERSYARATAN_KECUKUPAN_SKP,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async updateSyaratKecukupanSKP ({
		id,
		name,
		description
	}){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.UPDATE_PERSYARATAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async createInfromation ({
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.KECUKUPAN_SKP_INFORMATION,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getInformation (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.KECUKUPAN_SKP_INFORMATION,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getSyaratBerkas (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.PERSYARATAN_KECUKUPAN_SKP,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async uploadBerkasKecukupanSKP({
		id,
		document_name,
		dokumen
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", document_name);
		formdata.append("document", dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_PENGURUS_PC_ENDPOINT.UPDATE_KECUKUPAN_BERKAS(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async approvePengajuanPC({
		id,
		status,
		comment
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": status,
            "catatan": comment,
          });
		try {
			const response = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.APPROVAL_PENGAJUAN_PC(id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async approvePengajuanPD({
		id,
		status,
		comment
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": status,
            "catatan": comment,
          });
		try {
			const response = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.APPROVAL_PENGAJUAN_PD(id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async approveAllSerkom (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);
		

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.APPROVE_All_SERKOM,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async getPengajuanByIDPD({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DETAIL_PENGAJUAN_PD(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async getPengajuanByIDPC({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DETAIL_PENGAJUAN_PC(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async approveAllPC (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.APPROVE_All_PC,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async approveAllPD (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.APPROVE_All_PD,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async downloadKecukupanSKPByID({id , name}) {
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			const response =  await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DOWNLOAD_KECUKUPAN_SKP(id), {
					method: 'GET',
					headers: myHeaders
				});
		
			if (response.status == 200) {
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `Kecukupan_SKP_${name}.pdf`;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					URL.revokeObjectURL(url);
				}, 0);
				return response.status
			} else {
				
				console.log(`Request failed with status: ${response.status}`);
			}
		
			return {};
		} catch (err) {
			console.error(err);
			return {};
		}
		
	}

	static async downloadSuratSyaratKecukupanSKPByID({id , name}) {
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Accept', 'application/json');
			myHeaders.append('Access-Control-Allow-Origin', '{*}');
			myHeaders.append('Access-Control-Allow-Credentials', 'true');
			myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		  
			var rawValue = JSON.stringify({
				"document_name": "surat_pernyataan_keterangan_kecukupan_skp",
			  });
			  
			const response =  await fetchWithTimeout(KECUKUPAN_SKP_ENDPOINT.DOWNLOAD_SURAT_SYARAT_KECUKUPAN_SKP(id), {
					method: 'POST',
					headers: myHeaders,
					body : rawValue
				});
		
			if (response.status == 200) {
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = `Surat_Pernyataan_Keterangan_Kecukupan_Skp${name}.pdf`;
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					document.body.removeChild(a);
					URL.revokeObjectURL(url);
				}, 0);
				return response.status
			} else {
				
				console.log(`Request failed with status: ${response.status}`);
			}
		
			return {};
		} catch (err) {
			console.error(err);
			return {};
		}
		
	}

}

export default ApiKecukupanSKP;
