
import ApiMutasiPC from "../../api/api_pengurus_pc/data-mutasi.js";
import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PC_ENDPOINT from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";

const MutasiManualPresenterPC = {

	async init() {
		await this._setView()
		await this.renderGetUserByKtan()
		await this.initProvinceValue()
		await this.initCreateMutasiManual()
		await this.renderData()
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async renderGetUserByKtan() {
		const eventGetUser = async () => {
			let ktanValue = document.getElementById("tvKtan").value;
			let response = await ApiRole.getUserByKTAN(ktanValue);
			if (response.status_code == 200) {
				document.getElementById("userId").value = response.data.id;
				document.getElementById("tvName").value = response.data.name;
				document.getElementById("tvEmail").value = response.data.email;
			} else {
				document.getElementById("tvName").value = "Nama lengkap"
				document.getElementById("userId").value = ""
				document.getElementById("tvEmail").value = "Email"
			}
		}
		document.getElementById("tvKtan").addEventListener("keyup", eventGetUser)
	},

	async initProvinceValue() {
		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectPDTujuan").add(new Option(data.province_name, data.province_code))
			});
		}

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("selectPDTujuan").value;
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPCTujuan");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPCTujuan").add((new Option("Pilih Keanggotaan Wilayah PC", "")))
			if (dataRegistry.status_code == 200) {
				dataRegistry.data.forEach(data => {
					document.getElementById("selectPCTujuan").add(new Option(data.regency_name, data.id))
				});
			}

			e.preventDefault();
		}

		document.getElementById("selectPDTujuan").addEventListener("change", eventChange)

	},

	async initCreateMutasiManual() {

		let DzoneLampiran = new Dropzone("#form-upload-iuran", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-iuran .dz-preview:hidden').remove()
					$('#form-upload-iuran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-iuran").classList.remove('dz-started');
				});
			}
		});

		const eventChange = async (e) => {
			e.preventDefault();
			swal.fire({
				title: 'Mutasi Manual',
				html: "Yakin Anda akan Mutasi manual data tersebut? ",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui'
			}).then(async (result) => {
				if (result.value) {
					const response = await ApiMutasiPC.createMutasiManual({
						user_id: document.getElementById("userId").value,
						pc_id_tujuan: document.getElementById("selectPCTujuan").value,
						pd_id_tujuan: document.getElementById("selectPDTujuan").value,
						alasan: document.getElementById("tvAlasan").value,
						email_tembusan: document.getElementById("textMail1").value,
						email_tembusan_pd_lama: document.getElementById("textMailPDLama").value,
						email_tembusan_pc_lama: document.getElementById("textMailPCLama").value,
						email_tembusan_pd_baru: document.getElementById("textMailPDBaru").value,
						email_tembusan_pc_baru: document.getElementById("textMailPCBaru").value,
						attachment_name: document.getElementById("textFileName").value,
						documentFile: (DzoneLampiran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneLampiran.files[0].dataURL, DzoneLampiran.files[0].name)
					})
					if (response.status_code == 200) {
						NotificationModal.show(`${response.message}`, 'success');
						DzoneLampiran.emit("removeThumbnailCustom")
						document.getElementById("form-mutasi").reset();
						document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-edit"></i> Update Files`;
					} else {
						NotificationModal.show(`${response.message}`, 'error');
						document.getElementById("btnSimpanRekomSIPTTK").innerHTML = `<i class="fas fa-edit"></i>  Update Files`;
					}
				}
			});
			e.preventDefault();
		}

		document.getElementById("form-mutasi").addEventListener("submit", eventChange)
	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},


	async renderData() {
		let table = await $('#tableDataMutasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PC_ENDPOINT.GET_MUTASI_MANUAL}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'ktan' },
				{ data: 'cabang_asal' },
				{ data: 'cabang_tujuan' },
				{ data: 'alasan' },
				{
					data: 'lampiran', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},


			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
		});
	}


}

export default MutasiManualPresenterPC;