
import CONFIG from '../config/globals/config.js';
import API_PENGURUS_PC_ENDPOINT from '../config/globals/menu_pengurus_pc_endpoint.js';
import API_ENDPOINT_PENGURUS_PC from "../config/globals/menu_pengurus_pc_endpoint.js";
import SEMINAR_ENPOINT from '../config/globals/seminar_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';
import BERITA_END_POINT from '../config/globals/berita_endpoint.js';

class ApiEdaran {

	
	static async sendEdaran({
		title,
		content,
		fileList
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("title", title);
		formdata.append("content", content);

		for (let index = 0; index < fileList.length; index++) {
			const file = fileList[index];
			formdata.append(`attachments[]`, file);
		}

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}

		console.log(pairing)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.SEND_EDARAN, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async editEdaran({
		title,
		content,
		fileList,
		idValue
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var raw = null
		if (fileList.length == 0) {
			raw = JSON.stringify({
				"title": title,
				"content" : content
			});
		}else{
			raw = JSON.stringify({
				"title": title,
				"content" : content,
				"attachments" : fileList
			});
		}

		console.log(raw)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.EDIT_EDARAN(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async editEdaranFormData({
		title,
		content,
		fileList,
		idValue
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var formdata = new FormData();

		formdata.append("_method" , "PUT")
		formdata.append("title", title);
		formdata.append("content", content);

		for (let index = 0; index < fileList.length; index++) {
			const file = fileList[index];
			formdata.append(`attachments[]`, file);
		}

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}

		console.log(pairing)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.EDIT_EDARAN(idValue), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async getEdaranById({
		idValue
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(BERITA_END_POINT.EDARAN_WITH_ID(idValue),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async deleteEdaran({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.EDARAN_WITH_ID(idValue), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async publishEdaran({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "publish",
		  });
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.CHANGE_STATUS_EDARAN(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async unPublishEdaran({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "draft",
		  });
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.CHANGE_STATUS_EDARAN(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
   
}


export default ApiEdaran;