import ApiResetPassword from "../api/reset-password.js";
import Autentication from "../utils/autentication.js";
import NotificationModal from "../utils/initial_notification.js";



const ResendPaymentPresenter = {

	async init() {
		await this._setView();
		await this._giveEventResendPayment();
	},

	async _setView() {
		document.getElementById('textEmail').focus();
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		document.getElementById('main-content').style.removeProperty("height");
	},

	async _giveEventResendPayment() {
		let countdownSeconds = 0
		let isTimerOn = false
		let timeoutId = null
		var defaultCoundown  = localStorage.getItem('countdownSeconds');
		var isIntervalOn = localStorage.getItem('interval_timer_is_on_payment')
		if (isIntervalOn != undefined) {
			clearInterval(isIntervalOn);
		}
		if (defaultCoundown != undefined && defaultCoundown > 0) {
			isTimerOn = true
			countdownSeconds = defaultCoundown
			timeoutId = setInterval(() => {
				countdownSeconds--;
	
				localStorage.setItem('interval_timer_is_on_payment',timeoutId);
				try {
					document.getElementById("btnSendPayment").innerHTML = `Tunggu ${countdownSeconds} detik lagi, untuk mengirim ulang`;
				} catch (error) {
					
				}
				
				localStorage.setItem('countdownSeconds', countdownSeconds);
				if (countdownSeconds <= 0) {
					clearInterval(timeoutId);
	
					localStorage.removeItem('countdownSeconds');
					document.getElementById("btnSendPayment").innerHTML = ` Kirim Ulang Administrasi Pendaftaran`;
					isTimerOn = false;

					localStorage.removeItem('interval_timer_is_on_payment');
				}
			}, 1000);
		}

		const eventResetPassword = async (e) => {
			e.preventDefault();
			if (isTimerOn) {
				return
			}
				document.getElementById("btnSendPayment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataResetPassword = await ApiResetPassword.ResendPayment({
					email: document.getElementById('textEmail').value
				});

				if (dataResetPassword.status_code != 202) {
					NotificationModal.show(`${dataResetPassword.message}`, 'error');
					document.getElementById("btnSendPayment").innerHTML = ` Kirim Ulang Administrasi Pendaftaran`;
				} else {
					NotificationModal.show(`${dataResetPassword.message}`, 'success');
					isTimerOn = true
					countdownSeconds = 60
					localStorage.setItem('countdownSeconds', countdownSeconds);
					timeoutId = setInterval(() => {
						countdownSeconds--;
						localStorage.setItem('interval_timer_is_on_payment',timeoutId);
						try {
							document.getElementById("btnSendPayment").innerHTML = `Tunggu ${countdownSeconds} detik lagi, untuk mengirim ulang`;
						} catch (error) {
							
						}

						localStorage.setItem('countdownSeconds', countdownSeconds);
						if (countdownSeconds <= 0) {
							clearInterval(timeoutId);
							localStorage.removeItem('countdownSeconds');
							document.getElementById("btnSendPayment").innerHTML = ` Kirim Ulang Administrasi Pendaftaran`;
							isTimerOn = false;
							localStorage.removeItem('interval_timer_is_on_payment');
						}
					}, 1000);
					
				}
			e.preventDefault();
		}
		document.getElementById('reset-password').addEventListener('submit', eventResetPassword);
		document.getElementById('btnLogin').addEventListener('click',()=>{
			this._navigateToLogin();
		});
	},

	_navigateToLogin(){
		window.location.hash = '#/login';
	},
}

export default ResendPaymentPresenter;
