import BeritaPresenterPP from "../../../presenter/berita/BeritaPresenterPP.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';



const BeritaPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiKTAN" class="nav-link hapus-border active" id="data-pengajuan-ktan-tab" data-bs-toggle="tab" data-bs-target="#tabVerifikasiKTAN" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Berita</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiKTAN" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
                        <div class="card mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Dari Tangal</label>
                                            <input id="fromDate" type="date" class="form-control"/>
                                        </div>
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                            <input id="untilDate" type="date" class="form-control"/>
                                        </div>
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Status</label>
                                            <select id="statusBerita" class="form-control">
                                                <option value='draft'>Draft</option>
                                                <option value='publish'>Publish</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                             <button class="btn btn-primary btn-xl" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Data Berita
                                    </div>
                                    <div class="card-body">
                                    
                                        <div class="row">
                                            <div class="col-sm-5 col-xl-5 col-lg-5">
                                                <button type="button" class="btn btn-info" id="btnAdd" data-toggle="modal" data-target="#modalTambahPengajuan" title="Tambah">
                                                    <i class="fas fa-plus"></i> Tambah Berita
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tableNews" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="no">No</th>
                                                                <th data-column="title">Judul</th>
                                                                <th data-column="category" >Kategori</th>
                                                                <th data-column="status_berita" >Status</th>
                                                                <th data-column="created_at" >Tanggal dibuat</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                    
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
        
        <div class="modal fade text-uppercase bd-example-modal-lg" id="modalTambahPengajuan" tabindex="-1" role="dialog" aria-labelledby="modalTambahPengajuan"
                    aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold" >
                    <h5 class="modal-title text-primary font-weight-bold" id="titleModalPengajuan"> Tambah Berita</h5>
                       
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body" id="tambahMateri">
                        <div class="row mt-3">
                            <form id="form-pengajuan-berita" enctype="multipart/form-data">
                            <input type="hidden" id="statePengajuan" value="add" class="form-control" >
                            <input type="hidden" id="stateId" class="form-control" >
                                <div class="col-lg-12">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Kategori</label>
                                        <div class="col-sm-12">
                                            <select class="form-control text-uppercase" id="selectKategori" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="berita">Berita</option>
                                                <option value="lowongan_kerja">Lowongan Pekerjaan</option>
                                            </select>
                                        </div>
                                    </div>
                                
                                    <div class="form-group row">
                                        
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Judul</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="textJudul" class="form-control text-uppercase" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Konten</label>
                                                <div class="col-sm-12">
                                                    <textarea rows="5" id="textDeskripsi" placeholder="..." name="deskripsi"></textarea>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Lampiran Berita max (5) files</label>
                                            </div>
                                    
                                            <div class="card ml-2 mb-2 border-left-primary col-sm-11 " id="containerImageEdit">
                                                <div class="mt-2 mb-2">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">
                                                    
                                                    <h6 class="text-lowercase"> * Silahkan Upload ulang lagi jika Anda ingin mengubah Lampiran .</h6>
                                                 </div>
                                            </div>

                                            <div id="form-upload-file" class="col-sm-12 dropzone">
                                                    <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group row">
                                                <div class="col-sm-6">
                                                    <button type="submit" class="form-control text-uppercase btn btn-primary" id="btnSave"><i class="fas fa-save"></i>Simpan </button>
                                                </div>
                                                <div class="col-sm-6">
                                                    <button type="reset" class="form-control btn btn-warning text-uppercase" id="btnReset"><i class="fas fa-undo"></i> Kosongkan</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade text-uppercase bd-example-modal-lg" id="modalDetail" tabindex="-1" role="dialog" aria-labelledby="modalTambahPengajuan"
        aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-primary font-weight-bold" >
                        <h5 class="modal-title text-primary font-weight-bold" > Detail</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Kategori</label>
                                            <div class="col-sm-12">
                                                <select class="form-control text-uppercase" readonly id="selectKategoriDetail" required>
                                                    <option value="">Pilih Salah Satu</option>
                                                    <option value="berita">Berita</option>
                                                    <option value="lowongan_kerja">Lowongan Pekerjaan</option>
                                                </select>
                                            </div>
                                        </div>
                                    
                                        <div class="form-group row">
                                           
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <label class="control-label col-sm-12 font-weight-bold">Judul</label>
                                                    <div class="col-sm-12">
                                                        <input type="text" id="textJudulDetail" readonly class="form-control text-uppercase" required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ml-2 mb-2 col-sm-11 text-center " id="containerImageEditDetail">
                                                <div class="mt-2 mb-2">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">
                                                    <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">
                                                
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3" id="content-detail-modal">
                                              
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
        </div>
    
        <div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail style="width:200px; height:100px;"  /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
        `;
        `<div`
		return view;
	  },

	async afterRender() {
		await BeritaPresenterPP.init()
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default BeritaPP;