import MutasiManualPresenterPC from "../../../presenter/menu_pengurus_pc/mutasi-manual-pc-presenter.js";
import ImagePopUPInitiator from "../../../presenter/pop-up-presenter.js";


const MutasiPCManual = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataUmum" class="nav-link hapus-border active" id="data-umum-tab" data-bs-toggle="tab" data-bs-target="#tabDataUmum" data-toggle="tab" type="button" role="tab" aria-controls="tabDataUmum" aria-selected="true">Formulir </button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Data</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataUmum" role="tabpanel" aria-labelledby="data-umum-tab">
						<div class="row mt-3">
							<div class="col-lg-12 col-xl-8">
								<div class="card mt-3">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Anda akan memindahkan anggota</h6>
									</div>
									<div class="card-body ">
										<form id="form-mutasi">
										<div class="form-group row">
										<input type="hidden" class="form-control" required id="userId">
												<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
												<div class="col-sm-12">
													<input type="text" name="lulusan" placeholder="KTAN" class="form-control" required id="tvKtan">
												</div>
											</div>
											
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama</label>
												<div class="col-sm-12">
													<input type="text" name="dari_pc" placeholder="Nama" class="form-control" required id="tvName" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email</label>
												<div class="col-sm-12">
													<input type="text" name="ktan" placeholder="Email" class="form-control" required readonly id="tvEmail">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alasan Anda Dipindahkan</label>
												<div class="col-sm-12">
													<input type="text" name="tahun_lulus" placeholder="" class="form-control" required id="tvAlasan">
													
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Silahkan Pilih PD Baru</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectPDTujuan" name="pd_tujuan" required>
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Silahkan Pilih PC Baru</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectPCTujuan" name="pd_tujuan" required>
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>
											
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tembusan 1</label>
												<div class="col-sm-12">
													<input type="text" name="pd" value="info@pafi.or.id" class="form-control"  required id="textMail1">
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tembusan PD LAMA</label>
												<div class="col-sm-12">
													<input type="text" name="pd" value="info@pafibali.org" class="form-control"  required id="textMailPDLama">
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tembusan PC LAMA</label>
												<div class="col-sm-12">
													<input type="text" name="pd" value="info@pafidenpasar.org" class="form-control"  required id="textMailPCLama">
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tembusan PD Baru</label>
												<div class="col-sm-12">
													<input type="text" name="pd" placeholder="Email" class="form-control"  required id="textMailPDBaru">
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tembusan PC Baru</label>
												<div class="col-sm-12">
													<input type="text" name="pd" placeholder="Email" class="form-control" required id="textMailPCBaru">
												</div>
											</div>

											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Attachment Information</label>
												<div class="col-sm-12">
													<input type="text" name="tahun_lulus" placeholder="" class="form-control" required id="textFileName">
												</div>
											</div>

											<div class="form-group row col-sm-6">
												<label class="control-label font-weight-bold">Lampiran</label>
												<div id="form-upload-iuran" class="col-sm-12 dropzone">
													<input type="hidden" name="file" style=""/>
												</div>
											</div>
										
											<div class="form-group row">
												<div class="col-sm-4 mb-1">
													<button type="submit" class="form-control btn btn-primary btn-sm" id="btnSimpanRekomSIPTTK"><i class="fas fa-save"></i> Simpan</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="tab-pane fade" id="tabProfilPendidikan" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Mutasi Manual
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableDataMutasi" width="100%" cellspacing="0">
										<thead>
											<tr>
                                                        <th>Nama</th>
                                                        <th>KTAN</th>
                                                        <th>PC ASAL</th>
                                                        <th>PC Tujuan</th>
                                                        <th>Alasan dipindahkan</th>
                                                        <th>Lampiran</th>
											</tr>
										</thead>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabPekerjaan" role="tabpanel" aria-labelledby="pekerjaan-tab">Pekerjaan</div>
					<div class="tab-pane fade" id="tabKarirOrganisasi" role="tabpanel" aria-labelledby="karir-organisasi-tab">Organisasi</div>
				</div>
			</div>
		</div>
		
		<div style="display:none;" id="my-template">
			<div id="mytmp" class="dz-preview dz-file-preview">
				<div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
				<div class="dz-details">
					<div class="dz-size"><span data-dz-size></span></div>
					<div class="dz-filename"><span data-dz-name></span></div>
				</div>
				
				<div class="dz-error-message"><span data-dz-errormessage></span></div>
				<div class="dz-success-mark">
					<svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
					</svg>
				</div>
				<div class="dz-error-mark">
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<circle cx="12" cy="19" r="2" />
						<path d="M10 3h4v12h-4z" />
					</svg>
				</div>
				<div class="dz-remove" data-dz-remove>
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
					</svg>
				</div>
			</div>
		</div>
		
		`;
		return view;
	  },

	async afterRender() {
		await MutasiManualPresenterPC.init();
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default MutasiPCManual;