
const Footer = {
	async init() {
		const result = this._render();
		document.getElementById('footer-elm').insertAdjacentHTML("afterbegin", result);
	},

	_render() {
		return `<div class="container-fluid my-auto style="padding:1rem 0">
	  <div class="copyright text-center my-auto">
		  <div class="row">
			  <div class="col-md-12 my-auto" id="cpRght">
				  <h6> <b> SIPAFI v8.1</b> || Copyright PAFI 2024 &copy; <a href="https://cektrend.com/" target="_blank"
						  alt="PAFI"
						  title="PAFI"><b>Design by Cektrend
							  Studio</b></a>  </h6>
			  </div>
			 
		  </div>
	  </div>
  </div>`;
	},

	async _timeNow() {
	},

	
}



export default Footer;
