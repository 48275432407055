import RegistrasiAnggotaBaruPresenter from '../../../presenter/menu_pengurus_pp/registrasi-anggota-baru-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const RegistrasiAnggotaBaru = {
	async render() {
		const view = `
		                <div class="card shadow mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12">
                                    <div class="row">
                                    
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Status Approvement</label>
                                            <select id="selectStatusApprove" class="form-control">
                                                <option value='pending'>Permohonan</option>
                                                <option value='approve'>Disetujui</option>
                                                <option value=''>Semua</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Status Pembayaran</label>
                                            <select id="selectStatusPembayaran" class="form-control">
                                                <option value='confirm'>Menunggu Konfirmasi</option>
                                                <option value='paid'>Lunas</option>
                                                <option value='unpaid'>Belum Bayar</option>
                                                <option value=''>Semua</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                            <select id="selectPengajuanRegisPD" class="form-control">
                                                <option value=''>Pilih Daerah</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                            <select id="selectPengajuanRegisPC" class="form-control">
                                                <option value=''>Pilih Cabang</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <button class="btn btn-primary btn-xl" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card shadow">
                            <div class="card-header font-weight-bold text-primary">
                                Pengajuan Registrasi Akun Anggota Baru
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-7 col-xl-7 col-lg-7 text-center">
                                        <div class="row">
                                            <div class="col-sm-3 d-none">
                                                <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAllRegis">
                                                    <i class="fas fa-check"></i>
                                                </button>
                                                <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                    <i class="fas fa-print"></i>
                                                </button>
                                                <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                    <i class="fas fa-download"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover" id="tablePengajuanRegistrasi" width="100%" cellspacing="0">
                                                <thead>
                                                <tr>
                                                    <th class="text-center"><input type="checkbox" id="checkAllRegis"></th>
                                                    <th data-column="created_at" >Tanggal Registrasi</th>
                                                    <th data-column="name" >Nama Anggota</th>
                                                    <th>PD ID</th>
                                                    <th>NIK</th>
                                                    <th>PD</th>
                                                    <th>PC</th>
                                                    <th data-column="email">Email</th>
                                                    <th data-column="tanggal_lahir">Tanggal Lahir</th>
                                                    <th>No HP</th>
                                                    <th>Nominal Pembayaran</th>
                                                    <th data-column="tanggal_upload_bukti">Tanggal Upload Bukti bayar </th>
                                                    <th>Bukti Bayar</th>
                                                    <th>Status Pembayaran</th>
                                                    <th>Status Registrasi</th>
                                                    <th>Aksi</th>
                                                 </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer circle"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer circle"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer circle"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    
                                                </tfoot>
                                                <tbody>
                                                
                                                
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal fade" id="commentModalRegist" tabindex="-1" role="dialog" aria-labelledby="commentModalRegist"
                        aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Tolak Registrasi & Berikan Alasan Penolakan</h5>
                                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <form id="form-comment-regist">
                                                    <label class="control-label font-weight-bold">Alasan Penolakan Registrasi</label>
                                                    <textarea class="form-control" id="komentarRegistrasi" name="komentar" placeholder="Alasan Penolakan..." required></textarea>
                                                    <br>
                                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarRegistrasi"><i class='fas fa-comment'></i> Tolak & Berikan Alasan</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

        <div class="modal fade" id="commentModalRegistOld" tabindex="-1" role="dialog" aria-labelledby="commentModalRegist"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Tolak Registrasi Anggota Lama & Berikan Alasan Penolakan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment-regist">
                                    <label class="control-label font-weight-bold">Alasan Penolakan Registrasi Anggota Lama</label>
                                    <textarea class="form-control" id="komentarRegistrasiOld" name="komentar" placeholder="Alasan Penolakan..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarRegistrasiOld"><i class='fas fa-comment'></i> Tolak & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
		return view;
	  },

	async afterRender() {
		await RegistrasiAnggotaBaruPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default RegistrasiAnggotaBaru;