import ApiNotication from "../api/data-notification.js";
import NotificationModal from "../utils/initial_notification.js";

const NotificationInitiator = {

	async init() {
		await this._setView();
        await this.getAllNotification();
        await this._evenReadAll();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
      },

    async getAllNotification(){
        let request = await ApiNotication.getAllNotification();
        let item = ``
        let idNumber = 0
        if (request.status_code == 200) {
            const dataNotification = request.data.data;
            dataNotification.forEach(data => {
            idNumber++
            let timeCreated =  new Date(data.created_at).toLocaleDateString('en-US') + " " + new Date(data.created_at).toLocaleTimeString('en-US') ;
            if (this.isValidDate(timeCreated) == false) {
                timeCreated = data.created_at
              }
            let title =data.data.title;
            let isRead = data.read_at == null ? false : true
            let contentStatus = isRead == true ? `<span class="badge badge-success">Terbaca</span>` : `<span class="badge badge-danger">belum terbaca</span>`
            let contentButtonActionRead = isRead == true ? `<span class="badge badge-success">Sudah Terbaca</span>` : `<button type="button" id="btnUnread${idNumber}" class="btn btn-primary">
            <i class="fa fa-eye" aria-hidden="true"></i> Belum Terbaca
            </button>`

            let content = data.data.body;
            item += `<tr>
                <td>${timeCreated}</td>
                <td>${title}</td>
                <td>${content}</td>
                <td>
                    ${contentStatus}  
                </td>
                <td>
                    ${contentButtonActionRead}  
                </td>
                </tr>`
            }); 

            document.getElementById("content-notification-table").innerHTML = item; 
        
            //after render

            let idNumberAfterRender = 0
            dataNotification.forEach(data => {
                idNumberAfterRender++
                let idNotification =data.id;
                let idRendering = idNumberAfterRender
                let isRead = data.read_at == null ? false : true
                if (isRead == false) {
                    document.getElementById('btnUnread'+idNumberAfterRender).addEventListener('click',()=>{
                        this._readNotification(idNotification , idRendering)
                    }); 
                }
            }); 
           
        }
    },

    async _readNotification(notificationID , numberButton){	
        document.getElementById('btnUnread'+numberButton).innerHTML =`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
		let request = await ApiNotication.readNotification(notificationID);

        if (request.status_code == 200) {
            NotificationModal.show(`Notifikasi telah terbaca`, 'success');
            mRefresh.refresh();
            await this.getAllNotification();
            mRefresh.resolve();
        }else{
            NotificationModal.show(`${request.message}`, 'error');
            document.getElementById('btnUnread'+numberButton).innerHTML =` <i class="fa fa-eye" aria-hidden="true"></i> Belum Terbaca`
        }
	},


    async _evenReadAll(){	
        const eventReadAllAction = async (e) => {
			e.preventDefault();
				document.getElementById("btnReadAllNotif").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const request = await ApiNotication.readAllNotification()

				if (request.status_code == 200) {
					NotificationModal.show(`Semua Notifikasi Berhasil Dibaca`, 'success');
                    mRefresh.refresh();
                    await this.getAllNotification();
                    mRefresh.resolve();
				} else {
                    NotificationModal.show(`${request.message}`, 'error');
					document.getElementById("btnReadAllNotif").innerHTML = `Tandai Semua Sebagai Terbaca`;
				}
			e.preventDefault();
		}

        document.getElementById('btnReadAllNotif').addEventListener('click', eventReadAllAction);
	},

}

export default NotificationInitiator;
