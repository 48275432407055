import SyaratMutasiPresenter from "../../../presenter/menu_pengurus_pc/syarat-mutasi-pc-presenter.js";


const SyaratMutasiPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataUmum" class="nav-link hapus-border active" id="data-umum-tab" data-bs-toggle="tab" data-bs-target="#tabDataUmum" data-toggle="tab" type="button" role="tab" aria-controls="tabDataUmum" aria-selected="true">Formulir Input Persyaratan </button>
					</li>
				</ul>
				
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataUmum" role="tabpanel" aria-labelledby="data-umum-tab">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Informasi Persyaratan Mutasi
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Informasi Mutasi</label>
                                            <div class="col-sm-12">
                                                <textarea id="textInformasiSerkom" class="form-control" placeholder="Informasi Persyaratan..."></textarea>
                                            </div>
                                        </div>

                                        <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateInformasiSerkom"><i class='fas fa-edit'></i> Update Informasi</button>       
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Formulir Persyaratan Mutasi
                                    </div>
                                    <div class="card-body">
                                        <form id="form-persyaratan-mutasi">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
                                                <div class="col-sm-12">
                                                    <input type="hidden" id="textHIddenPersyaratan" class="form-control">
                                                    <input type="text" id="textNamaBerkas" class="form-control" placeholder="Nama Berkas..." required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Deskripsi</label>
                                                <div class="col-sm-12">
                                                    <textarea id="textDeskripsi" class="form-control" placeholder="Deskripsi Persyaratan..." required></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                                <div class="col-sm-12 text-right">
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnAddSyaratMutasi"><i class='fas fa-plus'></i> Tambah Persyaratan</button>
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateSyaratMutasi"><i class='fas fa-edit'></i> Edit Persyaratan</button>
                                                    <button type="button" class="mb-2 btn btn-sm btn-light border border-primary" id="btnClearSyaratMutasi"><i class='fas fa-eraser'></i> Batal</button>  
                                                </div>     
                                            </div>     
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Data Persyaratan Mutasi
                                    </div>
                                    <div class="card-body">
                        
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover" id="tablePersyaratanMutasi" width="100%" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Berkas</th>
                                                        <th>Deskripsi</th>
                                                        <th width="10%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    
                                                </tfoot>
                                                <tbody>
                                                
                                                
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>`;
		return view;
	  },

	async afterRender() {
		await SyaratMutasiPresenter.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SyaratMutasiPC;