import PengurusPPPresenter from "../../../presenter/menu_pengurus_pp/pengurus-pp-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';





const PengurusPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button href="#tabPeriodeKepengurusan" class="nav-link hapus-border active" id="data-verifikasi-anggota" data-bs-toggle="tab" data-bs-target="#tabPeriodeKepengurusan" data-toggle="tab" type="button" role="tab" aria-controls="tabPeriodeKepengurusan" aria-selected="true">Pengurus PAFI</button>
                    </li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabPeriodeKepengurusan" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
                        <div class="card mt-3">
                            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
                                Formulir Input Pengurus
                            </div>
                            <div class="card-body ">
                                <form id="form-pengurus" enctype="multipart/form-data">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group row">
                                                <input type="hidden" id="textHidden">
                                                <label class="control-label col-sm-12 font-weight-bold">Masa Bakti</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="selectPeriode" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nama Anggota</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="selectAnggota" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Jabatan</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="textJabatan" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                        <option value="ketua_umum">Ketua Umum</option>
                                                        <option value="ketua_1">Ketua I</option>
                                                        <option value="ketua_2">Ketua II</option>
                                                        <option value="sekretaris">Sekretaris Jendral</option>
                                                        <option value="wakil_sekretaris">Wakil Sekretaris Jendral</option>
                                                        <option value="bendahara_umum">Bendahara Umum</option>
                                                        <option value="wakil_bendahara_umum">Wakil Bendahara Umum</option>
                                                    </select>   
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Urut Tampil</label>
                                                <div class="col-sm-12">
                                                    <input type="number" class="form-control" id="textUrutTampil" required>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold text-primary"><h3>Publikasi</h3></label>
                                                <label class="control-label col-sm-12 font-weight-bold">Profile Pendidikan</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="selectPublishPendidikan" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                        <option value="1">Ya</option>
                                                        <option value="0">Tidak</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Profile Organisasi</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="selectPublishOrganisasi" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                        <option value="1">Ya</option>
                                                        <option value="0">Tidak</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Profile Tempat Kerja</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control" id="selectPublishTempatKerja" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                        <option value="1">Ya</option>
                                                        <option value="0">Tidak</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <button class="form-control mb-2 btn btn-sm btn-primary" id="btnAdd"><i class='fas fa-plus'></i> Tambah Pengurus</button>
                                            <button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdate"><i class='fas fa-edit'></i> Edit Pengurus</button>
                                            <button type="button" class="form-control btn btn-sm btn-secondary" id="btnClear"><i class='fas fa-eraser'></i> Batal</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pengurus
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tablePengurus" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th width="20%">Masa Bakti Kepengurusan</th>
												<th>Nama Lengkap</th>
												<th>Jabatan</th>
												<th>Foto</th>
												<th width="15%">Tanda Tangan</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                        </tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
                    </div>

				</div>
			</div>
		</div>

            <div class="modal fade" id="modalUploadTtd" tabindex="-1" role="dialog" aria-labelledby="modalUploadTtd"
            aria-hidden="true">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Upload Tanda Tangan</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body" id="ttd">
                            <input type="hidden" id="idPengurus">
                            <div id="form-upload-ttd" class="col-sm-12 dropzone">
                                <input type="hidden" name="file" style=""/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" id="btnSaveTtd">Save changes</button>
                            <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        <div style="display:none;" id="my-template">
            <div id="mytmp" class="dz-preview dz-file-preview">
                <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark">
                    <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>
                </div>
                <div class="dz-error-mark">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="19" r="2" />
                        <path d="M10 3h4v12h-4z" />
                    </svg>
                </div>
                <div class="dz-remove" data-dz-remove>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                    </svg>
                </div>
            </div>
        </div>
        
        `;
		return view;
	  },

	async afterRender() {
		await PengurusPPPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default PengurusPP;