import NotYourTimePresenter from "../../presenter/not-your-time-presenter.js";

const NotYourTime = {
    async render() {
        return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-6 col-lg-9 col-md-9">
      
                    <div class="card o-hidden border-0 shadow-lg my-2">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="p-5">
                                    <img loading="lazy" class="img-fluid rounded mx-auto d-block" src="././images/sorry.png" width="50%" alt="image login">
                                        <div class="text-center">
                                            <h1 id="noteMessage" class="h4 text-gray-900 mb-4">Maaf Belum diwaktu anda untuk memakai feature ini</h1>
                                                <a href="#/login" class="btn btn-sm text-center">&larr; Kembali ke Login</a>
                                                <a href="#/wilayah-time" class="btn btn-sm text-center d-none">&rarr; Lihat Jadwal Operasional Website</a>
                                        </div>

                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await NotYourTimePresenter.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default NotYourTime;
  

