import CONFIG from './config.js';

const API_PENGURUS_PC_ENDPOINT = {
	
	IP_SERVER: `${CONFIG.BASE_URL}login/ipServer`,
	LOGIN: (role) => `${CONFIG.BASE_URL}login/${role}`,
	LOGOUT : `${CONFIG.BASE_URL}logout`,

	GET_COMMENT_PENGAJUAN: (userID,commentFor,pengajuanID) => `${CONFIG.BASE_URL}comment/admin/${userID}/${commentFor}/${pengajuanID}`,
	SEND_COMMENT: `${CONFIG.BASE_URL}comment/admin/pc`,
	SEND_PENGAJUAN_SEMINAR_PENGURUS_PC: `${CONFIG.BASE_URL}permohonan/seminar/pc`,

	GET_REGISTRASI_ANGGOTA_LAMA: (status_regis) => `${CONFIG.BASE_URL}users/register/cabang?status_regis=${status_regis}`,
	GET_PENGAJUAN_SEMINAR_BY_ID: (idSeminar) => `${CONFIG.BASE_URL}permohonan/seminar/${idSeminar}`,
    
	PENGAJUAN_KTAN_DEFAULT: `${CONFIG.BASE_URL}pengajuan/admin/pc?jenis_pengajuan=membership`,
	ADD_MATERI_SEMINAR: `${CONFIG.BASE_URL}materi/seminar`,
	PENGAJUAN_KTAN_FILTERED: (startDate,endDate,status) => `${CONFIG.BASE_URL}pengajuan/admin/pc?jenis_pengajuan=membership&start_date=${startDate}&end_date=${endDate}&status=${status}`,
	APPROVE_KTAN:  (pengajuanID) => `${CONFIG.BASE_URL}pengajuan/approval/pc/${pengajuanID}`,
	DOWNLOAD_ANGGOTA:  (statusRegist, statusKTAN, start, length) => `${CONFIG.BASE_URL}users/cabang/download?start=${start}&length=${length}&status_regis=${statusRegist}&status_ktan=${statusKTAN}`,
	DOWNLOAD_KETERANGAN_KEANGGOTAAN:  (userId) => `${CONFIG.BASE_URL}print/surat-keterangan-keanggotaan/${userId}`,
	APPROVE_REGIS_OLD:  (pengajuanID) => `${CONFIG.BASE_URL}approval/register/old/${pengajuanID}`,
	APPROVE_All_KTAN: `${CONFIG.BASE_URL}pengajuan/approval/pc/bulk`,
	APPROVE_All_REGIS: `${CONFIG.BASE_URL}approval/register/pc/bulk`,

	GET_IURAN_ANGGOTA: (status,start_date, end_date) => `${CONFIG.BASE_URL}invoice/membership/admin?status=${status}&start_date=${start_date}&end_date=${end_date}`,
	GET_PENGAJUAN_SEMINAR: (status) => `${CONFIG.BASE_URL}permohonan/seminar/pc/list?status=${status}`,
	APPROVAL_IURAN_ANGGOTA: (id) => `${CONFIG.BASE_URL}invoice/membership/approval/${id}`,
	
	GET_ALL_USER_KTAN_1: (status_ktan, jenjang) => `${CONFIG.BASE_URL}users/cabang?status_regis=all&status_ktan=${status_ktan}&jenjang=${jenjang}`,


	GENERAL_SETTING: `${CONFIG.BASE_URL}setting/cabang/general`,
	IURAN_SETTING: `${CONFIG.BASE_URL}setting/cabang/iuran`,

	SKP: (status) => `${CONFIG.BASE_URL}skp/admin/pc?status=${status}`,
	SKP_DETAILS : (userID,status) => `${CONFIG.BASE_URL}skp/user/${userID}?status_pc=${status}`,
	APPROVE_SKP: (pengajuanSKPID) => `${CONFIG.BASE_URL}skp/approval/pc/${pengajuanSKPID}`,
	APPROVE_SKP_BULK :`${CONFIG.BASE_URL}skp/approval/pc/bulk`,
	APPROVE_SERKOM: (pengajuanSERKOMID) => `${CONFIG.BASE_URL}rekom/serkom/approval/pc/${pengajuanSERKOMID}`,
	APPROVE_All_SERKOM: `${CONFIG.BASE_URL}rekom/serkom/approval/pc/bulk`,
	SERKOM : (status, startDate, endDate) => `${CONFIG.BASE_URL}rekom/serkom/admin/pc?status=${status}&start_date=${startDate}&end_date=${endDate}`,
	SERKOM_INFORMATION : `${CONFIG.BASE_URL}setting/informasi/serkom`,
	KECUKUPAN_SKP_INFORMATION : `${CONFIG.BASE_URL}setting/informasi/kecukupan-skp`,

	STRTTK : (status) => `${CONFIG.BASE_URL}rekom/strttk/admin/pc?status=${status}`,
	SIPTTK_IN : (status, startDate, endDate) => `${CONFIG.BASE_URL}rekom/sipttk/admin/pc/in?status=${status}&start_date=${startDate}&end_date=${endDate}`,
	SIPTTK_OUT : (status, startDate, endDate) => `${CONFIG.BASE_URL}rekom/sipttk/admin/pc/out?status=${status}&start_date=${startDate}&end_date=${endDate}`,
	PENGAJUAN_STRTTK_BY_ID : (pengajuanID) => `${CONFIG.BASE_URL}rekom/strttk/admin/pc/${pengajuanID}`,
	STRTTK_INFORMATION : `${CONFIG.BASE_URL}setting/informasi/strttk`,
	SIPTTK_INFORMATION : `${CONFIG.BASE_URL}setting/informasi/sipttk`,
	APPROVE_STRTTK :  (pengajuanID) => `${CONFIG.BASE_URL}rekom/strttk/approval/pc/${pengajuanID}`,
	APPROVE_SIPTTK :  (pengajuanID) => `${CONFIG.BASE_URL}rekom/sipttk/approval/pc/sarana/${pengajuanID}`,
	APPROVE_SIPTTK_ASAL :  (pengajuanID) => `${CONFIG.BASE_URL}rekom/sipttk/approval/pc/asal/${pengajuanID}`,
	
	
	PERSYARATAN_SERKOM: `${CONFIG.BASE_URL}setting/additional/serkom`,
	PERSYARATAN_KECUKUPAN_SKP: `${CONFIG.BASE_URL}setting/additional/kecukupan-skp`,
	PERSYARATAN_SIPTTK: `${CONFIG.BASE_URL}setting/additional/sipttk`,
	UPDATE_PERSYARATAN: (persyaratanID) => `${CONFIG.BASE_URL}setting/additional/${persyaratanID}`,

	PERIODE_KEPENGURUSAN: `${CONFIG.BASE_URL}setting/kepengurusan/periode/pc`,
	PERIODE_KEPENGURUSAN_BY_ID: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,
	UPDATE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/pc/${id}`,
	UPDATE_KTAN_EXPIRES_DATE: (uuid) => `${CONFIG.BASE_URL}users/${uuid}`,
	DELETE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,


	GENERATE_INVOICE : `${CONFIG.BASE_URL}invoice/membership`, 

	ANGGOTA: (status_regis,status_ktan,search) => `${CONFIG.BASE_URL}users/cabang?status_regis=${status_regis}&status_ktan=${status_ktan}`,
	GET_ANGGOTA_BY_ID: (uuid) => `${CONFIG.BASE_URL}users/${uuid}`,
	FORCE_DELETE: (uuid) => `${CONFIG.BASE_URL}users/${uuid}/force`,
	CREATE_ANGGOTA : `${CONFIG.BASE_URL}users`,
	SEND_IURAN_BY_ID : (uuid) => `${CONFIG.BASE_URL}invoice/membership/${uuid}`,
	
	PENGURUS : `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pc`,
	PENGURUS_BY_ID : (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_PENGURUS: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pc/${id}`,
	DELETE_PENGURUS: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_TTD: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/media/${id}`,



	ADD_SYARAT_MUTASI: `${CONFIG.BASE_URL}setting/additional/mutasi`,
	PERSYARATAN_MUTASI: `${CONFIG.BASE_URL}setting/additional/mutasi`,
	INFORMATION_MUTASI : `${CONFIG.BASE_URL}setting/informasi/mutasi`,
	CREATE_MUTASI_MANUAL : `${CONFIG.BASE_URL}rekom/mutasi/manual`,
	GET_MUTASI_MANUAL: `${CONFIG.BASE_URL}rekom/mutasi/admin/pc/manual`,
	GET_FORMULIR_ANGGOTA :`${CONFIG.BASE_URL}rekom/mutasi/formulir`,
	APPLY_MUTASI_ANGGOTA :`${CONFIG.BASE_URL}rekom/mutasi/user`, 
	GET_MUTASI_ANGGOTA: `${CONFIG.BASE_URL}rekom/mutasi/user`,
	UPDATE_MUTASI: (id) => `${CONFIG.BASE_URL}rekom/mutasi/user/${id}`,
	GET_MUTASI_DETAIL : (id) => `${CONFIG.BASE_URL}rekom/mutasi/${id}`,
	UPDATE_MUTASI_BERKAS : (id) => `${CONFIG.BASE_URL}rekom/mutasi/user/media/${id}`,
	DELETE_MUTASI_ANGGOTA : (id) => `${CONFIG.BASE_URL}rekom/mutasi/${id}`,



	GET_ALL_MUTASI_DATA:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pc?status=${status}`,
	GET_DETAIL_MUTASI_PC:(id) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pc/${id}`,
	ACC_PC_ASAL_MUTASI:(id) => `${CONFIG.BASE_URL}rekom/mutasi/approval/pc/asal/${id}`,
	ACC_PC_TUJUAN_MUTASI:(id) => `${CONFIG.BASE_URL}rekom/mutasi/approval/pc/tujuan/${id}`,



	GET_TRACKING_MUTASI_PC : (pengajuanID , user_id) => `${CONFIG.BASE_URL}tracking/admin/${user_id}/rekom_mutasi/${pengajuanID}`,
	GET_TRACKING_MUTASI_ANGGOTA : (pengajuanID) => `${CONFIG.BASE_URL}tracking/user/rekom_mutasi/${pengajuanID}`,


	GET_REKAP_MUTASI_DATA: `${CONFIG.BASE_URL}rekom/mutasi/admin/pc/rekap?status=`,
	
	GET_ALL_MUTASI_DATA_IN_PC:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pc/in?status=${status}`,
	GET_ALL_MUTASI_DATA_OUT_PC:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pc/out?status=${status}`,


	GET_BERKAS_TUJUAN : (id) => `${CONFIG.BASE_URL}setting/additional/mutasi/${id}`,

	UPDATE_KECUKUPAN_BERKAS : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/user/media/${id}`,
};

export default API_PENGURUS_PC_ENDPOINT;
