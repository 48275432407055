import SIPTTKPCPresenter from "../../../presenter/menu_pengurus_pc/sipttk-anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const SIPTTKPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button href="#tabVerifikasiSIPTTKMasuk" class="nav-link hapus-border active" id="data-verifikasi-skp" data-bs-toggle="tab" data-bs-target="#tabVerifikasiSIPTTKMasuk" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiSIPTTKMasuk" aria-selected="true">Data Permohonan Masuk</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button href="#tabVerifikasiSIPTTKKeluar" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabVerifikasiSIPTTKKeluar" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiSIPTTKKeluar" aria-selected="true">Data Permohonan Keluar PC</button>
                    </li>
					<li class="nav-item" role="presentation">
					    <button href="#tabPersyaratanSIPTTK" class="nav-link hapus-border" id="tab3" data-bs-toggle="tab" data-bs-target="#tabPersyaratanSIPTTK" data-toggle="tab" type="button" role="tab" aria-controls="tabPersyaratanSIPTTK" aria-selected="false">Persyaratan & Setting Informasi</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiSIPTTKMasuk" role="tabpanel aria-labelledby="tab-verifikasi-skp">
						<div class="row mt-3">
                            <div class="col-sm-12">
                            
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status</label>
                                                <select id="selectStatus" class="form-control">
                                                    <option value="pending">Pending</option>
                                                    <option value="approve">Disetujui</option>
                                                    <option value="revise">Revisi</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Dari Tanggal</label>
                                                <input type="date" class="form-control" id="startDate">
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                                <input type="date" class="form-control" id="endDate">
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <button type="button" class="btn btn-primary" id="btnFilter">
                                                    <i class="fas fa-filter"></i> Filter Data
                                                </button>
                                                <button type="button" class="btn btn-warning" id="btnResetFilter">
                                                    <i class="fas fa-undo"></i> Reset Filter
                                                </button>
                                                <button type="button" class="btn btn-info" id="btnRefresh">
                                                    <i class="fas fa-sync-alt"></i> Refresh Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Pengajuan Rekomendasi SIPTTK Anggota Masuk
                                    </div>
                                    <div class="card-body">                                        
                                        <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                                            <div class="row">
                                                <div class="col-sm-8 mb-2">
                                                
                                                </div>
                                                <div class="col-sm-4 d-none">
                                                    <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                        <i class="fas fa-check"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                        <i class="fas fa-print"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                        <i class="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanSIPTTK" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th  data-column="name"  rowspan="2">Nama Anggota</th>
                                                                <th  data-column="created_at"  rowspan="2">Tanggal Pengajuan</th>
                                                                <th  data-column="jenis_permohonan"  rowspan="2">Jenis Permohonan</th>
                                                                <th  data-column="jenis_rekomendasi"  rowspan="2">Jenis Rekomendasi</th>
                                                                <th rowspan="2">PC Asal</th>
                                                                <th rowspan="2">PC Sarana</th>
                                                                <th colspan="4" align="center">Berkas Data Umum</th>
                                                                <th  data-column="status_pc" rowspan="2">Status PC</th>
                                                                <th  data-column="status_pc_sarana" rowspan="2">Status PC Sarana</th>
                                                                <th rowspan="2">Aksi</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Foto</th>
                                                                <th>KTP</th>
                                                                <th>STRTTK</th>
                                                                <th>SIPTTK</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>


                    <div class="tab-pane fade show" id="tabVerifikasiSIPTTKKeluar" role="tabpanel aria-labelledby="tab-verifikasi-skp">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status</label>
                                                <select id="selectStatusOut" class="form-control">
                                                    <option value="pending">Pending</option>
                                                    <option value="approve">Disetujui</option>
                                                    <option value="revise">Revisi</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Dari Tanggal</label>
                                                <input type="date" class="form-control" id="startDateOut">
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                                <input type="date" class="form-control" id="endDateOut">
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <button type="button" class="btn btn-primary" id="btnFilterOut">
                                                    <i class="fas fa-filter"></i> Filter Data
                                                </button>
                                                <button type="button" class="btn btn-warning" id="btnResetFilterOut">
                                                    <i class="fas fa-undo"></i> Reset Filter
                                                </button>
                                                <button type="button" class="btn btn-info" id="btnRefreshOut">
                                                    <i class="fas fa-sync-alt"></i> Refresh Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Pengajuan Rekomendasi SIPTTK Anggota Keluar PC
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanSIPTTKOut" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th  data-column="name" rowspan="2">Nama Anggota</th>
                                                                <th  data-column="created_at"  rowspan="2">Tanggal Pengajuan</th>
                                                                <th data-column="jenis_permohonan"  rowspan="2">Jenis Permohonan</th>
                                                                <th  data-column="jenis_rekomendasi" rowspan="2">Jenis Rekomendasi</th>
                                                                <th rowspan="2">PC Sarana</th>
                                                                <th colspan="4" align="center">Berkas Data Umum</th>
                                                                <th  data-column="status_pc" rowspan="2">Status PC</th>
                                                                <th data-column="status_pc_sarana" rowspan="2">Status PC Sarana</th>
                                                                <th rowspan="2">Aksi</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Foto</th>
                                                                <th>KTP</th>
                                                                <th>STRTTK</th>
                                                                <th>SIPTTK</th>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>





					<div class="tab-pane fade" id="tabPersyaratanSIPTTK" role="tabpanel" aria-labelledby="pengajuan-seminar-tab">
                        <div class="row">
                            <div class="col-sm-8 ">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Setting Informasi Rekomendasi SIPTTK
                                    </div>
                                    <div class="card-body">
                                            
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Informasi SIPTTK</label>
                                            <div class="col-sm-12">
                                                <textarea id="textInformasiSIPTTK" class="form-control" placeholder="Informasi Persyaratan..."></textarea>
                                            </div>
                                        </div>

                                        <button class="float-right mb-2 btn btn-sm btn-primary" id="btnUpdateInformasiSIPTTK"><i class='fas fa-edit'></i> Update Informasi</button>       
                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 ">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Persyaratan Rekomendasi SIPTTK
                                    </div>
                                    <div class="card-body">
                                        <form id="form-persyaratan-sipttk">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
                                                <div class="col-sm-12">
                                                    <input type="hidden" id="textHIddenPersyaratan" class="form-control">
                                                    <input type="text" id="textNamaBerkas" class="form-control" placeholder="Nama Berkas..." required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Deskripsi</label>
                                                <div class="col-sm-12">
                                                    <textarea id="textDeskripsi" class="form-control" placeholder="Deskripsi Persyaratan..." required></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                                <div class="col-sm-12 text-right">
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnAddSyaratSIPTTK"><i class='fas fa-plus'></i> Tambah Persyaratan</button>
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateSyaratSIPTTK"><i class='fas fa-edit'></i> Edit Persyaratan</button>
                                                    <button type="button" class="mb-2 btn btn-sm btn-light border border-primary" id="btnClearSyaratSIPTTK"><i class='fas fa-eraser'></i> Batal</button>  
                                                </div>     
                                            </div>     
                                        </form>      
                                    
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Data Persyaratan Rekomendasi SIPTTK
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover" id="tablePersyaratanSIPTTK" width="100%" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Berkas</th>
                                                        <th>Deskripsi</th>
                                                        <th width="10%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    
                                                </tfoot>
                                                <tbody>
                                                
                                                
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>

    <div class="modal fade" id="detailSIPTTKModalOut" tabindex="-1" role="dialog" aria-labelledby="detailSIPTTKModal"
        aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold " id="">Detail Pengajuan Rekomendasi Registrasi Ulang SIPTTK</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="bodyModalSIPTTK">
                        <div class="row">
                            <div class="col-sm-6 mb-2" style="border-top:2px solid orange" id="cols-update">
                                <div class="row">
                                    <div class="col-sm-12 mt-2">
                                        <label class="label control-label font-weight-bold ">Jenis Permohonan</label>
                                        <input type="text" id="textJenisPermohonan" placeholder="Jenis Permohonan..." class="form-control text-capitalize" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nama Anggota</label>
                                        <input type="text" id="textNamaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">KTAN</label>
                                        <input type="text" id="textKTAN" placeholder="KTAN..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">PD</label>
                                        <input type="text" id="textWilPD" placeholder="PD..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">PC</label>
                                        <input type="text" id="textWilPc" placeholder="PC..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Email</label>
                                        <input type="text" id="textEmail" placeholder="Email..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Alamat Domisili</label>
                                        <input type="text" id="textAlamatDomisili" placeholder="Alamat Domisili..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Desa / Kelurahan</label>
                                        <input type="text" id="textDesa" placeholder="Desa / Kelurahan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Kecamatan</label>
                                        <input type="text" id="textKecamatan" placeholder="Kecamatan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Kabupaten</label>
                                        <input type="text" id="textKabupaten" placeholder="Kabupaten..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Provinsi</label>
                                        <input type="text" id="textProvinsi" placeholder="Provinsi..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nomor STRTTK</label>
                                        <input type="text" id="textNoSTRTTK" placeholder="Nomor STRTTK..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Masa Berlaku STRTTK</label>
                                        <input type="text" id="textEDSTRTTK" placeholder="Masa Berlaku STRTTK..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12 overflow-auto">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <label class="label control-label font-weight-bold ">Data Pekerjaan (Sarana)</label>
                                                <table class="table table-bordered table-hovered overflow-auto" width="100%" style="width:100%" id="dataPekerjaan">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Hari Praktik</label>
                                        <input type="text" id="textHariPraktik" placeholder="Hari Praktik..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Jam Praktik</label>
                                        <textarea id="textJamPraktik" placeholder="Jam Praktik..." class="form-control" readonly></textarea>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nama Pimpinan</label>
                                        <input type="text" id="textNamaPimpinan" placeholder="Nama Pimpinan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Jabatan Pimpinan</label>
                                        <input type="text" id="textJabatanPimpinan" placeholder="Jabatan Pimpinan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12 mb-3">
                                        <label class="label control-label font-weight-bold ">Informasi Fasilitas</label>
                                        <input type="text" id="textInformasiFasilitas" placeholder="Informasi Fasilitas..." class="form-control" readonly>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-sm-6 mb-2" style="border-top:2px solid orange" >
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold ">Data Pendidikan</label>
                                    <div class="col-sm-12 overflow-auto">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Tahun Lulus</th>
                                                <th>Jenjang</th>
                                                <th>Nama Institusi</th>
                                                <th>Tanggal Ijazah</th>
                                            </thead>
                                            <tbody id="dataPendidikanSIPTTK">
        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold ">Persyaratan</label>
                                    <div class="col-sm-12 table-responsive">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Nama Persyaratan</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="persyaratanSIPTTK">
            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" id="out-hide">
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                                                <h6 class="m-0 font-weight-bold ">Komentar Kelengkapan Berkas</h6>
                                            </div>
                                            <div class="card-body chat-body p-1" id="lamanChat">
                                                <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <form id="form-comment">
                                                    <label class="control-label font-weight-bold">Komentar</label>
                                                    <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                                    <br>
                                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="detailSIPTTKModalIn" tabindex="-1" role="dialog" aria-labelledby="detailSIPTTKModalIn"
        aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold " id="">Detail Pengajuan Rekomendasi Registrasi Ulang SIPTTK</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="bodyModalSIPTTKIn">
                        <div class="row">
                            <div class="col-sm-6 mb-2" style="border-top:2px solid orange" id="cols-update">
                                <div class="row">
                                    <div class="col-sm-12 mt-2">
                                        <label class="label control-label font-weight-bold ">Jenis Permohonan</label>
                                        <input type="text" id="textJenisPermohonanIn" placeholder="Jenis Permohonan..." class="form-control text-capitalize" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nama Anggota</label>
                                        <input type="text" id="textNamaAnggotaIn" placeholder="Nama Anggota..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">KTAN</label>
                                        <input type="text" id="textKTANIn" placeholder="KTAN..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">PD</label>
                                        <input type="text" id="textWilPDIn" placeholder="PD..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">PC</label>
                                        <input type="text" id="textWilPcIn" placeholder="PC..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Email</label>
                                        <input type="text" id="textEmailIn" placeholder="Email..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Alamat Domisili</label>
                                        <input type="text" id="textAlamatDomisiliIn" placeholder="Alamat Domisili..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Desa / Kelurahan</label>
                                        <input type="text" id="textDesaIn" placeholder="Desa / Kelurahan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Kecamatan</label>
                                        <input type="text" id="textKecamatanIn" placeholder="Kecamatan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Kabupaten</label>
                                        <input type="text" id="textKabupatenIn" placeholder="Kabupaten..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Provinsi</label>
                                        <input type="text" id="textProvinsiIn" placeholder="Provinsi..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nomor STRTTK</label>
                                        <input type="text" id="textNoSTRTTKIn" placeholder="Nomor STRTTK..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Masa Berlaku STRTTK</label>
                                        <input type="text" id="textEDSTRTTKIn" placeholder="Masa Berlaku STRTTK..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12 overflow-auto">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <label class="label control-label font-weight-bold ">Data Pekerjaan (Sarana)</label>
                                                <table class="table table-bordered table-hovered overflow-auto" width="100%" style="width:100%" id="dataPekerjaanIn">
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Hari Praktik</label>
                                        <input type="text" id="textHariPraktikIn" placeholder="Hari Praktik..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Jam Praktik</label>
                                        <textarea id="textJamPraktikIn" placeholder="Jam Praktik..." class="form-control" readonly></textarea>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nama Pimpinan</label>
                                        <input type="text" id="textNamaPimpinanIn" placeholder="Nama Pimpinan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Jabatan Pimpinan</label>
                                        <input type="text" id="textJabatanPimpinanIn" placeholder="Jabatan Pimpinan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12 mb-3">
                                        <label class="label control-label font-weight-bold ">Informasi Fasilitas</label>
                                        <input type="text" id="textInformasiFasilitasIn" placeholder="Informasi Fasilitas..." class="form-control" readonly>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-sm-6 mb-2" style="border-top:2px solid orange" >
                                <div class="row mt-2">
                                    <label class="label col-sm-12 control-label font-weight-bold ">Data Pendidikan</label>
                                    <div class="col-sm-12 overflow-auto">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Tahun Lulus</th>
                                                <th>Jenjang</th>
                                                <th>Nama Institusi</th>
                                                <th>Tanggal Ijazah</th>
                                            </thead>
                                            <tbody id="dataPendidikanSIPTTKIn">
        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold ">Persyaratan</label>
                                    <div class="col-sm-12 table-responsive">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Nama Persyaratan</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="persyaratanSIPTTKIn">
            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row" id="out-hide">
                                    <div class="col-sm-12 mt-2">
                                        <label class="label control-label font-weight-bold ">Tanggal Surat Rekomendasi</label>
                                        <input type="hidden" id="textHiddenIn" class="form-control" readonly>
                                        <input type="text" id="tanggalSuratRekomendasiIn" class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Nomor Surat Rekomendasi</label>
                                        <input type="text" id="textNomorSuratIn" class="form-control">
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Masa Berlaku Surat Rekomendasi <i>Contoh</i> : 1 (Satu) Bulan</label>
                                        <input type="text" id="textMasaBerlakuIn" class="form-control">
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Status</label>
                                        <select class="form-control" id="selectStatusApproveIn">
                                            <option value="">Pilih Salah Satu</option>
                                            <option value="approve">Acc</option>
                                            <option value="revise">Tolak</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label font-weight-bold ">Catatan</label>
                                        <textarea id="textCatatanIn" class="form-control" placeholder="Isi jika pengajuan rekomendasi di tolak"></textarea>
                                    </div>
                                    <div class="col-sm-12">
                                        <button style="width:100%;" class="btn btn-sm btn-primary" id="btnSaveIn"><i class="fas fa-save"></i> Simpan</button>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-header d-flex flex-row align-items-center justify-content-between">
                                                <h6 class="m-0 font-weight-bold ">Komentar Kelengkapan Berkas</h6>
                                            </div>
                                            <div class="card-body chat-body p-1" id="lamanChatin">
                                                <div class="chat-body" id="content-commentIn"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <form id="form-comment">
                                                    <label class="control-label font-weight-bold">Komentar</label>
                                                    <textarea class="form-control" id="textKomentarIn" name="komentar" placeholder="Komentar..." required></textarea>
                                                    <br>
                                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendCommentIn"><i class='fas fa-comment'></i> Kirim</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancelApporveIn" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    


<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Komentar !</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea name="komentarPengajuan" id="textKomentarPengajuan" class="form-control" placeholder="Komentar..." required></textarea>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                <a class="btn btn-primary" id="btnSendKomentarPengajuan">Send</a>
            </div>
        </div>
    </div>
</div>
        
        `;
		return view;
	  },

	async afterRender() {
		await SIPTTKPCPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SIPTTKPC;