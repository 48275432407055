import ApiBerita from "../../api/data-berita.js";
import BERITA_END_POINT from "../../config/globals/berita_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import NotificationModal from "../../utils/initial_notification.js";
import ImagePopUPInitiator from "../pop-up-presenter.js";




const BeritaPresenterPP = {

	async init() {
		await this._setView();
		await this._initSendNewBerita()
		await this._getNews();
		await this.initEditBerita();

		await this.initDetail();
		await this.initDelete()

		await this.initPublish()
	},

    async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},

    async _initGetFilteredBerita() {
		
	},

    async _initSendNewBerita() {
		$("#btnAdd").click(()=>{
			document.getElementById("titleModalPengajuan").innerHTML = "Tambah Berita"
			document.getElementById("statePengajuan").value ="add"
			document.getElementById("stateId").value = "";
			
			document.getElementById("containerImageEdit").classList.add("d-none")
			document.getElementById("form-pengajuan-berita").reset()
		})

		let DzoneFile = new Dropzone("#form-upload-file", { 
			url: "/file/post", 
			uploadMultiple: true,
			autoDiscover: false, 
			maxFiles: 5,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png",
			dictDefaultMessage: 'Masukan Image (Max 5)',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				let dropzoneInstance = this;

				this.on("maxfilesreached", function() {
					document.getElementById("upload-message").style.display = "block";
				});
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("removeThumbnailCustom", function() { 
					this.removeAllFiles();
					$('#dropzone-area .dz-preview:hidden').remove()
					$('#dropzone-area .dz-preview').fadeOut('slow');
				});

				this.on("addedfile", function(file) {
					if (this.files.length >= this.options.maxFiles) {
						document.querySelector(".dz-default.dz-message").style.display = "none";
					}else{
						document.querySelector(".dz-default.dz-message").style.display = "block";
					}
				});

				this.on("queuecomplete", function() {
					document.getElementById("upload-message").style.display = "none";
				});

				this.on("removedfile", function(file) {
					if (dropzoneInstance.files.length === 0) {
						document.querySelector(".dz-default.dz-message").style.display = "block";
					}
					if (dropzoneInstance.files.length < 5) {
						document.querySelector(".dz-default.dz-message").style.display = "block";
					}
				});
			} 
		});
		
		
		try {
			tinymce.remove('#textDeskripsi')
			tinymce.init({
				selector: '#textDeskripsi',
				menubar: false,
				min_height: 500,
				plugins: 'print | link',
				visual: false,
				inline_styles: true,
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
				fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
			});
	
		} catch (error) {
			
		}
	
		try {
			tinymce.remove('#textDeskripsi')
			tinymce.init({
				selector: '#textDeskripsi',
				menubar: false,
				min_height: 500,
				plugins: 'print | link',
				visual: false,
				inline_styles: true,
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
				fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
			});
	
		} catch (error) {
			
		}

		const eventCreateSend = async (e) => {
			e.preventDefault();
			let state = $("#statePengajuan").val();
		
			let textKategori = $("#selectKategori").val();
			let textJudul = $("#textJudul").val();
			let textDeskripsi = tinymce.get("textDeskripsi").getContent();
			
			// state add
			if (state == "add") {
				if (DzoneFile.files.length <= 0) {
					NotificationModal.show('File upload Lampiran tidak boleh kosong !', 'error')
					document.getElementById("btnSave").innerHTML = `<i class="fas fa-save"></i> Simpan</button>`;
				}else {
					let valueArrayConverted = []
					let fileDzoneFileList = DzoneFile.files;

					for (let index = 0; index < fileDzoneFileList.length ; index++) {
						const element = fileDzoneFileList[index];
						let data = await this.dataUrlToFile(element.dataURL ,element.name )
						valueArrayConverted.push(data)	
					}
				

					document.getElementById("btnSave").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					
					const createPengajuanSeminar = await ApiBerita.sendBerita({
						title : textJudul,
						category : textKategori,
						content : textDeskripsi,
						fileList : valueArrayConverted,
					});
	
					if (createPengajuanSeminar.status_code != 201) {
						NotificationModal.show(`${createPengajuanSeminar.message}`, 'error');
						DzoneFile.emit("removeThumbnailCustom");
						document.getElementById("btnSave").innerHTML = `<i class="fas fa-save"></i> Simpan</button>`;
						
					} else {
						swal.fire({
							title: 'Success',
							html: createPengajuanSeminar.message,
							icon: 'success',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: '<i class="fas fa-check"></i> Ok'
						}).then(async (result) => {
							if (result.value) {
								
							};
						});
						
                        $("#tableNews").DataTable().ajax.reload();
						DzoneFile.emit("removeThumbnailCustom");
						document.getElementById("btnSave").innerHTML = `<i class="fas fa-save"></i> Simpan</button>`;
						
						$("#btnReset").trigger("click");
						$('#modalTambahPengajuan').modal("hide");
					}
					
	
					e.preventDefault();
	
				}
			}		


			if (state == "edit") {
				let stateId =  $("#stateId").val();
				
					let valueArrayConverted = []
					let fileDzoneFileList = DzoneFile.files;

					for (let index = 0; index < fileDzoneFileList.length ; index++) {
						const element = fileDzoneFileList[index];
						let data = await this.dataUrlToFile(element.dataURL ,element.name )
						valueArrayConverted.push(data)	
					}
				

					document.getElementById("btnSave").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					
					const createPengajuanSeminar = await ApiBerita.editBeritaFormData({
						title : textJudul,
						category : textKategori,
						content : textDeskripsi,
						fileList : valueArrayConverted,
						idValue : stateId
					});
	
					if (createPengajuanSeminar.status_code != 200) {
						NotificationModal.show(`${createPengajuanSeminar.message}`, 'error');
						DzoneFile.emit("removeThumbnailCustom");
						document.getElementById("btnSave").innerHTML = `<i class="fas fa-save"></i> Simpan</button>`;
						
					} else {
						swal.fire({
							title: 'Success',
							html: createPengajuanSeminar.message,
							icon: 'success',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: '<i class="fas fa-check"></i> Ok'
						}).then(async (result) => {
							if (result.value) {
								
							};
						});

                        $("#tableNews").DataTable().ajax.reload();
						DzoneFile.emit("removeThumbnailCustom");
						document.getElementById("btnSave").innerHTML = `<i class="fas fa-save"></i> Simpan</button>`;
						
						$("#btnReset").trigger("click");
						$('#modalTambahPengajuan').modal("hide");
					}
					
	
					e.preventDefault();
			} 
		
			e.preventDefault();
		}


		document.getElementById('btnSave').addEventListener('click', eventCreateSend);

        
	},

	async initEditBerita(){
		$('#tableNews tbody').on('click', '#edit', async function () {
			const table = $('#tableNews').DataTable();
			tinymce.remove('#textDeskripsi')
		

			tinymce.init({
				selector: '#textDeskripsi',
				menubar: false,
				min_height: 500,
				plugins: 'link',
				toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link',
				setup: (editor) => {
					editor.on('init', () => {
						// Memastikan input bisa diakses
						document.querySelector('.tox-tinymce').style.zIndex = '9999';
					});
				}
			});
	
				document.getElementById("form-pengajuan-berita").reset()
				document.getElementById("titleModalPengajuan").innerHTML = "Edit Berita"
				document.getElementById("statePengajuan").value ="edit"
				let data = table.row($(this).parents('tr')).data();
				let idBerita = data.id;
				document.getElementById("stateId").value = idBerita;
				const getDataResponse = await ApiBerita.getBeritaById({
					idValue : idBerita
				});
				if (getDataResponse.status_code != 200) {
					NotificationModal.show(`${getDataResponse.message}`, "error");
				}else {
					let dataDet = getDataResponse.data;
					$("#selectKategori").val(dataDet.category).change();
					$("#textJudul").val(dataDet.title);
					document.getElementById("containerImageEdit").classList.remove("d-none")
					let headContent = `<div class="mt-2 mb-2">`
					let footContent = `<h6 class="text-lowercase"> * Silahkan Upload ulang lagi jika Anda ingin mengubah Lampiran .</h6>
					</div>`
					let content = ``
					for (let index = 0; index < dataDet.attachments.length ; index++) {
						const element = dataDet.attachments[index].url;
						content += `<img loading="lazy" id="show" class="show-image-pop-up ml-4" name="show_image_brosur" src="${element}" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">`
					}
					if (content != ``) {
						let theValueContent = headContent + content + footContent;
						document.getElementById("containerImageEdit").innerHTML = theValueContent
						ImagePopUPInitiator.getImage()
					}

					try {
						tinymce.get('textDeskripsi').setContent(dataDet.content);	
					} catch (error) {
						
					}
				}
			});

			
			   $(document).on('focusin', function(e) {
				if ($(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length) {
				   e.stopImmediatePropagation();
				}
			  });
			  
	},

	async initDetail(){
		$('#tableNews tbody').on('click', '#detail', async function () {
				const table = $('#tableNews').DataTable();
				
				let data = table.row($(this).parents('tr')).data();
				let idBerita = data.id;
				document.getElementById("stateId").value = idBerita;
				const getDataResponse = await ApiBerita.getBeritaById({
					idValue : idBerita
				});
				if (getDataResponse.status_code != 200) {
					NotificationModal.show(`${getDataResponse.message}`, "error");
				}else {
					let dataDet = getDataResponse.data;
					$("#selectKategoriDetail").val(dataDet.category)
					$("#textJudulDetail").val(dataDet.title);
					
					let headContent = `<div class="mt-2 mb-2">`
					let footContent = `</div>`
					let content = ``
					for (let index = 0; index < dataDet.attachments.length ; index++) {
						const element = dataDet.attachments[index].url;
						content += `<img loading="lazy" id="show" class="show-image-pop-up ml-4" name="show_image_brosur" src="${element}" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="100px">`
					}
					if (content != ``) {
						let theValueContent = headContent + content + footContent;
						document.getElementById("containerImageEditDetail").innerHTML = theValueContent
						ImagePopUPInitiator.getImage()
					}
					document.getElementById("content-detail-modal").innerHTML = dataDet.content;
				}
			});
	},

	async initDelete(){
		$('#tableNews tbody').on('click', '#delete', async function () {
				const table = $('#tableNews').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Hapus Berita',
					html: "Anda ingin Hapus Berita ini ?\ " + data.title,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: ' #d33',
					cancelButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Delete!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiBerita.deleteBerita({
							idValue: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Delete',
								text: "Data berhasil dihapus",
								icon: 'success',
								showConfirmButton: true,
								timer: 1000
							});

							await $('#tableNews').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: true,
								timer: 1000
							});
						}
					};
				});
			});
	},

	async initPublish(){
		$('#tableNews tbody').on('click', '#publish', async function () {
				const table = $('#tableNews').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Publish Berita',
					html: "Anda ingin mengpublish Berita ini ?\ " + data.title,
					icon: 'warning',
					showCancelButton: true,
					cancelButtonColor: ' #d33',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Publish!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiBerita.publishBerita({
							idValue: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Publish',
								text: "Data berhasil dipublish",
								icon: 'success',
								showConfirmButton: false,
								timer: 3000
							});

							await $('#tableNews').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 3000
							});
						}
					};
				});
			});

		$('#tableNews tbody').on('click', '#unpublish', async function () {
				const table = $('#tableNews').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Batalkan Publish',
					html: "Anda ingin membatalkan Publish Berita ini ?\ " + data.title,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: ' #d33',
					cancelButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Batal Publish!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiBerita.unPublishBerita({
							idValue: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Publish',
								text: "Data berhasil dibatalkan dari publish",
								icon: 'success',
								showConfirmButton: false,
								timer: 3000
							});

							await $('#tableNews').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 3000
							});
						}
					};
				});
		});
	},

	

	async _getNews(){

		
		const eventFiltered = async () =>{
			let status = document.getElementById('statusBerita').value;
			
			let fromDateInput = document.getElementById('fromDate');
			let untilDateInput = document.getElementById('untilDate');

			let fromDate = this.formatDate(fromDateInput.value);
			let untilDate = this.formatDate(untilDateInput.value);

			this._getNewsList(status, fromDate, untilDate)
		}
	
		document.getElementById('btnFilter').addEventListener('click', eventFiltered);

		await eventFiltered();

    },

	formatDate(inputDate) {
		if (!inputDate) {
			return null;
		}
	
		let [year, month, day] = inputDate.split('-');
		;
		
		return `${day}-${month}-${year}`;
	},


	async _getNewsList(status,endDate,startDate){
		
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tableNews').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${BERITA_END_POINT.GET_BERITA(status,endDate,startDate)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				},
			},
			"columns": [
				{ data: null },
				{ data: 'title' },
				{ data: 'category' },
				{ data: 'status_berita' },
				{ data: 'created_at' },
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false,
					"orderable": false,
					"render": function (data, type, row, meta) {
						return meta.row + 1;
					}
				},
				{
					"targets": [5],
					"visible": true,
					"searchable": false,
					"className" :"text-center"
				},
				{
					"targets": 5,
					"data": null,
					render: function (data) {
						let content = ``;
						if (data.status_berita == 'draft') {
							content = `
							<div style="display:flex" class="mr-1">
								<button class='ml-1 btn btn-primary btn-sm' id='detail' title='Detail' data-toggle="modal" data-target="#modalDetail"><i class="fas fa-eye"></i></button>
								<button class='ml-1 btn btn-warning btn-sm' id='edit' title='Edit Pengajuan' data-toggle="modal" data-target="#modalTambahPengajuan"><i class="fas fa-edit"></i></button>
								<button class='ml-1 btn btn-danger btn-sm' id='delete' title='Delte Pengajuan' ><i class="fas fa-trash"></i></button>
							</div>
							<button class='ml-1 mt-2 btn btn-outline-primary btn-sm form-control' id='publish' title='Publish' ><i class="fas fa-plane"></i> Publish</button>
							`
						}else {
							content = `
							<div style="display:flex" class="mr-1">
								<button class='ml-1 btn btn-primary btn-sm' id='detail' title='Detail' data-toggle="modal" data-target="#modalDetail"><i class="fas fa-eye"></i></button>
							</div>
							<button class='ml-1 mt-2 btn btn-outline-danger btn-sm form-control' id='unpublish' title='UnPublish' ><i class="fas fa-plane"></i> Batal Publish</button>
							`
						}

						return content

						
					}
					
				},
				]
		});

		$('#tableNews thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			var columnName = ($(this)[0]).getAttribute('data-column')
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});
	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},


}


export default BeritaPresenterPP;