
const notificationRoutesPC = {
	'rekom_skp': "#/serkom-anggota-pc",
	'rekom_mutasi': "#/rekom-mutasi-pc",
	'rekom_strttk': "#/strttk-anggota-pc",
	'rekom_sipttk': "#/sipttk-anggota-pc",
	'membership' : "#/",
	'membership_fee' :"#/iuran-anggota-pc",
	'rekom_serkom' : "#/serkom-anggota-pc"
};

const notificationRoutesPP = {
	'rekom_skp': "#/",
	'rekom_mutasi': "#/",
	'rekom_strttk': "#/",
	'rekom_sipttk': "#/",
	'membership' : "#/",
	'membership_fee' :"#/",
	'rekom_serkom' : "#/"
};

const notificationRoutesPD = {
	'rekom_skp': "#/skp-anggota-pd",
	'rekom_mutasi': "#/rekom-mutasi-pd",
	'rekom_strttk': "#/strttk-anggota-pd",
	'rekom_sipttk': "#/",
	'membership' : "#/",
	'membership_fee' :"#/",
	'rekom_serkom' : "#/serkom-anggota-pd"
};

const notificationRoutesAnggota = {
	'rekom_skp': "#/skp-saya",
	'rekom_mutasi': "#/pengajuan-mutasi",
	'rekom_strttk': "#/rekom-strttk",
	'rekom_sipttk': "#/rekom-sipttk",
	'membership' : "#/",
	'membership_fee' :"#/iuran",
	'rekom_serkom' : "#/serkom-skp",
	'permohonan_kecukupan_skp' : "#/kecukupan-skp"
};

const NotificationRoutes = {
	
	getRouting(notiableLevel , type){
		if (notiableLevel == "pc") {
			return this.handleNoAction(notificationRoutesPC[type]) 
		}
		if (notiableLevel == "pp") {
			return this.handleNoAction(notificationRoutesPP[type])
		}
		if (notiableLevel == "pd") {
			return this.handleNoAction(notificationRoutesPD[type])
		}
		if (notiableLevel == "anggota") {
			return this.handleNoAction(notificationRoutesAnggota[type])
		}
	},

	handleNoAction(valueData){
		if (valueData == undefined || valueData == null || valueData == "") {
			return "#/"
		}else{
			return valueData
		}
	}
}


export default NotificationRoutes;