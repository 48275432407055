
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import UrlParser from "../../routes/url-parser.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiNotication from "../../api/data-notification.js";

const UpdatePasswordPresenter = {

	async init() {
		await this._setView();
		await this._updatePassword();
	},

	async _readNotification(){
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},
	
	
	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSKP").hide();
	},

	
	async _showLoading(){
		
		
	},
	
	async _hideLoading(){
		
	},
	async _updatePassword(){
		
		const eventValidation = async (e) => {
			let newPass = $("#textNewPass").val();
			let confirmPass = $("#textConfirmPass").val();
			if (confirmPass != newPass) {
				$("#textConfirmPass").removeClass("is-valid");
				$("#textConfirmPass").addClass("is-invalid");
			}else if(confirmPass == "" || confirmPass == null) {
				$("#textConfirmPass").removeClass("is-invalid");
				$("#textConfirmPass").removeClass("is-valid");
			}else {
				$("#textConfirmPass").removeClass("is-invalid");
				$("#textConfirmPass").addClass("is-valid")
			}
		}
		document.getElementById('textConfirmPass').addEventListener('keyup', eventValidation);
		const eventUpdatePass = async (e) => {
				
			document.getElementById("btnUpdatePassword").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			
			const updatePassword = await ApiAnggota.updatePassword({
				currentPassword : $("#textOldPass").val(),
				newPassword : $("#textNewPass").val(),
				passwordConfirmation : $("#textConfirmPass").val(),
			});

			if (updatePassword.status_code != 200) {
				NotificationModal.show(`${updatePassword.message}`, 'error');
				document.getElementById("btnUpdatePassword").innerHTML = `<i class="fas fa-key"></i> Ubah Password`;
				document.getElementById('form-update-password').reset()
			} else {
				NotificationModal.show('Passworb berhasil di ubah', 'success');
				document.getElementById("btnUpdatePassword").innerHTML = `<i class="fas fa-key"></i> Ubah Password`;
				document.getElementById('form-update-password').reset()
				$("#textConfirmPass").removeClass("is-valid");
			}
			e.preventDefault();
			

            
		}
		document.getElementById('form-update-password').addEventListener('submit', eventUpdatePass);
	}

	


}

export default UpdatePasswordPresenter;