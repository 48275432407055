
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSerkomPD from '../../api/api_pengurus_pd/pengajuan-serkom.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";


const SerkomPD = {

	async init() {
		await StorageAnggotaPD.makeStore()
		await this._setView();
		await this._getDataPrivate();
		await this._getPengajuanSerkomFiltered();
		await this._approvePengajuanSerkom();
		await this._checkAllEvent();
		await this._initAllApprove();
		await this._initDownloadSerkomBulk();
		await this._downloadSerkom();
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSyaratSerkom").hide();
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }

	},

	async _getDataPrivate() {

		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)

		var selectPC = document.getElementById("selectPengajuanPC");
		var lengthOptionPc = selectPC.options.length;
		for (let i = lengthOptionPc - 1; i >= 0; i--) {
			selectPC.options[i] = null;
		}

		document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
		});


	},

	
	async _getPengajuanSerkomFiltered(){
		let status = document.getElementById('selectStatus').value;
		let startDate = document.getElementById('startDate').value;
		let endDate = document.getElementById('endDate').value;
		let selectPC = document.getElementById('selectPengajuanPC').value;
		await this._getPengajuanSerkom(status, startDate, endDate, selectPC);

		const eventFiltered = async () => {
			let status = document.getElementById('selectStatus').value;
			let startDate = document.getElementById('startDate').value;
			let endDate = document.getElementById('endDate').value;
			let selectPC = document.getElementById('selectPengajuanPC').value;
			await this._getPengajuanSerkom(status, startDate, endDate, selectPC);
		}

		const eventResetFilter = async () => {
			let status = document.getElementById('selectStatus').value = 'pending_pd';
			let startDate = document.getElementById('startDate').value = '';
			let endDate = document.getElementById('endDate').value = '';
			let selectPC = document.getElementById('selectPengajuanPC').value = '';
			await this._getPengajuanSerkom(status, startDate, endDate, selectPC);
		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefresh').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilter').addEventListener('click', eventResetFilter);

	},

	async _getPengajuanSerkom(status, startDate, endDate, selectPC) {	
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		if (status == 'approve_pd') {
			var bg = 'bg-success'
			var disabled = 'd-none'
			$('#btnVerifikasiAllDt').hide()
			$('#btnDownloadsSelected').removeClass('d-none')
		} else if (status == 'revise_pd') {
			var bg = 'bg-warning'
			var disable_download = 'd-none'
			$('#btnVerifikasiAllDt').hide()
			$('#btnDownloadsSelected').addClass('d-none')
		} else if (status == 'pending_pd') {
			var disable_download = 'd-none'
			var bg = 'bg-primary'
			$('#btnVerifikasiAllDt').show()
			$('#btnDownloadsSelected').addClass('d-none')
		}else if (status == "all") {
			var disabled = 'd-none'
			$('#btnVerifikasiAllDt').hide()
		}

		let table = await $('#tablePengajuanSERKOM').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary " + disabled,
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				},

				{
					text: "<i class='fas fa-download'></i> Download Selected",
					exportOptions: {
						columns: ':visible'
					},
					className: `btn btn-primary ${disable_download}`,
					titleAttr: "Download",
					attr: {
						id: "btnDownloadsSelected"
					},
					action: function () {
						$("#btnDownloadsSelected").html("Loading...");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.SERKOM(status, startDate, endDate, selectPC)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'user.name' },
				{ data: 'cabang_name' },
				{ data: 'user.ktan' },
				{ data: 'serkom.tanggal' },
				{ data: 'serkom.nomor_serkom' },
				{
					data: 'pendidikan.jenjang', render: function (data) {

						var field = `
					<select class="form-control" id="selectJenjangPendidikan" name="jenjang_pendidikan" required>
						<option value="${data}">${data}</option>
						<option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
						<option value="D3 FARMASI">D3 FARMASI</option>
						<option value="D3 ANAFARMA">D3 ANAFARMA</option>
						<option value="S1 FARMASI">S1 FARMASI</option>
						<option value="S2 FARMASI">S2 FARMASI</option>
						<option value="S3 FARMASI">S3 FARMASI</option>
					</select>`;

						return field
					}
				},
				{
					data: 'status', render: function (data) {
						if (data == 'Pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'Revisi PC') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'Revisi PD') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},

				{
					data: 'new_serkom.no_serkom_pp', render: function (data) {
						var text = '';
						if (data == '' || data == null) {
							text = 'Menunggu persetujuan PD'
						} else {
							text = data
						}

						return text
					}
				},
				{
					data: 'new_serkom.kompetensi', render: function (data) {
						var text = '';
						if (data == '' || data == null) {
							text = 'Menunggu persetujuan PD'
						} else {
							text = data
						}

						return text
					}
				},
				{
					data: 'new_serkom.berlaku_dari', render: function (data) {
						var text = '';
						if (data == '' || data == null) {
							text = 'Menunggu persetujuan PD'
						} else {
							text = data
						}

						return text
					}
				},
				{
					data: 'new_serkom.berlaku_sampai', render: function (data) {
						var text = '';
						if (data == '' || data == null) {
							text = 'Menunggu persetujuan PD'
						} else {
							text = data
						}

						return text
					}
				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanSERKOM tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPD.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": 0,
					"orderable": false,
					className: 'select-checkbox',
					"data": null,
					"defaultContent": ``
				},
				{
					"targets": [3,5,6,7,9,10,11,12,13],
					"orderable": false
				},
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 13,
					"data": null,
					"render": function (data, type, row) {
						if (row.status =="Disetujui PD") {
							var disabled = "d-none"
							
						}
					return `<div style="display:flex">
						<button style="margin:1px" class='btn btn-primary btn-sm ${disable_download} text-center' id='download' title='Download'><i class="fas fa-download"></i></button>
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSerkomModal" id='detail' title='Detail'><i class="fas fa-list"></i></button>
						<button style="margin:1px" class='btn btn-primary btn-sm ${disabled}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
					</div>`
					},
					
				},
			]
		});



		$('#tablePengajuanSERKOM tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				let alamat = data.domisili.address + ' Desa / Kelurahan ' + data.domisili.village_name + ' Kecamatan ' + data.domisili.sub_regency_name + ' ' + data.domisili.regency_name + ' Provinsi ' + data.domisili.province_name;
				let alamatLower = alamat.toLowerCase();
				let alamatUpper = alamatLower.toUpperCase();
				let linkValidtion = `${CONFIG.BASE_URL}validation/serkom/${data.new_serkom.credential}`;
				await StorageAnggotaPD.deleteData(data.id, data.new_serkom.no_serkom_pp, data.user.name, data.user.jenis_kelamin, data.user.tempat_lahir, data.user.tanggal_lahir, alamatUpper, data.pendidikan.nomor_ijazah, data.pendidikan.tanggal_ijazah, data.pendidikan.jenjang, linkValidtion);
			} else {
				$(this).parents('tr').addClass('selected');
				let alamat = data.domisili.address + ' Desa / Kelurahan ' + data.domisili.village_name + ' Kecamatan ' + data.domisili.sub_regency_name + ' ' + data.domisili.regency_name + ' Provinsi ' + data.domisili.province_name;
				let alamatLower = alamat.toLowerCase();
				let alamatUpper = alamatLower.toUpperCase();
				let linkValidtion = `${CONFIG.BASE_URL}validation/serkom/${data.new_serkom.credential}`;
				await StorageAnggotaPD.pushData(data.id, data.new_serkom.no_serkom_pp, data.user.name, data.user.jenis_kelamin, data.user.tempat_lahir, data.user.tanggal_lahir, alamatUpper, data.pendidikan.nomor_ijazah, data.pendidikan.tanggal_ijazah, data.pendidikan.jenjang, linkValidtion)
			}
		});

		$('#tablePengajuanSERKOM tbody').off('click', '#detail').on('click', '#detail', async function () {
			let UserID = '';
			let PengajuanID = '';
			$("#persyaratanSerkom").html('');
			$("#dataPendidikanSerkom").html('');
			$("#dataSTRTTK").html('');
			let table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const getDetailPenagjuanSerkom = await ApiAnggota.getPengajuanSerkomByID({ id });

			let dataDetail = getDetailPenagjuanSerkom.data;
			if (getDetailPenagjuanSerkom.status_code == 200) {
				UserID = dataDetail.user_id;
				PengajuanID = dataDetail.id;
				$("#tanggalPengajuan").val(dataDetail.created_at);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textKTAN").val(dataDetail.user.ktan);
				$("#textTempatLahir").val(dataDetail.user.tempat_lahir);
				$("#textTanggalLahir").val(dataDetail.user.tanggal_lahir);
				if (dataDetail.domisili) {
					$("#textAlamat").val(dataDetail.domisili.address + ', Desa / Kelurahan ' + dataDetail.domisili.village_name + ', Kecamatan ' + dataDetail.domisili.sub_regency_name + ', ' + dataDetail.domisili.regency_name + ', Provinsi ' + dataDetail.domisili.province_name);
				} else {
					$("#textAlamat").val('');
				}

				var dokumen = dataDetail.dokumen
				if (dokumen == '' || dokumen == null) {
					$("#persyaratanSerkom").append('<td colspan="2" align="center" class="text-warning">Anggota belum mengisi persyaratan !</td>');
				} else {
					var persyaratanSerkom = ``
					dokumen.forEach(dataBerkas => {
						persyaratanSerkom += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					});

					$("#persyaratanSerkom").append(persyaratanSerkom);
				}

				var dokumenPendidikan = dataDetail.data_pendidikan
				dokumenPendidikan.forEach(dataBerkas => {
					var pendidikan = `
						<tr>
							<td>${dataBerkas.tahun_lulus}</td>
							<td>${dataBerkas.jenjang}</td>
							<td>${dataBerkas.nama_institusi_pendidikan}</td>
							<td>${dataBerkas.tanggal_ijazah}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen}' width="40px"></td>
						</tr>
					`

					$("#dataPendidikanSerkom").append(pendidikan);
				});

				var dokumenSTR = dataDetail.data_strttk
				dokumenSTR.forEach(dataBerkas => {
					var str = `
						<tr>
							<td>${dataBerkas.nomor_strttk}</td>
							<td>${dataBerkas.tanggal}</td>
							<td>${dataBerkas.berlaku_sampai}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen}' width="40px"></td>
						</tr>
					`

					$("#dataSTRTTK").append(str);
				});

				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanSerkomPD.getCommentar(UserID, "rekom_serkom", id)
				let dataComment = response.data;
				dataComment.reverse();

				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}

				$("#btnSendComment").on('click', async function () {



					if (UserID != '' && id != '') {
						document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentar").value
						const response = await ApiPengajuanSerkomPD.sendCommentar(UserID, id, "rekom_serkom", content);
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-left text-dark text-left" style="width:88%;">
												<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-right text-dark text-left" style="width:88%;">
												<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}


							let response = await ApiPengajuanSerkomPD.getCommentar(UserID, "rekom_serkom", id)
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-comment").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-comment');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						} else {
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						}


					}


					// $(this).off('click');

				});



				$('#detailSerkomModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendComment').off('click');
				});

			}

		});


		$('#tablePengajuanSERKOM thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSERKOM thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async _downloadSerkom() {

		$('#tablePengajuanSERKOM tbody').on('click', '#download', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			let table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const downloadSERKOM = await ApiAnggota.downloadSerkom({ id });
			$(this).html('<i class="fas fa-download"></i>');



		})
	},

	async _checkAllEvent() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanSERKOM')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanSERKOM').DataTable();
				let data = table.row(index).data()
				if (checked) {
					let alamat = data.domisili.address + ' Desa / Kelurahan ' + data.domisili.village_name + ' Kecamatan ' + data.domisili.sub_regency_name + ' ' + data.domisili.regency_name + ' Provinsi ' + data.domisili.province_name;
					let alamatLower = alamat.toLowerCase();
					let alamatUpper = alamatLower.toUpperCase();
					let linkValidtion = `${CONFIG.BASE_URL}validation/serkom/${data.new_serkom.credential}`;
					await StorageAnggotaPD.pushData(data.id, data.new_serkom.no_serkom_pp, data.user.name, data.user.jenis_kelamin, data.user.tempat_lahir, data.user.tanggal_lahir, alamatUpper, data.pendidikan.nomor_ijazah, data.pendidikan.tanggal_ijazah, data.pendidikan.jenjang, linkValidtion)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');

					let alamat = data.domisili.address + ' Desa / Kelurahan ' + data.domisili.village_name + ' Kecamatan ' + data.domisili.sub_regency_name + ' ' + data.domisili.regency_name + ' Provinsi ' + data.domisili.province_name;
					let alamatLower = alamat.toLowerCase();
					let alamatUpper = alamatLower.toUpperCase();
					let linkValidtion = `${CONFIG.BASE_URL}validation/serkom/${data.new_serkom.credential}`;
					await StorageAnggotaPD.pushData(data.id, data.new_serkom.no_serkom_pp, data.user.name, data.user.jenis_kelamin, data.user.tempat_lahir, data.user.tanggal_lahir, alamatUpper, data.pendidikan.nomor_ijazah, data.pendidikan.tanggal_ijazah, data.pendidikan.jenjang, linkValidtion)
				}
			}
		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)

	},

	async _approvePengajuanSerkom() {

		let idPengajuan = 0;
		let kompetensi = '';
		$('#tablePengajuanSERKOM tbody').on('click', '#approve', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			kompetensi = $(this).closest('tr').find('select').val();

			idPengajuan = data.id;

			const approveSerkom = await ApiPengajuanSerkomPD.approvePengajuanSerkom({
				id: idPengajuan,
				status: "approve",
				kompetensi: kompetensi,
				comment: "",
			});

			if (approveSerkom.status_code != 200) {
				NotificationModal.show(`${approveSerkom.message}`, 'error');
				$(this).html("<i class='fas fa-check'></i>");
				$('#tablePengajuanSERKOM').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${approveSerkom.message}`, 'success');
				$(this).html("<i class='fas fa-check'></i>");
				$('#tablePengajuanSERKOM').DataTable().ajax.reload();
			}
			await StorageAnggotaPD.makeStore()
		});

		$('#tablePengajuanSERKOM tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			kompetensi = $(this).closest('tr').find('select').val();
			idPengajuan = data.id;
		});

		$("#btnSendKomentarPengajuan").click(async function (e) {
			$(this).html("Loading...");
			e.stopPropagation()
			let comment = $("#textKomentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("Send");
			} else {
				const revisePengajuanSerkom = await ApiPengajuanSerkomPD.approvePengajuanSerkom({
					id: idPengajuan,
					status: "revise",
					kompetensi: kompetensi,
					comment: comment
				})
				if (revisePengajuanSerkom.status_code != 200) {
					NotificationModal.show(`${revisePengajuanSerkom.message}`, 'error');
					$(this).html("Send");
					$("#textKomentarPengajuan").val("")
					$('#commentModal').modal('hide');
				} else {
					NotificationModal.show(`${revisePengajuanSerkom.message}`, 'success');
					$(this).html("Send");
					$("#textKomentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanSERKOM').DataTable().ajax.reload();
				}
			}

		});


		$("#btnCancel").click(function () {
			$("#textKomentarPengajuan").val("")
		});
	},

	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPD.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPD.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanSerkomPD.approveAllSerkom(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSERKOM').DataTable().ajax.reload();
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSERKOM').DataTable().ajax.reload();
						}
						await StorageAnggotaPD.makeStore()
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});
			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},

	async _initDownloadSerkomBulk() {
		const eventDownloadSelected = async () => {
			if (await StorageAnggotaPD.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnDownloadsSelected").html("<i class='fas fa-download'></i> Download Selected")
			} else {
				let data = await StorageAnggotaPD.getData()
				const response = await ApiPengajuanSerkomPD.downloadSelected(data)
				if (response.status_code == 200) {
					NotificationModal.show("Data Berhasil di download", "success");
					$("#btnDownloadsSelected").html("<i class='fas fa-download'></i> Download Selected")
					$('#tablePengajuanSERKOM').DataTable().ajax.reload();
					function createExcelFile(data1, sheetName, fileName) {
						// Membuat lembar kerja baru
						const workbook = XLSX.utils.book_new();

						// Membuat lembar kerja
						const worksheet = XLSX.utils.aoa_to_sheet(data1);

						// Menambahkan lembar kerja ke dalam lembar kerja baru
						XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

						// Mengkonversi lembar kerja menjadi file Excel
						const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

						// Membuat objek blob dari data Excel
						const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

						// Membuat tautan unduhan untuk file Excel
						const url = URL.createObjectURL(blob);

						// Membuat elemen tautan unduhan
						const link = document.createElement('a');
						link.href = url;
						link.download = fileName + '.xlsx';
						link.click();

						// Membersihkan objek URL yang dibuat sebelumnya
						URL.revokeObjectURL(url);
					}

					// Contoh penggunaan
					const arrayData = [['NOMOR SERKOM PP', 'NAMA ANGGOTA', 'JENIS KELAMIN', 'TEMPAT LAHIR', 'TANGGAL LAHIR', 'ALAMAT', 'NOMOR IJAZAH', 'TANGGAL IJAZAH', 'JENJANG PENDIDIKAN', "LINK VALIDASI"]];
					// console.log(data)
					data.forEach(index => {
						let dataSerkom = [index.nomor_serkom, index.nama_anggota, index.jenis_kelamin, index.tempat_lahir, index.tanggal_lahir, index.alamat, index.nomor_ijazah, index.tanggal_ijazah, index.jenjang_pendidikan, index.link_validasi]
						arrayData.push(dataSerkom)
					})
					let date = new Date().toLocaleDateString('en-US')
					createExcelFile(arrayData, 'Data Serkom', 'Download Serkom ' + date);
				} else {
					NotificationModal.show(`${response.message}`, "error");
					$("#btnDownloadsSelected").html("<i class='fas fa-download'></i> Download Selected")
					$('#tablePengajuanSERKOM').DataTable().ajax.reload();
				}
				// const arrayData2 = [await StorageAnggotaPD.getData()]
				// console.log(arrayData, arrayData2);

			}
		}

		document.getElementById('btnDownload').addEventListener('click', eventDownloadSelected)
	}


}

const StorageAnggotaPD = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan, nomorSerkom, namaAnggota, jenisKelamin, tempatLahir, tanggalLahir, alamat, nomorIjazah, tanggalIjazah, jenjangPendidikan, linkValidtion) {
		if (this.isReady(idPengajuan, nomorSerkom, namaAnggota, jenisKelamin, tempatLahir, tanggalLahir, alamat, nomorIjazah, tanggalIjazah, jenjangPendidikan, linkValidtion)) return
		let gold = {
			"id_pengajuan": idPengajuan,
			"nomor_serkom": nomorSerkom,
			"nama_anggota": namaAnggota,
			"jenis_kelamin": jenisKelamin,
			"tempat_lahir": tempatLahir,
			"tanggal_lahir": tanggalLahir,
			"alamat": alamat,
			"nomor_ijazah": nomorIjazah,
			"tanggal_ijazah": tanggalIjazah,
			"jenjang_pendidikan": jenjangPendidikan,
			"link_validasi": linkValidtion
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}
export default SerkomPD;
