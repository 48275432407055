

const YoutubeHelper = {

    showTutorial(title, url) {
        $("#modalYoutube").on('show.bs.modal', function(){
            $("#cartoonVideo").attr('src', url);
        });

        $("#modalYoutube").on('hide.bs.modal', function(){
            $("#cartoonVideo").attr('src', '');
        });
        

        document.getElementById("titleYoutube").innerHTML = title

        $("#modalYoutube").modal('show');
    }
}

export default YoutubeHelper;