
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiAnggota from "../../api/data-anggota.js";


const GeneralSettingPCPresenter = {

	async init() {
		await this._setView();
		await this._getSetting();
		await this.updateSetting();
		await this.updateIuran();
		// await this._generateInvoice();

	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		const formattedNumberInput = document.getElementById('textNominalIuran');

		formattedNumberInput.addEventListener('input', async (event) => {
			// Hapus semua karakter non-digit dari input
			const validInput = event.target.value.replace(/\D/g, '');
			// Pastikan hanya angka yang dimasukkan
			event.target.value = await this.formatToRupiah(validInput);
		});

		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	rupiahToNumber(rupiah) {
		return parseInt(rupiah.replace(/\D/g, ''), 10);
	},

	async formatToRupiah(number) {
		const reverse = number.toString().split('').reverse().join('');
		let formattedValue = '';
		let count = 0;
	
		for (const char of reverse) {
		  if (count === 3) {
			formattedValue += '.';
			count = 0;
		  }
		  formattedValue += char;
		  count++;
		}
	
		return formattedValue.split('').reverse().join('');
  	},

	async _showLoading(){
		
	},

	async _hideLoading(){
		
	},

	async _getSetting(){
		this._showLoading();
		const getSetting = await ApiKepengurusanPC.getSetting();

		let dataSetting = getSetting.data;
		if (getSetting.status_code == 200) {
			$("#textNamaKota").val(dataSetting.regency_name);
			$("#textAlamatCabang").val(dataSetting.address);
			$("#textInformasiUmum").val(dataSetting.information);
			$("#textTelepon").val(dataSetting.telp);
			$("#textEmailCabang").val(dataSetting.email);
			$("#textFaks").val(dataSetting.fak);
			$("#textKodePos").val(dataSetting.kode_pos);
			$("#textUrlMaps").val(dataSetting.maps_url);
			$("#textSIPTTKKepada").val(dataSetting.kepada_sipttk);
			$("#textSIPTTKDi").val(dataSetting.di_sipttk);
			$("#textSTRTTKKepada").val(dataSetting.kepada_strttk);
			$("#textSTRTTKDi").val(dataSetting.di_strttk);
			// $("#cetakKirimEmail").val(dataSetting.send_email_sipttk);
			$("#strttkTTDPC").val(dataSetting.ttd_digital_active);
			$("#textInformasiRekening").val(dataSetting.informasi_rekening);
			$("#textNominalIuran").val( await this.formatToRupiah(dataSetting.membership_fee));
		}
		this._hideLoading();

	},

	async updateSetting(){
		const evenUpdateSetting = async (e) => {
				
			document.getElementById("btnUpdate").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const updateSetting = await ApiKepengurusanPC.updateSetting({
				nama_kota : $("#textNamaKota").val(),
				alamat_cabang: $("#textAlamatCabang").val(),
				informasi_umum: $("#textInformasiUmum").val(),
				telpon: $("#textTelepon").val(),
				email: $("#textEmailCabang").val(),
				faksimil: $("#textFaks").val(),
				kode_pos: $("#textKodePos").val(),
				url_maps: $("#textUrlMaps").val(),
				kepada_sipttk: $("#textSIPTTKKepada").val(),
				di_sipttk: $("#textSIPTTKDi").val(),
				// send_email_sipttk: $("#cetakKirimEmail").val(),
				kepada_strttk: $("#textSTRTTKKepada").val(),
				di_strttk: $("#textSTRTTKDi").val(),
				ttd_pc_strttk: $("#strttkTTDPC").val(),
				informasi_rekening: $("#textInformasiRekening").val()

			});

			if (updateSetting.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'error');
				document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Update Setting`;
			} else {
				NotificationModal.show('Setting data umum berhasil di update !', 'success');
				document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Update Setting`;
			}
			e.preventDefault();
			

            
		}

		const eventClear = async (e) => {
			document.getElementById("form-general-setting").reset()
			e.preventDefault();
		}
		
		
		document.getElementById('btnUpdate').addEventListener('click', evenUpdateSetting);
		document.getElementById('btnClear').addEventListener('click',eventClear)
	},

	async updateIuran(){
		const evenUpdateIuran = async (e) => {
			if (this.rupiahToNumber($("#textNominalIuran").val())< 5000) {
				NotificationModal.show('Maaf ! minimal nominal Iuran adalah sebesar Rp. 5.000 [Lima ribu rupiah]', 'warning');
				return
			}
			swal.fire({
				title: 'Ubah Nominal iuran',
				html: "Yakin mengubah Nominal iuran menjadi  <b> Rp. " + $("#textNominalIuran").val() + "</b> ?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ubah'
			}).then(async (result) => {
				if (result.value) {
					document.getElementById("btnUpdateIuran").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const updateIuran = await ApiKepengurusanPC.updateIuran({
						membership_fee : this.rupiahToNumber($("#textNominalIuran").val())
					});
					if (updateIuran.status_code != 200) {
						NotificationModal.show(`${updateIuran.message}`, 'error');
						document.getElementById("btnUpdateIuran").innerHTML = `<i class="fas fa-edit"></i> Update Iuran`;
					} else {
						NotificationModal.show('Iuran anggota berhasil di update !', 'success');
						document.getElementById("btnUpdateIuran").innerHTML = `<i class="fas fa-edit"></i> Update Iuran`;
					}
				}
			});
			e.preventDefault();
		}
		document.getElementById('btnUpdateIuran').addEventListener('click', evenUpdateIuran);
	},

	// async _generateInvoice(){
	// 	const evenGenerateInvoice = async (e) => {
				
	// 		document.getElementById("btnGenerateInvoice").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
	// 		const generateInvoice = await ApiKepengurusanPC.generateInvoice({
	// 			jatuh_tempo : $("#dateJatuhTempo").val()
	// 		});

	// 		if (generateInvoice.status_code != 200) {
	// 			NotificationModal.show(`${generateInvoice.message}`, 'error');
	// 			document.getElementById("btnGenerateInvoice").innerHTML = `<i class="fas fa-share-alt"></i> Generate Invoice`;
	// 		} else {
	// 			NotificationModal.show('Invoice berhasil di generate', 'success');
	// 			document.getElementById("btnGenerateInvoice").innerHTML = `<i class="fas fa-share-alt"></i> Generate Invoice`;
	// 		}
	// 		e.preventDefault();
			

            
	// 	}
	// 	document.getElementById('btnGenerateInvoice').addEventListener('click', evenGenerateInvoice);
	// }




}



export default GeneralSettingPCPresenter;