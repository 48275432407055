import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';
import LogAktivitasUserPresenter from '../../../presenter/super_admin/LogAktivitasUserPresenter.js';

const LogAktivitas = {

	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiKTAN" class="nav-link hapus-border active" id="data-verifikasi-anggota" data-bs-toggle="tab" data-bs-target="#tabVerifikasiKTAN" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Data Anggota</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiKTAN" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
                        <div class="card mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                    <div class="row">
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Status Registrasi</label>
                                            <select id="selectStatusRegist" class="form-control">
                                                <option value="all">Semua</option>
                                                <option value="1">Success</option>
                                                <option value="0">Pending</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Jenis Keanggotaan</label>
                                            <select id="selectJenisKeanggotaan" class="form-control">
                                                <option value="all">Semua</option>
                                                <option value="1">Sudah Memiliki KTAN</option>
                                                <option value="0">Belum Memiliki KTAN</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                            <select id="selectPengajuanPD" class="form-control">
                                                <option value=''>Pilih Daerah</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                            <select id="selectPengajuanPC" class="form-control">
                                                <option value=''>Pilih Cabang</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Jenjang Pendidikan</label>
                                            <select id="selectJenjang" class="form-control">
                                                <option value="">Semua</option>
                                                <option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
                                                <option value="D3 FARMASI">D3 FARMASI</option>
                                                <option value="D3 ANAFARMA">D3 ANAFARMA</option>
                                                <option value="D3 NON-FARMASI">D3 NON-FARMASI</option>
                                                <option value="S1 FARMASI">S1 FARMASI</option>
                                                <option value="S1 NON-FARMASI">S1 NON-FARMASI</option>
                                                <option value="S2 FARMASI">S2 FARMASI</option>
                                                <option value="S2 NON-FARMASI">S2 NON-FARMASI</option>
                                                <option value="S3 FARMASI">S3 FARMASI</option>
                                                <option value="S3 NON-FARMASI">S3 NON-FARMASI</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-12 mb-2">
                                            <button class="btn btn-primary btn-xl" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                        </div>
                                        <div class="col-sm-3 d-none">
                                            <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                <i class="fas fa-print"></i>
                                            </button>
                                            <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                <i class="fas fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div class="row mt-3">
                        
                            
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                       Cari Anggota
                                    </div>
                                    <div class="card-body">
                                        <div class="row mt-3">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tableDataAnggota" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th data-column="email" >Email</th>
                                                                <th data-column="phone_number" >No HP</th>
                                                                <th >PD</th> 
                                                                <th >PC</th> 
                                                                <th data-column="ktan">No KTAN</th>
                                                                <th >Aksi</th>
                                                            </tr>
                                                           
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

					
				</div>
			</div>
		</div>
        

        
        <div class="modal fade bd-example-modal-lg" id="detailAnggotaModal1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold text-primary">Detail Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 pr-4">
                                <div class="row mt-3 ">
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3 mb-3 text-center">
                                        <img loading="lazy" id="detailAvatar" src="images/logo.png" width="30%">
                                    </div>
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3">
                                        <h3 class="font-weight-bold" id="detailNamaAnggota">Nama Anggota</h3>
                                        <div class="row text-primary">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-id-card"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailKTAN">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-phone-alt"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailNoHP">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-at"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailEmailHead">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 mx-auto pt-3">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-user"></i> Data Umum</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                NIK
                                            </div>
                                            <div class="col-sm-8" id="detailNIK">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Email
                                            </div>
                                            <div class="col-sm-8" id="detailEmail">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tempat Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTempatLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tanggal Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTanggalLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Jenis Kelamin
                                            </div>
                                            <div class="col-sm-8" id="detailJenisKelamin">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-map-marker-alt"></i> Alamat</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Alamat
                                            </div>
                                            <div class="col-sm-7" id="detailAlamat">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Provinsi
                                            </div>
                                            <div class="col-sm-7" id="detailProvinsi">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kota / Kabupaten
                                            </div>
                                            <div class="col-sm-7" id="detailKota">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kecamatan
                                            </div>
                                            <div class="col-sm-7" id="detailKecamatan">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kelurahan / Desa
                                            </div>
                                            <div class="col-sm-7" id="detailKelurahan">
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-money-check"></i> Bukti Bayar</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table width="100%">
                                                    <tr>
                                                        <td id="detailBuktiBayar">Tidak ada bukti bayar !</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-graduation-cap"></i> Pendidikan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPendidikan">

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-briefcase"></i> Pekerjaan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPekerjaan">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-certificate"></i> Serkom</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailSerkom">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-file"></i> STRTTK</h5>
                                        <div class="col-sm-12 text-center overflow-auto">
                                            <table class="table table-bordered" width="100%" id="detailSTR">
                                            
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-id-badge"></i> SIPTTK</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center overflow-auto">
                                                <table class="table table-bordered" width="100%" id="detailSIP">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

       
        <div class="modal fade bd-example-modal-lg" id="detailAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Aktivitas User</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="alert alert-info" id="modalTitleLogUser">
                           
                        </div>


                        <div class="table-responsive">
                            <table class="table table-hovered table-bordered" id="tableLog" width="100%">
                                <thead>
                                    <tr>
                                        <th data-column="log_name">Aktivitas</th>
                                        <th data-column="event">Event Aktivitas</th>
                                        <th data-column="description">Deskripsi</th>
                                        <th data-column="updated_at">Tanggal</th>
                                    </tr>
                                </thead>
                                <tfoot>

                                </tfoot>
                                <tbody>
                                    <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                    </tr>
                                    <tr>
                                       <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                    </tr>
                                    <tr>
                                       <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
		return view;
	  },

	async afterRender() {
	 await LogAktivitasUserPresenter.init()
     await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default LogAktivitas;