
import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PP_ENDPOINT from "../../config/globals/menu_pengurus_pp_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiRegister from "../../api/data-register.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";
import NikParser from "../../utils/nik-parser.js";
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';

const LogAktivitasUserPresenter = {

	async init() {
		await this._initGetAnggota()
		await this._getDetailAnggota();
	},

	async _getDataPrivate() {
		const eventChange = async (e) => {
			document.getElementById("selectPengajuanPC").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}
		const eventChangePDBlock = async (e) => {
			document.getElementById("selectPCBlock").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPDBlock").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPCBlock");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPCBlock").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPCBlock").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}

		document.getElementById("selectPengajuanPD").addEventListener("change", eventChange);
		document.getElementById("selectPDBlock").addEventListener("change", eventChangePDBlock);

	},

	async _initRestore(){
		
	},

	async _getDetailAnggota() {
		$("#tableDataAnggota tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
		
			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			$("#modalTitleLogUser").html(`  <strong>Informasi :</strong><br>
                            Data ini merupakan data Aktivitas User / Anggota Di Sistem Ini <br>
                            Nama : ${data.name} <br>
                            KTAN : ${data.ktan} `)
			var savedOrderColumn = 'update_ate';
			var savedOrderDirection = 'asc';

			
			let table2 = await $('#tableLog').DataTable({
				"pageLength": 10,
				"lengthMenu": [10, 25, 50, 100],
				"processing": true,
				dom: 'lBfrtip<"actions">',
				buttons: [
					{
						extend: 'copy',
						text: '<i class="fas fa-copy"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Copy to clipboard"
					},
					{
						extend: 'csv',
						text: '<i class="fas fa-file-csv"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file csv"
					},
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file excel"
					},
					{
						extend: 'pdf',
						text: '<i class="fas fa-file-pdf"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Download file pdf"
					},
					{
						extend: 'print',
						text: '<i class="fas fa-print"></i>',
						exportOptions: {
							columns: ':visible'
						},
						className: "btn btn-light border-primary",
						titleAttr: "Print",
					},

				],
				"destroy": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"search":true,
				"ajax": {
					"url": `${API_PENGURUS_PP_ENDPOINT.GET_ANGGOTA_LOG(uuidAnggota)}`,
				
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					},
					"data" : function (d) {
						d.orderColumn = savedOrderColumn;
						d.orderDirection = savedOrderDirection;
					}
				},
				"columns": [
					{ data: 'log_name' },
					{ data: 'event' },
					{ data: 'description' },
					{ data: 'updated_at' },
				],
				"initComplete": function () {
					$('#tableDataAnggota_filter input').unbind();
						$('#tableDataAnggota_filter input').bind('keyup', function(e) {
							if(e.keyCode == 13) {
								table.search($('#tableDataAnggota_filter input').val()).draw();
							}
						}); 
				},
				"fnDrawCallback": function (data) {
					let rowTable = data.aoData;
	
				},
				select: {
					style: 'multi',
					selector: 'td:first-child'
				},

			});

			$('#tableLog thead').off('click', 'th').on('click', 'th', function () {
				let chachedCoulumnName = savedOrderColumn;
				const columnIndex = $(this).index();
				const columnHeaders = $('#tableLog thead th');
				const columnName = $(columnHeaders[columnIndex]).data('column');
				var order =  table.order()[0][1];
				
				if (order == "asc") {
					order = "desc"
				}else{
					order = "asc"
				}
				if(chachedCoulumnName != columnName){
					order = "asc"
				}
				savedOrderColumn = columnName;
				chachedCoulumnName = savedOrderColumn;
				savedOrderDirection = order;
			});
			
		});
	},

	async _initGetAnggota(){
		let id_cabang = document.getElementById('selectPengajuanPC').value;
		let id_daerah = document.getElementById('selectPengajuanPD').value;
		let status_ktan = document.getElementById('selectJenisKeanggotaan').value;
		let status_regist = document.getElementById('selectStatusRegist').value;
		let jenjang = document.getElementById('selectJenjang').value;

		await this._initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan,jenjang, status_regist)

		const eventFilterd = async () => {
			let id_cabang = document.getElementById('selectPengajuanPC').value;
			let id_daerah = document.getElementById('selectPengajuanPD').value;
			let status_ktan = document.getElementById('selectJenisKeanggotaan').value;
			let status_regist = document.getElementById('selectStatusRegist').value;
			let jenjang = document.getElementById('selectJenjang').value;
	
			await this._initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan,jenjang, status_regist)
		}

		document.getElementById('btnFilter').addEventListener('click', eventFilterd);
	},

	async _initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan, jenjang, status_regist) {
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';
		let table = await $('#tableDataAnggota').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				
			],
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"search":true,
			"ajax": {
				"url": `${API_PENGURUS_PP_ENDPOINT.GET_ALL_USER_KTAN_1(status_ktan, id_cabang, id_daerah, jenjang, status_regist)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'ktan' }
			],
			"initComplete": function () {
				$('#tableDataAnggota_filter input').unbind();
					$('#tableDataAnggota_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tableDataAnggota_filter input').val()).draw();
						}
					}); 
			},
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;

			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			"columnDefs": [
				{
					"targets":6,
					"data": null,
					render: function (data) {
						let isKtanExist = data.ktan == null || data.ktan == "";
						return `<div style="display:flex">
								<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail'><i class='fas fa-eye'></i></button>
	
							</div>`;
					},
				}
			]
		});

		$('#tableDataAnggota thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataAnggota thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async _deleteAnggota() {
		let uuid = ''
		$('#tableDataAnggota tbody').on('click', '#deleteAnggota', async function () {
			document.getElementById("infoAnggotaName").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaKTAN").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPC").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPD").innerHTML = "Memuat Data..."
			const table = $('#tableDataAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			document.getElementById("infoAnggotaName").innerHTML = data.name
			document.getElementById("infoAnggotaKTAN").innerHTML = data.ktan
			document.getElementById("infoAnggotaPC").innerHTML = data.cabang_name
			document.getElementById("infoAnggotaPD").innerHTML = data.daerah_name
			uuid = data.uuid;
			$("#textHideUUID").val(uuid);
		});
		$('#tableDataAnggota tbody').on('click', '#deleteAnggotaForce', async function () {
			document.getElementById("infoAnggotaNameForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaKTANForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPCForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPDForce").innerHTML = "Memuat Data..."
			const table = $('#tableDataAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			document.getElementById("infoAnggotaNameForce").innerHTML = data.name
			document.getElementById("infoAnggotaKTANForce").innerHTML = data.ktan
			document.getElementById("infoAnggotaPCForce").innerHTML = data.cabang_name
			document.getElementById("infoAnggotaPDForce").innerHTML = data.daerah_name
			uuid = data.uuid;
			$("#textHideUUID").val(uuid);
		});
		$("#btnSendCommentDelete").on("click", async function (e) {

			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#reaseonDeleteUser").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
			} else {
				const approveAnggota = await ApiPengajuanKTANPC.deleteAnggota({
					type:"soft",
					uuid: uuid,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
					$("#reaseonDeleteUser").val("")
					$('#deleteAnggotaModal').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
					$("#reaseonDeleteUser").val("")
					$('#deleteAnggotaModal').modal('hide');
					$('#tableDataAnggota').DataTable().ajax.reload();
				}
			}

		});
		$("#btnSendCommentDeleteForce").on("click", async function (e) {

			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#reaseonDeleteUserForce").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
			} else {
				const approveAnggota = await ApiPengajuanKTANPC.deleteAnggota({
					type:"force",
					uuid: uuid,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Alasan");
					$("#reaseonDeleteUserForce").val("")
					$('#deleteAnggotaPermanen').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Alasan");
					$("#reaseonDeleteUserForce").val("")
					$('#deleteAnggotaPermanen').modal('hide');
					$('#tableDataAnggota').DataTable().ajax.reload();
				}
			}

		});
	},

}


export default LogAktivitasUserPresenter;