
import API_ENDPOINT from "../../config/globals/endpoint.js";
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import API_PENGURUS_PD_ENDPOINT from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiMutasiPC {

    static async createSyaratMutasi ({
		name,
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ADD_SYARAT_MUTASI,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

    static async updateSyaratMutasi ({
		id,
		name,
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_PERSYARATAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async getInformationMutasi (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.INFORMATION_MUTASI ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async createInfromationMutasi ({
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.INFORMATION_MUTASI,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async createMutasiManual ({
        user_id,
        pc_id_tujuan,
        pd_id_tujuan,
        alasan,
        email_tembusan,
        email_tembusan_pd_lama,
        email_tembusan_pc_lama,
        email_tembusan_pd_baru,
        email_tembusan_pc_baru,
        attachment_name,
        documentFile
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
        
      
        var formdata = new FormData();
        formdata.append("user_id", user_id);
        formdata.append("pc_id_tujuan", pc_id_tujuan);
        formdata.append("pd_id_tujuan", pd_id_tujuan);
        formdata.append("alasan", alasan);
        formdata.append("email_tembusan", email_tembusan);
        formdata.append("email_tembusan_pd_lama", email_tembusan_pd_lama);
        formdata.append("email_tembusan_pc_lama", email_tembusan_pc_lama);
        formdata.append("email_tembusan_pd_baru",email_tembusan_pd_baru);
        formdata.append("email_tembusan_pc_baru", email_tembusan_pc_baru);
        formdata.append("attachment_name", attachment_name);
        if (document != null) {
         formdata.append("document", documentFile);
        }

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.CREATE_MUTASI_MANUAL,{
				method : "POST",
				headers: myHeaders,
                body : formdata
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getInformationAnggota (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_FORMULIR_ANGGOTA ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async applyMutasi ({
        lulusan,
        tahun_lulus,
        nomor_strttk,
        tempat_kerja_asal,
        sarana_kefarmasian_tujuan,
        pc_id_tujuan,
        pd_id_tujuan,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"lulusan": lulusan,
			"tahun_lulus": tahun_lulus,
			"nomor_strttk": nomor_strttk,
			"tempat_kerja_asal": tempat_kerja_asal,
			"sarana_kefarmasian_tujuan": sarana_kefarmasian_tujuan,
			"pc_id_tujuan": pc_id_tujuan,
			"pd_id_tujuan": pd_id_tujuan
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPLY_MUTASI_ANGGOTA,{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
	static async updateMutasi ({
		id,
        lulusan,
        tahun_lulus,
        nomor_strttk,
        tempat_kerja_asal,
        sarana_kefarmasian_tujuan,
        pc_id_tujuan,
        pd_id_tujuan,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"lulusan": lulusan,
			"tahun_lulus": tahun_lulus,
			"nomor_strttk": nomor_strttk,
			"tempat_kerja_asal": tempat_kerja_asal,
			"sarana_kefarmasian_tujuan": sarana_kefarmasian_tujuan,
			"pc_id_tujuan": pc_id_tujuan,
			"pd_id_tujuan": pd_id_tujuan
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_MUTASI(id),{
				method : "PUT",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async deleteMutasiAnggota({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.DELETE_MUTASI_ANGGOTA(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getDetailApplyMutasi ({
       id
     } ){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_MUTASI_DETAIL(id) ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getPersyaratanMutasiPcTujuan ({
		id
	  } ){
		 let myHeaders =  new Headers();
		 let token = await Autentication.getToken()
		 myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		 myHeaders.append('Content-Type', 'application/json');
		 myHeaders.append('Accept', 'application/json');
		 myHeaders.append('Access-Control-Allow-Origin', '{*}');
		 myHeaders.append('Access-Control-Allow-Credentials', 'true');
		 myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
 
		 try {
			 const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_BERKAS_TUJUAN(id) ,{
				 method : "GET",
				 headers: myHeaders,
			 });
			 const responseJson = await response.json();
			 if (await NetworkHelper.isResponseAccepted(responseJson)) {
				 return responseJson;
			 } return {};
		 } catch (err) {
			 
			 return {};
		 }
	 }

	static async uploadBerkasMutasi({
		id,
		document_name,
		dokumen
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", document_name);
		formdata.append("document", dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_MUTASI_BERKAS(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getDetailMutasiPC ({
		id
	  } ){
		 let myHeaders =  new Headers();
		 let token = await Autentication.getToken()
		 myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		 myHeaders.append('Content-Type', 'application/json');
		 myHeaders.append('Accept', 'application/json');
		 myHeaders.append('Access-Control-Allow-Origin', '{*}');
		 myHeaders.append('Access-Control-Allow-Credentials', 'true');
		 myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
 
		 try {
			 const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_DETAIL_MUTASI_PC(id) ,{
				 method : "GET",
				 headers: myHeaders,
			 });
			 const responseJson = await response.json();
			 if (await NetworkHelper.isResponseAccepted(responseJson)) {
				 return responseJson;
			 } return {};
		 } catch (err) {
			 
			 return {};
		 }
	 }
 
    static async getPenandatanganMutasi (){
		 let myHeaders =  new Headers();
		 let token = await Autentication.getToken()
		 myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		 myHeaders.append('Content-Type', 'application/json');
		 myHeaders.append('Accept', 'application/json');
		 myHeaders.append('Access-Control-Allow-Origin', '{*}');
		 myHeaders.append('Access-Control-Allow-Credentials', 'true');
		 myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
 
		 try {
			 const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PENGURUS ,{
				 method : "GET",
				 headers: myHeaders,
			 });
			 const responseJson = await response.json();
			 if (await NetworkHelper.isResponseAccepted(responseJson)) {
				 return responseJson;
			 } return {};
		 } catch (err) {
			 
			 return {};
		 }
	 }

	static async getPenandatanganMutasiById (id){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PENGURUS_BY_ID(id) ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async accMutasiPCAsal ({
        approval,
        nomor_pc,
        nama_penanda_pc,
        nian_penanda_pc,
		id,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"approval": "approve",
			"nomor_pc": nomor_pc,
			"nama_penanda_pc": nama_penanda_pc,
			"nian_penanda_pc": nian_penanda_pc
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ACC_PC_ASAL_MUTASI(id),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async rejectMutasiPCAsal ({
        approval,
		id,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"approval": "revise",
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ACC_PC_ASAL_MUTASI(id),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getCommentar(
		pengajuanID,
		commentFor,
		lastPage
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.GET_COMMENTAR(pengajuanID,commentFor,lastPage),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async sendCommentar(
		UserID,
		pengajuanID,
		contentComment,
		commentFor,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": `${UserID}`,
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.SEND_COMMENT,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}


	static async approvalMutasiPCTujuan ({
        approval,
		idPengajuan
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		let approvalValue = (approval == true ?  "approve" :  "revise")
		var rawData = JSON.stringify({
			"approval":  approvalValue,
		  });
		  

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ACC_PC_TUJUAN_MUTASI(idPengajuan),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async approvalMutasiPCAsal ({
        approval,
		idPengajuan
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		let approvalValue = (approval == true ?  "approve" :  "revise")
		var rawData = JSON.stringify({
			"approval":  approvalValue,
		  });
		  

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ACC_PC_TUJUAN_MUTASI(idPengajuan),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }


	static async getDetailMutasiPD ({
		id
	  } ){
		 let myHeaders =  new Headers();
		 let token = await Autentication.getToken()
		 myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		 myHeaders.append('Content-Type', 'application/json');
		 myHeaders.append('Accept', 'application/json');
		 myHeaders.append('Access-Control-Allow-Origin', '{*}');
		 myHeaders.append('Access-Control-Allow-Credentials', 'true');
		 myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
 
		 try {
			 const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.GET_DETAIL_MUTASI_PD(id) ,{
				 method : "GET",
				 headers: myHeaders,
			 });
			 const responseJson = await response.json();
			 if (await NetworkHelper.isResponseAccepted(responseJson)) {
				 return responseJson;
			 } return {};
		 } catch (err) {
			 
			 return {};
		 }
	 }


	static async getPenandatanganMutasiPD (){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.PENGURUS ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async getPenandatanganMutasiPDById (id){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.PENGURUS_BY_ID(id) ,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async accMutasiPDAsal ({
        approval,
        nomor_pd,
        nama_penanda_pd,
        nian_penanda_pd,
		id,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"approval": "approve",
			"nomor_pd": nomor_pd,
			"nama_penanda_pd": nama_penanda_pd,
			"nian_penanda_pd": nian_penanda_pd
		  });

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.ACC_PD_ASAL_MUTASI(id),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async rejectMutasiPDAsal ({
		id,
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
      
		var rawData = JSON.stringify({
			"approval": "revise"
		  });

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.ACC_PD_ASAL_MUTASI(id),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async approvalMutasiPDTujuan ({
        approval,
		idPengajuan
     } ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		let approvalValue = (approval == true ?  "approve" :  "revise")
		var rawData = JSON.stringify({
			"approval":  approvalValue,
		  });
		  

		try {
			const response = await fetchWithTimeout(API_PENGURUS_PD_ENDPOINT.ACC_PD_TUJUAN_MUTASI(idPengajuan),{
				method : "POST",
				headers: myHeaders,
                body : rawData
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }


	static async downloadCetakLolosButuhPD({
		id
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_LOLOS_BUTUH_PD(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'PDF Mutasi.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadCetakLolosButuhPC({
		id
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_LOLOS_BUTUH_PC(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'PDF Mutasi.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	

}


export default ApiMutasiPC;
