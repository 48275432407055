import PengajuanMutasiPresenter from "../../../presenter/menu_anggota/pengajuan-mutasi-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const PengajuanMutasi = {
	
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataUmum" class="nav-link hapus-border active" id="data-umum-tab" data-bs-toggle="tab" data-bs-target="#tabDataUmum" data-toggle="tab" type="button" role="tab" aria-controls="tabDataUmum" aria-selected="true">Formulir Pengajuan Mutasi</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Data Pengajuan Mutasi</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataUmum" role="tabpanel" aria-labelledby="data-umum-tab">
						<div class="row mt-3">
							<div class="col-lg-12 col-xl-12">

							<div class="card">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Informasi Mutasi</h6>
									</div>

									<div class="card-body " id="information_mutasi">
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
									</div>
							</div>		
								<div class="card mt-3">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Formulir Pengajuan Mutasi</h6>
									</div>
									<div class="card-body ">
										<form id="form-mutasi">
											<div class="form-group row d-none">
												<label class="control-label col-sm-12 font-weight-bold">ID</label>
												<div class="col-sm-12">
													<input type="text" name="dari_pd" placeholder="ID Pengajuan" class="form-control"  id="idPengajuan" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Daerah</label>
												<div class="col-sm-12">
													<input type="text" name="dari_pd" placeholder="Dari Pengurus Daerah..." class="form-control" required id="textDariPD" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Dari Pengurus Cabang</label>
												<div class="col-sm-12">
													<input type="text" name="dari_pc" placeholder="Dari Pengurus Cabang..." class="form-control" required id="textDariPC" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
												<div class="col-sm-12">
													<input type="text" name="ktan" placeholder="KTAN..." class="form-control" required readonly id="textKTAN">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Lulusan</label>
												<div class="col-sm-12">
													<input type="text" name="lulusan" placeholder="Lulusan..." class="form-control" required id="textLulusan">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tahun Lulus</label>
												<div class="col-sm-12">
													<input type="number" name="tahun_lulus" placeholder="Tahun Lulus..." class="form-control" required id="textTahunLulus">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">No STRTTK</label>
												<div class="col-sm-12">
													<input type="text" name="no_strttk" placeholder="No STRTTK..." class="form-control"  required id="textNOSTRTTK">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold text-capitalize">Tempat Kerja Saat di PC Asal</label>
												<div class="col-sm-12">
													<input type="text" name="pd" placeholder="Tempat Kerja Saat di [PC]..." class="form-control"  required id="textTempatKerjaPC">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Sarana Tujuan Kefarmasian</label>
												<div class="col-sm-12">
													<input type="text" name="pd" placeholder="Sarana Tujuan Kefarmasian..." class="form-control"  required id="textSaranaTujuanKefarmasian">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Pindah ke Pengurus Daerah Provinsi</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectProvinsiTujuan" name="pd_tujuan" required>
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Pindah ke Pengurus Cabang Kabupaten / Kota</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKotaTujuan" name="pc_tujuan" required>
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>
											
										
											
											<div class="form-group row">
												<div class="col-sm-12 mb-1">
													<button type="submit" class=" btn btn-primary btn" id="btnSimpanRekomSIPTTK"><i class="fas fa-save"></i> Simpan</button>
													<button type="button" class="d-none btn btn-warning btn" id="btnEdit"><i class="fas fa-undo"></i> Batal Edit</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>

							
						</div>
					</div>

					<div class="tab-pane fade" id="tabProfilPendidikan" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pengajuan Mutasi
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableDataMutasi" style="width:100%" cellspacing="0">
										<thead>
											<tr>
												<th rowspan="2">No</th>
												<th data-column="name" rowspan="2">Nama</th>
												<th data-column="tanggal_pengajuan" rowspan="2">Tanggal Pengajuan</th>
												<th colspan="2">Asal</th>
												<th colspan="2">Tujuan</th>
												<th data-column="status_pc_asal" rowspan="2">STATUS PC ASAL</th>
												<th data-column="status_pd_asal"rowspan="2">STATUS PD ASAL</th>
												<th data-column="status_pd_tujuan" rowspan="2">STATUS PD Tujuan</th>
												<th data-column="status_pc_tujuan"rowspan="2">STATUS PC Tujuan</th>
												<th rowspan="2">Aksi</th>
											</tr>
											<tr>
												<th>PC</th>
												<th>PD</th>
												<th>PD</th>
												<th>PC</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
                                    	</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabPekerjaan" role="tabpanel" aria-labelledby="pekerjaan-tab">Pekerjaan</div>
					<div class="tab-pane fade" id="tabKarirOrganisasi" role="tabpanel" aria-labelledby="karir-organisasi-tab">Organisasi</div>
				</div>
			</div>
		</div>
		
		
		<div class="modal fade" id="detailMutasiModal" tabindex="-1" role="dialog" aria-labelledby="detailSerkomModal"
		aria-hidden="true">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="">Detail Pengajuan Mutasi</h5>
					<button class="close" type="button" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
				</div>
				<div class="modal-body" id="bodyModalSerkom">
					<div class="form-group row">
						<div class="col-sm-3">
							<label class="label control-label">ID Pengajuan</label>
							<input type="text" id="hiddenIdPengajuan" class="form-control" readonly>
						</div>
						<div class="col-sm-9">
							<label class="label control-label">Tanggal Pengajuan</label>
							<input type="text" id="tanggalPengajuan" class="form-control" readonly>
						</div>
						<div class="col-sm-12">
							<label class="label control-label">Nama Anggota</label>
							<input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
						</div>
					</div>
					<div class="form-group row">
						<div class="col-sm-5">
							<label class="label control-label">Cabang Asal</label>
							<input type="text" id="cabangAsal" class="form-control" readonly>
						</div>
						<div class="col-sm-7">
							<label class="label control-label">Cabang Tujuan</label>
							<input type="text" placeholder="Nomor Serkom..." id="cabangTujuan" class="form-control" readonly>
						</div>
					</div>
					<div class="card mb-2">
						<div class="card-header text-primary font-weight-bold">
							<strong>Upload Persyaratan PC ASAL</strong>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group row">
										<label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
										<div class="col-sm-12">
											<select class="form-control" id="selectBerkas">
												<option value="">Pilih Salah Satu</option>
											</select>
										</div>
									</div>
									<div class="text-center d-none" id="containerAlertBerkas">
												<p>Deskripsi Persyaratan</p>
												<div class="alert alert-primary" role="alert" id="valueAlertBerkas">
													
												</div>
									</div>
									<div class="form-group row">
										<label class="control-label col-sm-12 font-weight-bold">Upload Berkas</label>
									</div>
									<div id="form-upload-serkom" class="col-sm-12 dropzone">
										<input type="hidden" name="file" style=""/>
									</div>
									<div class="form-group row">
										<div class="col-sm-12">
											<button class="mb-2 btn btn-sm btn-primary" id="btnUpdateBerkas" style="width:100%"><i class='fas fa-edit'></i> Kirim & Update Berkas</button>
										</div>
									</div>
								</div>
								<div class="col-sm-6">
									<label class="label control-label"><strong>Data Persyaratan</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanSerkom">
	
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>


					<div class="card mb-2">
						<div class="card-header text-primary font-weight-bold">
							<strong>Upload Persyaratan PC Tujuan</strong>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group row">
										<label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
										<div class="col-sm-12">
											<select class="form-control" id="selectBerkas2">
												<option value="">Pilih Salah Satu</option>
											</select>
										</div>
									</div>
									<div class="text-center d-none" id="containerAlertBerkas2">
												<p>Deskripsi Persyaratan</p>
												<div class="alert alert-primary" role="alert" id="valueAlertBerkas2">
													
												</div>
									</div>
									<div class="form-group row">
										<label class="control-label col-sm-12 font-weight-bold">Upload Berkas</label>
									</div>
									<div id="form-upload-serkom2" class="col-sm-12 dropzone">
										<input type="hidden" name="file" style=""/>
									</div>
									<div class="form-group row">
										<div class="col-sm-12">
											<button class="mb-2 btn btn-sm btn-primary" id="btnUpdateBerkas2" style="width:100%"><i class='fas fa-edit'></i> Kirim & Update Berkas</button>
										</div>
									</div>
								</div>
								<div class="col-sm-6">
									<label class="label control-label"><strong>Data Persyaratan</strong></label>
									
									<div class="col-sm-12">
										<div class="row">
											<table class="table table-bordered table-hovered" id="tablePersyaratan2" width="100%">
												<thead>
													<th>Nama Persyaratan</th>
													<th class="text-center">Dokumen</th>
												</thead>
												<tbody id="persyaratanSerkom2">
	
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					
					<div class="row">
					<div class="col-sm-6">
					<div class="card">
					<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
						<h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
					</div>
					<div class="card-body chat-body p-1" id="lamanChat">
						<div class="chat-body p-1" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<div class="shimmer circle"></div>
								</div>
								<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line end"></div>
								</div>
							</div>
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<div class="shimmer circle"></div>
								</div>
								<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line end" style="float:right;"></div>
								</div>
							</div>
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<div class="shimmer circle"></div>
								</div>
								<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line end"></div>
								</div>
							</div>
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<div class="shimmer circle"></div>
								</div>
								<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line"></div>
									<div class="shimmer content-line end" style="float:right;"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer">
						<form id="form-comment">
							<div class="form-group row">
								<label class="control-label col-sm-12 font-weight-bold">Komentar</label>
								<div class="col-sm-12">
									<textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-12">
									<button type="submit" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
								</div>
								</div>
							</div>
						</form>
					</div>
					</div>
				<div class="col-sm-6">
					<div class="card">
						<div class="card-header text-primary font-weight-bold">
							Riwayat Verifikasi Mutasi
						</div>
						<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
								
							<div class="table-responsive">
								<table class="table table-bordered table-hover" id="tableHistoryVerifikasiMutasi" width="100%" cellspacing="0">
									<thead>
										<tr>
											<th>No</th>
											<th>Tanggal</th>
											<th>Nama</th>
											<th>Hak</th>
											<th>Keterangan</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td><div class="shimmer title-line end"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
										</tr>
										<tr>
											<td><div class="shimmer title-line end"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
										</tr>
										<tr>
											<td><div class="shimmer title-line end"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
											<td><div class="shimmer title-line"></div></td>
										</tr>
									</tbody>
									<tfoot>
										
									</tfoot>
									<tbody>
									
									
									
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
						
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
				</div>
			</div>
		</div>
	</div>
		
		
		
	<div style="display:none;" id="my-template">
	<div id="mytmp" class="dz-preview dz-file-preview">
		<div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
		<div class="dz-details">
			<div class="dz-size"><span data-dz-size></span></div>
			<div class="dz-filename"><span data-dz-name></span></div>
		</div>
		
		<div class="dz-error-message"><span data-dz-errormessage></span></div>
		<div class="dz-success-mark">
			<svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
				<path d="M0 0h24v24H0z" fill="none" />
				<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
			</svg>
		</div>
		<div class="dz-error-mark">
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0z" fill="none" />
				<circle cx="12" cy="19" r="2" />
				<path d="M10 3h4v12h-4z" />
			</svg>
		</div>
		<div class="dz-remove" data-dz-remove>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
				<path d="M0 0h24v24H0z" fill="none" />
				<path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
			</svg>
		</div>
	</div>
</div>
		
		`;
		return view;
	  },

	async afterRender() {
		await PengajuanMutasiPresenter.init()
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default PengajuanMutasi;