import UpdatePasswordPresenter from "../../../presenter/menu_anggota/update-password-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const UpdatePassword = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="card shadow mb-4">
                    <div class="card-header text-primary font-weight-bold">
                        <i class="fas fa-key"></i> UBAH PASSWORD
                    </div>
                    <div class="card-body">
                        <form id="form-update-password">
                            <div class="form-group row">
                                <label class="control-label col-sm-12 font-weight-bold">Password Lama</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" id="textOldPass" required placeholder="Password Lama...">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-12 font-weight-bold">Password Baru</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" id="textNewPass" required placeholder="Password Baru...">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-12 font-weight-bold">Konfirmasi Password</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" id="textConfirmPass" required placeholder="Konfirmasi Password...">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn btn-primary float-right" id="btnUpdatePassword"><i class="fas fa-key"></i> Ubah Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>   
            </div>
        `;
		return view;
	  },

	async afterRender() {
		await UpdatePasswordPresenter.init()
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default UpdatePassword;