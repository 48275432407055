import CONFIG from './config.js';

const NOTIFICATION_ENDPOINT = {
	
	GET_UNREAD_NOTIFICATION : `${CONFIG.BASE_URL}notification/anggota/unread`,

	GET_UNREAD_COUNT_NOTIFICATION : `${CONFIG.BASE_URL}notification/anggota/count`,
	GET_NOTIFICATION_PC : `${CONFIG.BASE_URL}notification/pc`,
	GET_NOTIFICATION_PD : `${CONFIG.BASE_URL}notification/pd`,
	GET_NOTIFICATION_PP : `${CONFIG.BASE_URL}notification/pp`,
	READ_NOTIFICATION_ID :(id) => `${CONFIG.BASE_URL}notification/anggota/read/${id}`,
	GET_All_NOTIFICATION : `${CONFIG.BASE_URL}notification/anggota/all`,
	READ_ALL_NOTIFICATION : `${CONFIG.BASE_URL}notification/anggota/read/all`,

};

export default NOTIFICATION_ENDPOINT;
