import PeriodeKepengurusanPCPresenter from "../../../presenter/menu_pengurus_pc/anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const PeriodeKepengurusanPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button href="#tabPeriodeKepengurusan" class="nav-link hapus-border active" id="data-verifikasi-anggota" data-bs-toggle="tab" data-bs-target="#tabPeriodeKepengurusan" data-toggle="tab" type="button" role="tab" aria-controls="tabPeriodeKepengurusan" aria-selected="true">Periode Kepengurusan</button>
                    </li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabPeriodeKepengurusan" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Formulir Input Periode Kepengurusan
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePeriodeKepengurusan" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th>Masa Bakti</th>
                                                                <th>Keterangan</th>
                                                            </tr>
                                                        </thead>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

				</div>
			</div>
		</div>
        
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Komentar !</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <textarea name="komentarPengajuan" id="textKomentarPengajuan" class="form-control" placeholder="Komentar..." required></textarea>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        <a class="btn btn-primary" id="btnSendKomentarPengajuan">Send</a>
                    </div>
                </div>
            </div>
        </div>
        
        `;
		return view;
	  },

	async afterRender() {
		await PeriodeKepengurusanPCPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default PeriodeKepengurusanPC;