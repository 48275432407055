

import NotificationModal from '../utils/initial_notification.js';

const fetchWithTimeout = async (url,options , timeout = 60000)=>{
 
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Request timed out')), timeout)
  );

  const fetchPromise = fetch(url, options);

  return Promise.race([fetchPromise, timeoutPromise])
    .catch(error => {
      if (error.message === 'Request timed out') {
        console.log('Request timed out');
            Toastify({
            text: "Request timed out ! \n Tunggu beberapa saat dan Silakan periksa koneksi internet Anda.",
            style: {
              background: "linear-gradient(to right, #00b09b, #96c93d)",
            },
            duration: 2000,
            className: "warning",
          }).showToast();
       // NotificationModal.show("Request timed out ! \n Tunggu beberapa saat dan Silakan periksa koneksi internet Anda." , "warning")
      } else {
        console.error('Error:', error.message); 
      }
      throw error; 
    });
}
// for development

// const fetchWithTimeout = async (url,options , timeout = 8000)=>{
//     const timeOutCustomStorage = localStorage.getItem("rto")
//     let timeOutDefault = 8000
//     if (timeOutCustomStorage != undefined) {
//         timeOutDefault = timeOutCustomStorage
//     }
//     timeOutDefault = 10000
//     const timeoutPromise = new Promise((_, reject) =>
//       setTimeout(() => reject(new Error('Request timed out')), timeOutDefault)
//     );
  
//     const fetchPromise = fetch(url, options);
  
//     return Promise.race([fetchPromise, timeoutPromise])
//       .catch(error => {
//         if (error.message === 'Request timed out') {
//           console.log('Request timed out');
//           Toastify({
//             text: "Request timed out ! \n Tunggu beberapa saat dan Silakan periksa koneksi internet Anda.",
//             style: {
//               background: "linear-gradient(to right, #00b09b, #96c93d)",
//             },
//             duration: 2000,
//             className: "warning",
//           }).showToast();
//         } else {
//           console.error('Error:', error.message); 
//         }
//         throw error; 
//       });
//   }

  export default fetchWithTimeout;