import ResetPasswordInitiator from "../../presenter/reset-password-presenter.js";

const ResetPassword = {
    async render() {
            return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-10 col-lg-12 col-md-12">
      
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-5 p-3 text-center border-r d-lg-block ">
                                    <img loading="lazy" class="img-fluid" src="./src/public/img/logo.png" alt="image login" width="50%">
                                </div>
                                <div class="col-lg-7">
                                    <div class="p-3">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">Reset Password</h1>
                                        </div>
                                       
                                        <form class="user" class="form-horizontal" id='reset-password'>
                                            <div class="form-group">
                                                <input type="email" name="email" class="form-control form-control" required id="email" aria-describedby="email" placeholder="Email...">
                                            </div>
                                            <div class="form-group">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        </div>
                                                        <input name="password" type="password" value="" class="form-control" id="resetPasswordNew" placeholder="Password Baru..." required="true" aria-label="password" aria-describedby="basic-addon1" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text" id="btnShowPasswordNew">
                                                            <i class="fas fa-eye" id="show_eye"></i>
                                                            <i class="fas fa-eye-slash d-none" id="hide_eye"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        </div>
                                                        <input name="password_confirmation" type="password" value="" class="form-control" id="resetPasswordConfirmation" placeholder="Konfirmasi Password Baru..." required="true" aria-label="password_confirmation" aria-describedby="basic-addon1" />
                                                        <div class="input-group-append">
                                                        <span class="input-group-text" id="btnShowPasswordConfirmationNew">
                                                            <i class="fas fa-eye" id="show_eye_confirm"></i>
                                                            <i class="fas fa-eye-slash d-none" id="hide_eye_confirm"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
      
                                            <button id="btnResetPassword" type="submit" class="btn btn-primary btn-user btn-block">Ubah Password</button>
                                            <a id="btnLogin" class="btn btn-google btn-user btn-block">Kembali Ke Login</a>
                                            <hr>
                                            <a id="btnHome" class="btn btn-dark btn-user btn-block">Kembali Ke Home</a>
      
                                        </form>
                                        
                                        <hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await ResetPasswordInitiator.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default ResetPassword;
  

