
import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PP_ENDPOINT from "../../config/globals/menu_pengurus_pp_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiRegister from "../../api/data-register.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";
import NikParser from "../../utils/nik-parser.js";
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';

const KelolaAnggotaPresenterPP = {

	async init() {
		await this._initGetAnggota()
		await this._initGetBlockAnggota();
		await StorageKelolaAnggotaPP.makeStore()
		await this._getDataPrivate();
		await this.initProvinceValue();
		await this._getDetailAnggota();
		await this._updateDataAnggota();
		await this._updateKTANAnggota();
		await this._deleteAnggota();
		await this._initRestore();
	},

	async _getDataPrivate() {


	
		const eventChange = async (e) => {
			document.getElementById("selectPengajuanPC").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPengajuanPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPengajuanPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}

		
		const eventChangePDBlock = async (e) => {
			document.getElementById("selectPCBlock").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectPDBlock").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectPCBlock");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPCBlock").add((new Option("Pilih Cabang", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPCBlock").add(new Option(data.regency_name, data.id))
			});


			e.preventDefault();
		}



		document.getElementById("selectPengajuanPD").addEventListener("change", eventChange);
		document.getElementById("selectPDBlock").addEventListener("change", eventChangePDBlock);


	},

	async _initRestore(){
		$("#tableAnggotaBlock tbody").on("click", "#btnRestoreAnggota", async function () {
			const table = $("#tableAnggotaBlock").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let ktan = data.ktan;

			if (ktan == null) {
				ktan = '-'
			}else {
				ktan = ktan
			}
			
			swal.fire({
				title: 'Restore',
				html: "Anda ingin mengrestore data ini? <br> <strong>Nama Anggota </strong>: " + data.name + "<br><strong>KTAN </strong> :"+ ktan,
				icon: 'info',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Restore!'
			}).then(async (result) => {
				if (result.value) {
							
					var loadingElement = document.createElement("div");
					loadingElement.setAttribute("id", "loading");

					var spinnerElement = document.createElement("div");
					spinnerElement.setAttribute("class", "spinner");

					for (var i = 0; i < 3; i++) {
						var barElement = document.createElement("div");
						barElement.setAttribute("class", "bar");
						spinnerElement.appendChild(barElement);
					}

					loadingElement.appendChild(spinnerElement);
					document.body.appendChild(loadingElement);
					let uuid = data.uuid;

					const restoreAnggota = await ApiKepengurusanPP.restoreAnggota({
						uuid: uuid
					});

					if (restoreAnggota.status_code == '200') {
						swal.fire({
							title: 'Restore',
							text: restoreAnggota.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tableAnggotaBlock').DataTable().ajax.reload();
						$('#tableDataAnggota').DataTable().ajax.reload();
						
						var loadingElement = document.getElementById("loading");
						if (loadingElement) {
							loadingElement.parentNode.removeChild(loadingElement);
						}
						
					}else {
						swal.fire({
							title: 'Error',
							text: restoreAnggota.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});
		})
	},

	async initProvinceValue() {
		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("registerSelectPD").add(new Option(data.province_name, data.province_code))
			});
		}

		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectPengajuanPD").add(new Option(data.province_name, data.province_code))
				document.getElementById("selectPDBlock").add(new Option(data.province_name, data.province_code))
			});
		}



		const eventChange = async (e) => {
			let valueSelected = document.getElementById("registerSelectPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("registerSelectPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("registerSelectPC").add((new Option("Pilih Keanggotaan Wilayah PC", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("registerSelectPC").add(new Option(data.regency_name, data.id))
			});

			e.preventDefault();
		}

		document.getElementById("registerSelectPD").addEventListener("change", eventChange)

	},

	async _updateDataAnggota() {
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#edit", async function () {

			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			uuid = uuidAnggota;

			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);


			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

			} else {
				var jk = '';
				$("#registerTextNamaLengkap").val(dataByID.name)
				$("#registerSelectPD").val(dataByID.user_pd_aktif_id)

				var selectPC = document.getElementById("registerSelectPC");
				var lengthOptionPc = selectPC.options.length;
				for (let i = lengthOptionPc - 1; i >= 0; i--) {
					selectPC.options[i] = null;
				}
				const Registry = await ApiRegister.getRegistry(dataByID.user_pd_aktif_id)
				Registry.data.forEach(data => {
					document.getElementById("registerSelectPC").add(new Option(data.regency_name, data.id))
				});
				$("#registerSelectPC").val(dataByID.user_pc_aktif_id)
				$("#registerTextNIK").val(dataByID.nik)
				$("#registerNumberNoHP").val(dataByID.phone_number)
				$("#registerEmail").val(dataByID.email)
				if (dataByID.jenis_kelamin == 'Laki-laki') {
					jk = 'L'
				} else {
					jk = 'P'
				}
				
				var parts = dataByID.tanggal_lahir.split('-');
				var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
		  
				$('#dateTanggalIjazah').val(formattedDate);

				$("#registerSelectJenisKelamin").val(jk)
				$("#registerTanggalLahir").val(formattedDate)
			}
			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}

		})

		const eventUpdateDataAnggota = async (e) => {
			e.preventDefault();
			let nikUser = document.getElementById('registerTextNIK').value;
			let isNikValid = NikParser(nikUser)
			if (isNikValid.isValid == false) {
				NotificationModal.show(`NIK Tidak valid`, 'warning');
				return
			} else {
				document.getElementById("btnUpdateAnggota").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const dataRegister = await ApiKepengurusanPP.updateDataAnggota({
					uuid: uuid,
					nik: nikUser,
					pd: document.getElementById('registerSelectPD').value,
					pc: document.getElementById('registerSelectPC').value,
					nama_lengkap: document.getElementById('registerTextNamaLengkap').value,
					no_hp: document.getElementById('registerNumberNoHP').value,
					email: document.getElementById('registerEmail').value,
					jenis_kelamin: document.getElementById('registerSelectJenisKelamin').value,
					tanggal_lahir: document.getElementById('registerTanggalLahir').value,

				});

				if (dataRegister.status_code != 200) {
					NotificationModal.show(`${dataRegister.message}`, 'error');
					document.getElementById("btnUpdateAnggota").innerHTML = `Update Data Anggota`;
				} else {
					NotificationModal.show(`${dataRegister.message}`, 'success');
					document.getElementById("btnUpdateAnggota").innerHTML = `Update Data Anggota`;

					// $('#tableDataAnggota').DataTable().ajax.reload();
					// refresh manual
					// await this._initGetAnggota();
					$('#editAnggotaModal').modal('hide');
					await this._initGetAnggota();

				}
			}
			e.preventDefault();
		}


		document.getElementById('form-update-anggota').addEventListener('submit', eventUpdateDataAnggota);
	},


	async _updateKTANAnggota() {
		let uuid = '';
		$("#tableDataAnggota tbody").on("click", "#editKTAN", async function () {

			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			uuid = uuidAnggota;

			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

			} else {
				$("#textKTANAnggota").val(dataByID.ktan)
			}
			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}

		})

		const eventUpdateDataAnggota = async (e) => {
			e.preventDefault();
			let ktanUser = document.getElementById('textKTANAnggota').value;
			document.getElementById("btnSaveKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const dataRegister = await ApiKepengurusanPP.updateDataAnggota({
				uuid: uuid,
				ktan: ktanUser,

			});

			if (dataRegister.status_code != 200) {
				NotificationModal.show(`${dataRegister.message}`, 'error');
				document.getElementById("btnSaveKTAN").innerHTML = `<i class="fas fa-save"></i> Simpan`;
			} else {
				NotificationModal.show(`${dataRegister.message}`, 'success');
				document.getElementById("btnSaveKTAN").innerHTML = `<i class="fas fa-save"></i> Simpan`;

				// $('#tableDataAnggota').DataTable().ajax.reload();
				// refresh manual
				// await this._initGetAnggota();
				$('#editKTANAnggotaModal').modal('hide');
				await this._initGetAnggota();

			}
			e.preventDefault();
		}


		document.getElementById('btnSaveKTAN').addEventListener('click', eventUpdateDataAnggota);
	},


	// async _deleteAnggota(){
	// 	let uuid = '';
	// 	$("#tableDataAnggota tbody").on("click", "#deleteAnggota", async function () {

	// 		var loadingElement = document.createElement("div");
	// 		loadingElement.setAttribute("id", "loading");

	// 		var spinnerElement = document.createElement("div");
	// 		spinnerElement.setAttribute("class", "spinner");

	// 		for (var i = 0; i < 3; i++) {
	// 			var barElement = document.createElement("div");
	// 			barElement.setAttribute("class", "bar");
	// 			spinnerElement.appendChild(barElement);
	// 		}

	// 		loadingElement.appendChild(spinnerElement);
	// 		document.body.appendChild(loadingElement);

	// 		const table = $("#tableDataAnggota").DataTable();
	// 		let data = table.row($(this).parents('tr')).data();
	// 		let uuidAnggota = data.uuid;
	// 		uuid = uuidAnggota;

	// 		const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);
	// 		console.log(getAnggotaById)

	// 		let dataByID = getAnggotaById.data;
	// 		if (getAnggotaById.status_code != 200) {
	// 			NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');

	// 		}else {
	// 			$("#textNIKAnggota").val(dataByID.nik)
	// 		}
	// 		var loadingElement = document.getElementById("loading");
	// 		if (loadingElement) {
	// 			loadingElement.parentNode.removeChild(loadingElement);
	// 		}

	// 	})

	// 	const eventUpdateDataAnggota = async (e) => {
	// 		e.preventDefault();
	// 		let nikUser = document.getElementById('textNIKAnggota').value;
	// 		let isNikValid = NikParser(nikUser)
	// 		if (isNikValid.isValid == false) {
	// 			NotificationModal.show(`NIK Tidak valid`, 'warning');
	// 			return
	// 		}else{
	// 			document.getElementById("btnSaveNIK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
	// 			const dataRegister = await ApiKepengurusanPP.updateDataAnggota({
	// 				uuid: uuid,
	// 				nik:nikUser,

	// 			});

	// 			if (dataRegister.status_code != 200) {
	// 				NotificationModal.show(`${dataRegister.message}`, 'error');
	// 				document.getElementById("btnSaveNIK").innerHTML = `<i class="fas fa-save"></i> Simpan`;
	// 			} else {
	// 				NotificationModal.show(`${dataRegister.message}`, 'success');
	// 				document.getElementById("btnSaveNIK").innerHTML = `<i class="fas fa-save"></i> Simpan`;

	// 				// $('#tableDataAnggota').DataTable().ajax.reload();
	// 				// refresh manual
	// 				// await this._initGetAnggota();
	// 				$('#editNIKAnggotaModal').modal('hide');
	// 				await this._initGetAnggota();

	// 			}
	// 		}
	// 		e.preventDefault();
	// 	}


	// 	document.getElementById('btnSaveNIK').addEventListener('click', eventUpdateDataAnggota);
	// },

	async _getDetailAnggota() {
		$("#tableDataAnggota tbody").on("click", "#detail", async function () {
			$("#detailPendidikan").html('')
			$("#detailPekerjaan").html('')
			$("#detailSerkom").html('')
			$("#detailSIP").html('')
			$("#detailSTR").html('')
			$("#detailAvatar").attr('src', 'images/logo.png')
			var loadingElement = document.createElement("div");
			loadingElement.setAttribute("id", "loading");

			var spinnerElement = document.createElement("div");
			spinnerElement.setAttribute("class", "spinner");

			for (var i = 0; i < 3; i++) {
				var barElement = document.createElement("div");
				barElement.setAttribute("class", "bar");
				spinnerElement.appendChild(barElement);
			}

			loadingElement.appendChild(spinnerElement);
			document.body.appendChild(loadingElement);

			const table = $("#tableDataAnggota").DataTable();
			let data = table.row($(this).parents('tr')).data();
			let uuidAnggota = data.uuid;
			const getAnggotaById = await ApiKepengurusanPP.getAnggotaByID(uuidAnggota);

			let dataByID = getAnggotaById.data;
			if (getAnggotaById.status_code != 200) {
				NotificationModal.show(`${updateSetting.message}`, 'Gagal memuat detail anggota !');
			} else {
				if (dataByID.avatar == '' || dataByID.avatar == null) {
					$("#detailAvatar").attr('src', 'images/avatar_not_found.png');
				} else {
					$("#detailAvatar").attr('src', dataByID.avatar);
				}
				$("#detailNamaAnggota").html(dataByID.name);
				$("#detailKTAN").html(dataByID.ktan);
				$("#detailNIK").html(dataByID.nik);
				$("#detailEmail").html(dataByID.email);
				$("#detailEmailHead").html(dataByID.email);
				$("#detailTempatLahir").html(dataByID.tempat_lahir);
				$("#detailTanggalLahir").html(dataByID.tanggal_lahir);
				$("#detailJenisKelamin").html(dataByID.jenis_kelamin);
				$("#detailNoHP").html(dataByID.phone_number);
				if (dataByID.alamat == null) {
					$("#detailAlamat").html("Alamat belum di input oleh anggota");
					$("#detailProvinsi").html("Alamat belum di input oleh anggota");
					$("#detailKota").html("Alamat belum di input oleh anggota");
					$("#detailKecamatan").html("Alamat belum di input oleh anggota");
					$("#detailKelurahan").html("Alamat belum di input oleh anggota");
				} else {
					$("#detailAlamat").html(dataByID.alamat.address);
					$("#detailProvinsi").html(dataByID.alamat.province_name);
					$("#detailKota").html(dataByID.alamat.regency_name);
					$("#detailKecamatan").html(dataByID.alamat.sub_regency_name);
					$("#detailKelurahan").html(dataByID.alamat.village_name);
				}
				if (dataByID.dokumen.bukti_bayar_ktan == null || dataByID.dokumen.bukti_bayar_ktan == '') {
					$("#detailBuktiBayar").html(`
						Tidak ada Bukti Bayar !
					`)
				} else {
					$("#detailBuktiBayar").html(`
						<img loading="lazy" src="${dataByID.dokumen.bukti_bayar_ktan}" width="50%">
					`)
				}


				$("#detailPendidikan").html(`
				
					<tr>
						<th class="text-center">Ijazah</th>
						<th>Jenjang</th>
						<th>Nama Perguruan Tinggi</th>
						<th>Tahun Lulus</th>
						<th>Nomor Ijazah</th>
					</tr>
				`)

				$("#detailPekerjaan").html(`
					<tr>
						<th class="text-center">Dokumen</th>
						<th>Nama Tempat Kerja</th>
						<th>Jenis</th>
						<th>Jabatan</th>
						<th>Dari Tahun</th>
						<th>Sampai Tahun</th>
						<th>No HP/ Telp</th>
						<th>Alamat</th>
					</tr>
				`)


				$("#detailSerkom").html(`
					<tr>
						<th class="text-center">Serkom</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				$("#detailSIP").html(`
					<tr>
						<th class="text-center">SIPTTK</th>
						<th>Jenis</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
						<th>Supervisor</th>
						<th>STRA</th>
						<th>No STRTTK</th>
					</tr>
				`)


				$("#detailSTR").html(`
					<tr>
						<th class="text-center">STRTTK</th>
						<th>Nomor</th>
						<th>Tanggal</th>
						<th>Berlaku Sampai</th>
					</tr>
				`)


				var detPendidikan = ``;
				var detPekerjaan = ``;
				var detSerkom = ``;
				var detSIP = ``;
				var detSTR = ``;

				if (dataByID.pendidikan == null || dataByID.pendidikan == '') {
					$("#detailPendidikan").html(`
					<tr>
						<td>Tidak ada data Pendidikan !</td>
					</tr>
					`)
				} else {
					dataByID.pendidikan.forEach(dataPendidikan => {
						detPendidikan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPendidikan.dokumen}' width="40px"></td>
								<td>${dataPendidikan.jenjang}</td>
								<td>${dataPendidikan.nama_institusi_pendidikan}</td>
								<td>${dataPendidikan.tahun_lulus}</td>
								<td>${dataPendidikan.nomor_ijazah}</td>
							</tr>
						`

						$("#detailPendidikan").append(detPendidikan)
					});

				}

				if (dataByID.pekerjaan == null || dataByID.pekerjaan == '') {
					$("#detailPekerjaan").html(`
					<tr>
						<td>Tidak ada data Pekerjaan !</td>
					</tr>
					`)
				} else {
					dataByID.pekerjaan.forEach(dataPekerjaan => {
						detPekerjaan = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataPekerjaan.dokumen}' width="40px"></td>
								<td>${dataPekerjaan.nama_tempat_kerja}</td>
								<td>${dataPekerjaan.jenis_tempat_kerja}</td>
								<td>${dataPekerjaan.jabatan}</td>
								<td>${dataPekerjaan.dari_tahun}</td>
								<td>${dataPekerjaan.sampai_tahun}</td>
								<td>${dataPekerjaan.phone_number}</td>
								<td>${dataPekerjaan.alamat_tempat_kerja}</td>
							</tr>
						`

						$("#detailPekerjaan").append(detPekerjaan)
					});

				}

				if (dataByID.serkom == null || dataByID.serkom == '') {
					$("#detailSerkom").html(`
					<tr>
						<td>Tidak ada data SERKOM !</td>
					</tr>
					`)
				} else {
					dataByID.serkom.forEach(dataSerkom => {
						detSerkom = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSerkom.dokumen}' width="40px"></td>
								<td>${dataSerkom.nomor_serkom}</td>
								<td>${dataSerkom.tanggal}</td>
								<td>${dataSerkom.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSerkom").append(detSerkom)
					});

				}


				if (dataByID.sipttk == null || dataByID.sipttk == '') {
					$("#detailSIP").html(`
					<tr>
						<td>Tidak ada data SIPTTK !</td>
					</tr>
					`)
				} else {
					dataByID.sipttk.forEach(dataSIP => {
						detSIP = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSIP.dokumen}' width="40px"></td>
								<td>${dataSIP.jenis_sipttk}</td>
								<td>${dataSIP.nomor_sipttk}</td>
								<td>${dataSIP.tanggal}</td>
								<td>${dataSIP.berlaku_sampai}</td>
								<td>${dataSIP.nama_apoteker_supervisi}</td>
								<td>${dataSIP.nomor_stra_apoteker}</td>
								<td>${dataSIP.nomor_strttk}</td>
							</tr>
						`

						$("#detailSIP").append(detSIP)
					});

				}


				if (dataByID.strttk == null || dataByID.strttk == '') {
					$("#detailSTR").html(`
					<tr>
						<td>Tidak ada data STRTTK !</td>
					</tr>
					`)
				} else {
					dataByID.strttk.forEach(dataSTR => {
						detSTR = `
							<tr>
								<td class="text-center"><img loading="lazy" src='${dataSTR.dokumen}' width="40px"></td>
								<td>${dataSTR.nomor_strttk}</td>
								<td>${dataSTR.tanggal}</td>
								<td>${dataSTR.berlaku_sampai}</td>
							</tr>
						`

						$("#detailSTR").append(detSTR)
					});

				}


			}

			var loadingElement = document.getElementById("loading");
			if (loadingElement) {
				loadingElement.parentNode.removeChild(loadingElement);
			}


		});
	},

	async _initGetAnggota(){
		let id_cabang = document.getElementById('selectPengajuanPC').value;
		let id_daerah = document.getElementById('selectPengajuanPD').value;
		let status_ktan = document.getElementById('selectJenisKeanggotaan').value;
		let status_regist = document.getElementById('selectStatusRegist').value;
		let jenjang = document.getElementById('selectJenjang').value;

		await this._initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan,jenjang, status_regist)

		const eventFilterd = async () => {
			let id_cabang = document.getElementById('selectPengajuanPC').value;
			let id_daerah = document.getElementById('selectPengajuanPD').value;
			let status_ktan = document.getElementById('selectJenisKeanggotaan').value;
			let status_regist = document.getElementById('selectStatusRegist').value;
			let jenjang = document.getElementById('selectJenjang').value;
	
			await this._initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan,jenjang, status_regist)
		}

		document.getElementById('btnFilter').addEventListener('click', eventFilterd);
	},

	
	async _initGetBlockAnggota(){
		const eventAfterClick = async () => {
			let id_cabang = document.getElementById('selectPCBlock').value;
			let id_daerah = document.getElementById('selectPDBlock').value;
	
			await this._iniGetBlockAnggotaFiltered(id_cabang, id_daerah)
		}

		const eventFilterd = async () => {
			let id_cabang = document.getElementById('selectPCBlock').value;
			let id_daerah = document.getElementById('selectPDBlock').value;
	
			await this._iniGetBlockAnggotaFiltered(id_cabang, id_daerah)
		}

		document.getElementById('btnFilterBlock').addEventListener('click', eventFilterd);
		document.getElementById('btnRestoreAnggota').addEventListener('click', eventAfterClick);
	},

	async _iniGetBlockAnggotaFiltered(id_cabang, id_daerah){
		
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';
		let table = await $('#tableAnggotaBlock').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PP_ENDPOINT.GET_BLOCK_ANGGOTA(id_cabang, id_daerah)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ 	data: null,
					"orderable": false,
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row+meta.settings._iDisplayStart+1;
					}
				},
				{ data: 'name' },
				{ data: 'ktan'},
				{ data: 'email'},
				{ data: 'jenis_kelamin'},
				{ data: 'cabang_name' },
				{ data: 'daerah_name' },
				{
					data: 'avatar', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.old_ktan', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ktp_photo', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
			],
			"initComplete": function () {
				$('#tableAnggotaBlock_filter input').unbind();
					$('#tableAnggotaBlock_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tableAnggotaBlock_filter input').val()).draw();
						}
					}); 
			},
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;

			},
			"order": [1, "asc"],
			
			"columnDefs": [
				// style="word-wrap: break-word;"
				{ "width": "9000px", "targets": 1 },
				{
					"targets": [0,7,8,9,10],
					"orderable": false
				},
				{
					"targets": 10,
					"data": null,
					render: function (data) {
						return `<div style="display:flex">
								<a style="margin:1px" class='btn btn-success btn-sm' id='btnRestoreAnggota' title='Restore Anggota'><i class='fas fa-undo'></i> Restore</a>
							</div>`;
					},
				}
			]
		});

		
		$('#tableAnggotaBlock thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableAnggotaBlock thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});
	},

	async _initGetAnggotaFiltered(id_cabang, id_daerah, status_ktan, jenjang, status_regist) {
		var savedOrderColumn = 'name';
		var savedOrderDirection = 'asc';
		let table = await $('#tableDataAnggota').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: '<i class="fas fa-undo"></i> Restore Anggota',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary",
					titleAttr: "Restore Anggota",
					attr: {
						id: "btnRestoreAnggota",
						"data-toggle" : "modal",
						"data-target" : "#modalRestoreAnggota",
					},
				}
			],
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"search":true,
			"ajax": {
				"url": `${API_PENGURUS_PP_ENDPOINT.GET_ALL_USER_KTAN_1(status_ktan, id_cabang, id_daerah, jenjang, status_regist)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'phone_number', render : function (data) {
					let content = ``;
					if (data != null) {	
						let nomorTeleponBaru = data.replace(/^0/, "62");
						content = `${data} <a href="https://api.whatsapp.com/send?phone=${nomorTeleponBaru}" class="text-success" target="blank()"><i class="fab fa-whatsapp"></i> Chat</a>`;
					}else {
						content = '-';
					}

					return content
				}},
				{ data: 'daerah_name' },
				{ data: 'cabang_name' },
				{ data: 'ktan' },
				{ data: 'strttk.nomor_strttk', render : function (data) {
					if (data == '' || data == null) {
						return '-'
					}else return data
				} },
				{data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px;  width:150px">${pendidikanData.jenjang}</li><br>`
						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:250px"> ${pendidikanData.nama_institusi_pendidikan}</li><br>`

						});
					}
					return viewHtml;
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:90px" >${pendidikanData.tahun_lulus}</li><br>`

						});
					}
					return viewHtml
				}  },
				{ data: 'pendidikan', render : function (data) {
					var viewHtml = ``
					if (data === undefined || data.length == 0) {
						viewHtml = '-'
					}else{
						data.forEach(pendidikanData => {
							viewHtml += `<li style="height:45px; width:120px">${pendidikanData.tanggal_ijazah}</li><br>`
						});
					}
					return viewHtml
				}  },
				{ data: 'jenis_kelamin' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{
					data: 'ktan_expires_at', render: function (data) {
						if (data == null || data == "") {
							return '<span class="badge badge-danger">Belum Di Setting</span>'
						} else {
							return data;
						}
					}
				},
				{
					data: 'avatar', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.old_ktan', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ktp_photo', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'dokumen.ijazah', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
			],
			"initComplete": function () {
				$('#tableDataAnggota_filter input').unbind();
					$('#tableDataAnggota_filter input').bind('keyup', function(e) {
						if(e.keyCode == 13) {
							table.search($('#tableDataAnggota_filter input').val()).draw();
						}
					}); 
			},
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;

			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			"columnDefs": [
				// style="word-wrap: break-word;"
				{ "width": "9000px", "targets": 1 },
				{
					"targets": [2,3,4,5,6,7,8,9,10,15,16,17,18,19],
					"orderable": false
				},
				{
					"targets": 19,
					"data": null,
					render: function (data) {
						let isKtanExist = data.ktan == null || data.ktan == "";
						return `<div style="display:flex">
								<button style="margin:1px" class='btn btn-primary btn-sm' id='detail' data-toggle="modal" data-target="#detailAnggotaModal" title='Detail'><i class='fas fa-eye'></i></button>
								<button style="margin:1px" class='btn btn-warning btn-sm' id='edit' title='Edit Data Anggota' data-toggle="modal" data-target="#editAnggotaModal"><i class='fas fa-edit'></i></button>
								${(isKtanExist) ? `` : `<button style="margin:1px" class='btn btn-info btn-sm' id='editKTAN' title='Edit KTAN Anggota' data-toggle="modal" data-target="#editKTANAnggotaModal"><i class="fas fa-address-card"></i></button>`}
								<button style="margin:1px" class='btn btn-danger btn-sm' id='deleteAnggota' title='Blokir / Hapus Anggota Permanen' data-toggle="modal" data-target="#deleteAnggotaModal"><i class="fas fa-user-times"></i></button>
								<button style="margin:1px" class='btn btn-danger btn-sm' id='deleteAnggotaForce' title='Hapus Anggota' data-toggle="modal" data-target="#deleteAnggotaPermanen"><i class="fas fa-trash"></i></button>
							</div>`;
					},
				}
			]
		});

		$('#tableDataAnggota thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableDataAnggota thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},


	async _deleteAnggota() {
		let uuid = ''
		$('#tableDataAnggota tbody').on('click', '#deleteAnggota', async function () {
			document.getElementById("infoAnggotaName").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaKTAN").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPC").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPD").innerHTML = "Memuat Data..."
			const table = $('#tableDataAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			document.getElementById("infoAnggotaName").innerHTML = data.name
			document.getElementById("infoAnggotaKTAN").innerHTML = data.ktan
			document.getElementById("infoAnggotaPC").innerHTML = data.cabang_name
			document.getElementById("infoAnggotaPD").innerHTML = data.daerah_name
			uuid = data.uuid;
			$("#textHideUUID").val(uuid);
		});
		$('#tableDataAnggota tbody').on('click', '#deleteAnggotaForce', async function () {
			document.getElementById("infoAnggotaNameForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaKTANForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPCForce").innerHTML = "Memuat Data..."
			document.getElementById("infoAnggotaPDForce").innerHTML = "Memuat Data..."
			const table = $('#tableDataAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			document.getElementById("infoAnggotaNameForce").innerHTML = data.name
			document.getElementById("infoAnggotaKTANForce").innerHTML = data.ktan
			document.getElementById("infoAnggotaPCForce").innerHTML = data.cabang_name
			document.getElementById("infoAnggotaPDForce").innerHTML = data.daerah_name
			uuid = data.uuid;
			$("#textHideUUID").val(uuid);
		});
		$("#btnSendCommentDelete").on("click", async function (e) {

			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#reaseonDeleteUser").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
			} else {
				const approveAnggota = await ApiPengajuanKTANPC.deleteAnggota({
					type:"soft",
					uuid: uuid,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
					$("#reaseonDeleteUser").val("")
					$('#deleteAnggotaModal').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
					$("#reaseonDeleteUser").val("")
					$('#deleteAnggotaModal').modal('hide');
					$('#tableDataAnggota').DataTable().ajax.reload();
				}
			}

		});
		$("#btnSendCommentDeleteForce").on("click", async function (e) {

			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let comment = $("#reaseonDeleteUserForce").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Blokir & Berikan Alasan");
			} else {
				const approveAnggota = await ApiPengajuanKTANPC.deleteAnggota({
					type:"force",
					uuid: uuid,
					reason: comment
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'error');
					$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Alasan");
					$("#reaseonDeleteUserForce").val("")
					$('#deleteAnggotaPermanen').modal('hide');
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Hapus & Berikan Alasan");
					$("#reaseonDeleteUserForce").val("")
					$('#deleteAnggotaPermanen').modal('hide');
					$('#tableDataAnggota').DataTable().ajax.reload();
				}
			}

		});
	},

}

const StorageKelolaAnggotaPP = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(id) {
		if (this.isReady(id)) return
		let gold = {
			"id": id,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}

export default KelolaAnggotaPresenterPP;