import LoginInitiator from "../../presenter/login-presenter.js";
import Autentication from "../../utils/autentication.js";

const Login = {
    async render() {
        var value = await Autentication.isLogin()
        if(value){
                return ``
        }else{
            return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-10 col-lg-12 col-md-12">
      
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-5 p-3 text-center border-r d-lg-block ">
                                    <img loading="lazy" class="img-fluid" src="./src/public/img/logo.png" alt="image login" width="50%">

                                    <a id="btnJadwalOp" href="#/wilayah-time" class="btn col-sm-8 mt-4 ml-5 text-center d-none  btn-danger btn-user btn-block">Lihat jadwal operasional</a>
                                </div>
                                <div class="col-lg-7">
                                    <div class="p-3">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">Selamat Datang di  <span class="text-primary" >SI</span>PAFI</h1>
                                        </div>
                                       
                                        <form class="user" class="form-horizontal" id='login-user'>
                                            <div class="form-group">
                                                <input type="text" name="username" class="form-control form-control-user" required id="username" aria-describedby="username" placeholder="Email...">
                                            </div>
                                            <div class="form-group">
                                                <input type="password" class="form-control form-control-user" id="password" required name="password" placeholder="Password">
                                            </div>
                                            <hr>
                                            <button id="btnLogin" type="submit" class="btn btn-primary btn-user btn-block" style="margin-top:20px;">Login</button>
                                            <a id="btnForgotPassword" class="btn btn-google btn-user btn-block">Lupa Password</a>
                                            <hr>
                                            <a id="btnRegister" class="btn btn-info btn-user btn-block">Registrasi Anggota</a>
                                            <a id="btnUploadBuktiBayar" class="btn btn-warning btn-user btn-block">Upload Bukti Bayar Registrasi Anggota Baru</a>
                                            <a id="btnResendVerificationEmail" class="btn btn-default border border-primary text-primary btn-user btn-block">Kirim Ulang Verifikasi Email</a>
                                            <a id="btnResendPaymentRegist" class="btn btn-default border border-warning text-warning btn-user btn-block">Kirim Ulang Administrasi Pendaftaran</a>
                                            <a id="btnHome" href="https://webpafi.com/" class="btn btn-dark btn-user btn-block">Kembali Ke Home</a>
      
                                        </form>
                                        
                                        <hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
        }
   },
  
    async afterRender() {
        await LoginInitiator.init();
    },

    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default Login;
  

