import SKPAnggotaPCPresenter from "../../../presenter/menu_pengurus_pc/skp-anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const SKPAnggotaPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="card">
            <div class="card-header font-weight-bold text-primary">
                Filter Data
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label font-weight-bold">Status</label>
                        <select id="selectStatus" class="form-control">
                            <option value="pending">Pending</option>
                            <option value="approve">Disetujui</option>
                            <option value="revise">Revisi</option>
                            <option value="all">Semua</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header font-weight-bold text-primary">
                Pengajuan SKP Anggota
            </div>
            <div class="card-body">
                <div class="row">
                <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                <div class="row">
                    <div class="col-sm-8 mb-2">
                    
                    </div>
                    <div class="col-sm-4 d-none">
                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                            <i class="fas fa-check"></i>
                        </button>
                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                            <i class="fas fa-print"></i>
                        </button>
                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                            <i class="fas fa-download"></i>
                        </button>
                    </div>
                </div>
            </div>
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover" id="tablePengajuanSKP" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th data-column="latest_created_at">Tanggal Pengajuan Terakhir</th>
                                        <th data-column="ktan">KTAN</th>
                                        <th data-column="name">Nama</th>
                                        <th>NIK</th>
                                        <th data-column="total_skp">SKP</th>
                                        <th data-column="total_skp_pc">ACC PC</th>
                                        <th data-column="total_skp_pd">ACC PD</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    
                                </tfoot>
                                <tbody>
                                
                                
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
        
            <div class="modal fade" id="listSKPModal" tabindex="-1" role="dialog" aria-labelledby="listSKPModal"
				aria-hidden="true">
				<div class="modal-dialog modal-xl modal-skp" role="document">
					<div class="modal-content">
						<div class="modal-header text-primary font-weight-bold">
							Detail Pengajuan SKP Anggota
							<button class="close" type="button" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body">
                        
							<div class="card">
                                <div class="card-body with-border">
                                
                                    <input type="hidden" id="textUserID" class="form-control" readonly>
                                    <table width="100%" class="table table-bordered">
                                        <tr>
                                            <th width="20%">Nama Anggota</th>
                                            <th width="1%">:</th>
                                            <th id="textNamaAnggota"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">NIK</th>
                                            <th width="1%">:</th>
                                            <th id="textNIK"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">Email</th>
                                            <th width="1%">:</th>
                                            <th id="textEmail"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">KTAN</th>
                                            <th width="1%">:</th>
                                            <th id="textKTAN"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">No STRTTK</th>
                                            <th width="1%">:</th>
                                            <th id="textNoSTRTTK"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">Tanggal Terbit STRTTK</th>
                                            <th width="1%">:</th>
                                            <th id="textTanggalSTRTTK"></th>
                                        </tr>
                                        <tr>
                                            <th width="20%">Masa Berlaku STRTTK</th>
                                            <th width="1%">:</th>
                                            <th id="textMasaBerlakuSTRTTK"></th>
                                        </tr>
                                    </table>
                                </div>
                                <div class="card-body">
                                    <div class="card">
                                        <div class="card-header font-weight-bold text-primary">
                                            Filter Data
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label class="control-label font-weight-bold">Status</label>
                                                    <select id="selectStatusList" class="form-control">
                                                        <option value="pending">Pending</option>
                                                        <option value="approve">Disetujui</option>
                                                        <option value="revise">Revisi</option>
                                                        <option value="all">Semua</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                    
                                        <div class="col-sm-12">
                                        
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover" id="tablePengajuanSKPDet" width="100%" cellspacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center" ><input type="checkbox" id="checkAll"> Pilih Semua</th>
                                                            <th data-column="tanggal_mulai">Tanggal Mulai</th>
                                                            <th data-column="tanggal_selesai">Tanggal Selesai</th>
                                                            <th data-column="berlaku_sampai">Berlaku Sampai</th>
                                                            <th data-column="nama_kegiatan">Nama Kegiatan</th>
                                                            <th data-column="nomor_sertifikat">Nomor Sertifikat</th>
                                                            <th data-column="kategori">Kategori</th>
                                                            <th data-column="jenis_kegiatan">Jenis Kegiatan</th>
                                                            <th data-column="nomor_surat_keputusan"> Nomor Surat Keputusan</th>
                                                            <th >Sertifikat</th>
                                                            <th >Bukti Kegiatan</th>
                                                            <th   data-column="skp">SKP</th>
                                                            <th   data-column="skp_approve_pc" width="100px;">ACC PC</th>
                                                            <th  data-column="skp_approve_pd"  width="100px;">ACC PD</th>
                                                            <th  data-column="status_pc" width="100px;">STATUS PC</th>
                                                            <th  data-column="status_pd" width="100px;">STATUS PD</th>
                                                            <th width="5%">Aksi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                        </tr>
                                                        <tr>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer title-line"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer circle"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                            <td><div class="shimmer title-line end"></div></td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        
                                                    </tfoot>
                                                    <tbody>
                                                    
                                                    
                                                    
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>


            <div class="modal fade" id="commentModalSKP" tabindex="-1" role="dialog" aria-labelledby="commentModal"
            aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-primary font-weight-bold">
                            Komentar Pengajuan SKP
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                        <div style="width:100%">
                                            <div style="width:10%;" class="float-left">
                                                <div class="shimmer circle"></div>
                                            </div>
                                            <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line end"></div>
                                            </div>
                                        </div>
                                        <div style="width:100%">
                                            <div style="width:10%;" class="float-right">
                                                <div class="shimmer circle"></div>
                                            </div>
                                            <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line end"></div>
                                            </div>
                                        </div>
                                        <div style="width:100%">
                                            <div style="width:10%;" class="float-left">
                                                <div class="shimmer circle"></div>
                                            </div>
                                            <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line end"></div>
                                            </div>
                                        </div>
                                        <div style="width:100%">
                                            <div style="width:10%;" class="float-right">
                                                <div class="shimmer circle"></div>
                                            </div>
                                            <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line"></div>
                                                <div class="shimmer content-line end"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <form id="form-comment-user-ktan">
                                        <label class="control-label font-weight-bold">Komentar</label>
                                        <textarea class="form-control" id="komentarPengajuanSKP" name="komentar" placeholder="Komentar..." required></textarea>
                                        <br>
                                        <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanSKP"><i class='fas fa-comment'></i> Berikan Komentar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="modalApprovementSKP" tabindex="-1" role="dialog" aria-labelledby="modalApprovementSKP"
				aria-hidden="true">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header font-weight-bold text-primary">
							SKP Anggota
							<button class="close" type="button" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body">
                            <div class="row m-1">
                                <div class="col-sm-12 text-center">
                                    <img loading="lazy" id="imgSertifikat" src="" class="img-fluid rounded mb-3">
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Resume Kegiatan</label>
                                        <div class="col-sm-12">
                                            <textarea readonly rows="5" class="form-control" id="textResume" placeholder="Resume Kegiatan..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-12">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">Nama Kegiatan</label>
                                            <textarea id="textNamaKegiatan" class="form-control" readonly></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">Nomor Sertifikat</label>
                                            <input type="text" id="textNomorSertifikat" class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">Nomor Surat Keputusan</label>
                                            <input type="text" id="textNomorSuratKeputusan" class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">Jenis Kegiatan</label>
                                            <input type="text" id="textJenisKegiatan" class="form-control" readonly>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">Kategori</label>
                                            <input type="text" id="textKategori" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-1">
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">SKP</label>
                                            <input type="number" id="numberSKP" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label control-label">SKP Di Setujui</label>
                                            <input type="number" id="numberSKPDiSetujui" class="form-control" placeholder="SKP Di Setujui...">
                                            <small class="d-none" style="" id="validationSKP"></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
						</div>
						<div class="modal-footer">
							<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
							<button class="btn btn-success" id="btnApproveSKP"><i class="fas fa-check"></i></i> Approve</button>
						</div>
					</div>
				</div>
			</div>

            
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Revisi & Berikan Komentar
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

            <div class="modal fade bd-example-modal-lg" id="listApprove" tabindex="-1" role="dialog" aria-labelledby="listApprove"
                aria-hidden="true">
                <div class="modal-dialog modal-xl modal-skp" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Approve All Pengajuan SKP Anggota</h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body" id="skp_approve_content">
                           
                        </div>
                        <div class="modal-footer">
                        <button class="form-control btn btn-primary btn" id="btnApproveAllConfirmed">
                            <i class="fas fa-paper-plane"></i>&nbsp; &nbsp; Approve all
                        </button>
                            <button class=" form-control btn btn-secondary " id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalViewResume" tabindex="-1" role="dialog" aria-labelledby="modalViewResume"
            aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="titleSKP"></h5>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body" id="resumeSKP">
                        
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        `;
		return view;
	  },

	async afterRender() {
		await SKPAnggotaPCPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SKPAnggotaPC;