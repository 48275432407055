import UrlParser from '../routes/url-parser.js';
import ApiRegister from "../api/data-register.js";
import NotificationModal from "../utils/initial_notification.js";

const UploadBuktiBayarRegistrasiPresenter = {

	async init() {
		await this._setView();
		// await this._setUpViewSerkom();
		// await this.initProvinceValue();
		await this._uploadBuktiBayar();
	},

	async _setView() {
		document.getElementById("main-content").style.height = "100vh";
		document.getElementById("cpRght").style.display = "none";
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},

	
	async _uploadBuktiBayar(){
		let DzoneBuktiBayar = new Dropzone("#fileBuktiBayar", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#fileBuktiBayar .dz-preview:hidden').remove()
					$('#fileBuktiBayar .dz-preview').fadeOut('slow');
					document.getElementById("fileBuktiBayar").classList.remove('dz-started');
				});
			}
		});

		
		const eventUpload = async (e) => {
			e.preventDefault();

			
			let textEmail = $("#email").val();
			if (DzoneBuktiBayar.files.length <= 0) {
				NotificationModal.show('File upload tidak boleh kosong !', 'error')
				document.getElementById("btnUploadBuktiBayar").innerHTML = `<i class="fas fa-upload"></i> Kirim Bukti Bayar</button>`;
			}else {
			
				document.getElementById("btnUploadBuktiBayar").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const sendBuktiBayarRegistrasi = await ApiRegister.sendBuktiBayarRegistrasi({
				email : textEmail,
				buktiBayarRegis : (DzoneBuktiBayar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBuktiBayar.files[0].dataURL ,DzoneBuktiBayar.files[0].name )
				

				});
				if (sendBuktiBayarRegistrasi.status_code != 200) {
					NotificationModal.show(`${sendBuktiBayarRegistrasi.message}`, 'error');
					DzoneBuktiBayar.emit("removeThumbnailCustom");
					document.getElementById("btnUploadBuktiBayar").innerHTML = `<i class="fas fa-upload"></i> Kirim Bukti Bayar</button>`;
					$("#btnReset").trigger("click");
				} else {
					swal.fire({
						title: 'Success',
						html: "Bukti bayar registrasi berhasil di kirim",
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ok'
					}).then(async (result) => {
						if (result.value) {
							
						};
					});
					DzoneBuktiBayar.emit("removeThumbnailCustom");
					document.getElementById("btnUploadBuktiBayar").innerHTML = `<i class="fas fa-upload"></i> Kirim Bukti Bayar</button>`;
				}
				

				e.preventDefault();

			}
		}

		
		document.getElementById('form-upload-bukti-bayar-registrasi').addEventListener('submit', eventUpload);
	},

	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},



}

export default UploadBuktiBayarRegistrasiPresenter;
