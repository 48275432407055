
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiStats from "../../api/data-statistik.js";
import FormatCurrency from "../../utils/initial-currency.js";
import STATISTIK_ENDPOINT from "../../config/globals/statistik_endpoint.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";


const StatistikIuranPDPresenter = {

	async init() {
		await this._setView();
		await this._getStasByPeriod();
		await this._sendBuktiBayarBeforClick();
		await this._getListSetorInFiltered();
		await this._getListIuranPC()
		await this.approveAfterClickButton();
		await this._initCommentIuran();
		await this._getDataPrivate()
	},
	
	async _getDataPrivate() {

		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)

		var selectPCAnggota = document.getElementById("selectPCIn");
		var lengthOptionPcAnggota = selectPCAnggota.options.length;
		for (let i = lengthOptionPcAnggota - 1; i >= 0; i--) {
			selectPCAnggota.options[i] = null;
		}

		document.getElementById("selectPCIn").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectPCIn").add(new Option(data.regency_name, data.id))
		});


	},
	async approveAfterClickButton(){
		$("#tableSetorPC tbody").on("click", "#approve", async function () {
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`)
			const table = $("#tableSetorPC").DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Setujui',
				html: "Anda ingin menyetujui data ini? <br> <strong>Nama PC </strong>: " + data.cabang_name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Setujui!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;
					let approval = 'approve'
					let role = 'pd'
					await StatistikIuranPDPresenter.approveSharing(id, role ,approval);
			
					$(this).html(`<i class="fas fa-check"></i>`)
				}
			});

		})

		
		$("#tableSetorPC tbody").on("click", "#reject", async function () {
			const table = $("#tableSetorPC").DataTable();
			let data = table.row($(this).parents('tr')).data();
			document.getElementById('komentarPengajuanReject').value = ''
			$("#btnSendKomentarPengajuanReject").click(function () {
				eventRejectSharing(data);
			});
		});
		const eventRejectSharing =  async (data) => {
			swal.fire({
				title: 'Tolak',
				html: "Anda ingin menolak data ini? <br> <strong>Nama PC </strong>: " + data.cabang_name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Tolak!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;
					let approval = 'reject';
					let role = 'pd';
					let catatan = document.getElementById('komentarPengajuanReject').value;
					await StatistikIuranPDPresenter.approveSharing(id,role,approval,catatan);
					document.getElementById('komentarPengajuanReject').value = ''
					$("#btnCancel").trigger('click');

				}
			});
		}



	},
	
	async _initCommentIuran() {
		$('#tableSetorPC tbody').on('click', '#comment', async function () {

			const table = $('#tableSetorPC').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id;


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiStats.getCommentar(idPengajuan,"deposit")
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});
		
	},

	async approveSharing(id, role, approval, catatan) {
		let approveSharing = null; // Inisialisasi dengan null
	  
		if (approval === 'approve') {
		  approveSharing = await ApiStats.approveSharing({
			id: id,
			role: role,
			approval: approval,
		  });
		} else {
		  approveSharing = await ApiStats.approveSharing({
			id: id,
			role: role,
			approval: approval,
			catatan: catatan,
		  });
		}
	  
		if (approveSharing.status_code === 200) {
		  NotificationModal.show(approveSharing.message, 'success');
		  await this._getStasByPeriod();
		  await this._getListIuranPC();
		  await this._getListSetorInFiltered();
		} else {
		  NotificationModal.show(approveSharing.message, 'error');
		  await this._getStasByPeriod();
		  await this._getListIuranPC();
		  await this._getListSetorInFiltered();
		}
	  }
	  ,

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},

	async _sendBuktiBayar(idInvoice){
		let DzoneIuran = new Dropzone("#form-upload-bukti-bayar-iuran", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-bukti-bayar-iuran .dz-preview:hidden').remove()
					$('#form-upload-bukti-bayar-iuran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-bukti-bayar-iuran").classList.remove('dz-started');
				});
			}
		});



		const eventCreatPembayaran = async (e) => {
			e.preventDefault();
			if (DzoneIuran.files.length <= 0) {
				NotificationModal.show('Bukti bayar tidak boleh kosong !', 'error')
				document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;
			}else {
				document.getElementById("btnSaveIuran").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPembayaranIuran = await ApiStats.sendBuktiBayar({
				invoiceID : idInvoice,
				buktiBayar : (DzoneIuran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneIuran.files[0].dataURL ,DzoneIuran.files[0].name ),
				tanggalSetor: document.getElementById("dateTanggalSetor").value,
				paymentMethod: document.getElementById("selectPaymentMethod").value
				});
				if (createPembayaranIuran.status_code != 200) {
					NotificationModal.show(`${createPembayaranIuran.message}`, 'error');
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;				
				} else {
					swal.fire({
						title: 'Success',
						html: "Bukti bayar iuran berhasil di kirim",
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ok'
					}).then(async (result) => {
						if (result.value) {
							$("#getListSetor").DataTable().ajax.reload();
							$("#modalBayarIuran").modal("hide");
						};
					});
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran")	.innerHTML = `Kirim Bukti Bayar`;
				}
				

				e.preventDefault();

			}
		}
		document.getElementById('btnSaveIuran').addEventListener('click', eventCreatPembayaran);

		
		
		
	},

	async _sendBuktiBayarBeforClick(){
		let idInvoice = '';
		$("#getListSetor tbody").on("click", "#btnBayar", async function () {
			const table = $("#getListSetor").DataTable();
			let data = table.row($(this).parents('tr')).data();
			idInvoice = data.id;
			StatistikIuranPDPresenter._sendBuktiBayar(idInvoice)

		})
	},

	async _getStatsIuran(startDate,endDate){
		const getStats = await ApiStats.getStatsIuran({
			startDate:startDate,
			endDate:endDate,
			role:'pd'
		})
		let data = getStats.data;
		if (getStats.status_code == 200) {
			let totalIuran = data.total_iuran;
			let forPD = data.from_pc.for_pd;
			let forPP = data.from_pc.for_pp;
			let forPDSisa = data.from_pc.for_pd_sisa;
			let forPPSisa = data.from_pc.for_pp_sisa;
			let forALL = data.from_pc.total_deposit_must;
			let depositPaid = data.from_pc.total_deposit_paid;
			let depositSisa = data.from_pc.total_deposit_must_sisa;
			let totalSisaIuran = data.from_pc.total_iuran_deposit_unpaid; 
			
			const formatedForPDSisa = forPDSisa.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedForPPSisa = forPPSisa.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedSisaIuran = totalSisaIuran.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedTotalIuran = totalIuran.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedForPD = forPD.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			
			const formattedForPP = forPP.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedForALL = forALL.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedDepositPaid = depositPaid.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			const formatedDepositSisa = depositSisa.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			$("#iuran").html(formatedTotalIuran)
			$("#forPD").html(formatedForPD)
			$("#forPP").html(formattedForPP)
			$("#forAll").html(formatedForALL)
			$("#depositPaid").html(formatedDepositPaid)
			$("#depositSisa").html(formatedDepositSisa)
			$("#totalSisaIuran").html(formatedSisaIuran)
			$("#forPDSisa").html(formatedForPDSisa)
			$("#forPPSisa").html(formatedForPPSisa)
		}
	},
	
	
	async _getListSetorInFiltered(){
		let status = document.getElementById('selectStatusIn').value
		let idCabang = document.getElementById('selectPCIn').value
		await this._getListSetorPC(status,idCabang)

		const eventChangeFiltered = async () => {
			let status = document.getElementById('selectStatusIn').value
			let idCabang = document.getElementById('selectPCIn').value
			await this._getListSetorPC(status,idCabang)
		}

		document.getElementById('selectStatusIn').addEventListener('change', eventChangeFiltered)
		document.getElementById('selectPCIn').addEventListener('change', eventChangeFiltered)
	},


	async _getListIuranPC(startDate,endDate){
		let accumulationDeposit = '';
		let table = await $('#tableListIuranPC').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i> Copy to clipboard',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i> Download CSV',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i> Download Excel',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				}
			],
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${STATISTIK_ENDPOINT.GET_LIST_IURAN_PC(startDate,endDate)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'pc_id'},
				{ data: 'cabang_name'},
				{ data: 'total_iuran_deposit_unpaid', render: function (data,type, rows) {

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },
				{ data: 'for_pd_sisa', render: function (data,type, rows) {

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },
				{ data: 'for_pp_sisa', render: function (data,type, rows) {
					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },
				{ data: 'total_deposit_must_sisa', render: function (data,type, rows) {
					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});
					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },

			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
			{
				"targets": [0],
				"visible": true,
				"searchable": true,
			}
			]
		});
	},
//
	async _getListSetorPC(status,idCabang){
		let table = await $('#tableSetorPC').DataTable({
			"pageLength": 1,
			"lengthMenu": [1, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${STATISTIK_ENDPOINT.GET_LIST_SETOR_IN(status,idCabang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'deposit_date'},
				{ data: 'cabang_name'},
				{ data: 'amount', render: function (data,type, rows) {
					var amount = rows.amount;

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(amount);

					return formattedAmount
				} },
				{ data: 'payment_method'},
				{ data: 'description'},
				{ data: 'bukti_bayar_deposit', render: function (data) {
					let content = '';
					if (data == null || data == '') {
						content = '<strong>Tidak ada data</strong>'
					}else {
						content = `<img src="${data}" width="80px" class="rounded">`
					}
					return content
				}},
				{ data: 'status', render : function (data) {
					
					let content = ``
					if (data == 'Lunas') {
						content = `<div class="badge badge-success">${data}</div>`
					}else if (data == 'Menunggu Konfirmasi') {
						content = `<div class="badge badge-warning">${data}</div>`
					}else {
						
						content = `<div class="badge badge-danger">${data}</div>`
						
					}
					return content
				}},
				{ data: 'status', render : function (data) {
					let content = ``
					if (data == 'Lunas' || data == 'Belum Bayar') {
						content = `
						<div style="display:flex; text-align:center">
							<button style="margin:1px" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalSetor" id='comment' title='Komentar'><i class='fas fa-sticky-note'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalReject" id='reject' title='Reject'><i class="fas fa-times"></i></button>
						</div>`
					}else {
						content = `
						<div style="display:flex; text-align:center">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
							<button style="margin:1px" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalSetor" id='comment' title='Komentar'><i class='fas fa-sticky-note'></i></button>
							<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModalReject" id='reject' title='Reject'><i class="fas fa-times"></i></button>
						</div>`
					}

					return content
				}},
				

			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true,
				},
				{
					"targets": [5,6],
					"visible": true,
					"searchable": false,
					"className": 'text-center',
				},
			// {
			// 	"targets": 6,
			// 	"data": null,
			// 	"defaultContent": `
			// 		<div style="display:flex">
			// 			<button id="btnBayar" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalBayarIuran" ><i class="fas fa-dollar-sign"></i> <br>Bayar</button>&nbsp;
			// 			<button style="" data-toggle="modal" data-target="#commentModalIuran" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-sticky-note'></i><br>Noted</button>&nbsp;
			// 			<button id="btnCancel" class="btn btn-sm btn-danger" ><i class="fas fa-trash"></i> <br>Batal</button>
			// 		</div>`
			// },
			]
		});

		const eventDwonload = async (e) => {
			var pageInfo = table.page.info();
			var startValue = pageInfo.start;
			var lengthValue = pageInfo.length;
			let status = document.getElementById('selectStatusIn').value
			let idCabang = document.getElementById('selectPCIn').value
			let response = await ApiStats.downloadDepostPD(startValue,lengthValue,status,idCabang)
		}
		$('#unduh_statistic').off('click').on('click', async function(e) {
			await eventDwonload();
			e.preventDefault()
		});
	},
	async _getStasByPeriod(){
		await this._getStatsIuran('','')

		const eventClickHitung = async (e) => {
			let startDate = document.getElementById('dateDariTanggal').value;
			let endDate = document.getElementById('dateSampaiTanggal').value;
			
			await this._getStatsIuran(startDate,endDate)
		}

		document.getElementById('btnHitungData').addEventListener('click', eventClickHitung);
	},


	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	


}



export default StatistikIuranPDPresenter;