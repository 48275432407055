
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiAnggota from "../../api/data-anggota.js";
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';


const IuranAnggotaPCPresenter = {

	async init() {
		await this._setView();
		await this._getIuranFiltered();
		await this._approvalIuran();
		await this._initCommentIuran();
	},
	
	async _showLoading(){
		var loadingElement = document.createElement("div");
		loadingElement.setAttribute("id", "loading");
		
		var spinnerElement = document.createElement("div");
		spinnerElement.setAttribute("class", "spinner");
		
		for (var i = 0; i < 3; i++) {
			var barElement = document.createElement("div");
			barElement.setAttribute("class", "bar");
			spinnerElement.appendChild(barElement);
		}
		
		loadingElement.appendChild(spinnerElement);
		document.body.appendChild(loadingElement);
	},

	async _hideLoading(){
		var loadingElement = document.getElementById("loading");
		if (loadingElement) {
			loadingElement.parentNode.removeChild(loadingElement);
		}
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async _getIuranFiltered(){
		
		let status = document.getElementById('selectJenisKeanggotaan').value;
		let startDate = document.getElementById('selectStartDate').value;
		let endDate = document.getElementById('selectEndDate').value;
		await this._getIuranAnggota(status, startDate, endDate)
		const eventResetFilter = async (e) => {
			let status = document.getElementById('selectJenisKeanggotaan').value = 'confirm';
			let startDate = document.getElementById('selectStartDate').value = '';
			let endDate = document.getElementById('selectEndDate').value = '';
			await this._getIuranAnggota(status, startDate, endDate)
		}
		const eventFiltered = async (e) => {
			let status = document.getElementById('selectJenisKeanggotaan').value;
			let startDate = document.getElementById('selectStartDate').value;
			let endDate = document.getElementById('selectEndDate').value;
			await this._getIuranAnggota(status, startDate, endDate)
		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefresh').addEventListener('click', eventFiltered);
		document.getElementById('btnReset').addEventListener('click', eventResetFilter);
	},


    async _getIuranAnggota(status, startDate, endDate){
		
		this._showLoading();
		let statusInvoice = status;
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tableIuranAnggota').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i> Copy to clipboard',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i> Download CSV',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i> Download Excel',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				}
			],
			
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.GET_IURAN_ANGGOTA(statusInvoice, startDate, endDate)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'invoice_id' },
				{ data: 'created_at' },
				{ data: 'user.name' },
				{ data: 'user.ktan' },
				{ data: 'user.email' },
				{ data: 'bulan_awal' },
				{ data: 'bulan_akhir' },
				{ data: 'jumlah_bulan_iuran' },
				{ data: 'grand_total',
				 render: function (data) {
					data = parseFloat(data).toFixed(2);
					
					// Menambahkan titik sebagai pemisah ribuan
					data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					
					return "Rp. "+data;
				} 
				},
				{ data: 'fee_name' },
				{ data: 'bukti_bayar_membership', render: function (data) {
					var content = '';
					if (data == '' || data == null) {
						content = `-`;
					}else {
						content = `<img loading="lazy" src="${data}" width="40px">`
					}

					return content
				} },
				{ data: 'status', render: function (data) {
					var content = '';
					if (data == 'Belum Bayar') {
						content = `<span class="badge badge-danger">Belum Bayar</span>`;
					}else if (data == 'Menunggu Konfirmasi') {
						content = `<span class="badge badge-warning">Menunggu Konfirmasi</span>`;
					}else if (data == 'Menunggu Konfirmasi') {
						content = `<span class="badge badge-warning">Menunggu Konfirmasi</span>`;
					}else{
						content = `<span class="badge badge-success">Lunas</span>`
					}

					return content
				} },
				{ data: 'admin.name', render: function (data) {
					let adminName = '';

					if (data == null || data == '') {
						adminName = '-'
					}else {
						adminName = data
					}

					return adminName
				} },
			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [10,11],
					"visible": true,
					"searchable": false,
					className:'text-center'
				},
				{
					"targets": [10,11],
					"orderable": false,
				},
				{
					"targets": 13,
					"data": null,
					render : function (data) {
						let status = data.status;
						let disabled = ''
						if (status != 'Menunggu Konfirmasi') {
							disabled = 'd-none'
						}

						let content = 
						`
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-primary btn-sm ${disabled}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
						<button style="margin:1px; position:relative" class='btn btn-info btn-sm' data-toggle="modal" data-target="#commentModalUserIuran" id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data.comment_unread_count}</span></button>
						<button style="margin:1px" class='btn btn-danger btn-sm' id='reject' title='Reject'><i class="fas fa-times"></i></button>
					</div>`

					return content
					} 
				},
				]
		});

		$('#tableIuranAnggota thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			var columnName = ($(this)[0]).getAttribute('data-column')
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

		


		this._hideLoading();
    },

	
	async _initCommentIuran() {
		
		let userID = ``;
		let idPengajuan = ``;
		$('#tableIuranAnggota tbody').on('click', '#comment', async function () {
			const table = $('#tableIuranAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			userID = data.user.id
			idPengajuan = data.id;

			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanKTANPC.getCommentar(userID,"membership_fee", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});


		const eventSendCommentar = async (e) => {
			e.preventDefault();
			document.getElementById("btnSendKomentarPengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuan").value
			const response = await ApiPengajuanKTANPC.sendCommentar({
				userID: userID,
				pengajuanID: idPengajuan,
				commentFor: 'membership_fee',
				contentComment: content,
			});
			if (response.status_code == 200 || 201) {

				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanKTANPC.getCommentar(userID,"membership_fee", idPengajuan)
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$("#komentarPengajuan").val('');
				document.getElementById("btnSendKomentarPengajuan").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuan").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuan").addEventListener("click", eventSendCommentar)
	},
    // async _getIuranAnggotaFiltered(){
	// 	let statusInvoice = '';
	// 	$(".btn-jenis-permohonan").on("click", async function(){
	// 		var status = $(this).val();
	// 		let buttonAllElement  = document.querySelectorAll('.btn-jenis-permohonan')

	// 		buttonAllElement.forEach((element) => {
	// 			element.classList.remove('btn-primary');
	// 			element.classList.add('btn-outline-primary');
	// 		  });

	// 		this.classList.remove('btn-outline-primary')
	// 		this.classList.add('btn-primary')


	// 		if (status == 'approve') {
	// 			var bg = 'bg-success'
	// 			$('#btnVerifikasiAll').hide()
	// 			var disabled = 'd-none';
	// 		}else if (status == 'revise') {
	// 			var bg = 'bg-warning'
	// 			$('#btnVerifikasiAll').hide()
	// 		}else if(status == 'pending'){
	// 			var bg = 'bg-primary'
	// 			$('#btnVerifikasiAll').show()
	// 		}

	// 	});

    //     let table = await $('#tableIuranAnggota').DataTable({
	// 		"pageLength": 10,
	//		"lengthMenu": [10,25,50,100],
	// 		"processing": true,
	// 		"destroy": true,
	// 		'language': {
	// 			'loadingRecords': '&nbsp;',
	// 			'processing': '<div class="spinner"></div>'
	// 		},
	// 		"serverSide": true,
			// "searchDelay": 1000,
	// 		"ajax": {
	// 			"url": `${API_ENDPOINT_PENGURUS_PC.GET_IURAN_ANGGOTA('confirm')}`,
	// 			"type": "GET",
	// 			"beforeSend": function (xhr) {
	// 				xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
	// 			}
	// 		},
	// 		"columns": [
	// 			{ data: 'invoice_id' },
	// 			{ data: 'user.name' },
	// 			{ data: 'user.ktan' },
	// 			{ data: 'user.email' },
	// 			{ data: 'bukti_bayar_membership', render: function (data) {
	// 				var content = '';
	// 				if (data == '' || data == null) {
	// 					content = `-`;
	// 				}else {
	// 					content = `<img loading="lazy" src="${data}" width="40px">`
	// 				}

	// 				return content
	// 			} },
	// 			{ data: 'status', render: function (data) {
	// 				var content = '';
	// 				if (data == 'Belum Bayar') {
	// 					content = `<span class="badge badge-danger">Belum Bayar</span>`;
	// 				}else if (data == 'Menunggu Konfirmasi') {
	// 					content = `<span class="badge badge-warning">Menunggu Konfirmasi</span>`;
	// 				}else{
	// 					content = `<span class="badge badge-success">Lunas</span>`
	// 				}

	// 				return content
	// 			} },
	// 		],
	// 		order: [
	// 			[0, 'asc']
	// 		],
	// 		"columnDefs": [
	// 			{
	// 				"targets": [0],
	// 				"visible": true,
	// 				"searchable": false
	// 			},
	// 			{
	// 				"targets": [4],
	// 				"visible": true,
	// 				"searchable": false,
	// 				className:'text-center'
	// 			},
	// 			{
	// 				"targets": 6,
	// 				"data": null,
	// 				"defaultContent": `
	// 				<div style="display:flex">
	// 					<button class='mb-1 btn btn-primary btn-sm' id='approve' title='Approve'><i class='fas fa-check'></i></button>
	// 					&nbsp;
	// 					<button class='mb-1 btn btn-danger btn-sm' id='reject' title='Reject'><i class="fas fa-times-circle"></i></button>
	// 				</div>`
	// 			},
	// 			]
	// 	});


    // },

	async _approvalIuran(){
		$('#tableIuranAnggota tbody').on('click', '#approve', async function () {
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`)
			const table = $('#tableIuranAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let statusApprove = 'approve';
			let dataId = data.id;
			
			const approveIuranAnggota = await ApiKepengurusanPC.approvalIuranAnggota({
				id:dataId,
				statusApprove:statusApprove
			})

			if (approveIuranAnggota.status_code == 200) {
				NotificationModal.show(`Iuran anggota berhasil di approve`, 'success');
				$(this).html(`<i class='fas fa-check'></i>`)
				$("#tableIuranAnggota").DataTable().ajax.reload()
			}else {
				NotificationModal.show(`${approveIuranAnggota.message}`, 'error');
				$(this).html(`<i class='fas fa-check'></i>`)
				$("#tableIuranAnggota").DataTable().ajax.reload()
			}

		});

		
		$('#tableIuranAnggota tbody').on('click', '#reject', async function () {
			const table = $('#tableIuranAnggota').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let statusApprove = 'reject';

			
			swal.fire({
				title: 'Tolak',
				html: "Anda ingin menolak data ini? <br> <strong>Nama Anggota </strong>: " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Tolak!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const approveIuranAnggota = await ApiKepengurusanPC.approvalIuranAnggota({
						id:id,
						statusApprove:statusApprove
					})

					if (approveIuranAnggota.status_code == '200') {
						swal.fire({
							title: 'Success',
							text: 'Iuran anggota berhasil di tolak',
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tableIuranAnggota').DataTable().ajax.reload();
						
					}else {
						swal.fire({
							title: 'Error',
							text: approveIuranAnggota.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});
	},

	


}



export default IuranAnggotaPCPresenter;