import AssignRolePresenter from "../../../presenter/menu_pengurus_pp/AssignRolePresenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const AssignRole = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row mt-3">
            <div class="col-sm-7">
                <div class="card">
                    <div class="card-header text-primary font-weight-bold">
                        Kelola Role User
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                            <form id="form-assign-role">
                                <input type="hidden"  class="form-control" required id="userId">
                                <div class="form-group row">
                                    <label class="control-label col-sm-3 font-weight-bold">Nomor KTAN</label>
                                    <div class="col-sm-4">
                                        <input type="text" placeholder="XXXX.XXXXXXXX.X.XXXX" class="form-control" required id="tvKtan">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-3 font-weight-bold">Nama Lengkap</label>
                                    <div class="col-sm-9">
                                        <input type="text" placeholder="Nama Lengkap..." class="form-control" required id="textUserName" readonly>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-3 font-weight-bold">Email</label>
                                    <div class="col-sm-9">
                                        <input type="text" placeholder="Email..." class="form-control" required id="textEmail" readonly>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <input type="hidden" id="textHiddenIdPendidikan">
                                    <label class="control-label col-sm-3 font-weight-bold">Pilih Role User</label>
                                    <div class="col-sm-9">
                                        <select class="form-control" id="selectRoleUser" required>
                                            <option value="">Pilih Role User</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-3 font-weight-bold"></label>  
                                    <div class="col-sm-9 mb-4">
                                        <button type="submit" class="form-control btn btn-primary" id="btnSaveRole"><i class="fas fa-save"></i> Simpan </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="col-sm-5">
                <div class="card mb-3">
                    <div class="card-header text-primary font-weight-bold">
                        Filter Data
                    </div>
                    <div class="card-body">
                        <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                            <div class="row">
                                <div class="col-sm-12 mb-2">
                                    <label class="control-label font-weight-bold">Jenis Role</label>
                                    <select id="selectJenisKeanggotaan" class="form-control">
                                        <option value="">Pilih Satu</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 mb-2">
                                    <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                    <select id="selectPengajuanPD" class="form-control">
                                        <option value=''>Pilih Daerah</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 mb-2">
                                    <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                    <select id="selectPengajuanPC" class="form-control">
                                        <option value=''>Pilih Cabang</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-right">
                        <button class="btn btn-primary" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 mt-3">
            
                <div class="card">
                    <div class="card-header text-primary font-weight-bold">
                        Data Role User
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <table class="table table-bordered table-hover" id="tableRoleUser" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Nama Anggota</th>
                                            <th>Email</th>
                                            <th>KTAN</th>
                                            <th>Jenis Kelamin</th>
                                            <th>Tempat Lahir</th>
                                            <th>Tanggal Lahir</th>
                                            <th>No HP</th>
                                            <th>PC</th>
                                            <th>PD</th>
                                            <th>Poto</th>
                                            <th>Role</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer circle"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line end"></div></td>
                                        </tr>
                                        <tr>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer circle"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line end"></div></td>
                                        </tr>
                                        <tr>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer circle"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line end"></div></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        
                                    </tfoot>
                                    <tbody>
                                    
                                    
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalRolesUser" tabindex="-1" role="dialog" aria-labelledby="modalRolesUser"
				aria-hidden="true">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Detail Anggota</h5>
							<button class="close" type="button" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body" id="detailBody">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h6 class="font-weight-bold">Detail Anggota</h6>
                                    <table class="table" width="100%">
                                        <tr>
                                            <td>Nama Anggota</td>
                                            <td>:</td>
                                            <td id="namaAnggota"></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>:</td>
                                            <td id="emailAnggota"></td>
                                        </tr>
                                        <tr>
                                            <td>KTAN</td>
                                            <td>:</td>
                                            <td id="ktan"></td>
                                        </tr>
                                        <tr>
                                            <td>PD</td>
                                            <td>:</td>
                                            <td id="pdAnggota"></td>
                                        </tr>
                                        <tr>
                                            <td>PC
                                            <td>:</td>
                                            <td id="pcAnggota"></td>
                                        </tr>
                                    </table>
        
                                    
                                    <h6 class="font-weight-bold">Roles Anggota</h6>
                                    <table class="table" id="tableRoles" width="100%">
                                        <thead>
                                            <tr>
                                                <th width="50%">Nama Role</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody id="">
        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            
						</div>
						<div class="modal-footer">
							<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</div>

                        
        `;
		return view;
	  },

	async afterRender() {
        await AssignRolePresenter.init()
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default AssignRole;