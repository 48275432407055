
import API_ENDPOINT_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPP from "../../api/api_pengurus_pp/kepengurusan-pp.js";
import NotificationModal from "../../utils/initial_notification.js";


const GeneralSettingPPPresenter = {

	async init() {
		await this._setView();
		await this._getSetting();
		await this._getSettingDataBiaya()
		await this._eventUpdate()
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		const formattedNumberInput = document.getElementById('textBiaya');

		formattedNumberInput.addEventListener('input', async (event) => {
			// Hapus semua karakter non-digit dari input
			const validInput = event.target.value.replace(/\D/g, '');
			// Pastikan hanya angka yang dimasukkan
			event.target.value = this.formatToRupiah(validInput);
		});

	
	},

	async _showLoading(){
		
	},

	async _hideLoading(){
	
	},

	async _eventUpdate(){


		const updateEvent = async (e)=>{
			let valueChanged = this.rupiahToNumber($("#textBiaya").val())
			swal.fire({
				title: 'Ubah pengaturan biaya ',
				html: "Yakin megubah data Biaya ini ? Key : " + $("#textKey").val(),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ubah'
			}).then(async (result) => {
				if (result.value) {
					document.getElementById("btnSaveRole").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const updateSetting = await ApiKepengurusanPP.updateSettingBiaya({
						key : $("#textKey").val(),
						description: $("#textDescription").val(),
						value : valueChanged,
						name : $("#textJudul").val()
					});
			
					if (updateSetting.status_code != 200) {
						NotificationModal.show(`${updateSetting.message}`, 'error');
						document.getElementById("btnSaveRole").innerHTML = `<i class="fas fa-edit"></i> Simpan`;
					} else {
						NotificationModal.show(`${updateSetting.message}`, 'success');
						document.getElementById("btnSaveRole").innerHTML = `<i class="fas fa-edit"></i> Simpan`;
						$("#modalRolesUser").modal("hide");
						$('#tableSettingBiaya').DataTable().ajax.reload();
					}
				}
			});
			e.preventDefault();
		}
		
		document.getElementById("form-update-setting").addEventListener("submit", updateEvent)
		
	},
	
	async _getSetting(){

		const formatToRupiah = (value) =>{
			return this.formatToRupiah(value)
		}
		let table = await $('#tableSettingBiaya').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			"scrollX": true,
			
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PP.GET_SETTING_GENERAL}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'value', render: function (data) {
					return "Rp. "+formatToRupiah(data);
				} },
				{ data: 'description' },
				{ data: 'updated_at' },
				{ data: 'updated_by' }
			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
				{
					"targets": 5,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#modalRolesUser" id='edit' title='Edit'><i class="fas fa-edit"></i></button>`
				},
				]
		});


	},

	async _getSettingDataBiaya(){
		const formatToRupiah = (value) =>{
			return this.formatToRupiah(value)
		}
		$('#tableSettingBiaya tbody').on('click', '#edit', async function () {
			let table = await $('#tableSettingBiaya').DataTable()
			let data = table.row($(this).parents('tr')).data();
			console.log(data)
			$("#textKey").val(data.key);

			$("#textJudul").val(data.name);
			$("#textDescription").val(data.description);
			$("#textBiaya").val(formatToRupiah(data.value));
			
		});
	},


	 formatToRupiah(number) {
		const reverse = number.toString().split('').reverse().join('');
		let formattedValue = '';
		let count = 0;
	
		for (const char of reverse) {
		  if (count === 3) {
			formattedValue += '.';
			count = 0;
		  }
		  formattedValue += char;
		  count++;
		}
	
		return formattedValue.split('').reverse().join('');
  	},


	rupiahToNumber(rupiah) {
		return parseInt(rupiah.replace(/\D/g, ''), 10);
	},
}



export default GeneralSettingPPPresenter;