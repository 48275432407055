/* eslint-disable new-cap */
import CONFIG from '../config/globals/config.js';
import API_ENDPOINT from '../config/globals/endpoint.js';
import LOCK_ACCCESS_END_POINT from '../config/globals/lock_access_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class ApiLockAccess {

    static async LockAccessPD({
		province_id,
		is_locked,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");;

		var raw = JSON.stringify({
            "province_id": parseInt(province_id),
            "is_locked": is_locked
          });

		try {
			const fetchApi = await fetchWithTimeout(LOCK_ACCCESS_END_POINT.LOCK_PD, {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

    static async LockAccessPC({
		regency_id,
		is_locked,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "regency_id": parseInt(regency_id),
            "is_locked": is_locked
          });
          
		try {
			const fetchApi = await fetchWithTimeout(LOCK_ACCCESS_END_POINT.LOCK_PC, {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


}

export default ApiLockAccess;
