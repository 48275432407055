import ImagePopUPInitiator from "../../../presenter/pop-up-presenter.js";
import PermohonanSeminarPdPresenter from "../../../presenter/seminar/permohonan-seminar-presenter-pd.js";
import PDFHelper from "../../../utils/pdf-helpert.js";

const DataPermohonanSeminarPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row mt-3">
            <div class="col-sm-12">
                <div class="card mt-3">
                    <div class="card-header text-primary font-weight-bold">
                        Filter Data
                    </div>
                    <div class="card-body">
                        <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                            <div class="row">
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Kategori Seminar</label>
                                    <select id="selectKategori" class="form-control">
                                        <option value="">Semua</option>
                                        <option value="pendidikan">Pembelajaran</option>
                                        <option value="baksos">Pengabdian Masyarakat / RAKERCAB / RAKERDA</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Status Approve PD</label>
                                    <select id="selectStatus" class="form-control">
                                        <option value="pending">[Terbaru] Pending</option>
                                        <option value="">Semua</option>
                                        <option value="approve">Approve</option>
                                        <option value="revise">Revise</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Status Approve PP</label>
                                    <select id="selectStatusPP" class="form-control">
                                        <option value="">Semua</option>
                                        <option value="pending">Pending</option>
                                        <option value="approve">Approve</option>
                                        <option value="revise">Revise</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Status Pembayaran PD</label>
                                    <select id="selectStatusPembayaranPD" class="form-control">
                                        <option value="">Semua</option>
                                        <option value="confirm">Menunggu Konfirmasi</option>
                                        <option value="unpaid">Belum Bayar</option>
                                        <option value="paid">Approve [Lunas]</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Status Pembayaran PP</label>
                                    <select id="selectStatusPembayaranPP" class="form-control">
                                        <option value="">Semua</option>
                                        <option value="confirm">Menunggu Konfirmasi</option>
                                        <option value="unpaid">Belum Bayar</option>
                                        <option value="paid">Approve [Lunas]</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Status SK</label>
                                    <select id="selectStatusSK" class="form-control">
                                        <option value="">Semua</option>
                                        <option value="pending">Belum Ditandatangani</option>
                                        <option value="signed">Ditandatangani</option>
                                        <option value="unsigned">Menunggu Ditandatangani</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">Pilih Cabang</label>
                                    <select id="selectPC" class="form-control">
                                        <option value=''>Pilih Cabang</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col-sm-12 mb-2">
                            <button class="btn btn-primary" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                            <button class="btn btn-warning" id="btnResetFilter"><i class="fas fa-undo"></i> Reset Filter</button>
                            <button class="btn btn-info" id="btnRefreshData"><i class="fas fa-sync-alt"></i> Refresh Data</button>
                        </div>
                    </div>
                </div>
            
                <div class="card mt-3 shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
                        Data Permohonan Pengajuan Seminar SKP PD
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover" id="tablePengajuanSeminar" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th data-column="created_at">Tanggal Seminar</th>
                                        <th data-column="judul_seminar">Judul Seminar</th>
                                        <th data-column="penyelenggara">Jenis Penyelenggara</th>
                                        <th data-column="wilayah_penyelenggara">Wilayah</th>
                                        <th data-column="kategori_peserta">Kategori Peserta</th>
                                        <th data-column="kontribusi_peserta">Kontribusi Peserta</th>
                                        <th data-column="biaya_penerbitan_skp_pc">Biaya SKP PC</th>
                                        <th data-column="biaya_penerbitan_skp_pd">Biaya SKP PD</th>
                                        <th>Bukti Bayar (PC)</th>
                                        <th>Bukti Bayar (PD)</th>
                                        <th>Diajukan Oleh</th> 
                                        <th >SKP PD</th> 
                                        <th >SKP PP</th> 
                                        <th data-column="kategori_seminar">Kategori</th>
                                        <th>ACC PD</th>
                                        <th>ACC PP</th>
                                        <th>Dokumen</th>
                                        <th class="text-center">Status</th>
                                        <th width="10%">Opsi</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    
                                </tfoot>
                                <tbody>
                                
                                    <tr>
                                        <td><div class="shimmer content-line "></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line "></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                        <td><div class="shimmer content-line"></div></td>
                                    </tr>

                                    <tr>
                                    <td><div class="shimmer content-line "></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line "></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                    <td><div class="shimmer content-line"></div></td>
                                </tr>
                              

                                <tr>
                                <td><div class="shimmer content-line "></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line "></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                            </tr>
                          
                                  
                                
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalAccSeminar" tabindex="-1" role="dialog" aria-labelledby="modalAccSeminar"
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Acc Seminar & Berikan Rekomendasi SKP</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body" id="accSemminar">
                        <input type="hidden" id="textHiddenKategori" class="form-control"readonly>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">ID Seminar</label>
                            <div class="col-sm-9">
                                <input type="text" id="textIdSeminarAcc" class="form-control" placeholder="ID Seminar" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Judul Seminar</label>
                            <div class="col-sm-9">
                                <input type="text" id="textJudulSeminarAcc" readonly class="form-control" placeholder="Judul Seminar">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Kategori Seminar</label>
                            <div class="col-sm-9">
                                <input type="text" id="textKategoriSeminarAcc" class="form-control" placeholder="" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Kategori Peserta</label>
                            <div class="col-sm-9">
                                <input type="text" id="textPesertaSeminarAcc" class="form-control" placeholder="" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right" id="skp_peserta_title">SKP Peserta</label>
                            <div class="col-sm-9">
                                <input type="number" step="0.01" id="textSKPPeserta" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="form-group row" id="container_skp_online">
                            <label class="control-label col-sm-3 font-weight-bold text-right">SKP Peserta Online</label>
                            <div class="col-sm-9">
                                <input type="number" step="0.01" id="textSKPPesertaOnline" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">SKP Moderator</label>
                            <div class="col-sm-9">
                                <input type="number" step="0.01" id="textSKPModerator" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">SKP Panitia</label>
                            <div class="col-sm-9">
                                <input type="number" step="0.01" id="textSKPPanitia" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">SKP Narasumber</label>
                            <div class="col-sm-9">
                                <input type="number" step="0.01" id="textSKPNarasumber" class="form-control" placeholder="">
                            </div>
                        </div>

                        <div class="form-group row d-none" id="container-sk-pd">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Nomor SK PD</label>
                            <div class="col-sm-9">
                                <input type="text" id="textNomorSK" class="form-control" placeholder="">
                            </div>
                            <label class="control-label col-sm-3 mt-3 font-weight-bold text-right">Biaya Penerbitan Seminar SKP</label>
                            <div class="col-sm-9 mt-3">
                                <input type="text" id="numberBiayaPenerbitan" readonly value="0" class="form-control" placeholder="">
                            </div>
                            <label class="control-label col-sm-3 mt-2 font-weight-bold text-right">Menimbang</label>
                            <div class="col-sm-9 mt-2">
                                <textarea class="form-control" id="textMenimbang"></textarea>
                            </div>
                            <label class="control-label col-sm-3 mt-2 font-weight-bold text-right">Mengingat</label>
                            <div class="col-sm-9 mt-2">
                                <textarea class="form-control" id="textMengingat"></textarea>
                            </div>
                        </div>
                        <div class="form-group row" id="container-surat-pd">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Nomor Surat Rekomendasi SKP</label>
                            <div class="col-sm-9">
                                <input type="text" id="textNomorSuratRekom" class="form-control" placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" id="btnAccSeminar">Setujui Seminar</button>
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="modalViewDetail" tabindex="-1" role="dialog" aria-labelledby="modalViewDetail"
        aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Detail Seminar</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body col-sm-12">
                        <table class="table table-bordered" style="width:100%">
                            <tr>
                                <td valign="top" width="25%">Judul Seminar</td>
                                <td valign="top" width="1%">:</td>
                                <th valign="top" id="judulSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Kategori Seminar</td>
                                <td valign="top">:</td>
                                <th valign="top" id="kategoriSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Jumlah SKP</td>
                                <td valign="top">:</td>
                                <th valign="top" id="jumlahSKPDet">
                                    <table width="100%">
                                        <tr  id="tr_peserta_seminar_online">
                                            <td width="25%">Peserta Seminar Online</td>
                                            <td width="1%">:</td>
                                            <td id="skpPesertaOnline"></td>
                                        </tr>
                                        <tr>
                                            <td id="peserta_seminar_offline" width="25%">Peserta</td>
                                            <td width="1%">:</td>
                                            <td id="skpPeserta"></td>
                                        </tr>
                                        <tr>
                                            <td>Moderator</td>
                                            <td>:</td>
                                            <td id="skpModerator"></td>
                                        </tr>
                                        <tr>
                                            <td>Narasumber / Pembicara</td>
                                            <td>:</td>
                                            <td id="skpNarasumber"></td>
                                        </tr>
                                        <tr>
                                            <td>Panitia</td>
                                            <td>:</td>
                                            <td id="skpPanitia"></td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Ketua Pelaksana</td>
                                <td valign="top">:</td>
                                <th valign="top" id="ketuaSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top">Sekretaris</td>
                                <td valign="top">:</td>
                                <th valign="top" id="sekretarisSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top">Tanggal Pelaksanaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutTime"></th>
                            </tr>
                            <tr>
                                <td valign="top">Kepesertaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutPeserta"></th>
                            </tr>
                        
                        </table>
                        <input type="hidden" id="stateIdSeminarDetail" class="form-control">
                        <div class="form-group row shadow-lg rounded" id="formExternalDetail" style="margin:20px; padding:20px;">
                        <label class="control-label col-sm-12 font-weight-bold text-primary">Data Surat External</label>
                            <div class="col-sm-12">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Nomor Surat</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="textNomorSuratDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Tanggal Surat</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="tanggalSuratExternalDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-sm-12" style="margin-top:10px">
                                <div class="row">
                                <label class="control-label col-sm-12 font-weight-bold">Surat Dari Team External</label>
                                </div>
                                <button id="btnSuratDetPDF"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                        <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                        <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                    </svg> Buka File PDF</button>
                                </th>
                            </div>

                            <div class="col-sm-12" style="margin-top:10px">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Keterangan</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="textKeteranganDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="font-weight-bold text-primary">Materi Seminar</h6>
                        <div class="row">
                            <div class="col-sm-12 overflow-auto">
                                <table class="table table-bordered" style="width:100%" id="tableMateri">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Judul Materi</th>
                                            <th>Moderator</th>
                                            <th>Pemateri</th>
                                            <th>Tanggal Seminar</th>
                                            <th>Tempat Penyelenggaraan</th>
                                            <th>Author</th>
                                            <th>Dari Jam</th>
                                            <th>Sampai Jam</th>
                                            <th>Lampiran</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody id="contentDet">

                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                        <table class="table table-bordered mt-3" style="width:100%">
                        <tr>
                            <th class="text-center" width="30%">Proposal</th>
                            <th class="text-center" width="30%">Surat Pengantar</th>
                            <th class="text-center" width="35%">Brosur</th>
                            <th class="text-center" width="35%">Background Sertifikat</th>
                        </tr>
                        <tr>
                        <th class="text-center" width="30%">
                        <button id="btnProposalPdfDetail"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg> Buka File PDF</button>
                        </th>
                        <th class="text-center" width="30%">
                        <button id="btnSuratPengantarPdfDetail"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg> Buka File PDF</button>
                        </th>
                        <th class="text-center" width="35%">
                            <img loading="lazy" id="brosurDetailImage" src="" alt="Brosur Seminar" width="35%">
                        </th>
                        <th class="text-center" width="35%">
                        <img loading="lazy" id="backgroundSertifikatDetail" src="" alt="Background sertifikat Seminar" width="35%">
                        </th>
                        </tr>
                    </table>
                        <div class="row" style="width:100%">
                            <label class="control-label col-sm-12 font-weight-bold">Deskripsi Kegiatan</label>
                            <div class="col-sm-12" >
                                <textarea rows="5" style="width:100%" id="textDeskripsiDetail" placeholder="Deskripsi Kegiatan..." name="deskripsi"></textarea>
                            </div>
                        </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
            </div>
        </div>

<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Revisi & Berikan Komentar</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                <h6 class="m-0 font-weight-bold text-primary">Komentar Permohonan Seminar</h6>
                            </div>
                            <div class="card-body chat-body p-1" id="lamanChat">
                                <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg" id="modalUploadBuktiBayarSeminar" tabindex="-1" role="dialog" aria-labelledby="modalLaporanSeminar"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary font-weight-bold">Kirim Bukti Bayar Seminar SKP</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mt-3" style="widht=100%">
                     <input type="hidden" id="textIdSeminarBuktiBayar" style=""/>
                        <div class="col-lg-12 ">
                        <div class="alert alert-info">
                            <h5 class="font-weight-bold">Informasi Rekening : </h5>
                            <div id="informationRekening"></div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-12 font-weight-bold">Upload Bukti Bayar SKP Seminar</label>
                            <div class="card ml-2 mb-2 border-left-primary col-sm-11 " id="containerBuktiBayarSeminar">
                                            <div class="mt-2 mb-2">
                                            <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_bukti_bayar" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="120px">

                                                <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah bukti bayar .</h6>
                                            </div>
                            </div>
                            <div id="dropzone-bukti-bayar-seminar" class="col-sm-11 dropzone" style="margin-top:18px;">
                                <input type="hidden" name="file" style=""/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <button type="submit" class="form-control text-uppercase btn btn-primary" id="btnSaveBuktiBayarSeminar"><i class="fas fa-save"></i> Kirim Bukti Bayar SKP Seminar</button>
                            </div>
                        </div>
                    </div>
                 </form>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commentModalPayment" tabindex="-1" role="dialog" aria-labelledby="commentModalPayment"
aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Revisi & Berikan Komentar</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                <h6 class="m-0 font-weight-bold text-primary">Komentar Permohonan Seminar</h6>
                            </div>
                            <div class="card-body chat-body p-1" id="lamanChat">
                                <div class="chat-body" id="content-comment-payment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuanPayment" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPayment"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

    <div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
        
        `;
		return view;
	  },

	async afterRender() {
		PermohonanSeminarPdPresenter.init()
        await  ImagePopUPInitiator.init();
        PDFHelper.initiator()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default DataPermohonanSeminarPD;