/* eslint-disable new-cap */
import API_ENDPOINT from '../config/globals/endpoint.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class ApiRegister {

    static async getProvince() {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.PROVINCE, {
				method: 'GET',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
    static async getProvinceKemendagri() {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.PROVINCE_KEMENDAGRI, {
				method: 'GET',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async sendBuktiBayarRegistrasi({
		email,
		buktiBayarRegis
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("email", email);
		if (buktiBayarRegis != null) {
			formdata.append("bukti_bayar_registrasi", buktiBayarRegis);
		}


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.SEND_BUKTI_BAYAR_REGISTRASI, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


    static async getRegistry(provinceID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (provinceID == null || provinceID == '') {
				return {};
			}else{
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.REGENCY(provinceID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
		
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
    static async getRegistryKemendagri(provinceID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (provinceID == null || provinceID == '') {
				return {};
			}else{
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.REGENCY_KEMENDAGRI(provinceID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
		
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getSubRegency(regencyID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (regencyID == null || regencyID == '') {
				return {};
			}else{
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.SUBREGENCY(regencyID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
			
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getSubRegencyKemendagri(regencyID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (regencyID == null || regencyID == '') {
				return {};
			}else{
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.SUBREGENCY_KEMENDAGRI(regencyID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
			
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getVillage(subRegencyID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (subRegencyID == null || subRegencyID == '') {
				return {};
			}else {
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.VILLAGE(subRegencyID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
			
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getVillageKemendagri(subRegencyID) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'GET', 'OPTIONS');
		try {
			if (subRegencyID == null || subRegencyID == '') {
				return {};
			}else {
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.VILLAGE_KEMENDAGRI(subRegencyID), {
					method: 'GET',
					headers: myHeaders,
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}
			
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async Register({
		pd,
		pc,
		nama_lengkap,
		nik,
		no_hp,
		email,
		password,
		confirm_password,
		jenis_kelamin,
		tanggal_lahir
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"user_pc_aktif_id": `${pc}`,
			"name": `${nama_lengkap}`,
			"nik": `${nik}`,
			"phone_number": `${no_hp}`,
			"email": `${email}`,
			"password": `${password}`,
			"password_confirmation": `${confirm_password}`,
			"jenis_kelamin": `${jenis_kelamin}`,
			"tanggal_lahir": `${tanggal_lahir}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_USER, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async RegisterOld({
		pd,
		pc,
		nama_lengkap,
		ktan,
		nik,
		no_hp,
		email,
		password,
		confirm_password,
		jenis_kelamin,
		tanggal_lahir,
		old_ktan,
		ktan_expires_at,
		bulan_iuran_awal,
		bulan_iuran_akhir,
		bukti_iuran_terakhir,
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("user_pc_aktif_id", pc);
		formdata.append("name", nama_lengkap);
		formdata.append("ktan", ktan);
		formdata.append("nik", nik);
		formdata.append("phone_number", no_hp);
		formdata.append("email", email);
		formdata.append("password", password);
		formdata.append("password_confirmation", confirm_password);
		formdata.append("jenis_kelamin", jenis_kelamin);
		formdata.append("tanggal_lahir", tanggal_lahir);
		formdata.append("old_ktan", old_ktan);
		formdata.append("ktan_expires_at", ktan_expires_at);
		formdata.append("bulan_iuran_awal", bulan_iuran_awal);
		formdata.append("bulan_iuran_akhir", bulan_iuran_akhir);
		formdata.append("bukti_iuran_terakhir", bukti_iuran_terakhir);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_USER_OLD, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
}

export default ApiRegister;
