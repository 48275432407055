import RekomSTRTTKPresenter from "../../../presenter/menu_anggota/rekom-strttk-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const RekomSTRTTK = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		
		<div class="alert alert-info alert-dismissible">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<h3 style="padding:0; margin:0;"><strong>Informasi!</strong></h3><br>
			1. Isi formulir secara lengkap.<br>
			2. Setelah anda berhasil melakukan pengajuan lihat pada data pengajuan dan tombol detail apakah pengajuan sudah sesuai atau tidak.<br>
			3. Jika terdapat persyaratan yang harus di upload maka segera upload persyaratan pada tombol detail pengajuan.<br>
			4. Anda dapat berkomunikasi dengan Admin PC pada laman komentar yang terdapat pada tombol detail pengajuan.<br>
			5. Jika anda memiliki pertanyaan anda dapat menghubungi Helpdesk SiPAFI via WhatsApp berikut : <strong><a href="https://web.whatsapp.com/send?phone=6282220467605"><i class="fab fa-whatsapp"></i> Helpdesk SiPAFI</a></strong>
		</div>
		<div class="card shadow m-0 p-0">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataUmum" class="nav-link hapus-border active" id="data-umum-tab" data-bs-toggle="tab" data-bs-target="#tabDataUmum" data-toggle="tab" type="button" role="tab" aria-controls="tabDataUmum" aria-selected="true">Formulir Pengajuan</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Data Pengajuan</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataUmum" role="tabpanel" aria-labelledby="data-umum-tab">
						<div class="row mt-3">
							<div class="col-lg-12 col-xl-12">
								<div class="card">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Informasi</h6>
									</div>

									<div class="card-body" id="information_strttk">
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12">
									
										<div class="card mt-3">
											<div class="card-header font-weight-bold">
												Download Format Dokumen
											</div>
											<div class="card-body">
												<div class="row">

												
													<div class="col-sm-6 border p-3 text-center">
														PERMOHONAN REKOMENDASI E-STRTTK
														<br>
														<br>
														<button class="btn btn-primary form-control btn-download" value="surat_permohonan_pc"><i class="fas fa-download"></i> DOWNLOAD</button>
													</div>
													<div class="col-sm-6 border p-3 text-center">
														SURAT PERNYATAAN PATUH KODE ETIK PROFESI
														<br>
														<br>
														<button class="btn btn-primary form-control btn-download" value="surat_pernyataan_patuh_kode_etik"><i class="fas fa-download"></i> DOWNLOAD</button>
													</div>
													

													<div class="col-sm-6 border p-3 text-center">
														SURAT PERNYATAAN KEABSAHAN DOKUMEN
														<br>
														<br>
														<button class="btn btn-primary form-control btn-download" value="surat_pernyataan_keabsahan_dokumen"><i class="fas fa-download"></i> DOWNLOAD</button>
													</div>

													<div class="col-sm-6 border p-3 text-center">
														NASKAH SURAT SUMPAH
														<br>
														<br>
														<button class="btn btn-primary form-control btn-download" value="surat_pernyataan_sumpah"><i class="fas fa-download"></i> DOWNLOAD</button>
													</div>
												</div>
											</div>
										</div>
										<div class="card mt-3">
											<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
												<h6 class="m-0 font-weight-bold text-primary">Formulir Pengajuan</h6>
											</div>
											<form id="form-data-strttk">
											<div class="card-body ">
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Nama Lengkap</label>
														<div class="col-sm-12">
															<input type="text" name="textNamaLengkap" placeholder="Nama" class="form-control" required id="textNamaLengkap" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Nomor STRTTK</label>
														<div class="col-sm-12">
															<input type="text" name="textNomorSTRTTK" placeholder="Nomor STRTTK" class="form-control" id="textNomorSTRTTK" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Perpanjang Dengan</label>
														<div class="col-sm-12">
															<input type="text" name="textPerpanjangDengan" placeholder="Perpanjang Dengan" class="form-control" id="textPerpanjangDengan" readonly>
														</div>
													</div>
													<div class="row">
														<div class="col-sm-6">
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Lulusan</label>
																<div class="col-sm-12">
																	<select class="form-control" id="selectPendidikan">
																		<option value="">Pilih Salah Satu</option>
																	</select>
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Institusi Pendidikan</label>
																<div class="col-sm-12">
																	<input type="text" name="nama_lengkap" placeholder="Institusi Pendidikan..." class="form-control" required readonly id="InstutisiText">
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Jenjang</label>
																<div class="col-sm-12">
																	<input type="text" name="nama_lengkap" placeholder="Jenjang" class="form-control" required id="textJenjang" readonly>
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Tahun</label>
																<div class="col-sm-12">
																	<input type="text" name="nama_lengkap" placeholder="Tahun" class="form-control" required id="textTahun" readonly>
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">No Ijazah</label>
																<div class="col-sm-12">
																	<input type="text" name="nama_lengkap" placeholder="No ijazah" class="form-control" required id="textNoIjazah" readonly>
																</div>
															</div>
														</div>
														<div class="col-sm-6">
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Nama Sarana</label>
																<div class="col-sm-12">
																	<select class="form-control" id="selectSarana" name="sarana" required>
																		<option value="">Pilih Salah Satu</option>
																	</select>
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Jenis Tempat Kerja</label>
																<div class="col-sm-12">
																	<input type="text" name="tempat_kerja" placeholder="Jenis Tempat Kerja" class="form-control" readonly required id="textJenisTempatKerja">
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Nama Tempat Kerja</label>
																<div class="col-sm-12">
																	<input type="text" name="tempat_kerja" placeholder="Nama Tempat Kerja" class="form-control" readonly required id="textNamaTempatKerja">
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Dari Tahun</label>
																<div class="col-sm-12">
																	<input type="text" name="tempat_kerja" placeholder="Dari Tahun" class="form-control" readonly required id="TextDariTahun">
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Alamat Tempat Kerja</label>
																<div class="col-sm-12">
																	<input type="text" name="tempat_kerja" placeholder="Alamat Tempat Kerja" class="form-control" readonly required id="textAlamatTempatKerja">
																</div>
															</div>
															<div class="form-group row">
																<label class="control-label col-sm-12 font-weight-bold">Jabatan  Kerja</label>
																<div class="col-sm-12">
																	<input type="text" name="tempat_kerja" placeholder="Jabatan Kerja" class="form-control" readonly required id="textJabatanKerja">
																</div>
															</div>
														</div>
													</div>
											</div>
										</div>

										<div class="card mt-3">
											<div class="card-header ">
												Upload Persyaratan Wajib
											</div>
											<div class="card-body ">
												<div class="row">
													<div class="col-sm-6">
														<label class="control-label font-weight-bold">Surat Permohonan Rekomendasi E-STRTTK Kepada Ketua PC</label>
														<div id="form-upload-permohonan-ketua-pc" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>

														<label class="control-label font-weight-bold">Surat Pernyataan Patuh Kode Etik Profesi</label>
														<div id="form-upload-kode-etik" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>

														<label class="control-label font-weight-bold">Surat Sumpah</label>
														<div id="form-upload-surat-sumpah" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
													</div>

													<div class="col-sm-6">
														<label class="control-label font-weight-bold">Surat Keterangan Telah Melakukan Praktik</label>
														<div id="form-upload-keterangan-praktik" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>

														<label class="control-label font-weight-bold">Surat Pernyataan Keabsahan Dokumen</label>
														<div id="form-upload-keabsahan-dokumen" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
														<div class="form-group row">
															<div class="col-sm-12 text-right">
																<button class="btn btn-sm btn-primary" id="btnKirimPengajuan"><i class="fas fa-share"></i> Kirim Pengajuan</button>
																<button type="button" class="btn btn-sm btn-secondary d-none" id="btnClearPengajuanSTRTTK" ><i class='fas fa-eraser'></i> Kosongkan</button>
															</div>
														</div>
													</div>	
												</div>
																				
											</div>
										</div>
										
										</form>
									</div>
								</div>
								
								
							</div>
						</div>
					</div>

					
					<div class="tab-pane fade" id="tabProfilPendidikan" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pengajuan Rekomendasi Registrasi Ulang STRTTK
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tablePengajuanSTRTTK" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th rowspan="2">Tanggal Pengajuan</th>
												<th rowspan="2">Nama Anggota</th>
												<th rowspan="2">Jenis Permohonan</th>
												<th colspan="2" align="center">Berkas Data Umum</th>
												<th colspan="8" align="center">Persyaratan Wajib</th>
												<th rowspan="2">Status PC</th>
												<th rowspan="2">Status PD</th>
												<th rowspan="2">Aksi</th>
											</tr>
											<tr>
												<th>KTAN</th>
												<th>KTP</th>
												<th>IJAZAH</th>
												<th>SERKOM</th>
												<th>STRTTK</th>
												<th>SURAT PERMOHONAN REKOMENDASI</th>
												<th>SURAT KETERANGAN PRAKTIK</th>
												<th>SURAT KEABSAHAN DOKUMEN</th>
												<th>SURAT PATUH KODE ETIK</th>
												<th>SURAT SUMPAH</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
<div class="modal fade" id="detailSTRTTKModal" tabindex="-1" role="dialog" aria-labelledby="detailSTRTTKModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="">Detail Pengajuan Rekomendasi Registrasi Ulang STRTTK</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" id="bodyModalSTRTTK">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-12">
									<input type="hidden" id="textHIddenSTRTTK" class="form-control">
                                    <label class="label control-label">Tanggal Pengajuan</label>
                                    <input type="text" id="tanggalPengajuan" class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">KTAN</label>
                                    <input type="text" id="textKTAN" placeholder="KTAN..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Nama Anggota</label>
                                    <input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Email</label>
                                    <input type="text" id="textEmail" placeholder="Email..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Jenis Permohonan</label>
                                    <input type="text" id="textJenisPermohonan" placeholder="Jenis Permohonan..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Di Perpanjang Dengan</label>
                                    <input type="text" id="textPerpanjangDengan" placeholder="Di Perpanjang Dengan..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Status</label>
                                    <input type="text" id="textStatus" placeholder="Status..." class="form-control" readonly>
                                </div>
                                
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data Pendidikan</label>
                                <div class="col-sm-12 overflow-auto">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Tahun Lulus</th>
                                            <th>Jenjang</th>
                                            <th>Nama Institusi</th>
                                            <th>Tanggal Ijazah</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="dataPendidikanSTRTTK">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data Pekerjaan</label>
                                <div class="col-sm-12 overflow-auto">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Nama Instansi</th>
                                            <th>Alamat</th>
                                            <th>Telp/Fax</th>
                                            <th>No HP</th>
                                            <th>Email</th>
                                            <th>Jabatan</th>
                                            <th>Jenis Tempat Kerja</th>
                                            <th>Dari Tahun</th>
                                            <th>Sampai Tahun</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="dataPekerjaan">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
							<div class="row mt-2">
								<div class="col-sm-12">
									<div class="card">
										<div class="card-header text-primary font-weight-bold">
											Riwayat Verifikasi STRTTK
										</div>
										<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
												
											<div class="table-responsive">
												<table class="table table-bordered table-hover" id="tableHistoryVerifikasiSTRTTK" width="100%" cellspacing="0">
													<thead>
														<tr>
															<th>No</th>
															<th>Tanggal</th>
															<th>Nama</th>
															<th>Hak</th>
															<th>Keterangan</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
													</tbody>
													<tfoot>
														
													</tfoot>
													<tbody>
													
													
													
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
                        </div>
                        <div class="col-sm-6">
							<div class="card mb-2">
								<div class="card-header text-primary font-weight-bold">
									<strong>Upload Persyaratan Tambahan</strong>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectBerkas">
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>
											<div class="text-center d-none" id="containerAlertBerkas">
												<p>Deskripsi Persyaratan</p>
												<div class="alert alert-primary" role="alert" id="valueAlertBerkas">
													
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Upload Berkas</label>
											</div>
											<div id="form-upload-strttk" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div>
											<div class="form-group row">
												<div class="col-sm-12">
													<button class="mb-2 btn btn-sm btn-primary" id="btnUpdateBerkas" style="width:100%"><i class='fas fa-edit'></i> Kirim & Update Berkas</button>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Persyaratan</label>
                                <div class="col-sm-12">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Nama Persyaratan</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="persyaratanSTRTTK">
        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                            <h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
                                        </div>
                                        <div class="card-body chat-body p-1" id="lamanChat">
                                            <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
												<div style="width:100%">
													<div style="width:10%;" class="float-left">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-right">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-left">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-right">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <form id="form-comment">
                                                <label class="control-label font-weight-bold">Komentar</label>
                                                <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                                <br>
                                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
		

	<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
		`;
		return view;
	  },

	async afterRender() {
		await RekomSTRTTKPresenter.init();
		await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default RekomSTRTTK;