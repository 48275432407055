import SetAksesPresenterPD from "../../../presenter/setting_akses/setting-akses-presenter-pd.js";

const SettingAksesPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border active" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Setting Akses PC</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active " id="tabProfilPendidikan"  role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
							Setting Akses PC
							</div>
							<div class="card-body ">
								<div class="table-responsive">
                                <div class="form-group row col-sm-6">
										<label class="control-label col-sm-6 font-weight-bold">PD ANDA : </label>
										<select class="form-control" readonly style="margin-left:10px;" id="selectProvinsiTujuan" name="pd_tujuan" required>
											
										</select>
									</div>
									<table class="table table-bordered table-hover" id="tableDataAksesPC" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th >No</th>
                                                <th >ID PC</th>
												<th >Nama PC</th>
												<th >Status PC</th>
												<th >Aksi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line end"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer square"></div></td>
											</tr>
                                            <tr>
                                            <td><div class="shimmer title-line end"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer square"></div></td>
                                        </tr>
                                    	</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>


	</div>
		
		
		
		
		`;
		return view;
	  },


	async afterRender() {
        await SetAksesPresenterPD.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SettingAksesPD;