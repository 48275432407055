
import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import NotificationModal from "../../utils/initial_notification.js";
import API_ENDPOINT_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import CONFIG from '../../config/globals/config.js';
import ApiPengajuanKTANPC from '../../api/api_pengurus_pc/pengajuan-ktan.js';
import ApiRegister from "../../api/data-register.js";

const AssignRolePresenter = {

	async init() {
		await this._setView()
		await this.initProvinceValue();
		await this.renderGetUserByKtan();
		await this.renderRole();
		await this.initSubmitRole();
		await this._getRole();
		await this._getRoleByID();
		
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		const formattedNumberInput = document.getElementById('tvKtan');
		const blocks = [4, 8, 1, 6]; // Jumlah digit pada setiap blok
		const totalLength = blocks.reduce((total, num) => total + num, 0);
	  
		formattedNumberInput.addEventListener('input', (event) => {
		  const inputValue = event.target.value;
		  const validInput = inputValue.replace(/\D/g, ''); // Hapus karakter non-digit
	  
		  // Potong input jika panjang melebihi total panjang blok
		  const formattedValue = validInput.slice(0, totalLength);
	  
		  let formattedResult = '';
		  let index = 0;
	  
		  for (const block of blocks) {
			const chunk = formattedValue.slice(index, index + block);
			formattedResult += chunk;
	  
			if (formattedValue.length > index + block) {
			  formattedResult += '.';
			}
	  
			index += block;
		  }
	  
		  event.target.value = formattedResult;
	  
		  // Nonaktifkan input jika format sudah mencapai panjang yang diinginkan
		  if (formattedValue.length >= totalLength) {
			event.preventDefault();
		  }
		});

	},

    async renderRole() {
		let response = await ApiRole.getAllRole()
		if(response.status_code == 200){
			response.data.forEach(data => {
				document.getElementById("selectRoleUser").add(new Option(data.name , data.id))
				document.getElementById("selectJenisKeanggotaan").add(new Option(data.name , data.level_name))
			});	
		}
	},

    async renderGetUserByKtan() {
		const eventGetUser = async () => {
			let ktanValue = document.getElementById("tvKtan").value;
			let response = await ApiRole.getUserByKTAN(ktanValue);
			if (ktanValue == '') {
				$("#textUserName").attr('placeholder','Nama Lengkap')
				$("#textEmail").attr('placeholder','Email')
				document.getElementById("userId").value = ""
				$("#textUserName").removeClass('is-invalid')
				$("#textEmail").removeClass('is-invalid')
				$("#textUserName").removeClass('is-valid')
				$("#textEmail").removeClass('is-valid')
				document.getElementById("textUserName").value = "";
				document.getElementById("textEmail").value = "";
			}else {
				$("#textUserName").attr('placeholder','Loading...')
				$("#textEmail").attr('placeholder','Loading...')
				if (response.status_code == 200) {
					document.getElementById("userId").value = response.data.uuid;
					document.getElementById("textUserName").value = response.data.name;
					document.getElementById("textEmail").value = response.data.email;
					$("#textUserName").removeClass('is-invalid')
					$("#textEmail").removeClass('is-invalid')
					$("#textUserName").addClass('is-valid')
					$("#textEmail").addClass('is-valid')
				}else{
					$("#textUserName").attr('placeholder','User Tidak Ditemukan')
					$("#textEmail").attr('placeholder','User Tidak Ditemukan')
					$("#textUserName").removeClass('is-valid')
					$("#textEmail").removeClass('is-valid')
					$("#textUserName").addClass('is-invalid')
					$("#textEmail").addClass('is-invalid')
					document.getElementById("userId").value = ""
					document.getElementById("textUserName").value = "";
					document.getElementById("textEmail").value = "";
				}
			}
		}
		document.getElementById("tvKtan").addEventListener("keyup",eventGetUser)
	},

	async initSubmitRole() {
		const eventSubmit = async (e) => {
			e.preventDefault();
			let name = document.getElementById("textUserName").value;
			let role = $("#selectRoleUser option:selected" ).text();
			swal.fire({
				title: 'Simpan',
				html: `Anda ingin menjadikan ${name} menjadi role ${role} ?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Simpan!'
			}).then(async (result) => {
				if (result.value) {
					let uuIdRole = document.getElementById("selectRoleUser").value;
					let uuIdUser = document.getElementById("userId").value;
					let response = await ApiRole.assignRole(uuIdUser,uuIdRole);
					if (response.status_code == 200) {
						NotificationModal.show(`${response.message}`, 'success');
						document.getElementById("form-assign-role").reset();
						$('#tableRoleUser').DataTable().ajax.reload();
						$("#textUserName").removeClass('is-invalid')
						$("#textEmail").removeClass('is-invalid')
						$("#textUserName").removeClass('is-valid')
						$("#textEmail").removeClass('is-valid')
						$("#textUserName").attr('placeholder','Nama Lengkap...')
						$("#textEmail").attr('placeholder','Email...')
					}else{
						NotificationModal.show(`${response.message}`, 'warning');
						$('#tableRoleUser').DataTable().ajax.reload();
						$("#textUserName").removeClass('is-invalid')
						$("#textEmail").removeClass('is-invalid')
						$("#textUserName").removeClass('is-valid')
						$("#textEmail").removeClass('is-valid')
						$("#textUserName").attr('placeholder','Nama Lengkap...')
						$("#textEmail").attr('placeholder','Email...')
					}
				};
			});
		}
		document.getElementById("form-assign-role").addEventListener("submit",eventSubmit)
	},

	async _getRoleByID(){
		let idUser = '';
		let userName = ''
		$('#tableRoleUser tbody').on('click', '#detail', async function () {
			let table = await $('#tableRoleUser').DataTable()
			let data = table.row($(this).parents('tr')).data();
			idUser = data.uuid;
			userName = data.name;
			let roles = data.role_name;
			$("#namaAnggota").html(data.name);
			$("#emailAnggota").html(data.email);
			$("#ktan").html(data.ktan);
			$("#pdAnggota").html(data.daerah_name);
			$("#pcAnggota").html(data.cabang_name);
			// Perintah untuk melakukan full reset pada DataTable
			let dataTable = await $('#tableRoles').DataTable();
			dataTable.clear().destroy();
			const dataArray = Object.values(roles);
			let tableRoles = await $('#tableRoles').DataTable({
				data: dataArray,
				columns: [
					{ data: 'name' },
				],
				"columnDefs": [
				{
					"targets": 1,
					"data": null,
					"className":'col-sm-2',
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-danger btn-sm text-center' id='deleteRoles' title='Hapus Role'><i class="fas fa-trash"></i></button>`
				},
				]
			})

			
			$('#tableRoles tbody').off('click','#deleteRoles').on('click', '#deleteRoles', async function () {
				let dataRoles = tableRoles.row($(this).parents('tr')).data();
				swal.fire({
					title: 'Hapus',
					html: "Anda ingin menghapus data ini? <br> <strong>Name Role </strong>: " + dataRoles.name + "<br> <strong>Nama User </strong>: "  + userName,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Hapus!'
				}).then(async (result) => {
					if (result.value) {
						let idRole = dataRoles.id;
	
						const removeRoleUsers = await ApiRole.removeRole({
							idUser: idUser,
							idRole: idRole
						});
	
						if (removeRoleUsers.status_code == '200') {
							swal.fire({
								title: 'Hapus',
								text: removeRoleUsers.message,
								icon: 'success',
								showConfirmButton: false,
								timer: 1000
							});
							//refres manual 
							$('#tableRoleUser').DataTable().ajax.reload();
							$('#modalRolesUser').modal('hide');
						}else {
							swal.fire({
								title: 'Error',
								text: removeRoleUsers.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 1000
							});
						}
					};
				});
			})

			
		});
	},

	
	async initProvinceValue(){
		const dataProvince = await ApiRegister.getProvince()
		if(dataProvince.status_code == 200){
			dataProvince.data.forEach(data => {
				document.getElementById("selectPengajuanPD").add(new Option(data.province_name , data.province_code))
			});	
		}
		

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("selectPengajuanPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)
			
			var selectPC = document.getElementById("selectPengajuanPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc-1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang","")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectPengajuanPC").add(new Option(data.regency_name , data.id))
			});	

			e.preventDefault();
		}

		document.getElementById("selectPengajuanPD").addEventListener("change", eventChange)

	},

	async _getRole(){
		let roleName = '';
		let IDCabang = '';
		let IDDaerah = '';
		$("#btnFilter").on("click", async function () {
			roleName = $("#selectJenisKeanggotaan").val();
			IDCabang = $("#selectPengajuanPC").val();
			IDDaerah = $("#selectPengajuanPD").val();

			
		let table = await $('#tableRoleUser').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			"scrollX": true,
			
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PP.ROLE_USERS_DET(roleName,IDCabang,IDDaerah)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'ktan' },
				{ data: 'jenis_kelamin' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{ data: 'phone_number' },
				{ data: 'cabang_name' },
				{ data: 'daerah_name' },
				{ data: 'avatar', 
					render : function(data){
						if (data == null || data == '') {
							return '-'
						}else {
							return '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">'
						}
					
				} },
				{ data: 'role_name', render : function (data) {
					var content = ``;
					for(var k in data) {
						content += `<li>${data[k].name}</li>`
					 }
					return content;
				} },
			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
				{
					"targets": 9,
					"visible": true,
					"searchable": false,
					"className":"text-center"
				},
				{
					"targets": 11,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#modalRolesUser" id='detail' title='Detail'><i class="fas fa-list"></i></button>`
				},
				]
		});


		})
		let table = await $('#tableRoleUser').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			"scrollX": true,
			
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PP.ROLE_USERS_DET(roleName,IDCabang,IDDaerah)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'email' },
				{ data: 'ktan' },
				{ data: 'jenis_kelamin' },
				{ data: 'tempat_lahir' },
				{ data: 'tanggal_lahir' },
				{ data: 'phone_number' },
				{ data: 'cabang_name' },
				{ data: 'daerah_name' },
				{ data: 'avatar', 
					render : function(data){
						if (data == null || data == '') {
							return '-'
						}else {
							return '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">'
						}
					
				} },
				{ data: 'role_name', render : function (data) {
					var content = ``;
					for(var k in data) {
						content += `<li>${data[k].name}</li>`
					 }
					return content;
				} },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 9,
					"visible": true,
					"searchable": false,
					"className":"text-center"
				},
				{
					"targets": 11,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#modalRolesUser" id='detail' title='Detail'><i class="fas fa-list"></i></button>`
				},
				]
		});

	}
}

export default AssignRolePresenter;