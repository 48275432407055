import PeriodeKepengurusanPCPresenter from "../../../presenter/menu_pengurus_pc/periode-kepengurusan-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const PeriodeKepengurusanPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
	<div class="row">
		<div class="col-sm-4">
			<div class="card mt-3">
				<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
					Formulir Input Kepengurusan
				</div>
				<div class="card-body ">
					<form id="form-periode" enctype="multipart/form-data">
						<div class="row">
							<div class="col-lg-12">
								<div class="form-group row">
									<input type="hidden" id="textHiddenPeriode">
									<label class="control-label col-sm-12 font-weight-bold">Masa Bakti</label>
									<div class="col-sm-12">
										<input type="text" name="nomor_periode" placeholder="Masa Bakti..." class="form-control" required id="textPeriode">
									</div>
								</div>
								<div class="form-group row">
									<label class="control-label col-sm-12 font-weight-bold">Keterangan</label>
									<div class="col-sm-12">
										<input type="text" name="keterangan" placeholder="Keterangan..." class="form-control" required id="textKeterangan">
									</div>
								</div>
								<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddPeriode"><i class='fas fa-plus'></i> Tambah Masa Bakti</button>
								<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdatePeriode"><i class='fas fa-edit'></i> Edit Masa Bakti</button>
								<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearPeriode"><i class='fas fa-eraser'></i> Batal</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-sm-8">

			<div class="card mt-3">
				<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
					Data Masa Bakti Kepengurusan
				</div>
				<div class="card-body ">
					<div class="table-responsive">
						<table class="table table-bordered table-hover" id="tablePeriode" width="100%" cellspacing="0">
							<thead>
								<tr>
									<th>Masa Bakti</th>
									<th>Keterangan</th>
									<th width="10%">Opsi</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line end"></div></td>
								</tr>
								<tr>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line end"></div></td>
								</tr>
								<tr>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line"></div></td>
									<td><div class="shimmer title-line end"></div></td>
								</tr>
							</tbody>
							<tfoot>
								
							</tfoot>
							<tbody>
							
							
							
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
        
        `;
		return view;
	  },

	async afterRender() {
		await PeriodeKepengurusanPCPresenter.init();
		await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default PeriodeKepengurusanPC;