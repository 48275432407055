
import OverviewInitiator from '../../presenter/overview-presenter.js';
import ImagePopUPInitiator from '../../presenter/pop-up-presenter.js';

const Overview = {
	async render() {
		const view = `
		<div class="row">
			<div class="col-sm-6">
				<div class="alert alert-info">
					<h3 class="font-weight-bold">Pengurus Pusat</h3>
					Email : 
					<strong id="email_pp"></strong><br>
					Telp : 
					<strong id="telp_pp"></strong><br>
					Rekening : 
					<strong id="rek_pp"></strong><br>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="alert alert-success">
					<h3 class="font-weight-bold">Pengurus Daerah</h3>
					Email : 
					<strong id="email_pd"></strong><br>
					Telp : 
					<strong id="telp_pd"></strong><br>
					Rekening : 
					<strong id="rek_pd"></strong><br>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="alert alert-secondary">
					<h3 class="font-weight-bold">Pengurus Cabang</h3>
					Email : 
					<strong id="email_pc"></strong><br>
					Telp : 
					<strong id="telp_pc"></strong><br>
					Rekening : 
					<strong id="rek_pc"></strong><br>
				</div>
			</div>
		</div>
		<!--<div class="alert alert-warning alert-dismissible" id="alert-iuran-warning">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Informasi !</strong><br>
			<strong>Segera Lakukan Pembayaran Iuran Keanggotaan</strong>. Menu perizinan anda akan aktif setelah anda sudah membayar iuran keanggotaan
		</div>-->
		<div class="alert alert-warning alert-dismissible" id="alert-serkom-warning">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Warning!</strong> Serkom Anda akan segera habis.
		</div>
		<div class="alert alert-warning alert-dismissible" id="alert-sipttk-warning">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Warning!</strong> SIPTTK Anda akan segera habis.
		</div>
		<div class="alert alert-warning alert-dismissible" id="alert-strttk-warning">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Warning!</strong> STRTTK Anda akan segera habis.
		</div>
		<div class="alert alert-danger alert-dismissible" id="alert-serkom-danger">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Perhatian!</strong> Masa berlaku Serkom Anda sudah habis.
		</div>
		<div class="alert alert-danger alert-dismissible" id="alert-sipttk-danger">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Perhatian!</strong> Masa berlaku SIPTTK Anda sudah habis.
		</div>
		<div class="alert alert-danger alert-dismissible" id="alert-strttk-danger">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<strong>Perhatian!</strong> Masa berlaku STRTTK Anda sudah habis.
		</div>
		<h1 class="h3 mb-2 text-gray-800">Profil Anda</h1>
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataUmum" class="nav-link hapus-border active" id="data-umum-tab" data-bs-toggle="tab" data-bs-target="#tabDataUmum" data-toggle="tab" type="button" role="tab" aria-controls="tabDataUmum" aria-selected="true">Data Umum</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabProfilPendidikan" class="nav-link hapus-border" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabProfilPendidikan" data-toggle="tab" type="button" role="tab" aria-controls="tabProfilPendidikan" aria-selected="false">Profile Pendidikan</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabSERKOM" class="nav-link hapus-border" id="serkom-tab" data-bs-toggle="tab" data-toggle="tab" data-bs-target="#tabSERKOM" type="button" role="tab" aria-controls="tabSERKOM" aria-selected="false">SERKOM</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabPekerjaan" class="nav-link hapus-border" id="pekerjaan-tab" data-bs-toggle="tab" data-toggle="tab" data-bs-target="#tabPekerjaan" type="button" role="tab" aria-controls="tabPekerjaan" aria-selected="false">Riwayat Pekerjaan</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabKarirOrganisasi" class="nav-link hapus-border" id="karir-organisasi-tab" data-bs-toggle="tab" data-toggle="tab" data-bs-target="#tabKarirOrganisasi" type="button" role="tab" aria-controls="tabKarirOrganisasi" aria-selected="false">Karir Organisasi</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabSTRTTK" class="nav-link hapus-border" id="strttk-tab" data-bs-toggle="tab" data-toggle="tab" data-bs-target="#tabSTRTTK" type="button" role="tab" aria-controls="tabSTRTTK" aria-selected="false">STRTTK</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabSIPTTK" class="nav-link hapus-border" id="sipttk-tab" data-bs-toggle="tab" data-toggle="tab" data-bs-target="#tabSIPTTK" type="button" role="tab" aria-controls="tabSIPTTK" aria-selected="false">SIPTTK</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataUmum" role="tabpanel" aria-labelledby="data-umum-tab">
						<div class="row mt-3">
							<div class="col-lg-12 col-xl-6">
								<div class="card mb-3">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Formulir Data Umum</h6>
									</div>
									<div class="card-body">
										<div class="alert alert-info">
											<h3>Catatan Penting !</h3>
											<ol>
												<li>Pastikan anda mempunyai koneksi internet yang cukup</li>
												<li>Baca dan isi seluruh isian secara seksama secara urut</li>
												<li>Jangan menggunakan kamera HP secara langsung untuk upload foto/dokumen, karena file nya terlalu besar</li>
												<li>Foto diwajibkan menggunakan batik resmi PAFI.</li>
												<li>Sesuai dengan Anggaran Rumah Tangga Persatuan Ahli Farmasi Indonesia. BAB 1, Pasal 3, Nomor 3, Huruf b, ayat 4</li>
												<li>Setelah anda memperbaiki data atau upload file, silahkan klik tombol "UPDATE", untuk </li>memberitahu admin PC bahwa anda sudah memperbaiki data/upload file secara otomatis
												<li>Silahkan mulai mengisi/mengupdate form-form dibawah ini secara benar dan teliti</li>
												<li>Anda juga diwajibkan mengiisi profil pendidikan anda lengkap dengan scan Ijazah aslinya, Terutama pendidikan Farmasi</li>
												<li>Jika anda mendapati penolakan / revisi pada pengajuan ktan jangan lupa untuk melakukan perbaikan sesuai dengan apa yang di instruksikan oleh admin kemudian klik tombol <strong>Update.</strong></li>
												<li>Selanjutnya klik tombol <strong>Kirim Pengajuan KTAN</strong>, agar admin dapat mengetahui perubahan yang anda lakukan.</li>
											</ol>
										</div>
										
										<form id="form-data-umum">
											
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
												<div class="col-sm-12">
													<input type="text" name="ktan" placeholder="KTAN..." class="form-control" required id="textKTAN" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">PC</label>
												<div class="col-sm-12">
													<input type="text" name="pc" placeholder="PC..." class="form-control" required id="textPC" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">PD</label>
												<div class="col-sm-12">
													<input type="text" name="pd" placeholder="PD..." class="form-control" required id="textPD" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email</label>
												<div class="col-sm-12">
													<input type="email" name="email" placeholder="Email..." class="form-control" required id="email" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama Lengkap</label>
												<div class="col-sm-12">
													<input type="text" name="nama_lengkap" placeholder="Nama Lengkap..." class="form-control" required id="textNamaLengkap">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nomor Induk Kependudukan (NIK)</label>
												<div class="col-sm-12">
													<input type="number" name="nik" placeholder="Nomor Induk Kependudukan (NIK)..." class="form-control" required id="numberNIK" readonly>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Jenis Kelamin</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectJenisKelamin" name="jenis_kelamin" placeholder="Jenis Kelamin" required>
														<option value="">Jenis Kelamin</option>
														<option value="L">Laki Laki</option>
														<option value="P">Perempuan</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tempat Lahir</label>
												<div class="col-sm-12">
													<input type="text" name="tempait_lahir" placeholder="Tempat Lahir..." class="form-control" required id="textTempatLahir">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tanggal Lahir</label>
												<div class="col-sm-12">
													<input placeholder="Tanggal Lahir..." class="textbox-n form-control" type="date" id="dateTanggalLahir" required min="1900-01-01" max="2006-12-31"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Telp/HP</label>
												<div class="col-sm-12">
													<input type="number" name="no_hp" placeholder="Nomor Telp/HP..." class="form-control" required id="numberNoHP">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alamat Sesuai KTP</label>
												<div class="col-sm-12">
													<textarea class="form-control" id="textAlamat" name="alamat" placeholder="Alamat Sesuai KTP..." required></textarea>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Provinsi</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectProvinsi" name="provinsi" required>
														<option value="">Provinsi</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kabupaten / Kota</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKota" name="kota" required>
														<option value="">Kabupaten / Kota</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kecamatan</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKecamatan" name="selectKecamatan" required>
														<option value="">Kecamatan</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kelurahan / Desa</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKelurahan" name="kelurahan" required>
														<option value="">Kelurahan / Desa</option>
													</select>
												</div>
											</div>
											
											<div class="form-group row">
												<div class="col-sm-6 mb-1">
													<button type="submit" class="form-control btn btn-primary btn-sm" id="btnUpdateDataUmum"><i class="fas fa-edit"></i> Update Data</button>
												</div>
												
												<div class="col-sm-6 mb-1">
													<button class="btn btn-sm btn-success form-control" id="btnCetakKTAN"><i class="fas fa-print"></i> Cetak KTAN</button>
												</div>
												<div class="col-sm-12 mb-1 mt-2">
												
													<button type="button" class="btn btn-sm btn-primary form-control" id="btnKirimPengajuan"><i class="fas fa-paper-plane"></i> Kirim Pengajuan / Kirim Kembali Pengajuan KTAN</button>
												</div>
												<div class="col-sm-12 d-none border rounded border-primary p-3">
													<div class="row" id="ktanFisik">
														
													</div>
												</div>
											</div>
											<div id="cetakKTAN">

											</div>
										</form>
										<div class="card mt-3" id="cardMedia">
											<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
												<h6 class="m-0 font-weight-bold text-primary">Upload Berkas (Maks 500 KB / File)</h6>
											</div>
										<div class="card-body ">
											<div class="alert alert-info">
												<h5>Informasi Permohonan KTAN</h5>
												- KTAN Baru (Rp. 100.000)<br>
												- Lakukan Pembayaran Ke Rekening PC : <span id="infoRek"></span><br>
												- Pas Foto Wajib Menggunakan Batik Pafi
											</div>
											<form id="update-media">
												<div class="form-group row">
													<div class="col-sm-6">
														<label class="control-label font-weight-bold">File KTP (Max 500 Kb)</label>
														<div id="form-upload-ktp" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
													</div>
													
													<div class="col-sm-6">
														<label class="control-label font-weight-bold">File PAS Foto (Max 500 Kb)</label>
														<a style="margin:1px" class='btn btn-primary btn-sm' id='btnViewContohPasFoto' data-toggle="modal" data-target="#examplePasFoto" title='Lihat Contoh'><i class='fas fa-image'></i> Lihat Contoh</a>	
														<div id="form-upload-foto" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
													</div>
													
													<div class="col-sm-6">
														<label class="control-label font-weight-bold">Bukti Bayar (Max 500 Kb)</label>
														
														<div id="form-upload-pembayaran" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
													</div>

													
													<div class="col-sm-6">
														<label class="control-label font-weight-bold">File KTAN Lama (Max 500 Kb)</label>
														<div id="form-upload-ktan-lama" class="col-sm-12 dropzone">
															<input type="hidden" name="file" style=""/>
														</div>
													</div>
													
												</div>
												<div class="form-group row">
													<button type="button" class="form-control btn btn-primary btn-sm" id="btnUpdateDataFile"><i class="fas fa-edit"></i> Update Files</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div></div>
							<div class="col-lg-12 col-xl-6">
								<div class="card" id="cDomisili">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Alamat Domisili / Tinggal</h6>
									</div>
									<div class="card-body ">
										<form id="form-data-domisili">
												
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alamat</label>
												<div class="col-sm-12">
													<textarea class="form-control" id="textAlamatDomisili" name="alamat_domisili" placeholder="Alamat Domisili..." required></textarea>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Provinsi</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectProvinsiDomisili" name="provinsi_domisili" required>
														<option value="">Provinsi</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kabupaten / Kota</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKotaDomisili" name="kota_domisili" required>
														<option value="">Kabupaten / Kota</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kecamatan</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKecamatanDomisili" name="kecamatan_domisili" required>
														<option value="">Kecamatan</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Kelurahan / Desa</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectKelurahanDomisili" name="kelurahan_domisili" required>
														<option value="">Kelurahan / Desa</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<div class="col-sm-12 mb-1">
													<button class="form-control btn btn-primary btn-sm" id="btnUpdateDomisili"><i class="fas fa-edit"></i> Update Alamat Domisili</button>
												</div>
											</div>
										</form>
									</div>
									<div class="card-header mt-3 py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
									</div>
									<div class="card-body chat-body p-1" id="lamanChat">
										<div class="row p-3 chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
											<div style="width:100%">
												<div style="width:10%;" class="float-left">
													<div class="shimmer circle"></div>
												</div>
												<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line end"></div>
												</div>
											</div>
											<div style="width:100%">
												<div style="width:10%;" class="float-right">
													<div class="shimmer circle"></div>
												</div>
												<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line end" style="float:right;"></div>
												</div>
											</div>
											<div style="width:100%">
												<div style="width:10%;" class="float-left">
													<div class="shimmer circle"></div>
												</div>
												<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line end"></div>
												</div>
											</div>
											<div style="width:100%">
												<div style="width:10%;" class="float-right">
													<div class="shimmer circle"></div>
												</div>
												<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line"></div>
													<div class="shimmer content-line end" style="float:right;"></div>
												</div>
											</div>
										</div>
									</div>
									<div class="card-footer mb-3">
										<form id="form-comment">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Komentar</label>
												<div class="col-sm-12">
													<textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
												</div>
											</div>
											<div class="form-group row">
												<div class="col-sm-12">
													<button type="submit" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
												</div>
												</div>
											</div>
										</form>
									</div>

									<div class="card-header text-primary font-weight-bold">
										Riwayat Verifikasi KTAN
									</div>
									<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
											
										<div class="table-responsive">
											<table class="table table-bordered table-hover" id="tableHistoryVerifikasiKTAN" width="100%" cellspacing="0">
												<thead>
													<tr>
														<th>No</th>
														<th>Tanggal</th>
														<th>Nama</th>
														<th>Hak</th>
														<th>Keterangan</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td><div class="shimmer title-line end"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
													</tr>
													<tr>
														<td><div class="shimmer title-line end"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
													</tr>
													<tr>
														<td><div class="shimmer title-line end"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
														<td><div class="shimmer title-line"></div></td>
													</tr>
												</tbody>
												<tfoot>
													
												</tfoot>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					<div class="tab-pane fade" id="tabProfilPendidikan" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir Pendidikan
							</div>
							<div class="card-body ">
								<form id="form-pendidikan" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-4">
											<div class="form-group row">
												<input type="hidden" id="textHiddenIdPendidikan">
												<label class="control-label col-sm-12 font-weight-bold">Jenjang Pendidikan</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectJenjangPendidikan" name="jenjang_pendidikan" required>
														<option value="">Pilih Jenjang Pendidikan</option>
														<option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
														<option value="D3 FARMASI">D3 FARMASI</option>
														<option value="D3 ANAFARMA">D3 ANAFARMA</option>
														<option value="D3 NON-FARMASI">D3 NON-FARMASI</option>
														<option value="S1 FARMASI">S1 FARMASI</option>
														<option value="S1 NON-FARMASI">S1 NON-FARMASI</option>
														<option value="S2 FARMASI">S2 FARMASI</option>
														<option value="S2 NON-FARMASI">S2 NON-FARMASI</option>
														<option value="S3 FARMASI">S3 FARMASI</option>
														<option value="S3 NON-FARMASI">S3 NON-FARMASI</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Institusi Pendidikan</label>
												<div class="col-sm-12">
													<input type="text" name="institusi_pendidikan" placeholder="Institusi Pendidikan..." class="form-control" required id="textInstitusiPendidikan">
													
													<div id="pendidikanResult" class="list-group position-absolute col-sm-12" style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto; z-index:1000;">

													</div>
													
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tahun Lulus</label>
												<div class="col-sm-12">
													<input type="number" name="tahun_lulus" placeholder="Tahun Lulus..." class="form-control" required id="numberTahunLulus" min="1900" max="2099" step="1">
												</div>
											</div>
										</div>

										<div class="col-lg-4">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nomor Ijazah</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_ijazah" placeholder="Nomor Ijazah..." class="form-control" required id="textNomorIjazah">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tanggal Ijazah</label>
												<div class="col-sm-12">
													<input placeholder="Tanggal Ijazah..." class="textbox-n form-control" type="date"  id="dateTanggalIjazah" required min="01-01-1900" max="12-31-2099"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Jenis Dokumen</label>
												<div class="col-sm-12">
													<input type="text" name="jenis_dokumen" placeholder="Nomor Ijazah..." class="form-control" required id="textJenisDokumen" readonly value="Ijazah">
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<label class="control-label font-weight-bold">Scan Ijazah (Maks 500 KB / File)</label>
											<div id="form-upload-ijazah" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddPendidikan"><i class='fas fa-plus'></i> Tambah Pendidikan</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdatePendidikan"><i class='fas fa-edit'></i> Edit Pendidikan</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearPendidikan"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pendidikan
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableDataPendidikan" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Jenjang</th>
												<th>Nama Institusi Pendidikan</th>
												<th>Tahun Ijazah</th>
												<th>Nomor Ijazah</th>
												<th>Tanggal Ijazah</th>
												<th>Dokumen</th>
												<th width="5%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabPekerjaan" role="tabpanel" aria-labelledby="pekerjaan-tab">
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir Pekerjaan
							</div>
							<div class="card-body ">
								<form id="form-pekerjaan" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-4">
											<div class="form-group row">
												<input type="hidden" id="textHiddenPekerjaan">
												<label class="control-label col-sm-12 font-weight-bold">Jenis Pekerjaan</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectJenisPekerjaan" name="jenis_pekerjaan" required>
														<option value="">Pilih Jenis Pekerjaan</option>
														<option value="Apotek">Apotek</option>
														<option value="Klinik">Klinik</option>
														<option value="Instalasi Farmasi Kesehatan">Instalasi Farmasi Kesehatan</option>
														<option value="Rumah Sakit">Rumah Sakit</option>
														<option value="Puskesmas">Puskesmas</option>
														<option value="Dinas Kesehatan">Dinas Kesehatan</option>
														<option value="LABKESDA">LABKESDA</option>
														<option value="Pedagang Besar Farmasi">Pedagang Besar Farmasi</option>
														<option value="Toko Obat">Toko Obat</option>
														<option value="Toko Alat Kesehatan">Toko Alat Kesehatan</option>
														<option value="Kampus / Universitas">Kampus / Universitas</option>
														<option value="Sekolahan">Sekolahan</option>
														<option value="Industri Farmasi">Industri Farmasi</option>
														<option value="IEBA">IEBA</option>
														<option value="IOT">IOT</option>
														<option value="UMOT">UMOT</option>
														<option value="UKOT">UKOT</option>
														<option value="Ikos GOl. A">Ikos Gol. A</option>
														<option value="Ikos Gol. B">Ikos GOl. B</option>

													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama Tempat Kerja</label>
												<div class="col-sm-12">
													<input type="text" name="nama_tempat_kerja" placeholder="Nama Tempat Kerja..." class="form-control" required id="textNamaTempatKerja">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alamat</label>
												<div class="col-sm-12">
													<textarea class="form-control" placeholder="Alamat..." id="textAlamatPekerjaan"></textarea required>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Telp / Fax Tempat Kerja</label>
												<div class="col-sm-12">
													<input type="number" name="telp_fax" placeholder="Telp / Fax..." class="form-control" id="textTelpFax" required>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">No HP Tempat Kerja</label>
												<div class="col-sm-12">
													<input type="number" name="no_hp" placeholder="No HP..." class="form-control" id="textNoHP" required>
												</div>
											</div>
											
										</div>

										<div class="col-lg-4">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Email Tempat Kerja</label>
												<div class="col-sm-12">
													<input type="email" name="email" placeholder="Email..." class="form-control" id="textEmailPekerjaan" required>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Jabatan / Posisi</label>
												<div class="col-sm-12">
													<input type="text" name="jabatan" placeholder="Jabatan / Posisi..." class="form-control" required id="textJabatan">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Dari Tahun</label>
												<div class="col-sm-12">
													<select id="dateDariTahun" class="form-control"></select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Sampai Tahun</label>
												<div class="col-sm-12">
													<select id="dateSampaiTahun" class="form-control"></select>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<label class="control-label font-weight-bold">Scan SK Kerja (Maks 500 KB / File)</label>
											<div id="form-upload-pekerjaan" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddPekerjaan"><i class='fas fa-plus'></i> Tambah Pekerjaan</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdatePekerjaan"><i class='fas fa-edit'></i> Edit Pekerjaan</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearPekerjaan"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Riwayat Pekerjaan
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableDataPekerjaan" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Jenis Pekerjaan</th>
												<th>Nama Tempat Kerja</th>
												<th>Alamat</th>
												<th>Telp / Fax</th>
												<th>No Hp</th>
												<th>Email</th>
												<th>Jabatan</th>
												<th>Dari Tahun</th>
												<th>Sampai Tahun</th>
												<th>Dokumen</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabKarirOrganisasi" role="tabpanel" aria-labelledby="karir-organisasi-tab">
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir Karir Organisasi
							</div>
							<div class="card-body ">
								<form id="form-organisasi" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-4">
											<div class="form-group row">
												<input type="hidden" id="textHiddenOrganisasi">
												<label class="control-label col-sm-12 font-weight-bold">Nama Organisasi</label>
												<div class="col-sm-12">
													<input type="text" name="nama_organisasi" placeholder="Nama Organisasi..." class="form-control" required id="textNamaOrganisasi">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alamat</label>
												<div class="col-sm-12">
													<textarea class="form-control" placeholder="Alamat..." id="textAlamatOrganisasi"></textarea>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Jabatan / Posisi</label>
												<div class="col-sm-12">
													<input type="text" name="jabatan" placeholder="Jabatan / Posisi..." class="form-control" required id="textJabatanOrganisasi">
												</div>
											</div>
										</div>

										<div class="col-lg-4">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Dari Tahun</label>
												<div class="col-sm-12">
													<input placeholder="Dari Tahun..." class="textbox-n form-control" type="number"  id="dateDariTahunOrganisasi" required min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Sampai Tahun</label>
												<div class="col-sm-12">
													<input placeholder="Sampai Tahun..." class="textbox-n form-control" type="number"  id="dateSampaiTahunOrganisasi" required min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<label class="control-label font-weight-bold">Scan SK Organisasi (Maks 500 KB / File)</label>
											<div id="form-upload-organisasi" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddOrganisasi"><i class='fas fa-plus'></i> Tambah Organisasi</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdateOrganisasi"><i class='fas fa-edit'></i> Edit Organisasi</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearOrganisasi"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Riwayat Karir Organisasi
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableDataRiwayatOrganisasi" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Nama Organisasi</th>
												<th>Alamat</th>
												<th>Jabatan</th>
												<th>Dari Tahun</th>
												<th>Sampai Tahun</th>
												<th>Dokumen</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabSTRTTK" role="tabpanel" aria-labelledby="strttk-tab">
					
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir STRTTK
							</div>
							<div class="card-body ">
								<form id="form-strttk" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group row">
												<input type="hidden" id="textHiddenSTRTTK">
												<label class="control-label col-sm-12 font-weight-bold">Jenis STRTTK</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectJenisSTRTTK">
														<option value="old">STRTTK LAMA</option>
														<option value="new">STRTTK BARU</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nomor STRTTK</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_strttk" placeholder="Nomor STRTTK..." class="form-control" required id="textNomorSTRTTK">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tanggal</label>
												<div class="col-sm-12">
													<input placeholder="Tanggal..." class="textbox-n form-control" type="date"  id="dateTanggalSTRTTK" required min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
											<div class="form-group row d-none" id="oldForm">
												<label class="control-label col-sm-12 font-weight-bold">Berlaku Sampai</label>
												<div class="col-sm-12">
													<input placeholder="Berlaku Sampai..." class="textbox-n form-control" type="date"  id="dateBerlakuSampai" min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
											<div class="form-group row d-none" id="newForm">
												<label class="control-label col-sm-12 font-weight-bold">Berlaku Sampai</label>
												<div class="col-sm-12">
													<input placeholder="Seumur Hidup" class="textbox-n form-control" type="text" readonly/>
												</div>
											</div>
										</div>
										<div class="col-lg-6">
											<label class="control-label font-weight-bold">Scan STRTTK (Maks 500 KB / File)</label>
											<div id="form-upload-strttk" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddSTRTTK"><i class='fas fa-plus'></i> Tambah STRTTK</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdateSTRTTK"><i class='fas fa-edit'></i> Edit STRTTK</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearSTRTTK"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data STRTTK
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableSTRTTK" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Nomor STRTTK</th>
												<th>Tanggal</th>
												<th>Berlaku Sampai</th>
												<th>Dokumen</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					
					</div>
					<div class="tab-pane fade" id="tabSIPTTK" role="tabpanel" aria-labelledby="sipttk-tab">
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir SIPTTK
							</div>
							<div class="card-body ">
								<form id="form-sipttk" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-4">
											<div class="form-group row">
												<input type="hidden" id="textHiddenSIPTTK">
												<label class="control-label col-sm-12 font-weight-bold">Nomor STRTTK</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_strttk" placeholder="Nomor STRTTK..." class="form-control" required id="textNomorSTRTTKSIP">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nomor SIPTTK</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_sipttk" placeholder="Nomor SIPTTK..." class="form-control" required id="textNomorSIPTTK">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Jenis SIPTTK</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectJenisSIPTTK" name="jenis_sipttk" required>
														<option value="">Pilih Salah Satu</option>
														<option value="Ke Satu">Ke Satu</option>
														<option value="Ke Dua">Ke Dua</option>
														<option value="Ke Tiga">Ke Tiga</option>
													</select>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tanggal</label>
												<div class="col-sm-12">
													<input type="date"  pattern="\d{2}-\d{2}-\d{4}" name="tanggal" class="form-control" required id="dateTanggalSIPTTK">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Berlaku Sampai</label>
												<div class="col-sm-12">
													<input type="date"  pattern="\d{2}-\d{2}-\d{4}" name="berlaku_sampai" class="form-control" required id="dateBerlakuSampaiSIPTTK">
												</div>
											</div>
										</div>

										<div class="col-lg-4">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tempat Kerja</label>
												<div class="col-sm-10">
													<select class="form-control" id="selectTempatKerjaSIP" name="tempat_kerja" required>
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
												<div class="col-sm-2">
													<button type="button" class="btn btn-info btn-sm form-control" id="btnRefreshPekerjaan" title="Refresh Pekerjaan"><i class="fas fa-sync"></i></button>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Alamat Tempat Kerja</label>
												<div class="col-sm-12">
													<textarea placeholder="Alamat Tempat Kerja..." class="form-control" type="text"  id="textAlamatTempatKerjaSIP" required readonly></textarea>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama Apoteker Supervisi</label>
												<div class="col-sm-12">
													<input type="text" name="nama_apoteker_supervisi" placeholder="Nama Apoteker Supervisi..." class="form-control" required id="textNamaApotekerSupervisi">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nomor STRA Apoteker</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_stra_apoteker" placeholder="Nomor STRA Apoteker..." class="form-control" required id="textNomorSTRAApoteker">
												</div>
											</div>
										</div>
										<div class="col-lg-4">
											<label class="control-label font-weight-bold">Scan SK Wajib (Maks 500 KB / File)</label>
											<div id="form-upload-sipttk" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddSIPTTK"><i class='fas fa-plus'></i> Tambah SIPTTK</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdateSIPTTK"><i class='fas fa-edit'></i> Edit SIPTTK</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearSIPTTK"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data SIPTTK
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableSIPTTK" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Nomor STRTTK</th>
												<th>Nomor SIP/TTK</th>
												<th>Tanggal</th>
												<th>Berlaku Sampai</th>
												<th>Jenis</th>
												<th>Nama Tempat Kerja</th>
												<th>Alamat Tempat Kerja</th>
												<th>Nama Apoteker Supervisi</th>
												<th>Nomor STRA Apoteker</th>
												<th>Dokumen</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabSERKOM" role="tabpanel" aria-labelledby="serkom-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Formulir SERKOM
							</div>
							<div class="card-body ">
								<form id="form-serkom" enctype="multipart/form-data">
									<div class="row">
										<div class="col-lg-6">
											<div class="form-group row">
												<input type="hidden" id="textHiddenSERKOM">
												<label class="control-label col-sm-12 font-weight-bold">Nomor SERKOM</label>
												<div class="col-sm-12">
													<input type="text" name="nomor_serkom" placeholder="Nomor SERKOM..." class="form-control" required id="textNomorSERKOM">
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Tanggal</label>
												<div class="col-sm-12">
													<input placeholder="Tanggal..." class="textbox-n form-control" type="date"  id="dateTanggalSERKOM" required min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Berlaku Sampai</label>
												<div class="col-sm-12">
													<input placeholder="Berlaku Sampai..." class="textbox-n form-control" type="date"  id="dateBerlakuSampaiSERKOM" required min="1900-01-01" max="2099-12-31"/>
												</div>
											</div>
										</div>
										<div class="col-lg-6">
											<label class="control-label font-weight-bold">Scan SERKOM (Maks 500 KB / File)</label>
											<div id="form-upload-serkom" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div><br>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddSERKOM"><i class='fas fa-plus'></i> Tambah SERKOM</button>
											<button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdateSERKOM"><i class='fas fa-edit'></i> Edit SERKOM</button>
											<button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearSERKOM"><i class='fas fa-eraser'></i> Kosongkan</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data SERKOM
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableSERKOM" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th>Nomor SERKOM</th>
												<th>Tanggal</th>
												<th>Berlaku Sampai</th>
												<th>Dokumen</th>
												<th width="10%">Opsi</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
		
        <div class="modal fade" id="examplePasFoto" tabindex="-1" role="dialog" aria-labelledby="examplePasFoto"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary">CONTOH FOTO YANG BAIK DAN BENAR</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
							<div class="col-sm-6 p-2">
								<span class="text-primary">Laki Laki</span>
								<img src="images/pas-foto-laki-laki.jpg" class="img-fluid">
							</div>
							<div class="col-sm-6 p-2">
								<span class="text-primary">Perempuan</span>
								<img src="images/pas-foto-perempuan.jpg" class="img-fluid">
							</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
		
		
		
		<div style="display:none;" id="my-template">
			<div id="mytmp" class="dz-preview dz-file-preview">
				<div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
				<div class="dz-details">
					<div class="dz-size"><span data-dz-size></span></div>
					<div class="dz-filename"><span data-dz-name></span></div>
				</div>
				
				<div class="dz-error-message"><span data-dz-errormessage></span></div>
				<div class="dz-success-mark">
					<svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
					</svg>
				</div>
				<div class="dz-error-mark">
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<circle cx="12" cy="19" r="2" />
						<path d="M10 3h4v12h-4z" />
					</svg>
				</div>
				<div class="dz-remove" data-dz-remove>
					<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
						<path d="M0 0h24v24H0z" fill="none" />
						<path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
					</svg>
				</div>
			</div>
		</div>
		
		
		`;
		return view;
	  },

	async afterRender() {
		mixpanel.track("Overview page")
		await OverviewInitiator.init();
		await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default Overview;
