import UploadBuktiBayarRegistrasiPresenter from "../../presenter/upload-bukti-bayar-registrasi-presenter.js";

const UploadBuktiBayarRegistrasi = {
    async render() {
        return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-6 col-lg-6 col-md-6">
      
                    <div class="card shadow-lg my-2">
                        <div class="card-header text-primary font-weight-bold">
                            Upload Bukti Bayar Registrasi
                        </div>
                        <div class="card-body">
                            <!-- Nested Row within Card Body -->
                            <div class="alert alert-info">
                                <strong>Informasi:</strong>
                                <p>Silakan isi email dan upload bukti pembayaran registrasi sesuai dengan nominal yang tertera pada email anda, terimakasih</p>
                            </div>
                            <form id="form-upload-bukti-bayar-registrasi" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-sm-12 mb-3">
                                        <label class="control-label text-primary font-weight-bold">Email</label>
                                        <input type="email" id="email" class="form-control" placeholder="Email..." required>
                                    </div>
                                    <div class="col-lg-12">
                                        <label class="control-label text-primary font-weight-bold">Bukti Bayar</label>
                                        <div id="fileBuktiBayar" class="col-sm-12 dropzone">
                                            <input type="hidden" name="file" style=""/>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12">
                                        <button type="submit" class="form-control btn btn-primary" id="btnUploadBuktiBayar"><i class="fas fa-upload"></i> Kirim Bukti Bayar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>
        
        
        
        
    <div style="display:none;" id="my-template">
    <div id="mytmp" class="dz-preview dz-file-preview">
        <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
        <div class="dz-details">
            <div class="dz-size"><span data-dz-size></span></div>
            <div class="dz-filename"><span data-dz-name></span></div>
        </div>
        
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-success-mark">
            <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
        </div>
        <div class="dz-error-mark">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="19" r="2" />
                <path d="M10 3h4v12h-4z" />
            </svg>
        </div>
        <div class="dz-remove" data-dz-remove>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
        </div>
    </div>
</div>
        
        `
      
   },
  
    async afterRender() {
        await UploadBuktiBayarRegistrasiPresenter.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default UploadBuktiBayarRegistrasi;
  

