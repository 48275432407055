
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import UrlParser from "../../routes/url-parser.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiNotication from "../../api/data-notification.js";
import MembershipHelper from "../../utils/membership-helper.js";
import Autentication from "../../utils/autentication.js";

const SKPSayaPresenter = {

	async init() {
		//await MembershipHelper.scyncMembershipHelper();
		const eventClear = async (e) => {
			await this._getDataSKPFiltered();
			await this._getSUMSKP();
		}
		await this._setView();
		
		$("#skp-saya-tab").one("click", async function () {
			await eventClear()
		})


		await this._createSKP();
		await this._readNotification()
		await this._initCommentSKP();
		await this._downloadBorang();
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
		} else {
			window.location.hash = '#/not-access-fitur';
		}
		$("#btnUpdateSKP").hide();

	},

	async _downloadBorang(){
		const eventClickDownloadBorang = async () => {
			let dataUser = await Autentication.getData();
			let idUser = dataUser[0].id;
			let namaAnggota = dataUser[0].name;
			const downloadBorang = await ApiAnggota.downloadBorangSKP({
				id: idUser,
				namaAnggota : namaAnggota
			})
		}

		document.getElementById('btnDownloadBorang').addEventListener('click', eventClickDownloadBorang)
	},

	async _getDataSKPFiltered(){
		const eventClear = async (e) => {
			let status = document.getElementById('selectStatus').value;
			this._getDataSKP(status)
			

		const eventFiltered = async () =>{
			let status = document.getElementById('selectStatus').value;
			this._getDataSKP(status)
		}


		const eventClickResetFilter = async() => {
			let status = '';

			document.getElementById('selectStatus').value = status;

			this._getDataSKP(status)

		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefreshData').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilter').addEventListener('click', eventClickResetFilter);

		}

		await eventClear();
	
		

    },


	async _getDataSKP(status) {
		var savedOrderColumn = 'nama_kegiatan';
		var savedOrderDirection = 'asc';

		let table = await $('#tableSKPSaya').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"bProcessing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"bServerSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_ANGGOTA.SKP(status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'nama_kegiatan' },
				{
					data: 'kategori', render: function (data) {
						if (data == "baksos") {
							var kategori = 'Baksos';
						} else if (data == "masa_kerja") {
							var kategori = 'Masa Kerja';
						} else {
							var kategori = 'Pembelajaran';
						}

						return kategori;
					}
				},
				{ data: 'tanggal_mulai' },
				{ data: 'tanggal_selesai' },
				{ data: 'berlaku_sampai' },
				{ data: 'jenis_kegiatan' },
				{ data: 'nomor_sertifikat' },
				{ data: 'nomor_surat_keputusan' },
				{ data: 'tempat_penyelenggaraan' },
				{ data: 'kontribusi_peserta' },
				{ data: 'ketua_kegiatan' },
				{ data: 'informasi' },
				{ data: 'skp' },
				{
					data: 'skp_approve_pc', render: function (data) {
						if (data == null || data == "") {
							return '0';
						} else {
							return data;
						}
					}
				},
				{
					data: 'skp_approve_pd', render: function (data) {
						if (data == null || data == "") {
							return '0'
						} else {
							return data
						}
					}
				},
				{
					data: 'dokumen', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{
					data: 'bukti_kegiatan', render: function (data) {
						if (data == null || data == "") {
							return '-'
						} else {
							return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}
					}
				},
				{ data: 'status_pc', render : function (data) {
					if (data == 'pending') {
						var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					}else if (data == null) {
						var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">pending</span>`;
					} else if (data == 'revise') {
						var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					} else {
						var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					}

					

					return status
				} },
				{ data: 'status_pd', render : function (data) {
					
					if (data == 'pending') {
						var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					}else if (data == null) {
						var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">pending</span>`;
					} else if (data == 'revise') {
						var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					} else {
						var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
					}

					return status
				} },
				{
					data: 'comment_unread_count', render: function (data, type, rows) {
						if (rows.status_pd == 'approve') {
							var content = `<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='resume' title='Lihat Resume'  data-toggle="modal" data-target="#modalViewResume"><i class='far fa-eye'></i></button>
							<button style="margin:1px;" data-toggle="modal" data-target="#commentModalSKP" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data}</span></button></div>
	
							<div style="display:flex">
							<button style="margin:1px; padding-left: 10px; padding-right: 10px" class='btn btn-danger btn-sm' id='delete' title='Hapus SKP'><i class='fas fa-trash'></i></button></div>`
						}else {
							var content = `<div style="display:flex">
							<button style="margin:1px" class='btn btn-primary btn-sm' id='resume' title='Lihat Resume'  data-toggle="modal" data-target="#modalViewResume"><i class='far fa-eye'></i></button>
							<button style="margin:1px;" data-toggle="modal" data-target="#commentModalSKP" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i><span class="badge-chat" style="">${data}</span></button></div>
	
							<div style="display:flex">
							<button style="margin:1px" class='btn btn-warning btn-sm' id='edit' title='Edit data'><i class='far fa-edit'></i></button>
							<button style="margin:1px; padding-left: 10px; padding-right: 10px" class='btn btn-danger btn-sm' id='delete' title='Hapus SKP'><i class='fas fa-trash'></i></button></div>`

						}

						return content;
					}
				}

			],
			"columnDefs": [
				{
					"targets": [12,13,14, 15,16],
					"visible": true,
					"searchable": true,
					"className": "text-center",
				},
				{
					"targets": [15,16,19],
					"visible": true,
					"orderable": false
				},
			]
		});

		$('#tableSKPSaya thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tableSKPSaya thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			console.log(columnName)
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});

	},
	
	async _ubahFormatTanggal(tanggal){
		// Membagi tanggal menjadi komponen hari, bulan, dan tahun
		var komponenTanggal = tanggal.split("-");
		var hari = komponenTanggal[0];
		var bulan = komponenTanggal[1];
		var tahun = komponenTanggal[2];
	  
		// Membentuk tanggal baru dalam format "yyyy-MM-dd"
		var tanggalBaru = tahun + "-" + bulan + "-" + hari;
	  
		return tanggalBaru;
	},
	
	async _getSUMSKP() {
		let getSKP = await ApiAnggota.getCountSKP();
		let data = getSKP.data;

		$("#jmlSKPBaksos").html(data.skp_baksos);
		$("#jmlSKPMasaKerja").html(data.skp_masa_kerja);
		$("#jmlSKPPendidikkan").html(data.skp_pendidikan);
		$("#jmlSKP").html(data.skp_total)


	},

	async _initCommentSKP() {
		let idPengajuan = '';
		let userID = $("#textUserID").val();
		$('#tableSKPSaya tbody').on('click', '#comment', async function () {

			const table = $('#tableSKPSaya').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let userID = $("#textUserID").val();
			idPengajuan = data.id;


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiAnggota.getCommentar(idPengajuan,"skp")
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let userID = $("#textUserID").val();
			document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanSKP").value
			const response = await ApiAnggota.sendCommentar({
				pengajuanID: idPengajuan,
				contentComment: content,
				commentFor: 'skp',
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiAnggota.getCommentar(idPengajuan,"skp")
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanSKP').val('');
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanSKP").addEventListener("click", eventSendCommentar)
	},
	async _createSKP() {


		tinymce.remove('#textResume')
		tinymce.init({
			selector: '#textResume',
			menubar: false,
			min_height: 500,
			plugins: 'print | link',
			visual: false,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | link | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});


		let DzoneSKP = new Dropzone("#form-upload-sertifikat", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-sertifikat .dz-preview:hidden').remove()
					$('#form-upload-sertifikat .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-sertifikat").classList.remove('dz-started');
				});
			}
		});

		let DzoneSelfie = new Dropzone("#form-upload-selfie", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-selfie .dz-preview:hidden').remove()
					$('#form-upload-selfie .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-selfie").classList.remove('dz-started');
				});
			}
		});


		const eventCreateSKP = async (e) => {
			e.preventDefault();

			let textResume = tinymce.get("textResume").getContent();
			if ($("#btnAddSKP").is(':visible')) {
				if ( document.getElementById('selectKategoriSKP').value == "") {
					NotificationModal.show(`Kategori Wajib di isi`, 'error');
					return;
				}
				document.getElementById("btnAddSKP").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSKP = await ApiAnggota.createSKP({
					tanggal_mulai: document.getElementById('dateTanggalMulaiSKP').value,
					tanggal_selesai: document.getElementById('dateTanggalSelesaiSKP').value,
					nama_kegiatan: document.getElementById('textNamaKegiatanSKP').value,
					nomor_sertifikat: document.getElementById('textNomorSertifikatSKP').value,
					kategori: document.getElementById('selectKategoriSKP').value,
					jenis_kegiatan: document.getElementById('selectJenisKegiatanSKP').value,
					nomor_surat_keputusan: document.getElementById('textNomorSuratKeputusan').value,
					tempat_penyelenggaraan: document.getElementById('textTempatPenyelenggaraan').value,
					kontribusi_peserta: document.getElementById('textKontribusiPeserta').value,
					berlaku_sampai: document.getElementById('textBerlakuSampai').value,
					skp: document.getElementById('numberSKP').value,
					ketua_kegiatan: document.getElementById('textKetuaKegiatan').value,
					informasi: document.getElementById('textInformasi').value,
					catatan: textResume,
					sertifikatSKP: (DzoneSKP.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSKP.files[0].dataURL, DzoneSKP.files[0].name),
					buktiKegiatan: (DzoneSelfie.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSelfie.files[0].dataURL, DzoneSelfie.files[0].name)


				});
				if (createSKP.status_code != 201) {
					NotificationModal.show(`${createSKP.message}`, 'error');
					document.getElementById("btnAddSKP").innerHTML = `<i class="fas fa-add"></i> Tambah SKP`;
					$("#btnUpdateSKP").hide();
				} else {
					NotificationModal.show('SKP Berhasil di ajukan', 'success');
					document.getElementById("btnAddSKP").innerHTML = `<i class="fas fa-add"></i> Tambah SKP`;
					$("#btnUpdateSKP").hide();
					$("#btnClearSKP").trigger("click");
					$('#tableSKPSaya').DataTable().ajax.reload();
					this._getSUMSKP();
				}
			} else {
				document.getElementById("btnUpdateSKP").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSKP = await ApiAnggota.updateSKP({
					id: document.getElementById('textHIddenSKP').value,
					tanggal_mulai: document.getElementById('dateTanggalMulaiSKP').value,
					tanggal_selesai: document.getElementById('dateTanggalSelesaiSKP').value,
					nama_kegiatan: document.getElementById('textNamaKegiatanSKP').value,
					nomor_sertifikat: document.getElementById('textNomorSertifikatSKP').value,
					kategori: document.getElementById('selectKategoriSKP').value,
					jenis_kegiatan: document.getElementById('selectJenisKegiatanSKP').value,
					nomor_surat_keputusan: document.getElementById('textNomorSuratKeputusan').value,
					tempat_penyelenggaraan: document.getElementById('textTempatPenyelenggaraan').value,
					kontribusi_peserta: document.getElementById('textKontribusiPeserta').value,
					skp: document.getElementById('numberSKP').value,
					ketua_kegiatan: document.getElementById('textKetuaKegiatan').value,
					informasi: document.getElementById('textInformasi').value,
					catatan: textResume,
					sertifikatSKP: (DzoneSKP.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSKP.files[0].dataURL, DzoneSKP.files[0].name),
					buktiKegiatan: (DzoneSelfie.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSelfie.files[0].dataURL, DzoneSelfie.files[0].name)

				});

				if (updateSKP.status_code != 200) {
					NotificationModal.show(`${updateSKP.message}`, 'error');
					$("#btnAddSKP").hide();
					document.getElementById("btnUpdateSKP").innerHTML = `<i class="fas fa-edit"></i> Edit SKP`;
				} else {
					NotificationModal.show('SKP Berhasil di update', 'success');
					document.getElementById("btnUpdateSKP").innerHTML = `<i class="fas fa-edit"></i> Edit SKP`;
					$("#btnAddSKP").hide();
					$("#btnClearSKP").trigger("click");
					$('#tableSKPSaya').DataTable().ajax.reload();
					this._getSUMSKP();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-skp').addEventListener('submit', eventCreateSKP);


		$('#tableSKPSaya tbody').on('click', '#edit', async function () {
			const table = $('#tableSKPSaya').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let skpApprovePD = data.skp_approve_pd;
			let berlakuSampai = data.berlaku_sampai;

			if (skpApprovePD == null) {
				skpApprovePD = 0;
			}
				$('#textHIddenSKP').val(data.id);
				$('#dateTanggalMulaiSKP').val(await SKPSayaPresenter._ubahFormatTanggal(data.tanggal_mulai));
				$('#dateTanggalSelesaiSKP').val(await SKPSayaPresenter._ubahFormatTanggal(data.tanggal_selesai));
				$('#textNamaKegiatanSKP').val(data.nama_kegiatan);
				$('#textNomorSertifikatSKP').val(data.nomor_sertifikat);
				$('#selectKategoriSKP').val(data.kategori);
				$('#selectJenisKegiatanSKP').val(data.jenis_kegiatan);
				$('#textNomorSuratKeputusan').val(data.nomor_surat_keputusan);
				$('#textTempatPenyelenggaraan').val(data.tempat_penyelenggaraan);
				var kontribusiPesertaValue = data.kontribusi_peserta;
				var selectElement = document.getElementById("textKontribusiPeserta");
				var existingOption = Array.from(selectElement.options).find(option => option.value === kontribusiPesertaValue);
				if (existingOption) {
					selectElement.value = kontribusiPesertaValue;
					document.getElementById("containerEditKontrib").classList.add("d-none")
				} else {
					document.getElementById("containerEditKontrib").classList.remove("d-none")
					document.getElementById("kontribPesertaValue").innerHTML =` Anda Berperan sebagai <b>${kontribusiPesertaValue}</b>`
					
				}

				$('#numberSKP').val(data.skp);
				$('#textKetuaKegiatan').val(data.ketua_kegiatan);
				$('#textInformasi').val(data.informasi);
				if (berlakuSampai != null) {
					$('#textBerlakuSampai').val(await SKPSayaPresenter._ubahFormatTanggal(data.berlaku_sampai))
				}else {
					$('#textBerlakuSampai').val('')
				}
				$("#textResume_ifr").html(data.catatan);
				$("#btnAddSKP").hide();
				$("#btnUpdateSKP").show();
				$("#data-skp-tab").trigger('click');

				let skpbyid = await ApiAnggota.getSKPbyID(data.id)
				let resume = skpbyid.data.catatan;
				var editor = tinymce.get('textResume');
				editor.setContent(resume);

				DzoneSKP.emit("removeThumbnailCustom");
				if (data.dokumen != "") {
					var mockFile = { name: "Sertifikat-" + data.nama_kegiatan, size: 12345 };
					DzoneSKP.emit("addedfile", mockFile);
					DzoneSKP.emit("thumbnail", mockFile, data.dokumen);
				}
				DzoneSelfie.emit("removeThumbnailCustom");
				if (data.bukti_kegiatan != "") {
					var mockFile = { name: "Bukti Kegiatan-" + data.nama_kegiatan, size: 12345 };
					DzoneSelfie.emit("addedfile", mockFile);
					DzoneSelfie.emit("thumbnail", mockFile, data.bukti_kegiatan);
				}




		});

		$('#tableSKPSaya tbody').on('click', '#delete', async function () {
			const table = $('#tableSKPSaya').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Kegiatan </strong>: " + data.nama_kegiatan,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deleteSKP = await ApiAnggota.deleteSKP({
						id: id
					});

					if (deleteSKP.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSKP.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tableSKPSaya').DataTable().ajax.reload();

						let getSKP = await ApiAnggota.getSKP();
						let data = getSKP.data;
						var jmlSKPBaksos = 0;
						var jmlSKPMasaKerja = 0;
						var jmlSKPPendidikan = 0;
						var jmlSKP = 0;
						data.forEach((dataPerKategori) => {
							if (dataPerKategori.kategori == "Baksos") {
								jmlSKPBaksos = jmlSKPBaksos + dataPerKategori.skp_approve_pd
							}
							if (dataPerKategori.kategori == "Masa Kerja") {
								jmlSKPMasaKerja = jmlSKPMasaKerja + dataPerKategori.skp_approve_pd
							}
							if (dataPerKategori.kategori == "Pendidikan") {
								jmlSKPPendidikan = jmlSKPPendidikan + dataPerKategori.skp_approve_pd
							}
						});

						jmlSKP = parseInt(jmlSKPBaksos) + parseInt(jmlSKPMasaKerja) + parseInt(jmlSKPPendidikan);

						$("#jmlSKPBaksos").html(jmlSKPBaksos);
						$("#jmlSKPMasaKerja").html(jmlSKPMasaKerja);
						$("#jmlSKPPendidikkan").html(jmlSKPPendidikan);
						$("#jmlSKP").html(jmlSKP)
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSKP.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});
		});
		$('#tableSKPSaya tbody').on('click', '#resume', async function () {
			const table = $('#tableSKPSaya').DataTable();
			let data = table.row($(this).parents('tr')).data();

			let skpbyid = await ApiAnggota.getSKPbyID(data.id)
			let resume = skpbyid.data.catatan;
			let skpName = skpbyid.data.nama_kegiatan;

			if (resume == null || resume == "") {
				$("#resumeSKP").html("<center><i>Tidak ada resume !</i></center>")
			} else {
				$("#titleSKP").html(skpName);
				$("#resumeSKP").html(resume)
			}


		});
		const eventClear = async (e) => {
			document.getElementById("form-skp").reset();
			$("#btnAddSKP").show();
			$("#btnUpdateSKP").hide();
			if (DzoneSKP.files.length > 0) {
				DzoneSKP.removeFile(DzoneSKP.files[0])
			}
			if (DzoneSelfie.files.length > 0) {
				DzoneSelfie.removeFile(DzoneSelfie.files[0])
			}
			DzoneSKP.emit("removeThumbnailCustom");
			DzoneSelfie.emit("removeThumbnailCustom");
			document.getElementById("containerEditKontrib").classList.add("d-none")
		}

		document.getElementById("btnClearSKP").addEventListener("click", eventClear)




	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},




}

export default SKPSayaPresenter;