import DokumenValidPresenter from "../../presenter/dokumen-valid-presenter.js";

const DokumenValid = {
    async render() {
        return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-11 col-lg-11 col-md-11">
      
                    <div class="card o-hidden border-0 shadow-lg my-2">
                        <div class="card-body p-3">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-12 border p-3">
                                    <div class="p-2">
                                    <img loading="lazy" class="img-fluid rounded mx-auto d-block" src="images/check.gif" width="50px" alt="valid">
                                        <div class="text-center">
                                            <h2 id="noteMessage" class="h5 text-gray-900 mb-4">Dokumen Tervalidasi</h2>
                                        </div>
                                    </div>
                                    <img loading="lazy" class="img-fluid rounded mx-auto d-block" src="images/logo-pafi.png" width="10%" alt="valid"><br>
                                    <center><h1 class="h3 text-gray-900 font-weight-bold mb-4">PERSATUAN AHLI FARMASI INDONESIA<h1>
                                    <h1 class="h5 text-gray-900 font-weight-bold mb-4">SURAT KETERANGAN KECUKUPAN SKP TENAGA VOKASI FARMASI<h1>
                                    <h1 class="h5 text-gray-900 font-weight-bold mb-4">NO : <span id="textNoSurat"></span><h1></center>

                                    <table class="table table-bordered table-hovered table-responsive">
                                        <tr>
                                            <td valign="top" width="30%">Nama</td>
                                            <td valign="top" width="0%">:</td>
                                            <th valign="top" style="text-align:left" id="textName" width="80%"></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Jenis Kelamin</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left" id="textJK"></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Tempat, Tanggal Lahir</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left"><span id="textTempatLahir"></span>, <span id="textTanggalLahir"></span></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Alamat</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left" id="textAlamat"></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Institusi Pendidikan</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left" id="textInstitusi"></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Nomor Ijazah</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left" id="textNoIjazah"></th>
                                        </tr>
                                        <tr>
                                            <td valign="top">Tanggal Ijazah</td>
                                            <td valign="top">:</td>
                                            <th valign="top" style="text-align:left" id="textTanggalIjazah"></th>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await DokumenValidPresenter.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default DokumenValid;
  

