
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import PDFHelper from "../../utils/pdf-helpert.js";
import ApiSeminar from "../../api/data-seminar.js";
import SEMINAR_ENPOINT from "../../config/globals/seminar_endpoint.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";




const PermohonanSeminarPdPresenter = {

	async init() {
		await this._setView();
		await this._getDataPrivate()
		await this._getPengajuanSeminar();
		await this._getPengajuanSeminarById();
		await this._approveSeminar()
		await this._initKomentar()
		await this._downloadSK();
		await this._initApprovePembayaran();
		await this._initRevisiPembayaran();
		await this._initApproveAgreement();
		await this._initUploadBuktiBayar();
	},


	async _showLoading(){
		var loadingElement = document.createElement("div");
		loadingElement.setAttribute("id", "loading");
		
		var spinnerElement = document.createElement("div");
		spinnerElement.setAttribute("class", "spinner");
		
		for (var i = 0; i < 3; i++) {
			var barElement = document.createElement("div");
			barElement.setAttribute("class", "bar");
			spinnerElement.appendChild(barElement);
		}
		
		loadingElement.appendChild(spinnerElement);
		document.body.appendChild(loadingElement);
	},

	async _hideLoading(){
		var loadingElement = document.getElementById("loading");
		if (loadingElement) {
			loadingElement.parentNode.removeChild(loadingElement);
		}
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		await this._formatNumber('numberBiayaPenerbitan');
	},
	
	async _initUploadBuktiBayar(){
		let DzoneBuktiBayarSeminar = new Dropzone("#dropzone-bukti-bayar-seminar", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#dropzone-bukti-bayar-seminar .dz-preview:hidden').remove()
					$('#dropzone-bukti-bayar-seminar .dz-preview').fadeOut('slow');
					document.getElementById("dropzone-bukti-bayar-seminar").classList.remove('dz-started');
				});
			}
		});

		$('#tablePengajuanSeminar tbody').on('click', '#uploadBuktiBayar', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idSeminar = data.id;
			$("#textIdSeminarBuktiBayar").val(idSeminar)
			if (data.bukti_bayar_skp_seminar_pc != "" && data.bukti_bayar_skp_seminar_pc != null ) {
				document.getElementById("containerBuktiBayarSeminar").classList.remove("d-none")
				document.getElementsByName("show_image_bukti_bayar")[0].src = data.bukti_bayar_skp_seminar_pd;
			}else{
				document.getElementById("containerBuktiBayarSeminar").classList.add("d-none")
			}
		});

		const eventBuktiBayarSeminar = async (e) => {
			e.preventDefault()
			mRefresh.refresh()
			let idSeminar =$("#textIdSeminarBuktiBayar").val()
			let fileBuktiBayar =  (DzoneBuktiBayarSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBuktiBayarSeminar.files[0].dataURL ,DzoneBuktiBayarSeminar.files[0].name )
		
			if (fileBuktiBayar == null ) {
				NotificationModal.show("Bukti bayar harus di isi","warning")
			}else{
				document.getElementById("btnSaveBuktiBayarSeminar").innerHtml = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let response = await ApiSeminar.uploadBuktiBayarSeminarPD({
					file_import : fileBuktiBayar,
					idSeminar : idSeminar
				})
				if (response.status_code == 200) {
					$('#modalUploadBuktiBayarSeminar').modal("hide");
					swal.fire({
						title: 'Success',
						text: `${response.message}`,
						icon: 'success',
						showConfirmButton: false,
						timer: 1000
					});
					await $('#tablePengajuanSeminar').DataTable().ajax.reload();
					DzoneBuktiBayarSeminar.emit("removeThumbnailCustom");

				}else{
					NotificationModal.show(``,"warning")

					swal.fire({
						title: 'warning',
						text: `${response.message}`,
						icon: 'warning',
						showConfirmButton: false,
						timer: 1000
					});
				}
				document.getElementById("btnSaveBuktiBayarSeminar").innerHTML =`<i class="fas fa-file"></i> KIRIM LAPORAN SEMINAR`
			}
			mRefresh.resolve()
			e.preventDefault()
		}

		document.getElementById("btnSaveBuktiBayarSeminar").addEventListener("click",eventBuktiBayarSeminar)
	},
	
	async _getDataPrivate() {

		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)

		var selectPC = document.getElementById("selectPC");
		var lengthOptionPc = selectPC.options.length;
		for (let i = lengthOptionPc - 1; i >= 0; i--) {
			selectPC.options[i] = null;
		}


		document.getElementById("selectPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectPC").add(new Option(data.regency_name, data.id))
		});



	},


	async _getPengajuanSeminar(){

		let kategori = document.getElementById('selectKategori').value;
		let statusPD = document.getElementById('selectStatus').value;
		let statusPP = document.getElementById('selectStatusPP').value;
		let pembayaranPD = document.getElementById('selectStatusPembayaranPD').value;
		let pembayaranPP = document.getElementById('selectStatusPembayaranPP').value;
		let agreementStatus = document.getElementById('selectStatusSK').value;
		let idCabang = document.getElementById('selectPC').value;
		this._getPengajuanSeminarList(kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idCabang)

		const eventFiltered = async () =>{
			let kategori = document.getElementById('selectKategori').value;
			let statusPD = document.getElementById('selectStatus').value;
			let statusPP = document.getElementById('selectStatusPP').value;
			let pembayaranPD = document.getElementById('selectStatusPembayaranPD').value;
			let pembayaranPP = document.getElementById('selectStatusPembayaranPP').value;
			let agreementStatus = document.getElementById('selectStatusSK').value;
			let idCabang = document.getElementById('selectPC').value;
			this._getPengajuanSeminarList(kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idCabang)
		}

		const eventChangeKategori = async () => {
			let kategori = document.getElementById('selectKategori').value;
			if (kategori == 'baksos') {
				$('#selectStatusPP').prop('disabled', true);
				$('#selectStatusPembayaranPP').prop('disabled', true);
			} else {
				$('#selectStatusPP').prop('disabled', false);
				$('#selectStatusPembayaranPP').prop('disabled', false);
			}
		}

		const eventClickResetFilter = async() => {
			let kategori = '';
			let statusPD = '';
			let statusPP = '';
			let pembayaranPD = '';
			let pembayaranPP = '';
			let agreementStatus = '';
			let idCabang = '';

			document.getElementById('selectKategori').value = kategori;
			document.getElementById('selectStatus').value = statusPD;
			document.getElementById('selectStatusPP').value = statusPP;
			document.getElementById('selectStatusPembayaranPD').value = pembayaranPD;
			document.getElementById('selectStatusPembayaranPP').value = pembayaranPP;
			document.getElementById('selectStatusSK').value = agreementStatus;
			document.getElementById('selectPC').value = idCabang;
			$('#selectStatusPP').prop('disabled', false);
			$('#selectStatusPembayaranPP').prop('disabled', false);

			this._getPengajuanSeminarList(kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idCabang)

		}

		document.getElementById('selectKategori').addEventListener('change', eventChangeKategori);
		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefreshData').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilter').addEventListener('click', eventClickResetFilter);



    },


	async _getPengajuanSeminarList(kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idCabang){
		
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tablePengajuanSeminar').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"responsive":true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${SEMINAR_ENPOINT.GET_PENGAJUAN_SEMINAR_PD_IN(kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idCabang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				},
				"dataSrc": function (json) {
					document.getElementById("informationRekening").innerHTML = json.additional.rekening_pp
					const data = json.data;
					return data;
				}
			},
			"columns": [
				{ data: null },
				{ data: 'tanggal' },
				{ data: 'judul_seminar' },
				{ data: 'penyelenggara' },
				{ data: 'wilayah_penyelenggara' },
				{ data: 'kategori_peserta' },
				{ data: 'kontribusi_peserta', render: function name(data) {
					let content = '';
					if (data > 0) {
						content =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data);
					}else {
						content = 'Gratis'
					}
					return content
				} },
				{ data: 'biaya_penerbitan_skp_pc', render : function (data) {
					let content = '';
					if (data > 0) {
						content =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data);
					}else {
						content = 'Gratis'
					}
					return content
				}},
				{ data: 'biaya_penerbitan_skp_pd', render : function (data) {
					let content = '';
					if (data > 0) {
						content =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data);
					}else {
						content = 'Gratis'
					}
					return content
				}},
				{ data: 'bukti_bayar_skp_seminar_pc', render : function (data) {
					let content = '';
					if (data == null || data == "") {
						content =  '<strong>File tidak ditemukan</strong>'
					}else {
						content = `<img src="${data}" width="60px">`
					}
					return content
				}},
				{ data: 'bukti_bayar_skp_seminar_pd', render : function (data) {
					let content = '';
					if (data == null || data == "") {
						content =  '<strong>File tidak ditemukan</strong>'
					}else {
						content = `<img src="${data}" width="60px">`
					}
					return content
				}},
				{ data: 'dikirim_oleh' },
				{ data: 'skp_peserta_pd',render : function (data, type, rows){
					if (rows.kategori_peserta == "hybrid") {
						return `
						<table class="table-borderless table-sm ">
							<tr>
								<td>Peserta</td>
								<td>:</td>
								<td>${rows.skp_peserta_pd}</td>
							</tr>
							<tr>
								<td>Peserta-online</td>
								<td>:</td>
								<td>${rows.skp_peserta_pd_online}</td>
							</tr>
							<tr>
								<td>Panitia</td>
								<td>:</td>
								<td>${rows.skp_panitia_pd}</td>
							</tr>
							<tr>
								<td>Moderator</td>
								<td>:</td>
								<td>${rows.skp_moderator_pd}</td>
							</tr>
							<tr>
								<td>Narasumber</td>
								<td>:</td>
								<td>${rows.skp_narasumber_pd}</td>
							</tr>
							</table>
						`
					}else{
						return `
						<table class="table-borderless table-sm ">
						<tr>
							<td>Peserta</td>
							<td>:</td>
							<td>${rows.skp_peserta_pd}</td>
						</tr>
						<tr>
							<td>Panitia</td>
							<td>:</td>
							<td>${rows.skp_panitia_pd}</td>
						</tr>
						<tr>
							<td>Moderator</td>
							<td>:</td>
							<td>${rows.skp_moderator_pd}</td>
						</tr>
						<tr>
							<td>Narasumber</td>
							<td>:</td>
							<td>${rows.skp_narasumber_pd}</td>
						</tr>
						</table>
						`
					}
				} },
				{ data: 'skp_peserta_pp',render : function (data, type, rows){
					
					if (rows.kategori_peserta == "hybrid") {
						return `
						<table class="table-borderless table-sm ">
							<tr>
								<td>Peserta</td>
								<td>:</td>
								<td>${rows.skp_peserta_pp}</td>
							</tr>
							<tr>
								<td>Peserta-online</td>
								<td>:</td>
								<td>${rows.skp_peserta_pp_online}</td>
							</tr>
							<tr>
								<td>Panitia</td>
								<td>:</td>
								<td>${rows.skp_panitia_pp}</td>
							</tr>
							<tr>
								<td>Moderator</td>
								<td>:</td>
								<td>${rows.skp_moderator_pp}</td>
							</tr>
							<tr>
								<td>Narasumber</td>
								<td>:</td>
								<td>${rows.skp_narasumber_pp}</td>
							</tr>
							</table>
						`
					}else{
						return `
						<table class="table-borderless table-sm ">
						<tr>
							<td>Peserta</td>
							<td>:</td>
							<td>${rows.skp_peserta_pp}</td>
						</tr>
						<tr>
							<td>Panitia</td>
							<td>:</td>
							<td>${rows.skp_panitia_pp}</td>
						</tr>
						<tr>
							<td>Moderator</td>
							<td>:</td>
							<td>${rows.skp_moderator_pp}</td>
						</tr>
						<tr>
							<td>Narasumber</td>
							<td>:</td>
							<td>${rows.skp_narasumber_pp}</td>
						</tr>
						</table>
						`
					}
				} },
				{ data: 'kategori_seminar', render : function (data) {
					if (data == 'pendidikan') {
						return 'Pembelajaran'
					}else {
						return 'Pengabdian Masyarakat / RAKERCAB / RAKERDA'
					}
				} },
				{ data: 'verifikasi_pd', render : function (data) {
					if (data == 'Belum Verifikasi PD') {
						return `<strong class="text-warning">${data}</strong>`
					}else return data
				}},
				{ data: 'verifikasi_pp', render : function (data) {
					if (data == 'Belum Verifikasi PP') {
						return `<strong class="text-warning">${data}</strong>`
					}else return data
				}},
				{ data: 'proposal_seminar',
					render : function(data,type , row){
						let valueProposal = ""
						let valueLaporan = ""

						if (row.proposal_seminar == null || row.proposal_seminar == "") {
							valueProposal = '-'
						}else {
							if (data.endsWith(".pdf")) {
								valueProposal = `
								<button  type="button" data-title="${row.judul_seminar}" data-file="${data}" class="btn btn-outline-danger openPdf">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
									<path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
									<path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
								</svg> Buka Proposal Seminar</button>
								`
							}else {
								valueProposal = '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">'	
							}
						}

						if (row.laporan_seminar == null || row.laporan_seminar == "") {
							valueLaporan = ""
						}else {
							if (data.endsWith(".pdf")) {
								valueLaporan = `
								<button  type="button" data-title="${row.judul_seminar}" data-file="${row.laporan_seminar}" class="btn btn-outline-danger openPdf">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
									<path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
									<path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
								</svg> Buka Laporan Seminar</button>
								`
							}else {
								valueLaporan = '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">'	
							}
						}
						
						if (valueLaporan != "") {
							return `${valueProposal} ${valueLaporan}`
						}else{
							return `${valueProposal}`
						}
					}
				},
				{ data: 'status_pembayaran_pd', render : function (data, type, rows) {
					var kategori_seminar = rows.kategori_seminar;
					var statusPD = '';
					var statusPP = '';
					var pembayaranPD = '';
					var pembayaranPP = '';
					var agreement = '';
					if (kategori_seminar == 'pendidikan') {
						if (rows.status_pd == "pending") {
							statusPD = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Seminar Menunggu Persetujuan PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pd == "approve") {
							statusPD = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Seminar Disetujui PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else {
							statusPD = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> Seminar Direvisi PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						
						if (rows.status_pp == "pending") {
							statusPP = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Seminar Menunggu Persetujuan PP</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pp == "approve") {
							statusPP = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Seminar Disetujui PP</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else {
							statusPP = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> Seminar Direvisi PP</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						
						if (rows.status_pembayaran_pd == "confirm") {
							pembayaranPD = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Konfirmasi PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pembayaran_pd == "paid") {
							pembayaranPD = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Pembayaran Disetujui PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if(rows.status_pembayaran_pd == "pending"){
							pembayaranPD = `
							<div class="badge badge-pill badge-secondary"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Seminar Disetujui PP</div>
							<div>
								<i class="fas fa-arrow-down"></i>
							</div>
						`;
						}else{
							pembayaranPD = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> Pemohon Belum Upload Bukti Bayar</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						if (rows.status_pembayaran_pp == "confirm") {
							pembayaranPP = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Konfirmasi PP</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pembayaran_pp == "paid") {
							pembayaranPP = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Pembayaran Disetujui PP</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if(rows.status_pembayaran_pp == "pending"){
							pembayaranPP = `
							<div class="badge badge-pill badge-secondary"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Seminar Disetujui PP</div>
							<div>
								<i class="fas fa-arrow-down"></i>
							</div>
						`;
						}else{
							pembayaranPP = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> PD Belum Upload Bukti Bayar</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						if (rows.agreement_status == "pending") {
							if (rows.status_pembayaran_pp == "paid") {
								agreement = `
									<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> SK Belum di tanda tangani</div>
								`;
							}else {
								agreement = `
									<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Tanda Tangan Menunggu Pembayaran Disetujui PP</div>
								`;
							}
						}else if (rows.agreement_status == "signed") {
							agreement = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> SK Di tanda tangani</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
								<div class="badge badge-pill badge-primary"><i class="fas fa-check"></i> Finished</div>
							`;
						}else {
							agreement = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> SK Menunggu Di tanda tangan</div>
							`;
						}
					}else {
						if (rows.status_pd == "pending") {
							statusPD = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Seminar Menunggu Persetujuan PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pd == "approve") {
							statusPD = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Seminar Disetujui PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else {
							statusPD = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> Seminar Direvisi PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						
	
						
						if (rows.status_pembayaran_pd == "confirm") {
							pembayaranPD = `
								<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Konfirmasi PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if (rows.status_pembayaran_pd == "paid") {
							pembayaranPD = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> Pembayaran Disetujui PD</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}else if(rows.status_pembayaran_pd == "pending"){
							pembayaranPD = `
							<div class="badge badge-pill badge-secondary"><i class="fas fa-sync-alt"></i> Pembayaran Menunggu Seminar Disetujui PD</div>
							<div>
								<i class="fas fa-arrow-down"></i>
							</div>
						`;
						}else{
							pembayaranPD = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> Pemohon Belum Upload Bukti Bayar</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
							`;
						}
	
						if (rows.agreement_status == "pending") {
							if (rows.status_pembayaran_pd == "paid") {
								agreement = `
									<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> SK Belum di tanda tangani</div>
								`;
							}else {
								agreement = `
									<div class="badge badge-pill badge-warning"><i class="fas fa-sync-alt"></i> Tandatangan Menunggu Pembayaran Disetujui PD</div>
								`;
							}
						}else if (rows.agreement_status == "signed") {
							agreement = `
								<div class="badge badge-pill badge-success"><i class="fas fa-check"></i> SK Ditandatangani</div>
								<div>
									<i class="fas fa-arrow-down"></i>
								</div>
								<div class="badge badge-pill badge-primary"><i class="fas fa-check"></i> Finished</div>
							`;
						}else {
							agreement = `
								<div class="badge badge-pill badge-danger"><i class="fas fa-times"></i> SK Menunggu Ditandatangan</div>
							`;
						}
					}
					

					var content = statusPD + statusPP + pembayaranPD + pembayaranPP + agreement

					// if (data == "" || data == null) {
					// 	content = `<span class="badge badge-secondary">Menunggu persetujuan PP</span>`
					// }else if(data == 'unpaid') {
					// 	content = `<span class="badge badge-danger">${data}</span>`
					// }else if (data == "confirm") {
					// 	content = `<span class="badge badge-warning">${data}</span>`
					// }else{
					// 	content = `<span class="badge badge-success">${data}</span>`
					// }

					return content;
				} }
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false,
					"orderable": false,
					"render": function (data, type, row, meta) {
						return meta.row + 1;
					}
				},
				{
					"targets": [18],
					"visible": true,
					"searchable": false,
					"className" :"text-center"
				},
				
				{
					"targets": [ 0,7,8,9,10,11,12,13,17,18,19],
					"orderable": false,
				},
				{ responsivePriority: 1, targets: [2] },
				{ responsivePriority: 2, targets: [19] },
				{ responsivePriority: 3, targets: [18] },
				{ responsivePriority: 4, targets:  [7,8,12,13] },
				{
					"targets": 19,
					"data": null,
					render: function (data) {
						let content = ``;

						if (data.kategori_seminar == 'baksos') {
							if (data.status_pd == 'approve') {
								if (data.status_pembayaran_pd == "confirm") {
									content = `
										<div style="display:block" class="mr-1 mb-2">
											<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
											<button class='mb-1 btn btn-info btn-sm col-sm-12' id='approvePembayaran' title='Approve Pembayaran' ><i class="fas fa-coins"></i> Approve Pembayaran</button>
											<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revisePayment' title='Revisi Pembayaran PC' data-toggle="modal" data-target="#commentModalPayment"><i class="fas fa-coins"></i> Revisi Pembayaran PC</button>
										</div>
										`
								}else if(data.status_pembayaran_pd == "paid"){
									if (data.agreement_status == 'signed') {
										content = `
											<div style="display:block" class="mr-1 mb-2">
												<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												<button class='mb-1 btn btn-outline-success btn-sm col-sm-12' id='downloadSK' title='Download SK SKP' ><i class="fas fa-download"></i> Download SK</button>
												<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='reviseTTD' title='Batalkan Tanda Tangan SK' ><i class="fas fa-signature"></i> Batalkan Tanda Tangan</button>
												
											</div>
											`
									}else {
										if (data.biaya_penerbitan_skp_pd <= 0) {
											content = `
											<div style="display:block" class="mr-1 mb-2">
												<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												<button class='mb-1 btn btn-info btn-sm col-sm-12' id='approveTTD' title='Tanda Tangan SK' ><i class="fas fa-signature"></i> Tanda Tangan SK</button>
												<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revise' title='Revise Pengajuan' data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i> Revisi Pengajuan</button>
											</div>
												`
										}else {
											content = `
											<div style="display:block" class="mr-1 mb-2">
												<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												<button class='mb-1 btn btn-info btn-sm col-sm-12' id='approveTTD' title='Tanda Tangan SK' ><i class="fas fa-signature"></i> Tanda Tangan SK</button>
												<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revisePayment' title='Revisi Pembayaran PC' data-toggle="modal" data-target="#commentModalPayment"><i class="fas fa-coins"></i> Revisi Pembayaran PC</button>
											</div>
											`
										}
									}
								}else {
									content = `
									<div style="display:block" class="mr-1">
										<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
										<!--<button class='mb-1 btn btn-info btn-sm col-sm-12   id='accSeminar' title='Acc Seminar' data-toggle="modal" data-target="#modalAccSeminar"><i class="fas fa-check"></i></button>-->
										<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revise' title='Revise Pengajuan' data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i> Revisi Pengajuan</button>
										
									</div>
									`
								}
							}
							else {
								content = `
								<div style="display:Block" class="mr-1">
									<!--<button class='mb-1 btn btn-outline-success btn-sm col-sm-12' id='downloadSK' title='Download SK SKP' ><i class="fas fa-download"></i></button>-->
									<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
									<button class='mb-1 btn btn-info btn-sm col-sm-12 '   id='accSeminar' title='Acc Seminar' data-toggle="modal" data-target="#modalAccSeminar"><i class="fas fa-check"></i> Approve Pengajuan</button>
									<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revise' title='Revise Pengajuan' data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i> Revisi Pengajuan</button>
								</div>`
							}
						}else {
							if (data.status_pp == 'approve') {
								if (data.status_pembayaran_pd == "confirm") {
									content = `
										<div style="display:block" class="mr-1 mb-2">
											<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
											<button class='mb-1 btn btn-info btn-sm col-sm-12' id='approvePembayaran' title='Approve Pembayaran' ><i class="fas fa-coins"></i> Approve Pembayaran</button>
											<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revisePayment' title='Revisi Pembayaran PC' data-toggle="modal" data-target="#commentModalPayment"><i class="fas fa-coins"></i> Revisi Pembayaran PC</button>
										</div>
										`
								}else if(data.status_pembayaran_pd == "paid"){

									
									if (data.agreement_status == 'signed') {
										content = `
											<div style="display:block" class="mr-1 mb-2">
												<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												<button class='mb-1 btn btn-outline-success btn-sm col-sm-12' id='downloadSK' title='Download SK SKP' ><i class="fas fa-download"></i> Download SK</button>
												
											</div>
											`
									}else {
										if (data.biaya_penerbitan_skp_pd <= 0) {
											content = `
												<div style="display:block" class="mr-1 mb-2">
													<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												</div>
												`
										}else {
											content = `
												<div style="display:block" class="mr-1 mb-2">
													<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
												</div>
												<button class='mb-1 btn btn-outline-warning btn-sm col-sm-12' id='uploadBuktiBayar' title='Upload Bukti Bayar Seminar' data-toggle="modal" data-target="#modalUploadBuktiBayarSeminar"  ><i class="fas fa-upload"></i> Upload Bukti Bayar Seminar </button>
												`
										}
									}
								}else {
									if (data.bukti_bayar_skp_seminar_pc == null || data.bukti_bayar_skp_seminar_pc == "" ) {
										content = `
									<div style="display:block" class="mr-1">
										<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
									</div>
									`
									}else{
										content = `
										<div style="display:block" class="mr-1">
											<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
											<button class='mb-1 btn btn-outline-warning btn-sm col-sm-12' id='uploadBuktiBayar' title='Upload Bukti Bayar Seminar' data-toggle="modal" data-target="#modalUploadBuktiBayarSeminar"  ><i class="fas fa-upload"></i> Upload Bukti Bayar Seminar</button>
											
										</div>
										`
									}
									
								}
							}else {
								if (data.status_pd == 'approve') {
									content = `
									<div style="display:block" class="mr-1">
										<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
										<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revise' title='Revisi Pengajuan' data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i> Revisi Pengajuan</button>
										
									</div>`
								}else {
									content = `
									<div style="display:block" class="mr-1">
										<button class='mb-1 btn btn-primary btn-sm col-sm-12' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i> Detail</button>
										<button class='mb-1 btn btn-info btn-sm col-sm-12 '   id='accSeminar' title='Acc Seminar' data-toggle="modal" data-target="#modalAccSeminar"><i class="fas fa-check"></i> Approve Pengajuan</button>
										<button class='mb-1 btn btn-danger btn-sm col-sm-12' id='revise' title='Revisi Pengajuan' data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i> Revisi Pengajuan</button>
										
									</div>`
								}
							}


						}

						return content

						
					}
					
				},
				]
		});

		$('#tablePengajuanSeminar thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			var columnName = ($(this)[0]).getAttribute('data-column')
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});
	},

	
	async _downloadSK() {
		$('#tablePengajuanSeminar tbody').on('click', '#downloadSK', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let wilayah = '';

			if (data.kategori_seminar == 'baksos') {
				wilayah = 'pd'
			}else{
				wilayah = 'pp'
			}

			const downloadSK = await ApiSeminar.downloadSK({ id,wilayah });
			$(this).html('<i class="fas fa-download"></i> Download SK');

		});
	},
	
	async _getPengajuanSeminarById(){

		let idSeminar = 0;

		const formatAngka = (number)=> {
			
			const reverse = number.toString().split('').reverse().join('');
			let formattedValue = '';
			let count = 0;
		
			for (const char of reverse) {
			if (count === 3) {
				formattedValue += '.';
				count = 0;
			}
			formattedValue += char;
			count++;
			}
	
		return formattedValue.split('').reverse().join('');
		}
	
		
		$('#tablePengajuanSeminar tbody').on('click', '#detail', async function () {
			
			$("#judulSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#kategoriSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#ketuaSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#sekretarisSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#aboutTime").html('<div class="shimmer content-line"></div>');
			$("#aboutPeserta").html('<div class="shimmer content-line"></div>');
			$("#tanggalPelaksanaan").html('<div class="shimmer content-line"></div>');
			$("#menimbang").html('<div class="shimmer content-line"></div>');
			$("#mengingat").html('<div class="shimmer content-line"></div>');
			$("#skpPeserta").html('<div class="shimmer content-line end"></div>');
			$("#skpPanitia").html('<div class="shimmer content-line end"></div>');
			$("#skpModerator").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
			
			const table = $('#tablePengajuanSeminar').DataTable();
			
			let data = table.row($(this).parents('tr')).data();
			
			

			idSeminar = data.id;
			$("#stateIdSeminarDetail").val(idSeminar)
			const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
				idSeminar
			});

			if (getPengajuanSeminarById.status_code != 200) {
				NotificationModal.show("Gagal memuat data", "error");
			}else {
				
				let dataDet = getPengajuanSeminarById.data;
				let dataMateri = dataDet.materi_seminar;
				let jumlahSeminar = dataDet.materi_seminar.length;
				let skp_peserta = '';
				let skp_panitia = '';
				let skp_moderator = '';
				let skp_narasumber = ''; 
				let skp_peserta_online ='';
				if (dataDet.kategori_seminar == 'pendidikan') {
					skp_peserta = dataDet.skp_peserta_pp
					skp_moderator = dataDet.skp_moderator_pp
					skp_narasumber = dataDet.skp_narasumber_pp
					skp_panitia = dataDet.skp_panitia_pp
					skp_peserta_online = dataDet.skp_peserta_pp_online
				}else {
					skp_peserta = dataDet.skp_peserta_pd
					skp_moderator = dataDet.skp_moderator_pd
					skp_narasumber = dataDet.skp_narasumber_pd
					skp_panitia = dataDet.skp_panitia_pd
					skp_peserta_online = dataDet.skp_peserta_pd_online
				}

				if (data.kategori_peserta == "hybrid" ) {
					document.getElementById("tr_peserta_seminar_online").classList.remove("d-none")
					$("#skpPesertaOnline").html(skp_peserta_online);
					$("#peserta_seminar_offline").html("Peserta Seminar Offline");
				
				}else{
					document.getElementById("tr_peserta_seminar_online").classList.add("d-none")
					$("#peserta_seminar_offline").html("Peserta");
				}


				let kategoriSeminar = dataDet.kategori_seminar;
				if (kategoriSeminar == 'pendidikan') {
					kategoriSeminar = 'Pembelajaran'
				}else {
					kategoriSeminar = 'Pengabdian Masyarakat / RAKERCAB / RAKERDA'
				}
				$("#judulSeminarDet").html(dataDet.judul_seminar);
				$("#kategoriSeminarDet").html(kategoriSeminar);
				$("#skpPeserta").html(skp_peserta);
				$("#skpPanitia").html(skp_panitia);
				$("#skpModerator").html(skp_moderator);
				$("#skpNarasumber").html(skp_narasumber);
				$("#ketuaSeminarDet").html(dataDet.nama_penanda_pelaksana);
				$("#sekretarisSeminarDet").html(dataDet.nama_penanda_sekretaris);
				$("#aboutTime").html( `${dataDet.tanggal_mulai}  ${dataDet.waktu_mulai} sampai dengan ${dataDet.tanggal_selesai} ${dataDet.waktu_selesai}  `);
				if (dataDet.kategori_peserta == null || dataDet.quota_peserta == null || dataDet.kontribusi_peserta == null) {
					$("#aboutPeserta").html( `-`);
				}else {
					$("#aboutPeserta").html( `Dilaksanakan ${dataDet.kategori_peserta.toUpperCase()} , Quota Peserta   ${dataDet.quota_peserta} dengan kontribusi peserta sebesar Rp. ${formatAngka(dataDet.kontribusi_peserta)} `);
				}

				if (dataDet.penyelenggara == "external") {
					document.getElementById("formExternalDetail").classList.remove("d-none")
					$("#textNomorSuratDetail").val(dataDet.external.nomor_surat_permohonan);
					$("#tanggalSuratExternalDetail").val(dataDet.external.tanggal_surat_permohonan);
					$("#textKeteranganDetail").val(dataDet.external.keterangan);

					document.getElementById("btnSuratDetPDF").addEventListener("click",() =>{
						PDFHelper.showPdf(dataDet.judul_seminar,dataDet.external.surat_permohonan)
					})
				}else{
					document.getElementById("formExternalDetail").classList.add("d-none")
				}

				let tableMateri = $("#tableMateri").DataTable({
						"destroy": true,
						columnDefs: [{
							"defaultContent": "-",
							"targets": "_all"
						  }]
				})


				if (jumlahSeminar <= 0) {
					$("#contentDet").html('<tr><td colspan="10" class="text-center" style="font-style:italic;">Tidak ada data</td></tr>')
				}else {

					try {
						$('#tableMateri').dataTable().fnClearTable();
						$('#tableMateri').dataTable().fnDestroy();
					} catch (error) {
						console.log(error)
					}
					let i = 0
				
					dataMateri.forEach(async function (materi) {
						i++;
						var lampiran = ``;
						if (materi.lampiran == null || materi.lampiran == '') {
							lampiran = '-';
						}else {
							lampiran = `<img src="${materi.lampiran}" width="40px;">`
						}
						tableMateri.row
							.add([
								materi.id,
								materi.judul_materi_seminar,
								materi.moderator,
								materi.pemateri,
								materi.tanggal_seminar,
								materi.tempat_penyelenggaraan,
								materi.author,
								materi.dari_jam,
								materi.sampai_jam,
								lampiran,
								`-`
							]).draw(false);
					});

					$("#tableMateri").DataTable({
						"destroy": true
					})

					$('#tableMateri tbody').off('click','#deleteMateriSeminar').on('click', '#deleteMateriSeminar', async function () {
			
						const table = $('#tableMateri').DataTable();
						let data = table.row($(this).parents('tr')).data();
						const refreshThis = async () =>{
							var row = $(this).closest('tr');
							table.row(row).remove().draw(false);
						}
					
						swal.fire({
							title: 'Hapus Materi Seminar',
							html: "Anda ingin Hapus Materi Seminar ini ?\ " + data[1],
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: ' #d33',
							cancelButtonColor: '#3085d6',
							confirmButtonText: 'Ya, Delete!'
						}).then(async (result) => {
							if (result.value) {
								let id = data[0];
								const deleteMateriSeminar = await ApiSeminar.deleteMateriSeminar({
									id: id
								});
			
								if (deleteMateriSeminar.status_code == '200') {
									swal.fire({
										title: 'Delete',
										text: "Data berhasil dihapus",
										icon: 'success',
										showConfirmButton: false,
										timer: 1000
									});

									await refreshThis()
									
								}else {
									swal.fire({
										title: 'Error',
										text: deleteMateriSeminar.message,
										icon: 'error',
										showConfirmButton: false,
										timer: 1000
									});
								}
							};
						});

				});

				$('#tableMateri tbody').off('click','#editMateriSeminar').on('click', '#editMateriSeminar', async function () {
					const table = $('#tableMateri').DataTable();
					let data = table.row($(this).parents('tr')).data();
					document.getElementById("stateTitleMateri").innerHTML = "Edit Materi Seminar"
					mRefresh.refresh()
					const getMateriSeminar =await ApiSeminar.getMateriSeminarById({idSeminar : data[0]})
					if (getMateriSeminar.status_code == 200) {
						mRefresh.resolve()
						$('#modalTambahMateri').modal("show");
						$("#textIdSeminar").val(data[0]);
						$("#textJudulSeminar").val(getMateriSeminar.data.judul_materi_seminar);
						$("#textPemateri").val(getMateriSeminar.data.pemateri);
						$("#textInstansi").val(getMateriSeminar.data.instansi);
						$("#textDariJam").val(getMateriSeminar.data.dari_jam);
						$("#textSampaiJam").val(getMateriSeminar.data.sampai_jam);
						$("#textModerator").val(getMateriSeminar.data.moderator);
						document.getElementsByName("show_image_lampiran")[0].src = getMateriSeminar.data.lampiran;
						$("#stateMateriSeminar").val("edit");
						document.getElementById("containerLampiranEdit").classList.remove("d-none")	
						tinymce.remove('#textDeskripsiMateri')
						tinymce.init({
							selector: '#textDeskripsiMateri',
							menubar: false,
							min_height: 500,
							plugins: 'print | link',
							visual: false,
							inline_styles: true,
							toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
							fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
						});
						try {
							tinymce.get('textDeskripsiMateri').setContent(getMateriSeminar.data.description);	
						} catch (error) {
							
						}
					}

				 });
				}

				tinymce.remove('#textDeskripsiDetail')
				tinymce.init({
					selector: '#textDeskripsiDetail',
					menubar: false,
					min_height: 500,
					plugins: 'print | link',
					visual: false,
					inline_styles: true,
					toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
					fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
				});
				
				try {
					tinymce.get('textDeskripsiDetail').setContent(dataDet.description);	
				} catch (error) {
					
				}

				document.getElementById("btnProposalPdfDetail").addEventListener("click", () => {
					PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.proposal_seminar)
				})
				document.getElementById("btnSuratPengantarPdfDetail").addEventListener("click", () => {
					PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.surat_pengantar_pc)
				})

				$("#brosurDetailImage").attr("src",dataDet.dokumen.brosur);
				$("#backgroundSertifikatDetail").attr("src",dataDet.dokumen.background_sertifikat);
			}


		});

	},

	async _approveSeminar(){

		let idSeminar = 0;
		try {
			tinymce.remove('#textMenimbang')
			tinymce.remove('#textMengingat')
		} catch (error) {
			
		}

		
		tinymce.init({
			selector: 'textarea#textMenimbang',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});
		tinymce.init({
			selector: 'textarea#textMengingat',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});

		$('#tablePengajuanSeminar tbody').on('click', '#accSeminar', async function () {
			
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idSeminar = data.id;
			let kategoriSeminar = ''
			if (data.kategori_seminar == "pendidikan") {
				kategoriSeminar = "Pembelajaran"
			}else {
				kategoriSeminar = "Pengabdian Masyarakat / RAKERCAB / RAKERDA"
			}
			$("#textIdSeminarAcc").val(idSeminar)
			$("#textJudulSeminarAcc").val(data.judul_seminar)
			$("#textSKPPeserta").val(data.skp_peserta)
			$("#textSKPModerator").val(data.skp_moderator)
			$("#textSKPPanitia").val(data.skp_panitia)
			$("#textSKPNarasumber").val(data.skp_narasumber)
			$("#textNomorSK").val(data.nomor_sk)
			$("#textSKPAcc").val("")
			$("#textSKPPesertaOnline").val("")
			$("#textHiddenKategori").val(data.kategori_seminar)
			$("#textKategoriSeminarAcc").val(kategoriSeminar)
			if (data.kategori_seminar == "pendidikan") {
				document.getElementById("container-sk-pd").classList.add("d-none")
				document.getElementById("container-surat-pd").classList.remove("d-none")
			}else{
				document.getElementById("container-sk-pd").classList.remove("d-none")
				document.getElementById("container-surat-pd").classList.add("d-none")
			}
			$("#textPesertaSeminarAcc").val(data.kategori_peserta)

			if (data.kategori_peserta == "hybrid") {
				$("#skp_peserta_title").html("SKP Peserta Offline")
				document.getElementById("container_skp_online").classList.remove('d-none');
			}else{
				$("#skp_peserta_title").html("SKP Peserta")
				document.getElementById("container_skp_online").classList.add('d-none');
			}


			let valueMenimbangTemplate = `-`

			let valueMengingat = `-`

			tinymce.get('textMenimbang').setContent(valueMenimbangTemplate);

			tinymce.get('textMengingat').setContent(valueMengingat);


		});
 
		const eventAccSeminar = (e)=>{
			e.preventDefault()
			let skp_peserta = $("#textSKPPeserta").val()
			let skp_moderator = $("#textSKPModerator").val()
			let skp_narasumber = $("#textSKPNarasumber").val()
			let skp_panitia = $("#textSKPPanitia").val()
			let idSeminarAcc= $("#textIdSeminarAcc").val()
			let nomorSK = ''
			let nomorSuratSKP = ''
			let kategoriSeminar = $("#textKategoriSeminarAcc").val()
			let kategoriSeminarHidden = $("#textHiddenKategori").val()
			let biayaPenerbitanSKP = 0
			let statusPembayaran = 'unpaid'
			biayaPenerbitanSKP = 0

			let pesertaSeminar = $("#textPesertaSeminarAcc").val()
			if (kategoriSeminar == 'Bakti Sosial / Pengabdian Masyarakat' || kategoriSeminarHidden == "baksos") {
				if (biayaPenerbitanSKP <= 0) {
					statusPembayaran = 'paid'
				}
			}
			if (kategoriSeminar == "Bakti Sosial / Pengabdian Masyarakat" || kategoriSeminarHidden == "baksos" ) {

				nomorSK = $("#textNomorSK").val()
				var textMenimbang = tinymce.get('textMenimbang').getContent();
				var textMengingat = tinymce.get('textMengingat').getContent();
				nomorSuratSKP = null
				if (nomorSK == "" || nomorSK == null) {
					NotificationModal.show("Nomor SK harus di isi" ,"warning")
					return
				}
			}else{
				nomorSK = null
				var textMenimbang = null;
				var textMengingat = null;
				nomorSuratSKP = $("#textNomorSuratRekom").val()
				if (nomorSuratSKP == "" || nomorSuratSKP == null) {
					NotificationModal.show("Nomor Surat Rekomendasi harus di isi" ,"warning")
					return
				}
			}
				
			if (skp_peserta == "" || skp_panitia == "" || skp_moderator == "" || skp_narasumber == "") {
				NotificationModal.show("Jumlah skp yang di setujui harus di isi" ,"warning")
			}else{
				swal.fire({
					title: 'Acc Seminar Ini?',
					html: "Anda ingin Menyetujui Seminar ini ?\ " + $("#textJudulSeminarAcc").val(),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonColor: ' #d33',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Setujui!'
				}).then(async (result) => {
					if (result.value) {
						mRefresh.refresh();
						let skpPesertaOnlineValue = null
						if (pesertaSeminar == "hybrid") {
							skpPesertaOnlineValue = $("#textSKPPesertaOnline").val()
						}
				
						const deleteMateriSeminar = await ApiSeminar.accSeminarPD({
							idSeminar: idSeminarAcc,
							skp_peserta : skp_peserta,
							skp_moderator : skp_moderator,
							skp_narasumber : skp_narasumber,
							skp_panitia : skp_panitia,
							nomor_sk : nomorSK,
							nomor_sr_pd : nomorSuratSKP,
							menimbang : textMenimbang,
							mengingat: textMengingat,
							biaya_penerbitan_seminar: biayaPenerbitanSKP,
							statusPembayaran: statusPembayaran,
							skp_peserta_online : skpPesertaOnlineValue
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Acc Seminar',
								text: "Data berhasil disetujui",
								icon: 'success',
								showConfirmButton: false,
								timer: 1000
							});

							$("#tablePengajuanSeminar").DataTable().ajax.reload();
							$('#modalAccSeminar').modal("hide");
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 1000
							});
						}
						mRefresh.resolve();
					};
				});
			}
		}


		document.getElementById("btnAccSeminar").addEventListener("click",eventAccSeminar)


	},

	async _initKomentar(){
		let idPengajuan = ''
		$('#tablePengajuanSeminar tbody').on('click', '#revise', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			idPengajuan = data.id;
			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
			}


			let response = await ApiSeminar.getCommentar(UserID, "permohonan_seminar", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}
		});

		$("#btnSendKomentarPengajuan").click(async function (e) {
			$(this).html("Loading...");
			e.stopPropagation()
			let comment = $("#komentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const revisePengajuanSeminar = await ApiSeminar.reviseSeminarPD({
					idSeminar: idPengajuan,
					comment: comment
				})
				if (revisePengajuanSeminar.status_code != 200) {
					NotificationModal.show(`${revisePengajuanSeminar.message}`, 'error');
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanSeminar').DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
				} else {
					NotificationModal.show(`${revisePengajuanSeminar.message}`, 'success');
					$("#komentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanSeminar').DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
				}
			}
		});



		$("#btnCancel").click(function () {
			$("#komentarPengajuan").val("")
		});
	},

	
	async _initApprovePembayaran(){
		let idPengajuan = ''
		$('#tablePengajuanSeminar tbody').on('click', '#approvePembayaran', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;

			const approvePembayaran = await ApiSeminar.accPembayaranPD({
				idSeminar: idPengajuan,
				statusPembayaran: 'paid'
			})

			if (approvePembayaran.status_code == 200) {
				NotificationModal.show('Pembayaran berhasil di approve','success');
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}else {
				NotificationModal.show(approvePembayaran.message, 'error')
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}
		});

	},

	
	async _initApproveAgreement(){
		let idPengajuan = ''
		$('#tablePengajuanSeminar tbody').on('click', '#approveTTD', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;

			const approveTTD = await ApiSeminar.accTTD({
				idSeminar: idPengajuan,
				status: 'signed'
			})

			if (approveTTD.status_code == 200) {
				NotificationModal.show('SK berhasil di tanda tangani','success');
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}else {
				NotificationModal.show(approveTTD.message, 'error')
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}
		});

		$('#tablePengajuanSeminar tbody').on('click', '#reviseTTD', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;

			const approveTTD = await ApiSeminar.accTTD({
				idSeminar: idPengajuan,
				status: 'unsigned'
			})

			if (approveTTD.status_code == 200) {
				NotificationModal.show('Tanda tangan berhasil di batalkan','success');
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}else {
				NotificationModal.show(approveTTD.message, 'error')
				$("#tablePengajuanSeminar").DataTable().ajax.reload();
			}
		});
	},

	async _initRevisiPembayaran(){
		let idPengajuan = ''
		$('#tablePengajuanSeminar tbody').on('click', '#revisePayment', async function () {
			$("#btnSendKomentarPayment").html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			idPengajuan = data.id;
			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
			}


			let response = await ApiSeminar.getCommentar(UserID, "permohonan_seminar", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment-payment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment-payment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment-payment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment-payment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}
		});

		$("#btnSendKomentarPayment").click(async function (e) {
			$(this).html("Loading...");
			e.stopPropagation()
			let comment = $("#komentarPengajuanPayment").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const approvePembayaran = await ApiSeminar.accPembayaranPD({
					idSeminar: idPengajuan,
					statusPembayaran: 'unpaid',
					catatan: comment
				})
	
				if (approvePembayaran.status_code == 200) {
					NotificationModal.show('Pembayaran berhasil di reject','success');
					$("#tablePengajuanSeminar").DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuanPayment").val("")
					$('#commentModal').modal('hide');
				}else {
					NotificationModal.show(approvePembayaran.message, 'error')
					$("#tablePengajuanSeminar").DataTable().ajax.reload();
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuanPayment").val("")
					$('#commentModalPayment').modal('hide');
				}
			}
		});

	},
	
	async _formatNumber(elm){
		// Dapatkan elemen input
		const input = document.getElementById(elm);
		// const input = document.getElementById('textBiayaPenerbitanSKP');

		// Tambahkan event listener untuk memantau perubahan pada input
		input.addEventListener('input', function () {
		// Dapatkan nilai input
			let value = input.value;

			// Hapus semua karakter non-angka dari nilai input
			value = value.replace(/[^0-9]/g, '');

			// Gunakan fungsi Number untuk mengubah nilai menjadi angka
			const numberValue = Number(value);

			// Pastikan nilai yang diubah bukan NaN (Not-a-Number)
			if (!isNaN(numberValue)) {
				// Format nilai sebagai angka dengan tanda koma (contoh: 1,234)
				input.value = numberValue.toLocaleString('id-ID');
			} else {
				// Jika nilai tidak valid, kosongkan input
				input.value = '';
			}

		});

	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},


}





export default PermohonanSeminarPdPresenter;