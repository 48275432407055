import CONFIG from './config.js';

const STATISTIK_ENDPOINT = {
	//for all endpoint
	SEND_BUKTI_BAYAR: `${CONFIG.BASE_URL}deposit/iuran/paid`,
	CANCEL_INVOICE: (id) => `${CONFIG.BASE_URL}deposit/iuran/cancel/${id}`,
	GET_COMMENTAR: (id,comment_for) => `${CONFIG.BASE_URL}comment/${comment_for}/${id}`,
	REQUEST_INVOICE: (role) => `${CONFIG.BASE_URL}deposit/iuran/${role}/request`,
	APPROVE_DEPOSIT: (role) => `${CONFIG.BASE_URL}deposit/iuran/${role}/approval`,
	GET_STATS_IURAN: (startDate,endDate,role) => `${CONFIG.BASE_URL}stats/iuran/${role}?start_date=${startDate}&end_date=${endDate}`,

	// stats for pc
	GET_STATS_PC: (startDate,endDate) => `${CONFIG.BASE_URL}deposit/iuran/pc/info?start_date=${startDate}&end_date=${endDate}`,
	GET_STATS_IURAN_PC: (startDate,endDate) => `${CONFIG.BASE_URL}stats/iuran/pc?start_date=${startDate}&end_date=${endDate}`,
	GET_LIST_SETOR:(startDate,endDate,status) => `${CONFIG.BASE_URL}deposit/iuran/pc/list?start_date=${startDate}&end_date=${endDate}&status=${status}`,
	GET_LIST_IURAN_PC:(startDate,endDate) => `${CONFIG.BASE_URL}invoice/membership/pd/report?&status=approve`,

	
	// stats for pd
	GET_STATS_PD: (startDate,endDate) => `${CONFIG.BASE_URL}deposit/iuran/pd/info?start_date=${startDate}&end_date=${endDate}`,
	REQUEST_INVOICE_PD: `${CONFIG.BASE_URL}deposit/iuran/pd/request`,
	GET_LIST_SETOR_PD_OUT_LIST:(startDate,endDate,status) => `${CONFIG.BASE_URL}deposit/iuran/pd/list/out?start_date=${startDate}&end_date=${endDate}&status=${status}`,
	GET_LIST_SETOR_IN:(status,idCabang) => `${CONFIG.BASE_URL}deposit/iuran/pd/list/in?status=${status}&id_cabang=${idCabang}`,
	SEND_COMMENT_PD: `${CONFIG.BASE_URL}comment/admin/pd`,

	
	// stats for pp
	GET_STATS_PP: (startDate,endDate) => `${CONFIG.BASE_URL}deposit/iuran/pp/info?start_date=${startDate}&end_date=${endDate}`,
	GET_STATS_IURAN_PP: (startDate,endDate) => `${CONFIG.BASE_URL}stats/iuran/pp?start_date=${startDate}&end_date=${endDate}`,
	REQUEST_INVOICE_PP: `${CONFIG.BASE_URL}deposit/iuran/pp/request`,
	GET_LIST_SETOR_PP_OUT_LIST:(startDate,endDate,status) => `${CONFIG.BASE_URL}deposit/iuran/pp/list/out?start_date=${startDate}&end_date=${endDate}&status=${status}`,
	GET_LIST_SETOR_IN_PP:(status,idDaerah) => `${CONFIG.BASE_URL}deposit/iuran/pp/list/in?status=${status}&id_daerah=${idDaerah}`,
	GET_LIST_IURAN_PD:(startDate,endDate) => `${CONFIG.BASE_URL}invoice/membership/pp/report-pd?pd_id=&pc_id=&status=`,
	SEND_COMMENT_PP: `${CONFIG.BASE_URL}comment/admin/pp`,

	DWONLOAD_DEPOSIT_PD:(start,length,status ,idCabang) => `${CONFIG.BASE_URL}deposit/iuran/pd/download?status=${status}&id_cabang=${idCabang}&start=${start}&length=${length}`,
	DWONLOAD_DEPOSIT_PP:(start,length,status ,idCabang) => `${CONFIG.BASE_URL}deposit/iuran/pp/download?status=${status}&id_daerah=${idCabang}&start=${start}&length=${length}`,
};

export default STATISTIK_ENDPOINT;
