import GeneralSettingPCPresenter from "../../../presenter/menu_pengurus_pc/general-setting-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const GeneralSettingPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <h3>General Setting Pengurus Cabang</h3>
        <div class="row">
            <div class="col-xl-4">
                <div class="card shadow mb-4">
                    <div class="card-header text-primary font-weight-bold">
                        Setting Iuran Anggota
                    </div>
                    <div class="card-body">
                        <form id="form-setting-iuran">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="alert alert-info">
                                        <strong>Informasi !</strong><br>
                                        Setting iuran anggota di isi dengan nominal iuran <strong>bulanan</strong>, untuk nominal disesuaikan dengan jumlah iuran bulanan di PC anda.
                                    </div>
                                    <div class="form-group">
                                        <div class="input-group input-group-lg">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="inputGroup-sizing-lg">Rp.</span>
                                            </div>
                                            <input type="text" class="form-control" aria-label="Rp" aria-describedby="inputGroup-sizing-sm" id="textNominalIuran">
                                        </div>
                                        <button type="button" class="form-control btn mt-2 btn-primary" id="btnUpdateIuran"><i class='fas fa-edit'></i> Update Iuran</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="card shadow mb-4">
                    <div class="card-header text-primary font-weight-bold">
                        Setting Data Umum
                    </div>
                    <div class="card-body">
                        <form id="form-general-setting">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Nama Kota</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textNamaKota" required placeholder="Nama Kota...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Alamat Cabang</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textAlamatCabang" required placeholder="Alamat Cabang..."></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Informasi Umum</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textInformasiUmum" required placeholder="Informasi Umum..."></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Telepon</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textTelepon" required placeholder="Telepon...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Email</label>
                                        <div class="col-sm-12">
                                            <input type="email" class="form-control" id="textEmailCabang" required placeholder="Email...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Faks</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textFaks" required placeholder="Faksimil...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Kode Pos</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textKodePos" required placeholder="Kode Pos...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">URL Maps</label>
                                        <div class="col-sm-12">
                                            <input type="url" class="form-control" id="textUrlMaps" required placeholder="URL Maps...">
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Permohonan SIPTTK Kepada (Contoh: Dinas Kesehatan...)</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textSIPTTKKepada" required placeholder="Permohonan SIPTTK Kepada (Contoh: Dinas Kesehatan...)">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Permohonan SIPTTK Di (Contoh: Nama Kota...)</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textSIPTTKDi" required placeholder="Permohonan SIPTTK Di (Contoh: Nama Kota...)">
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Cetak Rekom SIPTTK Kirim Email</label>
                                        <div class="col-sm-12">
                                            <select class="form-control" id="cetakKirimEmail" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="1">Ya</option>
                                                <option value="0">Tidak</option>
                                            </select>
                                        </div>
                                    </div> --!>
                                    <hr>
                                    <div class="form-group row d-none">
                                        <label class="control-label col-sm-12 font-weight-bold">Permohonan STRTTK Kepada (Contoh: Dinas Kesehatan...)</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textSTRTTKKepada" required placeholder="Permohonan STRTTK Kepada (Contoh: Dinas Kesehatan...)">
                                        </div>
                                    </div>
                                    <div class="form-group row d-none">
                                        <label class="control-label col-sm-12 font-weight-bold">Permohonan STRTTK Di (Contoh: Nama Kota...)</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textSTRTTKDi" required placeholder="Permohonan STRTTK Di (Contoh: Nama Kota...)">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Tanda Tangan Digital</label>
                                        <div class="col-sm-12">
                                            <select class="form-control" id="strttkTTDPC" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="1">Aktif</option>
                                                <option value="0">Tidak Aktif</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Informasi Rekening</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textInformasiRekening" required placeholder="Informasi Rekening...">
                                        </div>
                                    </div>
                                    <button type="button" class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdate"><i class='fas fa-edit'></i> Update Setting</button>
                                    <button type="button" class="form-control btn btn-sm btn-secondary" id="btnClear"><i class='fas fa-eraser'></i> Kosongkan Formulir</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        
        `;
		return view;
	  },

	async afterRender() {
		await GeneralSettingPCPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default GeneralSettingPC;