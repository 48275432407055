import DokumenValidPresenter from "../../presenter/kode-wilayah-presenter.js";

const DokumenValid = {
    async render() {
        return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-12 col-lg-9 col-md-9">
      
                    <div class="card shadow-lg my-2">
                        <div class="card-header text-primary font-weight-bold">
                            Daftar Kode Wilayah
                        </div>
                        <div class="card-body" style="z-index:10000;">
                            <!-- Nested Row within Card Body -->
                            <div class="alert alert-info">
                                <strong>Informasi:</strong>
                                <p>Untuk dapat melihat kode wilayah <strong>Keanggotaan PD dan PC anda</strong>, silakan anda pilih <strong>Provinsi</strong> terlebih dahulu, <strong>terimakasih.</strong></p>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <label class="control-label text-primary">Pilih Provinsi</label>
                                    <select class="form-control" id="selectProvince">
                                        <option value="">Pilih Provinsi</option>
                                    </select>
                                </div>
                                <div class="col-lg-12">
                                    <table class="table table-bordered" id="tableKodeWilayah" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Kode Provinsi</th>
                                                <th>Kode Kabupaten/Kota</th>
                                                <th>Nama Kabupaten/Kota</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
        await DokumenValidPresenter.init();
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default DokumenValid;
  

