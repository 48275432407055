
const notFound = {
    async render() {
      return `  
      <div class="text-center">
        <div class="error mx-auto" data-text="Maaf !">Maaf !</div>
        <p class="lead text-gray-800 mb-5">Profile anda belum lengkap, silahkan lengkapi profile untuk mengakses fitur ini</p>
        <p class="text-gray-500 mb-0">Anda tidak memiliki akses kesini</p>
        <a href="#/">&larr; Back to Dashboard</a>
      </div>
          `;
    },
  
    async afterRender() {
      return;
    },

    _errorContent() {
  
    },

  };
  
  export default notFound;
  

