
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiAnggota from "../../api/data-anggota.js";


const PengurusPCPresenter = {

	async init() {
		await this._setView();
		await this._getPeriode();
		await this._getPengurus();
		await this._getAnggota();
		await this._createPengurus();
		await this._uploadTtd();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async _getPeriode(){
		const getPeriode = await ApiKepengurusanPC.getPeriode();

		let dataPeriode = getPeriode.data;

		
		var selectPeriode = document.getElementById("selectPeriode");
		var lengthOptionPekerjaan = selectPeriode.options.length;
		for (let i = lengthOptionPekerjaan-1; i >= 0; i--) {
			selectPeriode.options[i] = null;
		}
		
		if (dataPeriode == null) {
			document.getElementById("selectPeriode").add(new Option("Masa Bakti Kosong !" , ""))
		}else {
			document.getElementById("selectPeriode").add(new Option("Pilih Salah Satu" , ""))
			dataPeriode.forEach(data => {
				document.getElementById("selectPeriode").add(new Option(data.masa_bakti , data.id))
			});	
		}

		$("#selectPeriode").change(async function () {
			var valSelected = $(this).val();


		});
	},

    async _getPengurus(){
        let table = await $('#tablePengurus').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.PENGURUS}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'periode_kepengurusan' },
				{ data: 'user_name' },
				{ data: 'jabatan' },
				{ data: 'dokumen.foto', render: function (data) {
					var content = '';
					if (data == '' || data == null) {
						content = `-`;
					}else {
						content = `<img loading="lazy" src="${data}" width="40px">`
					}

					return content
				} },
				{ data: 'dokumen.ttd', render: function (data) {
					var content = '';
					if (data == '' || data == null) {
						content = `<span class="badge badge-danger">Belum</span>`;
					}else {
						content = `<span class="badge badge-primary">Sudah</span>`
					}

					return content
				} },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [3,4],
					"visible": true,
					"searchable": false,
					className:'text-center'
				},
				{
					"targets": 5,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button class='mb-1 btn btn-primary btn-sm' id='ttd' title='Upload Tanda Tangan' data-toggle="modal" data-target="#modalUploadTtd" ><i class="fas fa-file-signature"></i></button>
						&nbsp;
						<button class='mb-1 btn btn-warning btn-sm' id='edit' title='Edit'><i class='fas fa-edit'></i></button>
						&nbsp;
						<button class='mb-1 btn btn-danger btn-sm' id='hapus' title='Hapus'><i class='fas fa-trash'></i></button>
					</div>`
				},
				]
		});

		
		$('#tablePengurus tbody').on('click', '#edit', function () {

			$("#btnUpdate").show();
			$("#btnAdd").hide();
			const table = $('#tablePengurus').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHidden').val(data.id);
			$('#selectPeriode').val(data.periode_id);
			$('#selectAnggota').trigger('change');
			// $('#select2-selectAnggota-container').html(data.user_name);
			$('#selectAnggota').val(data.user_id);
			$('#textJabatan').val(data.jabatan);
			$('#textUrutTampil').val(data.urut_tampil);
			$('#selectPublishPendidikan').val(data.publish_pendidikan);
			$('#selectPublishOrganisasi').val(data.publish_organisasi);
			$('#selectPublishTempatKerja').val(data.publish_pekerjaan);

		});

		$('#tablePengurus tbody').on('click', '#ttd', function () {
			const table = $('#tablePengurus').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			$("#idPengurus").val(id);

		});

		
		$('#tablePengurus tbody').on('click', '#hapus', async function () {
			const table = $('#tablePengurus').DataTable();
			let data = table.row($(this).parents('tr')).data();

			
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Pengurus </strong>: " + data.user_name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deletePengurus = await ApiKepengurusanPC.deletePengurus({
						id: id
					});

					if (deletePengurus.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePengurus.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePengurus').DataTable().ajax.reload();
						eventClear()
						
					}else {
						swal.fire({
							title: 'Error',
							text: deletePengurus.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		
		const eventClear = async (e) => {
			document.getElementById("form-pengurus").reset();
			$("#btnAdd").show();
			$("#btnUpdate").hide();
		}

    },

	async _getAnggota(){
		
		$("#selectAnggota").select2({
			ajax : {
				delay: 250,
				url : API_ENDPOINT_PENGURUS_PC.ANGGOTA(1,1),
				dataType : 'json',
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				data: function (params) {
				  return {
					"search[value]" : params.term, // search term
					"start" : 0,
					"columns[0][data]": "name",
					"columns[1][data]": "email",
					"columns[2][data]": "ktan",
					page: params.page
				  };
				},
				processResults: function(data) {
				  // Process the remote data to match the format Select2 expects
				  return {
					results: data.data.map(function(item) {
					  return { id: item.id, text: item.name };
					})
				  };
				},
				cache: true
			},

			placeholder:"Ketik untuk mencari data...",
		});



		// const getAnggota = await ApiKepengurusanPC.getAnggota({
		// 	status_regis: 1,
		// 	status_ktan: 1
		// });

		// let dataAnggota = getAnggota.data;

		
		// var selectAnggota = document.getElementById("selectAnggota");
		// var lengthOptionPekerjaan = selectAnggota.options.length;
		// for (let i = lengthOptionPekerjaan-1; i >= 0; i--) {
		// 	selectAnggota.options[i] = null;
		// }
		
		// if (dataAnggota == null) {
		// 	document.getElementById("selectAnggota").add(new Option("Anggota Kosong !" , ""))
		// }else {
		// 	document.getElementById("selectAnggota").add(new Option("Pilih Salah Satu" , ""))
		// 	dataAnggota.forEach(data => {
		// 		document.getElementById("selectAnggota").add(new Option(data.name , data.id))
		// 	});	
		// }
	},

    async _createPengurus(){
        $("#btnUpdate").hide();
        const eventCreatePengurus = async (e) => {


			e.preventDefault();

			if ($("#btnAdd").is(':visible')) {
				document.getElementById("btnAdd").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPengurus = await ApiKepengurusanPC.createPengurus({
					periode_id: document.getElementById('selectPeriode').value,
					user_id: document.getElementById('selectAnggota').value,
					jabatan: document.getElementById('textJabatan').value,
					urut_tampil: document.getElementById('textUrutTampil').value,
					publish_pendidikan: document.getElementById('selectPublishPendidikan').value,
					publish_organisasi: document.getElementById('selectPublishOrganisasi').value,
					publish_pekerjaan: document.getElementById('selectPublishTempatKerja').value,
				});
				if (createPengurus.status_code != 201) {
					NotificationModal.show(`${createPengurus.message}`, 'error');
					document.getElementById("btnAdd").innerHTML = `<i class="fas fa-add"></i> Tambah Pengurus`;
					$("#btnUpdate").hide();
				} else {
					NotificationModal.show(`${createPengurus.message}`, 'success');
					document.getElementById("btnAdd").innerHTML = `<i class="fas fa-add"></i> Tambah Pengurus`;
					$("#btnUpdate").hide();
					$('#tablePengurus').DataTable().ajax.reload();
					eventClear();
					$("#selectAnggota").val(null).trigger('change');
				}

			}else {
				
				document.getElementById("btnUpdate").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updatePengurus = await ApiKepengurusanPC.updatePengurus({
					id : document.getElementById('textHidden').value,
					periode_id: document.getElementById('selectPeriode').value,
					user_id: document.getElementById('selectAnggota').value,
					jabatan: document.getElementById('textJabatan').value,
					urut_tampil: document.getElementById('textUrutTampil').value,
					publish_pendidikan: document.getElementById('selectPublishPendidikan').value,
					publish_organisasi: document.getElementById('selectPublishOrganisasi').value,
					publish_pekerjaan: document.getElementById('selectPublishTempatKerja').value,

				});

				if (updatePengurus.status_code != 200) {
					NotificationModal.show(`${updatePengurus.message}`, 'error');
					$("#btnAdd").hide();
					document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Edit Pengurus`;
				} else {
					NotificationModal.show(`${updatePengurus.message}`, 'success');
					document.getElementById("btnUpdate").innerHTML = `<i class="fas fa-edit"></i> Edit Pengurus`;
					$("#btnAdd").hide();
					$('#tablePengurus').DataTable().ajax.reload();
					eventClear();
					$("#selectAnggota").val(null).trigger('change');
				}
			}
			

			e.preventDefault();

            
		}
		document.getElementById('form-pengurus').addEventListener('submit', eventCreatePengurus);

		const eventClear = async (e) => {
			document.getElementById("form-pengurus").reset();
			$("#btnAdd").show();
			$("#btnUpdate").hide();
		}

		
		document.getElementById('btnClear').addEventListener('click', eventClear);

    },

	async _uploadTtd(){
		let DzoneTTD = new Dropzone("#form-upload-ttd", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-ttd .dz-preview:hidden').remove()
					$('#form-upload-ttd .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-ttd").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			document.getElementById("btnSaveTtd").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let id = $("#idPengurus").val();
			const updateTTD = await ApiKepengurusanPC.updateTTD({
			id:id,
			dokumen : (DzoneTTD.files.length <= 0) ? null : await this.dataUrlToFile(DzoneTTD.files[0].dataURL ,DzoneTTD.files[0].name )
			});
			if (updateTTD.status_code != 200) {
				NotificationModal.show(`${updateTTD.message}`, 'error');
				document.getElementById("btnSaveTtd").innerHTML = `Save changes`
			} else {
				NotificationModal.show(`${updateTTD.message}`, 'success');
				document.getElementById("btnSaveTtd").innerHTML = `Save changes`
			}
			DzoneTTD.emit("removeThumbnailCustom");
			e.preventDefault();
		}
		document.getElementById('btnSaveTtd').addEventListener('click', eventUploadBerkas);
	},

	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},


}



export default PengurusPCPresenter;