import DashboardInformationPresenter from "../../presenter/dashboard-information-presenter.js";
import ImagePopUPInitiator from "../../presenter/pop-up-presenter.js";


const DashboardInformation = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <h1 class="h3 mb-2 text-gray-800">Pusat Informasi </h1>
        
        <div class="card">
        <div class="card-header font-weight-bold text-primary">
            Edaran
        </div>
        <div class="card-body">
        
            <div class="row">
               
                <div class="col-sm-12">
                <div class="container posts-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card mb-4">
                            <div class="card-body">
                            <div class="media" style="height:60px;">
                                <img src=\'./src/public/img/logo.png\';" id="show" class="d-block ui-w-40 rounded-circle " alt="">
                                <div class="media-body ml-3">
                                    Pafi Pusat
                                    <div class="text-muted small">3 days ago</div>
                                </div>
                                </div>
                                <p>
                                SURAT EDARAN<br>
                                Nomor 015/SE/PP PAFI/X/2022 <br>
                                TENTANG
                                NOMOR REKENING
                                </p>
                                <img loading="lazy"  class=" ui-bg-cover show-image-pop-up"  src=\'./src/public/img/edaran-rekening.png\';"  width="100%"/>
                            
                            </div>
                            <div class="card-footer">
                            
                                <a href="javascript:void(0)" class="d-inline-block text-muted d-none">
                                    <strong></strong> <small class="align-middle"></small>
                                </a>
                                <a href="javascript:void(0)" class="d-inline-block text-muted ml-3 d-none">
                                    <strong></strong> <small class="align-middle"></small>
                                </a>
                                <a href="javascript:void(0)" class="d-inline-block text-muted ml-3 d-none">
                                <small class="align-middle"></small>
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                   
                </div>
            </div>
        </div>
    </div>

        <div class="card mt-5">
            <div class="card-header font-weight-bold text-primary">
                Informasi Seminar
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <div class="card">
                            <div class="card-header font-weight-bold text-primary">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class="form-group font-weight-bold row">
                                            <label class="control-label col-sm-12">Kategori Seminar</label>
                                            <div class="col-sm-12">
                                                <select class="form-control" id="selectByKategori">
                                                    <option value="">Pilih Kategori</option>
                                                    <option value="pendidikan">Pembelajaran</option>
                                                    <option value="baksos">Pengabdian Masyarakat</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group row">
                                            <label class="control-label font-weight-bold col-sm-12">Wilayah Seminar</label>
                                            <div class="col-sm-12">
                                                <select class="form-control" id="selectByWilayah">
                                                    <option value="">Semua</option>
                                                    <option value="pc">Cabang</option>
                                                    <option value="pd">Daerah</option>
                                                    <option value="pp">Nasional</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="row containerState" style="width:100% margin:auto;" id="container_seminar">

                        </div>

                        <div class="col-sm-12 mt-2 text-center" >

                            <button class="btn mt-4 mb-4 btn-outline-primary" style="width:100% margin:auto">Muat Lebih Banyak</button>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade bd-example-modal-lg" id="modalViewDetail" tabindex="-1" role="dialog" aria-labelledby="modalViewDetail"
            aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Detail Seminar</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered" style="width:100%">
                            <tr>
                                <td valign="top" width="25%">Judul Seminar</td>
                                <td valign="top" width="1%">:</td>
                                <th valign="top" id="judulSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Tempat Seminar</td>
                                <td valign="top">:</td>
                                <th valign="top" id="kategoriSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%" id="jumlahSKPDetOflineTitle">Jumlah SKP yang akan didapatkan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="jumlahSKPDet"></th>
                            </tr>
                            <tr id="container-online">
                                <td valign="top" width="25%" >Jumlah SKP yang akan didapatkan Jika hadir secara Online</td>
                                <td valign="top">:</td>
                                <th valign="top" id="jumlahSKPDetOnline"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Ketua Pelaksana</td>
                                <td valign="top">:</td>
                                <th valign="top" id="ketuaSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top">Sekretaris</td>
                                <td valign="top">:</td>
                                <th valign="top" id="sekretarisSeminarDet"></th>
                             </tr>
                            <tr>
                                <td valign="top">Tanggal Pelaksanaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutTime"></th>
                            </tr>
                            <tr>
                                <td valign="top">Kepesertaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutPeserta"></th>
                            </tr>
                          
                        </table>
                        <input type="hidden" id="stateIdSeminarDetail" class="form-control">
                        <h6 class="font-weight-bold text-primary">Deskripsi Seminar</h6>
                        <div class="row" style="width:100%">
                            <div class="col-sm-12" id="textDeskripsiDetail" >
                                
                            </div>
                        </div>
                        <h6 class="font-weight-bold text-primary">Materi Seminar</h6>
                        <div class="row">
                            <div class="col-sm-12 overflow-auto">
                                <table class="table table-bordered" style="width:100%" id="tableMateri">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Judul Materi</th>
                                            <th>Moderator</th>
                                            <th>Pemateri</th>
                                            <th>Tanggal Seminar</th>
                                            <th>Tempat Penyelenggaraan</th>
                                        </tr>
                                    </thead>
                                    <tbody id="contentDet">

                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                       
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

        
        
        `;
		return view;
	  },

	async afterRender() {
        DashboardInformationPresenter.init()
        ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},
};

export default DashboardInformation;