
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiAnggota from "../../api/data-anggota.js";


const PeriodeKepengurusanPCPresenter = {

	async init() {
		await this._setView();
		await  this._getPeriodeKepengurusan();
		await  this._createPeriodeKepengurusan();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

    async _getPeriodeKepengurusan(){
        let table = await $('#tablePeriode').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.PERIODE_KEPENGURUSAN}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'masa_bakti' },
				{ data: 'keterangan' },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 2,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button class='mb-1 btn btn-warning btn-sm' id='edit' title='Edit'><i class='fas fa-edit'></i></button>
                        &nbsp;
						<button class='mb-1 btn btn-danger btn-sm' id='hapus' title='Hapus'><i class='fas fa-trash'></i></button>
					</div>`
				},
				]
		});

		
		$('#tablePeriode tbody').on('click', '#edit', function () {
			$("#btnUpdatePeriode").show();
			$("#btnAddPeriode").hide();
			const table = $('#tablePeriode').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenPeriode').val(data.id);
			$('#textPeriode').val(data.masa_bakti);
			$('#textKeterangan').val(data.keterangan);

		});

		
		$('#tablePeriode tbody').on('click', '#hapus', async function () {
			const table = $('#tablePeriode').DataTable();
			let data = table.row($(this).parents('tr')).data();

			
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Masa Bakti </strong>: " + data.masa_bakti,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deletePendidikan = await ApiKepengurusanPC.deletePeriode({
						id: id
					});

					if (deletePendidikan.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePendidikan.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePeriode').DataTable().ajax.reload();
						eventClear()
						
					}else {
						swal.fire({
							title: 'Error',
							text: deletePendidikan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		
		const eventClear = async (e) => {
			document.getElementById("form-periode").reset();
			$("#btnAddPeriode").show();
			$("#btnUpdatePeriode").hide();
		}

    },

    async _createPeriodeKepengurusan(){
        $("#btnUpdatePeriode").hide();
        const eventCreatePeriode = async (e) => {


			e.preventDefault();

			if ($("#btnAddPeriode").is(':visible')) {
				document.getElementById("btnAddPeriode").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPeriode = await ApiKepengurusanPC.createPeriode({
					masa_bakti: document.getElementById('textPeriode').value,
					keterangan: document.getElementById('textKeterangan').value,
				});
				if (createPeriode.status_code != 201) {
					NotificationModal.show(`${createPeriode.message}`, 'error');
					document.getElementById("btnAddPeriode").innerHTML = `<i class="fas fa-add"></i> Tambah Periode`;
					$("#btnUpdatePeriode").hide();
				} else {
					NotificationModal.show(`${createPeriode.message}`, 'success');
					document.getElementById("btnAddPeriode").innerHTML = `<i class="fas fa-add"></i> Tambah Periode`;
					$("#btnUpdatePeriode").hide();
					$('#tablePeriode').DataTable().ajax.reload();
					eventClear();
				}

			}else {
				
				document.getElementById("btnUpdatePeriode").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updatePeriode = await ApiKepengurusanPC.updatePeriode({
					id : document.getElementById('textHiddenPeriode').value,
					masa_bakti: document.getElementById('textPeriode').value,
					keterangan: document.getElementById('textKeterangan').value,

				});

				if (updatePeriode.status_code != 200) {
					NotificationModal.show(`${updatePeriode.message}`, 'error');
					$("#btnAddPeriode").hide();
					document.getElementById("btnUpdatePeriode").innerHTML = `<i class="fas fa-edit"></i> Edit Periode`;
				} else {
					NotificationModal.show(`${updatePeriode.message}`, 'success');
					document.getElementById("btnUpdatePeriode").innerHTML = `<i class="fas fa-edit"></i> Edit Periode`;
					$("#btnAddPeriode").hide();
					$('#tablePeriode').DataTable().ajax.reload();
					eventClear();
				}
			}
			

			e.preventDefault();

            
		}
		document.getElementById('form-periode').addEventListener('submit', eventCreatePeriode);

		const eventClear = async (e) => {
			document.getElementById("form-periode").reset();
			$("#btnAddPeriode").show();
			$("#btnUpdatePeriode").hide();
		}

		
		document.getElementById('btnClearPeriode').addEventListener('click', eventClear);

    }


}



export default PeriodeKepengurusanPCPresenter;