import ApiAnggota from "../../api/data-anggota.js";
import Autentication from "../../utils/autentication.js";

const SideBar = {
	async init() {
		let isLogin = await Autentication.isLogin()
		let role = await Autentication.getRole()
		let dataPD = await Autentication.getPDID();
		var sortedArray = []
		  if (isLogin) {
			const customOrder = ["pp", "pd", "pc", "pendidikan_pp", "pendidikan_pd", "pendidikan_pc", "anggota"];

		var sortedArray = role.sort((a, b) => {
		return customOrder.indexOf(a) - customOrder.indexOf(b);
		});
		  }
		let result = ``
		result += this._renderHeaderSideBar()
		
		if (role != null) {
			await sortedArray.forEach(element => {
				if (isLogin) {
					if (element == "admin") {
						result  += this._renderSuperMenu();
					}
					else if (element == "pp") {
					   result  += this._renderMenuPP();
					}else if (element == "pc") {
					   result  += this._renderMenuPC();
					}else if (element == "anggota") {
						if (dataPD != 32) {
							result  += this._renderMenuAnggota();
						}else {
							result  += this._renderMenuAnggotaJabar();
						}
					}else if (element == "pd") {
					   result  += this._renderMenuPD();
					}
					else if (element == "pendidikan_pp") {
					result  += this._renderMenuPendidikanPP();
					}
					else if (element == "admin_registrasi") {
					result  += this._renderMenuAdminRegistrasi();
					}
			   }
			});
		}
		result += this._renderFooterSideBar();
		document.getElementById('wrapper').insertAdjacentHTML("afterbegin", result);
	},

	_renderHeaderSideBar() {
		return /*html*/ `<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
		<a class="sidebar-brand d-flex align-items-center justify-content-center" href="#/">
			<div class="sidebar-brand-icon">
				<img loading="lazy" class="" src="./src/public/img/logo.png" width="35%"
				alt="...">
			</div>
		</a>

		<hr class="sidebar-divider my-0">

		<li class="nav-item active">
			<a class="nav-link sidebar-custom" href="#/">
				<i class="fa fa-user" aria-hidden="true"></i>
				<span>Profile Anda</span></a>
		</li>

		
		<li class="nav-item">
		<a class="nav-link sidebar-custom" href="#/dashboard-information">
		<i class="fa fa-info" aria-hidden="true"></i>
		<span>Pusat Informasi</span></a>
		</li>
		

		<hr class="sidebar-divider">`;
	},

	_renderFooterSideBar(){
		return `<hr class="sidebar-divider d-none d-md-block">

		<div class="text-center d-none d-md-inline">
			<button class="rounded-circle border-0" id="sidebarToggle"></button>
		</div>

	</ul>`
	},

	_renderMenuAnggota(){
		return /*html*/ `<div class="sidebar-heading">Menu Anggota</div>

		<li class="nav-item" id="menu-anggota">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerizinan"
				aria-expanded="true" aria-controls="collapsePerizinan">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>PERIZINAN</span>
			</a>
			<div id="collapsePerizinan" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Perizinan:</h6>
					<a class="collapse-item sidebar-custom item-master"  href="#/skp-saya">SKP SAYA</a>
					<a class="collapse-item sidebar-custom item-master"  href="#">P3AFI</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/serkom-skp">SERKOM SKP</a>
					<a class="collapse-item sidebar-custom item-master" href="#/rekom-strttk">REKOM STRTTK</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/rekom-sipttk">REKOM SIPTTK</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/pengajuan-mutasi">PENGAJUAN MUTASI</a>
				</div>
			</div>
		</li>

		
		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseIuranAnggota"
				aria-expanded="true" aria-controls="collapseIuranAnggota">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>IURAN ANGGOTA</span>
			</a>
			<div id="collapseIuranAnggota" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Iuran Anggota:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/iuran">IURAN ANGGOTA</a>
					<!--<a class="collapse-item sidebar-custom item-master"  href="#/">BUKTI PEMBAYARAN</a>-->
				</div>
			</div>
		</li>`;
	},

	
	_renderMenuAnggotaJabar(){
		return /*html*/ `<div class="sidebar-heading">Menu Anggota</div>

		<li class="nav-item" id="menu-anggota">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerizinan"
				aria-expanded="true" aria-controls="collapsePerizinan">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>PERIZINAN</span>
			</a>
			<div id="collapsePerizinan" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Perizinan:</h6>
					<a class="collapse-item sidebar-custom item-master"  href="#/skp-saya">SKP SAYA</a>
					<a class="collapse-item sidebar-custom item-master"  href="#">P3AFI</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/serkom-skp">SERKOM SKP</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/kecukupan-skp">KECUKUPAN SKP</a>
					<a class="collapse-item sidebar-custom item-master" href="#/rekom-strttk">REKOM STRTTK</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/rekom-sipttk">REKOM SIPTTK</a>
					<a class="collapse-item sidebar-custom item-master"  href="#/pengajuan-mutasi">PENGAJUAN MUTASI</a>
				</div>
			</div>
		</li>

		
		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseIuranAnggota"
				aria-expanded="true" aria-controls="collapseIuranAnggota">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>IURAN ANGGOTA</span>
			</a>
			<div id="collapseIuranAnggota" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Iuran Anggota:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/iuran">IURAN ANGGOTA</a>
					<!--<a class="collapse-item sidebar-custom item-master"  href="#/">BUKTI PEMBAYARAN</a>-->
				</div>
			</div>
		</li>`;
	},

	_renderMenuPP(){
		return /*html*/ `
		<div class="sidebar-heading">Menu Admin PP</div>
		<li class="nav-item">
			<a class="nav-link sidebar-custom d-none" href="#/kelola-users"><i class="fas fa-fw fa-clipboard" aria-hidden="true"></i><span> KELOLA USERS</span></a>
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKeanggotaanPP"
				aria-expanded="true" aria-controls="collapseKeanggotaanPP">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>KEANGGOTAAN</span>
			</a>
			<div id="collapseKeanggotaanPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Keanggotaan:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/anggota-pp">KTAN & REGISTRASI</a>
					<a class="collapse-item sidebar-custom item-master" href="#/kelola-anggota-pp">DATA ANGGOTA</a>
				</div>
			</div>
		</li>
		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKepengurusanPP"
				aria-expanded="true" aria-controls="collapseKepengurusanPP">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>KEPENGURUSAN</span>
			</a>
			<div id="collapseKepengurusanPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Kepengurusan:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/periode-kepengurusan-pp">MASA BAKTI<br>KEPENGURUSAN</a>
					<a class="collapse-item sidebar-custom item-master" href="#/pengurus-pp">PENGURUS PAFI</a>
				</div>
			</div>
		</li>
		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKebendaharaanPP"
				aria-expanded="true" aria-controls="collapseKebendaharaanPP">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>KEBENDAHARAAN</span>
			</a>
			<div id="collapseKebendaharaanPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Kebendaharaan:</h6>
					<a class="collapse-item sidebar-custom item-master"  href="#/statistic-iuran-pp">DATA SHARING PD</a>
					<!--<a class="collapse-item sidebar-custom item-master"  href="#/request-blangko-pp">REQUEST BLANGKO</a>-->
				</div>
			</div>
		</li>
		
		<li class="nav-item">
		<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSeminarPP"
			aria-expanded="true" aria-controls="collapseSeminarPP">
			<i class=" fas fa-fw fa-clipboard"></i>
			<span>SEMINAR</span>
		</a>
		<div id="collapseSeminarPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			<div class="bg-white py-2 collapse-inner rounded">
				<h6 class="collapse-header">Menu Seminar:</h6>
				<a class="collapse-item sidebar-custom item-master" href="#/pengajuan-seminar-pp">FORMULIR PENGAJUAN<br>SEMINAR</a>
				<a class="collapse-item sidebar-custom item-master" href="#/data-permohonan-seminar-pp">DATA PERMOHONAN<br>SEMINAR</a>
			</div>
		</div>

		<li class="nav-item">
		<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBeritaLainPP"
			aria-expanded="true" aria-controls="collapseBeritaLainPP">
			<i class=" fas fa-fw fa-clipboard"></i>
			<span>INFORMASI LAIN</span>
		</a>
		<div id="collapseBeritaLainPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			<div class="bg-white py-2 collapse-inner rounded">
				<h6 class="collapse-header">Menu Berita Lain:</h6>
				<a class="collapse-item sidebar-custom item-master" href="#/berita-pp">BERITA</a>
				<a class="collapse-item sidebar-custom item-master" href="#/edaran-pp">EDARAN</a>
			</div>
		</div>
	</li>
		
		<!-- <li class="nav-item">
		
			<a class="nav-link" href="#/mutasi-manual-pc">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>MUTASI MANUAL</span>
			</a>
		</li> -->
		

		<li class="nav-item">
		<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePengaturanPP"
			aria-expanded="true" aria-controls="collapsePengaturanPP">
			<i class=" fas fa-fw fa-clipboard"></i>
			<span>PENGATURAN PP</span>
		</a>
		<div id="collapsePengaturanPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			<div class="bg-white py-2 collapse-inner rounded">
				<h6 class="collapse-header">Menu Pengaturan:</h6>
				<a class="collapse-item sidebar-custom item-master" href="#/assign-role">SETTING ROLE USER</a>
				<a class="collapse-item sidebar-custom item-master" href="#/set-akses-pp">SETTING AKSES PD PC</a>
				<a class="collapse-item sidebar-custom item-master" href="#/general-setting-pp">SETTING BIAYA <br></a>
			</div>
		</div>
		</li>
		`;
	},

	_renderMenuPD(){
		return /*html*/ `
		<div id="menu-pd" data-locked-initial="false">
		<div class="sidebar-heading">Menu Admin PD</div>


		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKeanggotaanPD"
				aria-expanded="true" aria-controls="collapseKeanggotaanPD">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>KEANGGOTAAN</span>
			</a>
			<div id="collapseKeanggotaanPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Keanggotaan:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/anggota-pd">Anggota</a>
					<a class="collapse-item sidebar-custom item-master" href="#/skp-anggota-pd">SKP</a>
					<a class="collapse-item sidebar-custom item-master" href="#/serkom-anggota-pd">SERKOM</a>
					<a class="collapse-item sidebar-custom item-master" href="#/strttk-anggota-pd">REKOM STRTTK</a>
				</div>
			</div>
		</li>
		<li class="nav-item">
			<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKepengurusanPD"
				aria-expanded="true" aria-controls="collapseKepengurusanPD">
				<i class=" fas fa-fw fa-clipboard"></i>
				<span>KEPENGURUSAN</span>
			</a>
			<div id="collapseKepengurusanPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
				<div class="bg-white py-2 collapse-inner rounded">
					<h6 class="collapse-header">Menu Kepengurusan:</h6>
					<a class="collapse-item sidebar-custom item-master" href="#/periode-kepengurusan-pd">MASA BAKTI<br>KEPENGURUSAN</a>
					<a class="collapse-item sidebar-custom item-master" href="#/pengurus-pd">PENGURUS PAFI</a>
				</div>
			</div>
		</li>
		
		<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMutasiPD"
						aria-expanded="true" aria-controls="collapseMutasiPD">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>MUTASI / LOLOS BUTUH</span>
					</a>
					<div id="collapseMutasiPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Mutasi:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/rekom-mutasi-pd">REKOMENDASI<BR>MUTASI / LOLOS BUTUH</a>
						</div>
					</div>
				</li>
				
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKebendaharaanPD"
						aria-expanded="true" aria-controls="collapseKebendaharaanPD">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>KEBENDAHARAAN</span>
					</a>
					<div id="collapseKebendaharaanPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Kebendaharaan:</h6>
							<a class="collapse-item sidebar-custom item-master"  href="#/statistic-iuran-pd">DATA SHARING PC</a>
							<a class="collapse-item sidebar-custom item-master"  href="#/sharing-profit-pd">FORMULIR SETOR</a>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSeminarPD"
						aria-expanded="true" aria-controls="collapseSeminarPD">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>SEMINAR</span>
					</a>
					<div id="collapseSeminarPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Seminar:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/pengajuan-seminar-pd">FORMULIR PENGAJUAN<br>SEMINAR</a>
							<a class="collapse-item sidebar-custom item-master" href="#/data-permohonan-seminar-pd">DATA PERMOHONAN<br>SEMINAR</a>
						</div>
					</div>
				</li>
				<!--<li class="nav-item">
					<a class="nav-link sidebar-custom" href="#/request-blangko"><i class="fas fa-fw fa-clipboard" aria-hidden="true"></i><span> REQUEST BLANGKO</span></a>
				</li>-->
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePengaturanPD"
						aria-expanded="true" aria-controls="collapsePengaturanPD">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>PENGATURAN PD</span>
					</a>
					<div id="collapsePengaturanPD" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Pengaturan:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/general-setting-pd">GENERAL SETTING PD</a>
							<a class="collapse-item sidebar-custom item-master" href="#/set-akses-pd">SETTING AKSES PC</a>
						</div>
					</div>
				</li>
				<hr class="sidebar-divider my-0">
				<br>
				</div>
		`;
	},

	_renderMenuPC() {
		return /*html*/ `
				<div id="menu-pc" data-locked-initial="false">
				<div class="sidebar-heading">Menu Admin PC</div>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKeanggotaanPC"
						aria-expanded="true" aria-controls="collapseKeanggotaanPC">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>KEANGGOTAAN</span>
					</a>
					<div id="collapseKeanggotaanPC" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Keanggotaan:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/anggota-pc">Anggota</a>
							<a class="collapse-item sidebar-custom item-master" href="#/skp-anggota-pc">SKP</a>
							<a class="collapse-item sidebar-custom item-master" href="#/serkom-anggota-pc">SERKOM</a>
							<a class="collapse-item sidebar-custom item-master" href="#/strttk-anggota-pc">REKOM STRTTK</a>
							<a class="collapse-item sidebar-custom item-master" href="#/sipttk-anggota-pc">REKOM SIPTTK</a>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKepengurusanPC"
						aria-expanded="true" aria-controls="collapseKepengurusanPC">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>KEPENGURUSAN</span>
					</a>
					<div id="collapseKepengurusanPC" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Kepengurusan:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/periode-kepengurusan-pc">MASA BAKTI<br> KEPENGURUSAN</a>
							<a class="collapse-item sidebar-custom item-master" href="#/pengurus-pc">PENGURUS PAFI</a>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseKebendaharaanPC"
						aria-expanded="true" aria-controls="collapseKebendaharaanPC">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>KEBENDAHARAAN</span>
					</a>
					<div id="collapseKebendaharaanPC" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Kebendaharaan:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/iuran-anggota-pc">IURAN ANGGOTA</a>
							<a class="collapse-item sidebar-custom item-master"  href="#/sharing-profit-pc">SHARING IURAN</a>
						</div>
					</div>
				</li>
				
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMutasiPC"
						aria-expanded="true" aria-controls="collapseMutasiPC">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>MUTASI/LOLOS BUTUH</span>
					</a>
					<div id="collapseMutasiPC" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Mutasi:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/rekom-mutasi-pc">REKOMENDASI<br>MUTASI / LOLOS BUTUH</a>
							<a class="collapse-item sidebar-custom item-master"  href="#/syarat-mutasi-pc">SYARAT BERKAS MUTASI</a>
						</div>
					</div>
				</li>
				
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSeminarPC"
						aria-expanded="true" aria-controls="collapseSeminarPC">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>SEMINAR</span>
					</a>
					<div id="collapseSeminarPC" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Seminar :</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/pengajuan-seminar-pc">PENGAJUAN SEMINAR</a>
						</div>
					</div>
				</li>
				<hr class="sidebar-divider my-0">
				<li class="nav-item">
					<a class="nav-link sidebar-custom" href="#/general-setting"><i class="fa fa-cogs" aria-hidden="true"></i><span>GENERAL SETTING PC</span></a>
				</li>
				<hr class="sidebar-divider my-0">
				<br>
				</div>
				`;
	},

	_renderMenuPendidikanPP() {
		return /*html*/ `
				<div class="sidebar-heading">Menu Pendidikan PP</div>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePendidikanSeminarPP"
						aria-expanded="true" aria-controls="collapsePendidikanSeminarPP">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>SEMINAR</span>
					</a>
					<div id="collapsePendidikanSeminarPP" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Seminar:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/pengajuan-seminar-pp">FORMULIR PENGAJUAN<br>SEMINAR</a>
							<a class="collapse-item sidebar-custom item-master" href="#/data-permohonan-seminar-pp">DATA PERMOHONAN<br>SEMINAR</a>
						</div>
					</div>
				</li>
				`;
	},

	
	_renderMenuAdminRegistrasi() {
		return /*html*/ `
				<div class="sidebar-heading">Menu Registrasi</div>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAdminRegist"
						aria-expanded="true" aria-controls="collapseAdminRegist">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span>REGISTRASI</span>
					</a>
					<div id="collapseAdminRegist" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Registrasi:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/registrasi-anggota-baru"> REGISTRASI<br>ANGGOTA BARU</a>
							<!--<a class="collapse-item sidebar-custom item-master" href="#/registrasi-anggota-lama">REGISTRASI<br>ANGGOTA LAMA</a>-->
						</div>
					</div>
				</li>
				`;
	},

	_renderSuperMenu() {
		return /*html*/ `
				<div class="sidebar-heading">Menu Super Admin</div>
				<li class="nav-item">
					<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSuperAdmin"
						aria-expanded="true" aria-controls="collapseSuperAdmin">
						<i class=" fas fa-fw fa-clipboard"></i>
						<span> Aktivitas </span>
					</a>
					<div id="collapseSuperAdmin" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div class="bg-white py-2 collapse-inner rounded">
							<h6 class="collapse-header">Menu Aktivitas:</h6>
							<a class="collapse-item sidebar-custom item-master" href="#/log-aktivitas"> Log Aktivitas User</a>
						</div>
					</div>
				</li>
				`;
	},

	activateClick() {


	},
}


export default SideBar;
