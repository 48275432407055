
import CONFIG from '../config/globals/config.js';
import API_PENGURUS_PC_ENDPOINT from '../config/globals/menu_pengurus_pc_endpoint.js';
import API_ENDPOINT_PENGURUS_PC from "../config/globals/menu_pengurus_pc_endpoint.js";
import SEMINAR_ENPOINT from '../config/globals/seminar_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';
import BERITA_END_POINT from '../config/globals/berita_endpoint.js';

class ApiBerita {

	
	static async sendBerita({
		title,
		category,
		content,
		fileList
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("title", title);
		formdata.append("category", category);
		formdata.append("content", content);

		for (let index = 0; index < fileList.length; index++) {
			const file = fileList[index];
			formdata.append(`attachments[]`, file);
		}

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}

		console.log(pairing)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.SEND_BERITA, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async editBerita({
		title,
		category,
		content,
		fileList,
		idValue
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var raw = null
		if (fileList.length == 0) {
			raw = JSON.stringify({
				"title": title,
				"category": category,
				"content" : content
			});
		}else{
			raw = JSON.stringify({
				"title": title,
				"category": category,
				"content" : content,
				"attachments" : fileList
			});
		}

		console.log(raw)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.EDIT_BERITA(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async editBeritaFormData({
		title,
		category,
		content,
		fileList,
		idValue
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var formdata = new FormData();

		formdata.append("_method" , "PUT")
		formdata.append("title", title);
		formdata.append("category", category);
		formdata.append("content", content);

		for (let index = 0; index < fileList.length; index++) {
			const file = fileList[index];
			formdata.append(`attachments[]`, file);
		}

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}

		console.log(pairing)
		
		try {
			const fetchApi = await fetch(BERITA_END_POINT.EDIT_BERITA(idValue), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async getBeritaById({
		idValue
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(BERITA_END_POINT.BERITA_WITH_ID(idValue),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async deleteBerita({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.BERITA_WITH_ID(idValue), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async publishBerita({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "publish",
		  });
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.CHANGE_STATUS_BERITA(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async unPublishBerita({
		idValue,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "draft",
		  });
		try {
			const fetchApi = await fetchWithTimeout(BERITA_END_POINT.CHANGE_STATUS_BERITA(idValue), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
   
}


export default ApiBerita;