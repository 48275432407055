import ApiRegister from "../api/data-register.js";
import Autentication from "../utils/autentication.js";
import NotificationModal from "../utils/initial_notification.js";
import NikParser from "../utils/nik-parser.js";
import YoutubeHelper from "../utils/youtube-helper.js";

const RegisterInitiator = {

	async init() {
		await this._setView();
		await this.initProvinceValue();
		await this._initForm();
		await this._giveEventRegister();
	},

	async _setView() {
		document.getElementById('registerSelectPD').focus();
		document.getElementById("main-content").style.height = "";
		
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')

		const formattedNumberInput = document.getElementById('textKTAN');

		const blocks = [4, 8, 1, 6]; // Jumlah digit pada setiap blok
		const totalLength = blocks.reduce((total, num) => total + num, 0);
	  
		formattedNumberInput.addEventListener('input', (event) => {
		  const inputValue = event.target.value;
		  const validInput = inputValue.replace(/\D/g, ''); // Hapus karakter non-digit
	  
		  // Potong input jika panjang melebihi total panjang blok
		  const formattedValue = validInput.slice(0, totalLength);
	  
		  let formattedResult = '';
		  let index = 0;
	  
		  for (const block of blocks) {
			const chunk = formattedValue.slice(index, index + block);
			formattedResult += chunk;
	  
			if (formattedValue.length > index + block) {
			  formattedResult += '.';
			}
	  
			index += block;
		  }
	  
		  event.target.value = formattedResult;
	  
		  // Nonaktifkan input jika format sudah mencapai panjang yang diinginkan
		  if (formattedValue.length >= totalLength) {
			event.preventDefault();
		  }
		});


		document.getElementById("btnTutorialRegist").addEventListener('click', (e) =>{
			YoutubeHelper.showTutorial("Tutorial Registrasi Anggota Baru" , "//www.youtube.com/embed/cRYWIdUy9HM")
		})
		
	},
	
	async _initForm(){
		
		const eventSelectJenisKeanggotaan = async (e) => {
			var jenisKeanggotaan = document.getElementById('selectJenisKeanggotaan').value;
			
			if (jenisKeanggotaan == 'oldAnggota') {
				$("#groupKTAN").removeClass('d-none');
				$("#dzoneKTAN").removeClass('d-none');
				$("#startIuran").removeClass('d-none');
				$("#endIuran").removeClass('d-none');
				$("#dzoneBuktiIuran").removeClass('d-none');
				$("#expiredKTAN").removeClass('d-none');
			}else {
				$("#groupKTAN").addClass('d-none');
				$("#dzoneKTAN").addClass('d-none');
				$("#startIuran").addClass('d-none');
				$("#endIuran").addClass('d-none');
				$("#dzoneBuktiIuran").addClass('d-none');
				$("#expiredKTAN").addClass('d-none');
			}
		}
		document.getElementById("selectJenisKeanggotaan").addEventListener('change', eventSelectJenisKeanggotaan);
	},

	async initProvinceValue(){
		const dataProvince = await ApiRegister.getProvince()
		if(dataProvince.status_code == 200){
			dataProvince.data.forEach(data => {
				document.getElementById("registerSelectPD").add(new Option(data.province_name , data.province_code))
			});	
		}

		const eventChange = async (e) => {
			let valueSelected = document.getElementById("registerSelectPD").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)
			
			var selectPC = document.getElementById("registerSelectPC");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc-1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("registerSelectPC").add((new Option("Pilih Keanggotaan Wilayah PC","")))
			dataRegistry.data.forEach(data => {
				document.getElementById("registerSelectPC").add(new Option(data.regency_name , data.id))
			});	

			e.preventDefault();
		}

		document.getElementById("registerSelectPD").addEventListener("change", eventChange)

	},

	async _giveEventRegister (){
		let DzoneKTAN = new Dropzone("#form-upload-ktan", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-ktan .dz-preview:hidden').remove()
					$('#form-upload-ktan .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-ktan").classList.remove('dz-started');
				});
			}
		});

		let DzoneBuktiIuran = new Dropzone("#form-upload-bukti-iuran", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-bukti-iuran .dz-preview:hidden').remove()
					$('#form-upload-bukti-iuran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-bukti-iuran").classList.remove('dz-started');
				});
			}
		});
		const eventRegister = async (e) => {
			
			var jenisKeanggotaan = document.getElementById('selectJenisKeanggotaan').value;
			if (jenisKeanggotaan == "newAnggota") {
				e.preventDefault();
				let nikUser = document.getElementById('registerTextNIK').value;
				let isNikValid = NikParser(nikUser)
				if (isNikValid.isValid == false) {
					NotificationModal.show(`NIK Tidak valid`, 'warning');
					return
				}else{
					document.getElementById("btnRegister").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const dataRegister = await ApiRegister.Register({
						pd: document.getElementById('registerSelectPD').value,
						pc: document.getElementById('registerSelectPC').value,
						nama_lengkap: document.getElementById('registerTextNamaLengkap').value,
						nik: document.getElementById('registerTextNIK').value,
						no_hp: document.getElementById('registerNumberNoHP').value,
						email: document.getElementById('registerEmail').value,
						password: document.getElementById('registerTextPassword').value,
						confirm_password: document.getElementById('registerTextConfirmPassword').value,
						jenis_kelamin: document.getElementById('registerSelectJenisKelamin').value,
						tanggal_lahir: document.getElementById('registerTanggalLahir').value,
		
					});
		
					if (dataRegister.status_code != null) {
						NotificationModal.show(`${dataRegister.message}`, 'error');
						document.getElementById("btnRegister").innerHTML = `Register`;
					} else {
						NotificationModal.show(`${dataRegister.message}`, 'success');
						this._navigateToLogin()
					}
				}
				e.preventDefault();
			}else {
				

				e.preventDefault();
				let nikUser = document.getElementById('registerTextNIK').value;
				let isNikValid = NikParser(nikUser)
				if (isNikValid.isValid == false) {
					NotificationModal.show(`NIK Tidak valid`, 'warning');
					return
				}else{
					let valueKTAN = document.getElementById('textKTAN').value
					if (valueKTAN.length < 22) {
						NotificationModal.show(`NO KTAN ini : ${valueKTAN} Tidak valid , silahkan isi kembali.`, 'warning');
						return
					}
					document.getElementById("btnRegister").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const dataRegister = await ApiRegister.RegisterOld({
						ktan: document.getElementById('textKTAN').value,
						pd: document.getElementById('registerSelectPD').value,
						pc: document.getElementById('registerSelectPC').value,
						nama_lengkap: document.getElementById('registerTextNamaLengkap').value,
						nik: document.getElementById('registerTextNIK').value,
						no_hp: document.getElementById('registerNumberNoHP').value,
						email: document.getElementById('registerEmail').value,
						password: document.getElementById('registerTextPassword').value,
						confirm_password: document.getElementById('registerTextConfirmPassword').value,
						jenis_kelamin: document.getElementById('registerSelectJenisKelamin').value,
						tanggal_lahir: document.getElementById('registerTanggalLahir').value,
						old_ktan: (DzoneKTAN.files.length <= 0) ? null : await this.dataUrlToFile(DzoneKTAN.files[0].dataURL ,DzoneKTAN.files[0].name ),
						ktan_expires_at: document.getElementById('masaBerlakuKTAN').value,
						bulan_iuran_awal: document.getElementById('startBulan').value,
						bulan_iuran_akhir: document.getElementById('endBulan').value,
						bukti_iuran_terakhir: (DzoneBuktiIuran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBuktiIuran.files[0].dataURL ,DzoneBuktiIuran.files[0].name ),
		
					});
		
					if (dataRegister.status_code != null) {
						NotificationModal.show(`${dataRegister.message}`, 'error');
						document.getElementById("btnRegister").innerHTML = `Register`;
					} else {
						NotificationModal.show(`${dataRegister.message}`, 'success');
						this._navigateToLogin()
					}
				}
				e.preventDefault();
			}
			
		}

			
		document.getElementById('register-user').addEventListener('submit', eventRegister);
		document.getElementById('btnLogin').addEventListener('click',()=>{
			this._navigateToLogin()
		})
		document.getElementById('btnShowPassword').addEventListener('click',()=>{
			this._showPassword()
		})
		document.getElementById('btnShowPasswordConfirm').addEventListener('click',()=>{
			this._showPasswordConfirm()
		})
	},


	
	
	async _setNameAppBar(){
		const datas = await Autentication.getData();

		datas.forEach(data => {
			document.querySelector('app-bar').setName = data;
		});
	},

	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	_navigateToLogin(){
		window.location.hash = '#/login';
	},

	_showPassword(){
		var x = document.getElementById("registerTextPassword");
        var show_eye = document.getElementById("show_eye");
        var hide_eye = document.getElementById("hide_eye");
        hide_eye.classList.remove("d-none");
        if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
        } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
        }
	},
	_showPasswordConfirm(){
		var x = document.getElementById("registerTextConfirmPassword");
        var show_eye = document.getElementById("show_eye_confirm");
        var hide_eye = document.getElementById("hide_eye_confirm");
        hide_eye.classList.remove("d-none");
        if (x.type === "password") {
        x.type = "text";
        show_eye.style.display = "none";
        hide_eye.style.display = "block";
        } else {
        x.type = "password";
        show_eye.style.display = "block";
        hide_eye.style.display = "none";
        }
	}

}

export default RegisterInitiator;
