
import CONFIG from '../config/globals/config.js';
import API_PENGURUS_PC_ENDPOINT from '../config/globals/menu_pengurus_pc_endpoint.js';
import API_ENDPOINT_PENGURUS_PC from "../config/globals/menu_pengurus_pc_endpoint.js";
import SEMINAR_ENPOINT from '../config/globals/seminar_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class ApiSeminar {

	static async uploadBuktiBayarSeminar({
		file_import,
		idSeminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("_method", "PUT");
		if (file_import != null) {
			formdata.append("bukti_bayar_skp_seminar_pc", file_import);
		}
		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.UPLOAD_BUKTI_BAYAR_SEMINAR(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async uploadBuktiBayarSeminarPD({
		file_import,
		idSeminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("_method", "PUT");
		if (file_import != null) {
			formdata.append("bukti_bayar_skp_seminar_pd", file_import);
		}
		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.UPLOAD_BUKTI_BAYAR_SEMINAR_PD(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updatePatchSeminarPP({
		idSeminar,
		menimbang,
		mengingat,
		skpPeserta,
		skpNarasumber,
		skpPanitia,
		skpModerator,
		skpPesertaOnline
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var raw = null

		if (skpPesertaOnline != null) {
			 raw = JSON.stringify({
				"menimbang": menimbang,
				"mengingat": mengingat,
				"skp_peserta_pp" : skpPeserta,
				"skp_panitia_pp" : skpPanitia,
				"skp_moderator_pp" : skpModerator,
				"skp_narasumber_pp" : skpNarasumber,
				"skp_peserta_pp_online" : skpPesertaOnline
			});
		}else{
			raw = JSON.stringify({
				"menimbang": menimbang,
				"mengingat": mengingat,
				"skp_peserta_pp" : skpPeserta,
				"skp_panitia_pp" : skpPanitia,
				"skp_moderator_pp" : skpModerator,
				"skp_narasumber_pp" : skpNarasumber
			});
		}
		
		var parsed = JSON.parse(raw);
		if (skpPesertaOnline == null) {
			delete parsed.skp_peserta_pd_online;
		}
		var updatedRaw = JSON.stringify(parsed);

		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.UPDATE_PATCH_SEMINAR(idSeminar), {
				method: 'PATCH',
				headers: myHeaders,
				body: updatedRaw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}

	}


	static async addMateriSeminar({
		idSeminar,
		judulMateriSeminar,
		pemateri,
		instansi,
		dariJam,
		sampaiJam,
		moderator,
		deskripsiMateri,
		lampiran,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("permohonan_seminar_id", idSeminar);
		formdata.append("judul_materi_seminar", judulMateriSeminar.toUpperCase())
		formdata.append("pemateri", pemateri.toUpperCase());
		formdata.append("instansi", instansi.toUpperCase());
		formdata.append("dari_jam", dariJam);
		formdata.append("sampai_jam", sampaiJam);
		formdata.append("moderator", moderator.toUpperCase());
		formdata.append("description", deskripsiMateri);
		
		formdata.append("lampiran", lampiran);


		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.ADD_MATERI_SEMINAR, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async downloadSK({id, wilayah}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(SEMINAR_ENPOINT.DOWNLOAD_SK(id,wilayah), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'SK SKP.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

    static async deleteMateriSeminar({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.DELETE_MATERI_SEMINAR(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadFormatImportAnggota() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(SEMINAR_ENPOINT.DOWONLOAD_FILE_IMPORT_ANGGOTA, {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Format Laporan Anggota Seminar Sipafi.xlsx';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
		return 200
		} catch (err) {
			console.log(err)
			return 400;
		}
	}


	// pc start
	static async sendPengajuanSeminarInternal({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSRPC,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		surat_pengantar_pc,
		sertifikat,
		brosur,
		deskripsi,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());



		formdata.append("no_sr_pc", noSRPC);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}

		if (surat_pengantar_pc != null) {
			formdata.append("surat_pengantar_pc", surat_pengantar_pc);
		}
		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}
		

		if (deskripsi != null) {
			formdata.append("description", deskripsi);
		}
		
		

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PC, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async sendPengajuanSeminarExternal({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSRPC,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		surat_pengantar_pc,
		sertifikat,
		brosur,
		deskripsi,
		no_surat,
		surat,
		tanggalSurat,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("nomor_surat_permohonan", no_surat);
		formdata.append("surat_permohonan", surat);
		formdata.append("tanggal_surat_permohonan", tanggalSurat);
		formdata.append("keterangan","-");

		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());


		formdata.append("no_sr_pc", noSRPC);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		
	

		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}

		if (surat_pengantar_pc != null) {
			formdata.append("surat_pengantar_pc", surat_pengantar_pc);
		}
		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}
		

		if (deskripsi != null) {
			formdata.append("description", deskripsi);
		}
		

		

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		

		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PC, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
// pc end send


	static async editPengajuanSeminarInternal({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSKSKP,
		noSRPC,
		noSRPD,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		menimbang,
		mengingat,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		surat_pengantar_pc,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		idSeminar,
		skpPesertaOnline,
		type_seminar,
		request_from
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("_method" , "PUT")
		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());

		if(kategoriSeminar == 'baksos'){
			formdata.append("nomor_sk", noSKSKP);
		}
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);
		formdata.append("no_sr_pc", noSRPC);
		formdata.append("no_sr_pd", noSRPD);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		if (type_seminar != undefined && type_seminar != null) {
			formdata.append("type_seminar", type_seminar);
		}
		
		
		formdata.append("description", deskripsi);

		if (skpPesertaOnline != null) {
			if (request_from == "pp") {
				formdata.append("skp_peserta_pp_online", skpPesertaOnline);
			}else{
				formdata.append("skp_peserta_pd_online", skpPesertaOnline);
			}
		}


		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);	
		}

		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}

		if (brosur != null) {
			formdata.append("brosur", brosur);
		}
		if (buktiBayarSeminar != null && buktiBayarSeminar != undefined) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		if (biayaPendaftaranSeminar != null && biayaPendaftaranSeminar != undefined) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}

		if (surat_pengantar_pc != null) {
			formdata.append("brosur", surat_pengantar_pc);
		}

		console.log("the value of edit form data : ")
		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.EDIT_PENGAJUAN_SEMINAR_PENGURUS(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async editPengajuanSeminarExternal({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSKSKP,
		noSRPC,
		noSRPD,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		menimbang,
		mengingat,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		surat_pengantar_pc,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		no_surat,
		surat,
		tanggalSurat,
		idSeminar,
		skpPesertaOnline,
		type_seminar,
		request_from
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("_method" , "PUT")
		formdata.append("nomor_surat_permohonan", no_surat.toUpperCase());
		
		formdata.append("tanggal_surat_permohonan", tanggalSurat);
		formdata.append("keterangan","-");

		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());
		
		if(kategoriSeminar == 'baksos'){
			formdata.append("nomor_sk", noSKSKP);
		}
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);
		formdata.append("no_sr_pc", noSRPC);
		formdata.append("no_sr_pd", noSRPD);
		formdata.append("skp_peserta_pd", jumlahSKPPeserta);
		formdata.append("skp_moderator_pd", jumlahSKPModerator);
		formdata.append("skp_panitia_pd", jumlahSKPPanitia);
		formdata.append("skp_narasumber_pd", jumlahSKPNarasumber);
		formdata.append("skp_peserta_pp", jumlahSKPPeserta);
		formdata.append("skp_moderator_pp", jumlahSKPModerator);
		formdata.append("skp_panitia_pp", jumlahSKPPanitia);
		formdata.append("skp_narasumber_pp", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan);
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);

		if (skpPesertaOnline != null && skpPesertaOnline != undefined) {
			if (request_from == "pp") {
				formdata.append("skp_peserta_pp_online", skpPesertaOnline);
			}else{
				formdata.append("skp_peserta_pd_online", skpPesertaOnline);
			}
		}

		if (type_seminar != undefined && type_seminar != null) {
			formdata.append("type_seminar", type_seminar);
		}
		
		
		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);	
		}

		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}

		if (brosur != null) {
			formdata.append("brosur", brosur);
		}

		if (buktiBayarSeminar != null && buktiBayarSeminar != undefined) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		if (biayaPendaftaranSeminar != null && biayaPendaftaranSeminar != undefined) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}

		if (surat != null) {
			formdata.append("surat_permohonan", surat);
		}
		
		if (surat_pengantar_pc != null) {
			formdata.append("surat_pengantar_pc", surat_pengantar_pc);
		}
		
		formdata.append("description", deskripsi);
		

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		

		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.EDIT_PENGAJUAN_SEMINAR_PENGURUS(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getPengajuanSeminarById({
		idSeminar
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_PENGAJUAN_SEMINAR_BY_ID(idSeminar),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async getMateriSeminarById({
		idSeminar
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(SEMINAR_ENPOINT.GET_MATERI_SEMINAR_BY_ID(idSeminar),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async editMateriSeminar({
		idSeminar,
		judulMateriSeminar,
		pemateri,
		instansi,
		dariJam,
		sampaiJam,
		moderator,
		deskripsiMateri,
		lampiran,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method" , "PUT")
		formdata.append("judul_materi_seminar", judulMateriSeminar.toUpperCase());
		formdata.append("pemateri", pemateri.toUpperCase());
		formdata.append("instansi", instansi.toUpperCase());
		formdata.append("dari_jam", dariJam);
		formdata.append("sampai_jam", sampaiJam);
		formdata.append("moderator", moderator.toUpperCase());
		formdata.append("description", deskripsiMateri);
		if (lampiran != null) {
			formdata.append("lampiran", lampiran);
		}
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.EDIT_MATERI_SEMINAR_BY_ID(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deletePengajuanSeminar({
		idSeminar,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.EDIT_PENGAJUAN_SEMINAR_PENGURUS(idSeminar), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async publishPengajuanSeminar({
		idSeminar,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "publish",
		  });
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.PUBLISH_PENGAJUAN_SEMINAR_PENGURUS(idSeminar), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async unPublishPengajuanSeminar({
		idSeminar,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"status": "draft",
		  });
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.PUBLISH_PENGAJUAN_SEMINAR_PENGURUS(idSeminar), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	//for pd
	static async sendPengajuanSeminarInternalPD({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSKSKP,
		noSRPD,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		menimbang,
		mengingat,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		skpPesertaOnline
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());



		formdata.append("nomor_sk", noSKSKP);
		formdata.append("no_sr_pd", noSRPD);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		

		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}

		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}
		
		

		if (buktiBayarSeminar != null && buktiBayarSeminar != undefined) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		if (biayaPendaftaranSeminar != null && biayaPendaftaranSeminar != undefined) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}

		if (skpPesertaOnline != null) {
			formdata.append("skp_peserta_pd_online", skpPesertaOnline);
		}

		formdata.append("description", deskripsi);

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PD, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendPengajuanSeminarExternalPD({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		noSKSKP,
		noSRPD,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		menimbang,
		mengingat,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		no_surat,
		surat,
		tanggalSurat,
		skpPesertaOnline
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("nomor_surat_permohonan", no_surat.toUpperCase());
		formdata.append("surat_permohonan", surat);
		formdata.append("tanggal_surat_permohonan", tanggalSurat);
		formdata.append("keterangan","-");

		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());


		formdata.append("nomor_sk", noSKSKP);
		formdata.append("no_sr_pd", noSRPD);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		
		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}
		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}
		

		if (buktiBayarSeminar != null && buktiBayarSeminar != undefined) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		if (biayaPendaftaranSeminar != null && biayaPendaftaranSeminar != undefined) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}

		if (skpPesertaOnline != null) {
			formdata.append("skp_peserta_pd_online", skpPesertaOnline);
		}
		
		formdata.append("description", deskripsi);

		

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		

		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PD, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	// end for pd


	//for pp
	static async sendPengajuanSeminarInternalPP({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		menimbang,
		mengingat,
		skpPesertaOnline,
		type_seminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());


		if (skpPesertaOnline != null) {
			formdata.append("skp_peserta_pp_online", skpPesertaOnline);
		}
		formdata.append("type_seminar", type_seminar);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan.toUpperCase());
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		
		
		if (buktiBayarSeminar != null) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		formdata.append("description", deskripsi);
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);

		
	
		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}

		if (biayaPendaftaranSeminar != null) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}
		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PP, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendPengajuanSeminarExternalPP({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		tempatPenyelenggaraan,
		jumlahSKPPeserta,
		jumlahSKPModerator,
		jumlahSKPPanitia,
		jumlahSKPNarasumber,
		judulSeminar,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		kategoriPeserta,
		quota,
		kontribusiPeserta,
		proposal,
		sertifikat,
		brosur,
		biayaPendaftaranSeminar,
		buktiBayarSeminar,
		deskripsi,
		menimbang,
		mengingat,
		no_surat,
		surat,
		tanggalSurat,
		skpPesertaOnline,
		type_seminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("nomor_surat_permohonan", no_surat.toUpperCase());
		formdata.append("surat_permohonan", surat);
		formdata.append("tanggal_surat_permohonan", tanggalSurat);
		formdata.append("keterangan","-");

		formdata.append("penyelenggara", penyelenggara);

		formdata.append("nama_penanda_pelaksana", ketuaPelaksana.toUpperCase());
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana.toUpperCase());
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana.toUpperCase());
		formdata.append("nama_penanda_sekretaris", sekretaris.toUpperCase());
		formdata.append("jabatan_penanda_sekretaris", jabatan.toUpperCase());
		formdata.append("nian_penanda_sekretaris", nianSekretaris.toUpperCase());


		if (skpPesertaOnline != null) {
			formdata.append("skp_peserta_pp_online", skpPesertaOnline);
		}
		formdata.append("type_seminar", type_seminar);
		formdata.append("skp_peserta", jumlahSKPPeserta);
		formdata.append("skp_moderator", jumlahSKPModerator);
		formdata.append("skp_panitia", jumlahSKPPanitia);
		formdata.append("skp_narasumber", jumlahSKPNarasumber);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan);
		formdata.append("judul_seminar", judulSeminar.toUpperCase());
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("kategori_peserta", kategoriPeserta);
		formdata.append("quota_peserta", quota);
		formdata.append("kontribusi_peserta", kontribusiPeserta);

		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		
		
		
		if (buktiBayarSeminar != null) {
			formdata.append("bukti_bayar_biaya_penerbitan_skp", buktiBayarSeminar);
		}
		formdata.append("description", deskripsi);
		formdata.append("menimbang", menimbang);
		formdata.append("mengingat", mengingat);

	
		if (proposal != null) {
			formdata.append("proposal_seminar", proposal);
		}

		if (biayaPendaftaranSeminar != null) {
			formdata.append("biaya_penerbitan_skp", biayaPendaftaranSeminar);
		}
		
		if (sertifikat != null) {
			formdata.append("background_sertifikat", sertifikat);
		}
		
		if (brosur != null) {
			formdata.append("brosur", brosur);	
		}
		

		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		

		try {
			const fetchApi = await fetch(SEMINAR_ENPOINT.SEND_PENGAJUAN_SEMINAR_PENGURUS_PP, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	// end for pp

	static async accSeminarPD({
		skp_peserta,
		skp_moderator,
		skp_narasumber,
		skp_panitia,
		idSeminar,
		nomor_sk,
		nomor_sr_pd,
		menimbang,
		mengingat,
		biaya_penerbitan_seminar,
		statusPembayaran,
		skp_peserta_online,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			if (nomor_sk == null) {
				var raw = JSON.stringify({
					"approval": "approve",
					"skp_peserta": skp_peserta,
					"skp_moderator": skp_moderator,
					"skp_narasumber": skp_narasumber,
					"skp_panitia": skp_panitia,
					"no_sr_pd": nomor_sr_pd,
					"biaya_penerbitan_seminar" : biaya_penerbitan_seminar,
					"skp_peserta_pd_online" : skp_peserta_online
				  });
				var rawStatusPembayaran = JSON.stringify({
					"status" : statusPembayaran
				})
			}else{
				var raw = JSON.stringify({
					"approval": "approve",
					"skp_peserta": skp_peserta,
					"skp_moderator": skp_moderator,
					"skp_narasumber": skp_narasumber,
					"skp_panitia": skp_panitia,
					"nomor_sk" : nomor_sk,
					"menimbang": menimbang,
					"mengingat": mengingat,
					"biaya_penerbitan_skp" : biaya_penerbitan_seminar,
					"skp_peserta_pd_online" : skp_peserta_online
				  });
				  var rawStatusPembayaran = JSON.stringify({
					  "status" : statusPembayaran
				  })
			}

				
				var parsed = JSON.parse(raw);
				if (skp_peserta_online == null) {
					delete parsed.skp_peserta_pd_online;
				}
				

				var updatedRaw = JSON.stringify(parsed);


			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PD_APPROVE(idSeminar), {
				method: 'POST',
				body: updatedRaw,
				headers: myHeaders
			})
			const fetchApiStatus = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PD_PAYMENT(idSeminar), {
				method: 'PUT',
				body: rawStatusPembayaran,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async accTTD({
		idSeminar,
		status
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			var rawStatusPembayaran = JSON.stringify({
				"status" : status
			})
			
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_TTD(idSeminar), {
				method: 'PUT',
				body: rawStatusPembayaran,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async accPembayaranPD({
		idSeminar,
		statusPembayaran,
		catatan
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			var rawStatusPembayaran = JSON.stringify({
				"status" : statusPembayaran,
				"catatan" : catatan
			})
			
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PD_PAYMENT(idSeminar), {
				method: 'PUT',
				body: rawStatusPembayaran,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async accPembayaranPP({
		idSeminar,
		statusPembayaran,
		catatan
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			var rawStatusPembayaran = JSON.stringify({
				"status" : statusPembayaran,
				"catatan" : catatan
			})
			
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PP_PAYMENT(idSeminar), {
				method: 'PUT',
				body: rawStatusPembayaran,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async reviseSeminarPD({
		idSeminar,
		comment
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			var raw = JSON.stringify({
				"approval": "revise",
                "catatan": comment,
			  });

			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PD_APPROVE(idSeminar), {
				method: 'POST',
				body: raw,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async reviseSeminarPP({
		idSeminar,
		comment
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			var raw = JSON.stringify({
				"approval": "revise",
                "catatan": comment,
			  });

			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PP_APPROVE(idSeminar), {
				method: 'POST',
				body: raw,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async accSeminarPP({
		skp_peserta,
		skp_moderator,
		skp_narasumber,
		skp_panitia,
		idSeminar,
		menimbang,
		mengingat,
		biaya_penerbitan_seminar,
		statusPembayaran,
		type_seminar,
		skp_peserta_online
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		console.log(type_seminar)
		try {
			var raw = null
			if (skp_peserta_online != null) {
				raw = JSON.stringify({
					"approval": "approve",
					"skp_peserta": skp_peserta,
					"skp_moderator": skp_moderator,
					"skp_narasumber": skp_narasumber,
					"skp_panitia": skp_panitia,
					"menimbang": menimbang,
					"mengingat": mengingat,
					"biaya_penerbitan_skp": biaya_penerbitan_seminar,
					"type_seminar" : type_seminar,
					"skp_peserta_pp_online" : skp_peserta_online,
				  });
			}else{
				raw = JSON.stringify({
					"approval": "approve",
					"skp_peserta": skp_peserta,
					"skp_moderator": skp_moderator,
					"skp_narasumber": skp_narasumber,
					"skp_panitia": skp_panitia,
					"menimbang": menimbang,
					"mengingat": mengingat,
					"biaya_penerbitan_skp": biaya_penerbitan_seminar,
					"type_seminar" : type_seminar,
				  });
			}
			var rawStatusPembayaran = JSON.stringify({
				"status" : statusPembayaran
			})

			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PP_APPROVE(idSeminar), {
			method: 'POST',
			body: raw,
			headers: myHeaders
			})
			
			const fetchApiStatus = await fetchWithTimeout(SEMINAR_ENPOINT.ACC_PP_PAYMENT(idSeminar), {
				method: 'PUT',
				body: rawStatusPembayaran,
				headers: myHeaders
			})
			
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getCommentar(
		userID,
		commentFor,
		pengajuanID,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(SEMINAR_ENPOINT.GET_COMMENT_PENGAJUAN(userID,commentFor,pengajuanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}


	static async importPesertaSeminar({
		file_import,
		idSeminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		if (file_import != null) {
			formdata.append("file_import", file_import);
		}
		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.IMPORT_PESERTA_SEMINAR(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendReportSeminar({
		file_import,
		idSeminar
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();

		formdata.append("_method", "PUT");
		if (file_import != null) {
			formdata.append("laporan_seminar", file_import);
		}
		let pairing ="";

		for (var pair of formdata.entries()) {
			pairing += pair[0]+ ' : ' + pair[1] + '\n'; 
		}
		
		try {
			const fetchApi = await fetchWithTimeout(SEMINAR_ENPOINT.REPORT_SEMINAR(idSeminar), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getListSeminar(wilayah, jenis){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(SEMINAR_ENPOINT.GET_SEMINAR_DASHBOARD(wilayah, jenis),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

   
}


export default ApiSeminar;