const RoutingVerificationPresenter = {

	async init() {
		await this._setView();
		await this._setUpViewMail();
	},

	async _setView() {
		document.getElementById("main-content").style.height = "100vh";
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
		document.getElementById("btnBackToLogin").addEventListener("click",()=>{
			this._navigateToLogin()
		})
	},

	async _setUpViewMail(){
		
	},

	_navigateToLogin(){
		window.location.hash = '#/resend-verification';
	},
}

export default RoutingVerificationPresenter;
