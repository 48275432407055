
const pdfObject = window['PDFObject'];
    
const PDFHelper = {

    showPdf(title, pdfUrl) {
        const pdfContainer = document.getElementById('pdfContainer');
        pdfContainer.innerHTML = ''; 
      
       
        pdfObject.embed(pdfUrl, '#pdfContainer');

        document.getElementById("titlePDF").innerHTML = `Preview Document`

      
        $('#modalPDF').modal('show');
    },


    initiator(){
        const callShowPdf = async(title,pdfUrl) => {
            this.showPdf(title,pdfUrl)
        }

        $('table').on('click','.openPdf', function (elm) {
            var pdfUrl = $(this).attr('data-file');
            var title = $(this).attr('data-title');
            callShowPdf(title,pdfUrl)
        })
    },

    getPdf(){
        const callShowPdf = async(title,pdfUrl) => {
            this.showPdf(title,pdfUrl)
        }

        $('.show-pdf-pop-up').off('click').on('click', function (elm) {
            var pdfUrl = $(this).attr('data-file');
            var title = $(this).attr('data-title');
    
            console.log('pdfUrl:', pdfUrl);
            console.log('title:', title);
   
            console.log(pdfUrl)
       
            callShowPdf(title,pdfUrl)
   
        });
      }
}

export default PDFHelper;