
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSKPPD from '../../api/api_pengurus_pd/pengajuan-skp.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";
import ApiPengajuanKTANPD from '../../api/api_pengurus_pd/pengajuan-ktan.js';


const SKPAnggotaPresenter = {

	async init() {
		await this._setView();
		await StorageAnggotaPC.makeStore()
        // await this._getPengajuanSKP();
        // await this._approvePengajuanSKP();
		await this._getListSKPFiltered();
		await this._getDataPrivate();
		await this._initCommentSKP();
		await this._statusFiltered()
		await this._rejectSKP();
		await this._checkAllEvent();
		await this._initAllApprove();
	},

    
	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pd_locked) {
		// 		window.location.hash = '#/404?reason=iuranpd'
		// 	}
		// }
	},
	
	async _getDataPrivate() {

		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)

		var selectPCAnggota = document.getElementById("selectAnggotaPC");
		var lengthOptionPcAnggota = selectPCAnggota.options.length;

		for (let i = lengthOptionPcAnggota - 1; i >= 0; i--) {
			selectPCAnggota.options[i] = null;
		}


		document.getElementById("selectAnggotaPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectAnggotaPC").add(new Option(data.regency_name, data.id))
		});


	},

    async _getPengajuanSKP(status,id_cabang){
		var savedOrderColumn = 'latest_created_at';
		var savedOrderDirection = 'asc';

        let table = await $('#tablePengajuanSKP').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.SKP(status,id_cabang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{
					data: 'latest_created_at'
				},
				{ data: 'ktan' },
				{ data: 'name' },
				{ data: 'nik' },
				{ data: 'cabang_name' },
				{ data: 'skp', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} },
				{ data: 'total_skp_pc', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} },
				{ data: 'total_skp_pd', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} }
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": true
				},
				{
					"targets":[4,3],
					"orderable": false,
					"searchable": false,
				},
				{
					"targets": 8,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#listSKPModal" id='detail' title='Detail'><i class="fas fa-list"></i></i></button>
					</div>`
				},
				{
					"targets": [5,6,7,8],
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
		});
		
		$('#tablePengajuanSKP thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSKP thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;

		});
		
    },

	
	async _statusFiltered(){
		let status = document.getElementById('selectStatus').value
		let id_cabang = document.getElementById('selectAnggotaPC').value
		await this._getPengajuanSKP(status,id_cabang);
		const eventSelectStatus = async (e) => {
			let status = document.getElementById('selectStatus').value
			let id_cabang = document.getElementById('selectAnggotaPC').value
			await this._getPengajuanSKP(status,id_cabang);
		}

		document.getElementById('selectStatus').addEventListener('change', eventSelectStatus)
		document.getElementById('selectAnggotaPC').addEventListener('change', eventSelectStatus)
	},

	
	async _rejectSKP() {
		let idSKP = '';
		let skpApprovePC = '';
		$('#tablePengajuanSKPDet tbody').on('click', '#reject', async function () {
			const table = $('#tablePengajuanSKPDet').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idSKP = data.id;
			skpApprovePC = 0;
		});

		$("#btnSendKomentarPengajuan").on("click", async function (e) {
			e.stopPropagation()
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let catatan = $("#komentarPengajuan").val();
			if (catatan === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'warning');
				$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
			} else {
				const approveAnggota = await ApiPengajuanSKPPD.reviseSKP({
					idSKP: idSKP,
					skpApprovePC: skpApprovePC,
					catatan: catatan
				})
				if (approveAnggota.status_code != 200) {
					NotificationModal.show(`${approveAnggota.message}`, 'warning');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#tablePengajuanSKPDet').DataTable().ajax.reload();
					$('#tablePengajuanSKP').DataTable().ajax.reload();
				} else {
					NotificationModal.show(`${approveAnggota.message}`, 'success');
					$(this).html("<i class='fas fa-comment'></i> Revisi & Berikan Komentar");
					$("#komentarPengajuan").val("")
					$('#tablePengajuanSKPDet').DataTable().ajax.reload();
					$('#tablePengajuanSKP').DataTable().ajax.reload();
				}
			}

		});
	},

	
	async _getListSKPFiltered(){
		let status = document.getElementById('selectStatusList').value
		await this._approvePengajuanSKP(status)

		const eventFiltered = async (e) => {
			let status = document.getElementById('selectStatusList').value
			let user_id = document.getElementById('textUserID').value;
			await this._getDetSKPUser(user_id,status)
		}

		document.getElementById('selectStatusList').addEventListener('change', eventFiltered)
	},

	async _getDetSKPUser(user_id,status){
		var savedOrderColumn = 'tanggal_mulai';
		var savedOrderDirection = 'asc';

		let tableDetailSKP = await $('#tablePengajuanSKPDet').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary ",
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
		"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PD.SKP_DETAILS(user_id,status)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: '' },
				{ data: 'tanggal_mulai' },
				{ data: 'tanggal_selesai' },
				{ data: 'berlaku_sampai' },
				{ data: 'nama_kegiatan' },
				{ data: 'nomor_sertifikat' },
				{ data: 'kategori', render : function (data) {
					if (data == "baksos") {
						var kategori = 'Baksos / Pengabdian Masyarakat';
					}else if (data == "masa_kerja") {
						var kategori = 'Masa Kerja';
					}else {
						var kategori = 'Pembelajaran';
					}

					return kategori;
				} },
				{ data: 'jenis_kegiatan' },
				{ data: 'nomor_surat_keputusan' },
				{ data: 'dokumen', render : function(data){
					var doc =  '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">';
					
					return doc;
			
				}},
				{ data: 'bukti_kegiatan', render : function(data){
					var doc =  '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">';
					
					return doc;
			
				}},
				{ data: 'skp', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} },
				{ data: 'skp_approve_pc', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} },
				{ data: 'skp_approve_pd', render : function(data){ if (data == null || data == "") {
					return '0';
				}else {
					return data;
				}} },
				{
					data: 'status_pc', render: function (data) {
						let content = ``;
						if (data == 'approve') {
							content = `<div class="badge badge-success">${data}</div>`
						}else if (data == 'pending') {
							content = `<div class="badge badge-warning">${data}</div>`
						}else {
							content = `<div class="badge badge-danger">${data}</div>`
						}

						return content;
					}
				},
				{
					data: 'status_pd', render: function (data) {
						let content = ``;
						if (data == 'approve') {
							content = `<div class="badge badge-success">${data}</div>`
						}else if (data == 'pending') {
							content = `<div class="badge badge-warning">${data}</div>`
						}else if(data == 'revise'){
							content = `<div class="badge badge-danger">${data}</div>`
						}else {
							content = `<div class="badge badge-danger">pending</div>`
						}

						return content;
					}
				},
			],
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'asc']
			],
			"columnDefs": [
				{
					"targets": 0,
					"orderable": false,
					className: 'select-checkbox',
					"data": null,
					"defaultContent": ``
				},
				{
					"targets": 16,
					"data": null,
					"render": function (data, type, row) {
						var disabledStatus = ""
						if (row.status_pd =="approve") {
							disabledStatus = "d-none"
							
						}
					return `<div style="display:flex">
					<button style="margin:1px" class='btn btn-success btn-sm text-center ${disabledStatus}' id='approve' title='Approve'  data-toggle="modal" data-target="#modalApprovementSKP"><i class="fas fa-check"></i></button>
					<button style="margin:1px" class='btn btn-danger btn-sm text-center' id='reject' title='Reject'  data-toggle="modal" data-target="#commentModal"><i class="fas fa-times"></i></i></button>
					<button style="margin:1px" class='btn btn-info btn-sm text-center' id='comment' title='Komentar'  data-toggle="modal" data-target="#commentModalSKP"><i class="fas fa-comment"></i></i></button>
				</div>`
					}
				},
				{
					"targets": [8,9,10,11,12,13,14,15],
					"visible": true,
					"searchable": true,
					"className": "text-center",
				},
				{
					"targets": [8,9],
					"visible": true,
					"orderable": false,
					"className": "text-center",
				},
			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanSKPDet tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPC.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
		});

			// here
			$('#tablePengajuanSKPDet thead').off('click', 'th').on('click', 'th', function () {
				let chachedCoulumnName = savedOrderColumn;
				const columnIndex = $(this).index();
				const columnHeaders = $('#tablePengajuanSKPDet thead th');
				const columnName = $(columnHeaders[columnIndex]).data('column');
				var order =  tableDetailSKP.order()[0][1];
				
				if (order == "asc") {
					order = "desc"
				}else{
					order = "asc"
				}
				if(chachedCoulumnName != columnName){
					order = "asc"
				}
				savedOrderColumn = columnName;
				chachedCoulumnName = savedOrderColumn;
				savedOrderDirection = order;
	
			});

			
		$('#tablePengajuanSKPDet tbody').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			const table = $('#tablePengajuanSKPDet').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id

			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPC.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPC.pushData(idPengajuan);
			}
		});
	},
	
	async _checkAllEvent() {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanSKPDet')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanSKPDet').DataTable();
				let data = table.row(index).data()
				if (checked) {
					StorageAnggotaPC.pushData(data.id)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPC.deleteData(data.id)
				}
			}
		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)

	},

	
	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPC.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPC.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiPengajuanSKPPD.approveAllPD(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSKPDet').DataTable().ajax.reload();
							$('#tablePengajuanSKP').DataTable().ajax.reload();
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSKPDet').DataTable().ajax.reload();
							$('#tablePengajuanSKP').DataTable().ajax.reload();
						}
						await StorageAnggotaPC.makeStore()
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
					await StorageAnggotaPC.makeStore()
				});

			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},

    async _approvePengajuanSKP(status){
		let user_id = 0;
		$('#tablePengajuanSKP tbody').on('click', '#detail', async function () {
			
			const table = $('#tablePengajuanSKP').DataTable();
			let data = table.row($(this).parents('tr')).data();

			user_id = data.user_id;
			
			$("#textUserID").val(user_id);
			$("#textNamaAnggota").html(data.name);
			$("#textEmail").html(data.email);
			$("#textNIK").html(data.nik);
			$("#textKTAN").html(data.ktan);
			if (data.strttk == null) {
				$("#textNoSTRTTK").html("Tidak ada data");
				$("#textTanggalSTRTTK").html("Tidak ada data");
				$("#textMasaBerlakuSTRTTK").html("Tidak ada data");
			}else {
				$("#textNoSTRTTK").html(data.strttk.nomor_strttk);
				$("#textTanggalSTRTTK").html(data.strttk.tanggal);
				

				let masaBerlakuSTRTTK = ''
				if (data.strttk.berlaku_sampai == 'lifetime') {
					masaBerlakuSTRTTK = 'Seumur Hidup'
				}else {
					masaBerlakuSTRTTK = data.strttk.berlaku_sampai;
				}
				$("#textMasaBerlakuSTRTTK").html(masaBerlakuSTRTTK);
			}

			
			await SKPAnggotaPresenter._getDetSKPUser(user_id,status)


			
			
		});

		

		var idSKP = 0;
		$('#tablePengajuanSKPDet tbody').on('click', '#approve', async function (e) {

			
			tinymce.remove('#textResume')	
			tinymce.init({
				selector: 'textarea#textResume',
				menubar: false,
				min_height: 400,
				visual: true,
				readonly : 1,
				inline_styles: true,
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
				fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
			});
			
			// e.stopPropagation()
			const table = $('#tablePengajuanSKPDet').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idSKP = data.id;
			
			let kategori = '';
			if (data.kategori == "baksos") {
				kategori = 'Baksos / Pengabdian Masyarakat'
			}else if (data.kategori == "masa_kerja") {
				kategori = 'Masa Kerja'
			}else {
				kategori = 'Pembelajaran'
			}

			
			$("#imgSertifikat").attr('src',data.dokumen);
			$("#textNamaKegiatan").val(data.nama_kegiatan);
			$("#textNomorSertifikat").val(data.nomor_sertifikat);
			$("#textNomorSuratKeputusan").val(data.nomor_surat_keputusan);
			$("#textJenisKegiatan").val(data.jenis_kegiatan);
			$("#textKategori").val(kategori);
			$("#numberSKP").val(data.skp);
			$("#numberSKPDiSetujuiPC").val(data.skp_approve_pc);
			$("#numberSKPDiSetujui").val(data.skp_approve_pc);
			
			let skpbyid = await ApiAnggota.getSKPbyID(idSKP)
			let resume = skpbyid.data.catatan;
			var editor = tinymce.get('textResume');
			editor.setContent(resume);

			$("#numberSKPDiSetujui").keyup(function () {
				if ($(this).val() > $("#numberSKP").val()) {
					$(this).addClass("border border-danger text-danger");
					$(this).css("box-shadow: 0 0 0 0.2rem rgb(223 139 139 / 26%);");
					$("#validationSKP").removeClass("d-none");
					$("#validationSKP").addClass("text-danger");
					$("#validationSKP").html("<strong>Invalid !</strong> SKP yang disetujui tidak boleh melebihi SKP yang diajukan !");
					$("#validationSKP").animate({left:0});
				}else {
					$(this).removeClass("border border-danger text-danger");
					$("#validationSKP").addClass("d-none");
				}
			});

			$("#btnCancelApporve").click(function () {
				$("#numberSKPDiSetujui").removeClass("border border-danger text-danger");
				$("#validationSKP").addClass("d-none");
			});

		});

		const eventApproveSKP = async (e) => {
			e.stopPropagation()
			$("#btnApproveSKP").html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let skpApprovePD = $("#numberSKPDiSetujui").val();
			if (parseFloat(skpApprovePD) > parseFloat($("#numberSKP").val())) {
				NotificationModal.show('Invalid ! SKP yang disetujui tidak boleh melebihi SKP yang diajukan !', 'error');
				$("#btnApproveSKP").html('<i class="fas fa-check"></i></i> Approve');
			}else {
				const ApproveSKP = await ApiPengajuanSKPPD.approveSKP(
					idSKP,
					skpApprovePD
				);
				if (ApproveSKP.status_code != 200) {
					NotificationModal.show(`${ApproveSKP.message}`, 'error');
					$("#btnApproveSKP").html('<i class="fas fa-check"></i></i> Approve');
					$("#btnCancelApporve").trigger("click");
					$('#tablePengajuanSKPDet').DataTable().ajax.reload();
					$('#tablePengajuanSKP').DataTable().ajax.reload();
				} else {
					NotificationModal.show(`${ApproveSKP.message}`, 'success');
					$("#btnApproveSKP").html('<i class="fas fa-check"></i></i> Approve');
					$("#btnCancelApporve").trigger("click");
					$('#tablePengajuanSKPDet').DataTable().ajax.reload();
					$('#tablePengajuanSKP').DataTable().ajax.reload();

				}
			}

			e.preventDefault();
		}

		document.getElementById("btnApproveSKP").addEventListener("click",eventApproveSKP);

		$("#listSKPModal").on('hide.bs.modal', function(){
			$('#selectStatusList').val('pending');
		});
		
		
	},


	
	async _initCommentSKP() {
		let idPengajuan = '';
		let userID = $("#textUserID").val();
		$('#tablePengajuanSKPDet tbody').on('click', '#comment', async function () {

			const table = $('#tablePengajuanSKPDet').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let userID = $("#textUserID").val();
			idPengajuan = data.id;
			console.log(data);


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiPengajuanKTANPD.getCommentar(userID, "skp", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let userID = $("#textUserID").val();
			document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanSKP").value
			const response = await ApiPengajuanKTANPD.sendCommentar({
				userID: userID,
				pengajuanID: idPengajuan,
				commentFor: 'skp',
				contentComment: content,
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanKTANPD.getCommentar(userID, "skp", idPengajuan)
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanSKP').val('');
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanSKP").addEventListener("click", eventSendCommentar)
	},

}

const StorageAnggotaPC = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}
export default SKPAnggotaPresenter;
