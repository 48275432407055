import RekomSIPTTKPresenter from "../../../presenter/menu_anggota/rekom-sipttk-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const RekomSIPTTK = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="alert alert-info alert-dismissible">
			<button type="button" class="close" data-dismiss="alert" style="padding:revert;">&times;</button>
			<h3 style="padding:0; margin:0;"><strong>Informasi!</strong></h3><br>
			1. Isi formulir secara lengkap.<br>
			2. Setelah anda berhasil melakukan pengajuan lihat pada data pengajuan dan tombol detail apakah pengajuan sudah sesuai atau tidak.<br>
			3. Jika terdapat persyaratan yang harus di upload maka segera upload persyaratan pada tombol detail pengajuan.<br>
			4. Anda dapat berkomunikasi dengan Admin PC pada laman komentar yang terdapat pada tombol detail pengajuan.<br>
			5. Jika anda memiliki pertanyaan anda dapat menghubungi Helpdesk SiPAFI via WhatsApp berikut : <strong><a href="https://web.whatsapp.com/send?phone=6282220467605"><i class="fab fa-whatsapp"></i> Helpdesk SiPAFI</a></strong>
		</div>
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataSIPTTK" class="nav-link hapus-border active" id="formulirSIPTTK" data-bs-toggle="tab" data-bs-target="#tabDataSIPTTK" data-toggle="tab" type="button" role="tab" aria-controls="tabDataSIPTTK" aria-selected="true">Formulir Pengajuan</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabFormulirSIPTTK" class="nav-link hapus-border" id="profile-pendidikan-tab" data-bs-toggle="tab" data-bs-target="#tabFormulirSIPTTK" data-toggle="tab" type="button" role="tab" aria-controls="tabFormulirSIPTTK" aria-selected="false">Data Pengajuan</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataSIPTTK" role="tabpanel" aria-labelledby="data-umum-tab">
						<div class="row mt-3">
							<div class="col-lg-12 col-xl-12">
								<div class="card">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Informasi</h6>
									</div>

									<div class="card-body" id="information_sipttk">
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
										<div class="shimmer content-line"></div>
									</div>
								</div>		
								<div class="card mt-3">
									<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
										<h6 class="m-0 font-weight-bold text-primary">Formulir Pengajuan</h6>
									</div>
									<div class="card-body ">
										<form id="form-data-sipttk">
											<div class="row">
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jenis Permohonan</label>
														<div class="col-sm-12">
															<input type="hidden" id="hideIdPengajuan" class="form-control">
															<select class="form-control" id="selectJenisPermohonan">
																<option value="">Pilih Salah Satu</option>
																<option value="kesatu">Ke Satu</option>
																<option value="kedua">Ke Dua</option>
																<option value="ketiga">Ke Tiga</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jenis Rekomendasi</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectJenisRekomendasi">
																<option value="">Pilih Salah Satu</option>
																<option value="penerbitan">Penerbitan</option>
																<option value="pencabutan">Pencabutan</option>
															</select>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 text-primary font-weight-bold">Yang Bertanda Tangan Dibawah Ini</label>
														<label class="control-label col-sm-12 font-weight-bold">Nama Lengkap</label>
														<div class="col-sm-12">
															<input type="text" name="textNamaLengkap" placeholder="Nama" class="form-control" required id="textNamaLengkap" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">KTAN</label>
														<div class="col-sm-12">
															<input type="text" name="textKTAN" placeholder="KTAN" class="form-control" id="textKTAN" readonly required>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Alamat Domisili</label>
														<div class="col-sm-12">
															<input type="text" name="textDomisili" placeholder="Alamat Domisili" class="form-control" readonly required id="textDomisili">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Desa / Kelurahan</label>
														<div class="col-sm-12">
															<input type="text" name="textDesa" placeholder="Desa / Kelurahan" class="form-control" id="textDesa" readonly required>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Kecamatan</label>
														<div class="col-sm-12">
															<input type="text" name="textKecamatan" placeholder="Kecamatan" class="form-control" id="textKecamatan" readonly required>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Kabupaten / Kota</label>
														<div class="col-sm-12">
															<input type="text" name="textKabupaten" placeholder="Kabupaten / Kota" class="form-control" id="textKabupaten" readonly required>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Provinsi</label>
														<div class="col-sm-12">
															<input type="text" name="textProvinsi" placeholder="Provinsi" class="form-control" id="textProvinsi" readonly required>
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 text-primary font-weight-bold">Profile Pendidikan</label>
														<label class="control-label col-sm-12 font-weight-bold">Lulusan</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectPendidikan">
																<option value="">Pilih Salah Satu</option>
															</select>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Institusi Pendidikan</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Institusi Pendidikan" class="form-control" required readonly id="InstutisiText">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jenjang</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Jenjang" class="form-control" required id="textJenjang" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Tahun Lulus</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Tahun" class="form-control" required id="textTahun" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">No STRTTK</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="No STRTTK" class="form-control" required id="textNoSTRTTK" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Masa Berlaku</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Masa Berlaku" class="form-control" required id="textMasaBerlaku" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Pengurus Daerah</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Pengurus Daerah" class="form-control" required id="textPengurusDaerah" readonly>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Pengurus Cabang</label>
														<div class="col-sm-12">
															<input type="text" name="nama_lengkap" placeholder="Pengurus Cabang" class="form-control" required id="textPengurusCabang" readonly>
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold text-primary">Profile Pekerjaan</label>
														<label class="control-label col-sm-12 font-weight-bold">Tempat Kerja</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectSarana" name="sarana" required>
																<option value="">Pilih Salah Satu</option>
															</select>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jenis Tempat Kerja</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Jenis Tempat Kerja" class="form-control" readonly required id="textJenisTempatKerja">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Nama Tempat Kerja</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Nama Tempat Kerja" class="form-control" readonly required id="textNamaTempatKerja">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">No Tlp/HP</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="No Tlp/HP" class="form-control" readonly required id="textNoHPTempatKerja">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Email</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Email" class="form-control" readonly required id="textEmailTempatKerja">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Alamat</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Alamat" class="form-control" readonly required id="textAlamatTempatKerja">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Provinsi</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectProvinsiTempatKerja" name="sarana" required>
																<option value="">Pilih Salah Satu</option>
															</select>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Kabupaten / Kota</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectKabupatenTempatKerja" name="sarana" required>
																<option value="">Pilih Salah Satu</option>
															</select>
														</div>
													</div>
												</div>
												<div class="col-sm-6">
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold text-primary">Informasi Praktik</label>
														<label class="control-label col-sm-12"><div class="alert alert-info">Jika praktik dilaksanakan pada setiap hari pada waktu yang sama, cukup disebutkan setiap hari dan disebutkan waktunya dari Jam berapa sampai dengan Jam berapa. Jika praktik dilaksanakan tidak setiap hari, sebutkan hari praktik dan waktu praktik.</div></label>
														<label class="control-label col-sm-12 font-weight-bold">Hari Praktik</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Hari Praktik" class="form-control" required id="textHariPraktik">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jam Praktik</label>
														<div class="col-sm-12">
															<textarea placeholder="Jam Praktik" rows="6" class="form-control" required id="textJamPraktik"></textarea>
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Nama Pimpinan</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Nama Pimpinan" class="form-control" required id="textNamaPimpinan">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Jabatan Pimpinan</label>
														<div class="col-sm-12">
															<input type="text" name="tempat_kerja" placeholder="Jabatan Pimpinan" class="form-control" required id="textJabatanPimpinan">
														</div>
													</div>
													<div class="form-group row">
														<label class="control-label col-sm-12 font-weight-bold">Informasi Fasilitas</label>
														<div class="col-sm-12">
															<select class="form-control" id="selectInformasiFasilitas" name="sarana" required>
																<option value="">Pilih Salah Satu</option>
																<option value="FASILITAS PELAYANAN KEFARMASIAN">FASILITAS PELAYANAN KEFARMASIAN</option>
																<option value="FASILITAS PRODUKSI">FASILITAS PRODUKSI</option>
																<option value="FASILITAS DISTRIBUSI">FASILITAS DISTRIBUSI</option>
																<option value="APOTEK">APOTEK</option>
																<option value="RUMAH SAKIT">RUMAH SAKIT</option>
																<option value="PUSKESMAS">PUSKESMAS</option>
																<option value="KLINIK">KLINIK</option>
																<option value="SARANA LAINNYA">SARANA LAINNYA</option>
															</select>
														</div>
													</div>
												</div>
											</div>
											<div class="form-group row">
												<div class="col-sm-12 text-right">
													<button class="btn btn-sm btn-primary" id="btnKirimPengajuan"><i class="fas fa-share"></i> Kirim Pengajuan</button>
													<button type="button" class="btn btn-sm btn-secondary" id="btnClearPengajuanSIPTTK" ><i class='fas fa-eraser'></i> Kosongkan</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					
					<div class="tab-pane fade" id="tabFormulirSIPTTK" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pengajuan Rekomendasi Registrasi Ulang SIPTTK
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tablePengajuanSIPTTK" width="100%" cellspacing="0">
										<thead>
											<tr>
												<th rowspan="2">Nama Anggota</th>
												<th rowspan="2">Tanggal Pengajuan</th>
												<th rowspan="2">Jenis Permohonan</th>
												<th rowspan="2">Jenis Rekomendasi</th>
												<th rowspan="2">PC Asal</th>
												<th rowspan="2">PC Sarana</th>
												<th colspan="4" align="center">Berkas Data Umum</th>
												<th rowspan="2">Status PC</th>
												<th rowspan="2">Status PC Sarana</th>
												<th rowspan="2">Aksi</th>
											</tr>
											<tr>
												<th>Foto</th>
												<th>KTP</th>
												<th>STRTTK</th>
												<th>SIPTTK</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
											<tr>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer circle"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line"></div></td>
												<td><div class="shimmer title-line end"></div></td>
											</tr>
										</tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
<div class="modal fade" id="detailSIPTTKModal" tabindex="-1" role="dialog" aria-labelledby="detailSIPTTKModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="">Detail Pengajuan Rekomendasi Registrasi Ulang SIPTTK</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" id="bodyModalSIPTTK">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-12">
									<input type="hidden" id="textHIddenSIPTTK" class="form-control">
                                    <label class="label control-label">Tanggal Pengajuan</label>
                                    <input type="text" id="tanggalPengajuan" class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">KTAN</label>
                                    <input type="text" id="textKTANmodal" placeholder="KTAN..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Nama Anggota</label>
                                    <input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Email</label>
                                    <input type="text" id="textEmail" placeholder="Email..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label">Jenis Permohonan</label>
                                    <input type="text" id="textJenisPermohonan" placeholder="Jenis Permohonan..." class="form-control" readonly>
                                </div>
                                
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data Pendidikan</label>
                                <div class="col-sm-12 overflow-auto">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Tahun Lulus</th>
                                            <th>Jenjang</th>
                                            <th>Nama Institusi</th>
                                            <th>Tanggal Ijazah</th>
                                        </thead>
                                        <tbody id="dataPendidikanSIPTTK">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data Pekerjaan</label>
                                <div class="col-sm-12 overflow-auto">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Nama Instansi</th>
                                            <th>Alamat</th>
                                            <th>Telp/Fax</th>
                                            <th>No HP</th>
                                            <th>Email</th>
                                            <th>Jabatan</th>
                                            <th>Jenis Tempat Kerja</th>
                                            <th>Dari Tahun</th>
                                            <th>Sampai Tahun</th>
                                        </thead>
                                        <tbody id="dataPekerjaan">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
							<div class="row mt-2">
								<div class="col-sm-12">
									<div class="card">
										<div class="card-header text-primary font-weight-bold">
											Riwayat Verifikasi SIPTTK
										</div>
										<div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
												
											<div class="table-responsive">
												<table class="table table-bordered table-hover" id="tableHistoryVerifikasiSIPTTK" width="100%" cellspacing="0">
													<thead>
														<tr>
															<th>No</th>
															<th>Tanggal</th>
															<th>Nama</th>
															<th>Hak</th>
															<th>Keterangan</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
														<tr>
															<td><div class="shimmer title-line end"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
															<td><div class="shimmer title-line"></div></td>
														</tr>
													</tbody>
													<tfoot>
														
													</tfoot>
													<tbody>
													
													
													
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
                        </div>
                        <div class="col-sm-6">
							<div class="card mb-2">
								<div class="card-header text-primary font-weight-bold">
									<strong>Upload Persyaratan</strong>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12">
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
												<div class="col-sm-12">
													<select class="form-control" id="selectBerkas">
														<option value="">Pilih Salah Satu</option>
													</select>
												</div>
											</div>
											<div class="text-center d-none" id="containerAlertBerkas">
												<p>Deskripsi Persyaratan</p>
												<div class="alert alert-primary" role="alert" id="valueAlertBerkas">
													
												</div>
											</div>
											<div class="form-group row">
												<label class="control-label col-sm-12 font-weight-bold">Upload Berkas</label>
											</div>
											<div id="form-upload-sipttk" class="col-sm-12 dropzone">
												<input type="hidden" name="file" style=""/>
											</div>
											<div class="form-group row">
												<div class="col-sm-12">
													<button class="mb-2 btn btn-sm btn-primary" id="btnUpdateBerkas" style="width:100%"><i class='fas fa-edit'></i> Kirim & Update Berkas</button>
												</div>
											</div>
										</div>
									</div>
									
								</div>
							</div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Persyaratan</label>
                                <div class="col-sm-12 table-responsive">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Nama Persyaratan</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="persyaratanSIPTTK">
        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                            <h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
                                        </div>
                                        <div class="card-body chat-body p-1" id="lamanChat">
                                            <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
												<div style="width:100%">
													<div style="width:10%;" class="float-left">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-right">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-left">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
												<div style="width:100%">
													<div style="width:10%;" class="float-right">
														<div class="shimmer circle"></div>
													</div>
													<div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line"></div>
														<div class="shimmer content-line end"></div>
													</div>
												</div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <form id="form-comment">
                                                <label class="control-label font-weight-bold">Komentar</label>
                                                <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                                <br>
                                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
		

	<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
		`;
		return view;
	  },

	async afterRender() {
		await RekomSIPTTKPresenter.init();
		await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default RekomSIPTTK;