import UrlParser from '../routes/url-parser.js';
import ApiRegister from "../api/data-register.js";

const WilayahTimePresenter = {

	async init() {
		await this._setView();
		await this.initProvinceValue();
	},

	async _setView() {
		//document.getElementById("main-content").style.height = "200vh";
		document.getElementById("cpRght").style.display = "none";
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},


	async initProvinceValue(){
		// let table = await $("#tableKodeWilayah").DataTable({
		// 	"scrollX":true,
			
		// });
		// jsonData.forEach(data => {
		// 	table.row.add([
		// 	  data.sesi,
		// 	  data.name_pd,
		// 	  data.tanggal,
		// 	  data.waktu
		// 	]).draw();
		//   });

		// $('#globalSearch').on('keyup', function () {
		// table.search(this.value).draw();
		// });
	},

	

	

}


const jsonData = [
	{"sesi": 1, "name_pd": "АСЕН", "tanggal": "29/02/2024", "waktu": "16.00 - 17.00 WIB"},
	{"sesi": 1, "name_pd": "LAMPUNG", "tanggal": "29/02/2024", "waktu": "16.00 - 17.00 WIB"},
	{"sesi": 1, "name_pd": "KEPULAUAN BANGKA BELITUNG", "tanggal": "29/02/2024", "waktu": "16.00 - 17.00 WIB"},
	
	{"sesi": 2, "name_pd": "SUMATRA UTARA KEPULAUAN RIAU", "tanggal": "29/02/2024", "waktu": "17.00 - 18.00 WIB"},
	{"sesi": 2, "name_pd": "KEPULAUAN RIAU", "tanggal": "29/02/2024", "waktu": "17.00 - 18.00 WIB"},
	{"sesi": 2, "name_pd": "BENGKULU", "tanggal": "29/02/2024", "waktu": "17.00 - 18.00 WIB"},

	{"sesi": 3, "name_pd": "SUMATRA SELATAN", "tanggal": "29/02/2024", "waktu": "17.00 - 18.00 WIB"},
	{"sesi": 3, "name_pd": "SUMATRA BARAT", "tanggal": "29/02/2024", "waktu": "18.00 - 19.00 WIB"},

	{"sesi": 4, "name_pd": "RIAU", "tanggal": "29/02/2024", "waktu": "19.00 - 20.00 WIB"},
	{"sesi": 4, "name_pd": "JAMBI BALI", "tanggal": "29/02/2024", "waktu": "19.00 - 20.00 WIB"},
	
	{"sesi": 5, "name_pd": "BALI", "tanggal": "29/02/2024", "waktu": "20.00 - 21.00 WIB"},
	{"sesi": 5, "name_pd": "NTB", "tanggal": "29/02/2024", "waktu": "20.00 - 21.00 WIB"},

	{"sesi": 6, "name_pd": "NTT", "tanggal": "29/02/2024", "waktu": "21.00 - 22.00 WIB"},
	{"sesi": 6, "name_pd": "GORONTALO", "tanggal": "29/02/2024", "waktu": "21.00 - 22.00 WIB"},

	{"sesi": 7, "name_pd": "KALIMANTAN BARAT", "tanggal": "29/02/2024", "waktu": "22.00 - 23.00 WIB"},
	{"sesi": 7, "name_pd": "KALIMANTAN TENGAH", "tanggal": "29/02/2024", "waktu": "22.00 - 23.00 WIB"},

	{"sesi": 8, "name_pd": "KALIMANTAN SELATAN", "tanggal": "29/02/2024", "waktu": "23.00 - 00.00 WIB"},
	{"sesi": 8, "name_pd": "KALIMANTAN TIMUR", "tanggal": "29/02/2024", "waktu": "23.00 - 00.00 WIB"},


	{"sesi": 9, "name_pd": "KALIMANTAN UTARA", "tanggal": "01/03/2024", "waktu": "00.00 - 01.00 WIB"},
	{"sesi": 9, "name_pd": "SULAWESI UTARA", "tanggal": "01/03/2024", "waktu": "00.00 - 01.00 WIB"},


	{"sesi": 10, "name_pd": "SULAWESI TENGAH", "tanggal": "29/02/2024", "waktu": "01.00 - 02.00 WIB"},
	{"sesi": 10, "name_pd": "SULAWESI SELATAN", "tanggal": "29/02/2024", "waktu": "01.00 - 02.00 WIB"},

	{"sesi": 11, "name_pd": "SULAWESI TENGGARA", "tanggal": "29/02/2024", "waktu": "02.00 - 03.00 WIB"},
	{"sesi": 11, "name_pd": "SULAWESI BARAT", "tanggal": "29/02/2024", "waktu": "02.00 - 03.00 WIB"},

	{"sesi": 12, "name_pd": "JAWA TIMUR", "tanggal": "29/02/2024", "waktu": "03.00 - 04.00 WIB"},
	{"sesi": 12, "name_pd": "BANTEN", "tanggal": "29/02/2024", "waktu": "03.00 - 04.00 WIB"},

	{"sesi": 13, "name_pd": "JAWA TENGAH", "tanggal": "29/02/2024", "waktu": "04.00 - 05.00 WIB"},
	{"sesi": 13, "name_pd": "DI YOGYAKARTA", "tanggal": "29/02/2024", "waktu": "04.00 - 05.00 WIB"},
	
	{"sesi": 14, "name_pd": "DKI JAKARTA", "tanggal": "29/02/2024", "waktu": "05.00 - 06.00 WIB"},
	{"sesi": 14, "name_pd": "JAWA BARAT", "tanggal": "29/02/2024", "waktu": "05.00 - 06.00 WIB"},

	{"sesi": 15, "name_pd": "MALUKU", "tanggal": "29/02/2024", "waktu": "06.00 - 06.00 WIB"},
	{"sesi": 15, "name_pd": "MALUKU UTARA", "tanggal": "29/02/2024", "waktu": "06.00 - 06.00 WIB"},
	{"sesi": 15, "name_pd": "PAPUA BARAT", "tanggal": "29/02/2024", "waktu": "06.00 - 06.00 WIB"},
	{"sesi": 15, "name_pd": "PAPUA", "tanggal": "29/02/2024", "waktu":"06.00 - 06.00 WIB"},

  ];
  
export default WilayahTimePresenter;
