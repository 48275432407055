import routes from '../routes/routes.js';
import UrlParser from '../routes/url-parser.js';
import Autentication from '../utils/autentication.js';
import ChangeColor from '../utils/initial_change_color.js';
import SideBar from './component/side-bar.js';
import Footer from './component/footer-bar.js';
import SideBarHelper from '../utils/side-bar-helper.js';
import ShorcutInitialize from '../utils/key-shorcut.js';
import AccessHelper from '../utils/access-helper.js';
import ApiNotication from '../api/data-notification.js';
import NotificationRoutes from '../routes/notification-routes.js';
import MembershipHelper from '../utils/membership-helper.js';
import ApiLogin from '../api/data-login.js';
import CONFIG from '../config/globals/config.js';
import ApiAnggota from '../api/data-anggota.js';

class App {
  constructor({content}) {
    this._content = content;
    this._initialAppShell();
    this.refreshTokenInProgress = false
  }

    _initialAppShell() {
      SideBar.init();
      Footer.init();
      this._initLogout();
      ShorcutInitialize.initialShortcut();
      mRefresh({
        nav: '#navMain',
        scrollEl: '#contentMain',
        maxTime: 20000,
    })
    }

   async _initLogout(){
      document.getElementById('logout-apps').addEventListener('click', async ()=>{
       await Autentication._logOut();
       $('#logoutModal').modal('hide');
      })
    }
  
    async renderPage() {
      await this._giveTheLoading();
      await this._checkStatusJaringan();
      const isLogin = await Autentication.isLogin();

      if (isLogin) {
         await this.finalRefreshToken();
      }

      let url = UrlParser.parseActiveUrlWithCombiner();

      if (!(url in routes)) {
        window.location.hash = '#/404';
        url = UrlParser.parseActiveUrlWithCombiner();
      }

      if (isLogin) {
        if (await Autentication.getVersion() == undefined) {
          Autentication.autoLogout()
          window.location.hash = '#/login';
          return
        }
      }
      if (!isLogin) {
        if(url != '/login' && url != '/register' && 
          url != '/reset-password' && 
          url != '/already-success-mail' && 
          url != '/already-verify-mail' && 
          url != '/dokumen-valid' && 
          url != '/423' && 
          url != '/kode-wilayah' && 
          url != '/wilayah-time' &&
          url != '/resend-verification'&&
          url != '/resend-payment'&&
          url != '/expired-verification'&&
          url != '/dokumen-invalid' && 
          url != '/user-not-registered' &&
          url != '/upload-bukti-bayar-registrasi' && 
          url != '/forgot-password'){
          window.location.hash = '#/login';
          return;
          }
        url = UrlParser.parseActiveUrlWithCombiner();
      }else{
        if(url == '/login' || url == '/register' ){
          window.location.hash = '#/dashboard';
          return;
        }
        url = UrlParser.parseActiveUrlWithCombiner();
        if (url != '/404') {
          this._setViewAppBar(url);
        }
      }
      
      let role = await Autentication.getRole() 
      if (role != null) {
        for (let index = 0; index < role.length; index++) {
          const element = role[index];
          let isRoleIncluded = await AccessHelper.isRoleIncluded(element ,url)
          if (isLogin && isRoleIncluded == false && index == role.length) {
            window.location.hash = '#/404';
            setTimeout(async () => {
              await this._hideLoading();
            }, 300);
          }else{
            break;
          }
        }
      }

       const page = routes[url];
      let replaceUrl = url.replace(/\//g, " ").replace(/-/g, " ");
       mixpanel.track(`Open ${replaceUrl} `)

       this._content.innerHTML = await page.render();
       await page.afterRender();
       await this.afterRenderPage(url);
       setTimeout(async () => {
         await this._hideLoading();
       }, 300);
    }

    async refreshToken(){
      if (!this.refreshTokenInProgress) {
        const isLogin = await Autentication.isLogin();
        if (isLogin) {
          this.refreshTokenInProgress = true;
          let dateNow = new Date()
          let dateExpiredIn = new Date(await Autentication.getExpireIn())
          if (dateNow >= dateExpiredIn) {
             await ApiLogin.RefreshToken();
          }
          this.refreshTokenInProgress = false;

        }
      }
    }

    async finalRefreshToken(){
     
        const isLogin = await Autentication.isLogin();
        if (isLogin) {
          this.refreshTokenInProgress = true;
          let dateNow = new Date()
          let dateExpiredIn = new Date(await Autentication.getExpireIn())
          if (dateNow >= dateExpiredIn) {
             await ApiLogin.RefreshToken();
          }
          this.refreshTokenInProgress = false;
          return true
        }
    }

    async afterRenderPage(url){
      SideBarHelper.removeActivator();
      SideBarHelper.activeByPage(url)
    }

    async _giveTheLoading(){
      mRefresh.refresh();
      document.body.style.pointerEvents = "none";
    }

    async _hideLoading(){
      mRefresh.resolve();
      document.body.style.pointerEvents = "auto";
    }

   async _setViewAppBar(){
      const datas = await Autentication.getData();
      datas.forEach( data => {
        document.querySelector('app-bar').setName = data;
      });
      await this.setProfilePhoto();

      const setUpNotificationAnggotaEvent =  async () =>{
        await this._setUpNotificationAnggota()
      }
      $('#alertsDropdown').off('click').on('click', async function(){
        await setUpNotificationAnggotaEvent()
      });
    }

    async setProfilePhoto(){
      const dataPrivate = await Autentication.getAvatar()
    
      if (dataPrivate != null && dataPrivate != undefined  && dataPrivate != "" && dataPrivate != "null") {
        $("#imgProfile").attr('src',dataPrivate);
      }else{
        $("#imgProfile").attr('src','./src/public/img/undraw_profile.svg');
      }

      if ( await MembershipHelper.isMemberShipPaid() == false) {
        document.getElementById("menu-anggota").innerHTML = "" 
        document.getElementById("menu-anggota").style.display = 'none';
         await this._lockAnggota()
      }else{
        if (document.getElementById("menu-anggota").style.display == 'none') {
          document.getElementById("menu-anggota").innerHTML = `	<a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerizinan"
          aria-expanded="true" aria-controls="collapsePerizinan">
          <i class=" fas fa-fw fa-clipboard"></i>
          <span>PERIZINAN</span>
        </a>
        <div id="collapsePerizinan" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Menu Perizinan:</h6>
            <a class="collapse-item sidebar-custom item-master"  href="#/skp-saya">SKP SAYA</a>
            <a class="collapse-item sidebar-custom item-master"  href="#/serkom-skp">SERKOM SKP</a>
            <a class="collapse-item sidebar-custom item-master" href="#/rekom-strttk">REKOMENDASI<BR>REGISTRASI ULANG<BR>STRTTK</a>
            <a class="collapse-item sidebar-custom item-master"  href="#/rekom-sipttk">REKOMENDASI<BR>REGISTRASI ULANG<BR>SIPTTK</a>
            <a class="collapse-item sidebar-custom item-master"  href="#/pengajuan-mutasi">PENGAJUAN MUTASI</a>
          </div>
        </div>`

        document.getElementById("menu-anggota").style.display = '';
        }
      }
      try {
        let role = await Autentication.getRole()
        const containsPC = role.some(item => item.includes('pc'));
        const containsPD = role.some(item => item.includes('pd'));

        if (containsPC || containsPD) {

           let checkMe = await ApiAnggota.checkIsPcPdLocked()
           if (checkMe.status_code == 200) {
             let valueInitialPc = document.getElementById('menu-pc').getAttribute('data-locked-initial')
             let valueInitialPd = document.getElementById('menu-pd').getAttribute('data-locked-initial')
            
             if (containsPC && checkMe.data.is_pc_locked ) {
               this._lockPc()
             }

             if (containsPD && checkMe.data.is_pd_locked) {
               this._lockPd()
             }

             document.getElementById('menu-pc').setAttribute('data-locked-initial', 'true');
             document.getElementById('menu-pd').setAttribute('data-locked-initial', 'true');
           }
        }
      } catch (error) {

      }

    }

    async _lockAnggota(){
      const alertDiv = document.createElement("div");
      alertDiv.className = "alert alert-warning";
      alertDiv.id = "alert-pc-anggota";
      alertDiv.innerHTML = '<strong>Perhatian!</strong> Anda tidak memiliki menu Perizinan. Karena Anda belum membayar Iuran Anggota.';
      const mainContentDiv = document.getElementById("main-content");
      mainContentDiv.insertBefore(alertDiv, mainContentDiv.firstChild);
    }

    async _lockPc(){
      document.getElementById("menu-pc").innerHTML =""
      const alertDiv = document.createElement("div");
      alertDiv.className = "alert alert-warning";
      alertDiv.id = "alert-pc-warning";
      alertDiv.innerHTML = '<strong>Perhatian!</strong> Anda tidak memiliki Menu PC!. Karena status PC anda LOCKED [Terkunci]. Hubungi Admin PD untuk Informasi lebih lanjut.';
      const mainContentDiv = document.getElementById("main-content");
      mainContentDiv.insertBefore(alertDiv, mainContentDiv.firstChild);
    }

    async _lockPd(){
        document.getElementById("menu-pd").innerHTML =""
        const alertDiv = document.createElement("div");
        alertDiv.className = "alert alert-warning";
        alertDiv.id = "alert-pd-warning";
        alertDiv.innerHTML = '<strong>Perhatian!</strong> Anda tidak memiliki Menu PD!. Karena status PD anda LOCKED [Terkunci]. Hubungi Admin Pusat untuk Informasi lebih lanjut.';
        const mainContentDiv = document.getElementById("main-content");
        mainContentDiv.insertBefore(alertDiv, mainContentDiv.firstChild);
    }

    async _setNotification(){
      const isLogin = await Autentication.isLogin(); 
      if (isLogin) {
        let role = await Autentication.getRole();
        await this.refreshToken();
        if (role != null) {
          for (let index = 0; index < role.length; index++) {
            const value = role[index];
            if (value == "pp") {
              this._setUpNotificationPP()
            }else if (value == "anggota") {
              this._setUpCountNotificationAnggota()
            }
            else if (value == "pd") {
              this._setUpNotificationPD()
            }
            else if (value == "pc") {
              this._setUpNotificationPC()
            }
          }
        }
      }
    }

    async _setUpCountNotificationAnggota(){
      const dataUnreadNotification = await ApiNotication.getCountUnreadNotification();
      if (dataUnreadNotification.data == undefined) {
        return
      }else{
        document.getElementById("count-notification-anggota").innerHTML = dataUnreadNotification.data;
      }
    }

     isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }


    async _setUpNotificationAnggota(){   
      try {
        document.getElementById("drop-notification").innerHTML = "";
        const dataUnreadNotification = await ApiNotication.getUnreadNotification();
        let item = ``
        const dataNotification = dataUnreadNotification.data;
        let idElmNotification = 0
        dataNotification.forEach(data => {
          idElmNotification++
          let timeCreated =  new Date(data.created_at).toLocaleDateString('en-US') + " " + new Date(data.created_at).toLocaleTimeString('en-US') ;
          if (this.isValidDate(timeCreated) == false) {
            timeCreated = data.created_at
          }
          let title =data.data.title;
          let content = data.data.body;
          let action = NotificationRoutes.getRouting(data.notifiable_level, data.data.type);
          item +=  ` <a class="dropdown-item d-flex align-items-center" id="read_action${idElmNotification}"  href="${action}">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas fa-file-alt text-white"></i>
                            </div>
                        </div>
                        <div>
                            <div class="small text-gray-500">${timeCreated}</div>
                            <div class="font-weight-bold">${title}</div>
                            <span class="font-weight-light">${content}</span>
                        </div>
                    </a>`
        }); 
  
        document.getElementById("drop-notification").innerHTML = item;
        let idElmNotificationAction = 0
        dataNotification.forEach(data =>{
          idElmNotificationAction++
          document.getElementById('read_action'+idElmNotificationAction).addEventListener('click', async ()=>{
           await this._readNotification(data.id)
          }); 
         })  
      } catch (error) {
        
      }
    }

    async _readNotification(notificationID){	
        let request = await ApiNotication.readNotification(notificationID);
       
        if (request.status_code == 200) {
            await this._setUpNotificationAnggota()
        }else{
            console.log(`"error status notification ${request.status_code}  ${request.message}"`)
        }
	  }

    async _setUpNotificationPC(){
      document.getElementById("containerNotificationPC").style.display = '';
     
      const dataUnreadNotification = await ApiNotication.getNotificationPC();
      if (dataUnreadNotification.status_code == 200) {
        const dataNotification = dataUnreadNotification.data;
        document.getElementById("counter_notif_pc_permohonan_serkom_skp").innerHTML = dataNotification.permohonan_serkom_skp
        document.getElementById("counter_notif_pc_permohonan_rekom_strttk").innerHTML = dataNotification.permohonan_rekom_strttk
        document.getElementById("counter_notif_pc_permohonan_rekom_sipttkk").innerHTML = dataNotification.permohonan_rekom_sipttk
        document.getElementById("counter_notif_pc_permohonan_skp").innerHTML = dataNotification.permohonan_skp
        document.getElementById("counter_notif_pc_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
        document.getElementById("counter_notif_pc_permohonan_mutasi_in").innerHTML = dataNotification.permohonan_mutasi_in
        document.getElementById("counter_notif_pc_permohonan_mutasi_out").innerHTML = dataNotification.permohonan_mutasi_out
        document.getElementById("counter_notif_pc_user_membership_unpaid").innerHTML = dataNotification.user_membership_unpaid
        document.getElementById("counter_notif_pc_kecukupan_skp").innerHTML = dataNotification.permohonan_kecukupan_skp
        document.getElementById("counter_notif_pc").innerHTML = dataNotification.permohonan_skp + dataNotification.permohonan_rekom_sipttk + dataNotification.permohonan_rekom_strttk + dataNotification.permohonan_serkom_skp + dataNotification.permohonan_ktan
    }}

    async _setUpNotificationPD(){
      document.getElementById("containerNotificationPD").style.display = '';
      const dataUnreadNotification = await ApiNotication.getNotificationPD();
      if (dataUnreadNotification.status_code == 200) {
        const dataNotification = dataUnreadNotification.data;
        document.getElementById("counter_notif_pd_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
        document.getElementById("counter_notif_pd_permohonan_serkom_skp").innerHTML = dataNotification.permohonan_serkom_skp
        document.getElementById("counter_notif_pd_permohonan_rekom_strttk").innerHTML = dataNotification.permohonan_rekom_strttk
        document.getElementById("counter_notif_pd_permohonan_skp").innerHTML = dataNotification.permohonan_skp
        document.getElementById("counter_notif_pd_permohonan_mutasi_in").innerHTML = dataNotification.permohonan_mutasi_in
        document.getElementById("counter_notif_pd_permohonan_mutasi_out").innerHTML = dataNotification.permohonan_mutasi_out
        document.getElementById("counter_notif_pd_seminar_in").innerHTML = dataNotification.permohonan_seminar_in
        document.getElementById("counter_notif_pd_seminar_payment").innerHTML = dataNotification.pembayaran_seminar_need_confirmed
        document.getElementById("counter_notif_pd_kecukupan_skp").innerHTML = dataNotification.permohonan_kecukupan_skp
        document.getElementById("counter_notif_pd").innerHTML = dataNotification.permohonan_ktan + dataNotification.permohonan_serkom_skp + dataNotification.permohonan_rekom_strttk + dataNotification.permohonan_skp + dataNotification.permohonan_seminar_in + dataNotification.pembayaran_seminar_need_confirmed

    }}

    async _setUpNotificationPP(){
      document.getElementById("containerNotificationPP").style.display = '';
      const dataUnreadNotification = await ApiNotication.getNotificationPP();
      if (dataUnreadNotification.status_code == 200) {
        const dataNotification = dataUnreadNotification.data;
        document.getElementById("counter_notif_pp_permohonan_ktan").innerHTML = dataNotification.permohonan_ktan
        document.getElementById("counter_notif_pp_register_need_confirmed").innerHTML = dataNotification.register_need_confirmed
        document.getElementById("counter_notif_pp_seminar_in").innerHTML = dataNotification.permohonan_seminar_in
        document.getElementById("counter_notif_pp_seminar_payment").innerHTML = dataNotification.pembayaran_seminar_need_confirmed
        document.getElementById("counter_notif_pp_seminar_agreement").innerHTML = dataNotification.seminar_unsigned
        document.getElementById("counter_notif_pp").innerHTML = dataNotification.register_need_confirmed + dataNotification.permohonan_ktan + dataNotification.permohonan_seminar_in + dataNotification.pembayaran_seminar_need_confirmed + dataNotification.seminar_unsigned

    }}


    async _checkStatusJaringan(){
      window.addEventListener("load", async () => {
        const statusJaringan = document.getElementById("statusJaringan");
        statusJaringan.innerHTML == '';

        const onLineOffLine = async ()=>{
          const kondisiJaringan = navigator.onLine
          statusJaringan.classList.remove("alert-danger");
          statusJaringan.classList.remove("alert-success");
          statusJaringan.classList.add("text-center");
          statusJaringan.classList.add("m-0");

          if (!kondisiJaringan) {
              statusJaringan.innerHTML = `<i class="fas fa-wifi-slash"></i> Kamu sedang <strong>offline</strong>, cek koneksi internet kamu ya`;
              statusJaringan.classList.add("alert");
              statusJaringan.classList.add("alert-danger");
              statusJaringan.style.display = "block"
              return
          }else {
              statusJaringan.innerHTML = `<i class="fas fa-wifi"></i> Kamu kembali <strong>online</strong>`;              
              statusJaringan.classList.add("alert");
              statusJaringan.classList.add("alert-success");

              setTimeout(() => {
                statusJaringan.style.display = "none"
                statusJaringan.style.opacity = "3s linear"
              }, 3000)

              return
          }

        }

        window.addEventListener("online", onLineOffLine);
        window.addEventListener("offline", onLineOffLine);
      })
    }
}
  
  export default App;
  