import SERKOMAnggotaPCPresenter from "../../../presenter/menu_pengurus_pc/serkom-anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const SERKOMAnggotaPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiSERKOM" class="nav-link hapus-border active" id="data-verifikasi-skp" data-bs-toggle="tab" data-bs-target="#tabVerifikasiSERKOM" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiSERKOM" aria-selected="true">Data Permohonan</button>
					</li>
					<li class="nav-item" role="presentation">
					    <button href="#tabPersyaratanSERKOM" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabPersyaratanSERKOM" data-toggle="tab" type="button" role="tab" aria-controls="tabPersyaratanSERKOM" aria-selected="false">Persyaratan</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiSERKOM" role="tabpanel aria-labelledby="tab-verifikasi-skp">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status</label>
                                                <select id="selectStatus" class="form-control">
                                                    <option value="pending">Pending</option>
                                                    <option value="approve">Disetujui</option>
                                                    <option value="revise">Revisi</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Dari Tanggal</label>
                                                <input type="date" class="form-control" id="startDate">
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                                <input type="date" class="form-control" id="endDate">
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <button type="button" class="btn btn-primary" id="btnFilter">
                                                    <i class="fas fa-filter"></i> Filter Data
                                                </button>
                                                <button type="button" class="btn btn-warning" id="btnResetFilter">
                                                    <i class="fas fa-undo"></i> Reset Filter
                                                </button>
                                                <button type="button" class="btn btn-info" id="btnRefresh">
                                                    <i class="fas fa-sync-alt"></i> Refresh Data
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Pengajuan Serkom Anggota
                                    </div>
                                    <div class="card-body">
                                        <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                                            <div class="row">
                                                <div class="col-sm-8 mb-2">
                                                
                                                </div>
                                                <div class="col-sm-4 d-none">
                                                    <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                        <i class="fas fa-check"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                        <i class="fas fa-print"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                        <i class="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanSERKOM" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAll"></th>
                                                                <th data-column="created_at" >Tanggal Pengajuan</th>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th data-column="ktan" >KTAN</th>
                                                                <th>Tanggal Serkom</th>
                                                                <th> Nomor Serkom</th>
                                                                <th data-column="status">Status</th>
                                                                <th style="width:10%">Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

					<div class="tab-pane fade" id="tabPersyaratanSERKOM" role="tabpanel" aria-labelledby="pengajuan-seminar-tab">
                       
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Informasi Persyaratan Serkom
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Informasi Serkom</label>
                                            <div class="col-sm-12">
                                                <textarea id="textInformasiSerkom" class="form-control" placeholder="Informasi Persyaratan..."></textarea>
                                            </div>
                                        </div>

                                        <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateInformasiSerkom"><i class='fas fa-edit'></i> Update Informasi</button>       
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Formulir Persyaratan Serkom
                                    </div>
                                    <div class="card-body">
                                        <form id="form-persyaratan-serkom">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
                                                <div class="col-sm-12">
                                                    <input type="hidden" id="textHIddenPersyaratan" class="form-control">
                                                    <input type="text" id="textNamaBerkas" class="form-control" placeholder="Nama Berkas..." required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Deskripsi</label>
                                                <div class="col-sm-12">
                                                    <textarea id="textDeskripsi" class="form-control" placeholder="Deskripsi Persyaratan..." required></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                                <div class="col-sm-12 text-right">
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnAddSyaratSerkom"><i class='fas fa-plus'></i> Tambah Persyaratan</button>
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateSyaratSerkom"><i class='fas fa-edit'></i> Edit Persyaratan</button>
                                                    <button type="button" class="mb-2 btn btn-sm btn-light border border-primary" id="btnClearSyaratSerkom"><i class='fas fa-eraser'></i> Batal</button>  
                                                </div>     
                                            </div>     
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header  font-weight-bold text-primary">
                                        Data Persyaratan Serkom
                                    </div>
                                    <div class="card-body">
                        
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover" id="tablePersyaratanSerkom" width="100%" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Berkas</th>
                                                        <th>Deskripsi</th>
                                                        <th width="10%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    
                                                </tfoot>
                                                <tbody>
                                                
                                                
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>

        <div class="modal fade" id="detailSerkomModal" tabindex="-1" role="dialog" aria-labelledby="detailSerkomModal"
        aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header font-weight-bold text-primary">
                    Detail Pengajuan Serkom
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="bodyModalSerkom">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">Tanggal Pengajuan</label>
                                    <input type="text" id="tanggalPengajuan" class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">KTAN</label>
                                    <input type="text" id="textKTAN" placeholder="KTAN..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">Nama Anggota</label>
                                    <input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">Tempat Lahir</label>
                                    <input type="text" id="textTempatLahir" placeholder="Tempat Lahir..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">Tanggal Lahir</label>
                                    <input type="text" id="textTanggalLahir" placeholder="Tanggal Lahir..." class="form-control" readonly>
                                </div>
                                <div class="col-sm-12">
                                    <label class="label control-label font-weight-bold">Alamat</label>
                                    <textarea type="text" rows="4" id="textAlamat" placeholder="Alamat..." class="form-control" readonly></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data Pendidikan</label>
                                <div class="col-sm-12">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Tahun Lulus</th>
                                            <th>Jenjang</th>
                                            <th>Nama Institusi</th>
                                            <th>Tanggal Ijazah</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="dataPendidikanSerkom">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Data STRTTK</label>
                                <div class="col-sm-12">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>No STRTTK</th>
                                            <th>Tanggal</th>
                                            <th>Berlaku Sampai</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="dataSTRTTK">
    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="row">
                                <label class="label col-sm-12 control-label font-weight-bold">Persyaratan</label>
                                <div class="col-sm-12">
                                    <table class="table table-bordered table-hovered" width="100%">
                                        <thead>
                                            <th>Nama Persyaratan</th>
                                            <th class="text-center">Dokumen</th>
                                        </thead>
                                        <tbody id="persyaratanSerkom">
        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="card">
                                        <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                            <h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
                                        </div>
                                        <div class="card-body chat-body p-1" id="lamanChat">
                                            <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                                <div style="width:100%">
                                                    <div style="width:10%;" class="float-left">
                                                        <div class="shimmer circle"></div>
                                                    </div>
                                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line end"></div>
                                                    </div>
                                                </div>
                                                <div style="width:100%">
                                                    <div style="width:10%;" class="float-right">
                                                        <div class="shimmer circle"></div>
                                                    </div>
                                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line end"></div>
                                                    </div>
                                                </div>
                                                <div style="width:100%">
                                                    <div style="width:10%;" class="float-left">
                                                        <div class="shimmer circle"></div>
                                                    </div>
                                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line end"></div>
                                                    </div>
                                                </div>
                                                <div style="width:100%">
                                                    <div style="width:10%;" class="float-right">
                                                        <div class="shimmer circle"></div>
                                                    </div>
                                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line"></div>
                                                        <div class="shimmer content-line end"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <form id="form-comment">
                                                <label class="control-label font-weight-bold">Komentar</label>
                                                <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                                <br>
                                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    


<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Revisi & Berikan Komentar !</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea name="komentarPengajuan" id="textKomentarPengajuan" class="form-control" placeholder="Komentar..." required></textarea>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                <a class="btn btn-primary" id="btnSendKomentarPengajuan"><i class="fas fa-comment"></i> Revisi & Berikan Komentar</a>
            </div>
        </div>
    </div>
</div>
        
        `;
		return view;
	  },

	async afterRender() {
		await SERKOMAnggotaPCPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SERKOMAnggotaPC;