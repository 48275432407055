import ApiLogin from "../api/data-login.js";
import Autentication from "./autentication.js"



let isResponseDebounced = false;


const NetworkHelper= {
      
   async isResponseAccepted(response , func) {
    try {
        if (response.status_code == 423) {
            let message = response.message;
            let pages = `#/423?note=${message}`
            Autentication.autoLogout()
            window.location.hash = pages;
            return
        }
        if (response.status_code != 401) {
          return true;
        } else {
          if (!isResponseDebounced) {
            isResponseDebounced = true;
             await ApiLogin.RefreshToken();
             await func()
            setTimeout(() => {
              isResponseDebounced = false;
            }, 5000); 
          }
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    
    actionResponseNotAccepted(){
        window.location.hash = '#/login?action=login';
        Autentication.saveAlert("true")
    }
    
    
}


export default NetworkHelper;
