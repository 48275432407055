import CONFIG from './config.js';

const API_PENGURUS_PD_ENDPOINT = {
	
	IP_SERVER: `${CONFIG.BASE_URL}login/ipServer`,
	LOGIN: (role) => `${CONFIG.BASE_URL}login/${role}`,
	LOGOUT : `${CONFIG.BASE_URL}logout`,

	GET_REQ_BLANGKO : (status,startDate,endDate) => `${CONFIG.BASE_URL}blangko/list/user?start_date=${startDate}&end_date=${endDate}&status=${status}`,

	SEND_BUKTI_BAYAR_BLANGKO : `${CONFIG.BASE_URL}blangko/paid`,
	CREATE_INVOICE_BLANGKO : `${CONFIG.BASE_URL}blangko/request`,
	CANCEL_INVOICE_BLANGKO : (invoiceID) => `${CONFIG.BASE_URL}blangko/cancel/${invoiceID}`,

	
	GET_COMMENT_PENGAJUAN: (userID,commentFor,pengajuanID) => `${CONFIG.BASE_URL}comment/admin/${userID}/${commentFor}/${pengajuanID}`,
	SEND_COMMENT: `${CONFIG.BASE_URL}comment/admin/pd`,
    
	PENGAJUAN_KTAN_DEFAULT: `${CONFIG.BASE_URL}pengajuan/admin/pd?jenis_pengajuan=membership`,
	PENGAJUAN_KTAN_FILTERED: (id_cabang,status) => `${CONFIG.BASE_URL}pengajuan/admin/pd?jenis_pengajuan=membership&id_cabang=${id_cabang}&status=${status}`,
	APPROVE_KTAN:  (pengajuanID) => `${CONFIG.BASE_URL}pengajuan/approval/pd/${pengajuanID}`,
	APPROVE_All_KTAN: `${CONFIG.BASE_URL}pengajuan/approval/pd/bulk`,

	GENERAL_SETTING: `${CONFIG.BASE_URL}setting/daerah/general`,
	
	GET_ALL_USER_KTAN_1: (status_ktan,id_cabang, jenjang) => `${CONFIG.BASE_URL}users/daerah?status_regis=all&status_ktan=${status_ktan}&id_cabang=${id_cabang}&jenjang=${jenjang}`,

	SKP:(status,id_cabang) => `${CONFIG.BASE_URL}skp/admin/pd?status=${status}&id_cabang=${id_cabang}`,
	SKP_DETAILS : (userID,status) => `${CONFIG.BASE_URL}skp/user/${userID}?status_pd=${status}`,
	APPROVE_SKP: (pengajuanSKPID) => `${CONFIG.BASE_URL}skp/approval/pd/${pengajuanSKPID}`,

	
	APPROVE_SERKOM: (pengajuanSERKOMID) => `${CONFIG.BASE_URL}rekom/serkom/approval/pd/${pengajuanSERKOMID}`,
	APPROVE_All_SERKOM: `${CONFIG.BASE_URL}rekom/serkom/approval/pd/bulk`,

	APPROVE_All_SKP_PD: `${CONFIG.BASE_URL}skp/approval/pd/bulk`,

	DOWNLOAD_SERKOM_BULK: `${CONFIG.BASE_URL}print/serkom/bulk`,
	SERKOM : (status, startDate, endDate, selectPC) => `${CONFIG.BASE_URL}rekom/serkom/admin/pd?status=${status}&start_date=${startDate}&end_date=${endDate}&id_cabang=${selectPC}`,
	STRTTK : (status) => `${CONFIG.BASE_URL}rekom/strttk/admin/pd?status=${status}`,
	APPROVE_STRTTK :  (pengajuanID) => `${CONFIG.BASE_URL}rekom/strttk/approval/pd/${pengajuanID}`,


	SERKOM_INFORMATION : `${CONFIG.BASE_URL}setting/informasi/serkom`,
	
	PERSYARATAN_SERKOM: `${CONFIG.BASE_URL}setting/additional/serkom`,

	PERSYARATAN_STRTTK: `${CONFIG.BASE_URL}setting/additional/strttk`,	 
	UPDATE_PERSYARATAN: (persyaratanID) => `${CONFIG.BASE_URL}setting/additional/${persyaratanID}`,

	GET_KOMENTAR_SERKOM:(userID) => `${CONFIG.BASE_URL}comment/admin/${userID}/rekom_serkom`,
	SEND_KOMENTAR: `${CONFIG.BASE_URL}comment/admin/pd`,	

	
	PERIODE_KEPENGURUSAN: `${CONFIG.BASE_URL}setting/kepengurusan/periode/pd`,
	PERIODE_KEPENGURUSAN_BY_ID: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,
	UPDATE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/pd/${id}`,
	DELETE_PERIODE_KEPENGURUSAN: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/periode/${id}`,

	
	ANGGOTA: (status_regis,status_ktan) => `${CONFIG.BASE_URL}users/daerah?status_regis=${status_regis}&status_ktan=${status_ktan}`,
	
	
	PENGURUS : `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pd`,
	PENGURUS_BY_ID : (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_PENGURUS: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/pd/${id}`,
	DELETE_PENGURUS: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/${id}`,
	UPDATE_TTD: (id) => `${CONFIG.BASE_URL}setting/kepengurusan/pengurus/media/${id}`,


	GET_ALL_MUTASI_DATA:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pd?status=${status}`,
	GET_DETAIL_MUTASI_PD:(id) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pd/${id}`,
	ACC_PD_ASAL_MUTASI:(id) => `${CONFIG.BASE_URL}rekom/mutasi/approval/pd/asal/${id}`,
	ACC_PD_TUJUAN_MUTASI:(id) => `${CONFIG.BASE_URL}rekom/mutasi/approval/pd/tujuan/${id}`,
	GET_ALL_LOG_MUTASI_DATA: () => `${CONFIG.BASE_URL}rekom/mutasi/admin/pd/manual`,

	GET_ALL_MUTASI_DATA_IN:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pd/in?status=${status}`,
	GET_ALL_MUTASI_DATA_OUT:(status) => `${CONFIG.BASE_URL}rekom/mutasi/admin/pd/out?status=${status}`,

	
	DOWNLOAD_KTAN : (id) => `${CONFIG.BASE_URL}print/ktan/user/${id}`,
};

export default API_PENGURUS_PD_ENDPOINT;
