
import Autentication from "../utils/autentication.js";
var currentScale = '';
const ImagePopUPInitiator = {

  async init() {
    await this.getImagePopUP();
    await this.getImage()
  },

  async getImagePopUP() {
    $('table').on('click', 'img', function () {
      
      var modal = document.getElementById("modalPopUpImage");



      // Get the image and insert it inside the modal - use its "alt" text as a caption
      var img = $(this).attr('src');
      var parts = img.split("/");
      var fileName = parts[parts.length - 1];
      var modalImg = document.getElementById("imgPopUp");
      modalImg.style.transform = `rotate(0deg)`
      modalImg.style.width = `30%`
      var rotate = 0;
      var scale = window.getComputedStyle(modalImg).getPropertyValue('width');
      scale = scale.split('%')
      currentScale = scale[0]
      ImagePopUPInitiator._initRotate(rotate)
      ImagePopUPInitiator._initZoomIn(currentScale)
      var captionText = document.getElementById("caption");


      $('#modalPopUpImage').modal('show');

      modalImg.src = img;
      captionText.innerHTML = fileName;

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("btn-close")[0];

      // When the user clicks on <span> (x), close the modal
      $(document).keydown(function (event) {
        if (event.key === "Escape" && $('#modalPopUpImage').is(':visible')) {
          $('#modalPopUpImage').modal('hide');
        }
      });
      $('.btn-close').click(function () {
        ('#modalPopUpImage').modal('hide');
      });
    })

    

  },
  async _initRotate(rotate){
    $(".btn-rotate").on("click", function () {
      rotate = rotate+90;
      var modalImg = document.getElementById("imgPopUp");
      modalImg.style.transform = `rotate(${rotate}deg)`
    })
  },
  async _initZoomIn(currentScale){
    let scale = currentScale
    $(".btn-zoom-in").on("click", function () {
      scale = parseInt(scale)+parseInt(5);
      var modalImg = document.getElementById("imgPopUp");
      modalImg.style.width = `${scale}%`
      currentScale = scale

    })
    
    $(".btn-zoom-out").on("click", function () {
      scale = parseInt(scale)-parseInt(5);
      var modalImg = document.getElementById("imgPopUp");
      modalImg.style.width = `${scale}%`
      currentScale = scale
    })
  },
  async getImage(){
    $('.show-image-pop-up').on('click', function () {
      var modal = document.getElementById("modalPopUpImage");

      // Get the image and insert it inside the modal - use its "alt" text as a caption
      var img = $(this).attr('src');
      var parts = img.split("/");
      var fileName = parts[parts.length - 1];
      var modalImg = document.getElementById("imgPopUp");
      var captionText = document.getElementById("caption");


      $('#modalPopUpImage').modal('show');

      modalImg.src = img;
      captionText.innerHTML = fileName;

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close")[0];

      // When the user clicks on <span> (x), close the modal
      $(document).keydown(function (event) {
        if (event.key === "Escape" && $('#modalPopUpImage').is(':visible')) {
          $('#modalPopUpImage').modal('hide');
          modalImg.style.transform = `rotate(0deg)`
        }
      });
      $('.close').click(function () {
        ('#modalPopUpImage').modal('hide');
        modalImg.style.transform = `rotate(0deg)`
      });
    })
  }

}

export default ImagePopUPInitiator;

