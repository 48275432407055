
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiPengajuanSerkomPC from '../../api/api_pengurus_pc/pengajuan-serkom.js';
import NotificationModal from "../../utils/initial_notification.js";
import UrlParser from "../../routes/url-parser.js";
import ApiNotication from "../../api/data-notification.js";
import MembershipHelper from "../../utils/membership-helper.js";

const SerkomPresenter = {

	async init() {
		//await MembershipHelper.scyncMembershipHelper();
		await this._setView();
		await this._getSUMSKP();
		await this._getFormulirSerkom();
		await this._sendPengajuanSerkom();
		await this._uploadBerkas();
		await this._getPengajuanSerkom();
		await this._getInformation();
		await this._readNotification()
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},
	
	async _ubahFormatTanggal(tanggal){
		// Membagi tanggal menjadi komponen hari, bulan, dan tahun
		var komponenTanggal = tanggal.split("-");
		var hari = komponenTanggal[0];
		var bulan = komponenTanggal[1];
		var tahun = komponenTanggal[2];
	  
		// Membentuk tanggal baru dalam format "yyyy-MM-dd"
		var tanggalBaru = tahun + "-" + bulan + "-" + hari;
	  
		return tanggalBaru;
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
			const eventChangeBerkas= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas").classList.remove("d-none")
					document.getElementById("valueAlertBerkas").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas").addEventListener("change", eventChangeBerkas);
		} else {
			window.location.hash = '#/not-access-fitur';
		}
		$("#btnUpdateSKP").hide();
	},

	async _showLoading() {

	},

	async _hideLoading() {

	},

	async _getSUMSKP() {
		this._showLoading();

		this._hideLoading();

	},


	async _getPengajuanSerkom() {
		var status = 'all';
		let table = await $('#tablePengajuanSerkom').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_ANGGOTA.PENGAJUAN_SERKOM}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'created_at' },
				{ data: 'serkom.tanggal' },
				{ data: 'serkom.nomor_serkom' },
				{
					data: 'status', render: function (data) {
						if (data == 'Pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'Revisi PC') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'Revisi PD') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{data : "comment_unread_count", render : function (data) {
					let content = ``
					if (data <= 0) {
						content = `-`
					}else {
						content =  `<span class="badge bg-info text-light font-weight-none text-capitalize text-center" id="status">${data} Belum terbaca</span>`
					}

					return content
				}}
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 5,
					"data": null,
					"defaultContent": `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSerkomModal" id='detail' title='Detail'><i class="fas fa-list"></i></button>
                     <button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
			]
		});

		let id = '';
		$('#tablePengajuanSerkom tbody').on('click', '#detail', async function () {
			$('#btnSendComment').on('click');
			let data = table.row($(this).parents('tr')).data();
			id = data.id;
			$("#hiddenIdPengajuan").val(id);
			let table_tracking = await $("#tableHistoryVerifikasiSERKOM").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_ANGGOTA.GET_TRACKING_SERKOM(id)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{
						"data": null,
						"class": "align-top",
						"searchable": false,
						"render": function (data, type, row, meta) {
							return meta.row + meta.settings._iDisplayStart + 1;
						}
					},
					{ data: 'created_at' },
					{ data: 'actor.name' },
					{ data: 'actor_type' },
					{ data: 'description' },
				],

				"order": [[0, 'desc']],
			});


			$("#persyaratanSerkom").html('');

			const getDetailPengajuanSerkom = await ApiAnggota.getPengajuanSerkomByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;
			if (getDetailPengajuanSerkom.status_code == 200) {
				$("#tanggalPengajuan").val(dataDetail.created_at);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#tanggalSerkom").val(await SerkomPresenter._ubahFormatTanggal(dataDetail.serkom.tanggal));
				$("#nomorSerkom").val(dataDetail.serkom.nomor_serkom);

				var dokumen = dataDetail.dokumen
				var persyaratanSerkom = ``
				dokumen.forEach(dataBerkas => {
					 persyaratanSerkom += `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`
				});

				$("#persyaratanSerkom").html(persyaratanSerkom);
			}


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			let response = await ApiAnggota.getCommentar(id,"rekom_serkom")
			let dataComment = response.data;
			dataComment.reverse();
			let getAllContent = async () => {
				let elmHtml = ``
				await dataComment.forEach(async element => {
					elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
				});
				return elmHtml
			}


			if (response.data == undefined || response.data.length <= 0) {
				document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
			} else {
				document.getElementById("content-comment").innerHTML = await getAllContent();

				setTimeout(() => {
					var elem = document.getElementById('content-comment');
					elem.scrollTop = elem.scrollHeight;
				}, 400);
			}

			$("#btnSendComment").on('click', async function (event) {

				

				if (UserID != '' && id != '') {
					document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					let content = document.getElementById("textKomentar").value
					const response = await ApiAnggota.sendCommentar({
						pengajuanID: id,
						contentComment: content,
						commentFor: 'rekom_serkom',
					});
					if (response.status_code == 200 || 201) {

						let getContentAdmin = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-left text-dark text-left" style="width:88%;">
											<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}

						let getContentUser = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-right text-dark text-left" style="width:88%;">
											<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}


						let response = await ApiAnggota.getCommentar(id,"rekom_serkom")
						let dataComment = response.data;
						dataComment.reverse();

						let getAllContent = async () => {
							let elmHtml = ``
							await dataComment.forEach(async element => {
								elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
							});
							return elmHtml
						}

						if (response.data == undefined || response.data.length <= 0) {
							document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
						} else {
							document.getElementById("content-comment").innerHTML = await getAllContent();

							setTimeout(() => {
								var elem = document.getElementById('content-comment');
								elem.scrollTop = elem.scrollHeight;
							}, 400);
						}
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					} else {
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					}



				}
				
				// $(this).on('click')

				// $(this).off('click');

			});

			$('#detailSerkomModal').on('hidden.bs.modal', async function (e) {
				$('#btnSendComment').off('click');
			});
		});

		$('#tablePengajuanSerkom tbody').on('click', '#delete', async function () {
			let data = table.row($(this).parents('tr')).data();

			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Tanggal Pengajuan </strong>: " + data.created_at,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idSerkom = data.id;
					const deletePengajuanSerkom = await ApiAnggota.deletePengajuanSerkom({
						id: idSerkom
					});

					if (deletePengajuanSerkom.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePengajuanSerkom.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePengajuanSerkom').DataTable().ajax.reload();

					} else {
						swal.fire({
							title: 'Error',
							text: deletePengajuanSerkom.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});
		});

		// $('#detailSerkomModal').on('hidden.bs.modal', function () {
		// 	// Menghapus cache pada browser
		// 	location.reload(true);
		//   });

		$('#detailSerkomModal').on('hidden.bs.modal', function () {

			$('#detailSerkomModal').off('shown.bs.modal');
		});

	},


	async _getInformation() {
		const getInformation = await ApiPengajuanSerkomPC.getInformationSerkom();
		if (getInformation.status_code == 404) {
			$("#information").html('Informasi belum di isi !');
		} else {
			$("#information").html(getInformation.data.description);
		}
	},

	async _getFormulirSerkom() {


		$("#textNamaLengkap").attr('placeholder','Loading...');
		$("#textKTAN").attr('placeholder','Loading...');
		$("#textTempatLahir").attr('placeholder','Loading...');
		$("#dateTanggalLahir").attr('placeholder','Loading...');
		$("#textJenisKelamin").attr('placeholder','Loading...');
		$("#textAlamatDomisili").attr('placeholder','Loading...');
		$("#selectPendidikan").attr('placeholder','Loading...');
		$("#selectPekerjaan").attr('placeholder','Loading...');


		this._getPendidikan();
		this._getPekerjaan();
		const getFormulir = await ApiAnggota.getFormulirSerkom();

		let getSKP = await ApiAnggota.getSKP();
		let dataSKP = getSKP.additional;

		let dataformulir = getFormulir.data;
		let data = getFormulir.data;

		var jmlSKPBaksos = 0;
		var jmlSKPMasaKerja = 0;
		var jmlSKPPendidikan = 0;
		var jmlSKP = 0;
		$('#alertWarning').html('<i class="fas fa-exclamation-triangle"></i> <strong>Warning : Anda tidak dapat mengajukan SERKOM SKP! </strong>');

		jmlSKPBaksos = dataSKP.skp_baksos
		jmlSKPMasaKerja = dataSKP.skp_masa_kerja
		jmlSKPPendidikan = dataSKP.skp_pendidikan
		jmlSKP = dataSKP.skp_total;

		if (dataformulir.ktan == null || dataformulir.ktan == "") {
			var warning_ktan = `<li><strong>Anda Belum Memiliki KTAN !</strong></li>`;
			$('#alertWarning').append(warning_ktan);
			$("#alertWarning").show();
		}
		if (dataformulir.berkas == null || dataformulir.domisili == null || dataformulir.jenis_kelamin == null || dataformulir.pekerjaan == null || dataformulir.pendidikan == null) {
			var warning_biodata = `<li><strong>Data diri anda belum lengkap !</strong></li>`;
			$('#alertWarning').append(warning_biodata);
			$("#alertWarning").show();
		}

		if (jmlSKP < 25) {

			var warning_skp = `
				<li>Jumlah <strong>SKP</strong> Anda adalah : <span id="jml-skp" class="font-weight-bold">0</span></li>
				<li><i>Setidaknya anda harus memiliki <strong>25 SKP</strong></i> !</li>
			`;

			$('#alertWarning').append(warning_skp);
			$("#alertWarning").show();
			$("#alertSuccess").hide();
			$("#jml-skp").html(jmlSKP);

		} else {
			$("#alertWarning").hide();
			$("#alertSuccess").show();
			$("#jml-skp-success").html(jmlSKP);
		}


		if (data.domisili == null || data.jenis_kelamin == null || data.ktan == null || data.name == null || data.pekerjaan == null || data.pendidikan == null || data.phone_number == null || data.serkom == null || data.tanggal_lahir == null || data.tanggal_lahir == null || data.tempat_lahir == null) {
			swal.fire({
				title: 'Profile anda belum lengkap !',
				html: "Silakan lengkapi profile anda !",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oke'
			}).then(async (result) => {
				if (result.value) {
					window.location.hash = '/';
				};
			});
		}
		let berkas = data.berkas;


		berkas.forEach(dataBerkas => {
			let option= new Option(dataBerkas.name, dataBerkas.document_name)
			option.setAttribute("description-lng",dataBerkas.description)
			document.getElementById("selectBerkas").add(option)
		});

		if (getFormulir.status_code != 200) {
			NotificationModal.show(`${getFormulir.message}`, 'error');
		} else {
			var jenis_kelamin = '';
			if (data.jenis_kelamin == 'Laki-laki') {
				jenis_kelamin = 'Laki-laki'
			} else {
				jenis_kelamin = 'Perempuan'
			}
			$("#textNamaLengkap").val(data.name);
			$("#textKTAN").val(data.ktan);
			$("#textTempatLahir").val(data.tempat_lahir);
			$("#dateTanggalLahir").val(data.tanggal_lahir);
			$("#textJenisKelamin").val(jenis_kelamin);
			$("#textAlamatDomisili").val(data.domisili.address + ', DESA / KELURAHAN ' + data.domisili.village_name + ', KECAMATAN ' + data.domisili.sub_regency_name + ', ' + data.domisili.regency_name + ', ' + data.domisili.province_name);

		}

		$("#selectPendidikan").change(async function () {
			$("#textNamaInstitusiPendidikan").attr('placeholder','Loading...');
			$("#textTahunLulus").attr('placeholder','Loading...');
			var idPendidikan = $(this).val();

			const getPendidikanByID = await ApiAnggota.getPendidikanByID({
				id: idPendidikan
			});
			let dataPendidikan = getPendidikanByID.data;

			if (getPendidikanByID.status_code != 200) {
				NotificationModal.show(`${getPendidikanByID.message}`, 'error');
			} else {
				let tanggalIjazah = dataPendidikan.tanggal_ijazah;
				var komponenTanggal = tanggalIjazah.split("-");
				var hari = komponenTanggal[0];
				var bulan = komponenTanggal[1];
				var tahun = komponenTanggal[2];
				var tanggalBaru = tahun + "-" + bulan + "-" + hari;

				$("#selectPendidikan").val(dataPendidikan.id);
				$("#textNamaInstitusiPendidikan").val(dataPendidikan.nama_institusi_pendidikan);
				$("#textTahunLulus").val(dataPendidikan.tahun_lulus);
				$("#dateTanggalIjazah").val(tanggalBaru);
			}
		});

		$("#selectPekerjaan").change(async function () {
			$("#textNamaTempatKerja").val('Loading...');
			$("#textAlamatTempatKerja").val('Loading...');
			$("#textNoTlpPekerjaan").val('Loading...');
			$("#textNoHPTempatKerja").val('Loading...');
			$("#emailTempatKerja").val('Loading...');
			var idPekerjaan = $(this).val();

			const getPekerjaanById = await ApiAnggota.getPekerjaanById(
				idPekerjaan
			);
			let dataPekerjaan = getPekerjaanById.data;

			if (getPekerjaanById.status_code != 200) {
				NotificationModal.show(`${getPekerjaanById.message}`, 'error');
			} else {
				$("#selectPekerjaan").val(dataPekerjaan.id);
				$("#textNamaTempatKerja").val(dataPekerjaan.nama_tempat_kerja);
				$("#textAlamatTempatKerja").val(dataPekerjaan.alamat_tempat_kerja);
				$("#textNoTlpPekerjaan").val(dataPekerjaan.telp_fax);
				$("#textNoHPTempatKerja").val(dataPekerjaan.phone_number);
				$("#emailTempatKerja").val(dataPekerjaan.email);
			}
		});

	},

	async _getPendidikan() {

		const getPendidikan = await ApiAnggota.getPendidikan();
		getPendidikan.data.forEach(dataPendidikan => {
			document.getElementById("selectPendidikan").add(new Option(dataPendidikan.jenjang + '-' + dataPendidikan.nama_institusi_pendidikan, dataPendidikan.id))
		});
	},

	async _getPekerjaan() {

		const getPekerjaan = await ApiAnggota.getPekerjaan();

		getPekerjaan.data.forEach(dataPekerjaan => {
			document.getElementById("selectPekerjaan").add(new Option(dataPekerjaan.nama_tempat_kerja, dataPekerjaan.id))
		});
	},


	async _sendPengajuanSerkom() {

		const eventPengajuanSerkom = async (e) => {
			e.preventDefault();
			document.getElementById("btnKirimPengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;

			var idPekerjaan = $("#selectPekerjaan").val();
			var idPendidikan = $("#selectPendidikan").val();

			const sendPengajuanSerkom = await ApiAnggota.sendPengajuanSerkom({
				pekerjaan_id: idPekerjaan,
				pendidikan_id: idPendidikan
			});
			if (sendPengajuanSerkom.status_code != 201) {
				NotificationModal.show(`${sendPengajuanSerkom.message}`, 'error');
				document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				$('#tablePengajuanSerkom').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${sendPengajuanSerkom.message}`, 'success');
				document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				$('#tablePengajuanSerkom').DataTable().ajax.reload();
			}

			e.preventDefault();
		}

		const eventClickReset =  async() => {
			await this._getFormulirSerkom();
		}
		document.getElementById('form-pengajuan-serkom').addEventListener('submit', eventPengajuanSerkom);
		document.getElementById('btnClearPengajuanSerkom').addEventListener('click', eventClickReset);

	},

	async _uploadBerkas() {
		let DzoneBerkasSerkom = new Dropzone("#form-upload-serkom", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-serkom .dz-preview:hidden').remove()
					$('#form-upload-serkom .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-serkom").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			$("#persyaratanSerkom").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSerkom").html('');
			let id = $("#hiddenIdPengajuan").val();
			document.getElementById("btnUpdateBerkas").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSerkom = await ApiAnggota.uploadBerkasSerkom({
				id: id,
				document_name: document.getElementById('selectBerkas').value,
				dokumen: (DzoneBerkasSerkom.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasSerkom.files[0].dataURL, DzoneBerkasSerkom.files[0].name)
			});
			if (uploadBerkasSerkom.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'error');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'success');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const getDetailPengajuanSerkom = await ApiAnggota.getPengajuanSerkomByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;
			if (getDetailPengajuanSerkom.status_code == 200) {
				$("#tanggalPengajuan").val(dataDetail.created_at);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#tanggalSerkom").val(await SerkomPresenter._ubahFormatTanggal(dataDetail.serkom.tanggal));
				$("#nomorSerkom").val(dataDetail.serkom.nomor_serkom);

				var dokumen = dataDetail.dokumen
				var persyaratanSerkom = ``
				dokumen.forEach(dataBerkas => {
					 persyaratanSerkom += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					
				});
				$("#persyaratanSerkom").html(persyaratanSerkom);

			}

			DzoneBerkasSerkom.emit("removeThumbnailCustom");
			e.preventDefault();
		}
		document.getElementById('btnUpdateBerkas').addEventListener('click', eventUploadBerkas);
	},


	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},




}

export default SerkomPresenter;