import CONFIG from './config.js';

const KECUKUPAN_SKP_ENDPOINT = {
	CREATE_KECUKUPAN_SKP: `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/user`,
	GET_PENGAJUAN_LIST : `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/user`,
	DETAIL_PENGAJUAN : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/${id}`,

	DETAIL_PENGAJUAN_PC : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/admin/pc/${id}`,

	DETAIL_PENGAJUAN_PD : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/admin/pd/${id}`,

	APPROVAL_PENGAJUAN_PC : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/approval/pc/${id}`,
	APPROVAL_PENGAJUAN_PD : (id) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/approval/pd/${id}`,

	DOWNLOAD_KECUKUPAN_SKP : (id) => `${CONFIG.BASE_URL}print/kecukupan-skp/${id}`,

	APPROVE_All_PC: `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/approval/pc/bulk`,
	APPROVE_All_PD: `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/approval/pd/bulk`,
	GET_PENGAJUAN_LIST : `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/user`,
	GET_PENGAJUAN_LIST_PC : (status, startDate, endDate, selectPC) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/admin/pc?status=${status}&start_date=${startDate}&end_date=${endDate}`,
	GET_PENGAJUAN_LIST_PD : (status, startDate, endDate, selectPC , statusDaerah) => `${CONFIG.BASE_URL}pengajuan/kecukupan-skp/admin/pd?status_pd=${status}&start_date=${startDate}&end_date=${endDate}&id_cabang=${selectPC}`,

	DOWNLOAD_SURAT_SYARAT_KECUKUPAN_SKP : (id) => `${CONFIG.BASE_URL}print/kecukupan-skp/syarat`,

};

export default KECUKUPAN_SKP_ENDPOINT;
