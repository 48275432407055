import UrlParser from '../routes/url-parser.js';

const SerkomInvalidPresenter = {

	async init() {
		await this._setView();
		await this._setUpViewSerkom();
	},

	async _setView() {
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},

	_setUpViewSerkom(){
		let url = UrlParser.parseActiveUrlWithCombiner();
		if(url == "/dokumen-invalid"){
			document.getElementById('noteMessage').textContent = 'Dokumen Invalid';
		}else{
			document.getElementById('noteMessage').textContent = 'Dokumen Valid';
		}
	},

}

export default SerkomInvalidPresenter;
