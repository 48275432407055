import KecukupanSkpAnggotaPresenter from "../../../presenter/pengajuan_kecukupan_skp/kecukupan-skp-anggota-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const PengajuanKecukupanSKP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabFormPengajuanSerkom" class="nav-link hapus-border active" id="data-skp-tab" data-bs-toggle="tab" data-bs-target="#tabFormPengajuanSerkom" data-toggle="tab" type="button" role="tab" aria-controls="tabFormPengajuanSerkom" aria-selected="true">Formulir Pengajuan</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabPengajuanSerkom" class="nav-link hapus-border" id="skp-saya-tab" data-bs-toggle="tab" data-bs-target="#tabPengajuanSerkom" data-toggle="tab" type="button" role="tab" aria-controls="tabPengajuanSerkom" aria-selected="false">Data Pengajuan</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabFormPengajuanSerkom" role="tabpanel" aria-labelledby="data-skp-tab">
                        <div class="alert alert-warning mt-3 d-none" id="alertWarning">
				            <i class="fas fa-exclamation-triangle"></i> <strong>Warning : Anda tidak dapat mengajukan surat keterangan Kecukupan SKP! </strong>
                        </div>
                       
                        <div class="alert alert-info mt-2 ">
                            <i class="fas fa-info-circle"></i> <strong>Syarat Wajib Kecukupan SKP : </strong>
                            <div class="col-sm-12" id="informationSKP">
                                <div class="shimmer content-line"></div>
                                <div class="shimmer content-line"></div>
                                <div class="shimmer content-line"></div>
                            </div>
                        </div>
                        <div class="alert alert-info mt-2 ">
                            <i class="fas fa-info-circle"></i> <strong>Informasi: </strong>
                            Setelah anda mengajukan Surat Keterangan Kecukupan SKP. Silakan anda pergi ke tab Data Pengajuan. Klik Detail Kemudian isi persyaratan untuk mengajukan kecukupan SKP. Terimakasih
                        </div>
                        <div class="alert alert-success mt-3 d-none" id="alertSuccessInfo">
                        <i class="fas fa-check-circle"></i> 
                            Anda <b>Memenuhi Syarat</b> untuk mengajukan surat keterangan Kecukupan SKP
                        </div>
                        <div class="alert alert-warning mt-3  d-none" id="alertWarningInfo">
                        <i class="fas fa-exclamation-triangle"></i>
                            Anda <b>Tidak Memenuhi Syarat</b>  untuk mengajukan surat keterangan Pengajuan Kecukupan SKP. <b>karena SKP Anda kurang dari 25.</b>
                        </div>

                        <div class="alert alert-info mt-2 d-none">
                            <i class="fas fa-info-circle"></i> <strong>Information :</strong>
                            <div class="col-sm-12" id="information">
                                <div class="shimmer content-line"></div>
                                <div class="shimmer content-line"></div>
                                <div class="shimmer content-line"></div>
                            </div>
                        </div>
                        
                        <div class="col-sm-5">
                            <div class="form-group row">
                                 <div class="col-sm-12 mb-1">
                                    <button type="submit" class=" btn btn-primary btn" id="btnSendKecukupan"><i class="fas fa-paper-plane"></i>&nbsp; &nbsp;Ajukan Kecukupan SKP</button>
                                </div>
                            </div>
                        </div>

                        <div class="card" >
                            <div class="card-header text-primary font-weight-bold" data-toggle="collapse" href="#collapseExample">
                               Data Formulir Pengajuan 
                            </div>
                            <div class="card-body" id="collapseExample">
                                <form id="form-pengajuan-serkom">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-12 text-primary border-bottom border-primary mb-1 pb-1">
                                                    <strong>Biodata</strong>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">Nama Lengkap</label>
                                                        <div class="col-sm-12">
                                                            <input type="hidden" id="textHIddenSKP" class="form-control">
                                                            <input type="text" id="textNamaLengkap" class="form-control" placeholder="Nama Lengkap..." required readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">KTAN</label>
                                                        <div class="col-sm-12">
                                                            <input type="text" id="textKTAN" class="form-control" placeholder="KTAN..." required readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">Tempat Lahir</label>
                                                        <div class="col-sm-12">
                                                            <input type="text" id="textTempatLahir" class="form-control" placeholder="Tempat Lahir..." required readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">Taggal Lahir</label>
                                                        <div class="col-sm-12">
                                                            <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="dateTanggalLahir" class="form-control" readonly required>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">Jenis Kelamin</label>
                                                        <div class="col-sm-12">
                                                            <input type="text" id="textJenisKelamin" class="form-control" placeholder="Jenis Kelamin..." required readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group row">
                                                        <label class="control-label col-sm-12 font-weight-bold">Alamat Domisili</label>
                                                        <div class="col-sm-12">
                                                            <textarea id="textAlamatDomisili" class="form-control" placeholder="Alamat Domisili..." required readonly></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 text-primary border-bottom border-primary mb-1 pb-1">
                                                    <strong>Profile Pendidikan</strong>
                                                </div>
                                                <div class="col-sm-12 mt-3" id="data_pendidikan">
                                                    <div class="table-responsive">
                                                        <table class="table" width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Jenjang Pendidikan</th>
                                                            <th scope="col">Institusi</th>
                                                            <th scope="col">Tahun Lulus</th>
                                                            <th scope="col">Tanggal Ijazah</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="content_data_pendidikan">
                                                        
                                                        </tbody>
                                                         </table>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 text-primary border-bottom border-primary mb-1 pb-1">
                                                    <strong>Profile Pekerjaan</strong>
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="table-responsive">
                                                        <table class="table" width="100%">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Nama Tempat Kerja</th>
                                                            <th scope="col">Alamat</th>
                                                            <th scope="col">Telp / Fax.</th>
                                                            <th scope="col">Nomor Hp</th>
                                                            <th scope="col">Email</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="content_data_pekerjaan">
                                                        
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
					</div>

					<div class="tab-pane fade" id="tabPengajuanSerkom" role="tabpanel" aria-labelledby="skp-saya-tab">
                       
						<div class="card mt-3">
							<div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
								Data Pengajuan Kecukupan SKP
							</div>
							<div class="card-body ">
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tablePengajuanSerkom" width="100%" cellspacing="0">
										<thead>
											<tr>
                                                <th>Tanggal Pengajuan</th>
                                                <th>Nomor Surat </th>
                                                <th>Tanggal Berlaku Surat</th>
												<th>Status PC</th>
                                                <th>Status PD</th>
												<th style="width:10%">Aksi</th>
											</tr>
										</thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                        </tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabPekerjaan" role="tabpanel" aria-labelledby="pekerjaan-tab">Pekerjaan</div>
					<div class="tab-pane fade" id="tabKarirOrganisasi" role="tabpanel" aria-labelledby="karir-organisasi-tab">Organisasi</div>
				</div>
			</div>
		</div>
        
		<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>

        

<div class="modal fade" id="detailSerkomModal" tabindex="-1" role="dialog" aria-labelledby="detailSerkomModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="">Detail Pengajuan</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" id="bodyModalSerkom">
                <div class="form-group row">
                    <div class="col-sm-5">
                        <label class="label control-label">Tanggal Pengajuan</label>
                        <input type="hidden" id="hiddenIdPengajuan" class="form-control" readonly>
                        <input type="text" id="tanggalPengajuan" class="form-control" readonly>
                    </div>
                    <div class="col-sm-7">
                        <label class="label control-label">Nama Anggota</label>
                        <input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-5">
                        <label class="label control-label">Tangal Berlaku</label>
                        <input type="text"  id="tanggalSerkom" class="form-control" readonly>
                    </div>
                    <div class="col-sm-7">
                        <label class="label control-label">Nomor Surat</label>
                        <input type="text" placeholder="Nomor Surat..." id="nomorSerkom" class="form-control" readonly>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-header text-primary font-weight-bold">
                        <strong>Upload Persyaratan</strong>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group row">
                                    <label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
                                    <div class="col-sm-12">
                                        <select class="form-control" id="selectBerkas">
                                            <option value="">Pilih Salah Satu</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="text-center d-none" id="containerAlertBerkas">
												<p>Deskripsi Persyaratan</p>
												<div class="alert alert-primary" role="alert" id="valueAlertBerkas">
													
												</div>
								</div>
                                <div class="form-group row">
                                    <label class="control-label col-sm-12 font-weight-bold">Upload Berkas</label>
                                </div>
                                <div id="form-upload-serkom" class="col-sm-12 dropzone">
                                    <input type="hidden" name="file" style=""/>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateBerkas" style="width:100%"><i class='fas fa-edit'></i> Kirim & Update Berkas</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label control-label"><strong>Data Persyaratan</strong></label>
                                
                                <div class="col-sm-12">
                                    <div class="row">
                                        <table class="table table-bordered table-hovered" id="tablePersyaratan" width="100%">
                                            <thead>
                                                <th>Nama Persyaratan</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="persyaratanSerkom">

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-6">
                        <div class="card">
                        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between>
                            <h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
                        </div>
                        <div class="card-body chat-body p-1" id="lamanChat">
                            <div class="chat-body p-1" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <form id="form-comment">
                                <div class="form-group row">
                                    <label class="control-label col-sm-12 font-weight-bold">Komentar</label>
                                    <div class="col-sm-12">
                                        <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-header text-primary font-weight-bold">
                                Riwayat Pengajuan
                            </div>
                            <div class="card-body" style="min-height:400px; box-shadow:0px 3px 7px #bbbbbb26;">
                                    
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableHistoryVerifikasiSERKOM" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Tanggal</th>
                                                <th>Nama</th>
                                                <th>Hak</th>
                                                <th>Keterangan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
        `;
		return view;
	  },

	async afterRender() {
		await KecukupanSkpAnggotaPresenter.init()
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default PengajuanKecukupanSKP;