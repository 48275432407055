
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import PDFHelper from "../../utils/pdf-helpert.js";
import ApiSeminar from "../../api/data-seminar.js";
import SEMINAR_ENPOINT from "../../config/globals/seminar_endpoint.js";
import FormatCurrency from "../../utils/initial-currency.js";
import ApiAnggota from "../../api/data-anggota.js";





const PengajuanSeminarPCPresenter = {

	async init() {
		await this._setView();
		await this._sendPengajuanSeminar();
		await this._getPengajuanSeminar();
		await this._getPengajuanSeminarById();
		await this._addMateriSeminar();
		await this._setUpLaporanAnggota();
		await this.initEditPengajuanSeminar()
		await this.initDeletePengajuanSeminar()
		await this.initPublishPengajuanSeminar()
		await this._initKomentar()
		await this._initInfoSKP();
		await this._initPesertaSeminar();
		await this._downloadSK();
		await this._initUploadBuktiBayar();
	},

	async _initUploadBuktiBayar(){
		let DzoneBuktiBayarSeminar = new Dropzone("#dropzone-bukti-bayar-seminar", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#dropzone-bukti-bayar-seminar .dz-preview:hidden').remove()
					$('#dropzone-bukti-bayar-seminar .dz-preview').fadeOut('slow');
					document.getElementById("dropzone-bukti-bayar-seminar").classList.remove('dz-started');
				});
			}
		});

		$('#tablePengajuanSeminar tbody').on('click', '#uploadBuktiBayar', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idSeminar = data.id;
			$("#textIdSeminarBuktiBayar").val(idSeminar)
			if (data.bukti_bayar_skp_seminar_pc != "" && data.bukti_bayar_skp_seminar_pc != null ) {
				document.getElementById("containerBuktiBayarSeminar").classList.remove("d-none")
				document.getElementsByName("show_image_bukti_bayar")[0].src = data.bukti_bayar_skp_seminar_pc;
			}else{
				document.getElementById("containerBuktiBayarSeminar").classList.add("d-none")
			}
		});

		const eventBuktiBayarSeminar = async (e) => {
			e.preventDefault()
			mRefresh.refresh()
			let idSeminar =$("#textIdSeminarBuktiBayar").val()
			let fileBuktiBayar =  (DzoneBuktiBayarSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBuktiBayarSeminar.files[0].dataURL ,DzoneBuktiBayarSeminar.files[0].name )
		
			if (fileBuktiBayar == null ) {
				NotificationModal.show("Laporan File harus di isi","warning")
			}else{
				document.getElementById("btnSaveBuktiBayarSeminar").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let response = await ApiSeminar.uploadBuktiBayarSeminar({
					file_import : fileBuktiBayar,
					idSeminar : idSeminar
				})
				if (response.status_code == 200) {
					$('#modalUploadBuktiBayarSeminar').modal("hide");
					swal.fire({
						title: 'Success',
						text: `${response.message}`,
						icon: 'success',
						showConfirmButton: false,
						timer: 1000
					});
					await $('#tablePengajuanSeminar').DataTable().ajax.reload();
					DzoneBuktiBayarSeminar.emit("removeThumbnailCustom");

				}else{
					NotificationModal.show(``,"warning")

					swal.fire({
						title: 'warning',
						text: `${response.message}`,
						icon: 'warning',
						showConfirmButton: false,
						timer: 1000
					});
				}
				document.getElementById("btnSaveBuktiBayarSeminar").innerHTML =`<i class="fas fa-file"></i>  Kirim Bukti Bayar SKP Seminar`
			}
			mRefresh.resolve()
			e.preventDefault()
		}

		document.getElementById("btnSaveBuktiBayarSeminar").addEventListener("click",eventBuktiBayarSeminar)
	},

	async _initPesertaSeminar(){

		const callListPesertaSeminar =  async () =>{
			await this._initListPesertaSeminar()
		}

		$('#tablePengajuanSeminar tbody').on('click', '#anggotaSeminar', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textIdSeminarAnggota').val(data.id)
			await callListPesertaSeminar()
			
		})
	},


	async _initListPesertaSeminar(){
		let idSeminar = $('#textIdSeminarAnggota').val()
		let table = await $('#tablePesertaSeminar').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>',
				"emptyTable": "Belum Ada Peserta Seminar "
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${SEMINAR_ENPOINT.GET_LIST_PESERTA_SEMINAR(idSeminar)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'id' },
				{ data: 'ktan' },
				{ data: 'name' },
				{ data: 'nomor_sertifikat' }
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
			]
		});
	},

	async _initInfoSKP(){
		$('#tablePengajuanSeminar tbody').on('click', '#info_skp', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();

			if (data.kategori_peserta == "hybrid" ) {
				swal.fire({
					title: 'INFO SKP SEMINAR',
					html: `
					<table style="border-spacing: 10px; width:100%" class="table table-bordered table-responsive">
					<tr>
						<th colspan="5" class="bg-secondary text-light">SKP PD</th>
					</tr>
					<tr>
						<th style="padding: 10px;" class="bg-primary text-light">
							PESERTA ONLINE
						</th>
						<th style="padding: 10px;" class="bg-primary text-light">
							PESERTA OFFLINE
						</th>
						
						<th style="padding: 10px;" class="bg-info text-light">
							MODERATOR
						</th>
						<th style="padding: 10px;" class="bg-success text-light">
							NARASUMBER
						</th>
						<th style="padding: 10px;" class="bg-warning text-light">
							PANITIA
						</th>
					</tr>
					<tr>
					  <td>${data.skp_peserta_pd_online}</td>
					  <td>${data.skp_peserta_pd}</td>
					  <td>${data.skp_moderator_pd}</td>
					  <td>${data.skp_narasumber_pd}</td>
					  <td>${data.skp_panitia_pd}</td>
					</tr>
				  </table>
				  
					<table style="border-spacing: 10px; width:100%" class="table table-bordered table-responsive">
						<tr>
							<th colspan="5" class="bg-secondary text-light">SKP PP</th>
						</tr>
						<tr>
							<th style="padding: 10px;" class="bg-primary text-light">
								PESERTA ONLINE
							</th>
							<th style="padding: 10px;" class="bg-primary text-light">
								PESERTA OFFLINE
							</th>
							
							<th style="padding: 10px;" class="bg-info text-light">
								MODERATOR
							</th>
							<th style="padding: 10px;" class="bg-success text-light">
								NARASUMBER
							</th>
							<th style="padding: 10px;" class="bg-warning text-light">
								PANITIA
							</th>
						</tr>
						<tr>
						<td>${data.skp_peserta_pp_online}</td>
						<td>${data.skp_peserta_pp}</td>
						<td>${data.skp_moderator_pp}</td>
						<td>${data.skp_narasumber_pp}</td>
						<td>${data.skp_panitia_pp}</td>
						</tr>
					</table>
				  `,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'TUTUP'
				});
			
			}else{
				swal.fire({
					title: 'INFO SKP SEMINAR',
					html: `
					<table style="border-spacing: 10px; width:100%" class="table table-bordered">
					<tr>
						<th colspan="4" class="bg-secondary text-light">SKP PD</th>
					</tr>
					<tr>
						<th style="padding: 10px;" class="bg-primary text-light">
							PESERTA
						</th>
						<th style="padding: 10px;" class="bg-info text-light">
							MODERATOR
						</th>
						<th style="padding: 10px;" class="bg-success text-light">
							NARASUMBER
						</th>
						<th style="padding: 10px;" class="bg-warning text-light">
							PANITIA
						</th>
					</tr>
					<tr>
					  <td>${data.skp_peserta_pd}</td>
					  <td>${data.skp_moderator_pd}</td>
					  <td>${data.skp_narasumber_pd}</td>
					  <td>${data.skp_panitia_pd}</td>
					</tr>
				  </table>
				  
					<table style="border-spacing: 10px; width:100%" class="table table-bordered">
						<tr>
							<th colspan="4" class="bg-secondary text-light">SKP PP</th>
						</tr>
						<tr>
							<th style="padding: 10px;" class="bg-primary text-light">
								PESERTA
							</th>
							<th style="padding: 10px;" class="bg-info text-light">
								MODERATOR
							</th>
							<th style="padding: 10px;" class="bg-success text-light">
								NARASUMBER
							</th>
							<th style="padding: 10px;" class="bg-warning text-light">
								PANITIA
							</th>
						</tr>
						<tr>
						<td>${data.skp_peserta_pp}</td>
						<td>${data.skp_moderator_pp}</td>
						<td>${data.skp_narasumber_pp}</td>
						<td>${data.skp_panitia_pp}</td>
						</tr>
					</table>
				  `,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'TUTUP'
				});
			}
		
		})
	},

	async _initKomentar(){
		let idPengajuan = ''
		$('#tablePengajuanSeminar tbody').on('click', '#comment', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			idPengajuan = data.id;
			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
			}


			let response = await ApiSeminar.getCommentar(UserID, "permohonan_seminar", idPengajuan)
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}
		});
	},
	
	async _setUpLaporanAnggota(){
		let DzoneLaporan = new Dropzone("#form-upload-anggota", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".xlsx",
			dictDefaultMessage: 'Masukan File Format xlsx',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-anggota .dz-preview:hidden').remove()
					$('#form-upload-anggota .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-anggota").classList.remove('dz-started');
				});
			}
		});

		let DzoneLaporanSeminar = new Dropzone("#dropzone-laporan-seminar", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 2,
			autoQueue: false,
			acceptedFiles: ".pdf",
			dictDefaultMessage: 'Masukan File PDF',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#dropzone-laporan-seminar .dz-preview:hidden').remove()
					$('#dropzone-laporan-seminar .dz-preview').fadeOut('slow');
					document.getElementById("dropzone-laporan-seminar").classList.remove('dz-started');
				});
			}
		});

		const eventDwonload = async (e) => {
			document.getElementById("btnDowonloadFormatLaporan").innerHtml = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let response = await ApiSeminar.downloadFormatImportAnggota()
			document.getElementById("btnDowonloadFormatLaporan").innerHTML =`<i class="fas fa-file"></i> Unduh Format Laporan Angota`
		}

		document.getElementById("btnDowonloadFormatLaporan").addEventListener("click", eventDwonload)

		$('#tablePengajuanSeminar tbody').on('click', '#laporanSeminar', async function () {
			mRefresh.refresh()
			DzoneLaporan.emit("removeThumbnailCustom");
			DzoneLaporanSeminar.emit("removeThumbnailCustom");
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idSeminar = data.id;
			const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
				idSeminar
			});
			if (getPengajuanSeminarById != 200) {
				 $("#btnSuratPdfEdit").attr('data-file',getPengajuanSeminarById.data.dokumen.laporan_seminar);
				 $("#btnSuratPdfEdit").attr('data-title',getPengajuanSeminarById.data.judul_seminar);
				 if (getPengajuanSeminarById.data.dokumen.laporan_seminar == null || getPengajuanSeminarById.data.dokumen.laporan_seminar == "" ) {
					document.getElementById("containerFileLaporanAnggota").classList.add("d-none")
				 }else{
					document.getElementById("containerFileLaporanAnggota").classList.remove("d-none")
				 }
			}
			
			$("#textIdSeminarLaporan").val(idSeminar)
			mRefresh.resolve()
		});

		document.getElementById("btnLaporanPdfEdit").addEventListener("click", async () =>{
			var pdfUrl = $("#btnSuratPdfEdit").attr('data-file');
            var title =  $("#btnSuratPdfEdit").attr('data-title');
			PDFHelper.showPdf(title,pdfUrl)
		})

		const eventLaporanAnggotaSeminar = async (e) => {
			e.preventDefault()
			mRefresh.refresh()
			let idSeminar =$("#textIdSeminarLaporan").val()
			let fileLaporan = (DzoneLaporan.files.length <= 0) ? null : new File([DzoneLaporan.files[0]], DzoneLaporan.files[0].name, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		
			if ( fileLaporan == null ) {
				NotificationModal.show(" File harus di isi","warning")
			}else{
				document.getElementById("btnSaveLaporanAnggotaSeminar").innerHtml = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let response = await ApiSeminar.importPesertaSeminar({
					file_import : fileLaporan,
					idSeminar : idSeminar
				})
				if (response.status_code == 200) {
					NotificationModal.show(`${response.message}`,"success")
					DzoneLaporan.emit("removeThumbnailCustom");
				}else{
					NotificationModal.show(`${response.message}`,"warning")
				}
				
				document.getElementById("btnSaveLaporanAnggotaSeminar").innerHTML =`<i class="fas fa-file"></i> KIRIM LAPORAN ANGGOTA SEMINAR`
			}
			mRefresh.resolve()
			e.preventDefault()
		}


		const eventLaporanSeminar = async (e) => {
			e.preventDefault()
			mRefresh.refresh()
			let idSeminar =$("#textIdSeminarLaporan").val()
			let fileLaporan = (DzoneLaporanSeminar.files.length <= 0) ? null : new File([DzoneLaporanSeminar.files[0]], DzoneLaporanSeminar.files[0].name, { type: 'application/pdf' });
		
			if (fileLaporan == null ) {
				NotificationModal.show("Laporan File harus di isi","warning")
			}else{
				document.getElementById("btnSaveLaporanSeminar").innerHtml = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				let response = await ApiSeminar.sendReportSeminar({
					file_import : fileLaporan,
					idSeminar : idSeminar
				})
				if (response.status_code == 200) {
					NotificationModal.show(`${response.message}`,"success")
					DzoneLaporanSeminar.emit("removeThumbnailCustom");
				}else{
					NotificationModal.show(`${response.message}`,"warning")
				}
				
				document.getElementById("btnSaveLaporanSeminar").innerHTML =`<i class="fas fa-file"></i> KIRIM LAPORAN SEMINAR`
			}
			mRefresh.resolve()
			e.preventDefault()
		}

		document.getElementById("btnSaveLaporanAnggotaSeminar").addEventListener("click",eventLaporanAnggotaSeminar)
		document.getElementById("btnSaveLaporanSeminar").addEventListener("click",eventLaporanSeminar)

		
	},
	
	async _showLoading(){
		var loadingElement = document.createElement("div");
		loadingElement.setAttribute("id", "loading");
		
		var spinnerElement = document.createElement("div");
		spinnerElement.setAttribute("class", "spinner");
		
		for (var i = 0; i < 3; i++) {
			var barElement = document.createElement("div");
			barElement.setAttribute("class", "bar");
			spinnerElement.appendChild(barElement);
		}
		
		loadingElement.appendChild(spinnerElement);
		document.body.appendChild(loadingElement);
	},

	async _hideLoading(){
		var loadingElement = document.getElementById("loading");
		if (loadingElement) {
			loadingElement.parentNode.removeChild(loadingElement);
		}
	},

	async initEditPengajuanSeminar(){
		$('#tablePengajuanSeminar tbody').on('click', '#edit', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			tinymce.remove('#textDeskripsi')
			tinymce.init({
				selector: '#textDeskripsi',
				menubar: false,
				min_height: 500,
				plugins: 'print | link',
				visual: false,
				inline_styles: true,
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
				fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
			});
	
				document.getElementById("form-pengajuan-seminar").reset()
				document.getElementById("titleModalPengajuan").innerHTML = "Edit Pengajuan Seminar SKP"
				document.getElementById("statePengajuan").value ="edit"
				let data = table.row($(this).parents('tr')).data();
				let idSeminar = data.id;
				document.getElementById("stateIdSeminar").value = idSeminar;
				const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
					idSeminar
				});
				if (getPengajuanSeminarById.status_code != 200) {
					NotificationModal.show(`${getPengajuanSeminarById.message}`, "error");
				}else {
					let dataDet = getPengajuanSeminarById.data;
					$("#selectPenyelenggara").val(dataDet.penyelenggara).change();
					
					$("#textKetuaPelaksana").val(dataDet.nama_penanda_pelaksana);
					$("#nianKetuaPelaksana").val(dataDet.nian_penanda_pelaksana);
					$("#jabatanKetuaPelaksana").val(dataDet.jabatan_penanda_pelaksana);
	
					$("#textSekretaris").val(dataDet.nama_penanda_sekretaris);
					$("#nianSekretaris").val(dataDet.nian_penanda_sekretaris);
					$("#jabatanSekretaris").val(dataDet.jabatan_penanda_sekretaris);
					$("#noSRPC").val(dataDet.no_sr_pc);
					$("#tempatPenyelenggaraan").val(dataDet.tempat_penyelenggaraan);
					$("#judulSeminar").val(dataDet.judul_seminar);
					$("#selectKategoriSeminar").val(dataDet.kategori_seminar);
					$("#selectKategoriSeminar").prop("disabled", true);
					$("#selectKategoriPesertaSeminar").val(dataDet.kategori_peserta); 
					$("#quotaOffline").val(dataDet.quota_peserta);
					$("#kontribusiPeserta").val(Number(dataDet.kontribusi_peserta).toLocaleString('id-ID'));
					$("#textBiayaPenerbitanSKP").val(Number(dataDet.biaya_penerbitan_skp).toLocaleString('id-ID'));
	
					$("#tanggalMulai").val(await PengajuanSeminarPCPresenter._ubahFormatTanggal(dataDet.tanggal_mulai));
					$("#waktuMulai").val(dataDet.waktu_mulai);
	
					$("#tanggalSelesai").val(await PengajuanSeminarPCPresenter._ubahFormatTanggal(dataDet.tanggal_selesai));
					$("#waktuSelesai").val(dataDet.waktu_selesai);
	
					$("#batasPendaftarOnline").val(dataDet.batas_pendaftaran_online);
					tinymce.remove('#textDeskripsi')
					tinymce.init({
						selector: '#textDeskripsi',
						menubar: false,
						min_height: 500,
						plugins: 'print | link',
						visual: false,
						inline_styles: true,
						toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
						fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
					});
					
					try {
						tinymce.get('textDeskripsi').setContent(dataDet.description);	
					} catch (error) {
						
					}
				
					document.getElementsByName("show_image_brosur")[0].src = dataDet.dokumen.brosur;
					document.getElementsByName("show_image_background")[0].src = dataDet.dokumen.background_sertifikat;
					document.getElementsByName("show_image_bukti_bayar")[0].src = dataDet.dokumen.bukti_bayar_biaya_penerbitan_skp;
					
					
					document.getElementById("btnProposalPdfEdit").addEventListener("click",() =>{
						PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.proposal_seminar)
					})
					
					document.getElementById("btnSuratPengantarPdfEdit").addEventListener("click",() =>{
						PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.surat_pengantar_pc)
					})
					
					
					document.getElementById("containerProposalEdit").classList.remove("d-none")
					document.getElementById("containerEditSuratPengantar").classList.remove("d-none")
					document.getElementById("containerSertifikatEdit").classList.remove("d-none")
					document.getElementById("containerBrosurEdit").classList.remove("d-none")
					document.getElementById("containerSuratEdit").classList.add("d-none")
	
					if (dataDet.penyelenggara =="external") {
						$("#textNomorSurat").val(dataDet.external.nomor_surat_permohonan);
						$("#tanggalSuratExternal").val(dataDet.external.tanggal_surat_permohonan);
	
						document.getElementById("containerSuratEdit").classList.remove("d-none")
						document.getElementById("btnSuratPdfEdit").addEventListener("click",() =>{
							PDFHelper.showPdf(dataDet.judul_seminar,dataDet.external.surat_permohonan)
						})
					}
				}
			});
	},

	async _ubahFormatTanggal(tanggal){
		// Membagi tanggal menjadi komponen hari, bulan, dan tahun
		var komponenTanggal = tanggal.split("-");
		var hari = komponenTanggal[0];
		var bulan = komponenTanggal[1];
		var tahun = komponenTanggal[2];
	  
		// Membentuk tanggal baru dalam format "yyyy-MM-dd"
		var tanggalBaru = tahun + "-" + bulan + "-" + hari;
	  
		return tanggalBaru;
	},
	async initDeletePengajuanSeminar(){
		$('#tablePengajuanSeminar tbody').on('click', '#delete', async function () {
				const table = $('#tablePengajuanSeminar').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Hapus Pengajuan Seminar',
					html: "Anda ingin Hapus Pengajuan Seminar ini ?\ " + data.judul_seminar,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: ' #d33',
					cancelButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Delete!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiSeminar.deletePengajuanSeminar({
							idSeminar: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Delete',
								text: "Data berhasil dihapus",
								icon: 'success',
								showConfirmButton: false,
								timer: 1000
							});

							await $('#tablePengajuanSeminar').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 1000
							});
						}
					};
				});
			});
	},

	async initPublishPengajuanSeminar(){
		$('#tablePengajuanSeminar tbody').on('click', '#publish', async function () {
				const table = $('#tablePengajuanSeminar').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Publish Pengajuan Seminar',
					html: "Anda ingin mengpublish Pengajuan Seminar ini ?\ " + data.judul_seminar,
					icon: 'warning',
					showCancelButton: true,
					cancelButtonColor: ' #d33',
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Publish!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiSeminar.publishPengajuanSeminar({
							idSeminar: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Publish',
								text: "Data berhasil dipublish",
								icon: 'success',
								showConfirmButton: false,
								timer: 3000
							});

							await $('#tablePengajuanSeminar').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 3000
							});
						}
					};
				});
			});

		$('#tablePengajuanSeminar tbody').on('click', '#unpublish', async function () {
				const table = $('#tablePengajuanSeminar').DataTable();
				let data = table.row($(this).parents('tr')).data();
			
				swal.fire({
					title: 'Batalkan Publish Pengajuan Seminar',
					html: "Anda ingin membatalkan Publish Pengajuan Seminar ini ?\ " + data.judul_seminar,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: ' #d33',
					cancelButtonColor: '#3085d6',
					confirmButtonText: 'Ya, Batal Publish!'
				}).then(async (result) => {
					if (result.value) {
						let id = data.id;
						const deleteMateriSeminar = await ApiSeminar.unPublishPengajuanSeminar({
							idSeminar: id
						});
	
						if (deleteMateriSeminar.status_code == '200') {
							swal.fire({
								title: 'Publish',
								text: "Data berhasil dibatalkan dari publish",
								icon: 'success',
								showConfirmButton: false,
								timer: 3000
							});

							await $('#tablePengajuanSeminar').DataTable().ajax.reload();
							
						}else {
							swal.fire({
								title: 'Error',
								text: deleteMateriSeminar.message,
								icon: 'error',
								showConfirmButton: false,
								timer: 3000
							});
						}
					};
				});
		});
	},


	async _showLoading(){
		var loadingElement = document.createElement("div");
		loadingElement.setAttribute("id", "loading");
		
		var spinnerElement = document.createElement("div");
		spinnerElement.setAttribute("class", "spinner");
		
		for (var i = 0; i < 3; i++) {
			var barElement = document.createElement("div");
			barElement.setAttribute("class", "bar");
			spinnerElement.appendChild(barElement);
		}
		
		loadingElement.appendChild(spinnerElement);
		document.body.appendChild(loadingElement);
	},

	async _hideLoading(){
		var loadingElement = document.getElementById("loading");
		if (loadingElement) {
			loadingElement.parentNode.removeChild(loadingElement);
		}
	},
	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		await this._formatNumber('kontribusiPeserta');

		$("#selectPenyelenggara").change(()=>{
			if (document.getElementById("selectPenyelenggara").value == "external") {
				document.getElementById("formExternal").classList.remove("d-none")
				$('#textNomorSurat').prop('required',true);

				$('#tanggalSuratExternal').prop('required',true);
			}else {
				document.getElementById("formExternal").classList.add("d-none")
				$('#textNomorSurat').prop('required',false);
				$('#tanggalSuratExternal').prop('required',false);
			}
		})
		

		$("#btnAddSeminar").click(()=>{
			document.getElementById("titleModalPengajuan").innerHTML = "Tambah Pengajuan Seminar SKP"
			document.getElementById("statePengajuan").value ="add"
			document.getElementById("stateIdSeminar").value = "";
			
			$("#selectKategoriSeminar").prop("disabled", false);
			document.getElementById("containerProposalEdit").classList.add("d-none")
			document.getElementById("containerEditSuratPengantar").classList.add("d-none")
			document.getElementById("containerSertifikatEdit").classList.add("d-none")
			document.getElementById("containerBrosurEdit").classList.add("d-none")
			document.getElementById("containerSuratEdit").classList.add("d-none")
			document.getElementById("form-pengajuan-seminar").reset()
		})

		let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		if (responseLocked.status_code == 200) {
			if (responseLocked.data.is_pc_locked) {
				window.location.hash = '#/404?reason=iuranpc'
			}
		}

		
	},

    async _getPengajuanSeminar(){
		let status = 'draft';
		
		const getPengajuanSeminarByStatus = async (status , statusApproval) =>{
			this._getPengajuanSeminarList(status , statusApproval)
		}

		$("#btnFilter").on("click", async function(){
			let valuePublish = $("#selectStatusSeminar").val()
			let valueApprove = $("#selectStatusApprove").val()
			await getPengajuanSeminarByStatus(valuePublish ,valueApprove );
		});

		getPengajuanSeminarByStatus(status , '')
    },


	async _getPengajuanSeminarList(statusParam , statusApproval){
		let status = statusParam
		let disabledByDraf = ''
		let disabledByPublis = ''
		if (status == 'draft') {
			 disabledByDraf = 'd-none';
			 disabledByPublis = 'd-block'
		}else if (status == 'publish') {
			disabledByDraf = 'd-block'
			disabledByPublis = 'd-none';
		}

		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';

		let table = await $('#tablePengajuanSeminar').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${SEMINAR_ENPOINT.GET_PENGAJUAN_SEMINAR_PC(status ,statusApproval)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
    				d.orderDirection = savedOrderDirection;
				},
				"dataSrc": function (json) {
					document.getElementById("informationRekening").innerHTML = json.additional.rekening_pd
					const data = json.data;
					return data;
				}
			},
			"columns": [
				{ data: 'id' },
				{ data: 'created_at' },
				{ data: 'judul_seminar' },
				{ data: 'penyelenggara' },
				{ data: 'kategori_peserta' },
				{ data: 'kontribusi_peserta', render : function (data) {
					let content = '';
					if (data > 0) {
						content =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data);
					}else {
						content = 'Gratis'
					}
					return content;
				}},
				{ data: 'tanggal_mulai' },
				{ data: 'tanggal_selesai' },
				{ data: 'kategori_seminar', render : function (data) {
					if (data == 'pendidikan') {
						return 'Pembelajaran'
					}else {
						return 'Pengabdian Masyarakat / RAKERCAB / RAKERDA'
					}
				} },
				{ data: null},
				{ data: 'status_pd',render : function (data) {
					var content = ``;
					content = `<span class="badge badge-danger">${data}</span>`
					if (data == "pending") {
						content = `<span class="badge badge-warning">${data}</span>`
					}
					if (data =="approve") {
						content = `<span class="badge badge-success">${data}</span>`
					}
					return content;
				} },
				{ data: 'status_pp', render : function (data) {
					var content = ``;
					content = `<span class="badge badge-danger">${data}</span>`
					if (data == "pending") {
						content = `<span class="badge badge-warning">${data}</span>`
					}
					if (data =="approve") {
						content = `<span class="badge badge-success">${data}</span>`
					}
					return content;
				} },
				{ data: 'biaya_penerbitan_skp_pc', render : function (data, type , row) {
					let content = '';
					if (data == null || data == '') {
						if (row.kategori_seminar == "baksos") {
							content = "Menunggu persetujuan PD"
						}else{
							content = 'Menunggu persetujuan PP'
						}
					}else{
						if (data > 0) {
							content =  new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(data);
						}else {
							content = 'Gratis'
						}
					}
					if (row.status_pd != "approve" || row.status_pp != "approve") {
						if (row.kategori_seminar == "baksos") {
							content = "Menunggu Persetujuan PD"
						}else{
							content = 'Menunggu Persetujuan PP'
						}
					}
					return content
				}},
				{ data: 'bukti_bayar_skp_seminar_pc', render : function (data) {
					let content = '';
					if (data == null || data == "") {
						content =  '<strong>File tidak ditemukan</strong>'
					}else {
						content = `<img src="${data}" width="60px">`
					}
					return content
				}},
				{ data: 'status_pembayaran_pd',  render : function (data , type , row) {
					var content = ``;
					if (data ==undefined || data == null){
						content = "-"
					}else{
						content = data
					}
				
					if (data == "paid") {
						content = "Lunas"
					}else if(data == "unpaid"){
						content = "Belum Bayar"
					}else if(data == "confirm"){
						content = "Menunggu Konfirmasi"
					}

					return content;
				} },
				
				{ data: 'nomor_sk', render : function(data,type , row){
					if (data == null) {
						if (row.kategori_seminar == "baksos") {
							return "Menunggu persetujuan PD"
						}else{
							return 'Menunggu persetujuan PP'
						}
					}else {
						return data;
					}
				} },
				{ data: 'jumlah_skp', render : function(data,type , row){
					return `<button class=' ml-1 btn btn-info btn-circle' id='info_skp' title='Info SKP'><i class="fas fa-info-circle"></i></button>`
				} },
				{ data: 'proposal_seminar',
					render : function(data,type , row){
						if (data == null || data == "") {
							return '-'
						}else {
							if (data.endsWith(".pdf")) {
								return `
								<button  type="button" data-title="${row.judul_seminar}" data-file="${data}" class="btn btn-outline-danger openPdf">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="20 20 20 20">
									<path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
									<path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
								</svg><i class="fas fa-file-pdf"></i>Buka File PDF</button>
								`
							}else {
								return '<img loading="lazy" id="show" src="'+data+'" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">'	
							}
						}
					}
				},
				{ data: 'status_seminar', render : function (data) {
					var content = ``;
					if (data == "Draft") {
						content = `<span class="badge badge-warning">${data}</span>`
					}else {
						content = `<span class="badge badge-primary">${data}</span>`
					}

					return content;
				} },
				{ data: 'agreement_status',  render : function (data,type , row) {
					var content = ``;
					var isPpOrPd = ``;
					if (row.kategori_seminar == "baksos") {
						isPpOrPd = "PD"
					}else{
						isPpOrPd = 'PP'
					}
					if (data ==undefined || data == null){
						content = "-"
					}else{
						content = data
					}

					if (data == "pending") {
						content = `Menunggu persetujuan ${isPpOrPd} `
					}else if(data == "unsigned"){
						content = `Menunggu ditanda tangani ${isPpOrPd}`
					}else if(data == "signed"){
						content = `Approved`
					}

					return content;
				} },
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [9,13,16,17,18,20],
					"orderable": false,
				},
				{"targets": 9,
				"data": null,
				"searchable": false,
					"data": null,
					"ordering": false,
				"className" :"text-center btn-info disable-sorting",
				render: function (data) {
					return '<div></div>'
				}},
				{
					"targets": [7,16],
					"visible": true,
					"searchable": false,
					"className" :"text-center"
				},
				{
					"targets": 20,
					"data": null,
					render: function (data) {
						let statusPP = data.status_pp;
						let statusPD = data.status_pd;
						let statusPembayaranPD = data.status_pembayaran_pd;
						var isShowUploadBuktiBayar = "d-none"
						if ((statusPembayaranPD == "unpaid" || statusPembayaranPD == null)  && data.status_seminar == "Draft" && data.kategori_seminar == "baksos" && statusPD == "approve") {
							isShowUploadBuktiBayar = ""
						}
						if ((statusPembayaranPD == "unpaid" || statusPembayaranPD == null)  && data.status_seminar == "Draft" && data.kategori_seminar == "pendidikan"  && statusPP == "approve") {
							isShowUploadBuktiBayar = ""
						}
						let statusSKP = data.agreement_status
						var showPublishSeminar = "d-none"
						if (statusSKP == "signed" && status == 'draft') {
							showPublishSeminar =  ""
						}

						var disableByApprove = ""
						if (data.kategori_seminar == "baksos" && statusPD == "approve") {
							disableByApprove = "d-none"
						}
						if (data.kategori_seminar == "pendidikan" && statusPP == "approve" ) {
							disableByApprove = "d-none"
						}
						let agreementStatus = data.agreement_status;
						var isShowButtonSk = "d-none"
						if (agreementStatus == "signed") {
							isShowButtonSk = ""
						}
						let content = '';
						if (statusPP == "approve") {
							content = `
							<div style="display:flex" class="mr-1">
								<button class='ml-1 btn btn-primary btn-sm' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i></button>
								<button class='ml-1 btn btn-info btn-sm ${disabledByDraf}'   id='laporanSeminar' title='Laporan Seminar' data-toggle="modal" data-target="#modalLaporanSeminar"><i class="fas fa-file"></i></button>
								<button class='ml-1 btn btn-warning btn-sm ${disableByApprove}' id='edit' title='Edit Pengajuan' data-toggle="modal" data-target="#modalTambahPengajuan"><i class="fas fa-edit"></i></button>
								<button class='ml-1 btn btn-success btn-sm ${disabledByPublis}' id='addSeminar' title='Tambah Materi Seminar' data-toggle="modal" data-target="#modalTambahMateri"><i class="fas fa-plus"></i></button>
								<button class='ml-1 btn btn-danger btn-sm ${disabledByPublis}' id='delete' title='Delte Pengajuan' ><i class="fas fa-trash"></i></button>
								<button class='ml-1 btn btn-warning btn-sm ${disabledByDraf}'' id='anggotaSeminar' title='Lihat Daftar Peserta'  data-toggle="modal" data-target="#modalPesertaSeminar" ><i class="fas fa-users"></i></button>
								<button class='ml-1 btn btn-secondary btn-sm' id='comment' title='Lihat Komentar'  data-toggle="modal" data-target="#commentModal" ><i class="fas fa-comment"></i></button>
							</div>
							<button class='ml-1 mt-2 btn btn-outline-primary btn-sm ${showPublishSeminar} form-control' id='publish' title='Publish Seminar' ><i class="fas fa-plane"></i> Publish Seminar</button>
							<button class='ml-1 mt-2 btn btn-outline-danger btn-sm ${disabledByDraf} form-control' id='unpublish' title='UnPublish Seminar' ><i class="fas fa-plane"></i> Batal Publish</button>
							<button class='ml-1 mt-2 btn btn-outline-success btn-sm  ${isShowButtonSk} form-control' id='downloadSK' title='Download SK SKP' ><i class="fas fa-download"></i> Download SK</button>
							<button class='ml-1 mt-2 btn btn-outline-warning btn-sm ${isShowUploadBuktiBayar}' id='uploadBuktiBayar' title='Upload Bukti Bayar Seminar' data-toggle="modal" data-target="#modalUploadBuktiBayarSeminar"  ><i class="fas fa-upload"></i> Upload Bukti Bayar Seminar</button>
							`
						}else {
							content = `
							<div style="display:flex" class="mr-1">
								<button class='ml-1 btn btn-primary btn-sm' id='detail' title='Detail Pengajuan' data-toggle="modal" data-target="#modalViewDetail"><i class="fas fa-eye"></i></button>
								<button class='ml-1 btn btn-info btn-sm ${disabledByDraf}'   id='laporanSeminar' title='Laporan Seminar' data-toggle="modal" data-target="#modalLaporanSeminar"><i class="fas fa-file"></i></button>
								<button class='ml-1 btn btn-warning btn-sm ${disableByApprove}' id='edit' title='Edit Pengajuan' data-toggle="modal" data-target="#modalTambahPengajuan"><i class="fas fa-edit"></i></button>
								<button class='ml-1 btn btn-success btn-sm ${disabledByPublis}' id='addSeminar' title='Tambah Materi Seminar' data-toggle="modal" data-target="#modalTambahMateri"><i class="fas fa-plus"></i></button>
								<button class='ml-1 btn btn-danger btn-sm ${disabledByPublis}' id='delete' title='Delte Pengajuan' ><i class="fas fa-trash"></i></button>
								<button class='ml-1 btn btn-warning btn-sm ${disabledByDraf}'' id='anggotaSeminar' title='Lihat Daftar Peserta'  data-toggle="modal" data-target="#modalPesertaSeminar" ><i class="fas fa-users"></i></button>
								<button class='ml-1 btn btn-secondary btn-sm' id='comment' title='Lihat Komentar'  data-toggle="modal" data-target="#commentModal" ><i class="fas fa-comment"></i></button>
							</div>
							<button class='ml-1 mt-2 btn btn-outline-danger btn-sm ${disabledByDraf} form-control' id='unpublish' title='UnPublish Seminar' ><i class="fas fa-plane"></i> Batal Publish</button>
							<button class='ml-1 mt-2 btn btn-outline-warning btn-sm ${isShowUploadBuktiBayar}' id='uploadBuktiBayar' title='Upload Bukti Bayar Seminar' data-toggle="modal" data-target="#modalUploadBuktiBayarSeminar"  ><i class="fas fa-upload"></i> Upload Bukti Bayar Seminar</button>
							`
						}

						return content
					}
				},
				]
		});

		
		$('#tablePengajuanSeminar thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			var columnName = ($(this)[0]).getAttribute('data-column')
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});


	},

	async _getPengajuanSeminarById(){

		let idSeminar = 0;

		const formatAngka = (number)=> {
			
			const reverse = number.toString().split('').reverse().join('');
			let formattedValue = '';
			let count = 0;
		
			for (const char of reverse) {
			if (count === 3) {
				formattedValue += '.';
				count = 0;
			}
			formattedValue += char;
			count++;
			}
	
		return formattedValue.split('').reverse().join('');
		}
	
		
		$('#tablePengajuanSeminar tbody').on('click', '#detail', async function () {
			
			$("#judulSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#kategoriSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#ketuaSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#sekretarisSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#aboutTime").html('<div class="shimmer content-line"></div>');
			$("#aboutPeserta").html('<div class="shimmer content-line"></div>');
			$("#tanggalPelaksanaan").html('<div class="shimmer content-line"></div>');
			$("#menimbang").html('<div class="shimmer content-line"></div>');
			$("#mengingat").html('<div class="shimmer content-line"></div>');
			$("#skpPeserta").html('<div class="shimmer content-line end"></div>');
			$("#skpPanitia").html('<div class="shimmer content-line end"></div>');
			$("#skpModerator").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
			
			const table = $('#tablePengajuanSeminar').DataTable();
			
			let data = table.row($(this).parents('tr')).data();
			idSeminar = data.id;
			$("#stateIdSeminarDetail").val(idSeminar)
			const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
				idSeminar
			});

			if (getPengajuanSeminarById.status_code != 200) {
				NotificationModal.show("Gagal memuat data", "error");
			}else {
				
				let dataDet = getPengajuanSeminarById.data;
				let dataMateri = dataDet.materi_seminar;
				let jumlahSeminar = dataDet.materi_seminar.length;
				let skp_peserta = '';
				let skp_panitia = '';
				let skp_moderator = '';
				let skp_narasumber = ''; 
				let skp_peserta_online =''
				if (dataDet.kategori_seminar == 'pendidikan') {
					skp_peserta = dataDet.skp_peserta_pp
					skp_moderator = dataDet.skp_moderator_pp
					skp_narasumber = dataDet.skp_narasumber_pp
					skp_panitia = dataDet.skp_panitia_pp
					skp_peserta_online = dataDet.skp_peserta_pp_online
				}else {
					skp_peserta = dataDet.skp_peserta_pd
					skp_moderator = dataDet.skp_moderator_pd
					skp_narasumber = dataDet.skp_narasumber_pd
					skp_panitia = dataDet.skp_panitia_pd
					skp_peserta_online = dataDet.skp_peserta_pd_online
				}
				let kategoriSeminar = dataDet.kategori_seminar;
				if (kategoriSeminar == 'pendidikan') {
					kategoriSeminar = 'Pembelajaran'
				}else {
					kategoriSeminar = 'Pengabdian Masyarakat / RAKERCAB / RAKERDA'
				}

				if (dataDet.kategori_peserta == "hybrid" ) {
					document.getElementById("tr_peserta_seminar_online").classList.remove("d-none")
					$("#skpPesertaOnline").html(skp_peserta_online);
					$("#peserta_seminar_offline").html("Peserta Seminar Offline");
				
				}else{
					document.getElementById("tr_peserta_seminar_online").classList.add("d-none")
					$("#peserta_seminar_offline").html("Peserta");
				}



				$("#judulSeminarDet").html(dataDet.judul_seminar);
				$("#kategoriSeminarDet").html(kategoriSeminar);
				$("#skpPeserta").html(skp_peserta);
				$("#skpPanitia").html(skp_panitia);
				$("#skpModerator").html(skp_moderator);
				$("#skpNarasumber").html(skp_narasumber);
				$("#ketuaSeminarDet").html(dataDet.nama_penanda_pelaksana);
				$("#sekretarisSeminarDet").html(dataDet.nama_penanda_sekretaris);
				$("#aboutTime").html( `${dataDet.tanggal_mulai}  ${dataDet.waktu_mulai} sampai dengan ${dataDet.tanggal_selesai} ${dataDet.waktu_selesai}  `);
				if (dataDet.kategori_peserta == null || dataDet.quota_peserta == null || dataDet.kontribusi_peserta == null) {
					$("#aboutPeserta").html( `-`);
				}else {
					$("#aboutPeserta").html( `Dilaksanakan ${dataDet.kategori_peserta.toUpperCase()} , Quota Peserta   ${dataDet.quota_peserta} dengan kontribusi peserta sebesar Rp. ${formatAngka(dataDet.kontribusi_peserta)} `);
				}

				if (dataDet.penyelenggara == "external") {
					document.getElementById("formExternalDetail").classList.remove("d-none")
					$("#textNomorSuratDetail").val(dataDet.external.nomor_surat_permohonan);
					$("#tanggalSuratExternalDetail").val(dataDet.external.tanggal_surat_permohonan);
					$("#textKeteranganDetail").val(dataDet.external.keterangan);

					document.getElementById("btnSuratDetPDF").addEventListener("click",() =>{
						PDFHelper.showPdf(dataDet.judul_seminar,dataDet.external.surat_permohonan)
					})
				}else{
					document.getElementById("formExternalDetail").classList.add("d-none")
				}

				let tableMateri = $("#tableMateri").DataTable({
						"destroy": true,
						columnDefs: [{
							"defaultContent": "-",
							"targets": "_all"
						  }]
				})


				if (jumlahSeminar <= 0) {
					$("#contentDet").html('<tr><td colspan="10" class="text-center" style="font-style:italic;">Tidak ada data</td></tr>')
				}else {

					try {
						$('#tableMateri').dataTable().fnClearTable();
						$('#tableMateri').dataTable().fnDestroy();
					} catch (error) {
						console.log(error)
					}
					let i = 0
				
					dataMateri.forEach(async function (materi) {
						i++;
						var lampiran = ``;
						if (materi.lampiran == null || materi.lampiran == '') {
							lampiran = '-';
						}else {
							lampiran = `<img src="${materi.lampiran}" width="40px;">`
						}
						tableMateri.row
							.add([
								materi.id,
								materi.judul_materi_seminar,
								materi.moderator,
								materi.pemateri,
								materi.tanggal_seminar,
								materi.tempat_penyelenggaraan,
								materi.author,
								materi.dari_jam,
								materi.sampai_jam,
								lampiran,
								`<button class='mb-1 btn btn-danger btn-sm' id='deleteMateriSeminar' title='Hapus'><i class="fas fa-trash"></i></button></td> 
								<button class='mb-1 btn btn-warning btn-sm' id='editMateriSeminar' title='Edit'><i class="fas fa-edit"></i></button></td>`,
							]).draw(false);
					});

					$("#tableMateri").DataTable({
						"destroy": true
					})

					$('#tableMateri tbody').off('click','#deleteMateriSeminar').on('click', '#deleteMateriSeminar', async function () {
			
						const table = $('#tableMateri').DataTable();
						let data = table.row($(this).parents('tr')).data();
						const refreshThis = async () =>{
							var row = $(this).closest('tr');
							table.row(row).remove().draw(false);
						}
					
						swal.fire({
							title: 'Hapus Materi Seminar',
							html: "Anda ingin Hapus Materi Seminar ini ?\ " + data[1],
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: ' #d33',
							cancelButtonColor: '#3085d6',
							confirmButtonText: 'Ya, Delete!'
						}).then(async (result) => {
							if (result.value) {
								let id = data[0];
								const deleteMateriSeminar = await ApiSeminar.deleteMateriSeminar({
									id: id
								});
			
								if (deleteMateriSeminar.status_code == '200') {
									swal.fire({
										title: 'Delete',
										text: "Data berhasil dihapus",
										icon: 'success',
										showConfirmButton: false,
										timer: 1000
									});

									await refreshThis()
									
								}else {
									swal.fire({
										title: 'Error',
										text: deleteMateriSeminar.message,
										icon: 'error',
										showConfirmButton: false,
										timer: 1000
									});
								}
							};
						});

				});

				$('#tableMateri tbody').off('click','#editMateriSeminar').on('click', '#editMateriSeminar', async function () {
					const table = $('#tableMateri').DataTable();
					let data = table.row($(this).parents('tr')).data();
					document.getElementById("stateTitleMateri").innerHTML = "Edit Materi Seminar"
					mRefresh.refresh()
					const getMateriSeminar =await ApiSeminar.getMateriSeminarById({idSeminar : data[0]})
					if (getMateriSeminar.status_code == 200) {
						mRefresh.resolve()
						$('#modalTambahMateri').modal("show");
						$("#textIdSeminar").val(data[0]);
						$("#textJudulSeminar").val(getMateriSeminar.data.judul_materi_seminar);
						$("#textPemateri").val(getMateriSeminar.data.pemateri);
						$("#textInstansi").val(getMateriSeminar.data.instansi);
						$("#textDariJam").val(getMateriSeminar.data.dari_jam);
						$("#textSampaiJam").val(getMateriSeminar.data.sampai_jam);
						$("#textModerator").val(getMateriSeminar.data.moderator);
						document.getElementsByName("show_image_lampiran")[0].src = getMateriSeminar.data.lampiran;
						$("#stateMateriSeminar").val("edit");
						document.getElementById("containerLampiranEdit").classList.remove("d-none")	
						tinymce.remove('#textDeskripsiMateri')
						tinymce.init({
							selector: '#textDeskripsiMateri',
							menubar: false,
							min_height: 500,
							plugins: 'print | link',
							visual: false,
							inline_styles: true,
							toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
							fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
						});
						try {
							tinymce.get('textDeskripsiMateri').setContent(getMateriSeminar.data.description);	
						} catch (error) {
							
						}
					}

				 });
				}

				tinymce.remove('#textDeskripsiDetail')
				tinymce.init({
					selector: '#textDeskripsiDetail',
					menubar: false,
					min_height: 500,
					plugins: 'print | link',
					visual: false,
					inline_styles: true,
					toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
					fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
				});
				
				try {
					tinymce.get('textDeskripsiDetail').setContent(dataDet.description);	
				} catch (error) {
					
				}

				document.getElementById("btnProposalPdfDetail").addEventListener("click", () => {
					PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.proposal_seminar)
				})
				document.getElementById("btnSuratPengantarPdfDetail").addEventListener("click", () => {
					PDFHelper.showPdf(dataDet.judul_seminar,dataDet.dokumen.surat_pengantar_pc)
				})

				$("#brosurDetailImage").attr("src",dataDet.dokumen.brosur);
				$("#backgroundSertifikatDetail").attr("src",dataDet.dokumen.background_sertifikat);
			}


		});

	},
	

	async _addMateriSeminar(){

		let idSeminar = 0;
		
		$('#tablePengajuanSeminar tbody').on('click', '#addSeminar', async function () {
			const table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idSeminar = data.id;
			document.getElementById("stateTitleMateri").innerHTML ="Tambah Materi Seminar"
			$("#textIdSeminar").val(idSeminar);
			$("#textJudulSeminar").val('');
			$("#textPemateri").val('');
			$("#textInstansi").val('');
			$("#textDariJam").val('');
			$("#textSampaiJam").val('');
			$("#textModerator").val('');	
			$("#stateMateriSeminar").val('add');	
			document.getElementById("containerLampiranEdit").classList.add("d-none")			
		});
		tinymce.remove('#textDeskripsiMateri')
		tinymce.init({
			selector: '#textDeskripsiMateri',
			menubar: false,
			min_height: 500,
			plugins: 'print | link',
			visual: false,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});
		
		let DzoneLampiran = new Dropzone("#form-upload-lampiran", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-lampiran .dz-preview:hidden').remove()
					$('#form-upload-lampiran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-lampiran").classList.remove('dz-started');
				});
			}
		});

		const eventAddMateriSeminar = async(e) => {
			e.preventDefault();
			let stateMateriSeminar = $("#stateMateriSeminar").val();

			let idSeminar = $("#textIdSeminar").val();
			let	judulSeminar = $("#textJudulSeminar").val();
			let	pemateri = $("#textPemateri").val();
			let	instansi = $("#textInstansi").val();
			let	dariJam = $("#textDariJam").val();
			let	sampaiJam = $("#textSampaiJam").val();
			let	moderator = $("#textModerator").val();
			let deskripsiMateri = tinymce.get("textDeskripsiMateri").getContent();

			if (stateMateriSeminar == "edit") {
					document.getElementById("btnSaveMateri").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;
					const editMateriSeminar = await ApiSeminar.editMateriSeminar({
						idSeminar: idSeminar,
						judulMateriSeminar : judulSeminar,
						pemateri : pemateri,
						instansi: instansi,
						dariJam : dariJam,
						sampaiJam: sampaiJam,
						moderator: moderator,
						deskripsiMateri: deskripsiMateri,
						lampiran: (DzoneLampiran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneLampiran.files[0].dataURL ,DzoneLampiran.files[0].name ),
					})
	
					if (editMateriSeminar.status_code != 200) {
						NotificationModal.show(`${editMateriSeminar.message}`, 'error');
						DzoneLampiran.emit("removeThumbnailCustom");
						tinymce.get('textDeskripsiMateri').setContent('');
						document.getElementById("btnSaveMateri").innerHTML = `Simpan`
					}else {
						try {
							let tableMateri = $("#tableMateri").DataTable({
								"destroy": true,
								columnDefs: [{
									"defaultContent": "-",
									"targets": "_all"
								  }]
							})
							$('#tableMateri').dataTable().fnClearTable();
							$('#tableMateri').dataTable().fnDestroy();
							let i = 0
							let idSeminar = $("#stateIdSeminarDetail").val()
							
							const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
								idSeminar
							});
							let dataDet = getPengajuanSeminarById.data;
							let dataMateri = dataDet.materi_seminar;
							dataMateri.forEach(async function (materi) {
								i++;
								var lampiran = ``;
								if (materi.lampiran == null || materi.lampiran == '') {
									lampiran = '-';
								}else {
									lampiran = `<img src="${materi.lampiran}" width="40px;">`
								}
								tableMateri.row
									.add([
										materi.id,
										materi.judul_materi_seminar,
										materi.moderator,
										materi.pemateri,
										materi.tanggal_seminar,
										materi.tempat_penyelenggaraan,
										materi.author,
										materi.dari_jam,
										materi.sampai_jam,
										lampiran,
										`<button class='mb-1 btn btn-danger btn-sm' id='deleteMateriSeminar' title='Hapus'><i class="fas fa-trash"></i></button></td> 
										<button class='mb-1 btn btn-warning btn-sm' id='editMateriSeminar' title='Edit'><i class="fas fa-edit"></i></button></td>`,
									]).draw(false);
							});
						} catch (error) {
							console.log(error)
						}
					
						swal.fire({
							title: 'Success',
							html: `${editMateriSeminar.message}`,
							icon: 'success',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: '<i class="fas fa-check"></i> Ok'
						}).then(async (result) => {
							if (result.value) {
								
							};
						});
						DzoneLampiran.emit("removeThumbnailCustom");
						$("#textIdSeminar").val('');
						$("#textJudulSeminar").val('');
						$("#textPemateri").val('');
						$("#textInstansi").val('');
						$("#textDariJam").val('');
						$("#textSampaiJam").val('');
						$("#textModerator").val('');
						tinymce.get('textDeskripsiMateri').setContent('');
						document.getElementById("btnSaveMateri").innerHTML = `Simpan`;
						$('#modalTambahMateri').modal("hide");
				}
				return
			}

			//state add
			if (DzoneLampiran.files.length <= 0) {
				NotificationModal.show('File upload tidak boleh kosong !', 'error')
				document.getElementById("btnSaveMateri").innerHTML = `Save changes`;
			}else {
				
				document.getElementById("btnSaveMateri").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;
				const addMateriSeminar = await ApiSeminar.addMateriSeminar({
					idSeminar: idSeminar,
					judulMateriSeminar : judulSeminar,
					pemateri : pemateri,
					instansi: instansi,
					dariJam : dariJam,
					sampaiJam: sampaiJam,
					moderator: moderator,
					deskripsiMateri: deskripsiMateri,
					lampiran: (DzoneLampiran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneLampiran.files[0].dataURL ,DzoneLampiran.files[0].name ),
				})

				if (addMateriSeminar.status_code != 201) {
					NotificationModal.show(`${addMateriSeminar.message}`, 'error');
					DzoneLampiran.emit("removeThumbnailCustom");
					$("#textJudulSeminar").val('');
					$("#textPemateri").val('');
					$("#textInstansi").val('');
					$("#textDariJam").val('');
					$("#textSampaiJam").val('');
					$("#textModerator").val('');
					tinymce.get('textDeskripsiMateri').setContent('');
					document.getElementById("btnSaveMateri").innerHTML = `Save changes`;
				}else {
					swal.fire({
						title: 'Success',
						html: `${addMateriSeminar.message}`,
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ok'
					}).then(async (result) => {
						if (result.value) {
							
						};
					});
					DzoneLampiran.emit("removeThumbnailCustom");
					$("#textIdSeminar").val('');
					$("#textJudulSeminar").val('');
					$("#textPemateri").val('');
					$("#textInstansi").val('');
					$("#textDariJam").val('');
					$("#textSampaiJam").val('');
					$("#textModerator").val('');
					tinymce.get('textDeskripsiMateri').setContent('');

					$('#modalTambahMateri').modal("hide");
					document.getElementById("btnSaveMateri").innerHTML = `Save changes`;
					
				}
			}
		}
		
		document.getElementById('btnSaveMateri').addEventListener('click', eventAddMateriSeminar);


	},

	async _downloadSK() {
		$('#tablePengajuanSeminar tbody').on('click', '#downloadSK', async function () {
			$(this).html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let table = $('#tablePengajuanSeminar').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let wilayah = '';

			if (data.kategori_seminar == 'baksos') {
				wilayah = 'pd'
			}else{
				wilayah = 'pp'
			}

			const downloadSK = await ApiSeminar.downloadSK({ id,wilayah });
			$(this).html('<i class="fas fa-download"></i> Download SK');

		});
	},

	async _formatNumber(elm){
		// Dapatkan elemen input
		const input = document.getElementById(elm);
		// const input = document.getElementById('textBiayaPenerbitanSKP');

		// Tambahkan event listener untuk memantau perubahan pada input
		input.addEventListener('input', function () {
		// Dapatkan nilai input
			let value = input.value;

			// Hapus semua karakter non-angka dari nilai input
			value = value.replace(/[^0-9]/g, '');

			// Gunakan fungsi Number untuk mengubah nilai menjadi angka
			const numberValue = Number(value);

			// Pastikan nilai yang diubah bukan NaN (Not-a-Number)
			if (!isNaN(numberValue)) {
				// Format nilai sebagai angka dengan tanda koma (contoh: 1,234)
				input.value = numberValue.toLocaleString('id-ID');
			} else {
				// Jika nilai tidak valid, kosongkan input
				input.value = '';
			}

		});

	},

	async _sendPengajuanSeminar(){
		try {
			tinymce.remove('#textDeskripsi')
			tinymce.init({
				selector: '#textDeskripsi',
				menubar: false,
				min_height: 500,
				plugins: 'print | link',
				visual: false,
				inline_styles: true,
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
				fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
			});
	
		} catch (error) {
			
		}
		
		
		
		let DzoneBuktiBayarSeminar = null

		let DzoneProposal = new Dropzone("#form-upload-proposal", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 2,
			autoQueue: false,
			acceptedFiles: ".pdf",
			dictDefaultMessage: 'Masukan File berbentuk pdf',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-proposal .dz-preview:hidden').remove()
					$('#form-upload-proposal .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-proposal").classList.remove('dz-started');
				});
			}
		});
		
		let DzoneSuratExternal = new Dropzone("#form-upload-surat", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 2,
			autoQueue: false,
			acceptedFiles: ".pdf",
			dictDefaultMessage: 'Masukan Surat bentuk file pdf',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-surat .dz-preview:hidden').remove()
					$('#form-upload-surat .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-surat").classList.remove('dz-started');
				});
			}
		});

		let DzoneSuratPengantar = new Dropzone("#form-upload-surat-pengantar", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 2,
			autoQueue: false,
			acceptedFiles: ".pdf",
			dictDefaultMessage: 'Masukan Surat bentuk file pdf',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus file ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-surat-pengantar .dz-preview:hidden').remove()
					$('#form-upload-surat-pengantar .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-surat-pengantar").classList.remove('dz-started');
				});
			}
		});

		let DzoneTemplateSeminar = new Dropzone("#form-upload-sertifikat", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-sertifikat .dz-preview:hidden').remove()
					$('#form-upload-sertifikat .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-sertifikat").classList.remove('dz-started');
				});
			}
		});

		let DzoneBrosur = new Dropzone("#form-upload-brosur", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-brosur .dz-preview:hidden').remove()
					$('#form-upload-brosur .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-brosur").classList.remove('dz-started');
				});
			}
		});

		const eventCreatPengajuanSeminar = async (e) => {
			e.preventDefault();

			let state = $("#statePengajuan").val();
			let textPenyelenggara = $("#selectPenyelenggara").val();
			//
			let textKetuaPelaksana = $("#textKetuaPelaksana").val();
			let nianKetuaPelaksana = $("#nianKetuaPelaksana").val();
			let jabatanKetuaPelaksana = $("#jabatanKetuaPelaksana").val();

			let textSekretaris = $("#textSekretaris").val();
			let nianSekretaris = $("#nianSekretaris").val();
			let jabatan = $("#jabatanSekretaris").val();


			let noSRPC = $("#noSRPC").val();
			let jumlahSKPPeserta = $("#jumlahSKPPeserta").val();
			let jumlahSKPModerator = $("#jumlahSKPModerator").val();
			let jumlahSKPPanitia = $("#jumlahSKPPanitia").val();
			let jumlahSKPNarasumber = $("#jumlahSKPNarasumber").val();
			let tempatPenyelenggaraan = $("#tempatPenyelenggaraan").val();
			let judulSeminar = $("#judulSeminar").val();
			let kategoriSeminar = $("#selectKategoriSeminar").val();
			let lingkupSeminar = "daerah"
			let textKategoriPeserta =  $("#selectKategoriPesertaSeminar").val(); 
			let biayaPendaftaranSeminar =  null

			let tanggalMulai = $("#tanggalMulai").val();
			let waktuMulai = $("#waktuMulai").val();

			let tanggalSelesai = $("#tanggalSelesai").val();
			let waktuSelesai = $("#waktuSelesai").val();

			let batasPendaftarOnline = $("#batasPendaftarOnline").val();
		
			let quota = $("#quotaOffline").val();
			let kontribusiPeserta = $("#kontribusiPeserta").val();
			kontribusiPeserta = kontribusiPeserta.replace(/\.+/g,'');

			let textDeskripsi = tinymce.get("textDeskripsi").getContent();

			let textTanggalSurat = $("#tanggalSuratExternal").val();

			if (state == "edit") {
				let idSeminarEditing =  $("#stateIdSeminar").val();
				if (textPenyelenggara == "internal") {
						let fileProposalPdf = (DzoneProposal.files.length <= 0) ? null : new File([DzoneProposal.files[0]], DzoneProposal.files[0].name, { type: 'application/pdf' });
						let fileSuratPengantarPdf = (DzoneSuratPengantar.files.length <= 0) ? null : new File([DzoneSuratPengantar.files[0]], DzoneSuratPengantar.files[0].name, { type: 'application/pdf' });
						
						document.getElementById("btnSavePengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						
						const createPengajuanSeminar = await ApiSeminar.editPengajuanSeminarInternal({
						penyelenggara : textPenyelenggara,
						ketuaPelaksana : textKetuaPelaksana,
						nianKetuaPelaksana : nianKetuaPelaksana,
						jabatanKetuaPelaksana : jabatanKetuaPelaksana,
						sekretaris : textSekretaris,
						nianSekretaris : nianSekretaris,
						jabatan : jabatan,
						tempatPenyelenggaraan : tempatPenyelenggaraan,
						noSRPC : noSRPC,
						jumlahSKPPeserta : jumlahSKPPeserta,
						jumlahSKPModerator : jumlahSKPModerator,
						jumlahSKPPanitia : jumlahSKPPanitia,
						jumlahSKPNarasumber : jumlahSKPNarasumber,
						judulSeminar : judulSeminar,
						kategoriSeminar : kategoriSeminar,
						lingkupSeminar : lingkupSeminar,
						tanggalMulai : tanggalMulai,
						waktuMulai : waktuMulai,
						tanggalSelesai : tanggalSelesai,
						waktuSelesai : waktuSelesai,
						batasPendaftarOnline : batasPendaftarOnline,
						kategoriPeserta : textKategoriPeserta,
						quota : quota,
						kontribusiPeserta : kontribusiPeserta,
						proposal : fileProposalPdf,
						surat_pengantar_pc : fileSuratPengantarPdf,
						sertifikat : (DzoneTemplateSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneTemplateSeminar.files[0].dataURL ,DzoneTemplateSeminar.files[0].name ),
						brosur : (DzoneBrosur.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBrosur.files[0].dataURL ,DzoneBrosur.files[0].name ),
						deskripsi: textDeskripsi,
						idSeminar : idSeminarEditing
						});
		
						if (createPengajuanSeminar.status_code != 200) {
							NotificationModal.show(`${createPengajuanSeminar.message.toString()}`, 'error');
							DzoneProposal.emit("removeThumbnailCustom");
							DzoneBrosur.emit("removeThumbnailCustom");
							DzoneTemplateSeminar.emit("removeThumbnailCustom");
							
							DzoneSuratPengantar.emit("removeThumbnailCustom");
							document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
							
						} else {
							swal.fire({
								title: 'Success',
								html: `${createPengajuanSeminar.message.toString()}`,
								icon: 'success',
								showCancelButton: false,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: '<i class="fas fa-check"></i> Ok'
							}).then(async (result) => {
								if (result.value) {
									
								};
							});
							
							DzoneProposal.emit("removeThumbnailCustom");
							DzoneBrosur.emit("removeThumbnailCustom");
							DzoneTemplateSeminar.emit("removeThumbnailCustom");
							
							DzoneSuratPengantar.emit("removeThumbnailCustom");
							document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
							
							
							$("#btnReset").trigger("click");
							$("#tablePengajuanSeminar").DataTable().ajax.reload();
							$('#modalTambahPengajuan').modal("hide");
						}
						e.preventDefault();
				} else {
						let fileProposalPdf = (DzoneProposal.files.length <= 0) ? null : new File([DzoneProposal.files[0]], DzoneProposal.files[0].name, { type: 'application/pdf' });
						let fileSuratPdf = (DzoneSuratExternal.files.length <= 0) ? null : new File([DzoneSuratExternal.files[0]], DzoneSuratExternal.files[0].name, { type: 'application/pdf' });
						let fileSuratPengantarPdf = (DzoneSuratPengantar.files.length <= 0) ? null : new File([DzoneSuratPengantar.files[0]], DzoneSuratPengantar.files[0].name, { type: 'application/pdf' });
						
						let noSuratText = $("#textNomorSurat").val();
						let textKeterangan = ""
	
						document.getElementById("btnSavePengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						
						const createPengajuanSeminar = await ApiSeminar.editPengajuanSeminarExternal({
							penyelenggara : textPenyelenggara,
							ketuaPelaksana : textKetuaPelaksana,
							nianKetuaPelaksana : nianKetuaPelaksana,
							jabatanKetuaPelaksana : jabatanKetuaPelaksana,
							sekretaris : textSekretaris,
							nianSekretaris : nianSekretaris,
							jabatan : jabatan,
							tempatPenyelenggaraan : tempatPenyelenggaraan,
							noSRPC : noSRPC,
							jumlahSKPPeserta : jumlahSKPPeserta,
							jumlahSKPModerator : jumlahSKPModerator,
							jumlahSKPPanitia : jumlahSKPPanitia,
							jumlahSKPNarasumber : jumlahSKPNarasumber,
							judulSeminar : judulSeminar,
							kategoriSeminar : kategoriSeminar,
							lingkupSeminar : lingkupSeminar,
							tanggalMulai : tanggalMulai,
							waktuMulai : waktuMulai,
							tanggalSelesai : tanggalSelesai,
							waktuSelesai : waktuSelesai,
							batasPendaftarOnline : batasPendaftarOnline,
							kategoriPeserta : textKategoriPeserta,
							quota : quota,
							kontribusiPeserta : kontribusiPeserta,
							proposal : fileProposalPdf,
							surat_pengantar_pc : fileSuratPengantarPdf,
							sertifikat : (DzoneTemplateSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneTemplateSeminar.files[0].dataURL ,DzoneTemplateSeminar.files[0].name ),
							brosur : (DzoneBrosur.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBrosur.files[0].dataURL ,DzoneBrosur.files[0].name ),
							deskripsi: textDeskripsi,
							surat :fileSuratPdf,
							no_surat : noSuratText,
							keterangan : textKeterangan,
							tanggalSurat : textTanggalSurat,
							idSeminar : idSeminarEditing
						});
		
						if (createPengajuanSeminar.status_code != 200) {
							NotificationModal.show(`${createPengajuanSeminar.message}`, 'error');
							DzoneProposal.emit("removeThumbnailCustom");
							DzoneBrosur.emit("removeThumbnailCustom");
							DzoneTemplateSeminar.emit("removeThumbnailCustom");
							DzoneSuratPengantar.emit("removeThumbnailCustom");
							document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
							
						} else {
							swal.fire({
								title: 'Success',
								html: `${createPengajuanSeminar.message.toString()}`,
								icon: 'success',
								showCancelButton: false,
								confirmButtonColor: '#3085d6',
								cancelButtonColor: '#d33',
								confirmButtonText: '<i class="fas fa-check"></i> Ok'
							}).then(async (result) => {
								if (result.value) {
									
								};
							});
							DzoneProposal.emit("removeThumbnailCustom");
							DzoneBrosur.emit("removeThumbnailCustom");
							DzoneTemplateSeminar.emit("removeThumbnailCustom");
							DzoneSuratPengantar.emit("removeThumbnailCustom");
							document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
							
							$("#btnReset").trigger("click");
							$("#tablePengajuanSeminar").DataTable().ajax.reload();
							$('#modalTambahPengajuan').modal("hide");
						}
						e.preventDefault();	
				}
			
				
				e.preventDefault();
				return
			}


			// state add
			if (textPenyelenggara == "internal") {
				if (DzoneProposal.files.length <= 0 || DzoneTemplateSeminar.files.length <=0 || DzoneBrosur.files.length <=0) {
					NotificationModal.show('File upload propsal / template sertifikat / brosur tidak boleh kosong !', 'error')
					document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
				}else {
					let fileProposalPdf = (DzoneProposal.files.length <= 0) ? null : new File([DzoneProposal.files[0]], DzoneProposal.files[0].name, { type: 'application/pdf' });
					let fileSuratPengantarPdf = (DzoneSuratPengantar.files.length <= 0) ? null : new File([DzoneSuratPengantar.files[0]], DzoneSuratPengantar.files[0].name, { type: 'application/pdf' });
					
					document.getElementById("btnSavePengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					
					const createPengajuanSeminar = await ApiSeminar.sendPengajuanSeminarInternal({
					penyelenggara : textPenyelenggara,
					ketuaPelaksana : textKetuaPelaksana,
					nianKetuaPelaksana : nianKetuaPelaksana,
					jabatanKetuaPelaksana : jabatanKetuaPelaksana,
					sekretaris : textSekretaris,
					nianSekretaris : nianSekretaris,
					jabatan : jabatan,
					tempatPenyelenggaraan : tempatPenyelenggaraan,
					noSRPC : noSRPC,
					jumlahSKPPeserta : jumlahSKPPeserta,
					jumlahSKPModerator : jumlahSKPModerator,
					jumlahSKPPanitia : jumlahSKPPanitia,
					jumlahSKPNarasumber : jumlahSKPNarasumber,
					judulSeminar : judulSeminar,
					kategoriSeminar : kategoriSeminar,
					lingkupSeminar : lingkupSeminar,
					tanggalMulai : tanggalMulai,
					waktuMulai : waktuMulai,
					tanggalSelesai : tanggalSelesai,
					waktuSelesai : waktuSelesai,
					batasPendaftarOnline : batasPendaftarOnline,
					kategoriPeserta : textKategoriPeserta,
					quota : quota,
					kontribusiPeserta : kontribusiPeserta,
					proposal : fileProposalPdf,
					surat_pengantar_pc : fileSuratPengantarPdf,
					sertifikat : (DzoneTemplateSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneTemplateSeminar.files[0].dataURL ,DzoneTemplateSeminar.files[0].name ),
					brosur : (DzoneBrosur.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBrosur.files[0].dataURL ,DzoneBrosur.files[0].name ),
					deskripsi: textDeskripsi
					});
	
					if (createPengajuanSeminar.status_code != 201) {
						NotificationModal.show(`${createPengajuanSeminar.message}`, 'error');
						DzoneProposal.emit("removeThumbnailCustom");
						DzoneBrosur.emit("removeThumbnailCustom");
						DzoneTemplateSeminar.emit("removeThumbnailCustom");
						DzoneSuratPengantar.emit("removeThumbnailCustom");
						document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
						
					} else {
						swal.fire({
							title: 'Success',
							html: "Seminar berhasil diajukan",
							icon: 'success',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: '<i class="fas fa-check"></i> Ok'
						}).then(async (result) => {
							if (result.value) {
								
							};
						});
						DzoneProposal.emit("removeThumbnailCustom");
						DzoneBrosur.emit("removeThumbnailCustom");
						DzoneTemplateSeminar.emit("removeThumbnailCustom");
						DzoneSuratPengantar.emit("removeThumbnailCustom");
						document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
						
						
						$("#btnReset").trigger("click");
						$("#tablePengajuanSeminar").DataTable().ajax.reload();
						$('#modalTambahPengajuan').modal("hide");
					}
					
	
					e.preventDefault();
	
				}
			} else {
				if (DzoneProposal.files.length <= 0 || DzoneTemplateSeminar.files.length <=0 || DzoneBrosur.files.length <=0 || DzoneSuratExternal.files.length <= 0) {
					NotificationModal.show('File upload propsal / template sertifikat / brosur dan surat external tidak boleh kosong!', 'error')
					document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
				}else {
					let fileProposalPdf = (DzoneProposal.files.length <= 0) ? null : new File([DzoneProposal.files[0]], DzoneProposal.files[0].name, { type: 'application/pdf' });
					let fileSuratPdf = (DzoneSuratExternal.files.length <= 0) ? null : new File([DzoneSuratExternal.files[0]], DzoneSuratExternal.files[0].name, { type: 'application/pdf' });
					let fileSuratPengantarPdf = (DzoneSuratPengantar.files.length <= 0) ? null : new File([DzoneSuratPengantar.files[0]], DzoneSuratPengantar.files[0].name, { type: 'application/pdf' });
					
					let noSuratText = $("#textNomorSurat").val();
					let textKeterangan = $("#textKeterangan").val();

					document.getElementById("btnSavePengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					
					const createPengajuanSeminar = await ApiSeminar.sendPengajuanSeminarExternal({
						penyelenggara : textPenyelenggara,
						ketuaPelaksana : textKetuaPelaksana,
						nianKetuaPelaksana : nianKetuaPelaksana,
						jabatanKetuaPelaksana : jabatanKetuaPelaksana,
						sekretaris : textSekretaris,
						nianSekretaris : nianSekretaris,
						jabatan : jabatan,
						tempatPenyelenggaraan : tempatPenyelenggaraan,
						noSRPC : noSRPC,
						jumlahSKPPeserta : jumlahSKPPeserta,
						jumlahSKPModerator : jumlahSKPModerator,
						jumlahSKPPanitia : jumlahSKPPanitia,
						jumlahSKPNarasumber : jumlahSKPNarasumber,
						judulSeminar : judulSeminar,
						kategoriSeminar : kategoriSeminar,
						lingkupSeminar : lingkupSeminar,
						tanggalMulai : tanggalMulai,
						waktuMulai : waktuMulai,
						tanggalSelesai : tanggalSelesai,
						waktuSelesai : waktuSelesai,
						batasPendaftarOnline : batasPendaftarOnline,
						kategoriPeserta : textKategoriPeserta,
						quota : quota,
						kontribusiPeserta : kontribusiPeserta,
						proposal : fileProposalPdf,
						surat_pengantar_pc : fileSuratPengantarPdf,
						sertifikat : (DzoneTemplateSeminar.files.length <= 0) ? null : await this.dataUrlToFile(DzoneTemplateSeminar.files[0].dataURL ,DzoneTemplateSeminar.files[0].name ),
						brosur : (DzoneBrosur.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBrosur.files[0].dataURL ,DzoneBrosur.files[0].name ),
						deskripsi: textDeskripsi,
						surat :fileSuratPdf,
						no_surat : noSuratText,
						keterangan : textKeterangan,
						tanggalSurat : textTanggalSurat
					});
	
					if (createPengajuanSeminar.status_code != 201) {
						NotificationModal.show(`${createPengajuanSeminar.message}`, 'error');
						DzoneProposal.emit("removeThumbnailCustom");
						DzoneBrosur.emit("removeThumbnailCustom");
						DzoneTemplateSeminar.emit("removeThumbnailCustom");
						DzoneSuratPengantar.emit("removeThumbnailCustom");
						document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
						
					} else {
						swal.fire({
							title: 'Success',
							html: "Seminar berhasil diajukan",
							icon: 'success',
							showCancelButton: false,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							confirmButtonText: '<i class="fas fa-check"></i> Ok'
						}).then(async (result) => {
							if (result.value) {
								
							};
						});
						DzoneProposal.emit("removeThumbnailCustom");
						DzoneBrosur.emit("removeThumbnailCustom");
						DzoneTemplateSeminar.emit("removeThumbnailCustom");
						DzoneSuratPengantar.emit("removeThumbnailCustom");
						document.getElementById("btnSavePengajuan").innerHTML = `<i class="fas fa-save"></i> Kirim Pengajuan</button>`;
						
						
						$("#btnReset").trigger("click");
						$("#tablePengajuanSeminar").DataTable().ajax.reload();
						$('#modalTambahPengajuan').modal("hide");
					}
					
	
					e.preventDefault();
	
				}	
			}
		
			e.preventDefault();
		}

		document.getElementById('form-pengajuan-seminar').addEventListener('submit', eventCreatPengajuanSeminar);
		
	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	async dataUrlToPDF(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const arrayBuffer = await res.arrayBuffer();
		
		return new File([arrayBuffer], fileName, { type: 'application/pdf' });
	},


}





export default PengajuanSeminarPCPresenter;