import CONFIG from './config.js';

const API_MENU_ANGGOTA_ENDPOINT = {
	CREATE_SKP : `${CONFIG.BASE_URL}me/skp`,
	COUNT_SKP : `${CONFIG.BASE_URL}me/count/skp`,
	SKP : (status)=>`${CONFIG.BASE_URL}me/skp?status=${status}`,
	FORMULIR_SERKOM : `${CONFIG.BASE_URL}rekom/serkom/formulir`,
	FORMULIR_STRTTK : `${CONFIG.BASE_URL}rekom/strttk/formulir`,
	FORMULIR_SIPTTK : `${CONFIG.BASE_URL}rekom/sipttk/formulir`,
	FORMULIR_MUTASI : `${CONFIG.BASE_URL}rekom/mutasi/formulir`,
	SKP_UPDATE :(skpID) => `${CONFIG.BASE_URL}me/skp/${skpID}`,
	UPDATE_SERKOM_BERKAS : (id) => `${CONFIG.BASE_URL}rekom/serkom/user/media/${id}`,
	UPDATE_STRTTK_BERKAS : (id) => `${CONFIG.BASE_URL}rekom/strttk/user/media/${id}`,
	UPDATE_SIPTTK_BERKAS : (id) => `${CONFIG.BASE_URL}rekom/sipttk/user/media/${id}`,
	PENGAJUAN_SERKOM : `${CONFIG.BASE_URL}rekom/serkom/user`,
	PENGAJUAN_SIPTTK :`${CONFIG.BASE_URL}rekom/sipttk/user`,

	PENGAJUAN_SERKOM_BY_ID : (pengajuanID) => `${CONFIG.BASE_URL}rekom/serkom/${pengajuanID}`,
	GET_TRACKING_SERKOM : (pengajuanID) => `${CONFIG.BASE_URL}tracking/user/rekom_serkom/${pengajuanID}`,
	GET_TRACKING_STRTTK : (pengajuanID) => `${CONFIG.BASE_URL}tracking/user/rekom_strttk/${pengajuanID}`,
	GET_TRACKING_SIPTTK : (pengajuanID) => `${CONFIG.BASE_URL}tracking/user/rekom_sipttk/${pengajuanID}`,
	GET_TRACKING_KECUKUPAN_SKP : (pengajuanID) => `${CONFIG.BASE_URL}tracking/user/pengajuan_kecukupan_skp/${pengajuanID}`,
	
	INFORMATION_STRTTK : `${CONFIG.BASE_URL}setting/informasi/strttk`,
	INFORMATION_SIPTTK : `${CONFIG.BASE_URL}setting/informasi/sipttk`,
	GET_STRTTK : `${CONFIG.BASE_URL}rekom/strttk/user`,
	GET_SIPTTK : `${CONFIG.BASE_URL}rekom/sipttk/user`,
	PENGAJUAN_STRTTK_BY_ID : (pengajuanID) => `${CONFIG.BASE_URL}rekom/strttk/${pengajuanID}`,
	PENGAJUAN_SIPTTK_BY_ID : (pengajuanID) => `${CONFIG.BASE_URL}rekom/sipttk/${pengajuanID}`,
	GET_PERSYARATAN_SIPTTK_BY_PC_ID : (regencySaranaID) => `${CONFIG.BASE_URL}setting/additional/sipttk/${regencySaranaID}`,
	PENGAJUAN_STRTTK : `${CONFIG.BASE_URL}rekom/strttk/user`,
	UPDATE_PENGAJUAN_SIPTTK : (idPengajuan) => `${CONFIG.BASE_URL}rekom/sipttk/user/${idPengajuan}`,

	PENDIDIKAN_SEARCH : (key) => `${CONFIG.BASE_URL}data/sekolah/dikti?search=${key}`,



	
};

export default API_MENU_ANGGOTA_ENDPOINT;
