
import CONFIG from '../config/globals/config.js';
import API_PENGURUS_PC_ENDPOINT from '../config/globals/menu_pengurus_pc_endpoint.js';
import API_ENDPOINT_PENGURUS_PC from "../config/globals/menu_pengurus_pc_endpoint.js";
import STATISTIK_ENDPOINT from '../config/globals/statistik_endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class ApiStats {
	//for all
	
	static async cencelInvoice({
		invoiceID,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(STATISTIK_ENDPOINT.CANCEL_INVOICE(invoiceID), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async sendBuktiBayar({
		buktiBayar,
		invoiceID,
		tanggalSetor,
		paymentMethod,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		if (buktiBayar != null) {
			formdata.append("deposit_id", invoiceID);
			formdata.append("bukti_bayar_deposit", buktiBayar);
			formdata.append("deposit_date", tanggalSetor);
			formdata.append("payment_method", paymentMethod);
		}


		try {
			const fetchApi = await fetchWithTimeout(STATISTIK_ENDPOINT.SEND_BUKTI_BAYAR, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	// for pc
	static async getStats({startDate,endDate}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.GET_STATS_PC(startDate,endDate),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async getStatsIuranPC({startDate,endDate}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.GET_STATS_IURAN_PC(startDate,endDate),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async requestInvoice({
		role,
		totalBayar,
		description,
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"total_bayar": totalBayar,
			"description": description
		});

		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.REQUEST_INVOICE(role),{
				method : "POST",
				headers: myHeaders,
				body: raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	//for pd
	
	static async getStatsPD({startDate,endDate}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.GET_STATS_PD(startDate,endDate),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
	static async getCommentar(
		pengajuanID,
		commentFor,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.GET_COMMENTAR(pengajuanID,commentFor),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async sendCommentar({
		pengajuanID,
		commentFor,
		contentComment,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": '',
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.SEND_COMMENT_PD,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async approveSharing({
		id,
		role,
		approval,
		catatan,
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var raw = JSON.stringify({
			"deposit_id": id,
			"approval": approval,
			"catatan": catatan
		});
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.APPROVE_DEPOSIT(role),{
				method : "PUT",
				headers: myHeaders,
				body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async getStatsIuran({startDate,endDate,role}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.GET_STATS_IURAN(startDate,endDate,role),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async requestInvoicePD({
		totalBayar,
		description,
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"total_bayar": totalBayar,
			"description": description
		});

		try {
			const response = await fetchWithTimeout(STATISTIK_ENDPOINT.REQUEST_INVOICE_PD,{
				method : "POST",
				headers: myHeaders,
				body: raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async downloadDepostPD(
		start , end , status , idCabang
	) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(STATISTIK_ENDPOINT.DWONLOAD_DEPOSIT_PD(start,end,status,idCabang), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Deposit Out PD.xlsx';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
		return 200
		} catch (err) {
			console.log(err)
			return 400;
		}
	}


	static async downloadDepostPP(
		start , end , status , idCabang
	) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetch(STATISTIK_ENDPOINT.DWONLOAD_DEPOSIT_PP(start,end,status,idCabang), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Deposit Out PP.xlsx';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
		return 200
		} catch (err) {
			console.log(err)
			return 400;
		}
	}


   
}


export default ApiStats;