import CONFIG from './config.js';

const SEMINAR_ENPOINT = {
	UPLOAD_BUKTI_BAYAR_SEMINAR:(id) => `${CONFIG.BASE_URL}permohonan/seminar/pc/upload/${id}/bukti`,
	UPLOAD_BUKTI_BAYAR_SEMINAR_PD:(id) => `${CONFIG.BASE_URL}permohonan/seminar/pd/upload/${id}/bukti`,
	DELETE_MATERI_SEMINAR: (id) => `${CONFIG.BASE_URL}materi/seminar/${id}`,
	DOWONLOAD_FILE_IMPORT_ANGGOTA:`${CONFIG.BASE_URL}permohonan/seminar/peserta/import/example`,
	DOWNLOAD_SK: (id, wilayah) =>`${CONFIG.BASE_URL}print/sk/${wilayah}/${id}`,
	SEND_PENGAJUAN_SEMINAR_PENGURUS_PC: `${CONFIG.BASE_URL}permohonan/seminar/pc`,
	EDIT_PENGAJUAN_SEMINAR_PENGURUS : (id) =>`${CONFIG.BASE_URL}permohonan/seminar/${id}`,
	GET_MATERI_SEMINAR_BY_ID: (id) => `${CONFIG.BASE_URL}materi/seminar/${id}`,
	EDIT_MATERI_SEMINAR_BY_ID: (id) => `${CONFIG.BASE_URL}materi/seminar/${id}`,
	PUBLISH_PENGAJUAN_SEMINAR_PENGURUS : (id) =>`${CONFIG.BASE_URL}permohonan/seminar/status/${id}`,
	SEND_PENGAJUAN_SEMINAR_PENGURUS_PD: `${CONFIG.BASE_URL}permohonan/seminar/pd`,
	SEND_PENGAJUAN_SEMINAR_PENGURUS_PP: `${CONFIG.BASE_URL}permohonan/seminar/pp`,
	GET_PENGAJUAN_SEMINAR_PC: (status , status_approval) => `${CONFIG.BASE_URL}permohonan/seminar/pc/list?status=${status}&status_approval=${status_approval}`,
	GET_PENGAJUAN_SEMINAR_PD: (status, status_approval) => `${CONFIG.BASE_URL}permohonan/seminar/pd/list?status=${status}&status_approval=${status_approval}`,
	GET_PENGAJUAN_SEMINAR_PP: (status) => `${CONFIG.BASE_URL}permohonan/seminar/pp/list?status=${status}`,
	ADD_MATERI_SEMINAR: `${CONFIG.BASE_URL}materi/seminar`,
	GET_PENGAJUAN_SEMINAR_PD_IN: (kategori, status_pd, status_pp, pembayaran_pd, pembayaran_pp, status_agrement ,id_cabang) => `${CONFIG.BASE_URL}permohonan/seminar/pd/in?id_cabang=${id_cabang}&kategori_seminar=${kategori}&status_pd=${status_pd}&status_pp=${status_pp}&pembayaran_pd=${pembayaran_pd}&pembayaran_pp=${pembayaran_pp}&status_agreement=${status_agrement}`,
	GET_PENGAJUAN_SEMINAR_PP_IN: (kategori,statusPD, statusPP, pembayaranPD, pembayaranPP, agreementStatus,idDaerah,idCabang) => `${CONFIG.BASE_URL}permohonan/seminar/pp/in?id_cabang=${idCabang}&id_daerah=${idDaerah}&kategori_seminar=${kategori}&status_pd=${statusPD}&status_pp=${statusPP}&pembayaran_pd=${pembayaranPD}&pembayaran_pp=${pembayaranPP}&status_agreement=${agreementStatus}`,
	ACC_PD_APPROVE:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/pd/approval/${id}`,
	ACC_PP_APPROVE:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/pp/approval/${id}`,
	ACC_PP_PAYMENT:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/pp/approval/payment/${id}`,
	ACC_PD_PAYMENT:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/pd/approval/payment/${id}`,
	ACC_TTD:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/agreement/${id}`,
	GET_COMMENT_PENGAJUAN: (userID,commentFor,pengajuanID) => `${CONFIG.BASE_URL}comment/seminar/${pengajuanID}`,
	IMPORT_PESERTA_SEMINAR:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/peserta/import/${id}`,
	REPORT_SEMINAR:(id) =>`${CONFIG.BASE_URL}permohonan/seminar/laporan/${id}`,
	GET_LIST_PESERTA_SEMINAR: (id) => `${CONFIG.BASE_URL}permohonan/seminar/peserta/list/${id}`,
	GET_SEMINAR_DASHBOARD: (wilayah, jenis) => `${CONFIG.BASE_URL}permohonan/seminar/user?is_active=1&wilayah=${wilayah}&kategori_seminar=${jenis}`,
	UPDATE_PATCH_SEMINAR: (id) =>  `${CONFIG.BASE_URL}permohonan/seminar/${id}`,
	UPDATE_MEDIA_SEMINAR:(id) => `${CONFIG.BASE_URL}permohonan/seminar/media/${id}`
};

export default SEMINAR_ENPOINT;
