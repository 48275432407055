import STRTTKPDPresenter from "../../../presenter/menu_pengurus_pd/strttk-anggota-pd-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const STRTTKPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiSTRTTK" class="nav-link hapus-border active" id="data-verifikasi-skp" data-bs-toggle="tab" data-bs-target="#tabVerifikasiSTRTTK" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiSTRTTK" aria-selected="true">Data Permohonan</button>
					</li>
					<li class="nav-item" role="presentation">
					    <button href="#tabPersyaratanSTRTTK" class="nav-link hapus-border" id="tab2" data-bs-toggle="tab" data-bs-target="#tabPersyaratanSTRTTK" data-toggle="tab" type="button" role="tab" aria-controls="tabPersyaratanSTRTTK" aria-selected="false">Persyaratan</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiSTRTTK" role="tabpanel aria-labelledby="tab-verifikasi-skp">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Pengajuan Rekomendasi STRTTK Anggota
                                    </div>
                                    <div class="card-body">
                                                
                                        <button type="button" class="btn btn-outline-primary btn-jenis-permohonan" id="btnPermohonanKTAN" value="pending_pd">
                                        Permohonan
                                        </button>
                                        <button type="button" class="btn btn-outline-primary  btn-jenis-permohonan" id="btnDisetujuiKTAN" value="approve_pd">
                                            Disetujui
                                        </button>
                                        <button type="button" class="btn btn-outline-primary btn-jenis-permohonan" id="btnRevisiKTAN" value="revise_pd">
                                            Revisi
                                        </button>

                                        
                                        <div class="col-sm-5 col-xl-5 col-lg-5 text-center">
                                            <div class="row">
                                                <div class="col-sm-8 mb-2">
                                                
                                                </div>
                                                <div class="col-sm-4 d-none">
                                                    <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                        <i class="fas fa-check"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                        <i class="fas fa-print"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                        <i class="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanSTRTTK" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="name"  rowspan="2">Nama Anggota</th>
                                                                <th data-column="created_at" rowspan="2">Tanggal Pengajuan</th>
                                                                <th data-column="jenis_permohonan"  rowspan="2">Jenis Permohonan</th>
                                                                <th colspan="2" align="center">Berkas Data Umum</th>
                                                                <th colspan="7" align="center">Persyaratan Wajib</th>
                                                                <th data-column="status_pc" rowspan="2">Status PC</th>
                                                                <th data-column="status_pd" rowspan="2">Status PD</th>
                                                                <th rowspan="2">Aksi</th>
                                                            </tr>
                                                            <tr>
                                                                <th>KTAN</th>
                                                                <th>KTP</th>
                                                                <th>IJAZAH</th>
                                                                <th>SERKOM</th>
                                                                <th>STRTTK</th>
                                                                
                                                                <th>SURAT KETERANGAN PRAKTIK</th>
                                                                <th>SURAT KEABSAHAN DOKUMEN</th>
                                                                <th>SURAT PATUH KODE ETIK</th>
                                                                <th>SURAT SUMPAH</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer square"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer square"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer square"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

					<div class="tab-pane fade" id="tabPersyaratanSTRTTK" role="tabpanel" aria-labelledby="pengajuan-seminar-tab">
                       
                        <div class="row">
                            <div class="col-sm-8 d-none">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Informasi Persyaratan Rekomendasi STRTTK
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Informasi STRTTK</label>
                                            <div class="col-sm-12">
                                                <textarea id="textInformasiSTRTTK" class="form-control" placeholder="Informasi Persyaratan..."></textarea>
                                            </div>
                                        </div>

                                        <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateInformasiSTRTTK"><i class='fas fa-edit'></i> Update Informasi</button>       
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Formulir Persyaratan Rekomendasi STRTTK
                                    </div>
                                    <div class="card-body">
                                        <form id="form-persyaratan-STRTTK">
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nama Berkas</label>
                                                <div class="col-sm-12">
                                                    <input type="hidden" id="textHIddenPersyaratan" class="form-control">
                                                    <input type="text" id="textNamaBerkas" class="form-control" placeholder="Nama Berkas..." required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="control-label col-sm-12 font-weight-bold">Deskripsi</label>
                                                <div class="col-sm-12">
                                                    <textarea id="textDeskripsi" class="form-control" placeholder="Deskripsi Persyaratan..." required></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                                <div class="col-sm-12 text-right">
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnAddSyaratSTRTTK"><i class='fas fa-plus'></i> Tambah Persyaratan</button>
                                                    <button class="mb-2 btn btn-sm btn-primary" id="btnUpdateSyaratSTRTTK"><i class='fas fa-edit'></i> Edit Persyaratan</button>
                                                    <button type="button" class="mb-2 btn btn-sm btn-light border border-primary" id="btnClearSyaratSTRTTK"><i class='fas fa-eraser'></i> Batal</button>  
                                                </div>     
                                            </div>     
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card mt-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Data Persyaratan Rekomendasi STRTTK
                                    </div>
                                    <div class="card-body">
                        
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover" id="tablePersyaratanSTRTTK" width="100%" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Berkas</th>
                                                        <th>Deskripsi</th>
                                                        <th width="10%">Aksi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line"></div></td>
                                                        <td><div class="shimmer title-line end"></div></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    
                                                </tfoot>
                                                <tbody>
                                                
                                                
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>

        <div class="modal fade" id="detailSTRTTKModal" tabindex="-1" role="dialog" aria-labelledby="detailSTRTTKModal"
        aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="">Detail Pengajuan Rekomendasi Registrasi Ulang STRTTK</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="bodyModalSTRTTK">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="label control-label">Tanggal Surat Rekomendasi</label>
                                        <input type="hidden" id="textHidden" class="form-control" readonly>
                                        <input type="text" id="tanggalSuratRekomendasi" class="form-control" readonly>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="label control-label">Nomor Surat Rekomendasi</label>
                                        <input type="text" id="textNomorSuratRekomendasi" class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold">Data Pendidikan</label>
                                    <div class="col-sm-12 overflow-auto">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Tahun Lulus</th>
                                                <th>Jenjang</th>
                                                <th>Nama Institusi</th>
                                                <th>Tanggal Ijazah</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="dataPendidikanSTRTTK">
        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold">Data Pekerjaan</label>
                                    <div class="col-sm-12 overflow-auto">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Nama Instansi</th>
                                                <th>Alamat</th>
                                                <th>Telp/Fax</th>
                                                <th>No HP</th>
                                                <th>Email</th>
                                                <th>Jabatan</th>
                                                <th>Jenis Tempat Kerja</th>
                                                <th>Dari Tahun</th>
                                                <th>Sampai Tahun</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="dataPekerjaan">
        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="label control-label">Status</label>
                                        <select class="form-control" id="selectStatusApprove">
                                            <option value="">Pilih Salah Satu</option>
                                            <option value="approve">Acc</option>
                                            <option value="revise">Tolak</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="label control-label">Catatan</label>
                                        <textarea id="textCatatan" class="form-control" placeholder="Isi jika pengajuan rekomendasi di tolak"></textarea>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-12">
                                        <button style="width:100%;" class="btn btn-sm btn-primary" id="btnSave"><i class="fas fa-save"></i> Simpan</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <input type="hidden" id="textHIddenSTRTTK" class="form-control">
                                        <label class="label control-label">Tanggal Pengajuan</label>
                                        <input type="text" id="tanggalPengajuan" class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">KTAN</label>
                                        <input type="text" id="textKTAN" placeholder="KTAN..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">Nama Anggota</label>
                                        <input type="text" id="namaAnggota" placeholder="Nama Anggota..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">Email</label>
                                        <input type="text" id="textEmail" placeholder="Email..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">Jenis Permohonan</label>
                                        <input type="text" id="textJenisPermohonan" placeholder="Jenis Permohonan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">Di Perpanjang Dengan</label>
                                        <input type="text" id="textPerpanjangDengan" placeholder="Di Perpanjang Dengan..." class="form-control" readonly>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="label control-label">Status</label>
                                        <input type="text" id="textStatus" placeholder="Status..." class="form-control" readonly>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <label class="label col-sm-12 control-label font-weight-bold">Persyaratan</label>
                                    <div class="col-sm-12">
                                        <table class="table table-bordered table-hovered" width="100%">
                                            <thead>
                                                <th>Nama Persyaratan</th>
                                                <th class="text-center">Dokumen</th>
                                            </thead>
                                            <tbody id="persyaratanSTRTTK">
            
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <hr>
                                    <div class="col-sm-12">
                                        <div class="card">
                                            <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                                <h6 class="m-0 font-weight-bold text-primary">Komentar Kelengkapan Berkas</h6>
                                            </div>
                                            <div class="card-body chat-body p-1" id="lamanChat">
                                                <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-left">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                    <div style="width:100%">
                                                        <div style="width:10%;" class="float-right">
                                                            <div class="shimmer circle"></div>
                                                        </div>
                                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line"></div>
                                                            <div class="shimmer content-line end"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer">
                                                <form id="form-comment">
                                                    <label class="control-label font-weight-bold">Komentar</label>
                                                    <textarea class="form-control" id="textKomentar" name="komentar" placeholder="Komentar..." required></textarea>
                                                    <br>
                                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendComment"><i class='fas fa-comment'></i> Kirim</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
        
        `;
		return view;
	  },

	async afterRender() {
		await STRTTKPDPresenter.init();
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default STRTTKPD;