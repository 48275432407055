
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiPengajuanSerkomPC from '../../api/api_pengurus_pc/pengajuan-serkom.js';
import NotificationModal from "../../utils/initial_notification.js";
import UrlParser from "../../routes/url-parser.js";
import ApiNotication from "../../api/data-notification.js";
import MembershipHelper from "../../utils/membership-helper.js";
import ApiKecukupanSKP from "../../api/data-kecukupan-skp.js";
import KECUKUPAN_SKP_ENDPOINT from "../../config/globals/kecukupan_skp_endpoint.js";
import Autentication from "../../utils/autentication.js";

const KecukupanSkpAnggotaPresenter = {

	async init() {
		//await MembershipHelper.scyncMembershipHelper();
		await this._setView();
		await this._getFormulirPengajuan();
		await this._sendPengajuanSerkom();
		await this._uploadBerkas();
		await this._getPengajuanSerkom();
		await this._getInformation();
		await this._readNotification();
		await this._downloadKecukupanSKP();
		
	},

	async _downloadKecukupanSKP() {

		$('#tablePengajuanSerkom tbody').on('click', '#download_keterangan', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			let table = $('#tablePengajuanSerkom').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id;
			swal.fire({
				title: 'Download',
				html: "Anda ingin Download data ini? <br> <strong>Nomor Surat </strong>: " + data.nomor_surat,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, download!'
			}).then(async (result) => {	
				
				if (result.value) {
					$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					let dataUser = await Autentication.getData()
				
					const approveResponse = await ApiKecukupanSKP.downloadKecukupanSKPByID({
						id: idPengajuan,
						name: dataUser[0].name
					});
					if (approveResponse != 200) {
						NotificationModal.show(`Data Gagal diunduh`, 'error');
					}
					$(this).html('<i class="fas fa-download"></i>');
					$('#tablePengajuanSerkom').DataTable().ajax.reload();
				}else{
					$(this).html('<i class="fas fa-download"></i>');
				};
			});

		})
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},
	
	async _ubahFormatTanggal(tanggal){
		// Membagi tanggal menjadi komponen hari, bulan, dan tahun
		var komponenTanggal = tanggal.split("-");
		var hari = komponenTanggal[0];
		var bulan = komponenTanggal[1];
		var tahun = komponenTanggal[2];
	  
		// Membentuk tanggal baru dalam format "yyyy-MM-dd"
		var tanggalBaru = tahun + "-" + bulan + "-" + hari;
	  
		return tanggalBaru;
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
			const eventChangeBerkas= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas").classList.remove("d-none")
					document.getElementById("valueAlertBerkas").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas").addEventListener("change", eventChangeBerkas);
		} else {
			window.location.hash = '#/not-access-fitur';
		}
		$("#btnUpdateSKP").hide();
	},

	async _showLoading() {

	},

	async _hideLoading() {

	},

	async _getSUMSKP() {
		this._showLoading();

		this._hideLoading();

	},


	async _getPengajuanSerkom() {
		var status = 'all';
		let table = await $('#tablePengajuanSerkom').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${KECUKUPAN_SKP_ENDPOINT.GET_PENGAJUAN_LIST}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'created_at' },
				{ data: 'nomor_surat', render: function (data) { 
					if (data == null) {
						return `-`
					}else{
						return data
					}
				}},
				{ data: 'berlaku_dari' , render: function (data,w,row) { 
					
					if (data == null) {
						return ` - | -`
					}else{
						return `${row.berlaku_dari} -  ${row.berlaku_sampai} `
					}
				}},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 5,
					"data": null,
					"render": function (data, type, row) {
						var PDID = localStorage.getItem(CONFIG.USER_PD_ID)
						var disabledDownload = ``
						//KOTA BANDUNG 3273
						if (row.status_pd != "approve" || PDID != 32) {
							disabledDownload = `d-none`
						}
					return `<div style="display:flex">
						
					<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSerkomModal" id='detail' title='Detail'><i class="fas fa-list"></i></button>
					<button style="margin:1px" class='btn btn-primary btn-sm ${disabledDownload}' id='download_keterangan' title='Download Keterangan Kecukupan SKP'><i class='fas fa-download'></i></button>
					<button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
					},
					
				},

				
			]
		});

		let id = '';
		$('#tablePengajuanSerkom tbody').on('click', '#detail', async function () {
			$('#btnSendComment').on('click');
			let data = table.row($(this).parents('tr')).data();
			id = data.id;
			$("#hiddenIdPengajuan").val(id);
			let table_tracking = await $("#tableHistoryVerifikasiSERKOM").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_ANGGOTA.GET_TRACKING_KECUKUPAN_SKP(id)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{
						"data": null,
						"class": "align-top",
						"searchable": false,
						"render": function (data, type, row, meta) {
							return meta.row + meta.settings._iDisplayStart + 1;
						}
					},
					{ data: 'created_at' },
					{ data: 'actor.name' },
					{ data: 'actor_type' },
					{ data: 'description' },
				],

				"order": [[0, 'desc']],
			});


			$("#persyaratanSerkom").html('');

			const getDetailResponse = await ApiKecukupanSKP.getPengajuanByID({ id });
			
			let dataDetail = getDetailResponse.data;
			if (getDetailResponse.status_code == 200) {
				$("#tanggalPengajuan").val(dataDetail.created_at);
				let dataUser = await Autentication.getData()
				
				$("#namaAnggota").val(dataUser[0].name);
				if (dataDetail.berlaku_dari == null) {
					$("#tanggalSerkom").val("- | -");
				}else{
					$("#tanggalSerkom").val(`${dataDetail.berlaku_dari} - ${dataDetail.berlaku_sampai} `);
				}

				$("#nomorSerkom").val(dataDetail.nomor_surat);

				var dokumen = dataDetail.dokumen
				var persyaratanSerkom = ``
				dokumen.forEach(dataBerkas => {
					persyaratanSerkom += `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`

				});

				
				$("#persyaratanSerkom").html(persyaratanSerkom);

			}


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}

			let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
			let dataLocal = JSON.parse(local);
			let UserID = dataLocal[0].id;
			let response = await ApiAnggota.getCommentar(id,"pengajuan_kecukupan_skp")
			let dataComment = response.data;
			dataComment.reverse();
			let getAllContent = async () => {
				let elmHtml = ``
				await dataComment.forEach(async element => {
					elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
				});
				return elmHtml
			}


			if (response.data == undefined || response.data.length <= 0) {
				document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
			} else {
				document.getElementById("content-comment").innerHTML = await getAllContent();

				setTimeout(() => {
					var elem = document.getElementById('content-comment');
					elem.scrollTop = elem.scrollHeight;
				}, 400);
			}

			$("#btnSendComment").on('click', async function (event) {

				

				if (UserID != '' && id != '') {
					document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					let content = document.getElementById("textKomentar").value
					const response = await ApiAnggota.sendCommentar({
						pengajuanID: id,
						contentComment: content,
						commentFor: 'pengajuan_kecukupan_skp',
					});
					if (response.status_code == 200 || 201) {

						let getContentAdmin = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-left text-dark text-left" style="width:88%;">
											<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}

						let getContentUser = (user) => {
							let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
							return `
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-right text-dark text-left" style="width:88%;">
											<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
						}


						let response = await ApiAnggota.getCommentar(id,"pengajuan_kecukupan_skp")
						let dataComment = response.data;
						dataComment.reverse();

						let getAllContent = async () => {
							let elmHtml = ``
							await dataComment.forEach(async element => {
								elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
							});
							return elmHtml
						}

						if (response.data == undefined || response.data.length <= 0) {
							document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
						} else {
							document.getElementById("content-comment").innerHTML = await getAllContent();

							setTimeout(() => {
								var elem = document.getElementById('content-comment');
								elem.scrollTop = elem.scrollHeight;
							}, 400);
						}
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					} else {
						document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
						$("#textKomentar").val('');
					}



				}

			});

			$('#detailSerkomModal').on('hidden.bs.modal', async function (e) {
				$('#btnSendComment').off('click');
			});
		});

		$('#tablePengajuanSerkom tbody').on('click', '#delete', async function () {
			let data = table.row($(this).parents('tr')).data();

			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Tanggal Pengajuan </strong>: " + data.created_at,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idSerkom = data.id;
					const deletePengajuanSerkom = await ApiKecukupanSKP.deletePengajuan({
						id: idSerkom
					});

					if (deletePengajuanSerkom.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePengajuanSerkom.message,
							icon: 'success',
							showConfirmButton: true,
							timer: 10000
						});
						//refres manual 
						$('#tablePengajuanSerkom').DataTable().ajax.reload();

					} else {
						swal.fire({
							title: 'Error',
							text: deletePengajuanSerkom.message,
							icon: 'error',
							showConfirmButton: true,
							timer: 10000
						});
					}
				};
			});
		});


		$('#detailSerkomModal').on('hidden.bs.modal', function () {

			$('#detailSerkomModal').off('shown.bs.modal');
		});

	},


	async _getInformation() {
		const getInformation = await ApiPengajuanSerkomPC.getInformationSerkom();
		if (getInformation.status_code == 404) {
			$("#information").html('Informasi belum di isi !');
		} else {
			$("#information").html(getInformation.data.description);
		}
	},

	async _getFormulirPengajuan() {

	
		$("#textNamaLengkap").attr('placeholder','Loading...');
		$("#textKTAN").attr('placeholder','Loading...');
		$("#textTempatLahir").attr('placeholder','Loading...');
		$("#dateTanggalLahir").attr('placeholder','Loading...');
		$("#textJenisKelamin").attr('placeholder','Loading...');
		$("#textAlamatDomisili").attr('placeholder','Loading...');
		$("#selectPendidikan").attr('placeholder','Loading...');
		$("#selectPekerjaan").attr('placeholder','Loading...');


		await this._getPendidikan();
		await this._getPekerjaan();
		const getFormulir = await ApiAnggota.getFormulirSerkom();


		let dataformulir = getFormulir.data;
		let data = getFormulir.data;

		if (data.skp_baksos <= 0 && data.total_skp < 25) {
			$('#alertWarning').html('<i class="fas fa-exclamation-triangle"></i> <strong>Warning : Anda tidak dapat mengajukan Surat keterangan Kecukupan SKP! </strong>');
		}
		let contentSKPBaksos = ``;
		let contentTotalSKP = ``;
		if (data.skp_baksos <= 0) {
			contentSKPBaksos = `
			<strong class="text-danger">SKP Pengabdian Masyarakat: ${data.skp_baksos} <i class="fas fa-times"></i></strong> <br>
		`
		}else {
			contentSKPBaksos = `
			<strong class="text-success">SKP Pengabdian Masyarakat: ${data.skp_baksos} <i class="fas fa-check"></i></strong> <br>
		`
		}
		if (data.total_skp < 25) {
			contentTotalSKP = `
			<strong class="text-danger">SKP Total: ${data.total_skp} <i class="fas fa-times"></i></strong> 
		`
		}else {
			contentTotalSKP = `
			<strong class="text-success">SKP Total: ${data.total_skp} <i class="fas fa-check"></i></strong> 
		`
		}
		
		$("#informationSKP").html(contentSKPBaksos + contentTotalSKP);


		if (dataformulir.ktan == null || dataformulir.ktan == "") {
			var warning_ktan = `<li><strong>Anda Belum Memiliki KTAN !</strong></li>`;
			$('#alertWarning').append(warning_ktan);
			$("#alertWarning").show();
		}
		if (dataformulir.berkas == null || dataformulir.domisili == null || dataformulir.jenis_kelamin == null || dataformulir.pekerjaan == null || dataformulir.pendidikan == null) {
			var warning_biodata = `<li><strong>Data diri anda belum lengkap !</strong></li>`;
			$('#alertWarning').append(warning_biodata);
			$("#alertWarning").show();
		}


		if (data.domisili == null || data.jenis_kelamin == null || data.ktan == null || data.name == null || data.pekerjaan == null || data.pendidikan == null || data.phone_number == null || data.tanggal_lahir == null || data.tanggal_lahir == null || data.tempat_lahir == null) {
			swal.fire({
				title: 'Profile anda belum lengkap !',
				html: "Silakan lengkapi profile anda !",
				icon: 'warning',
				showCancelButton: false,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oke'
			}).then(async (result) => {
				if (result.value) {
					window.location.hash = '/';
				};
			});
		}

		let berkasGet = await ApiKecukupanSKP.getSyaratBerkas()
		console.log(berkasGet.data)
		let berkas = berkasGet.data;

		// here todo
		berkas.forEach(dataBerkas => {
			let option= new Option(dataBerkas.name, dataBerkas.document_name)
			option.setAttribute("description-lng",dataBerkas.description)
			document.getElementById("selectBerkas").add(option)
		});

		if (getFormulir.status_code != 200) {
			NotificationModal.show(`${getFormulir.message}`, 'error');
		} else {
			var jenis_kelamin = '';
			if (data.jenis_kelamin == 'Laki-laki') {
				jenis_kelamin = 'Laki-laki'
			} else {
				jenis_kelamin = 'Perempuan'
			}
			$("#textNamaLengkap").val(data.name);
			$("#textKTAN").val(data.ktan);
			$("#textTempatLahir").val(data.tempat_lahir);
			$("#dateTanggalLahir").val(data.tanggal_lahir);
			$("#textJenisKelamin").val(jenis_kelamin);
			$("#textAlamatDomisili").val(data.domisili.address + ', DESA / KELURAHAN ' + data.domisili.village_name + ', KECAMATAN ' + data.domisili.sub_regency_name + ', ' + data.domisili.regency_name + ', ' + data.domisili.province_name);

		}

	},

	async _getPendidikan() {

		let contentPendidikan = ``
		let no = 0
		const getPendidikan = await ApiAnggota.getPendidikan();
		getPendidikan.data.forEach(dataPendidikan => {
			no++
			contentPendidikan += `
			<tr>
			<td scope="col">${no}</td>
			<td scope="col">${dataPendidikan.jenjang}</td>
			<td scope="col">${dataPendidikan.nama_institusi_pendidikan}</td>
			<td scope="col">${dataPendidikan.tahun_lulus}</td>
			<td scope="col">${dataPendidikan.tanggal_ijazah}</td>
			<tr>
			`
		});

		document.getElementById("content_data_pendidikan").innerHTML = contentPendidikan;
	},


	async _getPekerjaan() {
		let contentPendidikan = ``
		let no = 0
		const getPekerjaan = await ApiAnggota.getPekerjaan();

		getPekerjaan.data.forEach(dataPekerjaan => {
			no++
			contentPendidikan += `
			<tr>
			<td scope="col">${no}</td>
			<td scope="col">${dataPekerjaan.nama_tempat_kerja}</td>
			<td scope="col">${dataPekerjaan.alamat_tempat_kerja}</td>
			<td scope="col">${dataPekerjaan.telp_fax}</td>
			<td scope="col">${dataPekerjaan.phone_number}</td>
			<td scope="col">${dataPekerjaan.email}</td>
			<tr>
			`
		});

		document.getElementById("content_data_pekerjaan").innerHTML = contentPendidikan;

		
	},


	async _sendPengajuanSerkom() {

		const eventSend = async (e) => {
			e.preventDefault();
			swal.fire({
				title: 'Ajukan',
				html: "Anda ingin melakukan pengajuan Surat kecukupan SKP?",
				icon: 'info',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ajukan!'
			}).then(async (result) => {
				if (result.value) {

					const createResponse = await ApiKecukupanSKP.createKecukupanSKP();

					if (createResponse.status_code == '201') {
						swal.fire({
							title: 'Pengajuan',
							text: createResponse.message,
							icon: 'success',
							showConfirmButton: true,
							timer: 5000
						});
						//refres manual 
						
						$('#tablePengajuanSerkom').DataTable().ajax.reload();

					} else {
						swal.fire({
							title: 'Error',
							text: createResponse.message,
							icon: 'error',
							showConfirmButton: true,
							timer: 5000
						});
					}
				};
			});

			e.preventDefault();
		}

		
		document.getElementById("btnSendKecukupan").addEventListener("click",eventSend)

	},

	async _uploadBerkas() {
		let DzoneBerkasSerkom = new Dropzone("#form-upload-serkom", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-serkom .dz-preview:hidden').remove()
					$('#form-upload-serkom .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-serkom").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			$("#persyaratanSerkom").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSerkom").html('');
			let id = $("#hiddenIdPengajuan").val();
			document.getElementById("btnUpdateBerkas").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSerkom = await ApiKecukupanSKP.uploadBerkasKecukupanSKP({
				id: id,
				document_name: document.getElementById('selectBerkas').value,
				dokumen: (DzoneBerkasSerkom.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasSerkom.files[0].dataURL, DzoneBerkasSerkom.files[0].name)
			});
			if (uploadBerkasSerkom.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'error');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSerkom.message}`, 'success');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const getDetailPengajuanSerkom = await ApiKecukupanSKP.getPengajuanByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;
			if (getDetailPengajuanSerkom.status_code == 200) {
		
				var dokumen = dataDetail.dokumen
				var persyaratanSerkom = ``
				dokumen.forEach(dataBerkas => {
					 persyaratanSerkom += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					
				});
				$("#persyaratanSerkom").html(persyaratanSerkom);

			}

			DzoneBerkasSerkom.emit("removeThumbnailCustom");
			e.preventDefault();
		}
		document.getElementById('btnUpdateBerkas').addEventListener('click', eventUploadBerkas);
	},


	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},




}

export default KecukupanSkpAnggotaPresenter;