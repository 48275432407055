
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiKepengurusanPC {

	static async downloadAnggota({statusRegist, statusKTAN, start, length}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.DOWNLOAD_ANGGOTA(statusRegist, statusKTAN, start, length), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			var currentDate = new Date();

			// Extract the individual date components
			var year = currentDate.getFullYear(); // Full year (e.g., 2023)
			var month = currentDate.getMonth() + 1; // Month (0-11, so add 1 for January-December)
			var day = currentDate.getDate(); // Day of the month (1-31)
			var hours = currentDate.getHours(); // Hours (0-23)
			var minutes = currentDate.getMinutes(); // Minutes (0-59)
			var seconds = currentDate.getSeconds(); // Seconds (0-59)

			a.href = url;
			a.download = 'DATA ANGGOTA '+day+month+year+"_"+hours+minutes+seconds+".xlsx";
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadKeteranganKeanggotaan({ userId, nomorUrutSurat, sipttkId, namaAnggota }) {
		try {
			let myHeaders = new Headers();
			let token = await Autentication.getToken();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append('Authorization', `Bearer ${token.replace(/"/g, '')}`); // Mengganti semua tanda kutip ganda
			const raw = JSON.stringify({
				"nomor_urut_surat": nomorUrutSurat,
				"sipttk_id": sipttkId,
			});
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.DOWNLOAD_KETERANGAN_KEANGGOTAAN(userId), {
				method: "POST",
				headers: myHeaders,
				body: raw
			});
			if (!response.ok) {
				console.error('Network response was not ok:', response.statusText);
				return;
			}
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			const contentDisposition = response.headers.get('Content-Disposition');
			let filename = 'SURAT KETERANGAN KEANGGOTAAN_'+namaAnggota;
			if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
				const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
				if (matches != null && matches[1]) { 
					filename = matches[1].replace(/['"]/g, '');
				}
			}
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
			
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			}

			return {};
	
		} catch (err) {
			console.error('Error:', err);
		}
	}
	


	static async getAnggota({
		status_regis,
		status_ktan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ANGGOTA(status_regis,status_ktan),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
	
	static async getPengajuanSeminarById({
		idSeminar
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_PENGAJUAN_SEMINAR_BY_ID(idSeminar),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
	
	
	static async sendPengajuanSeminar({
		penyelenggara,
		ketuaPelaksana,
		nianKetuaPelaksana,
		jabatanKetuaPelaksana,
		sekretaris,
		nianSekretaris,
		jabatan,
		ketuaSeminar,
		tempatPenyelenggaraan,
		jumlahSKP,
		judulSeminar,
		kategoriSeminar,
		lingkupSeminar,
		tanggalMulai,
		waktuMulai,
		tanggalSelesai,
		waktuSelesai,
		batasPendaftarOnline,
		quota,
		quotaOffline,
		kontribusiPeserta,
		proposal,
		sertifikat,
		brosur,
		deskripsi,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("penyelenggara", penyelenggara);
		formdata.append("lingkup_seminar", lingkupSeminar);
		formdata.append("tanggal_mulai", tanggalMulai);
		formdata.append("waktu_mulai", waktuMulai);
		formdata.append("tanggal_selesai", tanggalSelesai);
		formdata.append("waktu_selesai", waktuSelesai);
		formdata.append("batas_pendaftaran_online", batasPendaftarOnline);
		formdata.append("ketua_seminar", ketuaSeminar);
		formdata.append("quota_peserta", quota);
		formdata.append("quota_peserta_luring", quotaOffline);
		formdata.append("kontribusi_peserta", kontribusiPeserta);
		formdata.append("tempat_penyelenggaraan", tempatPenyelenggaraan);
		formdata.append("jumlah_skp", jumlahSKP);
		formdata.append("judul_seminar", judulSeminar);
		formdata.append("kategori_seminar", kategoriSeminar);
		formdata.append("description", deskripsi);
		formdata.append("nama_penanda_pelaksana", ketuaPelaksana);
		formdata.append("jabatan_penanda_pelaksana", jabatanKetuaPelaksana);
		formdata.append("nian_penanda_pelaksana", nianKetuaPelaksana);
		formdata.append("nama_penanda_sekretaris", sekretaris);
		formdata.append("jabatan_penanda_sekretaris", jabatan);
		formdata.append("nian_penanda_sekretaris", nianSekretaris);
		
		formdata.append("proposal_seminar", proposal);
		formdata.append("background_sertifikat", sertifikat);
		formdata.append("brosur", brosur);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.SEND_PENGAJUAN_SEMINAR_PENGURUS_PC, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

    static async getPeriode (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PERIODE_KEPENGURUSAN,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getPeriodeByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PERIODE_KEPENGURUSAN_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async addMateriSeminar({
		idSeminar,
		judulMateriSeminar,
		pemateri,
		instansi,
		dariJam,
		sampaiJam,
		moderator,
		deskripsiMateri,
		lampiran,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("permohonan_seminar_id", idSeminar);
		formdata.append("judul_materi_seminar", judulMateriSeminar);
		formdata.append("pemateri", pemateri);
		formdata.append("instansi", instansi);
		formdata.append("dari_jam", dariJam);
		formdata.append("sampai_jam", sampaiJam);
		formdata.append("moderator", moderator);
		formdata.append("description", deskripsiMateri);
		
		formdata.append("lampiran", lampiran);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.ADD_MATERI_SEMINAR, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createPeriode ({
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PERIODE_KEPENGURUSAN,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    
    static async updatePeriode ({
        id,
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_PERIODE_KEPENGURUSAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateExpiredKTAN ({
        uuid,
		masaBerlakuKTAN
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "ktan_expires_at": masaBerlakuKTAN
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_KTAN_EXPIRES_DATE(uuid),{
				method : "PATCH",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async deletePeriode ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.DELETE_PERIODE_KEPENGURUSAN(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
    static async getPengurus (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PENGURUS,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async getPengurusByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PENGURUS_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async createPengurus ({
		periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.PENGURUS,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async updatePengurus ({
		id,
        periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_PENGURUS(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async deletePengurus ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.DELETE_PENGURUS(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    static async updateTTD ({
        id,
		dokumen
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", "ttd");
		formdata.append("document", dokumen);

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_TTD(id),{
				method : "POST",
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
    static async getSetting (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GENERAL_SETTING,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateSetting ({
		nama_kota,
        alamat_cabang,
		informasi_umum,
		telpon,
		email,
		faksimil,
		kode_pos,
		url_maps,
		kepada_sipttk,
		di_sipttk,
		send_email_sipttk,
		kepada_strttk,
		di_strttk,
		ttd_pc_strttk,
		informasi_rekening,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "nama_kota": nama_kota,
			"address": alamat_cabang,
			"information": informasi_umum,
			"telp": telpon,
			"email": email,
			"fak": faksimil,
			"kode_pos": kode_pos,
			"maps_url": url_maps,
			"kepada_sipttk": kepada_sipttk,
			"di_sipttk": di_sipttk,
			"send_email_sipttk": send_email_sipttk,
			"kepada_strttk": kepada_strttk,
			"di_strttk": di_strttk,
			"ttd_digital_active": ttd_pc_strttk,
			"informasi_rekening": informasi_rekening
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GENERAL_SETTING,{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
	static async updateIuran ({
		membership_fee,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var raw = JSON.stringify({
            "membership_fee": membership_fee,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.IURAN_SETTING,{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async approvalIuranAnggota ({
		id,
		statusApprove
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "approval": statusApprove,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVAL_IURAN_ANGGOTA(id),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async generateInvoice ({
		jatuh_tempo,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "jatuh_tempo": jatuh_tempo,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GENERATE_INVOICE,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }


	// static async getInformationSerkom (){
	// 	// console.log(data);
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.SERKOM_INFORMATION,{
	// 			method : "GET",
	// 			headers: myHeaders,
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	// static async updateSyaratSerkom ({
	// 	id,
	// 	name,
	// 	description
	// }){
	// 	// console.log(data);
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	var raw = JSON.stringify({
	// 		"name": name,
	// 		"description": description,
	// 	  });
	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_PERSYARATAN(id),{
	// 			method : "PUT",
	// 			headers: myHeaders,
    //             body : raw
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	// static async deleteSyaratSerkom ({
	// 	id
	// }){
	// 	// console.log(data);
	// 	console.log(id)
    //     let myHeaders =  new Headers();
	// 	let token = await Autentication.getToken()
	// 	myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
	// 	myHeaders.append('Content-Type', 'application/json');
	// 	myHeaders.append('Accept', 'application/json');
	// 	myHeaders.append('Access-Control-Allow-Origin', '{*}');
	// 	myHeaders.append('Access-Control-Allow-Credentials', 'true');
	// 	myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	// 	try {
	// 		const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.UPDATE_PERSYARATAN(id),{
	// 			method : "DELETE",
	// 			headers: myHeaders,
	// 		});
	// 		const responseJson = await response.json();
	// 		if (await NetworkHelper.isResponseAccepted(responseJson)) {
	// 			return responseJson;
	// 		} return {};
	// 	} catch (err) {
	// 		
	// 		return {};
	// 	}
    // }

	
}


export default ApiKepengurusanPC;