import ApiSeminar from "../api/data-seminar.js";


const DashboardInformationPresenter = {

    async init() {
		await this._setView();
        await this._getPengajuanSeminarById()
		await this._initGetSeminarByFiltered();
	},

    async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},

	async _initGetSeminarByFiltered(){
		let wilayah = document.getElementById('selectByWilayah').value;
		let jenis = document.getElementById('selectByKategori').value;
        await this._initGetSeminarInformation(wilayah, jenis);

		const eventChangeFiltered = async () => {
			let wilayah = document.getElementById('selectByWilayah').value;
			let jenis = document.getElementById('selectByKategori').value;
			await this._initGetSeminarInformation(wilayah, jenis);
		}

		document.getElementById('selectByWilayah').addEventListener('change', eventChangeFiltered);
		document.getElementById('selectByKategori').addEventListener('change', eventChangeFiltered);
	},

    async _initGetSeminarInformation(wilayah, jenis) {
        mRefresh.refresh();
		const data = await ApiSeminar.getListSeminar(wilayah, jenis)
        let dataList = null
        let getContentSeminar = (dataSeminar) => {
            let contentDescription = dataSeminar.description.replace(`""`,"")
            var textarea = document.createElement("textarea");
            textarea.innerHTML = contentDescription;
            var decodedHtml = textarea.value;
            let timeSeminar = dataSeminar.tanggal_mulai
            let timeBatasDaftar = dataSeminar.batas_pendaftaran_online
			let brosur = dataSeminar.dokumen.brosur
			let judulSeminar = dataSeminar.judul_seminar;

			let skp_peserta = 0;
			let skp_moderator = 0;
			let skp_narasumber = 0;
			let skp_panitia = 0;
			let kategori = ''
			let skpPesertaOnline = null
			if (dataSeminar.kategori_seminar == "pendidikan") {
				kategori = 'Pembelajaran'
				skp_peserta = dataSeminar.skp_peserta_pp
				skp_moderator = dataSeminar.skp_moderator_pp
				skp_narasumber = dataSeminar.skp_narasumber_pp
				skp_panitia = dataSeminar.skp_panitia_pp
			}else {
				kategori = 'Pengabdian Masyarakat'
				skp_peserta = dataSeminar.skp_peserta_pd
				skp_moderator = dataSeminar.skp_moderator_pd
				skp_narasumber = dataSeminar.skp_narasumber_pd
				skp_panitia = dataSeminar.skp_panitia_pd
			}
            
            return `
				<div class="col-sm-3 mb-5">
					<div>
						<img src="${brosur}" class="rounded mb-2" height="180px" width="100%"><br>
						<strong class="text-info mt-2">${dataSeminar.penyelenggara.toUpperCase()}</strong>
						<h5 class="mt-2 font-weight-bold">${judulSeminar}</h5>
						<table width="100%">
							<tr class="text-warning font-weight-bold">
								<td width="40%" valign="top">Kategori</td>
								<td valign="top">:</td>
								<td valign="top">${kategori}</td>
							</tr>
							<tr class="text-success">
								<td width="40%">Tanggal</td>
								<td>:</td>
								<td>${timeSeminar}</td>
							</tr>
							<tr>
								<td width="40%">Peserta</td>
								<td width="1%">:</td>
								<td>${skp_peserta} SKP</td>
							</tr>
							<tr>
								<td width="40%">Moderator</td>
								<td>:</td>
								<td>${skp_moderator} SKP</td>
							</tr>
							<tr>
								<td width="40%">Panitia</td>
								<td>:</td>
								<td>${skp_panitia} SKP</td>
							</tr>
							<tr>
								<td width="40%">Pembicara</td>
								<td>:</td>
								<td>${skp_narasumber} SKP</td>
							</tr>
							<tr class="text-danger">
								<td width="40%">Batas Pendaftaran</td>
								<td>:</td>
								<td>${timeBatasDaftar}</td>
							</tr>
							<tr class="text-center">
								<td width="40%" colspan="3">
									<br>
									<button class="btn btn-sm btn-outline-primary" id="detail" data-seminar-id="${dataSeminar.id}" data-toggle="modal" data-target="#modalViewDetail">Detail Seminar</button>
								</td>
							</tr>

						</table>
					</div>
					
				</div>
			`
        }

        

        if (data != null) {
            dataList = data.data;
			
           
            let getAllContent = async () => {
                let elmHtml = ``
                await dataList.forEach(async element => {
                    elmHtml = elmHtml + getContentSeminar(element)
                });    
                return elmHtml
            }

            if (dataList == null || dataList.length <= 0) {
                document.getElementById("container_seminar").innerHTML = `<span class="no-comment">Belum ada Informasi Terbaru</span>`
            } else {
                document.getElementById("container_seminar").innerHTML = 'Loading...';
                document.getElementById("container_seminar").innerHTML = await getAllContent();
            }
        }
        mRefresh.resolve();
	},

    async _getPengajuanSeminarById(){

		let idSeminar = 0;

		const formatAngka = (number)=> {
			
			const reverse = number.toString().split('').reverse().join('');
			let formattedValue = '';
			let count = 0;
		
			for (const char of reverse) {
			if (count === 3) {
				formattedValue += '.';
				count = 0;
			}
			formattedValue += char;
			count++;
			}
	
		return formattedValue.split('').reverse().join('');
		}
	
		
		$('.containerState ').on('click', '#detail', async function () {
			$("#judulSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#kategoriSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#ketuaSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#sekretarisSeminarDet").html('<div class="shimmer content-line"></div>');
			$("#aboutTime").html('<div class="shimmer content-line"></div>');
			$("#aboutPeserta").html('<div class="shimmer content-line"></div>');
			$("#tanggalPelaksanaan").html('<div class="shimmer content-line"></div>');
			$("#menimbang").html('<div class="shimmer content-line"></div>');
			$("#mengingat").html('<div class="shimmer content-line"></div>');
			$("#skpPeserta").html('<div class="shimmer content-line end"></div>');
			$("#skpPanitia").html('<div class="shimmer content-line end"></div>');
			$("#skpModerator").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
			$("#skpNarasumber").html('<div class="shimmer content-line end"></div>');
		
		
			idSeminar = $(this).attr('data-seminar-id');
			$("#stateIdSeminarDetail").val(idSeminar)
			const getPengajuanSeminarById = await ApiSeminar.getPengajuanSeminarById({
				idSeminar
			});

			if (getPengajuanSeminarById.status_code != 200) {
				NotificationModal.show("Gagal memuat data", "error");
			}else {
				
				let dataDet = getPengajuanSeminarById.data;
				let dataMateri = dataDet.materi_seminar;
				let jumlahSeminar = dataDet.materi_seminar.length;
				let skp_peserta = '';
				let skpPesertaOnline = null
				if (dataDet.kategori_seminar == "pendidikan") {
					skp_peserta = dataDet.skp_peserta_pp
					if (dataDet.kategori_peserta == "hybrid" ) {
						skpPesertaOnline = dataDet.skp_peserta_pp_online
					}
				}else{
					skp_peserta = dataDet.skp_peserta_pd
					if (dataDet.kategori_peserta == "hybrid" ) {
						skpPesertaOnline = dataDet.skp_peserta_pd_online
					}
					
				}

				if (skpPesertaOnline != null) {
					$("#jumlahSKPDetOnline").html(skpPesertaOnline);
					$("#jumlahSKPDetOflineTitle").html("Jumlah SKP yang akan didapatkan Jika hadir secara Offline ");
					document.getElementById("container-online").classList.remove("d-none")
				}else{
					$("#jumlahSKPDetOflineTitle").html("Jumlah SKP yang akan didapatkan");
					document.getElementById("container-online").classList.add("d-none")
				}
                
			
				$("#judulSeminarDet").html(dataDet.judul_seminar);
				$("#kategoriSeminarDet").html(dataDet.tempat_penyelenggaraan);
				$("#jumlahSKPDet").html(skp_peserta);
				$("#textDeskripsiDetail").html(dataDet.description)
				$("#ketuaSeminarDet").html(dataDet.nama_penanda_pelaksana);
				$("#sekretarisSeminarDet").html(dataDet.nama_penanda_sekretaris);
				$("#aboutTime").html( `${dataDet.tanggal_mulai}  ${dataDet.waktu_mulai} sampai dengan ${dataDet.tanggal_selesai} ${dataDet.waktu_selesai}  `);
				if (dataDet.kategori_peserta == null || dataDet.quota_peserta == null || dataDet.kontribusi_peserta == null) {
					$("#aboutPeserta").html( `-`);
				}else {
					$("#aboutPeserta").html( `Dilaksanakan ${dataDet.kategori_peserta.toUpperCase()} , Quota Peserta   ${dataDet.quota_peserta} dengan kontribusi peserta sebesar Rp. ${formatAngka(dataDet.kontribusi_peserta)} `);
				}

				let tableMateri = $("#tableMateri").DataTable({
						"destroy": true,
						columnDefs: [{
							"defaultContent": "-",
							"targets": "_all"
						  }]
				})

				console.log(dataDet)


				if (jumlahSeminar <= 0) {
					$("#contentDet").html('<tr><td colspan="10" class="text-center" style="font-style:italic;">Tidak ada data</td></tr>')
				}else {

					try {
						$('#tableMateri').dataTable().fnClearTable();
						$('#tableMateri').dataTable().fnDestroy();
					} catch (error) {
						console.log(error)
					}
					let i = 0
				
					dataMateri.forEach(async function (materi) {
						i++;
						var lampiran = ``;
						if (materi.lampiran == null || materi.lampiran == '') {
							lampiran = '-';
						}else {
							lampiran = `<img src="${materi.lampiran}" width="40px;">`
						}
						tableMateri.row
							.add([
								materi.id,
								materi.judul_materi_seminar,
								materi.moderator,
								materi.pemateri,
								materi.tanggal_seminar,
								materi.tempat_penyelenggaraan,
								materi.author,
								lampiran
							]).draw(false);
					});

					$("#tableMateri").DataTable({
						"destroy": true
					})
				}

			
			}


		});

	},
}


export default DashboardInformationPresenter;