
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiPengajuanKTANPD {
    static async aprroveKTAN (
        data,
    ){
		console.log(data.id,data.status,data.comment);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": data.status,
            "catatan": data.comment,
          });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_KTAN(data.id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async getCommentar(
		userID,
		commentFor,
		pengajuanID,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.GET_COMMENT_PENGAJUAN(userID,commentFor,pengajuanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}

	
	static async sendCommentar({
		userID,
		pengajuanID,
		commentFor,
		contentComment,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": `${userID}`,
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SEND_COMMENT,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async approveAllKTAN (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_All_KTAN,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async downloadKTAN({id,nama}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.DOWNLOAD_KTAN(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'KTAN_'+nama+'.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

}


export default ApiPengajuanKTANPD;
