import ApiPublic from '../api/data-public.js';
import UrlParser from '../routes/url-parser.js';

const DokumenValidPresenter = {

	async init() {
		await this._setView();
		await this._setUpViewValidation();
		await this._setUpViewKecukupanSkp();
	},

	async _setView() {
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},

	async _setUpViewValidation(){
		let url = UrlParser.parseActiveUrlWithCombiner();
		if(url == "/dokumen-valid"){
			document.getElementById('noteMessage').textContent = 'Dokumen Tervalidasi';
		}else{
			document.getElementById('noteMessage').textContent = 'Dokumen Invalid';
		}
	},


	async _setUpViewKecukupanSkp(){
		let type = UrlParser._getParameterByName("type")
		let key = UrlParser._getParameterByName("key")
		if (type == "kecukupan-skp") {
			let getData = await ApiPublic.sendKey(key);

			if (getData.status_code == 200) {
				let data = getData.data;
				let alamat = data.alamat.address +", Desa / Kelurahan "+ data.alamat.village_name +", "+ data.alamat.regency_name +", Provinisi "+ data.alamat.province_name
				$("#textNoSurat").html(data.nomor_surat);
				$("#textName").html(data.user.name);
				$("#textJK").html(data.user.jenis_kelamin);
				$("#textTempatLahir").html(data.user.tempat_lahir);
				$("#textTanggalLahir").html(data.user.tanggal_lahir);
				$("#textAlamat").html(alamat);
				$("#textInstitusi").html(data.pendidikan.jenjang+" - "+data.pendidikan.nama_institusi_pendidikan);
				$("#textNoIjazah").html(data.pendidikan.nomor_ijazah);
				$("#textTanggalIjazah").html(data.pendidikan.tanggal_ijazah);
			}
		}


		// let getCredential = await ApiPublic.getCredentialKecukupanSKP(credentialId)
		// let getDataCredential = await ApiPublic.getDataByCredential(credentialId)
		// console.log(getDataByCredential)
	}

}

export default DokumenValidPresenter;
