
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiPengajuanSerkomPD {

    static async createSyaratSerkom ({
		name,
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PERSYARATAN_SERKOM,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async createInfromationSerkom ({
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SERKOM_INFORMATION,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getInformationSerkom (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SERKOM_INFORMATION,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateSyaratSerkom ({
		id,
		name,
		description
	}){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async deleteSyaratSerkom ({
		id
	}){
		// console.log(data)
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
				method : "DELETE",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
	static async approvePengajuanSerkom({
		id,
		status,
		kompetensi,
		comment
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": status,
			"kompetensi": kompetensi,
            "catatan": comment,
          });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_SERKOM(id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	
	static async approveAllSerkom (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);
		console.log(dataList);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_All_SERKOM,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	
	static async downloadSelected (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.DOWNLOAD_SERKOM_BULK,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	
	static async getCommentar(
		userID,
		commentFor,
		pengajuanID,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.GET_COMMENT_PENGAJUAN(userID,commentFor,pengajuanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async sendCommentar(
		userID,
		pengajuanID,
		commentFor,
		contentComment,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": `${userID}`,
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SEND_COMMENT,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
}


export default ApiPengajuanSerkomPD;
