import GeneralSettingPPPresenter from "../../../presenter/menu_pengurus_pp/general-setting-presenter.js";

const GeneralSettingPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row mt-3">
           
       
            <div class="col-sm-12 mt-3">
            
                <div class="card">
                    <div class="card-header text-primary font-weight-bold">
                        Data Pengaturan Biaya
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <table class="table table-bordered table-hover" id="tableSettingBiaya" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Nama Pengaturan</th>
                                            <th>Value</th>
                                            <th>Deskripsi</th>
                                            <th>Terakhir Diperbarui,</th>
                                            <th>Diperbarui Oleh</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                            <td><div class="shimmer title-line"></div></td>
                                        </tr>
                                        <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>

                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                
                                        </tr>
                                        <tr>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>

                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        
                                    </tfoot>
                                    <tbody>
                                    
                                    
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="modal fade" id="modalRolesUser" tabindex="-1" role="dialog" aria-labelledby="modalRolesUser"
				aria-hidden="true">
				<div class="modal-dialog modal-lg" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Edit Data Pegaturan Biaya</h5>
							<button class="close" type="button" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div class="modal-body" id="detailBody">
                        <div class="row">
                        <div class="col-lg-12">
                        <form id="form-update-setting">
                            <input type="hidden"  class="form-control" required id="userId">
                            <div class="form-group row d-none">
                                <label class="control-label col-sm-3 font-weight-bold">KEY </label>
                                <div class="col-sm-9">
                                    <input type="text" readonly  class="form-control" required id="textKey">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-3 font-weight-bold">Nama Pengaturan </label>
                                <div class="col-sm-9">
                                    <input type="text" readonly placeholder="Nama Pengaturan Biaya..." class="form-control" required id="textJudul">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-3 font-weight-bold">Biaya </label>
                                <div class="col-sm-9">
                                   <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-lg">Rp.</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Rp" aria-describedby="inputGroup-sizing-sm" id="textBiaya">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-3 font-weight-bold">Deskripsi </label>
                                <div class="col-sm-9">
                                    <input type="text" placeholder="Deskripsi..." class="form-control" required id="textDescription">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="control-label col-sm-3 font-weight-bold"></label>  
                                <div class="col-sm-9 mb-4">
                                    <button type="submit" class="form-control btn btn-primary" id="btnSaveRole"><i class="fas fa-save"></i> Simpan </button>
                                </div>
                            </div>

                        </form>
                    </div>
                    </div>
                            
						</div>
						<div class="modal-footer">
							<button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</div>

                        
        `;
		return view;
	  },

	async afterRender() {
        await GeneralSettingPPPresenter.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default GeneralSettingPP;