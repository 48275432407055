import ApiLockAccess from "../../api/data-lock-access.js";
import ApiRegister from "../../api/data-register.js";


const SetAksesPresenterPP = {

    async init() {
		await this._setView();
        await this._setViewAksesPD();
        await this._setViewAksesPC()
	},

    async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
    },

    async _setViewAksesPD(){
        // id tableDataAksesPD
        mRefresh.refresh()
        const dataPD = await ApiRegister.getProvince()
        if (dataPD.status_code == 200) {
			dataPD.data.forEach(data => {
				document.getElementById("selectProvinsiTujuan").add(new Option(data.province_name, data.province_code))
			});
		}

        let tableAksesPD = $("#tableDataAksesPD").DataTable({
            columnDefs: [{
                "defaultContent": "-",
                "targets": "_all"
            }],
            "pageLength": 100, // Set the number of rows to display
        });

         try {
            $('#tableDataAksesPD').dataTable().fnClearTable();
            $('#tableDataAksesPD').dataTable().fnDestroy();
        } catch (error) {
            console.log(error)
        }
        let i = 0
    
       await dataPD.data.forEach(async function (data) {
            i++;
            let contentLock = ``
            if (data.is_locked == 0) {
                // unlocked
                contentLock = `<span class="badge badge-success"><i class="fa fa-unlock-alt"></i> Terbuka</span>`
            }else {
                // locked
                contentLock = `<span class="badge badge-danger"><i class="fa fa-lock"></i> Terkunci</span>`
            }
            let isShowUnlockedAction = ``
            if (data.is_locked == 1) {
                // locked condition
                isShowUnlockedAction = ``
            }else {
                // unlocked condition
                isShowUnlockedAction = `d-none`
            }

            let isShowLockedAction = `d-none`
            if (data.is_locked == 1) {
                // locked condition
                isShowLockedAction = `d-none`
            }else {
                // unlocked condition
                isShowLockedAction = ``
            }
        
            tableAksesPD.row
                .add([
                    i,
                    data.province_code,
                    data.province_name,
                    contentLock,
                    `<button class='mb-1 btn btn-danger btn-sm ${isShowLockedAction}' id='lockedAction' title='Kunci Akses'><i class="fa fa-lock"></i> Kunci Akses</button></td> 
                    <button class='mb-1 btn btn-success btn-sm ${isShowUnlockedAction}' id='unlockedAction' title='Buka Akses'><i class="fa fa-unlock-alt"></i> Buka Akses</button></td>`,
                ]).draw(false);
        });

        $("#tableDataAksesPD").DataTable({
            "destroy": true
        })
        const refreshThis = async () =>{
           await this._setViewAksesPD()
           console.log("refresh")
        }

        $('#tableDataAksesPD tbody').off('click','#lockedAction').on('click', '#lockedAction', async function () {
			
            const table = $('#tableDataAksesPD').DataTable();
            let data = table.row($(this).parents('tr')).data();
        
            swal.fire({
                title: 'Kunci Akses PD ',
                html: "Anda ingin Mengunci PD INI ?\ " + data[2],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: ' #d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, Kunci!'
            }).then(async (result) => {
                if (result.value) {
                    let id = data[1];
                    const lockPDResponse = await ApiLockAccess.LockAccessPD({
                        province_id: id,
                        is_locked : 1
                    });

                    if (lockPDResponse.status_code == '200') {
                        swal.fire({
                            title: 'Kunci Akses',
                            text: "Data PD berhasil Dikunci",
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });

                        await refreshThis()
                        
                    }else {
                        swal.fire({
                            title: 'Error',
                            text: lockPDResponse.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }
                };
            });

        });

        $('#tableDataAksesPD tbody').off('click','#unlockedAction').on('click', '#unlockedAction', async function () {
			
            const table = $('#tableDataAksesPD').DataTable();
            let data = table.row($(this).parents('tr')).data();
        
            swal.fire({
                title: 'Buka Akses PD ',
                html: "Anda ingin Membuka Akses PD INI ?\ " + data[2],
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6 ',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, Buka Kunci!'
            }).then(async (result) => {
                if (result.value) {
                    let id = data[1];
                    const lockPDResponse = await ApiLockAccess.LockAccessPD({
                        province_id: id,
                        is_locked : 0
                    });

                    if (lockPDResponse.status_code == '200') {
                        swal.fire({
                            title: 'Kunci Akses',
                            text: "Data PD berhasil Dibuka",
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });

                        await refreshThis()
                        
                    }else {
                        swal.fire({
                            title: 'Error',
                            text: lockPDResponse.message,
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }
                };
            });

        });
        mRefresh.resolve()
    },

    async _setViewAksesPC(){
          // id tableDataAksesPC
          let tableAksesPCEmptyOuter = $("#tableDataAksesPC").DataTable({
            columnDefs: [{
                "defaultContent": "-",
                "targets": "_all"
            }],
            "pageLength": 100, // Set the number of rows to display
        });
        tableAksesPCEmptyOuter.destroy();
        try {
            $('#tableDataAksesPC').dataTable().fnClearTable();
            $('#tableDataAksesPC').dataTable().fnDestroy();
        } catch (error) {
            console.log(error)
        }
        $("#tableDataAksesPC").DataTable({
            "destroy": true
        })    

          const eventChange = async (e) => {
            mRefresh.refresh()
			let valueSelected = document.getElementById("selectProvinsiTujuan").value;
            if (valueSelected == "") {
                let tableAksesPCEmpty = $("#tableDataAksesPC").DataTable({
                    columnDefs: [{
                        "defaultContent": "-",
                        "targets": "_all"
                    }],
                    "pageLength": 100, // Set the number of rows to display
                });
                tableAksesPCEmpty.destroy();
                try {
                    $('#tableDataAksesPC').dataTable().fnClearTable();
                    $('#tableDataAksesPC').dataTable().fnDestroy();
                } catch (error) {
                    console.log(error)
                }
                $("#tableDataAksesPC").DataTable({
                    "destroy": true
                })    
                mRefresh.resolve()
                e.preventDefault()
                return
            }
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

            let tableAksesPC = $("#tableDataAksesPC").DataTable({
                columnDefs: [{
                    "defaultContent": "-",
                    "targets": "_all"
                }],
                "pageLength": 100, // Set the number of rows to display
            });
    
             try {
                $('#tableDataAksesPC').dataTable().fnClearTable();
                $('#tableDataAksesPC').dataTable().fnDestroy();
            } catch (error) {
                console.log(error)
            }
            let i = 0
        
           await dataRegistry.data.forEach(async function (data) {
                i++;
                let contentLock = ``
                if (data.is_locked == false) {
                    // unlocked
                    contentLock = `<span class="badge badge-success"><i class="fa fa-unlock-alt"></i> Terbuka</span>`
                }else {
                    // locked
                    contentLock = `<span class="badge badge-danger"><i class="fa fa-lock"></i> Terkunci</span>`
                }
                let isShowUnlockedAction = ``
                if (data.is_locked == true) {
                    // locked condition
                    isShowUnlockedAction = ``
                }else {
                    // unlocked condition
                    isShowUnlockedAction = `d-none`
                }
    
                let isShowLockedAction = `d-none`
                if (data.is_locked == true) {
                    // locked condition
                    isShowLockedAction = `d-none`
                }else {
                    // unlocked condition
                    isShowLockedAction = ``
                }
            
                tableAksesPC.row
                    .add([
                        i,
                        data.id,
                        data.regency_name,
                        contentLock,
                        `<button class='mb-1 btn btn-danger btn-sm ${isShowLockedAction}' id='lockedAction' title='Kunci Akses'><i class="fa fa-lock"></i> Kunci Akses</button></td> 
                        <button class='mb-1 btn btn-success btn-sm ${isShowUnlockedAction}' id='unlockedAction' title='Buka Akses'><i class="fa fa-unlock-alt"></i> Buka Akses</button></td>`,
                    ]).draw(false);
            });
    
            $("#tableDataAksesPC").DataTable({
                "destroy": true
            })

            const refreshThis = async () =>{
              eventChange(e)
            }
    
            $('#tableDataAksesPC tbody').off('click','#lockedAction').on('click', '#lockedAction', async function () {
                
                const table = $('#tableDataAksesPC').DataTable();
                let data = table.row($(this).parents('tr')).data();
            
                swal.fire({
                    title: 'Kunci Akses PC ',
                    html: "Anda ingin Mengunci PC INI ?\ " + data[2],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: ' #d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Ya, Kunci!'
                }).then(async (result) => {
                    if (result.value) {
                        let id = data[1];
                        const lockPDResponse = await ApiLockAccess.LockAccessPC({
                            regency_id: id,
                            is_locked : 1
                        });
                        console.log("S")
                        if (lockPDResponse.status_code == '200') {
                            swal.fire({
                                title: 'Kunci Akses',
                                text: "Data PC Berhasil Terkunci",
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000
                            });
    
                            await refreshThis()
                            
                        }else {
                            swal.fire({
                                title: 'Error',
                                text: lockPDResponse.message,
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1000
                            });
                        }
                    };
                });
    
            });
    
            $('#tableDataAksesPC tbody').off('click','#unlockedAction').on('click', '#unlockedAction', async function () {
                
                const table = $('#tableDataAksesPC').DataTable();
                let data = table.row($(this).parents('tr')).data();
            
                swal.fire({
                    title: 'Buka Akses PC ',
                    html: "Anda ingin Membuka Akses PC INI ?\ " + data[2],
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6 ',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Buka Kunci!'
                }).then(async (result) => {
                    if (result.value) {
                        let id = data[1];
                        const lockPDResponse = await ApiLockAccess.LockAccessPC({
                            regency_id: id,
                            is_locked : 0
                        });
    
                        if (lockPDResponse.status_code == '200') {
                            swal.fire({
                                title: 'Kunci Akses',
                                text: "Data PC Berhasil Terbuka",
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1000
                            });
    
                            await refreshThis()
                            
                        }else {
                            swal.fire({
                                title: 'Error',
                                text: lockPDResponse.message,
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1000
                            });
                        }
                    };
                });
    
            });

            mRefresh.resolve();
			e.preventDefault();
		}

		document.getElementById("selectProvinsiTujuan").addEventListener("change", eventChange);
    }
}

export default SetAksesPresenterPP;