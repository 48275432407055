
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSerkomPC from '../../api/api_pengurus_pc/pengajuan-serkom.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiKecukupanSKP from "../../api/data-kecukupan-skp.js";
import KECUKUPAN_SKP_ENDPOINT from "../../config/globals/kecukupan_skp_endpoint.js";
import ApiRegister from "../../api/data-register.js";
import ApiPengajuanSerkomPD from "../../api/api_pengurus_pd/pengajuan-serkom.js";
import Autentication from "../../utils/autentication.js";


const KecukupanSkpPDPresenter = {

	async init() {
		await StorageAnggotaPC.makeStore()
		await this._setView();
		const theEvent = async (e) => {
			mRefresh.refresh();
			await this._getPersyaratan();

			await this.createInformationSerkom();
			mRefresh.resolve();
		}
		$("#tab2").one("click", async function () {
			await theEvent();
		});
		
		await this.createPersyaratan();
		await this._getPengajuanSerkomFiltered();
		await this._approvePengajuanSerkom();
		await this._initAllApprove();		
		await this._downloadKecukupanSKP();
		await this._checkAllEvent()
		let auth = await Autentication.getData()
		if (auth[0].id != "2") {
		   alert("feature skp pd sedang dinonaktifkan")
		   window.location.hash = '#/dashboard';
		}
	},

	async _downloadKecukupanSKP() {

		$('#tablePengajuanSERKOM tbody').on('click', '#download', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
			let table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id;

			swal.fire({
				title: 'Download',
				html: "Anda ingin Download data ini? <br> <strong>Nama Anggota </strong>: " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, download!'
			}).then(async (result) => {	
				if (result.value) {
					$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					const approveSerkom = await ApiKecukupanSKP.downloadKecukupanSKPByID({
						id: idPengajuan,
						name:data.user.name
					});
					$(this).html('<i class="fas fa-download"></i>');
					if (approveSerkom != 200) {
						NotificationModal.show(`Data Gagal diunduh`, 'error');
					}
					$('#tablePersyaratanSerkom').DataTable().ajax.reload();
				}else{
					$(this).html('<i class="fas fa-download"></i>');
				};
			});
		})
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSyaratSerkom").hide();
		let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		if (responseLocked.status_code == 200) {
			if (responseLocked.data.is_pc_locked) {
				window.location.hash = '#/404?reason=iuranpc'
			}
		}
		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		const member_pd = dataAddressPrivate.user_pd_aktif_id;

		const dataRegistry = await ApiRegister.getRegistry(member_pd)


		var selectPC = document.getElementById("selectPengajuanPC");
		var lengthOptionPc = selectPC.options.length;
		for (let i = lengthOptionPc - 1; i >= 0; i--) {
			selectPC.options[i] = null;
		}

		document.getElementById("selectPengajuanPC").add((new Option("Pilih Cabang", "")))
		dataRegistry.data.forEach(data => {
			document.getElementById("selectPengajuanPC").add(new Option(data.regency_name, data.id))
		});


	},

	async _getPersyaratan() {
		let table = await $('#tablePersyaratanSerkom').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.PERSYARATAN_KECUKUPAN_SKP}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'description' },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 2,
					"data": null,
					"defaultContent": `
					<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-sm'  id='edit' title='Edit data '><i class='far fa-edit'></i></button>
                     <button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
			]
		});
	},

	async _getPengajuanSerkomFiltered(){
		
		let status = document.getElementById('selectStatusPC').value;
		let startDate = document.getElementById('startDate').value;
		let endDate = document.getElementById('endDate').value;
		let cabang = document.getElementById('selectPengajuanPC').value;
		await this._getPengajuan(status, startDate, endDate ,cabang,'',0);
		
		const eventFiltered = async () => {
			let status = document.getElementById('selectStatusPC').value;
			let startDate = document.getElementById('startDate').value;
			let endDate = document.getElementById('endDate').value;
			let cabang = document.getElementById('selectPengajuanPC').value;
			await this._getPengajuan(status, startDate, endDate ,cabang,'',0);
		}

		const eventResetFilter = async () => {
			document.getElementById("selectStatusPC").value = "pending"
			document.getElementById('startDate').value = null
			document.getElementById('endDate').value = null
			document.getElementById('selectPengajuanPC').value = ""
			await eventFiltered()
		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefresh').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilter').addEventListener('click', eventResetFilter);

	},
	async _getPengajuan(status, startDate, endDate , idCabang , statusDarah, quotabBlangko) {
		$("#jmlBlangko").html(quotabBlangko);
		
		if (status == 'approve') {
			var bg = 'bg-success'
			var disabled = 'd-none'
			$('#btnVerifikasiAllDt').hide()
			$('#btnDownloadsSelected').removeClass('d-none')
		} else if (status == 'revise') {
			var bg = 'bg-warning'
			var disable_download = 'd-none'
			$('#btnVerifikasiAllDt').hide()
			$('#btnDownloadsSelected').addClass('d-none')
		} else if (status == "all") {
			var disabled = 'd-none'
			$('#btnVerifikasiAllDt').hide()
		}
		let disableByquota = ''
		if (quotabBlangko <= 0) {
			disableByquota = 'd-none';
		}else {
			disableByquota = ''
		}
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tablePengajuanSERKOM').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				},
				{
					text: "<i class='fas fa-check'></i> Approve All",
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-primary " + disableByquota,
					titleAttr: "Approve All",
					attr: {
						id: "btnVerifikasiAllDt"
					},
					action: function () {
						$("#btnVerifikasiAllDt").html("Loading...");
						$("#btnVerifikasiAll").trigger("click");
					}
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${KECUKUPAN_SKP_ENDPOINT.GET_PENGAJUAN_LIST_PD(status, startDate, endDate , idCabang)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"dataSrc": function (data) {
					$("#jmlBlangko").html(data.additional.quota_blangko)
					quotabBlangko = data.additional.quota_blangko;
					if (quotabBlangko <= 0 ) {
						document.getElementById("btnVerifikasiAllDt").classList.add("d-none")
					}else {
						if (status == 'pending' || status == 'revise') {
							document.getElementById("btnVerifikasiAllDt").classList.remove("d-none")
						}else{
							document.getElementById("btnVerifikasiAllDt").classList.add("d-none")
						}
						
					}
					return data.data;
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
					
				},
			},
			"columns": [
				{ data: '' },
				{ data: 'created_at' },
				{ data: 'user.name' },
				{ data: 'cabang_name' },
				{ data: 'berlaku_dari',render: function (data , as , row) { 
					if (data == null) {
						return "- | -"
					}else{
						return `${data} - ${row.berlaku_sampai} `
					}
				}},
				{ data: 'nomor_surat' },
				{ data: 'download_count',render: function (data , as , row) { 
					if (data == null) {
						return "-"
					}else{
						return `Jumlah unduhan : ${data} <br> Terakhir di unduh : ${row.last_download_at}`
					}
				}},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pd', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},

			],
			"fnDrawCallback": function (data) {
				let rowTable = data.aoData;
				
				const checked = document.getElementById('checkAll').checked;
				if (checked) {
					document.getElementById('checkAll').checked = false;
				}

				$('#tablePengajuanSERKOM tbody tr').each(function (e) {
					if (rowTable[e] !== undefined) {
						let result = rowTable[e]._aData.id;
						let isChecked = StorageAnggotaPC.isReady(result);
						if (isChecked) {
							$(this).addClass('selected');
						}
					}
				})
			},
			select: {
				style: 'multi',
				selector: 'td:first-child'
			},
			order: [
				[1, 'asc']
			],
			"columnDefs": [
				{
					"targets": 0,
					"orderable": false,
					className: 'select-checkbox',
					"data": null,
					"defaultContent": ``
				},

				{
					"targets": [3,4,5,6],
					"orderable": false,
				},
				
				{
					"targets": 9,
					"data": null,
					"render": function (data, type, row) {
						var disabledDownload = `d-none`
						if (row.status_pd == "approve") {
							disabledDownload = ``
						}
						
						if (row.status_pd == "approve") {
							var disabled = "d-none"
							
						}
					return `<div style="display:flex">
						<button style="margin:1px" class='btn btn-primary btn-sm ${disabledDownload} text-center' id='download' title='Download'><i class="fas fa-download"></i></button>
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSerkomModal" id='detail' title='Detail'><i class="fas fa-list"></i></button>
						<button style="margin:1px" class='btn btn-primary btn-sm ${disabled}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
					</div>`
					},
					
				},
			]
		});

		

		$('#tablePengajuanSERKOM tbody').off('click', '.select-checkbox').on('click', '.select-checkbox', async function (e) {
			e.stopPropagation();
			
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let quota = $("#jmlBlangko").html();
			let idPengajuan = data.id;
			let jmlDataInput = await StorageAnggotaPC.getData();
			let lengthData = parseInt(jmlDataInput.length);
			
		
			if ($(this).parents('tr').hasClass("selected")) {
				$(this).parents('tr').removeClass('selected');
				await StorageAnggotaPC.deleteData(idPengajuan);
			} else {
				$(this).parents('tr').addClass('selected');
				await StorageAnggotaPC.pushData(idPengajuan);
			}
			
			if (lengthData >= quota) {
				NotificationModal.show('Maaf blangko anda tersisa ' + quota, 'warning');
				await KecukupanSkpPDPresenter._getPengajuanSerkomFiltered();
				await StorageAnggotaPC.makeStore()
			}
		
		});
		

		$('#tablePengajuanSERKOM tbody').off('click', '#detail').on('click', '#detail', async function () {
			let UserID = '';
			let PengajuanID = '';

			$("#persyaratanSerkom").html('');
			$("#dataPendidikanSerkom").html('');
			$("#dataSTRTTK").html('');
			let table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			const getDetailPengajuanSerkom = await ApiKecukupanSKP.getPengajuanByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;



			if (getDetailPengajuanSerkom.status_code == 200) {
				UserID = dataDetail.user_id;
				PengajuanID = dataDetail.id;
				$("#tanggalPengajuan").val(dataDetail.created_at);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textKTAN").val(dataDetail.user.ktan)

				var dokumen = dataDetail.dokumen
				if (dokumen == '' || dokumen == null) {
					$("#persyaratanSerkom").append('<td colspan="2" align="center" class="text-warning">Anggota belum mengisi persyaratan !</td>');
				} else {
					var persyaratanSerkom = ``
					dokumen.forEach(dataBerkas => {
						persyaratanSerkom += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`
					});

					$("#persyaratanSerkom").append(persyaratanSerkom);
				}
			


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanSerkomPC.getCommentar(UserID, "pengajuan_kecukupan_skp", id)
				
				let dataComment = response.data;
				dataComment.reverse();

				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}



				$("#btnSendComment").on('click', async function () {



					if (UserID != '' && id != '') {
						document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentar").value
						const response = await ApiPengajuanSerkomPD.sendCommentar(UserID, id, "pengajuan_kecukupan_skp", content);
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-left text-dark text-left" style="width:88%;">
												<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-right text-dark text-left" style="width:88%;">
												<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}


							let response = await ApiPengajuanSerkomPC.getCommentar(UserID, "pengajuan_kecukupan_skp", id)
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-comment").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-comment');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						} else {
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						}


					}


					// $(this).off('click');

				});



				$('#detailSerkomModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendComment').off('click');
				});
			}

		});


		$('#tablePengajuanSERKOM thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSERKOM thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});

	},

	async _checkAllEvent(quota) {
		const eventSelectAll = async () => {
			let parentElement = document.getElementById('tablePengajuanSERKOM')
			let tagElement = parentElement.getElementsByTagName('tbody')[0]
			const element = tagElement.querySelectorAll(".select-checkbox");
			const checked = document.getElementById('checkAll').checked;
			for (let index = 0; index < element.length; index++) {
				const table = $('#tablePengajuanSERKOM').DataTable();
				let data = table.row(index).data()
				if (checked) {
					StorageAnggotaPC.pushData(data.id)
					await element[index].parentNode.classList.add('selected');
				} else {
					element[index].parentNode.classList.remove('selected');
					await StorageAnggotaPC.deleteData(data.id)
				}
			}
		}

		document.getElementById('checkAll').addEventListener('change', eventSelectAll)

	},

	async createInformationSerkom() {

		tinymce.remove('#textInformasiSerkom')
		tinymce.init({
			selector: 'textarea#textInformasiSerkom',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});

		const getInformation = await ApiKecukupanSKP.getInformation();
		var textInformationSerkom = tinymce.get('textInformasiSerkom').setContent(getInformation.data.description);

		$("#btnUpdateInformasiSerkom").click(async function () {
			$(this).html("Loading...")
			var textInformationSerkom = tinymce.get('textInformasiSerkom').getContent();
			const createInformationSerkom = await ApiKecukupanSKP.createInfromation({
				description: textInformationSerkom,
			});

			if (createInformationSerkom.status_code == 200) {
				NotificationModal.show(`${createInformationSerkom.message}`, 'success');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			} else {
				NotificationModal.show(`${createInformationSerkom.message}`, 'error');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			}


		});
	},
	async createPersyaratan() {
		const eventCreateSyaratSerkom = async (e) => {
			e.preventDefault();
			if ($("#btnAddSyaratSerkom").is(':visible')) {
				document.getElementById("btnAddSyaratSerkom").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSyaratSerkom = await ApiKecukupanSKP.createSyaratKecukupanSKP({
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,
				});
				if (createSyaratSerkom.status_code != 201) {
					NotificationModal.show(`${createSyaratSerkom.message}`, 'error');
					document.getElementById("btnAddSyaratSerkom").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSerkom").hide();
				} else {
					NotificationModal.show(`${createSyaratSerkom.message}`, 'success');
					document.getElementById("btnAddSyaratSerkom").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSerkom").hide();
					$("#btnClearSyaratSerkom").trigger("click");
					$('#tablePersyaratanSerkom').DataTable().ajax.reload();
				}
			} else {
				document.getElementById("btnUpdateSyaratSerkom").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSyaratSerkom = await ApiKecukupanSKP.updateSyaratKecukupanSKP({
					id: document.getElementById('textHIddenPersyaratan').value,
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,

				});

				if (updateSyaratSerkom.status_code != 200) {
					NotificationModal.show(`${updateSyaratSerkom.message}`, 'error');
					$("#btnAddSyaratSerkom").hide();
					document.getElementById("btnUpdateSyaratSerkom").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
				} else {
					NotificationModal.show(`${updateSyaratSerkom.message}`, 'success');
					document.getElementById("btnUpdateSyaratSerkom").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
					$("#btnAddSyaratSerkom").hide();
					$("#btnClearSyaratSerkom").trigger("click");
					$('#tablePersyaratanSerkom').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-persyaratan-serkom').addEventListener('submit', eventCreateSyaratSerkom);

		$('#tablePersyaratanSerkom tbody').on('click', '#edit', function () {
			const table = $('#tablePersyaratanSerkom').DataTable();
			let data = table.row($(this).parents('tr')).data();

			$('#textHIddenPersyaratan').val(data.id);
			$('#textNamaBerkas').val(data.name);
			$('#textDeskripsi').val(data.description);
			$("#btnAddSyaratSerkom").hide();
			$("#btnUpdateSyaratSerkom").show();

		});


		const eventClear = async (e) => {
			document.getElementById("form-persyaratan-serkom").reset();
			$("#btnAddSyaratSerkom").show();
			$("#btnUpdateSyaratSerkom").hide();
		}

		document.getElementById("btnClearSyaratSerkom").addEventListener("click", eventClear)

		$('#tablePersyaratanSerkom tbody').on('click', '#delete', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			const table = $('#tablePersyaratanSerkom').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Persyaratan </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deleteSyaratSerkom = await ApiPengajuanSerkomPC.deleteSyaratSerkom({
						id: id
					});
					if (deleteSyaratSerkom.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSyaratSerkom.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePersyaratanSerkom').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSyaratSerkom.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				} else if (result.dismiss == 'cancel') {
					$(this).html('<i class="fas fa-trash"></i></button>');
				}
			}

			);
		});
	},
	async _approvePengajuanSerkom() {

		let idPengajuan = 0;
		$('#tablePengajuanSERKOM tbody').on('click', '#approve', async function () {
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;

			swal.fire({
				title: 'Approve',
				html: "Anda ingin Approve data ini? <br> <strong>Nama Anggota </strong>: " + data.user.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Approve!'
			}).then(async (result) => {	
				if (result.value) {
					$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="18px">');
					const approveSerkom = await ApiKecukupanSKP.approvePengajuanPD({
						id: idPengajuan,
						status: "approve",
						comment: "",
					});
		
					if (approveSerkom.status_code != 200) {
						NotificationModal.show(`${approveSerkom.message}`, 'error');
						$(this).html("<i class='fas fa-check'></i>");
						$('#tablePengajuanSERKOM').DataTable().ajax.reload();
					} else {
						NotificationModal.show(`${approveSerkom.message}`, 'success');
						$(this).html("<i class='fas fa-check'></i>");
						$('#tablePengajuanSERKOM').DataTable().ajax.reload();
					}
				};
				await StorageAnggotaPC.makeStore()
			});
		});

		$('#tablePengajuanSERKOM tbody').on('click', '#revisi', async function () {
			const table = $('#tablePengajuanSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;
		});

		$("#btnSendKomentarPengajuan").click(async function (e) {
			$(this).html("Loading...");
			e.stopPropagation()
			let comment = $("#textKomentarPengajuan").val();
			if (comment === "" || null) {
				NotificationModal.show(`Komentar tidak boleh kosong !`, 'error');
				$(this).html("Send");
			} else {
				const revisePengajuanSerkom = await ApiKecukupanSKP.approvePengajuanPD({
					id: idPengajuan,
					status: "revise",
					comment: comment
				})
				if (revisePengajuanSerkom.status_code != 200) {
					NotificationModal.show(`${revisePengajuanSerkom.message}`, 'error');
					$(this).html("Send");
					$("#textKomentarPengajuan").val("")
					$('#commentModal').modal('hide');
				} else {
					NotificationModal.show(`${revisePengajuanSerkom.message}`, 'success');
					$(this).html("Send");
					$("#textKomentarPengajuan").val("")
					$('#commentModal').modal('hide');
					$('#tablePengajuanSERKOM').DataTable().ajax.reload();
				}
			}

		});


		$("#btnCancel").click(function () {
			$("#textKomentarPengajuan").val("")
		});
	},

	async _initAllApprove() {
		const eventAllApprove = async () => {
			if (await StorageAnggotaPC.size() <= 0) {
				NotificationModal.show("Silahkan Pilih Data Terlebih Dahulu", "warning")
				$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
			} else {
				let data = await StorageAnggotaPC.getData()
				swal.fire({
					title: 'Setujui Semua',
					html: "Yakin menyetujui semua ? <br> <strong>Jumlah Data </strong>: " + data.length,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ya, Setujui'
				}).then(async (result) => {
					if (result.value) {
						const response = await ApiKecukupanSKP.approveAllPD(data)
						if (response.status_code == 200) {
							NotificationModal.show("Data Berhasil di Approve", "success");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSERKOM').DataTable().ajax.reload();
						} else {
							NotificationModal.show(`${response.message}`, "error");
							$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
							$('#tablePengajuanSERKOM').DataTable().ajax.reload();
						}
						await StorageAnggotaPC.makeStore()
					} else if (result.dismiss == 'cancel') {
						$("#btnVerifikasiAllDt").html("<i class='fas fa-check'></i> Approve All")
					};
				});

			}
		}

		document.getElementById('btnVerifikasiAll').addEventListener('click', eventAllApprove)
	},


}

const StorageAnggotaPC = {

	async makeStore() {
		this._data = [];
	},

	isReady(kode) {
		const result = this._data.map((e) => { return e.id_pengajuan; }).indexOf(kode);
		let value = false
		if (result != -1) {
			value = true
		}
		return value;
	},

	async pushData(idPengajuan) {
		if (this.isReady(idPengajuan)) return
		let gold = {
			"id_pengajuan": idPengajuan,
		}
		this._data.push(gold);
	},

	async deleteData(kode) {
		let removeIndex = this._data.map((item) => { return item.id_pengajuan; }).indexOf(`${kode}`);
		this._data.splice(removeIndex, 1)
	},

	async size() {
		return this._data.length
	},

	async getData() {
		return this._data;
	}
}
export default KecukupanSkpPDPresenter;
