
const AccessPD =  [
	"/",
 	"/dashboard",
	"/anggota-pd",
	"/skp-anggota-pd",
	"/serkom-anggota-pd",
	"/strttk-anggota-pd",
	"/kelola-anggota",
	"/periode-kepengurusan-pd",
	"/pengurus-pd",
	"/rekom-mutasi-pd",
	"/pengajuan-seminar-pd",
	"/data-seminar-pd",
	"/data-permohonan-seminar-pd"
];

const AccessPC =  [
	"/",
 	"/dashboard",
	"/anggota-pc",
	"/skp-anggota-pc",
	"/serkom-anggota-pc",
	"/kelola-anggota",

	"/strttk-anggota-pc",
	"/sipttk-anggota-pc",

	"/periode-kepengurusan-pc",
	"/pengurus-pc",
	
	"/rekom-mutasi-pc",
	"/syarat-mutasi-pc",
	"/mutasi-manual-pc",
	"/data-seminar-pc",
];


const AccessPP = [
	"/",
	"/dashboard",
	"/anggota-pp",
	"/assign-role",
	"/kelola-anggota-pp",
	"/pengajuan-seminar-pp",
	"/data-permohonan-seminar-pp",
	"/data-seminar-pp",
	"/periode-kepengurusan-pp",
	"/pengurus-pp",
];

const AccessAnggota = [
	"/",
 	"/dashboard", 
	"/rekom-strttk",
	"/rekom-sipttk",
	"/skp-saya",
	"/serkom-skp",
	"/kelola-anggota",

	
];


const AccessHelper= {

	include(arr,obj) {
		return (arr.indexOf(obj) != -1);
	},

    async isRoleIncluded(role , page) {
        if (role == "pp") {
			if (this.include(AccessPP, page)) {
				return true
			} else return false
		}else if (role == "anggota") {
			if (this.include(AccessAnggota, page)) {
				return true
			} else return false
		}
		else if (role == "pd") {
			if (this.include(AccessPD, page)) {
				return true
			} else return false
		}
		else if (role == "pc") {
			if (this.include(AccessPC, page)) {
				return true
			} else return false
		}else{
			return  false
		}
	},
    
}


export default AccessHelper;