class NotificationModal{
	static show(msg, status) {
        let newValue = ""
        if (status =='error') {
            newValue = 'warning'
        }else{
            newValue = status
        }
        swal.fire({
            title: `${newValue}`,
            text: `${msg}`,
            icon: `${newValue}`,
            confirmButtonColor: '#4fa7f3'
        });

        // let oTable = $('table').dataTable();
        // oTable.fnDraw(false);
        // $(this).parents('tr').fadeOut(300);
	}

}

export default NotificationModal;