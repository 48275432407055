/* eslint-disable new-cap */
import API_ENDPOINT from '../config/globals/endpoint.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

class ApiResetPassword {

	static async Reset({
		email,
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'POST', 'OPTIONS');
	
		var rawValue = JSON.stringify({
			"email": `${email}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.RESET_PASSWORD, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async ResetPassword({
		email,
		token,
		password,
		password_confirmation
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'POST', 'OPTIONS');

		
		var rawValue = JSON.stringify({
			"email": `${email}`,
			"token": `${token}`,
			"password": `${password}`,
			"password_confirmation": `${password_confirmation}`,
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.RESET_PASSWORD_BY_EMAIL, {
				method: 'PUT',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
		
	}

	static async ResendVerification({
		email,
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'POST', 'OPTIONS');
	
		var rawValue = JSON.stringify({
			"email": `${email}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.RESEND_VERIFICATION, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async ResendPayment({
		email,
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'POST', 'OPTIONS');
	
		var rawValue = JSON.stringify({
			"email": `${email}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.RESEND_PAYMENT, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
}

export default ApiResetPassword;
