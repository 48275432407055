class AppBar extends HTMLElement {
    connectedCallback() {
        this._render();
    }

    set setName(data) {
        document.getElementById('nama-user').innerHTML = data.name;
        this._nameUser = data.name;
    }

    _render() {
        this.innerHTML = `
        <!-- Topbar Navbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow" >
        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
        </button>
        <ul class="navbar-nav ml-auto">

            <li class="nav-item dropdown no-arrow mx-1 containerNotificationAnggota" id="containerNotificationAnggota">
                <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-bell fa-fw"></i>
                    <span id="count-notification-anggota" class="badge badge-danger badge-counter">0</span>
                </a>

                <div id="item-notification" class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto"
                    aria-labelledby="alertsDropdown">
                    <h6 class="dropdown-header">
                        Pemberitahuan Anggota
                    </h6>
                    <div id="drop-notification">
                        <p class="text-center" style="margin-top:40px;">Belum Ada notifikasi Terbaru </p>
                    </div>
                   
                    <a class="dropdown-item text-center small text-gray-500" href="#/notification">Lihat Semua Notikasi</a>
                </div>
            </li>

        ${this._renderNotifPc()}
        ${this._renderNotifPd()}
        ${this._renderNotifPP()}
        ${this._renderAccount()}

        </nav>`;

      document.getElementById("containerNotificationPC").style.display = 'none';
      document.getElementById("containerNotificationPP").style.display = 'none';
      document.getElementById("containerNotificationPD").style.display = 'none';

      document.getElementById("messagesDropdownPC").addEventListener("click", () =>{
        const elements = document.querySelectorAll('.dropdown-item');
        elements.forEach((element) => {
          element.classList.remove('active');
        });
      })
      document.getElementById("messagesDropdownPD").addEventListener("click", () =>{
        const elements = document.querySelectorAll('.dropdown-item');
        elements.forEach((element) => {
          element.classList.remove('active');
        });
      })
      document.getElementById("messagesDropdownPP").addEventListener("click", () =>{
        const elements = document.querySelectorAll('.dropdown-item');
        elements.forEach((element) => {
          element.classList.remove('active');
        });
      })

      document.getElementById("alertsDropdown").addEventListener("click", () =>{
        const elements = document.querySelectorAll('.dropdown-item');
        elements.forEach((element) => {
          element.classList.remove('active');
        });
      })

      
    }



    _renderNotifPc(){
        return `  <li class="nav-item dropdown no-arrow mx-1" id="containerNotificationPC">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdownPC" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell fa-fw"></i>
                <span class="badge badge-danger badge-counter" id="counter_notif_pc">0</span>
            </a>

            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdownPC" style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                <h6 class="dropdown-header">
                    Pemberitahuan Pengurus Cabang
                </h6>
                <a class="dropdown-item d-flex align-items-center" href="#/anggota-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_permohonan_ktan">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan KTAN</div>
                        <span class="font-weight-light">Total Permohonan KTAN</span>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#/serkom-anggota-pc">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pc_permohonan_serkom_skp" >0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan SERKOM SKP</div>
                            <span class="font-weight-light">Total Permohonan SERKOM SKP</span>
                        </div>
                    </a>
                <a class="dropdown-item d-flex align-items-center" href="#/strttk-anggota-pc">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id ="counter_notif_pc_permohonan_rekom_strttk">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan Rekom STRTTK</div>
                            <span class="font-weight-light">Total Rekom STRTTK</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/sipttk-anggota-pc">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pc_permohonan_rekom_sipttkk">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan Rekom SIPTTK</div>
                            <span class="font-weight-light">Total Permohonan SIPTTK</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/skp-anggota-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_permohonan_skp">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan SKP</div>
                        <span class="font-weight-light">Total Permohonan SKP</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/kecukupan-skp-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_kecukupan_skp">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Kecukupan SKP</div>
                        <span class="font-weight-light">Total Permohonan Kecukupan SKP</span>
                    </div>
                  </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/rekom-mutasi-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_permohonan_mutasi_in">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Mutasi Masuk</div>
                        <span class="font-weight-light">Total Permohonan Mutasi Masuk</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/rekom-mutasi-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_permohonan_mutasi_out">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Mutasi Keluar</div>
                        <span class="font-weight-light">Total Permohonan Mutasi Keluar</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/iuran-anggota-pc">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pc_user_membership_unpaid">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Anggota yang belum Lunas iuran</div>
                        <span class="font-weight-light">Total Anggota yang belum Lunas iuran</span>
                    </div>
                </a>
                <a class="dropdown-item text-center small text-gray-500" href="#"></a>
            </div>
        </li>`
    }

    _renderNotifPd(){
        return `  <li class="nav-item dropdown no-arrow mx-1" id="containerNotificationPD">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdownPD" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell fa-fw"></i>
                <span class="badge badge-danger badge-counter" id="counter_notif_pd">0</span>
            </a>

            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdownPD" style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                <h6 class="dropdown-header">
                    Pemberitahuan Pengurus Daerah
                </h6>
                <a class="dropdown-item d-flex align-items-center" href="#/anggota-pd">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pd_permohonan_ktan">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan KTAN</div>
                        <span class="font-weight-light">Total Permohonan KTAN</span>
                    </div>
                </a>
                <a class="dropdown-item d-flex align-items-center" href="#/serkom-anggota-pd">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pd_permohonan_serkom_skp">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan SERKOM</div>
                            <span class="font-weight-light">Total Permohonan SERKOM</span>
                        </div>
                    </a>
                <a class="dropdown-item d-flex align-items-center" href="#/strttk-anggota-pd">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pd_permohonan_rekom_strttk">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan Rekom STRTTK</div>
                            <span class="font-weight-light">Total Permohonan Rekom STRTTK</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/skp-anggota-pd">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pd_permohonan_skp">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan SKP</div>
                            <span class="font-weight-light">Total Permohonan SKP</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/rekom-mutasi-pd">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pd_permohonan_mutasi_in">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Mutasi Masuk</div>
                        <span class="font-weight-light">Total Permohonan Mutasi Masuk</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/rekom-mutasi-pd">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pd_permohonan_mutasi_out">0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Mutasi Keluar</div>
                        <span class="font-weight-light">Total Permohonan Mutasi Keluar</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/data-permohonan-seminar-pd">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pd_seminar_in" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Seminar SKP</div>
                        <span class="font-weight-light">Total permohonan Seminar SKP yg membutuhkan konfirmasi</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/kecukupan-skp-pd">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pd_kecukupan_skp">0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan Kecukupan SKP</div>
                            <span class="font-weight-light">Total Permohonan Kecukupan SKP</span>
                        </div>
                      </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/data-permohonan-seminar-pd">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pd_seminar_payment" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Pembayaran Seminar SKP</div>
                        <span class="font-weight-light">Total pembayaran permohonan Seminar SKP yg membutuhkan konfirmasi</span>
                    </div>
                <a class="dropdown-item text-center small text-gray-500" ></a>
            </div>
        </li>`
    }

    _renderNotifPP(){
        return `  <li class="nav-item dropdown no-arrow mx-1" id="containerNotificationPP">
            <a class="nav-link dropdown-toggle" href="#" id="messagesDropdownPP" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-bell fa-fw"></i>
                <span class="badge badge-danger badge-counter" id="counter_notif_pp">0</span>
            </a>

            <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdownPP" style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                <h6 class="dropdown-header">
                    Pemberitahuan Pengurus Pusat
                </h6>
                    <a class="dropdown-item d-flex align-items-center" href="#/anggota-pp">
                        <div class="mr-3">
                            <div class="icon-circle bg-primary">
                                <i class="fas text-white" id="counter_notif_pp_permohonan_ktan" >0</i>
                            </div>
                        </div>
                        <div>
                            <div class="font-weight-bold">Permohonan KTAN</div>
                            <span class="font-weight-light">Total Permohonan KTAN</span>
                        </div>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" href="#/anggota-pp">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pp_register_need_confirmed" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Register Membutuhkan Konfirmasi</div>
                        <span class="font-weight-light">Total permohonan Registrasi yg membutuhkan konfirmasi</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/data-permohonan-seminar-pp">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pp_seminar_in" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Permohonan Seminar SKP</div>
                        <span class="font-weight-light">Total permohonan Seminar SKP yg membutuhkan konfirmasi</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/data-permohonan-seminar-pp">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pp_seminar_payment" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Pembayaran Seminar SKP</div>
                        <span class="font-weight-light">Total pembayaran permohonan Seminar SKP yg membutuhkan konfirmasi</span>
                    </div>
                    <a class="dropdown-item d-flex align-items-center" href="#/data-permohonan-seminar-pp">
                    <div class="mr-3">
                        <div class="icon-circle bg-primary">
                            <i class="fas text-white" id="counter_notif_pp_seminar_agreement" >0</i>
                        </div>
                    </div>
                    <div>
                        <div class="font-weight-bold">Tandatangan Seminar SKP</div>
                        <span class="font-weight-light">Total permohonan Seminar SKP yg belum di tanda tangani</span>
                    </div>
                </a>

                    
                  
                <a class="dropdown-item text-center small text-gray-500"></a>
            </div>
        </li>`
    }

    _renderAccount(){
        return `
        <div class="topbar-divider d-none d-sm-block"></div>
        <li class="nav-item dropdown no-arrow m-0">
            <a class="nav-link text-center m-0 badge" href="#" id="messagesDropdownPP" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-wifi" id="containerPing"></i>
                <span class="badge " style="font-size:0.9em; font-style:italic" id="ping-real-time">128 ms</span>
            </a>
        </li>

        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small" id="nama-user"><div class="shimmer title-line"></div></span>
                <img loading="lazy" class="img-profile rounded-circle" id="imgProfile"
                    src="./src/public/img/undraw_profile.svg">
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#/update-password">
                    <i class="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                    Ubah Password
                </a>
                <!--<a class="dropdown-item" href="#">
                    <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                </a>
                <a class="dropdown-item" href="#">
                    <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                </a>-->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </a>
            </div>
        </li>

    </ul>`
    }
}

customElements.define('app-bar', AppBar);
