import AnggotaPPPresenter from "../../../presenter/menu_pengurus_pp/anggota-pp-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const LokerPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabVerifikasiKTAN" class="nav-link hapus-border active" id="data-pengajuan-ktan-tab" data-bs-toggle="tab" data-bs-target="#tabVerifikasiKTAN" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Pengajuan KTAN</button>
					</li>
					<li class="nav-item" role="presentation">
					    <button href="#tabPengajuanRegistrasi" class="nav-link hapus-border" id="pengajuan-registrasi-new-tab" data-bs-toggle="tab" data-bs-target="#tabPengajuanRegistrasi" data-toggle="tab" type="button" role="tab" aria-controls="tabPengajuanRegistrasi" aria-selected="false">Registrasi Akun Anggota Baru</button>
					</li>
					<li class="nav-item" role="presentation">
					    <button href="#tabPengajuanRegistrasiOld" class="nav-link hapus-border" id="pengajuan-registrasi-old-tab" data-bs-toggle="tab" data-bs-target="#tabPengajuanRegistrasiOld" data-toggle="tab" type="button" role="tab" aria-controls="tabPengajuanRegistrasiOld" aria-selected="false">Registrasi Akun Anggota Lama</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabVerifikasiKTAN" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
                        <div class="card mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                            <select id="selectPengajuanPD" class="form-control">
                                                <option value=''>Pilih Daerah</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                            <select id="selectPengajuanPC" class="form-control">
                                                <option value=''>Pilih Cabang</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Pengajuan KTAN
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-5 col-xl-5 col-lg-5">
                                                <button type="button" class="btn btn-outline-primary mb-2 btn-jenis-permohonan" id="btnPermohonanKTAN" value="pending">
                                                Permohonan
                                                </button>
                                                <button type="button" class="btn btn-outline-primary mb-2  btn-jenis-permohonan" id="btnDisetujuiKTAN" value="approve">
                                                    Disetujui
                                                </button>
                                                <button type="button" class="btn btn-outline-primary mb-2 btn-jenis-permohonan" id="btnRevisiKTAN" value="revise">
                                                    Revisi
                                                </button>
                                            </div>
                                            <div class="col-sm-7 col-xl-7 col-lg-7 text-center">
                                                <div class="row">
                                                    <!--<div class="col-sm-6 mb-2">
                                                        <select id="selectPengajuanPD" class="form-control">
                                                            <option>Pilih Daerah</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-6 mb-2">
                                                        <select id="selectPengajuanPC" class="form-control">
                                                            <option>Pilih Cabang</option>
                                                        </select>
                                                    </div>-->
                                                    <div class="col-sm-3 d-none">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanKTAN" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAll"></th>
                                                                <th data-column="created_at">Tanggal Pengajuan</th>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th>PD ID</th>
                                                                <th>PD</th>
                                                                <th>PC</th>
                                                                <th>NIK</th>
                                                                <th>KTP</th>
                                                                <th>PAS Foto</th>
                                                                <th>Bukti Bayar</th>
                                                                <th>Ijazah</th>
                                                                <th>Status</th>
                                                                <th>Admin PC</th>
                                                                <th data-column="approval_cabang_at">Tanggal Approval PC</th>
                                                                <th>Admin PD</th>
                                                                <th data-column="approval_daerah_at">Tanggal Approval PD</th>
                                                                <th>Admin PP</th>
                                                                <th data-column="approval_pusat_at" >Tanggal Approval PP</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>

					<div class="tab-pane fade" id="tabPengajuanRegistrasi" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
                        <div class="card mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12">
                                    <div class="row">
                                    
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Status Approvement</label>
                                            <select id="selectStatusApprove" class="form-control">
                                                <option value='pending'>Permohonan</option>
                                                <option value='approve'>Disetujui</option>
                                                <option value=''>Semua</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Status Pembayaran</label>
                                            <select id="selectStatusPembayaran" class="form-control">
                                                <option value='confirm'>Menunggu Konfirmasi</option>
                                                <option value='paid'>Lunas</option>
                                                <option value='unpaid'>Belum Bayar</option>
                                                <option value=''>Semua</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                            <select id="selectPengajuanRegisPD" class="form-control">
                                                <option value=''>Pilih Daerah</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                            <select id="selectPengajuanRegisPC" class="form-control">
                                                <option value=''>Pilih Cabang</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3 mb-2">
                                            <button class="btn btn-primary btn-xl" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Pengajuan Registrasi Akun Anggota Baru
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-7 col-xl-7 col-lg-7 text-center">
                                                <div class="row">
                                                    <div class="col-sm-3 d-none">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAllRegis">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanRegistrasi" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center"><input type="checkbox" id="checkAllRegis"></th>
                                                                <th data-column="created_at" >Tanggal Registrasi</th>
                                                                <th data-column="name" >Nama Anggota</th>
                                                                <th>PD ID</th>
                                                                <th>NIK</th>
                                                                <th>PD</th>
                                                                <th>PC</th>
                                                                <th  data-column="email">Email</th>
                                                                <th  data-column="tempat_lahir">Tempat Lahir</th>
                                                                <th  data-column="tanggal_lahir">Tanggal Lahir</th>
                                                                <th data-column="phone-number">No HP</th>
                                                                <th>Nominal Pembayaran</th>
                                                                <th>Bukti Bayar</th>
                                                                <th>Status Pembayaran</th>
                                                                <th>Status Registrasi</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>


					</div>
                    <div class="tab-pane fade" id="tabPengajuanRegistrasiOld" role="tabpanel" aria-labelledby="profile-pendidikan-tab">
						
                        <div class="card mt-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="col-sm-12 col-xl-12 col-lg-12">
                                    <div class="row">
                                    
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                            <select id="selectPengajuanRegisPDOld" class="form-control">
                                                <option value=''>Pilih Daerah</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-4 mb-2">
                                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                            <select id="selectPengajuanRegisPCOld" class="form-control">
                                                <option value=''>Pilih Cabang</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header font-weight-bold text-primary">
                                        Pengajuan Registrasi Akun Anggota Lama
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-5 col-xl-5 col-lg-5">
                                                <button type="button" class="btn btn-outline-primary mb-2 btn-jenis-permohonan-regis-old" id="btnPermohonanRegistrasi" value="0">
                                                Permohonan
                                                </button>
                                                <button type="button" class="btn btn-outline-primary mb-2  btn-jenis-permohonan-regis-old" id="btnDisetujuiRegistrasi" value="1">
                                                    Disetujui
                                                </button>
                                            </div>
                                            <div class="col-sm-7 col-xl-7 col-lg-7 text-center">
                                                <div class="row">
                                                    <div class="col-sm-3 d-none">
                                                        <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAllRegis">
                                                            <i class="fas fa-check"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                                            <i class="fas fa-print"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                                            <i class="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-sm-12">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover" id="tablePengajuanRegistrasiOld" width="100%" cellspacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th data-column="created_at">Tanggal Registrasi</th>
                                                                <th data-column="name">Nama Anggota</th>
                                                                <th data-column="phone_number">No HP</th>
                                                                <th data-column="ktan">No KTAN</th>
                                                                <th>NIK</th>
                                                                <th data-column="email">Email</th>
                                                                <th>PD</th>
                                                                <th>PC</th>
                                                                <th data-column="ktan_expires_at">Masa Berlaku KTAN</th>
                                                                <th>KTAN Lama</th>
                                                                <th  >Periode Iuran Terakhir (Bulan Awal)</th>
                                                                <th  >Periode Iuran Terakhir (Bulan Akhir)</th>
                                                                <th>Bukti Iuran Terakhir</th>
                                                                <th data-column="status_pc">Status</th>
                                                                <th>Aksi</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer circle"></div></td>
                                                                <td><div class="shimmer title-line"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                                <td><div class="shimmer title-line end"></div></td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            
                                                        </tfoot>
                                                        <tbody>
                                                        
                                                        
                                                        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>


					</div>
				</div>
			</div>
		</div>
        
        <div class="modal fade" id="commentModalUserKTAN" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Komentar Pengajuan KTAN
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                                <form id="form-comment-user-ktan">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuanKTANUser" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanKTAN"><i class='fas fa-comment'></i> Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Revisi & Berikan Komentar
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Revisi & Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="modal fade" id="commentModalRegist" tabindex="-1" role="dialog" aria-labelledby="commentModalRegist"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Tolak Registrasi & Berikan Alasan Penolakan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment-regist">
                                    <label class="control-label font-weight-bold">Alasan Penolakan Registrasi</label>
                                    <textarea class="form-control" id="komentarRegistrasi" name="komentar" placeholder="Alasan Penolakan..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarRegistrasi"><i class='fas fa-comment'></i> Tolak & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="commentModalRegistOld" tabindex="-1" role="dialog" aria-labelledby="commentModalRegist"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Tolak Registrasi Anggota Lama & Berikan Alasan Penolakan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <form id="form-comment-regist">
                                    <label class="control-label font-weight-bold">Alasan Penolakan Registrasi Anggota Lama</label>
                                    <textarea class="form-control" id="komentarRegistrasiOld" name="komentar" placeholder="Alasan Penolakan..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarRegistrasiOld"><i class='fas fa-comment'></i> Tolak & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
		return view;
	  },

	async afterRender() {
		
        await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default LokerPP;