import PengajuanSeminarPresenter from "../../../presenter/seminar/pengajuan-seminar-pp-presenter.js";
import ImagePopUPInitiator from "../../../presenter/pop-up-presenter.js";
import PDFHelper from "../../../utils/pdf-helpert.js";


const PengajuanSeminarPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row mt-3">
            <div class="col-sm-12">
                <div class="card shadow mb-4">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
                        Data Pengajuan Seminar SKP PP
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-sm-3 mb-2">
                                        <label class="control-label font-weight-bold">Status Data Seminar</label>
                                        <select id="selectStatusSeminar" class="form-control">
                                            <option value='draft'>DRAFT</option>
                                            <option value='publish'>PUBLISH</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-3 mb-2">
                                    <label class="control-label font-weight-bold">&nbsp;</label>
                                    <button class="btn btn-primary btn-xl form-control" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                    </div>
                                </div>
                            </div>


                            <div class="col-sm-12 col-xl-12 col-lg-12 mb-3 mt-3">
                            <button type="button" class="btn btn-info" id="btnAddSeminar" data-toggle="modal" data-target="#modalTambahPengajuan" title="Tambah Pengajuan Seminar">
                                <i class="fas fa-plus"></i> Tambah Pengajuan Seminar SKP
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover" id="tablePengajuanSeminar" width="100%" cellspacing="0">
                            <thead>
                            <tr>
                                <th data-column="id">ID Pengajuan</th>
                                <th data-column="created_at">Tanggal Pengajuan</th>
                                <th data-column="judul_seminar">Judul Seminar</th>
                                <th data-column="penyelenggara">Jenis Penyelenggara</th>
                                <th data-column="kategori_peserta">Kategori Peserta</th>
                                <th data-column="kontribusi_peserta">Kontribusi Peserta</th>
                                <th data-column="tanggal_mulai">Tanggal Mulai</th>
                                <th data-column="tanggal_selesai">Tanggal Selesai</th>
                                <th data-column="kategori_seminar">Kategori</th>
                                <th data-column="biaya_penerbitan_skp">Biaya Penerbitan Seminar SKP</th>
                                <th>Bukti Bayar Penerbitan Seminar SKP</th>
                                <th data-column="status_pp">Status PP</th>
                                <th data-column="nomor_sk">NO-SK</th>
                                <th>INFO-SKP</th>
                                <th>Proposal</th>
                                <th>Status</th>
                                <th data-column="agreement_status">Status SKP</th>
                                <th width="10%">Opsi</th>
                            </tr>
                        </thead>
                        <tfoot>
                            
                        </tfoot>
                        <tbody>
                            <tr>
                                <td><div class="shimmer content-line end"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer circle"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                                <td><div class="shimmer content-line"></div></td>
                            </tr>
                            <tr>
                            <td><div class="shimmer content-line end"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer circle"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            </tr>
                            <tr>
                            <td><div class="shimmer content-line end"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer circle"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            <td><div class="shimmer content-line"></div></td>
                            </tr>
                        
                        
                        
                        </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-uppercase bd-example-modal-lg" id="modalTambahPengajuan" tabindex="-1" role="dialog" aria-labelledby="modalTambahPengajuan"
            aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold" id="titleModalPengajuan">Tambah Pengajuan Seminar SKP</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body" id="tambahMateri">
                        <div class="row mt-3">
                            <form id="form-pengajuan-seminar" enctype="multipart/form-data">
                            <input type="hidden" id="statePengajuan" class="form-control" >
                            <input type="hidden" id="stateIdSeminar" class="form-control" >
                                <div class="col-lg-12">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Penyelenggara</label>
                                        <div class="col-sm-12">
                                            <select class="form-control text-uppercase" id="selectPenyelenggara" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="internal">Penyelenggara Internal</option>
                                                <option value="external">Penyelenggara External</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row shadow-lg rounded d-none  " id="formExternal" style="margin:20px; padding:20px;">
                                      <label class="control-label col-sm-12 font-weight-bold text-primary">Data Surat External</label>
                                        
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Nomor Surat</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="textNomorSurat" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Tanggal Surat Permohonan</label>
                                                <div class="col-sm-12">
                                                    <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="tanggalSuratExternal"  class="form-control">
                                                </div>
                                            </div>
                                         </div> 
                                        <div class="col-sm-12">
                                            <div class="row">
                                              <label class="control-label col-sm-12 font-weight-bold">Upload Surat Dari Team External</label>
                                            </div>
                                            <div class="row">
                                                <div class="card ml-2 mb-2 border-left-primary col-sm-11 "  id="containerSuratEdit">
                                                    <div class="mt-2 mb-2">
                                                    <button id="btnSuratPdfEdit"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                                        <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                                        <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                                    </svg> Buka File Surat Lama</button>

                                                <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah file surat atau biarkan jika tidak ingin mengubah.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="form-upload-surat" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>

                                       
                                    </div>
                                    <div class="form-group row">
                                    <label class="control-label col-sm-12 font-weight-bold text-primary">Kepanitiaan</label>
                                        
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Ketua Pelaksana</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="textKetuaPelaksana" class="form-control text-uppercase" required>
                                                </div>
                                                <label class="control-label col-sm-12 font-weight-bold">NIAN Ketua Pelaksana</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="nianKetuaPelaksana" class="form-control text-uppercase" required>
                                                </div>
                                                <label class="control-label col-sm-12 font-weight-bold">Jabatan Ketua Pelaksana</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="jabatanKetuaPelaksana" class="form-control text-uppercase" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Sekretaris</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="textSekretaris" class="form-control text-uppercase" required>
                                                </div>
                                                <label class="control-label col-sm-12 font-weight-bold">NIAN Sekretaris</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="nianSekretaris" class="form-control text-uppercase" required>
                                                </div>
                                                <label class="control-label col-sm-12 font-weight-bold">Jabatan Sekretaris</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="jabatanSekretaris" class="form-control text-uppercase" required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold text-primary">Perihal Seminar</label>
                                    
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Kategori Seminar</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control text-uppercase" id="selectKategoriSeminar" required>
                                                        <option value="pendidikan">Pembelajaran</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Judul Seminar</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="judulSeminar" class="form-control text-uppercase" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6" id="formSKP">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold" id="jumlah_skp_peserta_title">Jumlah SKP Peserta</label>
                                                <div class="col-sm-12">
                                                    <input type="number" step="0.01" id="jumlahSKPPeserta" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                            <div class="row" id="container_skp_peserta_online_add">
                                                <label class="control-label col-sm-12 font-weight-bold">Jumlah SKP Peserta Online</label>
                                                <div class="col-sm-12">
                                                    <input type="number" step="0.01" id="jumlahSKPPesertaOnline" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Jumlah SKP Panitia</label>
                                                <div class="col-sm-12">
                                                    <input type="number" step="0.01" id="jumlahSKPPanitia" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Jumlah SKP Moderator</label>
                                                <div class="col-sm-12">
                                                    <input type="number" step="0.01" id="jumlahSKPModerator" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Jumlah SKP Narasumber</label>
                                                <div class="col-sm-12">
                                                    <input type="number" step="0.01" id="jumlahSKPNarasumber" class="form-control text-uppercase" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Tempat Penyelenggaraan</label>
                                                <div class="col-sm-12">
                                                    <input type="text" id="tempatPenyelenggaraan" class="form-control text-uppercase" required>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Kategori Peserta Seminar</label>
                                                <div class="col-sm-12">
                                                    <select class="form-control text-uppercase" id="selectKategoriPesertaSeminar" required>
                                                        <option value="">Pilih Salah Satu</option>
                                                        <option value="online">Online</option>
                                                        <option value="offline">Offline</option>
                                                        <option value="hybrid">Hybrid Offline & Online</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">      
                                                <label class="control-label col-sm-12 font-weight-bold">Quota Peserta</label>
                                                <div class="col-sm-12">
                                                    <input type="number" id="quotaOffline" class="form-control" required>
                                                </div>
                                            </div>
                                             <div class="row">
                                                 <label class="control-label col-sm-12 font-weight-bold">Kontribusi Peserta</label>
                                                 <div class="col-sm-12">
                                                     <div class="input-group mb-3">
                                                         <div class="input-group-prepend">
                                                         <span class="input-group-text" id="basic-addon1">Rp</span>
                                                         </div>
                                                         <input type="text" class="form-control" placeholder="" aria-label="Kontribusi Peserta" aria-describedby="basic-addon1" id="kontribusiPeserta">
                                                     </div>
                                                 </div>
                                             </div>
                                             <div class="row">
                                                <label class="control-label col-sm-12  font-weight-bold">Pilih Tipe Pembayaran </label>
                                                <div class="col-sm-12">
                                                            <select id="selectStatusBiaya" class="form-control">
                                                                <option value="">Pilih Salah Satu</option>
                                                                <option value="0">Gratis</option>
                                                                <option value="1">Berbayar</option>
                                                        </select>
                                                </div>
                                             </div>
                                         </div>
                                         <div class="col-sm-12"> 
                                             <div class="row">    
                                                 <label class="control-label col-sm-12 font-weight-bold">Biaya Penerbitan Seminar SKP</label>
                                                 <div class="col-sm-12">
                                                     <div class="input-group mb-3">
                                                         <div class="input-group-prepend">
                                                         <span class="input-group-text" id="basic-addon1">Rp</span>
                                                         </div>
                                                         <input type="text" class="form-control" placeholder="" aria-label="Biaya Penerbitan Seminar SKP" aria-describedby="basic-addon1" readonly id="textBiayaPenerbitanSKP">
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                         <div class="col-sm-12">
                                             <div class="row">
                                                 <label class="control-label col-sm-12 font-weight-bold">Upload Bukti Bayar Penerbitan SKP (Max 500 KB)</label>
                                                 <div class="card ml-2 mb-2 border-left-primary col-sm-11 "  id="containerBuktiBayarSeminar">
                                                     <div class="mt-2 mb-2">
                                                         <img loading="lazy" class="show-image-pop-up" id="show" name="show_image_bukti_bayar" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">
 
                                                         <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah file.</h6>
                                                     </div>
                                                 </div>
                                             
                                             </div>
 
                                             <div id="form-upload-bukti-bayar-seminar" class="col-sm-12 dropzone">
                                                 <input type="hidden" name="file" style=""/>
                                             </div>
                                         </div>
                                       
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Menimbang</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textMenimbang"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Mengingat</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textMengingat"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold text-primary">Tanggal & Waktu Pelaksanaan</label>
                                        
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Tanggal Mulai & Waktu Mulai</label>
                                                <div class="col-sm-7">
                                                    <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="tanggalMulai" class="form-control" required>
                                                </div>
                                                <div class="col-sm-5">
                                                    <input type="time" id="waktuMulai" class="form-control" required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <label class="control-label col-sm-12 font-weight-bold">Tanggal Selesai & Waktu Selesai</label>
                                                <div class="col-sm-7">
                                                    <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="tanggalSelesai" class="form-control" required>
                                                </div>
                                                <div class="col-sm-5">
                                                    <input type="time" id="waktuSelesai" class="form-control" required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Batas Pendaftaran </label>
                                        <div class="col-sm-12">
                                            <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="batasPendaftarOnline" class="form-control" required>
                                        </div>
                                    </div>
                                   
                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <div class="row">
                                            <label class="control-label col-sm-12 font-weight-bold">Upload Proposal (Max 2 MB)</label>
                                            </div>
                                            <div class="row">
                                                <div class="card ml-2 mb-2 border-left-primary col-sm-11 "  id="containerProposalEdit">
                                                    <div class="mt-2 mb-2">
                                                    <button id="btnProposalPdfEdit"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                                        <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                                        <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                                    </svg> Buka File Proposal Lama</button>

                                                  <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah file proposal atau biarkan jika tidak ingin mengubah.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="form-upload-proposal" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                            <label class="control-label col-sm-12 font-weight-bold">Upload Background Sertifikat (Max 500 KB)</label>
                                            </div>         
                                                <div class="card ml-2 mb-2 border-left-primary col-sm-11 "  id="containerSertifikatEdit">
                                                    <div class="mt-2 mb-2">
                                                    <img loading="lazy" class="show-image-pop-up" id="show" name="show_image_background" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">

                                                        <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah background sertifikat.</h6>
                                                    </div>
                                                </div>

                                            <div id="form-upload-sertifikat" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-12">
                                            <div class="row">
                                            <label class="control-label col-sm-12 font-weight-bold">Upload Brosur (Max 500 KB)</label>
                                            </div>
                                            <div class="card ml-2 mb-2 border-left-primary col-sm-11 " id="containerBrosurEdit">
                                            <div class="mt-2 mb-2">
                                            <img loading="lazy" id="show" class="show-image-pop-up" name="show_image_brosur" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">

                                                <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah brosur .</h6>
                                            </div>
                                        </div>
                                            <div id="form-upload-brosur" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Deskripsi Kegiatan</label>
                                        <div class="col-sm-12">
                                            <textarea rows="5" id="textDeskripsi" placeholder="Deskripsi Kegiatan..." name="deskripsi"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6">
                                            <button type="submit" class="form-control text-uppercase btn btn-primary" id="btnSavePengajuan"><i class="fas fa-save"></i> Kirim Pengajuan</button>
                                        </div>
                                        <div class="col-sm-6">
                                            <button type="reset" class="form-control btn btn-warning text-uppercase" id="btnReset"><i class="fas fa-undo"></i> Kosongkan Formulir</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade text-uppercase" id="modalTambahMateri" tabindex="-1" role="dialog" aria-labelledby="modalTambahMateri"
            aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold" id="stateTitleMateri">Materi Seminar</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body" id="tambahMateri">
                    <input type="hidden" id="stateIdMateriSeminar" class="form-control">
                    <input type="hidden" id="stateMateriSeminar" class="form-control">

                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">ID Seminar</label>
                            <div class="col-sm-9">
                                <input type="text" id="textIdSeminar" class="form-control" placeholder="ID Seminar" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Judul Meteri Seminar</label>
                            <div class="col-sm-9">
                                <input type="text" id="textJudulSeminar" class="form-control" placeholder="Judul Materi Seminar">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Pemateri</label>
                            <div class="col-sm-9">
                                <input type="text" id="textPemateri" class="form-control" placeholder="Pemateri">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Moderator</label>
                            <div class="col-sm-9">
                                <input type="text" id="textModerator" class="form-control" placeholder="Moderator">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Instansi</label>
                            <div class="col-sm-9">
                                <input type="text" id="textInstansi" class="form-control" placeholder="Instansi">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Dari Jam</label>
                            <div class="col-sm-9">
                                <input type="time" id="textDariJam" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row border-bottom pb-2">
                            <label class="control-label col-sm-3 font-weight-bold text-right">Sampai Jam</label>
                            <div class="col-sm-9">
                                <input type="time" id="textSampaiJam" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-12 font-weight-bold">Deskripsi Materi</label>
                            <div class="col-sm-12">
                                <textarea rows="5" id="textDeskripsiMateri" placeholder="Deskripsi Materi..." name="deskripsi"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="control-label col-sm-12 font-weight-bold">Lampiran</label>
                        </div>
                        <div class="card ml-2 mb-2 border-left-primary col-sm-11 "  id="containerLampiranEdit">
                            <div class="mt-2 mb-2">
                            <img loading="lazy" class="show-image-pop-up" id="show" name="show_image_lampiran" src="" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="'+data+'" width="40px">
                                <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah lampiran materi seminar.</h6>
                            </div>
                         </div>
                        <div id="form-upload-lampiran" class="col-sm-12 dropzone">
                            <input type="hidden" name="file" style=""/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" id="btnSaveMateri">Simpan Perubahan</button>
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="modalViewDetail" tabindex="-1" role="dialog" aria-labelledby="modalViewDetail"
        aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Detail Seminar</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body col-sm-12">
                        <table class="table table-bordered" style="width:100%">
                            <tr>
                                <td valign="top" width="25%">Judul Seminar</td>
                                <td valign="top" width="1%">:</td>
                                <th valign="top" id="judulSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Kategori Seminar</td>
                                <td valign="top">:</td>
                                <th valign="top" id="kategoriSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Jumlah SKP</td>
                                <td valign="top">:</td>
                                <th valign="top" id="jumlahSKPDet">
                                    <table width="100%">
                                    <table width="100%">
                                        <tr  id="tr_peserta_seminar_online">
                                            <td width="25%">Peserta Seminar Online</td>
                                            <td width="1%">:</td>
                                            <td id="skpPesertaOnline"></td>
                                        </tr>
                                        <tr>
                                            <td id="peserta_seminar_offline" width="25%">Peserta</td>
                                            <td width="1%">:</td>
                                            <td id="skpPeserta"></td>
                                        </tr>
                                        <tr>
                                            <td>Moderator</td>
                                            <td>:</td>
                                            <td id="skpModerator"></td>
                                        </tr>
                                        <tr>
                                            <td>Narasumber / Pembicara</td>
                                            <td>:</td>
                                            <td id="skpNarasumber"></td>
                                        </tr>
                                        <tr>
                                            <td>Panitia</td>
                                            <td>:</td>
                                            <td id="skpPanitia"></td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                            <tr>
                                <td valign="top" width="25%">Ketua Pelaksana</td>
                                <td valign="top">:</td>
                                <th valign="top" id="ketuaSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top">Sekretaris</td>
                                <td valign="top">:</td>
                                <th valign="top" id="sekretarisSeminarDet"></th>
                            </tr>
                            <tr>
                                <td valign="top">Tanggal Pelaksanaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutTime"></th>
                            </tr>
                            <tr>
                                <td valign="top">Kepesertaan</td>
                                <td valign="top">:</td>
                                <th valign="top" id="aboutPeserta"></th>
                            </tr>
                        
                        </table>
                        <input type="hidden" id="stateIdSeminarDetail" class="form-control">
                        <div class="form-group row shadow-lg rounded" id="formExternalDetail" style="margin:20px; padding:20px;">
                        <label class="control-label col-sm-12 font-weight-bold text-primary">Data Surat External</label>
                            <div class="col-sm-12">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Nomor Surat</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="textNomorSuratDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Tanggal Surat</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="tanggalSuratExternalDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="col-sm-12" style="margin-top:10px">
                                <div class="row">
                                <label class="control-label col-sm-12 font-weight-bold">Surat Dari Team External</label>
                                </div>
                                <button id="btnSuratDetPDF"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                        <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                        <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                    </svg> Buka File PDF</button>
                                </th>
                            </div>

                            <div class="col-sm-12" style="margin-top:10px">
                                <div class="row">
                                    <label class="control-label col-sm-12 font-weight-bold">Keterangan</label>
                                    <div class="col-sm-12">
                                        <input type="text" id="textKeteranganDetail" class="form-control text-uppercase" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="font-weight-bold text-primary">Materi Seminar</h6>
                        <div class="row">
                            <div class="col-sm-12 overflow-auto">
                                <table class="table table-bordered" style="width:100%" id="tableMateri">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Judul Materi</th>
                                            <th>Moderator</th>
                                            <th>Pemateri</th>
                                            <th>Tanggal Seminar</th>
                                            <th>Tempat Penyelenggaraan</th>
                                            <th>Author</th>
                                            <th>Dari Jam</th>
                                            <th>Sampai Jam</th>
                                            <th>Lampiran</th>
                                            <th>Opsi</th>
                                        </tr>
                                    </thead>
                                    <tbody id="contentDet">

                                    </tbody>
                                    
                                </table>
                            </div>
                        </div>
                        <table class="table table-bordered mt-3" style="width:100%">
                        <tr>
                            <th class="text-center" width="30%">Proposal</th>
                            <th class="text-center" width="30%">Surat Pengantar</th>
                            <th class="text-center" width="35%">Brosur</th>
                            <th class="text-center" width="35%">Background Sertifikat</th>
                        </tr>
                        <tr>
                        <th class="text-center" width="30%">
                        <button id="btnProposalPdfDetail"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg> Buka File PDF</button>
                        </th>
                        <th class="text-center" width="30%">
                        <button id="btnSuratPengantarPdfDetail"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg> Buka File PDF</button>
                        </th>
                        <th class="text-center" width="35%">
                            <img loading="lazy" id="brosurDetailImage" src="" alt="Brosur Seminar" width="35%">
                        </th>
                        <th class="text-center" width="35%">
                        <img loading="lazy" id="backgroundSertifikatDetail" src="" alt="Background sertifikat Seminar" width="35%">
                        </th>
                        </tr>
                    </table>
                        <div class="row" style="width:100%">
                            <label class="control-label col-sm-12 font-weight-bold">Deskripsi Kegiatan</label>
                            <div class="col-sm-12" >
                                <textarea rows="5" style="width:100%" id="textDeskripsiDetail" placeholder="Deskripsi Kegiatan..." name="deskripsi"></textarea>
                            </div>
                        </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    <div class="modal fade text-uppercase bd-example-modal-lg" id="modalLaporanSeminar" tabindex="-1" role="dialog" aria-labelledby="modalLaporanSeminar"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary font-weight-bold">Laporan Pengajuan Seminar SKP</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" id="tambahMateri">
                <div class="row mt-3" style="widht=100%">
                     <input type="hidden" id="textIdSeminarLaporan" style=""/>
                        <div class="col-lg-6">
                          
                            <div class="form-group row">
                                <label class="control-label col-sm-12 font-weight-bold">Import Peserta Anggota</label>
                                    <div class="col-sm-12" style="margin:5px;">
                                        <button type="button" class="form-control text-uppercase btn btn-success" id="btnDowonloadFormatLaporan"><i class="fas fa-file"></i> Unduh Format Laporan Angota</button>
                                    </div>
                                <div id="form-upload-anggota" class="col-sm-10 dropzone" style="margin-top:20px;">
                                    <input type="hidden" name="file" style=""/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <button type="submit" class="form-control text-uppercase btn btn-primary" id="btnSaveLaporanAnggotaSeminar"><i class="fas fa-save"></i> Kirim Laporan Anggota Seminar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 ">
                        <div class="form-group row">
                            <label class="control-label col-sm-12 font-weight-bold">Upload Laporan Seminar</label>
                            <div class="row ml-2">
                                <div class="card ml-2 mb-2 border-left-primary col-sm-11 " id="containerFileLaporanAnggota">
                                    <div class="mt-2 mb-2">
                                    <button id="btnLaporanPdfEdit"  type="button" data-title="" data-file="" class="btn btn-outline-danger" id="openPDF">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                        <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                        <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                    </svg> Buka File Laporan Lama</button>

                                <h6 class="text-lowercase"> *Upload lagi jika Anda ingin mengubah file laporan seminar atau biarkan jika tidak ingin mengubah.</h6>
                                    </div>
                                </div>
                            </div>
                            <div id="dropzone-laporan-seminar" class="col-sm-11 dropzone" style="margin-top:68px;">
                                <input type="hidden" name="file" style=""/>
                            </div>
                         </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <button type="submit" class="form-control text-uppercase btn btn-primary" id="btnSaveLaporanSeminar"><i class="fas fa-save"></i> Kirim Laporan Seminar</button>
                            </div>
                        </div>
                    </div>
                 </form>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Tutup</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal"
aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Komentar Permohonan Seminar</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header d-flex flex-row align-items-center justify-content-between>
                                <h6 class="m-0 font-weight-bold text-primary">Komentar Permohonan Seminar</h6>
                            </div>
                            <div class="card-body chat-body p-1" id="lamanChat">
                                <div class="chat-body" id="content-comment"  style="height:400px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Tutup</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade text-uppercase bd-example-modal-lg" id="modalPesertaSeminar" tabindex="-1" role="dialog" aria-labelledby="modalPesertaSeminar"
    aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary font-weight-bold " id="anggotaSeminarTitle">Anggota Seminar</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body" id="tambahMateri">
                <div class="row mt-3" style="widht=100%">
                     <input type="hidden" id="textIdSeminarAnggota" />
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover" id="tablePesertaSeminar" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>KTAN</th>
                                            <th>Nama</th>
                                            <th>No Sertifikat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tfoot>
                                        
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                 </form>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

        
		

    <div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
        
        `;
		return view;
	  },

	async afterRender() {
		await PengajuanSeminarPresenter.init();
        await  ImagePopUPInitiator.init();
        PDFHelper.initiator()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},
};

export default PengajuanSeminarPP;