import CONFIG from "../config/globals/config.js";

const PUBLIC_ENDPOINT = {
	
	SEND_KEY_VALIDATIONS : `${CONFIG.BASE_URL_PUBLIC}validation/kecukupan-skp`,

};

class ApiPublic {
	
	static async sendKey(key) {
		try {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var raw = JSON.stringify({
			"credential": key
		  });
		  
		const response =  await fetch(PUBLIC_ENDPOINT.SEND_KEY_VALIDATIONS, {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		})
			const responseJson = await response.json();
			return responseJson;
		} catch (err) {
			console.log(err)
			return {};
		}
	}

    static async getDataByCredential() {
		try {
		let myHeaders =  new Headers();
		const response =  await fetch(PUBLIC_ENDPOINT.GET_DATA_KECUKUPAN_SKP, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			return responseJson;
		} catch (err) {
			console.log(err)
			return {};
		}
	}


}

export default ApiPublic;