import ApiMutasiPC from "../../api/api_pengurus_pc/data-mutasi.js";
import ApiPengajuanSerkomPC from "../../api/api_pengurus_pc/pengajuan-serkom.js";
import ApiAnggota from "../../api/data-anggota.js";
import CONFIG from "../../config/globals/config.js";
import API_PENGURUS_PC_ENDPOINT from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";


const SyaratMutasiPresenter = {

	async init() {
		await this._setView();
		await this._createEventUpload();
		await this._getPersyaratan();
		await this.createInformationSerkom();
	},
	
	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSyaratMutasi").hide();
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async _createEventUpload() {
		const eventCreateSyaratMutasi= async (e) => {
			e.preventDefault();
			if ($("#btnAddSyaratMutasi").is(':visible')) {
				document.getElementById("btnAddSyaratMutasi").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSyaratSerkom = await ApiMutasiPC.createSyaratMutasi({
				name: document.getElementById('textNamaBerkas').value,
				description: document.getElementById('textDeskripsi').value,
				});
				if (createSyaratSerkom.status_code != 201) {
					NotificationModal.show(`${createSyaratSerkom.message}`, 'error');
					document.getElementById("btnAddSyaratMutasi").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratMutasi").hide();
				} else {
					NotificationModal.show(`${createSyaratSerkom.message}`, 'success');
					document.getElementById("btnAddSyaratMutasi").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratMutasi").hide();
					$("#btnClearSyaratMutasi").trigger("click");
					$('#tablePersyaratanMutasi').DataTable().ajax.reload();
				}
			}else {
				document.getElementById("btnUpdateSyaratMutasi").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSyaratSerkom = await ApiMutasiPC.updateSyaratMutasi({
					id : document.getElementById('textHIddenPersyaratan').value,
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,

				});

				if (updateSyaratSerkom.status_code != 200) {
					NotificationModal.show(`${updateSyaratSerkom.message}`, 'error');
					$("#btnAddSyaratMutasi").hide();
					document.getElementById("btnUpdateSyaratMutasi").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
				} else {
					NotificationModal.show(`${updateSyaratSerkom.message}`, 'success');
					document.getElementById("btnUpdateSyaratMutasi").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
					$("#btnAddSyaratMutasi").hide();
					$("#btnClearSyaratMutasi").trigger("click");
					$('#tablePersyaratanMutasi').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-persyaratan-mutasi').addEventListener('submit', eventCreateSyaratMutasi);

		$('#tablePersyaratanMutasi tbody').on('click', '#edit', function () {
			const table = $('#tablePersyaratanMutasi').DataTable();
			let data = table.row($(this).parents('tr')).data();

				$('#textHIddenPersyaratan').val(data.id);
				$('#textNamaBerkas').val(data.name);
				$('#textDeskripsi').val(data.description);
				$("#btnAddSyaratMutasi").hide();
				$("#btnUpdateSyaratMutasi").show();

		});

		const eventClear = async (e) => {
			document.getElementById("form-persyaratan-mutasi").reset();
			$("#btnAddSyaratSerkom").show();
			$("#btnUpdateSyaratSerkom").hide();
		}

		document.getElementById("btnClearSyaratMutasi").addEventListener("click", eventClear)

		$('#tablePersyaratanMutasi tbody').on('click', '#delete', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			const table = $('#tablePersyaratanMutasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Persyaratan </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deleteSyaratSerkom = await ApiPengajuanSerkomPC.deleteSyaratSerkom({
						id: id
					});
					if (deleteSyaratSerkom.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSyaratSerkom.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePersyaratanMutasi').DataTable().ajax.reload();
					}else {
						swal.fire({
							title: 'Error',
							text: deleteSyaratSerkom.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				}else if (result.dismiss == 'cancel') {
					$(this).html('<i class="fas fa-trash"></i></button>');	
				}
			}
			
			);
		});
	},

	async _getPersyaratan(){

		let table = await $('#tablePersyaratanMutasi').DataTable({
			"pageLength": 10,
			"lengthMenu": [10,25,50,100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className : "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_PENGURUS_PC_ENDPOINT.PERSYARATAN_MUTASI}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization','Bearer '+localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'description' },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 2,
					"data": null,
					"defaultContent": `
					<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-sm'  id='edit' title='Edit data '><i class='far fa-edit'></i></button>
                     <button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
				]
		});
	},

	async createInformationSerkom(){
		
		tinymce.remove('#textInformasiSerkom')
		tinymce.init({
			selector: 'textarea#textInformasiSerkom',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});
		
		const getInformation = await ApiMutasiPC.getInformationMutasi();
		var textInformationSerkom = tinymce.get('textInformasiSerkom').setContent(getInformation.data.description);

		$("#btnUpdateInformasiSerkom").click(async function () {
			$(this).html("Loading...")
			var textInformationSerkom = tinymce.get('textInformasiSerkom').getContent();
			const createInformationSerkom = await ApiMutasiPC.createInfromationMutasi({
				description: textInformationSerkom,
			});

			if(createInformationSerkom.status_code == 200){
				NotificationModal.show(`${createInformationSerkom.message}`, 'success');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			}else {
				NotificationModal.show(`${createInformationSerkom.message}`, 'error');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			}


		});
	},
}
export default SyaratMutasiPresenter;
