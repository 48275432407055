import GeneralSettingPDPresenter from "../../../presenter/menu_pengurus_pd/general-setting-pd-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const GeneralSettingPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <h3>General Setting Pengurus Daerah</h3>
        <div class="row">
            
            <div class="col-xl-12">
                <div class="card shadow mb-4">
                    <div class="card-header text-primary font-weight-bold">
                        Setting Data Umum
                    </div>
                    <div class="card-body">
                        <form id="form-general-setting">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Nama Kota</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textNamaKota" required placeholder="Nama Kota...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Alamat Daerah</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textAlamatDaerah" required placeholder="Alamat Daerah..."></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Informasi Umum</label>
                                        <div class="col-sm-12">
                                            <textarea class="form-control" id="textInformasiUmum" required placeholder="Informasi Umum..."></textarea>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Telepon</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textTelepon" required placeholder="Telepon...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Email</label>
                                        <div class="col-sm-12">
                                            <input type="email" class="form-control" id="textEmailCabang" required placeholder="Email...">
                                        </div>
                                    </div>                                 
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Faks</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textFaks" required placeholder="Faksimil...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Kode Pos</label>
                                        <div class="col-sm-12">
                                            <input type="number" class="form-control" id="textKodePos" required placeholder="Kode Pos...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">URL Maps</label>
                                        <div class="col-sm-12">
                                            <input type="url" class="form-control" id="textUrlMaps" required placeholder="URL Maps...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Informasi Rekening</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textInformasiRekening" required placeholder="Informasi Rekening...">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Tanda Tangan Digital</label>
                                        <div class="col-sm-12">
                                            <select class="form-control" id="strttkTTDPD" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="1">Aktif</option>
                                                <option value="0">Tidak Aktif</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button type="button" class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdate"><i class='fas fa-edit'></i> Update Setting</button>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        
        `;
		return view;
	  },

	async afterRender() {
		await GeneralSettingPDPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default GeneralSettingPD;