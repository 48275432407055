
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiPengajuanSTRTTKPD {

    static async createSyaratSTRTTK ({
		name,
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PERSYARATAN_STRTTK,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async createInfromationSTRTTK ({
		description
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.STRTTK_INFORMATION,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getInformationSTRTTK (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.STRTTK_INFORMATION,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateSyaratSTRTTK ({
		id,
		name,
		description
	}){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"name": name,
			"description": description,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async deleteSyaratSTRTTK ({
		id
	}){
		// console.log(data);
		console.log(id)
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.UPDATE_PERSYARATAN(id),{
				method : "DELETE",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
	
	static async approvePengajuanSTRTTK({
		id,
		approval,
		nomor_surat_keputusan_pd,
		nomor_sr_pd,
		nomor_keterangan_pd,
		tanggal_sr_pd,
		nama_penanda1_pd,
		jabatan_penanda1_pd,
		nian_penanda1_pd,
		periode_penanda1_pd,
		nama_penanda2_pd,
		jabatan_penanda2_pd,
		nian_penanda2_pd,
		masa_berlaku_sr_pd,
		kepada_pd,
		di_pd,
		sifat_pd,
		lampiran_pd,
		perihal_pd,
		jumlah_pembayaran_pd,
		status_pembayaran_pd,
		penanda_kwitansi_pd,
		ttd_digital_active,
		catatan
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var raw = JSON.stringify({
			"approval": approval,
			"tanggal_sr_pd": tanggal_sr_pd,
			"nomor_keputusan_pd": nomor_surat_keputusan_pd,
			"nomor_sr_pd": nomor_sr_pd,
			"nomor_keterangan_pd": nomor_keterangan_pd,
			"nama_penanda1_pd": nama_penanda1_pd,
			"jabatan_penanda1_pd": jabatan_penanda1_pd,
			"nian_penanda1_pd": nian_penanda1_pd,
			"periode_penanda1_pd": periode_penanda1_pd,
			"nama_penanda2_pd": nama_penanda2_pd,
			"jabatan_penanda2_pd": jabatan_penanda2_pd,
			"nian_penanda2_pd": nian_penanda2_pd,
			"masa_berlaku_sr_pd": masa_berlaku_sr_pd,
			"kepada_pd": kepada_pd,
			"di_pd": di_pd,
			"sifat_pd": sifat_pd,
			"lampiran_pd": lampiran_pd,
			"perihal_pd": perihal_pd,
			"jumlah_pembayaran_pd": jumlah_pembayaran_pd,
			"status_pembayaran_pd": status_pembayaran_pd,
			"penanda_kwitansi_pd": penanda_kwitansi_pd,
			"ttd_digital_active": ttd_digital_active,
			"catatan": catatan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_STRTTK(id),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	

	
	static async getPengurusPD (){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.PENGURUS,{
				method : "GET",
				headers: myHeaders,
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
	static async getCommentar(
		userID,
		commentFor,
		pengajuanID,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.GET_COMMENT_PENGAJUAN(userID,commentFor,pengajuanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async sendCommentar(
		userID,
		pengajuanID,
		commentFor,
		contentComment,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": `${userID}`,
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.SEND_COMMENT,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
}


export default ApiPengajuanSTRTTKPD;
