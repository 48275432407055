import kelolaUsersPPPresenter from '../../../presenter/menu_pengurus_pp/kelola-users-pp-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';

const kelolaUsersPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="card mt-3">
            <div class="card-header text-primary font-weight-bold">
                Filter Data
            </div>
            <div class="card-body">
                <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                    <div class="row">
                        <div class="col-sm-3 mb-2">
                            <label class="control-label font-weight-bold">Jenis Keanggotaan</label>
                            <select id="selectJenisKeanggotaan" class="form-control">
                                <option value="all">Semua</option>
                                <option value="1">Sudah Memiliki KTAN</option>
                                <option value="0">Belum Memiliki KTAN</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                            <select id="selectPengajuanPD" class="form-control">
                                <option value=''>Pilih Daerah</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                            <select id="selectPengajuanPC" class="form-control">
                                <option value=''>Pilih Cabang</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <label class="control-label font-weight-bold">Jenjang Pendidikan</label>
                            <select id="selectJenjang" class="form-control">
                                <option value="">Semua</option>
                                <option value="SMK-F / SMF / SAA">SMK-F / SMF / SAA</option>
                                <option value="D3 FARMASI">D3 FARMASI</option>
                                <option value="D3 ANAFARMA">D3 ANAFARMA</option>
                                <option value="D3 NON-FARMASI">D3 NON-FARMASI</option>
                                <option value="S1 FARMASI">S1 FARMASI</option>
                                <option value="S1 NON-FARMASI">S1 NON-FARMASI</option>
                                <option value="S2 FARMASI">S2 FARMASI</option>
                                <option value="S2 NON-FARMASI">S2 NON-FARMASI</option>
                                <option value="S3 FARMASI">S3 FARMASI</option>
                                <option value="S3 NON-FARMASI">S3 NON-FARMASI</option>
                            </select>
                        </div>
                        <div class="col-sm-12 mb-2">
                            <button class="btn btn-primary btn-xl" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                        </div>
                        <div class="col-sm-3 d-none">
                            <button type="button" class="btn btn-primary" title="Aprrove" id="btnVerifikasiAll">
                                <i class="fas fa-check"></i>
                            </button>
                            <button type="button" class="btn btn-light border" title="Print" id="btnPrint">
                                <i class="fas fa-print"></i>
                            </button>
                            <button type="button" class="btn btn-light border" title="Downloads" id="btnDownload">
                                <i class="fas fa-download"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
        
            
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header text-primary font-weight-bold">
                        Kelola Data Anggota
                    </div>
                    <div class="card-body">
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableDataAnggota" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th data-column="name"  rowspan="2">Nama Anggota</th>
                                                <th data-column="email" rowspan="2">Email</th>
                                                <th data-column="phone_number" rowspan="2">No HP</th>
                                                <th rowspan="2">PD</th> 
                                                <th rowspan="2">PC</th> 
                                                <th data-column="ktan" rowspan="2">No KTAN</th>
                                                <th rowspan="2">No STRTTK</th> 
                                                <th colspan="4" class="text-center">Pendidikan</th>
                                                <th data-column="jenis_kelamin" rowspan="2">Jenis Kelamin</th>
                                                <th data-column="tempat_lahir"  rowspan="2">Tempat Lahir</th>
                                                <th data-column="tanggal_lahir" rowspan="2">Tanggal Lahir</th>
                                                <th data-column="ktan_expires_at" rowspan="2">Masa Berlaku KTAN</th>
                                                <th rowspan="2">Foto</th>
                                                <th rowspan="2">KTAN</th>
                                                <th rowspan="2">KTP</th>
                                                <th rowspan="2">Ijazah</th>
                                                <th rowspan="2">Aksi</th>
                                            </tr>
                                            <tr>
                                                <th>Jenjang Pendidikan</th>
                                                <th>Nama Institusi</th>
                                                <th>Tahun Lulus</th>
                                                <th>Tanggal Ijazah</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="deleteAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="deleteAnggotaModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold text-primary">Blokir / Hapus Anggota Permanen & Berikan Alasan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-info">
                                    <strong>Informasi :</strong><br>
                                    <ul>
                                        <li>Alasan yang anda masukkan akan di kirim ke email anggota.</li>
                                        <li>Memblokir anggota dapat membuat anggota tidak dapat registrasi kembali</li>
                                        <li>Pastikan anda melakukan pemblokiran terhadap anggota yang sesuai</li>
                                    </ul>
                                    <br>
                                    <strong>Infromasi data anggota :</strong><br>
                                    <table width="100%" class="table">
                                        <tr>
                                            <td>Nama Anggota</td>
                                            <td>:</td>
                                            <td id="infoAnggotaName" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>KTAN</td>
                                            <td>:</td>
                                            <td id="infoAnggotaKTAN" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>PC</td>
                                            <td>:</td>
                                            <td id="infoAnggotaPC" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>PD</td>
                                            <td>:</td>
                                            <td id="infoAnggotaPD" class="font-weight-bold"></td>
                                        </tr>
                                    </table>
                                </div>
                                <form id="form-delete-anggota">
                                    <label class="control-label font-weight-bold">Alasan Pemblokiran Anggota</label>
                                    <textarea class="form-control" id="reaseonDeleteUser" name="komentar" placeholder="Alasan Pemblokiran Anggota..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendCommentDelete"><i class='fas fa-comment'></i> Blokir & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="deleteAnggotaPermanen" tabindex="-1" role="dialog" aria-labelledby="deleteAnggotaPermanen"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold text-primary">Hapus Anggota & Berikan Alasan</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-info">
                                    <strong>Informasi :</strong><br>
                                    <ul>
                                        <li>Alasan yang anda masukkan akan di kirim ke email anggota.</li>
                                        <li>Penghapusan anggota dapat berpengaruh terhadap seluruh data anggota, pengajuan, dan iuran anggota.</li>

                                    </ul>
                                    <br>
                                    <strong>Infromasi data anggota :</strong><br>
                                    <table width="100%" class="table">
                                        <tr>
                                            <td>Nama Anggota</td>
                                            <td>:</td>
                                            <td id="infoAnggotaNameForce" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>KTAN</td>
                                            <td>:</td>
                                            <td id="infoAnggotaKTANForce" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>PC</td>
                                            <td>:</td>
                                            <td id="infoAnggotaPCForce" class="font-weight-bold"></td>
                                        </tr>
                                        <tr>
                                            <td>PD</td>
                                            <td>:</td>
                                            <td id="infoAnggotaPDForce" class="font-weight-bold"></td>
                                        </tr>
                                    </table>
                                </div>
                                <form id="form-delete-anggota">
                                    <label class="control-label font-weight-bold">Alasan Penghapusan Anggota</label>
                                    <textarea class="form-control" id="reaseonDeleteUserForce" name="komentarForce" placeholder="Alasan Penghapusan Anggota..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendCommentDeleteForce"><i class='fas fa-comment'></i> Hapus & Berikan Alasan</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="editKTANAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="editKTANAnggotaModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Edit No. KTAN Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="control-label font-weight-bold">No. KTAN Anggota</label>
                            <input type="text" name="ktan" class="form-control" id="textKTANAnggota" aria-describedby="ktan" placeholder="No. KTAN Anggota">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                        <a class="btn btn-primary" id="btnSaveKTAN"><i class="fas fa-save"></i> Simpan</a>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="modal fade bd-example-modal-lg" id="detailAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold text-primary">Detail Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 pr-4">
                                <div class="row mt-3 ">
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3 mb-3 text-center">
                                        <img loading="lazy" id="detailAvatar" src="images/logo.png" width="30%">
                                    </div>
                                    <div class="col-sm-5 col-xl-5 col-lg-5 mx-auto pt-3">
                                        <h3 class="font-weight-bold" id="detailNamaAnggota">Nama Anggota</h3>
                                        <div class="row text-primary">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-id-card"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailKTAN">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-phone-alt"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailNoHP">
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-1 font-weight-bold">
                                                <i class="fas fa-at"></i>
                                            </div>
                                            <div class="col-sm-11" id="detailEmailHead">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5 mx-auto pt-3">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-user"></i> Data Umum</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                NIK
                                            </div>
                                            <div class="col-sm-8" id="detailNIK">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Email
                                            </div>
                                            <div class="col-sm-8" id="detailEmail">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tempat Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTempatLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Tanggal Lahir
                                            </div>
                                            <div class="col-sm-8" id="detailTanggalLahir">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4 font-weight-bold">
                                                Jenis Kelamin
                                            </div>
                                            <div class="col-sm-8" id="detailJenisKelamin">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-map-marker-alt"></i> Alamat</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Alamat
                                            </div>
                                            <div class="col-sm-7" id="detailAlamat">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Provinsi
                                            </div>
                                            <div class="col-sm-7" id="detailProvinsi">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kota / Kabupaten
                                            </div>
                                            <div class="col-sm-7" id="detailKota">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kecamatan
                                            </div>
                                            <div class="col-sm-7" id="detailKecamatan">
                                                
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-5 font-weight-bold">
                                                Kelurahan / Desa
                                            </div>
                                            <div class="col-sm-7" id="detailKelurahan">
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-money-check"></i> Bukti Bayar</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table width="100%">
                                                    <tr>
                                                        <td id="detailBuktiBayar">Tidak ada bukti bayar !</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-graduation-cap"></i> Pendidikan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPendidikan">

                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-briefcase"></i> Pekerjaan</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailPekerjaan">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-5 overflow-auto font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-certificate"></i> Serkom</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center">
                                                <table class="table table-bordered" width="100%" id="detailSerkom">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-5 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-file"></i> STRTTK</h5>
                                        <div class="col-sm-12 text-center overflow-auto">
                                            <table class="table table-bordered" width="100%" id="detailSTR">
                                            
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-sm-11 font-wight-bold pt-3 mx-auto">
                                        <h5 class="text-primary border-bottom border-primary pb-2"><i class="fas fa-id-badge"></i> SIPTTK</h5>
                                        <div class="row mb-3">
                                            <div class="col-sm-12 text-center overflow-auto">
                                                <table class="table table-bordered" width="100%" id="detailSIP">
                                                
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        
                        
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="editAnggotaModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Edit Data Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-info">
                            <strong>Informasi :</strong><br>
                            Periksa data anggota dengan teliti sebelum anda melakukan perubahan data anggota, terimakasih.
                        </div>
                        <form class="user" class="form-horizontal" id='form-update-anggota'>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">NIK</label>
                                <input type="text" name="NIK" class="form-control" id="registerTextNIK" aria-describedby="ktan" placeholder="NIK">
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Nama Lengkap</label>
                                <input type="text" name="register_nama_lengkap" class="form-control" id="registerTextNamaLengkap" aria-describedby="register_nama_lengkap" placeholder="Nama Lengkap..." required>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Keanggotaan PD</label>
                                <select class="form-control" id="registerSelectPD" name="register_select_pd" placeholder="Pilih Keanggotaan Wilayah PD" required>
                                    <option id="" value="">Pilih Keanggotaan Wilayah PD</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Keanggotaan PC</label>
                                <select class="form-control" id="registerSelectPC" name="register_select_pc" placeholder="Pilih Keanggotaan Wilayah PC" required>
                                    <option value="">Pilih Keanggotaan Wilayah PC</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">No HP</label>
                                <input type="number" name="register_no_hp" class="form-control" id="registerNumberNoHP" aria-describedby="register_no_hp" placeholder="No Hp..." required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                type = "number"
                                maxlength = "14">
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Email</label>
                                <input type="email" name="register_email" class="form-control" id="registerEmail" aria-describedby="register_email" placeholder="Email..." required>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Jenis Kelamin</label>
                                <select class="form-control" id="registerSelectJenisKelamin" name="register_jenis_kelamin" placeholder="Jenis Kelamin" required>
                                <option value="">Jenis Kelamin</option>
                                <option value="L">Laki Laki</option>
                                <option value="P">Perempuan</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="control-label font-weight-bold">Tanggal Lahir</label>
                                <input placeholder="Tanggal Lahir..." class="textbox-n form-control" type="date" pattern="\d{2}-\d{2}-\d{4}" id="registerTanggalLahir" required min="1900-01-01" max="2006-12-31"/>
                            </div>
                            
                            <button type="submit" id="btnUpdateAnggota" style="margin-top:15px;" class="btn btn-primary btn-user btn-block">Update Data Anggota</button>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" id="modalRestoreAnggota" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-primary font-weight-bold">Restore / Kembalikan Anggota</h5>
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="false" class="text-default">×</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="alert alert-info">
                            <strong>Informasi :</strong><br>
                            Data ini merupakan data anggota yang sudah di blokir, Anda dapat mengembalikan seluruh data anggota dengan cara klik tombol <a class ="btn btn-sm btn-success"><i class="fas fa-undo"></i> Restore</a>
                        </div>

                        <div class="row">
                            <div class="col-sm-3 mb-2">
                                <label class="control-label font-weight-bold">Keanggotaan Wilayah PD</label>
                                <select id="selectPDBlock" class="form-control">
                                    <option value=''>Pilih Daerah</option>
                                </select>
                            </div>
                            <div class="col-sm-3 mb-2">
                                <label class="control-label font-weight-bold">Keanggotaan Wilayah PC</label>
                                <select id="selectPCBlock" class="form-control">
                                    <option value=''>Pilih Cabang</option>
                                </select>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <button class="btn btn-primary btn-xl" id="btnFilterBlock"><i class="fas fa-filter"></i> Filter Data</button>
                            </div>
                        </div>


                        <div class="table-responsive">
                            <table class="table table-hovered table-bordered" id="tableAnggotaBlock" width="100%">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th data-column="name">Nama Anggota</th>
                                        <th data-column="ktan">Nomor KTAN</th>
                                        <th data-column="email">Email</th>
                                        <th data-column="jenis_kelamin">Jenis Kelamin</th>
                                        <th>PC</th>
                                        <th>PD</th>
                                        <th>Foto</th>
                                        <th>KTAN</th>
                                        <th>KTP</th>
                                        <th>Aksi</th>
                                    </tr>
                                </thead>
                                <tfoot>

                                </tfoot>
                                <tbody>
                                    <tr>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                    <tr>
                                        <td><div class="shimmer title-line end"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer title-line"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer circle"></div></td>
                                        <td><div class="shimmer title-line end"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
		return view;
	  },

	async afterRender() {
	 await kelolaUsersPPPresenter.init()
     await ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default kelolaUsersPP;