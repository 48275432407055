import ApiAnggota from "../api/data-anggota.js";

const MembershipHelper= {

    async scyncMembershipHelper() {
        const dataPrivate = await ApiAnggota.checkMembership();
        const dataAddressPrivate = dataPrivate.data;

        if (dataPrivate.status_code == 200) {
            if (dataAddressPrivate.is_membership_paid == false) {
                window.location.hash = '#/404?reason=unpaid';
                
            }
        }else{
            window.location.hash = '#/404?reason=unpaid';
        }
       
	},


    async isMemberShipPaid(){
        const dataPrivate = await ApiAnggota.checkMembership();
        const dataAddressPrivate = dataPrivate.data;
        if (dataPrivate.status_code == 200) {
            if (dataAddressPrivate.is_membership_paid == false) {
                return false
            }else{
                return true
            }
        }else{
          return false
        }
    },


    async isPcPdLocked(value){
        const dataPrivate = await ApiAnggota.checkIsPcPdLocked();
        console.log(dataPrivate)
        const dataAddressPrivate = dataPrivate.data;
        if (dataPrivate.status_code == 200) {
            
        }else{
          
        }
    }

    


    
}

export default MembershipHelper;