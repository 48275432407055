import IuranAnggotaPCPresenter from "../../../presenter/menu_pengurus_pc/iuran-anggota-pc-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';





const IuranAnggotaPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="card mb-3">
            <div class="card-header text-primary font-weight-bold">
                Filter Data
            </div>
            <div class="card-body">
                <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                    <div class="row">
                        <div class="col-sm-4 mb-2">
                            <label class="control-label font-weight-bold">Status Iuran</label>
                            <select id="selectJenisKeanggotaan" class="form-control">
                                <option value="confirm">Menunggu Konfirmasi</option>
                                <option value="approve">Lunas</option>
                                <option value="pending">Belum Bayar</option>
                                <option value="">Semua</option>
                            </select>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <label class="control-label font-weight-bold">Dari Tanggal</label>
                            <input type="date" id="selectStartDate" class="form-control">
                        </div>
                        <div class="col-sm-4 mb-2">
                            <label class="control-label font-weight-bold">Sampai Tanggal</label>
                            <input type="date" id="selectEndDate" class="form-control">
                        </div>
                        <div class="col-sm-12 mb-2">
                            <button type="button" class="btn btn-primary" id="btnFilter">
                                <i class="fas fa-filter"></i> Filter Data
                            </button>
                            <button type="button" class="btn btn-warning" id="btnReset">
                                <i class="fas fa-undo"></i> Reset Filter
                            </button>
                            <button type="button" class="btn btn-info" id="btnRefresh">
                                <i class="fas fa-sync-alt"></i> Refresh Data
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		<div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row text-primary font-weight-bold align-items-center justify-content-between">
                Iuran Anggota
            </div>
			<div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover" id="tableIuranAnggota" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th data-column="invoice_id">ID Invoice</th>
                                <th data-column="created_at">Tanggal Invoice</th>
                                <th data-column="name">Nama Anggota</th>
                                <th data-column="ktan">KTAN</th>
                                <th>Email</th>
                                <th>Dari Bulan</th>
                                <th>Sampai Bulan</th>
                                <th>Jumlah Bulan</th>
                                <th data-column="grand_total">Nominal Iuran</th>
                                <th>Deskripsi</th>
                                <th>Bukti Bayar</th>
                                <th data-column="status">Status</th>
                                <th>Nama Admin</th>
                                <th width="10%">Opsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer circle"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line end"></div></td>
                            </tr>
                            <tr>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer circle"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line end"></div></td>
                            </tr>
                            <tr>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer circle"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line"></div></td>
                                <td><div class="shimmer title-line end"></div></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            
                        </tfoot>
                        <tbody>
                        
                        
                        
                        </tbody>
                    </table>
                </div>
			</div>
		</div>

        
        <div class="modal fade" id="commentModalUserIuran" tabindex="-1" role="dialog" aria-labelledby="commentModal"
        aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header text-primary font-weight-bold">
                        Komentar Iuran
                        <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-left">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                    <div style="width:100%">
                                        <div style="width:10%;" class="float-right">
                                            <div class="shimmer circle"></div>
                                        </div>
                                        <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line"></div>
                                            <div class="shimmer content-line end"></div>
                                        </div>
                                    </div>
                                </div>
                                <form id="form-comment-user-iuran">
                                    <label class="control-label font-weight-bold">Komentar</label>
                                    <textarea class="form-control" id="komentarPengajuan" name="komentar" placeholder="Komentar..." required></textarea>
                                    <br>
                                    <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuan"><i class='fas fa-comment'></i> Berikan Komentar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        
        `;
		return view;
	  },

	async afterRender() {
		await IuranAnggotaPCPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default IuranAnggotaPC;