
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiRegister from "../../api/data-register.js";
import UrlParser from "../../routes/url-parser.js";
import ApiNotication from "../../api/data-notification.js";
import MembershipHelper from "../../utils/membership-helper.js";

const RekomSIPTTKPresenter = {

	async init() {
		//await MembershipHelper.scyncMembershipHelper();
		await this._setView();
		await this._getInformation();
		await this._getFormulir();
		await this._initProvinceValue();
		await this._getPengajuanSIPTTK();
		await this._uploadBerkas();
		await this._sendPengajuanSIPTTK();
		await this._downloadSIPTTK();
		await this._readNotification();
		await this._updatePengajuanSIPTTK();
	},

	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},

	async _setView() {
		let user = await ApiAnggota.getPrivate();
		if (user.status_code == 200) {
			if (user.data.ktan == "" || user.data.ktan == null || user.data.ktan == undefined) {
				window.location.hash = '#/not-access-fitur';
			} else {
				document.getElementById('main-content').classList.remove('bg-gradient-primary');
				document.getElementById('main-content').style.removeProperty("height");
				document.getElementById('main-content').style.removeProperty("padding-top");
				document.getElementById('content-wrapper').style.removeProperty("overflow-x");
				document.getElementById('appbar').classList.remove('d-none');
				document.getElementById('accordionSidebar').classList.remove('d-none');
				document.getElementById("main-content").style.height = "";
			}
			const eventChangeBerkas= async (y) => {
				let selectTedDom = document.getElementById("selectBerkas")
				let value = selectTedDom.options[selectTedDom.selectedIndex].getAttribute('description-lng')
				if (value == null || value == "") {
					document.getElementById("containerAlertBerkas").classList.add("d-none")
				}else{
					document.getElementById("containerAlertBerkas").classList.remove("d-none")
					document.getElementById("valueAlertBerkas").innerHTML = value
				}
				y.preventDefault();
			}
			document.getElementById("selectBerkas").addEventListener("change", eventChangeBerkas);
		} else {
			window.location.hash = '#/not-access-fitur';
		}
	},

	async _getInformation() {
		$("#information_sipttk").html('Loading ...');
		const getInformation = await ApiAnggota.getInformationSIPTTK();
		if (getInformation.status_code == 404) {
			$("#information_sipttk").html('Tidak ada informasi !');
		} else {
			$("#information_sipttk").html(getInformation.data.description);
		}
	},


	async _getFormulir() {

		$("#textNamaLengkap").val('Loading...');
		$("#textKTAN").val('Loading...');
		$("#textDomisili").val('Loading...');
		$("#textDesa").val('Loading...');
		$("#textKecamatan").val('Loading...');
		$("#textKabupaten").val('Loading...');
		$("#textProvinsi").val('Loading...');
		$("#textNoSTRTTK").val('Loading...');
		$("#textMasaBerlaku").val('Loading...');
		$("#textPengurusDaerah").val('Loading...');
		$("#textPengurusCabang").val('Loading...');
		const getFormulir = await ApiAnggota.getFormulirSIPTTK();
		let data = getFormulir.data;
		let dataAlamat = data.domisili;
		let pendidikan = data.pendidikan;
		let pekerjaan = data.pekerjaan;
		let dataSTRTTK = data.strttk;

		if (getFormulir.status_code == 200) {
			$("#textNamaLengkap").val(data.name);
			$("#textKTAN").val(data.ktan);
			if (dataAlamat == null) {
				$("#textDomisili").val('-');
				$("#textDesa").val('-');
				$("#textKecamatan").val('-');
				$("#textKabupaten").val('-');
				$("#textProvinsi").val('-');
			} else {

				$("#textDomisili").val(dataAlamat.address);
				$("#textDesa").val(dataAlamat.village_name);
				$("#textKecamatan").val(dataAlamat.sub_regency_name);
				$("#textKabupaten").val(dataAlamat.regency_name);
				$("#textProvinsi").val(dataAlamat.province_name);
			}

			if (pendidikan == null) {
				document.getElementById("selectPendidikan").add(new Option('Data Pendidikan anda Kosong !', ''))
			} else {
				pendidikan.forEach(dataPendidikan => {
					document.getElementById("selectPendidikan").add(new Option(dataPendidikan.jenjang + ' - ' + dataPendidikan.nama_institusi_pendidikan, dataPendidikan.id))
				});
			}

			$("#selectPendidikan").change(async function () {

				$("#InstutisiText").val('Loading...')
				$("#textJenjang").val('Loading...')
				$("#textTahun").val('Loading...')

				let id = $(this).val();
				const dataPendidikanByID = await ApiAnggota.getPendidikanByID({ id });
				let dataPendidikan = dataPendidikanByID.data;

				if (dataPendidikanByID.status_code == 200) {
					$("#InstutisiText").val(dataPendidikan.nama_institusi_pendidikan)
					$("#textJenjang").val(dataPendidikan.jenjang)
					$("#textTahun").val(dataPendidikan.tahun_lulus)
				} else {
					$("#InstutisiText").val('')
					$("#textJenjang").val('')
					$("#textTahun").val('')
				}
			});

			if (pekerjaan == null) {
				document.getElementById("selectSarana").add(new Option('Data Pekerjaan anda Kosong !', ''))
			} else {
				pekerjaan.forEach(dataPekerjaan => {
					document.getElementById("selectSarana").add(new Option(dataPekerjaan.nama_tempat_kerja, dataPekerjaan.id))
				});
			}

			$("#selectSarana").change(async function () {

				$("#textJenisTempatKerja").val('Loading...')
				$("#textNamaTempatKerja").val('Loading...')
				$("#TextDariTahun").val('Loading...')
				$("#textNoHPTempatKerja").val('Loading...')
				$("#textEmailTempatKerja").val('Loading...')
				$("#textAlamatTempatKerja").val('Loading...')
				$("#textJabatanKerja").val('Loading...')

				let id = $(this).val();
				const dataPekerjaanByID = await ApiAnggota.getPekerjaanById(id);
				let dataPekerjaan = dataPekerjaanByID.data;

				if (dataPekerjaanByID.status_code == 200) {
					$("#textJenisTempatKerja").val(dataPekerjaan.jenis_tempat_kerja)
					$("#textNamaTempatKerja").val(dataPekerjaan.nama_tempat_kerja)
					$("#TextDariTahun").val(dataPekerjaan.dari_tahun)
					$("#textNoHPTempatKerja").val(dataPekerjaan.phone_number)
					$("#textEmailTempatKerja").val(dataPekerjaan.email)
					$("#textAlamatTempatKerja").val(dataPekerjaan.alamat_tempat_kerja)
					$("#textJabatanKerja").val(dataPekerjaan.jabatan)
				} else {
					$("#textJenisTempatKerja").val('')
					$("#textNamaTempatKerja").val('')
					$("#TextDariTahun").val('')
					$("#textNoHPTempatKerja").val('')
					$("#textEmailTempatKerja").val('')
					$("#textAlamatTempatKerja").val('')
					$("#textJabatanKerja").val('')
				}
			});

			if (dataSTRTTK == null) {
				$("#textNoSTRTTK").val('')
				$("#textMasaBerlaku").val('')
			} else {
				let masaBerlakuSTRTTK = ''
				if (dataSTRTTK.berlaku_sampai == 'lifetime') {
					masaBerlakuSTRTTK = 'Seumur Hidup'
				}else {
					masaBerlakuSTRTTK = dataSTRTTK.berlaku_sampai;
				}
				$("#textNoSTRTTK").val(dataSTRTTK.nomor_strttk);
				$("#textMasaBerlaku").val(masaBerlakuSTRTTK);
			}

			$("#textPengurusDaerah").val(data.daerah_name);
			$("#textPengurusCabang").val(data.cabang_name);
		}
	},

	async _initProvinceValue() {
		const dataProvince = await ApiRegister.getProvince()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectProvinsiTempatKerja").add(new Option(data.province_name, data.province_code))
			});
		}

		const eventChange = async (e) => {
			document.getElementById("selectKabupatenTempatKerja").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectProvinsiTempatKerja").value
			const dataRegistry = await ApiRegister.getRegistry(valueSelected)

			var selectPC = document.getElementById("selectKabupatenTempatKerja");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			document.getElementById("selectKabupatenTempatKerja").add((new Option("Pilih Salah Satu", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectKabupatenTempatKerja").add(new Option(data.regency_name, data.id))
			});

			e.preventDefault();
		}

		document.getElementById("selectProvinsiTempatKerja").addEventListener("change", eventChange);


	},
	async _updatePengajuanSIPTTK() {
		let idPengajuan = '';
		$('#tablePengajuanSIPTTK tbody').on('click', '#editRekomSIPTTK', async function () {

			let table = await $('#tablePengajuanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			idPengajuan = id;

			if (data.status_pc_sarana == 'approve') {
				NotificationModal.show("Anda tidak dapat mengubah data permohonan yang telah di setujui oleh PC sarana", "warning");
			} else {
				$("#formulirSIPTTK").trigger('click');


				$("#hideIdPengajuan").val(idPengajuan);
				$("#selectJenisPermohonan").val(data.jenis_permohonan);
				$("#selectJenisRekomendasi").val(data.jenis_rekomendasi);
				$("#selectPendidikan").val(data.pendidikan.id);
				$("#InstutisiText").val(data.pendidikan.nama_institusi_pendidikan);
				$("#textJenjang").val(data.pendidikan.jenjang);
				$("#textTahun").val(data.pendidikan.tahun_lulus);


				$("#selectSarana").val(data.pekerjaan.id);
				$("#textJenisTempatKerja").val(data.pekerjaan.jenis_tempat_kerja);
				$("#textNamaTempatKerja").val(data.pekerjaan.nama_tempat_kerja);
				$("#textNoHPTempatKerja").val(data.pekerjaan.phone_number);
				$("#textEmailTempatKerja").val(data.pekerjaan.email);
				$("#textAlamatTempatKerja").val(data.pekerjaan.alamat_tempat_kerja);


				const getDetailPengajuanSIPTTK = await ApiAnggota.getPengajuanSIPTTKByID({ id });

				let dataDetail = getDetailPengajuanSIPTTK.data;

				let idSaranaPC = data.regency_sarana_id;
				let idSaranaPD = String(idSaranaPC).substring(0, 2);


				$("#textHariPraktik").val(dataDetail.hari_praktik);
				$("#textJamPraktik").val(dataDetail.jam_praktik);
				$("#textNamaPimpinan").val(dataDetail.nama_pimpinan);
				$("#textJabatanPimpinan").val(dataDetail.jabatan_pimpinan);
				$("#selectInformasiFasilitas").val(dataDetail.informasi_fasilitas);

				document.getElementById("selectKabupatenTempatKerja").add((new Option("Loading Kabupaten / Kota ...", "")))
				const dataRegistry = await ApiRegister.getRegistry(idSaranaPD)

				var selectPC = document.getElementById("selectKabupatenTempatKerja");
				var lengthOptionPc = selectPC.options.length;
				for (let i = lengthOptionPc - 1; i >= 0; i--) {
					selectPC.options[i] = null;
				}

				document.getElementById("selectKabupatenTempatKerja").add((new Option("Pilih Salah Satu", "")))
				dataRegistry.data.forEach(data => {
					document.getElementById("selectKabupatenTempatKerja").add(new Option(data.regency_name, data.id))
				});

				$("#selectKabupatenTempatKerja").val(idSaranaPC);
				$("#selectProvinsiTempatKerja").val(idSaranaPD);
			}




		})
	},
	async _getPengajuanSIPTTK() {
		let table = await $('#tablePengajuanSIPTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_ANGGOTA.GET_SIPTTK}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{
					data: 'created_at'
				},
				{ data: 'jenis_permohonan' },
				{ data: 'jenis_rekomendasi' },
				{ data: 'cabang_name' },
				{ data: 'cabang_sarana_name' },
				{
					data: 'avatar', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.sipttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_sarana', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				}
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [2,3],
					"visible": true,
					"searchable": true,
					className: "text-capitalize"
				},
				{
					"targets": [6, 7, 8, 9, 10,11],
					"visible": true,
					"searchable": true,
					className: "text-center"
				},
				{
					"targets": 12,
					"data": null,
					render : function (data, type, rows) {
						let content =  ``;
						if (data.status_pc_sarana != 'approve') {
							content = `<div style="display:block">
							<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSIPTTK' title='Download Rekomendasi SIPTTK'><i class='fas fa-download'></i> Rekomendasi SIPTTK</button>
							<button style="width:100%;" class='mb-1 btn btn-info btn-sm' id='editRekomSIPTTK' title='Edit Pengajuan Rekom SIPTTK'><i class='fas fa-edit'></i> Edit</button>
							<button style="width:100%;" class='mb-1 btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSIPTTKModal" id='detail' title='Detail'><i class="fas fa-list"></i> Detail</button>
							<button style="width:100%;" class='mb-1 btn btn-danger btn-sm' id='delete' title='Hapus Pengajuan'><i class='fas fa-trash'></i> Hapus</button>
						</div>`
						}else {
							content = `<div style="display:block">
							<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSIPTTK' title='Download Rekomendasi SIPTTK'><i class='fas fa-download'></i> Rekomendasi SIPTTK</button>
							<button style="width:100%;" class='mb-1 btn btn-info btn-sm' id='editRekomSIPTTK' title='Edit Pengajuan Rekom SIPTTK'><i class='fas fa-edit'></i> Edit</button>
							<button style="width:100%;" class='mb-1 btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSIPTTKModal" id='detail' title='Detail'><i class="fas fa-list"></i> Detail</button>
						</div>`
						}

						return content;
					}
				},
			]
		});
		let id = ''
		$('#tablePengajuanSIPTTK tbody').on('click', '#detail', async function () {


			$('#btnSendComment').on('click');
			var selectBerkas = document.getElementById("selectBerkas");
			var lengthOptionBerkas = selectBerkas.options.length;
			for (let i = lengthOptionBerkas - 1; i >= 0; i--) {
				selectBerkas.options[i] = null;
			}
			$("#persyaratanSIPTTK").html('');
			$("#dataPendidikanSIPTTK").html('');
			$("#dataPekerjaan").html('');
			$("#tanggalPengajuan").val('Loading...');
			$("#textKTANmodal").val('Loading...');
			$("#namaAnggota").val('Loading...');
			$("#textEmail").val('Loading...');
			$("#textJenisPermohonan").val('Loading...');
			let data = table.row($(this).parents('tr')).data();
			id = data.id;
			let regencySaranaID = '';






			let table_tracking = await $("#tableHistoryVerifikasiSIPTTK").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT_ANGGOTA.GET_TRACKING_SIPTTK(id)}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{
						"data": null,
						"class": "align-top",
						"searchable": false,
						"render": function (data, type, row, meta) {
							return meta.row + meta.settings._iDisplayStart + 1;
						}
					},
					{ data: 'created_at' },
					{ data: 'actor.name' },
					{ data: 'actor_type' },
					{ data: 'description' },
				],

				"order": [[0, 'desc']],
			});

			$("#textHIddenSIPTTK").val(id);



			const getDetailPengajuanSIPTTK = await ApiAnggota.getPengajuanSIPTTKByID({ id });

			let dataDetail = getDetailPengajuanSIPTTK.data;
			if (getDetailPengajuanSIPTTK.status_code == 200) {


				let tanggalPengajuan = dataDetail.created_at;


				regencySaranaID = dataDetail.regency_sarana_id;
				const getPerysaratanSIPTTKByPCID = await ApiAnggota.getPersyaratanSIPTTKByPCID({ regencySaranaID });

				$("#tanggalPengajuan").val(tanggalPengajuan);
				$("#namaAnggota").val(dataDetail.user.name);
				$("#textKTANmodal").val(dataDetail.user.ktan);
				$("#textEmail").val(dataDetail.user.email);
				$("#textJenisPermohonan").val(data.jenis_permohonan);
				$("#textPerpanjangDengan").val(dataDetail.perpanjang_dengan);
				$("#textStatus").val(dataDetail.status);


				document.getElementById("selectBerkas").add(new Option('Pilih Salah Satu', ''))
				getPerysaratanSIPTTKByPCID.data.forEach(dataBerkas => {
					let option= new Option(dataBerkas.name, dataBerkas.document_name)
					option.setAttribute("description-lng",dataBerkas.description)
					document.getElementById("selectBerkas").add(option)
				});

				var dokumen = dataDetail.dokumen
				dokumen.forEach(dataBerkas => {
					var persyaratanSIPTTK = `
						<tr>
							<td>${dataBerkas.name}</td>
							<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
						</tr>
					`

					$("#persyaratanSIPTTK").append(persyaratanSIPTTK);
				});
				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="5" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
						</tr>
					`
				}

				$("#dataPendidikanSIPTTK").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td colspan="10" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<td>${dokumenPekerjaan.nama_tempat_kerja}</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
							<td>${dokumenPekerjaan.phone_number}</td>
							<td>${dokumenPekerjaan.email}</td>
							<td>${dokumenPekerjaan.jabatan}</td>
							<td>${dokumenPekerjaan.jenis_tempat_kerja}</td>
							<td>${dokumenPekerjaan.dari_tahun}</td>
							<td>${dokumenPekerjaan.sampai_tahun}</td>
						</tr>
					`
				}

				$("#dataPekerjaan").append(pekerjaan);


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ${user.actor.province_name}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let local = localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI);
				let dataLocal = JSON.parse(local);
				let UserID = dataLocal[0].id;
				let response = await ApiAnggota.getCommentar( id, "rekom_sipttk", )
				let dataComment = response.data;
				dataComment.reverse();
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}


				$("#btnSendComment").on('click', async function () {
					if (UserID != '' && id != '') {
						document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentar").value
						const response = await ApiAnggota.sendCommentar({
							pengajuanID: id,
							contentComment: content,
							commentFor: 'rekom_sipttk',
						});
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
									<div style="width:100%">
										<div style="width:10%;" class="float-left">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-left text-dark text-left" style="width:88%;">
											<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ${user.actor.province_name}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
									<div style="width:100%">
										<div style="width:10%;" class="float-right">
											<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
										</div>
										<div class="float-right text-dark text-left" style="width:88%;">
											<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
												<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
												<small>${user.body}</small><br>
												<div class="text-right">
													<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
												</div>
											</div>
										</div>
									</div>
									`
							}


							let response = await ApiAnggota.getCommentar(id,"rekom_sipttk")
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-comment").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-comment');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						} else {
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						}


					}


					// $(this).off('click');

				});


				$('#detailSIPTTKModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendComment').off('click');
				});
			}


		});
		$('#tablePengajuanSIPTTK tbody').on('click', '#delete', async function () {
			const table = $('#tablePengajuanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Tanggal Pengajuan </strong>: " + data.created_at,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idPendidikan = data.id;

					const deletePengajuanSIPTTK = await ApiAnggota.deletePengajuanSIPTTK({
						id: idPendidikan
					});

					if (deletePengajuanSIPTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePengajuanSIPTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deletePengajuanSIPTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});


	},

	async _uploadBerkas() {
		let DzoneBerkasSIPTTK = new Dropzone("#form-upload-sipttk", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-sipttk .dz-preview:hidden').remove()
					$('#form-upload-sipttk .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-sipttk").classList.remove('dz-started');
				});
			}
		});

		const eventUploadBerkas = async (e) => {
			e.preventDefault();
			$("#persyaratanSIPTTK").html('<td colspan="2">Loading ...</td>');
			$("#persyaratanSIPTTK").html('');
			let id = $("#textHIddenSIPTTK").val();
			document.getElementById("btnUpdateBerkas").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const uploadBerkasSIPTTK = await ApiAnggota.uploadBerkasSIPTTK({
				id: id,
				document_name: document.getElementById('selectBerkas').value,
				dokumen: (DzoneBerkasSIPTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneBerkasSIPTTK.files[0].dataURL, DzoneBerkasSIPTTK.files[0].name)
			});
			if (uploadBerkasSIPTTK.status_code != 200) {
				NotificationModal.show(`${uploadBerkasSIPTTK.message}`, 'error');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			} else {
				NotificationModal.show(`${uploadBerkasSIPTTK.message}`, 'success');
				document.getElementById("btnUpdateBerkas").innerHTML = `<i class='fas fa-edit'></i> Kirim & Update Berkas`;
			}

			const getDetailPengajuanSerkom = await ApiAnggota.getPengajuanSIPTTKByID({ id });

			let dataDetail = getDetailPengajuanSerkom.data;
			if (getDetailPengajuanSerkom.status_code == 200) {

				var dokumen = dataDetail.dokumen
				dokumen.forEach(dataBerkas => {
					var persyaratanSIPTTK = `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					$("#persyaratanSIPTTK").append(persyaratanSIPTTK);
				});

			}
			$("#selectBerkas").val("");
			DzoneBerkasSIPTTK.emit("removeThumbnailCustom");
			e.preventDefault();
		}
		document.getElementById('btnUpdateBerkas').addEventListener('click', eventUploadBerkas);
	},

	async _downloadSIPTTK() {
		$('#tablePengajuanSIPTTK tbody').on('click', '#rekomendasiSIPTTK', async function () {
			$(this).html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
			let table = $('#tablePengajuanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let name = data.name

			if (data.status_pc_sarana != "approve") {
				swal.fire({
					title: 'Warning !',
					html: "Status Permohonan : <strong><u>" + data.status_pc_sarana + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PC setempat",
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
			} else {
				if (data.jenis_rekomendasi == '' || data.jenis_rekomendasi == null || data.jenis_rekomendasi == 'penerbitan') {
					const downloadSKSIPTTK = await ApiAnggota.downloadSKSIPTTK({
						id: id,
						name: name
					})
				}else {
					const downloadSKSIPTTK = await ApiAnggota.downloadSKSIPTTKPencabutan({
						id: id,
						name: name
					})
				}
			}

			$(this).html('<i class="fas fa-download"></i> Rekomendasi SIPTTK');


		});
	},

	async _sendPengajuanSIPTTK() {

		const eventPengajuanSIPTTK = async (e) => {
			e.preventDefault();
			document.getElementById("btnKirimPengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`;
			var idPengajuan = $("#hideIdPengajuan").val();
			var jenisPermohonan = $("#selectJenisPermohonan").val();
			var jenisRekomendasi = $("#selectJenisRekomendasi").val();
			var idPendidikan = $("#selectPendidikan").val();
			var idPekerjaan = $("#selectSarana").val();
			var noSTRTTK = $("#textNoSTRTTK").val();
			var masaBerlaku = $("#textMasaBerlaku").val();
			if (masaBerlaku == 'Seumur Hidup') {
				masaBerlaku = 'lifetime'
			}else {
				masaBerlaku == masaBerlaku
			}
			var provinceSaranaID = $("#selectProvinsiTempatKerja").val();
			var regencySaranaID = $("#selectKabupatenTempatKerja").val();
			var telpSarana = $("#textNoHPTempatKerja").val();
			var emailSarana = $("#textEmailTempatKerja").val();
			var hariPraktik = $("#textHariPraktik").val();
			var jamPraktik = $("#textJamPraktik").val();
			var namaPimpinan = $("#textNamaPimpinan").val();
			var jabatanPimpinan = $("#textJabatanPimpinan").val();
			var informasiFasilitas = $("#selectInformasiFasilitas").val();
			if (idPengajuan == '' || idPengajuan == null) {
				const sendPengajuanSIPTTK = await ApiAnggota.sendPengajuanSIPTTK({
					jenis_permohonan: jenisPermohonan,
					jenis_rekomendasi: jenisRekomendasi,
					pendidikan_id: idPendidikan,
					pekerjaan_id: idPekerjaan,
					nomor_strttk: noSTRTTK,
					masa_berlaku: masaBerlaku,
					province_sarana_id: provinceSaranaID,
					regency_sarana_id: regencySaranaID,
					telp_sarana: telpSarana,
					email_sarana: emailSarana,
					hari_praktik: hariPraktik,
					jam_praktik: jamPraktik,
					nama_pimpinan: namaPimpinan,
					jabatan_pimpinan: jabatanPimpinan,
					informasi_fasilitas: informasiFasilitas
				});
				if (sendPengajuanSIPTTK.status_code != 201) {
					NotificationModal.show(`${sendPengajuanSIPTTK.message}`, 'error');
					document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
					$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
				} else {
					NotificationModal.show(`${sendPengajuanSIPTTK.message}`, 'success');
					document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
					$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
					document.getElementById("form-data-sipttk").reset();
				}
			} else {
				const updatePengajuanSIPTTK = await ApiAnggota.updatePengajuanSIPTTK({
					idPengajuan: idPengajuan,
					jenis_permohonan: jenisPermohonan,
					jenis_rekomendasi: jenisRekomendasi,
					pendidikan_id: idPendidikan,
					pekerjaan_id: idPekerjaan,
					nomor_strttk: noSTRTTK,
					masa_berlaku: masaBerlaku,
					province_sarana_id: provinceSaranaID,
					regency_sarana_id: regencySaranaID,
					telp_sarana: telpSarana,
					email_sarana: emailSarana,
					hari_praktik: hariPraktik,
					jam_praktik: jamPraktik,
					nama_pimpinan: namaPimpinan,
					jabatan_pimpinan: jabatanPimpinan,
					informasi_fasilitas: informasiFasilitas
				})
				if (updatePengajuanSIPTTK.status_code != 200) {
					NotificationModal.show(updatePengajuanSIPTTK.message, "warning")
					$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
					document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				} else {
					NotificationModal.show(updatePengajuanSIPTTK.message, "success")
					$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
					document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-share"></i> Kirim Pengajuan`;
				}
			}


			e.preventDefault();
		}

		const eventResetFormulir = async (e) => {
			document.getElementById("form-data-sipttk").reset();
			await this._getFormulir();
		}

		document.getElementById('form-data-sipttk').addEventListener('submit', eventPengajuanSIPTTK);
		document.getElementById('btnClearPengajuanSIPTTK').addEventListener('click', eventResetFormulir);

	},



	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	_navigateToLogin() {
		window.location.hash = '#/login';
	},


}

export default RekomSIPTTKPresenter;