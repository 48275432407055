import ResendPaymentPresenter from "../../presenter/resend-payment-presenter.js";

const ResendPayment = {
    async render() {
            return `<div class="container">
            <!-- Outer Row -->
            <div class="row justify-content-center">
      
                <div class="col-xl-10 col-lg-12 col-md-12">
      
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <!-- Nested Row within Card Body -->
                            <div class="row">
                                <div class="col-lg-5 p-3 d-lg-block ">
                                    <div class="text-center">
                                        <img loading="lazy" src="./src/public/img/logo.png" width="30%">
                                    </div>
                                    <div class="text-left">
                                        <h1 class="h4 text-center text-gray-900 mb-4" style ="margin-top:30px;">Catatan Penting !</h1>
                                        <ol>
                                            <li>Isi alamat menggunakan alamat email saat anda mendaftar.</li>
                                            <li><b>Silakan untuk mengkosongkan / menyisakan penyimpanan email Anda</b></li>
                                            <li>Link akan kami kirimkan melalui email, Cek kotak masuk, termasuk Folder SPAM</li>
                                            <li>Ikuti langkah yang kami berikan sesuai instruksi pada email yang anda terima</li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h4 text-gray-900 mb-4">Kirim Kembali Administrasi Pendaftaran</h1>
                                            <p>Jika ada kesulitan bisa kontak kami di<br>
                                            Kontak Telp/WA: +62 822-2046-7605<br>
                                            Email: helpdesk.pafi@gmail.com</p>
                                        </div>
                                        <div class="alert alert-info">
                                            <strong>Informasi : </strong> Halaman ini hanya digunakan jika anggota baru pada saat registrasi tidak mendapatkan email pembayaran registrasi. Terimakasih 
                                        </div>
                                       
                                        <form class="user" class="form-horizontal" id='reset-password'>
                                            <div class="form-group">
                                                <input type="email" name="email" class="form-control form-control-user" required id="textEmail" aria-describedby="email" placeholder="Masukkan Email...">
                                            </div>
                                            <hr>
      
                                            <button id="btnSendPayment" type="submit" class="btn btn-primary btn-user btn-block">Kirim Ulang Administrasi Pendaftaran</button>
                                            <a id="btnLogin" class="btn btn-google btn-user btn-block">Kembali Ke Login</a>
                                            <hr>
                                        </form>
                                            
                                        
                                        <hr>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
      
            </div>
      
        </div>`
      
   },
  
    async afterRender() {
       await ResendPaymentPresenter.init()
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  
  export default ResendPayment;
  

