import NotificationInitiator from "../../presenter/notification-presenter.js";

const NotificationPage = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					    <button href="#tabAssignRole" class="nav-link hapus-border active" id="data-verifikasi-anggota" data-bs-toggle="tab" data-bs-target="#tabAssignRole" data-toggle="tab" type="button" role="tab" aria-controls="tabVerifikasiKTAN" aria-selected="true">Notifikasi</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabAssignRole" role="tabpanel" aria-labelledby="tab-verifikasi-ktan">
						<div class="row mt-3">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-header text-primary font-weight-bold">
                                        Notifikasi Anggota
                                    </div>
                                    <div class="card-body">
                                    <div class="col-lg-12" >
                                    <div class="container-table-notif" style="height:500px; box-shadow:0px 3px 7px #bbbbbb26;  overflow-y:auto">
                                    <table class="table table-bordered table-hover" id="tableDataMutasi" width="100%" cellspacing="0" >
                                        <thead>
                                            <tr>
                                            <th>Tanggal</th>
                                            <th>Judul</th>
                                            <th>Pesan</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody id="content-notification-table">
                                           
                                        </tbody>
                                        </table>
                                        </div>
                                        <button type="button" id="btnReadAllNotif" class="btn btn-primary" style="margin-top:30px;">
                                        <i class="fa fa-eye" aria-hidden="true"></i> Tandai Semua Sebagai Terbaca
                                      </button>
                                    </div>
                                </div>
                            </div>
						</div>
                    </div>
				</div>
			</div>
		</div>
        `;
		return view;
	  },

	async afterRender() {
        await NotificationInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default NotificationPage;