
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiPengajuanKTANPC {
    static async aprroveKTAN (
        data,
    ){
		
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": data.status,
            "catatan": data.comment,
          });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_KTAN(data.id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	static async approveRegisOld (
        data,
    ){
		
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": data.approval,
            "reason": data.reason,
          });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_REGIS_OLD(data.id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	static async getCommentar(
		userID,
		commentFor,
		pengajuanID,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_COMMENT_PENGAJUAN(userID,commentFor,pengajuanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	
	
	static async sendCommentar({
		userID,
		pengajuanID,
		commentFor,
		contentComment,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"user_id": `${userID}`,
			"pengajuan_id": `${pengajuanID}`,
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.SEND_COMMENT,{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async approveAllKTAN (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "pengajuan_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_All_KTAN,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async approveAllRegis (
        data,
    ){
        let myHeaders =  new Headers();
		let dataList= data.map(a => a.user_uuid);
		console.log(dataList)
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "user_uuid": dataList,
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.APPROVE_All_REGIS,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async getAnggotaByID(uuid){
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_ANGGOTA_BY_ID(uuid), {
					method: 'GET',
					headers: myHeaders
			})
			
				const responseJson = await response.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			} catch (err) {
				console.log(err)
				return {};
			}
	}

	static async deleteAnggota({
		type,
		uuid,
		reason
	}){
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			myHeaders.append("Content-Type", "application/json");
			
			var raw = JSON.stringify({
				"reason": reason
			});

			if (type == "soft") {
				const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.GET_ANGGOTA_BY_ID(uuid), {
						method: 'DELETE',
						headers: myHeaders,
						body: raw
				})
				const responseJson = await response.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}else {
				const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.FORCE_DELETE(uuid), {
						method: 'DELETE',
						headers: myHeaders,
						body: raw
				})
				const responseJson = await response.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			}

			
			} catch (err) {
				console.log(err)
				return {};
			}
	}

	static async createAnggotaManual ({
		name,
		email,
		nik,
		ktan,
		masa_berlaku_ktan,
		bulan_iuran_awal,
		bulan_iuran_akhir,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "name": name,
			"email": email,
			"nik": nik,
			"ktan": ktan,
			"ktan_expires_at": masa_berlaku_ktan,
			"bulan_iuran_awal": bulan_iuran_awal,
			"bulan_iuran_akhir": bulan_iuran_akhir,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.CREATE_ANGGOTA,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async sendIuran ({
		uuid,
		jatuh_tempo
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		var raw = JSON.stringify({
            "jatuh_tempo": jatuh_tempo
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PC.SEND_IURAN_BY_ID(uuid),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
}


export default ApiPengajuanKTANPC;
