import SKPSayaPresenter from "../../../presenter/menu_anggota/skp-saya-presenter.js";
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';




const SKPSaya = {
	async render() {
		const view = `
		<!-- DataTales Example -->
		<div class="card shadow mb-4">
			<div class="card-body">
				<ul class="nav nav-tabs" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
					<button href="#tabDataSKP" class="nav-link hapus-border active" id="data-skp-tab" data-bs-toggle="tab" data-bs-target="#tabDataSKP" data-toggle="tab" type="button" role="tab" aria-controls="tabDataSKP" aria-selected="true">Formulir Data SKP</button>
					</li>
					<li class="nav-item" role="presentation">
					<button href="#tabSKPSaya" class="nav-link hapus-border" id="skp-saya-tab" data-bs-toggle="tab" data-bs-target="#tabSKPSaya" data-toggle="tab" type="button" role="tab" aria-controls="tabSKPSaya" aria-selected="false">SKP Saya</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="tabDataSKP" role="tabpanel" aria-labelledby="data-skp-tab">
                        <div class="card  mt-2">
                            <div class="card-header font-weight-bold text-primary">
                                Formulir SKP
                            </div>
                            <div class="card-body">
                            <form id="form-skp">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Tanggal Mulai (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="hidden" id="textHIddenSKP" class="form-control">
                                                <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="dateTanggalMulaiSKP" class="form-control">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Tanggal Selesai (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="dateTanggalSelesaiSKP" class="form-control">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Nama Kegiatan (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="text" id="textNamaKegiatanSKP" class="form-control" placeholder="Nama Kegiatan...">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Nomor Sertifikat (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="text" id="textNomorSertifikatSKP" class="form-control" placeholder="Nomor Sertifikat...">
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Kategori (Wajib)</label>
                                            <div class="col-sm-12">
                                                <select class="form-control" id="selectKategoriSKP">
                                                    <option value="">Pilih Salah Satu</option>
                                                    <option value="baksos">Baksos / Pengabdian Masyarakat</option>
                                                    <option value="masa_kerja">Masa Kerja</option>
                                                    <option value="pendidikan">Pembelajaran</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Jenis Kegiatan (Wajib)</label>
                                            <div class="col-sm-12">
                                                <select class="form-control" id="selectJenisKegiatanSKP">
                                                    <option value="">Pilih Salah Satu</option>
                                                    <option value="Penyelenggara PP dengan SK PP">Penyelenggara PP dengan SK PP</option>
                                                    <option value="Penyelenggara PD dengan SK PP">Penyelenggara PD dengan SK PP</option>
                                                    <option value="Penyelenggara PC dengan SK PP">Penyelenggara PC dengan SK PP</option>
                                                    <option value="Penyelenggara Kampus dengan SK PP">Penyelenggara Kampus dengan SK PP</option>
                                                    <option value="Penyelenggara PD dengan SK SKP PD">Penyelenggara PD dengan SK SKP PD</option>
                                                    <option value="Penyelenggara PC dengan SK SKP PD">Penyelenggara PC dengan SK SKP PD</option>
                                                    <option value="Seminar Diluar SK PP PAFI Khusus Kefarmasian">Seminar Diluar SK PP PAFI Khusus Kefarmasian</option>
                                                    <option value="Seminar Diluar SK PP PAFI Umum Kesehatan">Seminar Diluar SK PP PAFI Umum Kesehatan</option>
                                                    <option value="Kegiatan Pengabdian / Bhakti Profesi (Dengan SK, SK PP, SK PD), Karya Ilmiah, Dll">Kegiatan Pengabdian / Bhakti Profesi (Dengan SK, SK PP, SK PD), Karya Ilmiah, Dll</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Berlaku Sampai (Opsional)</label>
                                            <div class="col-sm-12">
                                                <input type="date"  pattern="\d{2}-\d{2}-\d{4}" id="textBerlakuSampai" class="form-control" placeholder="Berlaku Sampai...">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Nomor Surat Keputusan (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="text" id="textNomorSuratKeputusan" class="form-control" placeholder="Nomor Surat Keputusan...">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Tempat Penyelenggaraan (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="text" id="textTempatPenyelenggaraan" class="form-control" placeholder="Tempat Penyelenggaraan...">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Berperan Sebagai (Wajib)</label>
                                            <div class="card ml-2 mb-2 border-left-primary col-sm-11 d-none"  id="containerEditKontrib">
                                                <div class="mt-2 mb-2">
                                                <h6 class="text-lowercase" id="kontribPesertaValue"> Kontribusi Peserta Anda adalah ""</h5>
                                                <h6 class="text-lowercase"> *Ubah dan mohon sesuaikan pengisiannya dengan memilih isi dari piihan dibawah.</h6>
                                            </div>
                                        </div>
                                            <div class="col-sm-12">
                                            <select class="form-control" id="textKontribusiPeserta">
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="peserta">Peserta</option>
                                                <option value="panitia">Panitia</option>
                                                <option value="moderator">Moderator</option>
                                                <option value="pemateri">Pemateri</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">SKP (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="number" step="0.1" id="numberSKP" class="form-control" placeholder="SKP..." min="0">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Ketua Kegiatan (Wajib)</label>
                                            <div class="col-sm-12">
                                                <input type="text" id="textKetuaKegiatan" class="form-control" placeholder="Ketua Kegiatan...">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Informasi (Opsional)</label>
                                            <div class="col-sm-12">
                                                <textarea rows="5" class="form-control" id="textInformasi" placeholder="Informasi silahkan isi dengan Nama Panitia/Penyelenggara/Jumlah Peserta atau semacamnya..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Scan Sertifikat (Wajib)</label>
                                        <div id="form-upload-sertifikat" class="col-sm-12 dropzone">
                                            <input type="hidden" name="file" style=""/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Bukti Kegiatan (Opsional)</label>
                                        <div id="form-upload-selfie" class="col-sm-12 dropzone">
                                            <input type="hidden" name="file" style=""/>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-group row">
                                            <label class="control-label col-sm-12 font-weight-bold">Resume Kegiatan (Opsional)</label>
                                            <div class="col-sm-12">
                                                <textarea rows="5" class="form-control" id="textResume" placeholder="Resume Kegiatan..."></textarea>
                                            </div>
                                        </div>
                                        <br>
                                        <button class="form-control mb-2 btn btn-sm btn-primary" id="btnAddSKP"><i class='fas fa-plus'></i> Tambah SKP</button>
                                        <button class="form-control mb-2 btn btn-sm btn-primary" id="btnUpdateSKP"><i class='fas fa-edit'></i> Edit SKP</button>
                                        <button type="button" class="form-control btn btn-sm btn-secondary" id="btnClearSKP"><i class='fas fa-eraser'></i> Kosongkan</button>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
					</div>

					<div class="tab-pane fade" id="tabSKPSaya" role="tabpanel" aria-labelledby="skp-saya-tab">
                        <div class="alert alert-info mt-3">
                            <strong>Informasi :</strong>
                            <ol>
                                <li>Anda tidak dapat mengubah permohonan SKP yang sudah di setujui oleh PC maupun PD</li>
                                <li>SKP yang akan di akumulasikan adalah SKP yang masih berlaku dan sudah di setujui oleh PD</li>
                            </ol>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 col-md-6 mb-4 mt-3">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                    Pengabidan Masyarakat (Max 2 SKP)</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800" id="jmlSKPBaksos"><div class="shimmer title-line end"></div></div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-thumbs-up fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-xl-3 col-md-6 mb-4 mt-3">
                                <div class="card border-left-success shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                    Masa Kerja (Max 5 SKP)</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800" id="jmlSKPMasaKerja"><div class="shimmer title-line end"></div></div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-clock fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-xl-3 col-md-6 mb-4 mt-3">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                                    Pembelajaran (Min 18 SKP)</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800" id="jmlSKPPendidikkan"><div class="shimmer title-line end"></div></div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-graduation-cap fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="col-xl-3 col-md-6 mb-4 mt-3">
                                <div class="card border-left-danger shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                                    Jumlah (Min 25 SKP)</div>
                                                <div class="h5 mb-0 font-weight-bold text-gray-800" id="jmlSKP"><div class="shimmer title-line end"></div></div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-list fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="card mb-3">
                            <div class="card-header font-weight-bold text-primary">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <label class="control-label font-weight-bold">Status SKP</label>
                                        <select id="selectStatus" class="form-control">
                                            <option value="">Semua</option>
                                            <option value="active">Aktif</option>
                                            <option value="non_active">Tidak Aktif</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-12 mt-3">
                                        <button class="btn btn-primary" id="btnFilter"><i class="fas fa-filter"></i> Filter Data</button>
                                        <button class="btn btn-warning" id="btnResetFilter"><i class="fas fa-undo"></i> Reset Filter</button>
                                        <button class="btn btn-info" id="btnRefreshData"><i class="fas fa-sync-alt"></i> Refresh Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>

						<div class="card">
							<div class="card-header font-weight-bold text-primary py-3 d-flex flex-row align-items-center justify-content-between">
								Data Pengajuan SKP
							</div>
							<div class="card-body ">
                            <button class="btn btn-outline-primary d-none" id="btnDownloadBorang"><i class="fas fa-download"></i> Download Borang SKP</button>
								<div class="table-responsive">
									<table class="table table-bordered table-hover" id="tableSKPSaya" width="100%" cellspacing="0">
										<thead>
											<tr>
                                                <th data-column="nama_kegiatan">Nama Kegiatan</th>
												<th data-column="kategori">Kategori</th>
												<th  data-column="tanggal_mulai">Tanggal Mulai</th>
												<th  data-column="tanggal_selesai">Tanggal Selesai</th>
												<th  data-column="berlaku_sampai">Berlaku Sampai</th>
												<th  data-column="jenis_kegiatan">Jenis Kegiatan</th>
												<th  data-column="nomor_sertifikat">Nomor Sertifikat</th>
												<th  data-column="nomor_surat_keputusan">Nomor Surat Keputusan</th>
												<th  data-column="tempat_penyelenggaraan">Tempat Penyelenggaraan</th>
												<th  data-column="kontribusi_peserta">Kontribusi Peserta</th>
												<th  data-column="ketua_kegiatan">Ketua Kegiatan</th>
												<th  data-column="informasi">Informasi</th>
												<th  data-column="skp" >SKP</th>
												<th  data-column="skp_approve_pc">ACC PC</th>
												<th  data-column="skp_approve_pd">ACC PD</th>
												<th  data-column="kategori">Sertifikat</th>
												<th  data-column="kategori">Bukti Kegiatan</th>
												<th  data-column="status_pc" >STATUS PC</th>
												<th  data-column="status_pd">STATUS PD</th>
												<th style="width:250px">Aksi</th>
											</tr>
										</thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer circle"></div></td>
                                                <td><div class="shimmer title-line end"></div></td>
                                            </tr>
                                        </tbody>
										<tfoot>
											
										</tfoot>
										<tbody>
										
										
										
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" id="tabPekerjaan" role="tabpanel" aria-labelledby="pekerjaan-tab">Pekerjaan</div>
					<div class="tab-pane fade" id="tabKarirOrganisasi" role="tabpanel" aria-labelledby="karir-organisasi-tab">Organisasi</div>
				</div>
			</div>
		</div>
        
        
	<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>

    <div class="modal fade" id="commentModalSKP" tabindex="-1" role="dialog" aria-labelledby="commentModal"
            aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-primary font-weight-bold">
                    Komentar Pengajuan SKP
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                            </div>
                            <form id="form-comment-user-ktan">
                                <label class="control-label font-weight-bold">Komentar</label>
                                <textarea class="form-control" id="komentarPengajuanSKP" name="komentar" placeholder="Komentar..." required></textarea>
                                <br>
                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanSKP"><i class='fas fa-comment'></i> Berikan Komentar</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalViewResume" tabindex="-1" role="dialog" aria-labelledby="modalViewResume"
    aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <strong><h5 class="modal-title">Detail Resume Kegiatan</h5></strong>
                </div>
                <div class="modal-header">
                    <strong>Judul Kegiatan : <span class="modal-title" id="titleSKP"></span></strong>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="resumeSKP">
                
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancelApporve" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

        `;
		return view;
	  },

	async afterRender() {
		await SKPSayaPresenter.init()
		await ImagePopUPInitiator.init()
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default SKPSaya;