
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiPengajuanSIPTTKPC from '../../api/api_pengurus_pc/pengajuan-sipttk.js';
import ApiAnggota from "../../api/data-anggota.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"


const SIPTTKPC = {

	async init() {
		await this._setView();
		await this._getInformationSIPTTK();
		await this.createSyaratSIPTTK();
		await this.createInformationSIPTTK();
		await this._getPengajuanSIPTTKInFiltered();
	
		await this._approveSIPTTK();

		const theEvent = async (e) => {
			mRefresh.refresh();
			await this._getPengajuanSIPTTKOutFiltered();
			mRefresh.resolve();
		}

		$("#tab2").one("click", async function () {
			await theEvent();
		});

		const theEventTab3 = async (e) => {
			mRefresh.refresh();	
			await this._getPersyaratan();
			mRefresh.resolve();
		}

		$("#tab3").one("click", async function () {
			await theEventTab3();
		});
	},


	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSyaratSIPTTK").hide();
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }

	},
	async _getPengajuanSIPTTKInFiltered(){
		let status = document.getElementById('selectStatus').value;
		let startDate = document.getElementById('startDate').value;
		let endDate = document.getElementById('endDate').value;
		await this._getPengajuanSIPTTKIn(status, startDate, endDate);

		const eventFiltered = async () => {
			let status = document.getElementById('selectStatus').value;
			let startDate = document.getElementById('startDate').value;
			let endDate = document.getElementById('endDate').value;
			await this._getPengajuanSIPTTKIn(status, startDate, endDate);
		}

		const eventResetFilter = async () => {
			let status = document.getElementById('selectStatus').value = 'pending';
			let startDate = document.getElementById('startDate').value = '';
			let endDate = document.getElementById('endDate').value = '';
			await this._getPengajuanSIPTTKIn(status, startDate, endDate);
		}

		document.getElementById('btnFilter').addEventListener('click', eventFiltered);
		document.getElementById('btnRefresh').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilter').addEventListener('click', eventResetFilter);
	},

	
	async _getPengajuanSIPTTKOutFiltered(){
		let status = document.getElementById('selectStatusOut').value;
		let startDateOut = document.getElementById('startDateOut').value;
		let endDateOut = document.getElementById('endDateOut').value;
		await this._getPengajuanSIPTTKOut(status, startDateOut, endDateOut);

		const eventFiltered = async () => {
			let status = document.getElementById('selectStatusOut').value;
			let startDateOut = document.getElementById('startDateOut').value;
			let endDateOut = document.getElementById('endDateOut').value;
			await this._getPengajuanSIPTTKOut(status, startDateOut, endDateOut);
		}

		const eventResetFilter = async () => {
			let status = document.getElementById('selectStatusOut').value = 'pending';
			let startDateOut = document.getElementById('startDateOut').value = '';
			let endDateOut = document.getElementById('endDateOut').value = '';
			await this._getPengajuanSIPTTKOut(status, startDateOut, endDateOut);
		}

		document.getElementById('btnFilterOut').addEventListener('click', eventFiltered);
		document.getElementById('btnRefreshOut').addEventListener('click', eventFiltered);
		document.getElementById('btnResetFilterOut').addEventListener('click', eventResetFilter);
	},

	async _getPengajuanSIPTTKIn(status, startDate, endDate) {
		var statusSarana = '';
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';

		let table = await $('#tablePengajuanSIPTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.SIPTTK_IN(status, startDate, endDate)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{
					data: 'created_at'
				},
				{ data: 'jenis_permohonan' },
				{ data: 'jenis_rekomendasi', render : function (data) {
					if (data == null || data == '') {
						return "Penerbitan"
					}else {
						return data
					}
				} },
				{ data: 'cabang_name' },
				{ data: 'cabang_sarana_name' },
				{
					data: 'avatar', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.sipttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_sarana', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				}
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [4,5,6,7,8,9],
					"orderable": false,
				},
				{
					"targets": [2,3],
					"visible": true,
					"searchable": true,
					className: "text-capitalize"
				},
				{
					"targets": 12,
					"data": null,
					"defaultContent": `
					<div style="display:block">
						<button style="width:100%;" data-toggle="modal" data-target="#detailSIPTTKModalIn" class='mb-1 btn btn-success btn-sm' id='approve' title='Approve'><i class='fas fa-check-circle'></i> Proses</button>
						<button style="width:100%;" class='mb-1 btn btn-primary btn-sm' id='rekomendasiSIPTTK' title='Download Rekomendasi SIPTTK'><i class='fas fa-download'></i> Rekomendasi SIPTTK</button>
						<!--<button style="width:100%;" class='mb-1 btn btn-light border btn-sm' id='suratPernyataan' title='Download Surat Pernyataan'><i class='fas fa-download'></i> Surat Pernyataan</button>
						<button style="width:100%;" class='mb-1 btn btn-light border btn-sm' id='permohonanSurat' title='Download Permohonan Surat'><i class='fas fa-download'></i> Permohonan Surat</button>
						<button style="width:100%;" class='mb-1 btn btn-light border btn-sm' id='keterangan' title='Download Surat Keterangan'><i class='fas fa-download'></i> Surat Keterangan</button>-->
					</div>`
				},
			]
		});

		
		$('#tablePengajuanSIPTTK tbody').off('click').on('click', '#approve', async function () {

			$("#persyaratanSIPTTKIn").html('');
			$("#dataPendidikanSIPTTKIn").html('');
			$("#dataPekerjaanIn").html('');

			$("#tanggalSuratRekomendasiIn").val('Loading...');
			$("#textJenisPermohonanIn").val('Loading...');


			$("#textNamaAnggotaIn").val('Loading...');
			$("#textEmailin").val('Loading...');
			$("#textKTANIn").val('Loading...');
			$("#textAlamatDomisiliIn").val('Loading...');
			$("#textDesaIn").val('Loading...');
			$("#textKecamatanIn").val('Loading...');
			$("#textKabupatenIn").val('Loading...');
			$("#textProvinsiIn").val('Loading...');


			let table = $('#tablePengajuanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let UserID = data.user_id;

			$("#textHiddenIn").val(id);

			const getDetailPenagjuanSIPTTK = await ApiAnggota.getPengajuanSIPTTKByID({ id });



			let dataDetail = getDetailPenagjuanSIPTTK.data;

			if (getDetailPenagjuanSIPTTK.status_code == 200) {
				let timePengajuan = dataDetail.created_at;
				let timeSurat = new Date().toLocaleDateString('en-US');
				let jenisPermohonan = dataDetail.jenis_permohonan;
				let domisili = dataDetail.domisili;

				$("#tanggalSuratRekomendasiIn").val(timeSurat);
				$("#textKTANIn").val(dataDetail.user.ktan);
				$("#textNamaAnggotaIn").val(dataDetail.user.name);
				$("#textEmailIn").val(dataDetail.user.email);
				$("#textJenisPermohonanIn").val(jenisPermohonan);

				if (domisili) {
					$("#textAlamatDomisiliIn").val(domisili.address);
					$("#textDesaIn").val(domisili.village_name);
					$("#textKecamatanIn").val(domisili.sub_regency_name);
					$("#textKabupatenIn").val(domisili.regency_name);
					$("#textProvinsiIn").val(domisili.province_name);
				} else {
					$("#textAlamatDomisiliIn").val('');
					$("#textDesaIn").val('');
					$("#textKecamatanIn").val('');
					$("#textKabupatenIn").val('');
					$("#textProvinsiIn").val('');
				}


				$("#textWilPDIn").val(dataDetail.daerah_name);
				$("#textWilPcIn").val(dataDetail.cabang_name);
				$("#textNoSTRTTKIn").val(dataDetail.nomor_strttk);
				$("#textEDSTRTTKIn").val(dataDetail.masa_berlaku_strttk);

				$("#textInformasiFasilitasIn").val(dataDetail.informasi_fasilitas);


				$("#textNamaPimpinanIn").val(dataDetail.nama_pimpinan);
				$("#textHariPraktikIn").val(dataDetail.hari_praktik);
				$("#textJamPraktikIn").val(dataDetail.jam_praktik);
				$("#textJabatanPimpinanIn").val(dataDetail.jabatan_pimpinan);


				var dokumen = dataDetail.dokumen
				var persyaratanSIPTTK = ``
				if (dokumen.length <= 0) {
					persyaratanSIPTTK = `
					<tr>
						<td colspan="2" align="center">Tidak ada persyaratan !</td>
					</tr>
				`
				} else {
					dokumen.forEach(dataBerkas => {
						persyaratanSIPTTK += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					});
				}

				$("#persyaratanSIPTTKIn").append(persyaratanSIPTTK);

				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="4" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
						</tr>
					`
				}

				$("#dataPendidikanSIPTTKIn").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td align="center">Data Pekerjaan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<th width="25%">Nama Sarana</th>
							<td>:</td>
							<td width="75%">${dokumenPekerjaan.nama_tempat_kerja}</td>
						</tr>
						<tr>
							<th>Alamat</th>
							<td>:</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
						</th>
						<tr>
							<th>Telp / Fax</th>
							<td>:</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
						</tr>
						<tr>
							<th>No HP</th>
							<td>:</td>
							<td>${dokumenPekerjaan.phone_number}</td>
						</tr>
						<tr>
							<th>Email</th>
							<td>:</td>
							<td>${dokumenPekerjaan.email}</td>
						</tr>
					`
				}

				$("#dataPekerjaanIn").append(pekerjaan);


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanSIPTTKPC.getCommentar(UserID, "rekom_sipttk", id)
				let dataComment = response.data;
				dataComment.reverse();

				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}

				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-commentIn").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-commentIn").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-commentIn');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}



				$("#btnSendCommentIn").off('click').on('click', async function () {

					if (UserID != '' && id != '') {
						document.getElementById("btnSendCommentIn").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentarIn").value
						const response = await ApiPengajuanSIPTTKPC.sendCommentar(UserID, id, "rekom_sipttk", content);
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-left text-dark text-left" style="width:88%;">
												<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-right text-dark text-left" style="width:88%;">
												<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}


							let response = await ApiPengajuanSIPTTKPC.getCommentar(UserID, "rekom_sipttk", id)
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-commentIn").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-commentIn").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-commentIn');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendCommentIn").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentarIn").val('');
						} else {
							document.getElementById("btnSendCommentIn").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentarIn").val('');
						}


					}


					// $(this).off('click');

				});



				$('#detailSIPTTKModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendCommentIn').off('click');
				});

			}
		});


		$('#tablePengajuanSIPTTK tbody').on('click', '#rekomendasiSIPTTK', async function () {

			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			let table = $('#tablePengajuanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id;
			let name = data.name;

			if (data.status_pc_sarana != "approve") {
				swal.fire({
					title: 'Warning !',
					html: "Status Permohonan : <strong><u>" + data.status_pc_sarana + "</u></strong><br>Anda tidak dapat mendownload surat rekomendasi sebelum di setujui oleh Admin PC setempat",
					icon: 'warning',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK'
				})
			} else {
				if (data.jenis_rekomendasi == '' || data.jenis_rekomendasi == null || data.jenis_rekomendasi == 'penerbitan') {
					const downloadSKSIPTTK = await ApiAnggota.downloadSKSIPTTK({
						id: idPengajuan,
						name: name
					})
				}else {
					const downloadSKSIPTTK = await ApiAnggota.downloadSKSIPTTKPencabutan({
						id: idPengajuan,
						name: name
					})
				}
			}


			$(this).html('<i class="fas fa-download"></i> Rekomendasi SIPTTK');

		});

		
		$('#tablePengajuanSIPTTK thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSIPTTK thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});


	},


	async _getPengajuanSIPTTKOut(status, startDateOut, endDateOut) {
		var savedOrderColumn = 'created_at';
		var savedOrderDirection = 'desc';
		let table = await $('#tablePengajuanSIPTTKOut').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.SIPTTK_OUT(status, startDateOut, endDateOut)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				},
				"data" : function (d) {
					d.orderColumn = savedOrderColumn;
					d.orderDirection = savedOrderDirection;
				}
			},
			"columns": [
				{ data: 'name' },
				{
					data: 'created_at'
				},
				{ data: 'jenis_permohonan' },
				{ data: 'jenis_rekomendasi', render : function (data) {
					if (data == null || data == '') {
						return "Penerbitan"
					}else {
						return data
					}
				} },
				{ data: 'cabang_sarana_name' },
				{
					data: 'avatar', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.ktp_photo', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.strttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'berkas_data_umum.sipttk', render: function (data) {
						var url = data;
						var content = '';
						if (url == '' || url == null) {
							content = '-';
						} else {
							content = '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
						}

						return content;
					}
				},
				{
					data: 'status_pc', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				},
				{
					data: 'status_pc_sarana', render: function (data) {
						if (data == 'pending') {
							var status = `<span class="badge bg-warning text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else if (data == 'revise') {
							var status = `<span class="badge bg-danger text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						} else {
							var status = `<span class="badge bg-success text-light font-weight-none text-capitalize text-center" id="status">${data}</span>`;
						}

						return status
					}
				}
			],
			order: [
				[1, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": [4,5,6,7,8],
					"orderable": false,
				},
				{
					"targets": [2,3],
					"visible": true,
					"searchable": true,
					className: "text-capitalize"
				},
				{
					"targets": 11,
					"data": null,
					"render": function (data, type, row) {
						var disabled = ""
						if (row.status_pc_sarana =="approve") {
							 disabled = "d-none"
							
						}
					return `
					<div style="display:flex">
						<button style="margin:1px" class='btn btn-warning btn-sm text-center' data-toggle="modal" data-target="#detailSIPTTKModalOut" id='detailOut' title='Detail'><i class="fas fa-list"></i></button>
						<button style="margin:1px" class='btn btn-primary btn-sm ${disabled}' id='approve' title='Approve'><i class='fas fa-check'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-sm' data-toggle="modal" data-target="#commentModal" id='revisi' title='Revisi'><i class='fas fa-times'></i></button>
					</div>`
					}
				},
			]
		});

		$('#tablePengajuanSIPTTKOut tbody').off('click').on('click', '#detailOut', async function () {
			// $("#out-hide").hide();
			// $("#cols-update").attr('class', 'col-sm-12');

			$("#persyaratanSIPTTK").html('');
			$("#dataPendidikanSIPTTK").html('');
			$("#dataPekerjaan").html('');

			$("#tanggalSuratRekomendasi").val('Loading...');
			$("#textJenisPermohonan").val('Loading...');


			$("#textNamaAnggota").val('Loading...');
			$("#textEmail").val('Loading...');
			$("#textKTAN").val('Loading...');
			$("#textAlamatDomisili").val('Loading...');
			$("#textDesa").val('Loading...');
			$("#textKecamatan").val('Loading...');
			$("#textKabupaten").val('Loading...');
			$("#textProvinsi").val('Loading...');


			let table = $('#tablePengajuanSIPTTKOut').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;
			let UserID = data.user_id;

			$("#textHidden").val(id);

			const getDetailPenagjuanSIPTTK = await ApiAnggota.getPengajuanSIPTTKByID({ id });



			let dataDetail = getDetailPenagjuanSIPTTK.data;

			if (getDetailPenagjuanSIPTTK.status_code == 200) {
				let timePengajuan = dataDetail.created_at;
				let timeSurat = new Date().toLocaleDateString('en-US');
				let jenisPermohonan = dataDetail.jenis_permohonan;
				let domisili = dataDetail.domisili;

				$("#tanggalSuratRekomendasi").val(timeSurat);
				$("#textKTAN").val(dataDetail.user.ktan);
				$("#textNamaAnggota").val(dataDetail.user.name);
				$("#textEmail").val(dataDetail.user.email);
				$("#textJenisPermohonan").val(jenisPermohonan);

				if (domisili) {
					$("#textAlamatDomisili").val(domisili.address);
					$("#textDesa").val(domisili.village_name);
					$("#textKecamatan").val(domisili.sub_regency_name);
					$("#textKabupaten").val(domisili.regency_name);
					$("#textProvinsi").val(domisili.province_name);
				} else {
					$("#textAlamatDomisili").val('');
					$("#textDesa").val('');
					$("#textKecamatan").val('');
					$("#textKabupaten").val('');
					$("#textProvinsi").val('');
				}


				$("#textWilPD").val(dataDetail.daerah_name);
				$("#textWilPc").val(dataDetail.cabang_name);
				$("#textNoSTRTTK").val(dataDetail.nomor_strttk);
				$("#textEDSTRTTK").val(dataDetail.masa_berlaku_strttk);

				$("#textInformasiFasilitas").val(dataDetail.informasi_fasilitas);


				$("#textNamaPimpinan").val(dataDetail.nama_pimpinan);
				$("#textHariPraktik").val(dataDetail.hari_praktik);
				$("#textJamPraktik").val(dataDetail.jam_praktik);
				$("#textJabatanPimpinan").val(dataDetail.jabatan_pimpinan);


				var dokumen = dataDetail.dokumen
				var persyaratanSIPTTK = ``
				if (dokumen.length <= 0) {
					persyaratanSIPTTK = `
					<tr>
						<td colspan="2" align="center">Tidak ada persyaratan !</td>
					</tr>
				`
				} else {
					dokumen.forEach(dataBerkas => {
						persyaratanSIPTTK += `
							<tr>
								<td>${dataBerkas.name}</td>
								<td width="50%" class="text-center"><img loading="lazy" src='${dataBerkas.dokumen_url}' width="40px"></td>
							</tr>
						`

					});
				}

				$("#persyaratanSIPTTK").append(persyaratanSIPTTK);

				var dokumenPendidikan = dataDetail.pendidikan
				if (dokumenPendidikan == null) {
					var pendidikan = `
					<tr>
						<td colspan="4" align="center">Data Pendidikan Kosong !</td>
					</tr>
				`
				} else {
					var pendidikan = `
						<tr>
							<td>${dokumenPendidikan.tahun_lulus}</td>
							<td>${dokumenPendidikan.jenjang}</td>
							<td>${dokumenPendidikan.nama_institusi_pendidikan}</td>
							<td>${dokumenPendidikan.tanggal_ijazah}</td>
						</tr>
					`
				}

				$("#dataPendidikanSIPTTK").append(pendidikan);


				var dokumenPekerjaan = dataDetail.pekerjaan
				if (dokumenPekerjaan == null) {
					var pekerjaan = `
					<tr>
						<td align="center">Data Pekerjaan Kosong !</td>
					</tr>
				`
				} else {
					var pekerjaan = `
						<tr>
							<th width="25%">Nama Sarana</th>
							<td>:</td>
							<td width="75%">${dokumenPekerjaan.nama_tempat_kerja}</td>
						</tr>
						<tr>
							<th>Alamat</th>
							<td>:</td>
							<td>${dokumenPekerjaan.alamat_tempat_kerja}</td>
						</th>
						<tr>
							<th>Telp / Fax</th>
							<td>:</td>
							<td>${dokumenPekerjaan.telp_fax}</td>
						</tr>
						<tr>
							<th>No HP</th>
							<td>:</td>
							<td>${dokumenPekerjaan.phone_number}</td>
						</tr>
						<tr>
							<th>Email</th>
							<td>:</td>
							<td>${dokumenPekerjaan.email}</td>
						</tr>
					`
				}

				$("#dataPekerjaan").append(pekerjaan);


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-left">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-left text-dark text-left" style="width:88%;">
									<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiPengajuanSIPTTKPC.getCommentar(UserID, "rekom_sipttk", id)
				let dataComment = response.data;
				dataComment.reverse();

				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}

				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}



				$("#btnSendComment").off('click').on('click', async function () {

					if (UserID != '' && id != '') {
						document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
						let content = document.getElementById("textKomentar").value
						const response = await ApiPengajuanSIPTTKPC.sendCommentar(UserID, id, "rekom_sipttk", content);
						if (response.status_code == 200 || 201) {

							let getContentAdmin = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-left">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-left text-dark text-left" style="width:88%;">
												<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}

							let getContentUser = (user) => {
								let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
								return `
										<div style="width:100%">
											<div style="width:10%;" class="float-right">
												<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
											</div>
											<div class="float-right text-dark text-left" style="width:88%;">
												<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
													<small><b>${user.actor.name} [${user.actor.role} ${user.actor.cabang_name} ]</b></small><hr>
													<small>${user.body}</small><br>
													<div class="text-right">
														<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
													</div>
												</div>
											</div>
										</div>
										`
							}


							let response = await ApiPengajuanSIPTTKPC.getCommentar(UserID, "rekom_sipttk", id)
							let dataComment = response.data;
							dataComment.reverse();

							let getAllContent = async () => {
								let elmHtml = ``
								await dataComment.forEach(async element => {
									elmHtml = elmHtml + (element.actor_type == "user" ? getContentAdmin(element) : getContentUser(element))
								});
								return elmHtml
							}

							if (response.data == undefined || response.data.length <= 0) {
								document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
							} else {
								document.getElementById("content-comment").innerHTML = await getAllContent();

								setTimeout(() => {
									var elem = document.getElementById('content-comment');
									elem.scrollTop = elem.scrollHeight;
								}, 400);
							}
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						} else {
							document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
							$("#textKomentar").val('');
						}


					}


					// $(this).off('click');

				});



				$('#detailSIPTTKModal').on('hidden.bs.modal', async function (e) {
					$('#btnSendComment').off('click');
				});

			}
		});

		$('#tablePengajuanSIPTTKOut tbody').on('click', '#approve', async function () {

			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			let table = $('#tablePengajuanSIPTTKOut').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let id = data.id;

			const approvePengajuanPCAsal = await ApiPengajuanSIPTTKPC.approvePengajuanSIPTTKAsal({
				id: id,
				approval: 'approve'
			});


			if (approvePengajuanPCAsal.status_code != 200) {
				NotificationModal.show(`${approvePengajuanPCAsal.message}`, 'error');
				$(this).html('<i class="fas fa-check"></i>');
			} else {
				NotificationModal.show(`${approvePengajuanPCAsal.message}`, 'success');
				$('#tablePengajuanSIPTTKOut').DataTable().ajax.reload();
			}


		});
		let idPengajuan = '';
		$('#tablePengajuanSIPTTKOut tbody').on('click', '#revisi', async function () {

			let table = $('#tablePengajuanSIPTTKOut').DataTable();
			let data = table.row($(this).parents('tr')).data();
			idPengajuan = data.id;

		});

		$("#btnSendKomentarPengajuan").click(async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			let catatan = $("#textKomentarPengajuan").val();
			const revisePengajuanPCAsal = await ApiPengajuanSIPTTKPC.revisePengajuanSIPTTKAsal({
				id: idPengajuan,
				approval: 'revise',
				catatan: catatan
			});


			if (revisePengajuanPCAsal.status_code != 200) {
				NotificationModal.show(`${revisePengajuanPCAsal.message}`, 'error');
				$(this).html('Send');
			} else {
				NotificationModal.show(`${revisePengajuanPCAsal.message}`, 'success');
				$('#tablePengajuanSIPTTKOut').DataTable().ajax.reload();
				$(this).html('Send');
			}
		});

		$('#tablePengajuanSIPTTKOut thead').off('click', 'th').on('click', 'th', function () {
			let chachedCoulumnName = savedOrderColumn;
			const columnIndex = $(this).index();
			const columnHeaders = $('#tablePengajuanSIPTTKOut thead th');
			const columnName = $(columnHeaders[columnIndex]).data('column');
			var order =  table.order()[0][1];
			
			if (order == "asc") {
				order = "desc"
			}else{
				order = "asc"
			}
			if(chachedCoulumnName != columnName){
				order = "asc"
			}
			savedOrderColumn = columnName;
			chachedCoulumnName = savedOrderColumn;
			savedOrderDirection = order;
		});




	},

	async _getPersyaratan() {

		let table = await $('#tablePersyaratanSIPTTK').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				},
				{
					extend: 'pdf',
					text: '<i class="fas fa-file-pdf"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file pdf"
				},
				{
					extend: 'print',
					text: '<i class="fas fa-print"></i>',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Print",
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT_PENGURUS_PC.PERSYARATAN_SIPTTK}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'name' },
				{ data: 'description' },
			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
				{
					"targets": [0],
					"visible": true,
					"searchable": false
				},
				{
					"targets": 2,
					"data": null,
					"defaultContent": `
					<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-sm'  id='edit' title='Edit data '><i class='far fa-edit'></i></button>
                     <button style="margin:1px" class='btn btn-danger btn-sm' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
			]
		});

	},

	async createSyaratSIPTTK() {
		const eventCreateSyaratSIPTTK = async (e) => {
			e.preventDefault();
			if ($("#btnAddSyaratSIPTTK").is(':visible')) {
				document.getElementById("btnAddSyaratSIPTTK").innerHTML = `<img loading="lazy" src="images/loading-gif.gif" width="20px">`
				const createSyaratSIPTTK = await ApiPengajuanSIPTTKPC.createSyaratSIPTTK({
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,
				});
				if (createSyaratSIPTTK.status_code != 201) {
					NotificationModal.show(`${createSyaratSIPTTK.message}`, 'error');
					document.getElementById("btnAddSyaratSIPTTK").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSIPTTK").hide();
				} else {
					NotificationModal.show(`${createSyaratSIPTTK.message}`, 'success');
					document.getElementById("btnAddSyaratSIPTTK").innerHTML = `<i class="fas fa-add"></i> Tambah Persyaratan`;
					$("#btnUpdateSyaratSIPTTK").hide();
					$("#btnClearSyaratSIPTTK").trigger("click");
					$('#tablePersyaratanSIPTTK').DataTable().ajax.reload();
				}
			} else {
				document.getElementById("btnUpdateSyaratSIPTTK").innerHTML = `<img loading="lazy" src="images/loading-gif.gif" width="20px">`
				const updateSyaratSIPTTK = await ApiPengajuanSIPTTKPC.updateSyaratSIPTTK({
					id: document.getElementById('textHIddenPersyaratan').value,
					name: document.getElementById('textNamaBerkas').value,
					description: document.getElementById('textDeskripsi').value,

				});

				if (updateSyaratSIPTTK.status_code != 200) {
					NotificationModal.show(`${updateSyaratSIPTTK.message}`, 'error');
					$("#btnAddSyaratSIPTTK").hide();
					document.getElementById("btnUpdateSyaratSIPTTK").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
				} else {
					NotificationModal.show(`${updateSyaratSIPTTK.message}`, 'success');
					document.getElementById("btnUpdateSyaratSIPTTK").innerHTML = `<i class="fas fa-edit"></i> Edit Persyaratan`;
					$("#btnAddSyaratSIPTTK").hide();
					$("#btnClearSyaratSIPTTK").trigger("click");
					$('#tablePersyaratanSIPTTK').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-persyaratan-sipttk').addEventListener('submit', eventCreateSyaratSIPTTK);

		$('#tablePersyaratanSIPTTK tbody').on('click', '#edit', function () {
			const table = $('#tablePersyaratanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();

			$('#textHIddenPersyaratan').val(data.id);
			$('#textNamaBerkas').val(data.name);
			$('#textDeskripsi').val(data.description);
			$("#btnAddSyaratSIPTTK").hide();
			$("#btnUpdateSyaratSIPTTK").show();

		});


		const eventClear = async (e) => {
			document.getElementById("form-persyaratan-sipttk").reset();
			$("#btnAddSyaratSIPTTK").show();
			$("#btnUpdateSyaratSIPTTK").hide();
		}

		document.getElementById("btnClearSyaratSIPTTK").addEventListener("click", eventClear)

		$('#tablePersyaratanSIPTTK tbody').on('click', '#delete', async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');
			const table = $('#tablePersyaratanSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Persyaratan </strong>: " + data.name,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let id = data.id;

					const deleteSyaratSIPTTK = await ApiPengajuanSIPTTKPC.deleteSyaratSIPTTK({
						id: id
					});
					if (deleteSyaratSIPTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSyaratSIPTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tablePersyaratanSIPTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSyaratSIPTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				} else if (result.dismiss == 'cancel') {
					$(this).html('<i class="fas fa-trash"></i></button>');
				}
			}

			);
		});
	},

	async _getInformationSIPTTK() {

		tinymce.remove('#textInformasiSIPTTK')
		tinymce.init({
			selector: 'textarea#textInformasiSIPTTK',
			menubar: false,
			min_height: 100,
			visual: true,
			inline_styles: true,
			toolbar: 'undo redo | styleselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print',
			fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
		});

		const getInformation = await ApiPengajuanSIPTTKPC.getInformationSIPTTK();

		var textInformationSIPTTK = tinymce.get('textInformasiSIPTTK').setContent(getInformation.data.description);

	},
	async createInformationSIPTTK() {

		$("#btnUpdateInformasiSIPTTK").click(async function () {
			$(this).html('<img loading="lazy" src="images/loading-gif.gif" width="20px">')
			var textInformationSIPTTK = tinymce.get('textInformasiSIPTTK').getContent();
			const createInformationSIPTTK = await ApiPengajuanSIPTTKPC.createInfromationSIPTTK({
				description: textInformationSIPTTK,
			});

			if (createInformationSIPTTK.status_code == 200) {
				NotificationModal.show(`${createInformationSIPTTK.message}`, 'success');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			} else {
				NotificationModal.show(`${createInformationSIPTTK.message}`, 'error');
				$(this).html("<i class='fas fa-edit'></i> Update Informasi");
			}


		});
	},


	async _approveSIPTTK() {

		const approveSIPTTK = async (e) => {
			$("#btnSaveIn").html('<img loading="lazy" src="images/loading-gif.gif" width="20px">');

			let id = $("#textHiddenIn").val();
			let approval = $("#selectStatusApproveIn").val();
			let nomorSurat = $("#textNomorSuratIn").val();
			let tanggalSurat = $("#tanggalSuratRekomendasiIn").val()
			let masaBerlaku = $("#textMasaBerlakuIn").val();
			let catatan = $("#textCatatanIn").val();

			const ApproveSIPTTK = await ApiPengajuanSIPTTKPC.approvePengajuanSIPTTK({
				id: id,
				approval: approval,
				masa_berlaku_sr_pc: masaBerlaku,
				nomor_sr_pc: nomorSurat,
				catatan: catatan
			});

			if (ApproveSIPTTK.status_code == 200) {
				NotificationModal.show(`${ApproveSIPTTK.message}`, 'success');
				$("#btnSaveIn").html('<i class="fas fa-save"></i> Simpan');
				$('#tablePengajuanSIPTTK').DataTable().ajax.reload();
			} else {
				NotificationModal.show(`${ApproveSIPTTK.message}`, 'error');
				$("#btnSaveIn").html('<i class="fas fa-save"></i> Simpan');
			}
		}

		document.getElementById('btnSaveIn').addEventListener('click', approveSIPTTK);
	}


}

export default SIPTTKPC;
