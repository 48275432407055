import ErrorPresenter from "../../presenter/error-presenter.js";

const notFound = {
    async render() {
      return `  
      <div class="text-center">
        <div class="error mx-auto" data-text="404">404</div>
        <p class="lead text-gray-800 mb-5">Page Not Found</p>
        <br>
        <br>
        <p class="text-gray-500 mb-0" id="reason"> Maaf Anda tidak memiliki akses kesini.</p>
        <br>
        <br>
        <br>
        <br>
        <a href="#/">&larr; Back to Dashboard</a>
      </div>
          `;
    },
  
    async afterRender() {
     await ErrorPresenter.init()
    },

    _errorContent() {
  
    },

  };
  
  export default notFound;
  

