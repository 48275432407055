import ApiLogin from "../api/data-login.js";
import CONFIG from "../config/globals/config.js";
import NotificationModal from "./initial_notification.js";

const Autentication= {
    async isLogin() {
        const result = await this.getData(); 
        const resultRole = await this.getRole();
        const resultToken = await this.getToken();
        if(result.length < 1 || resultRole < 1 || resultToken < 1){
            return false
        }else {
            return true
        }
	},

    async saveData(dataReceive) {
        const dayLogin = {tanggal : `${new Date().toISOString().slice(0,10)}`};
        const data  =  Object.assign(dataReceive , dayLogin);
      
        if (this._checkForStorage()) {
            let userData = null;
            if (localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI) === null) {
                userData = [];
            } else {
                userData = JSON.parse(localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI));
            }
      
            userData.unshift(data);
      
            if (userData.length > 1) {
                userData.pop();
            }
      
            localStorage.setItem(CONFIG.CACHE_KEY_OTENTIKASI, JSON.stringify(userData));
        }
    },

    _checkForStorage(){
        return typeof(Storage) !== "undefined";
    },

    async getData(){
        if (this._checkForStorage) {
            return JSON.parse(localStorage.getItem(CONFIG.CACHE_KEY_OTENTIKASI)) || [];
        } else {
            return [];
        }
    },

    autoLogout(){
        localStorage.removeItem(CONFIG.CACHE_KEY_OTENTIKASI);
        localStorage.removeItem(CONFIG.TOKEN_USER);
        localStorage.removeItem(CONFIG.ROLE);
        localStorage.removeItem("avatar");
        localStorage.removeItem("pc_id");
        localStorage.removeItem("pd_id");
    },

    
	async _showLoading(){
        mRefresh.refresh();
	},

	async _hideLoading(){
        mRefresh.resolve();	
	},

    async _logOut(){
        this._showLoading()
        const res = await ApiLogin.Logout();
        if(res.status_code ==200){
            this.autoLogout();
            window.location.hash = '#/login';
        }else{
            NotificationModal.show(`${res.message}`,'error');
            this.autoLogout();
            window.location.hash = '#/login';
        }
        this._hideLoading();
      
    },

    async getToken() {
       return localStorage.getItem(CONFIG.TOKEN_USER)
    },

    async getRefreshToken() {
        return localStorage.getItem(CONFIG.REFRESH_TOKEN)
    },

    async getExpireIn() {
        return localStorage.getItem(CONFIG.EXPIRED_IN)
     },

    async saveToken(token , expireIn) {
        localStorage.setItem(CONFIG.TOKEN_USER, token);
        var currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + expireIn - 500);
        console.log(currentDate.toISOString())
        const dateObject = new Date(currentDate.toISOString());
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();
        
        const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        
        localStorage.setItem(CONFIG.EXPIRED_IN , currentDate.toISOString())
        console.log(formattedDateTime)
        localStorage.setItem("expire_date_desc",formattedDateTime )
        this.saveVersion(CONFIG.VERSI_APP)
    },

    async saveRefreshToken(token) {
        localStorage.setItem(CONFIG.REFRESH_TOKEN, token);
    },

    async getRole() {
        return  JSON.parse(localStorage.getItem(CONFIG.ROLE))
     },
 
     async saveRole(role) {
         localStorage.setItem(CONFIG.ROLE, JSON.stringify(role));
     },

     async getPCID() {
        return localStorage.getItem("pc_id")
     },
 
     async savePCID(pc_id) {
         localStorage.setItem("pc_id", pc_id);
     },

     async getPCMember() {
        return localStorage.getItem("pc_member")
     },
 
     async savePCMember(pc_id) {
         localStorage.setItem("pc_member", pc_id);
     },

     async getPDMember() {
        return localStorage.getItem("pd_member")
     },
 
     async savePDMember(pc_id) {
         localStorage.setItem("pd_member", pc_id);
     },

     async getPDID() {
        return localStorage.getItem("pd_id")
     },
 
     async savePDID(pd_id) {
         localStorage.setItem("pd_id", pd_id);
     },

     async getAvatar() {
        return localStorage.getItem("avatar")
     },
 
     async saveAvatar(avatar) {
         localStorage.setItem("avatar", avatar);
     },
 
  
     async getIntervalNotificationId() {
        return localStorage.getItem("notification_id")
     },
 
     async saveIntervalNotificationId(id) {
         localStorage.setItem("notification_id", id);
     },

     async getAlertLogout() {
        return localStorage.getItem("alert_logout")
     },
 
     async saveAlert(token) {
         localStorage.setItem("alert_logout", token);
     },
 
     async getClientID() {
        return CONFIG.CLIENT_ID;
    },

    async getSecretClient() {
        return CONFIG.SECRET_ID;
    },

     async removeIntervalNotificationId(id) {
        localStorage.setItem("notification_id", id);
    },

    async saveVersion(versi) {
        localStorage.setItem("versi_sipafi", versi);
    },

    async getVersion() {
        return localStorage.getItem("versi_sipafi")
    },
    
}


export default Autentication;
