import RegisterInitiator from "../../presenter/register-presenter.js";

const Register = {
    async render() {
        return `<div class="">
        <!-- Outer Row -->
        <div class="row justify-content-center">
  
            <div class="col-xl-10 col-lg-12 col-md-12">
  
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-5 d-lg-block ">
                                <div class="p-3">
                                    <div class="text-center">
                                        <img loading="lazy" src="images/logo.png" width="30%">
                                    </div>
                                    <div class="text-left">
                                        <h1 class="h4 text-center text-gray-900 mb-4" style="margin-top : 10px;">Petunjuk Registrasi Anggota</h1>
                                        <div class="text-center">
                                              <a id="btnTutorialRegist" class="btn btn-google" data-toggle="modal" style="background:#4e73df">
                                              <i class="fab fa-youtube"></i>
                                                Video Tutorial Registrasi Anggota Baru
                                              </a>
                                       </div>
                                        <ol>
                                            <li>Isi Alamat email anda yang valid dan masih aktif, Gunakan email pribadi bukan email organisasi</li>
                                            <li>Masukkan Password</li>
                                            <li>Isikan Nama Anda</li>
                                            <li>Isikan NIK/No. KTP Anda</li>
                                            <li>Isikan Tanggal Lahir</li>
                                            <li>Pilih Jenis Kelamin</li>
                                        </ol>
                                        <hr>
                                        <h1 class="h4 text-center text-gray-900 mb-4">Catatan Penting !</h1>
                                        <ol>
                                            <li>Untuk anggota baru atau yang belum memiliki NO KTAN / NIAN silakan anda pilih anggota baru</li>
                                            <li>Untuk anggota yang sudah memiliki NO KTAN / NIAN silakan anda pilih anggota lama</li>
                                            <li>Kami sangat menyarankan anda menggunakan email dari gmail</li>
                                            <li>Link aktivasi akan kami kirim ke alamat email anda, sesaat setelah anda mendaftar, anda bisa buka inbox email anda dan jika tidak ada email di inbox, silakan cek di folder spam email anda.</li>
                                            <li>Setelah akun anda aktif, silakan lengkapi data diri anda untuk kami verifikasi keabsahan nya</li>
                                            <li>Jika data anda sudah lengkap, maka akan kami validasi dan memberikan KTAN kepada anda</li>
                                            <li>Jika anda mengalami kesulitan, anda bisa menghubungi admin PC dimana anda mendaftar</li>
                                            <li>Selama akun anda belum terverifikasi, maka anda tidak dapat mengakses menu khusus anggota, diantara nya: Lowongan pekerjaan, Informasi Seminar mengirim lowongan pekerjaan dan sebagainya</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="p-3">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Registrasi Anggota</h1>
                                        <p>Jika ada kesulitan bisa kontak kami di<br>
                                        Kontak Telp/WA: +62 822-2046-7605<br>
                                        Email: helpdesk.pafi@gmail.com</p>
                                    </div>
                                   
                                    <form class="user" class="form-horizontal" id='register-user'>
                                        <div class="form-group">
                                            <select class="form-control" id="selectJenisKeanggotaan" name="registerSelectJenis" placeholder="Pilih Jenis Keanggotaan" required>
                                                <option id="" value="">Pilih Jenis Keanggotaan</option>
                                                <option id="" value="newAnggota">Anggota Baru</option>
                                                <option id="" value="oldAnggota">Anggota Lama</option>
                                            </select>
                                        </div>
                                        <div class="form-group d-none" id="groupKTAN">
                                            <input type="text" name="KTAN" class="form-control" id="textKTAN" aria-describedby="ktan" placeholder="Nomor KTAN [XXXX.XXXXXXXX.X.XXXXXX]">
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control" id="registerSelectPD" name="register_select_pd" placeholder="Pilih Keanggotaan Wilayah PD" required>
                                                <option id="" value="">Pilih Keanggotaan Wilayah PD</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control" id="registerSelectPC" name="register_select_pc" placeholder="Pilih Keanggotaan Wilayah PC" required>
                                                <option value="">Pilih Keanggotaan Wilayah PC</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" name="register_nama_lengkap" class="form-control" id="registerTextNamaLengkap" aria-describedby="register_nama_lengkap" placeholder="Nama Lengkap..." required>
                                        </div>
                                        <div class="form-group">
                                            <input type="number" name="register_nik" class="form-control" id="registerTextNIK" aria-describedby="registernik" placeholder="NIK / NO. KTP..." required title="NIK / NO. KTP Harus terdiri dari 16 Digit" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            type = "number"
                                            maxlength = "16">
                                        </div>
                                        <div class="form-group">
                                            <input type="number" name="register_no_hp" class="form-control" id="registerNumberNoHP" aria-describedby="register_no_hp" placeholder="No Hp..." required oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            type = "number"
                                            maxlength = "14">
                                        </div>
                                        <div class="form-group">
                                            <input type="email" name="register_email" class="form-control" id="registerEmail" aria-describedby="register_email" placeholder="Email..." required>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    </div>
                                                    <input name="register_password" type="password" value="" class="form-control" id="registerTextPassword" placeholder="Password..." required="true" aria-label="register_password" aria-describedby="basic-addon1" />
                                                    <div class="input-group-append">
                                                    <span class="input-group-text" id="btnShowPassword">
                                                        <i class="fas fa-eye" id="show_eye"></i>
                                                        <i class="fas fa-eye-slash d-none" id="hide_eye"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">

                                        
                                            <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                            </div>
                                                <input name="register_confirm_password" type="password" value="" class="form-control" id="registerTextConfirmPassword" placeholder="Ulangi Password..." required="true" aria-label="register_confirm_password" aria-describedby="basic-addon1" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="btnShowPasswordConfirm">
                                                        <i class="fas fa-eye" id="show_eye_confirm"></i>
                                                        <i class="fas fa-eye-slash d-none" id="hide_eye_confirm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control" id="registerSelectJenisKelamin" name="register_jenis_kelamin" placeholder="Jenis Kelamin" required>
                                            <option value="">Jenis Kelamin</option>
                                            <option value="L">Laki Laki</option>
                                            <option value="P">Perempuan</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <input placeholder="Tanggal Lahir..." class="textbox-n form-control" type="text" onfocus="(this.type='date')" id="registerTanggalLahir" required min="1900-01-01" max="2006-12-31"/>
                                        </div>

                                        
                                        <div class="form-group d-none" id="dzoneKTAN">
                                            <label class="control-label font-weight-bold">File KTAN Lama</label>
                                            <div id="form-upload-ktan" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>

                                        <div class="form-group row d-none" id="expiredKTAN">
                                            <label class="control-label col-sm-12 font-weight-bold">Masa Berlaku KTAN</label>
                                            <div class="col-sm-12">
                                                <input class="form-control" type="date" id="masaBerlakuKTAN" />
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row d-none" id="startIuran">
                                            <label class="control-label col-sm-12 font-weight-bold">Iuran Terakhir (Dari Bulan)</label>
                                            <div class="col-sm-12">
                                                <input type="month" min="2015-01" class="form-control" id="startBulan">
                                            </div>
                                        </div>
                                        <div class="form-group row d-none" id="endIuran">
                                            <label class="control-label col-sm-12 font-weight-bold">Iuran Terakhir (Sampai Bulan)</label>
                                            <div class="col-sm-12">
                                                <input type="month" min="2015-01" class="form-control" id="endBulan">
                                            </div>
                                        </div>
                                        <div class="form-group d-none" id="dzoneBuktiIuran">
                                            <label class="control-label font-weight-bold">Bukti Pembayaran Iuran</label>
                                            <div id="form-upload-bukti-iuran" class="col-sm-12 dropzone">
                                                <input type="hidden" name="file" style=""/>
                                            </div>
                                        </div>

                                        <p class="text-justify">Disclaimer:<br>
                                        Dengan mengisi dan mengirim pendaftaran anggota di website ini, maka seluruh data, informasi dan dokumen yang diinputkan pada aplikasi ini adalah yang sebenarnya. dan bertanggung jawab penuh atas data yang diisi dan dokumen/file yang diuploadkan.
                                        Silakan klik tombol kirim jika anda setuju dan akan mematuhi segala kewajiban dan ketentuan yang berlaku pada organisasi Persatuan Ahli Farmasi Indonesia<p>
                                        <div class="form-check">
                                            <input class="form-check-input" required type="checkbox" value="" id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                            Setuju
                                            </label>
                                         </div>
                                        
                                         <button id="btnRegister" style="margin-top:15px;" class="btn btn-primary btn-user btn-block">Registrasi</button>
                                        <hr>
                                        
                                        <a id="btnLogin" class="btn btn-google btn-user btn-block">Kembali ke Login</a>
                                        <a id="btnHome" href="https://webpafi.com/" class="btn  btn-outline-secondary btn-user btn-block text-dark">Kembali Ke Home</a>
  
                                    </form>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  
            </div>
  
        </div>
  
    </div>
    
    <div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>
    
    `
   },
  
    async afterRender() {
        await RegisterInitiator.init();
        
    },


  
    _errorContent(container) {
      const errorContent = document.getElementById('main-content');
      errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
    },

  };
  export default Register;
  

