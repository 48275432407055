/* eslint-disable new-cap */
import CONFIG from '../config/globals/config.js';
import API_ENDPOINT from '../config/globals/endpoint.js';
import Autentication from '../utils/autentication.js';
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';

var isInProgressRefresh = false;
class ApiLogin {

	static async Login({
		username,
		password,
		role,
	}) {
		let myHeaders =  new Headers();
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		myHeaders.append( 'POST', 'OPTIONS');
	
		var rawValue = JSON.stringify({
			"grant_type": "password",
			"client_id": await Autentication.getClientID(),
			"client_secret": await Autentication.getSecretClient(),
			"username": `${username}`,
			"password": `${password}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.LOGIN_OUAUTH, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async Logout() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.LOGOUT, {
				method: 'POST',
				headers: myHeaders,
				redirect: 'follow'
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async RefreshToken() {
			if (await Autentication.getRefreshToken() == null) {
				if (Autentication.isLogin()) {
					Autentication.autoLogout()
					window.location.hash = '#/login';
					return
				}else{
					Autentication.autoLogout()
					window.location.hash = '#/login';
					return	
				}
			}

			if (isInProgressRefresh == true) {
				console.log("as refreshed")
				setTimeout(() => {
					if (isInProgressRefresh == true) {
						setTimeout(async () => {
							await this.FinalRefreshToken();
						}, 3000);
					}
				},5000);
			}else{
				console.log("slice request")
			isInProgressRefresh = true;
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Accept', 'application/json');
			myHeaders.append('Access-Control-Allow-Origin', '{*}');
			myHeaders.append('Access-Control-Allow-Credentials', 'true');
			myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
			var rawValue = JSON.stringify({
				"grant_type": "refresh_token",
				"client_id": await Autentication.getClientID(),
				"client_secret": await Autentication.getSecretClient(),
				"refresh_token": await Autentication.getRefreshToken()
			  });

			try {
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.LOGIN_OUAUTH, {
					method: 'POST',
					headers: myHeaders,
					body: rawValue
				})
				const responseJson = await fetchApi.json();
				console.log(fetchApi.status)
				if (fetchApi.status == 200) {
					await Autentication.saveToken(responseJson.access_token ,responseJson.expires_in);
					await Autentication.saveRefreshToken(responseJson.refresh_token);
					
					isInProgressRefresh = false

					return responseJson;
				}else{
					setTimeout(async () => {
						await this.FinalRefreshToken();
					}, 1000);

					isInProgressRefresh = false
				}
			} catch (err) {
				console.log(err)
				isInProgressRefresh = false
				return {};
			}
		}
	}


	static async FinalRefreshToken() {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

	
		var rawValue = JSON.stringify({
			"grant_type": "refresh_token",
			"client_id": await Autentication.getClientID(),
			"client_secret": await Autentication.getSecretClient(),
			"refresh_token": await Autentication.getRefreshToken()
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.LOGIN_OUAUTH, {
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			})
			console.log("final")
			console.log(fetchApi.status)
			const responseJson = await fetchApi.json();
			if (fetchApi.status == 200) {
				await Autentication.saveToken(responseJson.access_token ,responseJson.expires_in);
				await Autentication.saveRefreshToken(responseJson.refresh_token);
				return responseJson;
			}else{
				setTimeout(async () => {
					 NetworkHelper.actionResponseNotAccepted()
					 Autentication.autoLogout();
					
				}, 1000);
				
			}
		} catch (err) {
			console.log(err)
			return {};
		}
	}


}

export default ApiLogin;
