import UrlParser from '../routes/url-parser.js';
import ApiRegister from "../api/data-register.js";

const KodeWilayah = {

	async init() {
		await this._setView();
		// await this._setUpViewSerkom();
		await this.initProvinceValue();
	},

	async _setView() {
		document.getElementById("main-content").style.height = "100vh";
		document.getElementById("cpRght").style.display = "none";
		document.getElementById('main-content').classList.add('bg-gradient-primary');
		document.getElementById("main-content").style.paddingTop = "20px";
		document.getElementById('appbar').classList.add('d-none')
		document.getElementById('accordionSidebar').classList.add('d-none')
		
	},


	async initProvinceValue(){
		let kodeProvinsi = '';
		const dataProvince = await ApiRegister.getProvince()
		if(dataProvince.status_code == 200){
			dataProvince.data.forEach(data => {
				document.getElementById("selectProvince").add(new Option(data.province_name , data.province_code))
			});	
		}
		
		
		let dataKab = []
		
		let table = await $("#tableKodeWilayah").DataTable({
			"scrollX":true,
			
		});
		$("#selectProvince").on("change", async function () {
			
			
			table.clear().draw();
			let valueSelected = document.getElementById("selectProvince").value
			const dataRegency = await ApiRegister.getRegistry(valueSelected)
			
			dataRegency.data.forEach(data => {
				let dataKode = []
				dataKode = [data.province_id,data.id,data.regency_name]
				table.row.add(dataKode).draw();
			})


		})

		// new DataTable("#tableKodeWilayah", {
		// 	"data":dataKab,
		// })

		
		// const eventChange = async (e) => {
			
		// 	console.log(valueSelected);

			
		// }

		// document.getElementById("selectProvince").addEventListener("change", eventChange)

	},

	_setUpViewSerkom(){
		let url = UrlParser.parseActiveUrlWithCombiner();
		if(url == "/kode-wilayah"){
			document.getElementById('noteMessage').textContent = 'Dokumen Valid';
		}else{
			document.getElementById('noteMessage').textContent = 'Dokumen Invalid';
		}
	},

}

export default KodeWilayah;
