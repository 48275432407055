
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PD from "../../config/globals/menu_pengurus_pd_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";
class ApiPengajuanKTANPC {
    static async approveSKP (
        id,
		skp_approve_pd

    ){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"approval": "approve",
			"skp_approve_pd": skp_approve_pd,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_SKP(id),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	
	static async approveAllPD (
        idPengajuanList,
    ){
        let myHeaders =  new Headers();
		let dataList= idPengajuanList.map(a => a.id_pengajuan);

		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
      
		var rawValue = JSON.stringify({
            "skp_id": dataList,
            "approval": "approve",
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_All_SKP_PD,{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	

	
    
    static async reviseSKP ({
        idSKP,
		skpApprovePC,
		catatan

	}){
		// console.log(data);
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var raw = JSON.stringify({
			"approval": "revise",
			"skp_approve_pd": skpApprovePC,
			"catatan": catatan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PD.APPROVE_SKP(idSKP),{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }
}


export default ApiPengajuanKTANPC;
